
import { paths } from "@/router/routes";
import partner, { partnerModule } from "@/store/modules/partner";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue, Prop } from "vue-property-decorator";
import { PartnerSocialLinks } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class Footer extends Vue {
  @partnerModule.State partnerSocialLinks: RemoteData<UserError, PartnerSocialLinks>;

  @Prop({ default: false }) showFaqsBtn: boolean;
  @Prop({ default: false }) dark: boolean;

  paths = paths;

  get color(): string {
    return this.dark ? "white" : "gray";
  }

  get isShopPage(): boolean {
    return window.location.pathname == "/shop";
  }

  get socialLinks(): { icon?: string; src?: string; link: string }[] {
    const links = this.partnerSocialLinks.data;

    return links
      ? [
          { icon: "mdi-linkedin", link: links.getLinkedIn() },
          { icon: "mdi-facebook", link: links.getFacebook() },
          { icon: "mdi-instagram", link: links.getInstagram() },
          { icon: "mdi-twitter", link: links.getTwitter() },
          { src: "xing-logo.png", link: links.getXing() },
        ].filter((e) => e.link)
      : [];
  }

  mounted(): void {
    partner.getSocialLinks();
  }
}
