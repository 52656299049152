
import { Component, Vue } from "vue-property-decorator";
import FeaturePage, { Feature } from "./FeaturePage.vue";

@Component({
  components: {
    FeaturePage,
  },
})
export default class MunicipalUtilitiesFeatures extends Vue {
  features: Feature[] = [
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.crossPlatform.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.crossPlatform.text"),
      image: "cross-platform.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.complyWithRegulations.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.complyWithRegulations.text"),
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.consumptionTracking.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.consumptionTracking.text"),
      image: "visualisation.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.smartCharging.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.smartCharging.text"),
      image: "smart-charging.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.nonSmartMeters.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.nonSmartMeters.text"),
      image: "old-style-meter.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.multipleDevices.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.multipleDevices.text"),
      image: "multiple-devices.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.upselling.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.upselling.text"),
      image: "upselling.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.newEnergyProducts.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.newEnergyProducts.text"),
      image: "dynamic-tariff.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.simulator.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.simulator.text"),
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.adminPortal.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.adminPortal.text"),
      image: "admin-portal.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.landlordToTenant.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.landlordToTenant.text"),
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.reporting.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.reporting.text"),
      image: "report.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.marketPremium.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.marketPremium.text"),
      image: "market-premium.png",
    },
  ];
}
