
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { userServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { RemoveUserFromAdminRoleRequest } from "zaehlerfreunde-central/user_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";

interface UserRow {
  id: string;
  name: string;
  email: string;
  picture: string;
  lastLogin: string;
  loginCount: number;
  user: User;
}

@Component
export default class RemoveAdminDialog extends Vue {
  @Prop() user: UserRow | null;

  removeAdminCall: RemoteCall<UserError> = initialized;

  async onProceedClicked(): Promise<void> {
    try {
      this.removeAdminCall = pending;
      const request = new RemoveUserFromAdminRoleRequest();
      request.setUserId(this.user?.id ?? "");

      await userServiceClient.removeUserFromAdminRole(request, {});
      this.removeAdminCall = success(void 0);
      this.$emit("input", false);
      this.$emit("admin-removed");
    } catch (error) {
      this.removeAdminCall = failure(userErrorFrom(error));
    }
  }
}
