import moment from "moment";

export default class IoUtils {
    static fetchFile(bytes: Uint8Array,type: FileType = FileType.JSON, name: string = moment(new Date()).format('MM-DD-YYYY')) {
        const blob = new Blob([bytes], { type: "application/"+type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = name;
        link.download = fileName;
        link.click();
    }
  
}
export enum FileType {
  PDF='pdf', 
  JSON='json',
}
