
import { paths } from "@/router/routes";
import { Component, Vue } from "vue-property-decorator";
import { articleList, BlogArticle } from "./blog-articles";

@Component
export default class Article extends Vue {
  blogArticles: Map<string, BlogArticle> = articleList;

  get blogArticle(): BlogArticle | null {
    return this.blogArticles.get(this.$route.params.title) ?? null;
  }

  beforeMount(): void {
    if (!this.blogArticles.has(this.$route.params.title)) {
      this.$router.push(paths.homepage.blog);
    }
  }

  metaInfo(): { title: string | undefined; titleTemplate: null } {
    return {
      title: this.blogArticle?.title,
      titleTemplate: null,
    };
  }
}
