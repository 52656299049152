
import { RemoteDataVue } from "@/store/utils/remote-data";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Group extends RemoteDataVue {
  @Prop({ default: null }) title: string | null;
  @Prop({ default: null }) subtitle: string | null;
  @Prop({ default: "normal" }) justifyContent: string;
  @Prop({ default: false }) largeRowGap: boolean;
  @Prop({ default: false }) marginTop: boolean;

  get rowGap(): string {
    if (this.largeRowGap) {
      return "40px";
    } else {
      return "22px";
    }
  }
}
