// source: services/central/device_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
var types_device_registration_pb = require('../../types/device_registration_pb.js');
goog.object.extend(proto, types_device_registration_pb);
var types_device_reading_pb = require('../../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
var types_external_reading_pb = require('../../types/external_reading_pb.js');
goog.object.extend(proto, types_external_reading_pb);
var types_automations_pb = require('../../types/automations_pb.js');
goog.object.extend(proto, types_automations_pb);
var google_api_httpbody_pb = require('../../google/api/httpbody_pb.js');
goog.object.extend(proto, google_api_httpbody_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var types_pagination_pb = require('../../types/pagination_pb.js');
goog.object.extend(proto, types_pagination_pb);
var types_data_import_pb = require('../../types/data_import_pb.js');
goog.object.extend(proto, types_data_import_pb);
var types_data_export_pb = require('../../types/data_export_pb.js');
goog.object.extend(proto, types_data_export_pb);
var services_central_advisory_service_pb = require('../../services/central/advisory_service_pb.js');
goog.object.extend(proto, services_central_advisory_service_pb);
goog.exportSymbol('proto.services.central.AddDeviceToSpaceRequest', null, global);
goog.exportSymbol('proto.services.central.AddDeviceToSpaceResponse', null, global);
goog.exportSymbol('proto.services.central.BitShakeMeter', null, global);
goog.exportSymbol('proto.services.central.BlueMeteringDevice', null, global);
goog.exportSymbol('proto.services.central.BlueMeteringDevice.Taf', null, global);
goog.exportSymbol('proto.services.central.ConnectPowerfoxAccountRequest', null, global);
goog.exportSymbol('proto.services.central.ConnectPowerfoxAccountResponse', null, global);
goog.exportSymbol('proto.services.central.ConnectSmartMeAccountRequest', null, global);
goog.exportSymbol('proto.services.central.ConnectSmartMeAccountResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteDeviceRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteDeviceResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteViessmannClientInfoRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteViessmannClientInfoResponse', null, global);
goog.exportSymbol('proto.services.central.DeviceDetails', null, global);
goog.exportSymbol('proto.services.central.ExportDataTypeWithLabel', null, global);
goog.exportSymbol('proto.services.central.GetAvailableViessmannDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetAvailableViessmannDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetBitShakeMeterScriptRequest', null, global);
goog.exportSymbol('proto.services.central.GetBitShakeMeterScriptResponse', null, global);
goog.exportSymbol('proto.services.central.GetBitShakeSupportedMetersRequest', null, global);
goog.exportSymbol('proto.services.central.GetBitShakeSupportedMetersResponse', null, global);
goog.exportSymbol('proto.services.central.GetBlueMeteringDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetBlueMeteringDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetBluemeteringApiKeyRequest', null, global);
goog.exportSymbol('proto.services.central.GetBluemeteringApiKeyResponse', null, global);
goog.exportSymbol('proto.services.central.GetDataImportsRequest', null, global);
goog.exportSymbol('proto.services.central.GetDataImportsResponse', null, global);
goog.exportSymbol('proto.services.central.GetDeviceProvidersRequest', null, global);
goog.exportSymbol('proto.services.central.GetDeviceProvidersResponse', null, global);
goog.exportSymbol('proto.services.central.GetDeviceReadingStatsRequest', null, global);
goog.exportSymbol('proto.services.central.GetDeviceReadingStatsResponse', null, global);
goog.exportSymbol('proto.services.central.GetDeviceRequest', null, global);
goog.exportSymbol('proto.services.central.GetDeviceResponse', null, global);
goog.exportSymbol('proto.services.central.GetDeviceTypesRequest', null, global);
goog.exportSymbol('proto.services.central.GetDeviceTypesResponse', null, global);
goog.exportSymbol('proto.services.central.GetDevicesInSpaceRequest', null, global);
goog.exportSymbol('proto.services.central.GetDevicesInSpaceResponse', null, global);
goog.exportSymbol('proto.services.central.GetExportDataTypesRequest', null, global);
goog.exportSymbol('proto.services.central.GetExportDataTypesResponse', null, global);
goog.exportSymbol('proto.services.central.GetIometerQRCodeRequest', null, global);
goog.exportSymbol('proto.services.central.GetIometerQRCodeResponse', null, global);
goog.exportSymbol('proto.services.central.GetLobaroDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetLobaroDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetMainDeviceRequest', null, global);
goog.exportSymbol('proto.services.central.GetMainDeviceResponse', null, global);
goog.exportSymbol('proto.services.central.GetMeteocontrolInvertersRequest', null, global);
goog.exportSymbol('proto.services.central.GetMeteocontrolInvertersResponse', null, global);
goog.exportSymbol('proto.services.central.GetMeteocontrolSystemsRequest', null, global);
goog.exportSymbol('proto.services.central.GetMeteocontrolSystemsResponse', null, global);
goog.exportSymbol('proto.services.central.GetMetergridProjectsRequest', null, global);
goog.exportSymbol('proto.services.central.GetMetergridProjectsResponse', null, global);
goog.exportSymbol('proto.services.central.GetMetergridTenantsRequest', null, global);
goog.exportSymbol('proto.services.central.GetMetergridTenantsResponse', null, global);
goog.exportSymbol('proto.services.central.GetMetiundoMeteringPointsRequest', null, global);
goog.exportSymbol('proto.services.central.GetMetiundoMeteringPointsResponse', null, global);
goog.exportSymbol('proto.services.central.GetMyStromDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetMyStromDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetPartnerDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetPartnerDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetPowerfoxAuthURLRequest', null, global);
goog.exportSymbol('proto.services.central.GetPowerfoxAuthURLResponse', null, global);
goog.exportSymbol('proto.services.central.GetShellyDeviceDetailsRequest', null, global);
goog.exportSymbol('proto.services.central.GetShellyDeviceDetailsResponse', null, global);
goog.exportSymbol('proto.services.central.GetShellyPortalInformationRequest', null, global);
goog.exportSymbol('proto.services.central.GetShellyPortalInformationResponse', null, global);
goog.exportSymbol('proto.services.central.GetShellyUserDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetShellyUserDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetSmartMeAuthURLRequest', null, global);
goog.exportSymbol('proto.services.central.GetSmartMeAuthURLResponse', null, global);
goog.exportSymbol('proto.services.central.GetSolarEdgeDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetSolarEdgeDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetSolarEdgeSitesRequest', null, global);
goog.exportSymbol('proto.services.central.GetSolarEdgeSitesResponse', null, global);
goog.exportSymbol('proto.services.central.GetSolarizeMetersRequest', null, global);
goog.exportSymbol('proto.services.central.GetSolarizeMetersResponse', null, global);
goog.exportSymbol('proto.services.central.GetSolarizeSitesRequest', null, global);
goog.exportSymbol('proto.services.central.GetSolarizeSitesResponse', null, global);
goog.exportSymbol('proto.services.central.GetTibberAuthTokenRequest', null, global);
goog.exportSymbol('proto.services.central.GetTibberAuthTokenResponse', null, global);
goog.exportSymbol('proto.services.central.GetTibberAuthURLRequest', null, global);
goog.exportSymbol('proto.services.central.GetTibberAuthURLResponse', null, global);
goog.exportSymbol('proto.services.central.GetTibberHomesRequest', null, global);
goog.exportSymbol('proto.services.central.GetTibberHomesResponse', null, global);
goog.exportSymbol('proto.services.central.GetTsunDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetTsunDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetUserDevicesRequest', null, global);
goog.exportSymbol('proto.services.central.GetUserDevicesResponse', null, global);
goog.exportSymbol('proto.services.central.GetViessmannAuthConfigRequest', null, global);
goog.exportSymbol('proto.services.central.GetViessmannAuthConfigResponse', null, global);
goog.exportSymbol('proto.services.central.GetVirtualDeviceRequest', null, global);
goog.exportSymbol('proto.services.central.GetVirtualDeviceResponse', null, global);
goog.exportSymbol('proto.services.central.ImovisMeterReading', null, global);
goog.exportSymbol('proto.services.central.ImportBluemeteringDataRequest', null, global);
goog.exportSymbol('proto.services.central.ImportBluemeteringDataResponse', null, global);
goog.exportSymbol('proto.services.central.ImportMetergridDataRequest', null, global);
goog.exportSymbol('proto.services.central.ImportMetergridDataResponse', null, global);
goog.exportSymbol('proto.services.central.ImportReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.ImportReadingsRequest.FileFormat', null, global);
goog.exportSymbol('proto.services.central.ImportReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.LobaroDevice', null, global);
goog.exportSymbol('proto.services.central.MeteocontrolInverters', null, global);
goog.exportSymbol('proto.services.central.MeteocontrolSystem', null, global);
goog.exportSymbol('proto.services.central.MetergridProject', null, global);
goog.exportSymbol('proto.services.central.MetergridProjectTenants', null, global);
goog.exportSymbol('proto.services.central.MetergridTenant', null, global);
goog.exportSymbol('proto.services.central.MyStromDevice', null, global);
goog.exportSymbol('proto.services.central.RegisterDeviceRequest', null, global);
goog.exportSymbol('proto.services.central.RegisterDeviceResponse', null, global);
goog.exportSymbol('proto.services.central.ShellyDevice', null, global);
goog.exportSymbol('proto.services.central.SmartMeDevice', null, global);
goog.exportSymbol('proto.services.central.SolarEdgeDevice', null, global);
goog.exportSymbol('proto.services.central.SolarEdgeSite', null, global);
goog.exportSymbol('proto.services.central.StoreImovisReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.StoreImovisReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.StoreNonSmartMeterReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.StoreNonSmartMeterReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.StoreReadingsRequest', null, global);
goog.exportSymbol('proto.services.central.StoreReadingsResponse', null, global);
goog.exportSymbol('proto.services.central.TibberHome', null, global);
goog.exportSymbol('proto.services.central.TsunDevice', null, global);
goog.exportSymbol('proto.services.central.UpdateDeviceDetailsRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateDeviceDetailsResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateShellyDeviceChannelDirectionsRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateShellyDeviceChannelDirectionsResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateShellyDeviceStatusRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateShellyDeviceStatusResponse', null, global);
goog.exportSymbol('proto.services.central.ViessmannDevice', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceRequest.displayName = 'proto.services.central.GetDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceResponse.displayName = 'proto.services.central.GetDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteDeviceRequest.displayName = 'proto.services.central.DeleteDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteDeviceResponse.displayName = 'proto.services.central.DeleteDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetUserDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetUserDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetUserDevicesRequest.displayName = 'proto.services.central.GetUserDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetUserDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetUserDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetUserDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetUserDevicesResponse.displayName = 'proto.services.central.GetUserDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDevicesInSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDevicesInSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDevicesInSpaceRequest.displayName = 'proto.services.central.GetDevicesInSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDevicesInSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDevicesInSpaceResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDevicesInSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDevicesInSpaceResponse.displayName = 'proto.services.central.GetDevicesInSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ConnectSmartMeAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ConnectSmartMeAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ConnectSmartMeAccountRequest.displayName = 'proto.services.central.ConnectSmartMeAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ConnectSmartMeAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ConnectSmartMeAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.ConnectSmartMeAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ConnectSmartMeAccountResponse.displayName = 'proto.services.central.ConnectSmartMeAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SmartMeDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SmartMeDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SmartMeDevice.displayName = 'proto.services.central.SmartMeDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSmartMeAuthURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSmartMeAuthURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSmartMeAuthURLRequest.displayName = 'proto.services.central.GetSmartMeAuthURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSmartMeAuthURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSmartMeAuthURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSmartMeAuthURLResponse.displayName = 'proto.services.central.GetSmartMeAuthURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMainDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMainDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMainDeviceRequest.displayName = 'proto.services.central.GetMainDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMainDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMainDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMainDeviceResponse.displayName = 'proto.services.central.GetMainDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddDeviceToSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddDeviceToSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddDeviceToSpaceRequest.displayName = 'proto.services.central.AddDeviceToSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddDeviceToSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddDeviceToSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddDeviceToSpaceResponse.displayName = 'proto.services.central.AddDeviceToSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateDeviceDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateDeviceDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateDeviceDetailsRequest.displayName = 'proto.services.central.UpdateDeviceDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeviceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeviceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeviceDetails.displayName = 'proto.services.central.DeviceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateDeviceDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateDeviceDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateDeviceDetailsResponse.displayName = 'proto.services.central.UpdateDeviceDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetPowerfoxAuthURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetPowerfoxAuthURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetPowerfoxAuthURLRequest.displayName = 'proto.services.central.GetPowerfoxAuthURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetPowerfoxAuthURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetPowerfoxAuthURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetPowerfoxAuthURLResponse.displayName = 'proto.services.central.GetPowerfoxAuthURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ConnectPowerfoxAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ConnectPowerfoxAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ConnectPowerfoxAccountRequest.displayName = 'proto.services.central.ConnectPowerfoxAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ConnectPowerfoxAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ConnectPowerfoxAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ConnectPowerfoxAccountResponse.displayName = 'proto.services.central.ConnectPowerfoxAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetShellyPortalInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetShellyPortalInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetShellyPortalInformationRequest.displayName = 'proto.services.central.GetShellyPortalInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetShellyPortalInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetShellyPortalInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetShellyPortalInformationResponse.displayName = 'proto.services.central.GetShellyPortalInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetShellyUserDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetShellyUserDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetShellyUserDevicesRequest.displayName = 'proto.services.central.GetShellyUserDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ShellyDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ShellyDevice.repeatedFields_, null);
};
goog.inherits(proto.services.central.ShellyDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ShellyDevice.displayName = 'proto.services.central.ShellyDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetShellyUserDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetShellyUserDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetShellyUserDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetShellyUserDevicesResponse.displayName = 'proto.services.central.GetShellyUserDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateShellyDeviceStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.UpdateShellyDeviceStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.UpdateShellyDeviceStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateShellyDeviceStatusRequest.displayName = 'proto.services.central.UpdateShellyDeviceStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateShellyDeviceStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateShellyDeviceStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateShellyDeviceStatusResponse.displayName = 'proto.services.central.UpdateShellyDeviceStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetShellyDeviceDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetShellyDeviceDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetShellyDeviceDetailsRequest.displayName = 'proto.services.central.GetShellyDeviceDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetShellyDeviceDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetShellyDeviceDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetShellyDeviceDetailsResponse.displayName = 'proto.services.central.GetShellyDeviceDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.UpdateShellyDeviceChannelDirectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.displayName = 'proto.services.central.UpdateShellyDeviceChannelDirectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateShellyDeviceChannelDirectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.displayName = 'proto.services.central.UpdateShellyDeviceChannelDirectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBlueMeteringDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBlueMeteringDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBlueMeteringDevicesRequest.displayName = 'proto.services.central.GetBlueMeteringDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBlueMeteringDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetBlueMeteringDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetBlueMeteringDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBlueMeteringDevicesResponse.displayName = 'proto.services.central.GetBlueMeteringDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImportBluemeteringDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ImportBluemeteringDataRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.ImportBluemeteringDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImportBluemeteringDataRequest.displayName = 'proto.services.central.ImportBluemeteringDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImportBluemeteringDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ImportBluemeteringDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImportBluemeteringDataResponse.displayName = 'proto.services.central.ImportBluemeteringDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBluemeteringApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBluemeteringApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBluemeteringApiKeyRequest.displayName = 'proto.services.central.GetBluemeteringApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBluemeteringApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBluemeteringApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBluemeteringApiKeyResponse.displayName = 'proto.services.central.GetBluemeteringApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.BlueMeteringDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.BlueMeteringDevice.repeatedFields_, null);
};
goog.inherits(proto.services.central.BlueMeteringDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.BlueMeteringDevice.displayName = 'proto.services.central.BlueMeteringDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.BlueMeteringDevice.Taf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.BlueMeteringDevice.Taf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.BlueMeteringDevice.Taf.displayName = 'proto.services.central.BlueMeteringDevice.Taf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetPartnerDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetPartnerDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetPartnerDevicesRequest.displayName = 'proto.services.central.GetPartnerDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetPartnerDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetPartnerDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetPartnerDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetPartnerDevicesResponse.displayName = 'proto.services.central.GetPartnerDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMetergridProjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMetergridProjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMetergridProjectsRequest.displayName = 'proto.services.central.GetMetergridProjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMetergridProjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMetergridProjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMetergridProjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMetergridProjectsResponse.displayName = 'proto.services.central.GetMetergridProjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MetergridProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MetergridProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MetergridProject.displayName = 'proto.services.central.MetergridProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMetergridTenantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMetergridTenantsRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMetergridTenantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMetergridTenantsRequest.displayName = 'proto.services.central.GetMetergridTenantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMetergridTenantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMetergridTenantsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMetergridTenantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMetergridTenantsResponse.displayName = 'proto.services.central.GetMetergridTenantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MetergridProjectTenants = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.MetergridProjectTenants.repeatedFields_, null);
};
goog.inherits(proto.services.central.MetergridProjectTenants, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MetergridProjectTenants.displayName = 'proto.services.central.MetergridProjectTenants';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MetergridTenant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MetergridTenant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MetergridTenant.displayName = 'proto.services.central.MetergridTenant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImportMetergridDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.ImportMetergridDataRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.ImportMetergridDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImportMetergridDataRequest.displayName = 'proto.services.central.ImportMetergridDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImportMetergridDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ImportMetergridDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImportMetergridDataResponse.displayName = 'proto.services.central.ImportMetergridDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDataImportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDataImportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDataImportsRequest.displayName = 'proto.services.central.GetDataImportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDataImportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDataImportsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDataImportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDataImportsResponse.displayName = 'proto.services.central.GetDataImportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImovisMeterReading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ImovisMeterReading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImovisMeterReading.displayName = 'proto.services.central.ImovisMeterReading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreImovisReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.StoreImovisReadingsRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.StoreImovisReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreImovisReadingsRequest.displayName = 'proto.services.central.StoreImovisReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreImovisReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.StoreImovisReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreImovisReadingsResponse.displayName = 'proto.services.central.StoreImovisReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDeviceTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceTypesRequest.displayName = 'proto.services.central.GetDeviceTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDeviceTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDeviceTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceTypesResponse.displayName = 'proto.services.central.GetDeviceTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceProvidersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDeviceProvidersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceProvidersRequest.displayName = 'proto.services.central.GetDeviceProvidersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDeviceProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDeviceProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceProvidersResponse.displayName = 'proto.services.central.GetDeviceProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.RegisterDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.RegisterDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.RegisterDeviceRequest.displayName = 'proto.services.central.RegisterDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.RegisterDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.RegisterDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.RegisterDeviceResponse.displayName = 'proto.services.central.RegisterDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImportReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ImportReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImportReadingsRequest.displayName = 'proto.services.central.ImportReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ImportReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ImportReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ImportReadingsResponse.displayName = 'proto.services.central.ImportReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMyStromDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMyStromDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMyStromDevicesRequest.displayName = 'proto.services.central.GetMyStromDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MyStromDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MyStromDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MyStromDevice.displayName = 'proto.services.central.MyStromDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMyStromDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMyStromDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMyStromDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMyStromDevicesResponse.displayName = 'proto.services.central.GetMyStromDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTsunDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTsunDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTsunDevicesRequest.displayName = 'proto.services.central.GetTsunDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTsunDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTsunDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTsunDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTsunDevicesResponse.displayName = 'proto.services.central.GetTsunDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TsunDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TsunDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TsunDevice.displayName = 'proto.services.central.TsunDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreNonSmartMeterReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.StoreNonSmartMeterReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreNonSmartMeterReadingsRequest.displayName = 'proto.services.central.StoreNonSmartMeterReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreNonSmartMeterReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.StoreNonSmartMeterReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreNonSmartMeterReadingsResponse.displayName = 'proto.services.central.StoreNonSmartMeterReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetViessmannAuthConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetViessmannAuthConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetViessmannAuthConfigRequest.displayName = 'proto.services.central.GetViessmannAuthConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetViessmannAuthConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetViessmannAuthConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetViessmannAuthConfigResponse.displayName = 'proto.services.central.GetViessmannAuthConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAvailableViessmannDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAvailableViessmannDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAvailableViessmannDevicesRequest.displayName = 'proto.services.central.GetAvailableViessmannDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAvailableViessmannDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAvailableViessmannDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAvailableViessmannDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAvailableViessmannDevicesResponse.displayName = 'proto.services.central.GetAvailableViessmannDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ViessmannDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ViessmannDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ViessmannDevice.displayName = 'proto.services.central.ViessmannDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteViessmannClientInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteViessmannClientInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteViessmannClientInfoRequest.displayName = 'proto.services.central.DeleteViessmannClientInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteViessmannClientInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteViessmannClientInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteViessmannClientInfoResponse.displayName = 'proto.services.central.DeleteViessmannClientInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreReadingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.StoreReadingsRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.StoreReadingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreReadingsRequest.displayName = 'proto.services.central.StoreReadingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.StoreReadingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.StoreReadingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.StoreReadingsResponse.displayName = 'proto.services.central.StoreReadingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceReadingStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDeviceReadingStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceReadingStatsRequest.displayName = 'proto.services.central.GetDeviceReadingStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDeviceReadingStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDeviceReadingStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDeviceReadingStatsResponse.displayName = 'proto.services.central.GetDeviceReadingStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTibberHomesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTibberHomesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTibberHomesRequest.displayName = 'proto.services.central.GetTibberHomesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TibberHome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TibberHome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TibberHome.displayName = 'proto.services.central.TibberHome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTibberHomesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTibberHomesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTibberHomesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTibberHomesResponse.displayName = 'proto.services.central.GetTibberHomesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTibberAuthURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTibberAuthURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTibberAuthURLRequest.displayName = 'proto.services.central.GetTibberAuthURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTibberAuthURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTibberAuthURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTibberAuthURLResponse.displayName = 'proto.services.central.GetTibberAuthURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTibberAuthTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTibberAuthTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTibberAuthTokenRequest.displayName = 'proto.services.central.GetTibberAuthTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTibberAuthTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTibberAuthTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTibberAuthTokenResponse.displayName = 'proto.services.central.GetTibberAuthTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LobaroDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.LobaroDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LobaroDevice.displayName = 'proto.services.central.LobaroDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLobaroDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetLobaroDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLobaroDevicesRequest.displayName = 'proto.services.central.GetLobaroDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetLobaroDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetLobaroDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetLobaroDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetLobaroDevicesResponse.displayName = 'proto.services.central.GetLobaroDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarEdgeSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSolarEdgeSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarEdgeSitesRequest.displayName = 'proto.services.central.GetSolarEdgeSitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarEdgeDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSolarEdgeDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarEdgeDevicesRequest.displayName = 'proto.services.central.GetSolarEdgeDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SolarEdgeSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SolarEdgeSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SolarEdgeSite.displayName = 'proto.services.central.SolarEdgeSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarEdgeSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetSolarEdgeSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetSolarEdgeSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarEdgeSitesResponse.displayName = 'proto.services.central.GetSolarEdgeSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SolarEdgeDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SolarEdgeDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SolarEdgeDevice.displayName = 'proto.services.central.SolarEdgeDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarEdgeDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetSolarEdgeDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetSolarEdgeDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarEdgeDevicesResponse.displayName = 'proto.services.central.GetSolarEdgeDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetVirtualDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetVirtualDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetVirtualDeviceRequest.displayName = 'proto.services.central.GetVirtualDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetVirtualDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetVirtualDeviceResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetVirtualDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetVirtualDeviceResponse.displayName = 'proto.services.central.GetVirtualDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMetiundoMeteringPointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMetiundoMeteringPointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMetiundoMeteringPointsRequest.displayName = 'proto.services.central.GetMetiundoMeteringPointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMetiundoMeteringPointsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMetiundoMeteringPointsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMetiundoMeteringPointsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMetiundoMeteringPointsResponse.displayName = 'proto.services.central.GetMetiundoMeteringPointsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBitShakeMeterScriptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBitShakeMeterScriptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBitShakeMeterScriptRequest.displayName = 'proto.services.central.GetBitShakeMeterScriptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBitShakeMeterScriptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBitShakeMeterScriptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBitShakeMeterScriptResponse.displayName = 'proto.services.central.GetBitShakeMeterScriptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBitShakeSupportedMetersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetBitShakeSupportedMetersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBitShakeSupportedMetersRequest.displayName = 'proto.services.central.GetBitShakeSupportedMetersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetBitShakeSupportedMetersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetBitShakeSupportedMetersResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetBitShakeSupportedMetersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetBitShakeSupportedMetersResponse.displayName = 'proto.services.central.GetBitShakeSupportedMetersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.BitShakeMeter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.BitShakeMeter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.BitShakeMeter.displayName = 'proto.services.central.BitShakeMeter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetIometerQRCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetIometerQRCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetIometerQRCodeRequest.displayName = 'proto.services.central.GetIometerQRCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetIometerQRCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetIometerQRCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetIometerQRCodeResponse.displayName = 'proto.services.central.GetIometerQRCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMeteocontrolSystemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMeteocontrolSystemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMeteocontrolSystemsRequest.displayName = 'proto.services.central.GetMeteocontrolSystemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMeteocontrolSystemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMeteocontrolSystemsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMeteocontrolSystemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMeteocontrolSystemsResponse.displayName = 'proto.services.central.GetMeteocontrolSystemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MeteocontrolSystem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MeteocontrolSystem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MeteocontrolSystem.displayName = 'proto.services.central.MeteocontrolSystem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMeteocontrolInvertersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetMeteocontrolInvertersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMeteocontrolInvertersRequest.displayName = 'proto.services.central.GetMeteocontrolInvertersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetMeteocontrolInvertersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetMeteocontrolInvertersResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetMeteocontrolInvertersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetMeteocontrolInvertersResponse.displayName = 'proto.services.central.GetMeteocontrolInvertersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.MeteocontrolInverters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.MeteocontrolInverters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.MeteocontrolInverters.displayName = 'proto.services.central.MeteocontrolInverters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetExportDataTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetExportDataTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetExportDataTypesRequest.displayName = 'proto.services.central.GetExportDataTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetExportDataTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetExportDataTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetExportDataTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetExportDataTypesResponse.displayName = 'proto.services.central.GetExportDataTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.ExportDataTypeWithLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.ExportDataTypeWithLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.ExportDataTypeWithLabel.displayName = 'proto.services.central.ExportDataTypeWithLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarizeSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSolarizeSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarizeSitesRequest.displayName = 'proto.services.central.GetSolarizeSitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarizeSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetSolarizeSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetSolarizeSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarizeSitesResponse.displayName = 'proto.services.central.GetSolarizeSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarizeMetersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSolarizeMetersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarizeMetersRequest.displayName = 'proto.services.central.GetSolarizeMetersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSolarizeMetersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetSolarizeMetersResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetSolarizeMetersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSolarizeMetersResponse.displayName = 'proto.services.central.GetSolarizeMetersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceRequest}
 */
proto.services.central.GetDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceRequest;
  return proto.services.central.GetDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceRequest}
 */
proto.services.central.GetDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetDeviceRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDeviceRequest} returns this
 */
proto.services.central.GetDeviceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetDeviceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDeviceRequest} returns this
 */
proto.services.central.GetDeviceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDeviceRequest} returns this
 */
proto.services.central.GetDeviceRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && types_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceResponse}
 */
proto.services.central.GetDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceResponse;
  return proto.services.central.GetDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceResponse}
 */
proto.services.central.GetDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.Device device = 1;
 * @return {?proto.types.Device}
 */
proto.services.central.GetDeviceResponse.prototype.getDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.services.central.GetDeviceResponse} returns this
*/
proto.services.central.GetDeviceResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDeviceResponse} returns this
 */
proto.services.central.GetDeviceResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deleteForAll: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    easeePinCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteDeviceRequest}
 */
proto.services.central.DeleteDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteDeviceRequest;
  return proto.services.central.DeleteDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteDeviceRequest}
 */
proto.services.central.DeleteDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteForAll(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEaseePinCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeleteForAll();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.DeleteDeviceRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteDeviceRequest} returns this
 */
proto.services.central.DeleteDeviceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.DeleteDeviceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteDeviceRequest} returns this
 */
proto.services.central.DeleteDeviceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool delete_for_all = 3;
 * @return {boolean}
 */
proto.services.central.DeleteDeviceRequest.prototype.getDeleteForAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.DeleteDeviceRequest} returns this
 */
proto.services.central.DeleteDeviceRequest.prototype.setDeleteForAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string easee_pin_code = 4;
 * @return {string}
 */
proto.services.central.DeleteDeviceRequest.prototype.getEaseePinCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteDeviceRequest} returns this
 */
proto.services.central.DeleteDeviceRequest.prototype.setEaseePinCode = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeleteDeviceRequest} returns this
 */
proto.services.central.DeleteDeviceRequest.prototype.clearEaseePinCode = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteDeviceRequest.prototype.hasEaseePinCode = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteDeviceResponse}
 */
proto.services.central.DeleteDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteDeviceResponse;
  return proto.services.central.DeleteDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteDeviceResponse}
 */
proto.services.central.DeleteDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetUserDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetUserDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetUserDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetUserDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetUserDevicesRequest}
 */
proto.services.central.GetUserDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetUserDevicesRequest;
  return proto.services.central.GetUserDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetUserDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetUserDevicesRequest}
 */
proto.services.central.GetUserDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetUserDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetUserDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetUserDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetUserDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.services.central.GetUserDevicesRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetUserDevicesRequest} returns this
 */
proto.services.central.GetUserDevicesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetUserDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetUserDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetUserDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetUserDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetUserDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    types_device_pb.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetUserDevicesResponse}
 */
proto.services.central.GetUserDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetUserDevicesResponse;
  return proto.services.central.GetUserDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetUserDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetUserDevicesResponse}
 */
proto.services.central.GetUserDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetUserDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetUserDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetUserDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetUserDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.Device devices = 1;
 * @return {!Array<!proto.types.Device>}
 */
proto.services.central.GetUserDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.types.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {!Array<!proto.types.Device>} value
 * @return {!proto.services.central.GetUserDevicesResponse} returns this
*/
proto.services.central.GetUserDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Device}
 */
proto.services.central.GetUserDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetUserDevicesResponse} returns this
 */
proto.services.central.GetUserDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDevicesInSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDevicesInSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDevicesInSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && types_pagination_pb.Pagination.toObject(includeInstance, f),
    includeChildSpaces: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDevicesInSpaceRequest}
 */
proto.services.central.GetDevicesInSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDevicesInSpaceRequest;
  return proto.services.central.GetDevicesInSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDevicesInSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDevicesInSpaceRequest}
 */
proto.services.central.GetDevicesInSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_pagination_pb.Pagination;
      reader.readMessage(value,types_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeChildSpaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDevicesInSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDevicesInSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDevicesInSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getIncludeChildSpaces();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDevicesInSpaceRequest} returns this
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Pagination pagination = 2;
 * @return {?proto.types.Pagination}
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.getPagination = function() {
  return /** @type{?proto.types.Pagination} */ (
    jspb.Message.getWrapperField(this, types_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.types.Pagination|undefined} value
 * @return {!proto.services.central.GetDevicesInSpaceRequest} returns this
*/
proto.services.central.GetDevicesInSpaceRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDevicesInSpaceRequest} returns this
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool include_child_spaces = 3;
 * @return {boolean}
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.getIncludeChildSpaces = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetDevicesInSpaceRequest} returns this
 */
proto.services.central.GetDevicesInSpaceRequest.prototype.setIncludeChildSpaces = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDevicesInSpaceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDevicesInSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDevicesInSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDevicesInSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    types_device_pb.Device.toObject, includeInstance),
    totalNumPages: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDevicesInSpaceResponse}
 */
proto.services.central.GetDevicesInSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDevicesInSpaceResponse;
  return proto.services.central.GetDevicesInSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDevicesInSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDevicesInSpaceResponse}
 */
proto.services.central.GetDevicesInSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDevicesInSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDevicesInSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDevicesInSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated types.Device devices = 1;
 * @return {!Array<!proto.types.Device>}
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.types.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {!Array<!proto.types.Device>} value
 * @return {!proto.services.central.GetDevicesInSpaceResponse} returns this
*/
proto.services.central.GetDevicesInSpaceResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Device}
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDevicesInSpaceResponse} returns this
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional int32 total_num_pages = 2;
 * @return {number}
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.getTotalNumPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetDevicesInSpaceResponse} returns this
 */
proto.services.central.GetDevicesInSpaceResponse.prototype.setTotalNumPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ConnectSmartMeAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ConnectSmartMeAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ConnectSmartMeAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectSmartMeAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ConnectSmartMeAccountRequest}
 */
proto.services.central.ConnectSmartMeAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ConnectSmartMeAccountRequest;
  return proto.services.central.ConnectSmartMeAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ConnectSmartMeAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ConnectSmartMeAccountRequest}
 */
proto.services.central.ConnectSmartMeAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ConnectSmartMeAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ConnectSmartMeAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ConnectSmartMeAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectSmartMeAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.services.central.ConnectSmartMeAccountRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ConnectSmartMeAccountRequest} returns this
 */
proto.services.central.ConnectSmartMeAccountRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ConnectSmartMeAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ConnectSmartMeAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ConnectSmartMeAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ConnectSmartMeAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectSmartMeAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    smartMeDevicesList: jspb.Message.toObjectList(msg.getSmartMeDevicesList(),
    proto.services.central.SmartMeDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ConnectSmartMeAccountResponse}
 */
proto.services.central.ConnectSmartMeAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ConnectSmartMeAccountResponse;
  return proto.services.central.ConnectSmartMeAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ConnectSmartMeAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ConnectSmartMeAccountResponse}
 */
proto.services.central.ConnectSmartMeAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.SmartMeDevice;
      reader.readMessage(value,proto.services.central.SmartMeDevice.deserializeBinaryFromReader);
      msg.addSmartMeDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ConnectSmartMeAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ConnectSmartMeAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ConnectSmartMeAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectSmartMeAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartMeDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.SmartMeDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SmartMeDevice smart_me_devices = 1;
 * @return {!Array<!proto.services.central.SmartMeDevice>}
 */
proto.services.central.ConnectSmartMeAccountResponse.prototype.getSmartMeDevicesList = function() {
  return /** @type{!Array<!proto.services.central.SmartMeDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.SmartMeDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.SmartMeDevice>} value
 * @return {!proto.services.central.ConnectSmartMeAccountResponse} returns this
*/
proto.services.central.ConnectSmartMeAccountResponse.prototype.setSmartMeDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.SmartMeDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.SmartMeDevice}
 */
proto.services.central.ConnectSmartMeAccountResponse.prototype.addSmartMeDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.SmartMeDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ConnectSmartMeAccountResponse} returns this
 */
proto.services.central.ConnectSmartMeAccountResponse.prototype.clearSmartMeDevicesList = function() {
  return this.setSmartMeDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SmartMeDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SmartMeDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SmartMeDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SmartMeDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serial: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSelectable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SmartMeDevice}
 */
proto.services.central.SmartMeDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SmartMeDevice;
  return proto.services.central.SmartMeDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SmartMeDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SmartMeDevice}
 */
proto.services.central.SmartMeDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSerial(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSelectable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SmartMeDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SmartMeDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SmartMeDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SmartMeDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSerial();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsSelectable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.SmartMeDevice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SmartMeDevice} returns this
 */
proto.services.central.SmartMeDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.central.SmartMeDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SmartMeDevice} returns this
 */
proto.services.central.SmartMeDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 serial = 3;
 * @return {number}
 */
proto.services.central.SmartMeDevice.prototype.getSerial = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.SmartMeDevice} returns this
 */
proto.services.central.SmartMeDevice.prototype.setSerial = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_selectable = 4;
 * @return {boolean}
 */
proto.services.central.SmartMeDevice.prototype.getIsSelectable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.SmartMeDevice} returns this
 */
proto.services.central.SmartMeDevice.prototype.setIsSelectable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSmartMeAuthURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSmartMeAuthURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSmartMeAuthURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSmartMeAuthURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSmartMeAuthURLRequest}
 */
proto.services.central.GetSmartMeAuthURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSmartMeAuthURLRequest;
  return proto.services.central.GetSmartMeAuthURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSmartMeAuthURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSmartMeAuthURLRequest}
 */
proto.services.central.GetSmartMeAuthURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSmartMeAuthURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSmartMeAuthURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSmartMeAuthURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSmartMeAuthURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.services.central.GetSmartMeAuthURLRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSmartMeAuthURLRequest} returns this
 */
proto.services.central.GetSmartMeAuthURLRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSmartMeAuthURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSmartMeAuthURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSmartMeAuthURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSmartMeAuthURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSmartMeAuthURLResponse}
 */
proto.services.central.GetSmartMeAuthURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSmartMeAuthURLResponse;
  return proto.services.central.GetSmartMeAuthURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSmartMeAuthURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSmartMeAuthURLResponse}
 */
proto.services.central.GetSmartMeAuthURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSmartMeAuthURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSmartMeAuthURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSmartMeAuthURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSmartMeAuthURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_url = 1;
 * @return {string}
 */
proto.services.central.GetSmartMeAuthURLResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSmartMeAuthURLResponse} returns this
 */
proto.services.central.GetSmartMeAuthURLResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMainDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMainDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMainDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMainDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMainDeviceRequest}
 */
proto.services.central.GetMainDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMainDeviceRequest;
  return proto.services.central.GetMainDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMainDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMainDeviceRequest}
 */
proto.services.central.GetMainDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMainDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMainDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMainDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMainDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetMainDeviceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMainDeviceRequest} returns this
 */
proto.services.central.GetMainDeviceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Device.Medium medium = 2;
 * @return {!proto.types.Device.Medium}
 */
proto.services.central.GetMainDeviceRequest.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.services.central.GetMainDeviceRequest} returns this
 */
proto.services.central.GetMainDeviceRequest.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMainDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMainDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMainDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMainDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && types_device_pb.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMainDeviceResponse}
 */
proto.services.central.GetMainDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMainDeviceResponse;
  return proto.services.central.GetMainDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMainDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMainDeviceResponse}
 */
proto.services.central.GetMainDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMainDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMainDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMainDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMainDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.Device device = 1;
 * @return {?proto.types.Device}
 */
proto.services.central.GetMainDeviceResponse.prototype.getDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.services.central.GetMainDeviceResponse} returns this
*/
proto.services.central.GetMainDeviceResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetMainDeviceResponse} returns this
 */
proto.services.central.GetMainDeviceResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetMainDeviceResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddDeviceToSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddDeviceToSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddDeviceToSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isMainDevice: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddDeviceToSpaceRequest}
 */
proto.services.central.AddDeviceToSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddDeviceToSpaceRequest;
  return proto.services.central.AddDeviceToSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddDeviceToSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddDeviceToSpaceRequest}
 */
proto.services.central.AddDeviceToSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMainDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddDeviceToSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddDeviceToSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddDeviceToSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsMainDevice();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddDeviceToSpaceRequest} returns this
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddDeviceToSpaceRequest} returns this
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_main_device = 3;
 * @return {boolean}
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.getIsMainDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.AddDeviceToSpaceRequest} returns this
 */
proto.services.central.AddDeviceToSpaceRequest.prototype.setIsMainDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddDeviceToSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddDeviceToSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddDeviceToSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddDeviceToSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddDeviceToSpaceResponse}
 */
proto.services.central.AddDeviceToSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddDeviceToSpaceResponse;
  return proto.services.central.AddDeviceToSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddDeviceToSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddDeviceToSpaceResponse}
 */
proto.services.central.AddDeviceToSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddDeviceToSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddDeviceToSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddDeviceToSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddDeviceToSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateDeviceDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateDeviceDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateDeviceDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceDetails: (f = msg.getDeviceDetails()) && proto.services.central.DeviceDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateDeviceDetailsRequest}
 */
proto.services.central.UpdateDeviceDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateDeviceDetailsRequest;
  return proto.services.central.UpdateDeviceDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateDeviceDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateDeviceDetailsRequest}
 */
proto.services.central.UpdateDeviceDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = new proto.services.central.DeviceDetails;
      reader.readMessage(value,proto.services.central.DeviceDetails.deserializeBinaryFromReader);
      msg.setDeviceDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateDeviceDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateDeviceDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateDeviceDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.central.DeviceDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateDeviceDetailsRequest} returns this
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateDeviceDetailsRequest} returns this
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceDetails device_details = 3;
 * @return {?proto.services.central.DeviceDetails}
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.getDeviceDetails = function() {
  return /** @type{?proto.services.central.DeviceDetails} */ (
    jspb.Message.getWrapperField(this, proto.services.central.DeviceDetails, 3));
};


/**
 * @param {?proto.services.central.DeviceDetails|undefined} value
 * @return {!proto.services.central.UpdateDeviceDetailsRequest} returns this
*/
proto.services.central.UpdateDeviceDetailsRequest.prototype.setDeviceDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateDeviceDetailsRequest} returns this
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.clearDeviceDetails = function() {
  return this.setDeviceDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateDeviceDetailsRequest.prototype.hasDeviceDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeviceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeviceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeviceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    isMainDevice: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    deviceName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeviceDetails}
 */
proto.services.central.DeviceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeviceDetails;
  return proto.services.central.DeviceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeviceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeviceDetails}
 */
proto.services.central.DeviceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMainDevice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeviceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeviceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeviceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeviceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_main_device = 1;
 * @return {boolean}
 */
proto.services.central.DeviceDetails.prototype.getIsMainDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.DeviceDetails} returns this
 */
proto.services.central.DeviceDetails.prototype.setIsMainDevice = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeviceDetails} returns this
 */
proto.services.central.DeviceDetails.prototype.clearIsMainDevice = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceDetails.prototype.hasIsMainDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string device_name = 2;
 * @return {string}
 */
proto.services.central.DeviceDetails.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeviceDetails} returns this
 */
proto.services.central.DeviceDetails.prototype.setDeviceName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeviceDetails} returns this
 */
proto.services.central.DeviceDetails.prototype.clearDeviceName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeviceDetails.prototype.hasDeviceName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateDeviceDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateDeviceDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateDeviceDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateDeviceDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateDeviceDetailsResponse}
 */
proto.services.central.UpdateDeviceDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateDeviceDetailsResponse;
  return proto.services.central.UpdateDeviceDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateDeviceDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateDeviceDetailsResponse}
 */
proto.services.central.UpdateDeviceDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateDeviceDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateDeviceDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateDeviceDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateDeviceDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetPowerfoxAuthURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetPowerfoxAuthURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetPowerfoxAuthURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPowerfoxAuthURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetPowerfoxAuthURLRequest}
 */
proto.services.central.GetPowerfoxAuthURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetPowerfoxAuthURLRequest;
  return proto.services.central.GetPowerfoxAuthURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetPowerfoxAuthURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetPowerfoxAuthURLRequest}
 */
proto.services.central.GetPowerfoxAuthURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetPowerfoxAuthURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetPowerfoxAuthURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetPowerfoxAuthURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPowerfoxAuthURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.services.central.GetPowerfoxAuthURLRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetPowerfoxAuthURLRequest} returns this
 */
proto.services.central.GetPowerfoxAuthURLRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetPowerfoxAuthURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetPowerfoxAuthURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetPowerfoxAuthURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPowerfoxAuthURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetPowerfoxAuthURLResponse}
 */
proto.services.central.GetPowerfoxAuthURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetPowerfoxAuthURLResponse;
  return proto.services.central.GetPowerfoxAuthURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetPowerfoxAuthURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetPowerfoxAuthURLResponse}
 */
proto.services.central.GetPowerfoxAuthURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetPowerfoxAuthURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetPowerfoxAuthURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetPowerfoxAuthURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPowerfoxAuthURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_url = 1;
 * @return {string}
 */
proto.services.central.GetPowerfoxAuthURLResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetPowerfoxAuthURLResponse} returns this
 */
proto.services.central.GetPowerfoxAuthURLResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ConnectPowerfoxAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ConnectPowerfoxAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectPowerfoxAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isMainDevice: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    deviceCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest}
 */
proto.services.central.ConnectPowerfoxAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ConnectPowerfoxAccountRequest;
  return proto.services.central.ConnectPowerfoxAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ConnectPowerfoxAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest}
 */
proto.services.central.ConnectPowerfoxAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMainDevice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ConnectPowerfoxAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ConnectPowerfoxAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectPowerfoxAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsMainDevice();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDeviceCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest} returns this
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest} returns this
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_name = 3;
 * @return {string}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest} returns this
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_main_device = 4;
 * @return {boolean}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.getIsMainDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest} returns this
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.setIsMainDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 device_count = 5;
 * @return {number}
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.getDeviceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ConnectPowerfoxAccountRequest} returns this
 */
proto.services.central.ConnectPowerfoxAccountRequest.prototype.setDeviceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ConnectPowerfoxAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ConnectPowerfoxAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ConnectPowerfoxAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectPowerfoxAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ConnectPowerfoxAccountResponse}
 */
proto.services.central.ConnectPowerfoxAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ConnectPowerfoxAccountResponse;
  return proto.services.central.ConnectPowerfoxAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ConnectPowerfoxAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ConnectPowerfoxAccountResponse}
 */
proto.services.central.ConnectPowerfoxAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ConnectPowerfoxAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ConnectPowerfoxAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ConnectPowerfoxAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ConnectPowerfoxAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetShellyPortalInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetShellyPortalInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetShellyPortalInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyPortalInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetShellyPortalInformationRequest}
 */
proto.services.central.GetShellyPortalInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetShellyPortalInformationRequest;
  return proto.services.central.GetShellyPortalInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetShellyPortalInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetShellyPortalInformationRequest}
 */
proto.services.central.GetShellyPortalInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetShellyPortalInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetShellyPortalInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetShellyPortalInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyPortalInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetShellyPortalInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetShellyPortalInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetShellyPortalInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyPortalInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetShellyPortalInformationResponse}
 */
proto.services.central.GetShellyPortalInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetShellyPortalInformationResponse;
  return proto.services.central.GetShellyPortalInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetShellyPortalInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetShellyPortalInformationResponse}
 */
proto.services.central.GetShellyPortalInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetShellyPortalInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetShellyPortalInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetShellyPortalInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyPortalInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.services.central.GetShellyPortalInformationResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetShellyPortalInformationResponse} returns this
 */
proto.services.central.GetShellyPortalInformationResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetShellyUserDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetShellyUserDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetShellyUserDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyUserDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetShellyUserDevicesRequest}
 */
proto.services.central.GetShellyUserDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetShellyUserDevicesRequest;
  return proto.services.central.GetShellyUserDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetShellyUserDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetShellyUserDevicesRequest}
 */
proto.services.central.GetShellyUserDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Device.Type} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetShellyUserDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetShellyUserDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetShellyUserDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyUserDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.types.Device.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional types.Device.Type device_type = 1;
 * @return {!proto.types.Device.Type}
 */
proto.services.central.GetShellyUserDevicesRequest.prototype.getDeviceType = function() {
  return /** @type {!proto.types.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Device.Type} value
 * @return {!proto.services.central.GetShellyUserDevicesRequest} returns this
 */
proto.services.central.GetShellyUserDevicesRequest.prototype.setDeviceType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetShellyUserDevicesRequest} returns this
 */
proto.services.central.GetShellyUserDevicesRequest.prototype.clearDeviceType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetShellyUserDevicesRequest.prototype.hasDeviceType = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ShellyDevice.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ShellyDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ShellyDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ShellyDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ShellyDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    channelNamesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    channelDirectionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    title: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 11, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isDirectionsEditable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hasMultiChannelsSupport: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ShellyDevice}
 */
proto.services.central.ShellyDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ShellyDevice;
  return proto.services.central.ShellyDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ShellyDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ShellyDevice}
 */
proto.services.central.ShellyDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addChannelNames(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.types.Direction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannelDirections(values[i]);
      }
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirectionsEditable(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMultiChannelsSupport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ShellyDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ShellyDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ShellyDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ShellyDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChannelNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getChannelDirectionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsDirectionsEditable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHasMultiChannelsSupport();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_type = 3;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_code = 4;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string channel_names = 5;
 * @return {!Array<string>}
 */
proto.services.central.ShellyDevice.prototype.getChannelNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setChannelNamesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.addChannelNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.clearChannelNamesList = function() {
  return this.setChannelNamesList([]);
};


/**
 * repeated types.Direction channel_directions = 6;
 * @return {!Array<!proto.types.Direction>}
 */
proto.services.central.ShellyDevice.prototype.getChannelDirectionsList = function() {
  return /** @type {!Array<!proto.types.Direction>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.types.Direction>} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setChannelDirectionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.types.Direction} value
 * @param {number=} opt_index
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.addChannelDirections = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.clearChannelDirectionsList = function() {
  return this.setChannelDirectionsList([]);
};


/**
 * optional string title = 10;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string subtitle = 11;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string imageURL = 12;
 * @return {string}
 */
proto.services.central.ShellyDevice.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_directions_editable = 13;
 * @return {boolean}
 */
proto.services.central.ShellyDevice.prototype.getIsDirectionsEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setIsDirectionsEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool has_multi_channels_support = 14;
 * @return {boolean}
 */
proto.services.central.ShellyDevice.prototype.getHasMultiChannelsSupport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ShellyDevice} returns this
 */
proto.services.central.ShellyDevice.prototype.setHasMultiChannelsSupport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetShellyUserDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetShellyUserDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetShellyUserDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetShellyUserDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyUserDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.ShellyDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetShellyUserDevicesResponse}
 */
proto.services.central.GetShellyUserDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetShellyUserDevicesResponse;
  return proto.services.central.GetShellyUserDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetShellyUserDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetShellyUserDevicesResponse}
 */
proto.services.central.GetShellyUserDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.ShellyDevice;
      reader.readMessage(value,proto.services.central.ShellyDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetShellyUserDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetShellyUserDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetShellyUserDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyUserDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.ShellyDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ShellyDevice devices = 1;
 * @return {!Array<!proto.services.central.ShellyDevice>}
 */
proto.services.central.GetShellyUserDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.ShellyDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ShellyDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.ShellyDevice>} value
 * @return {!proto.services.central.GetShellyUserDevicesResponse} returns this
*/
proto.services.central.GetShellyUserDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.ShellyDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ShellyDevice}
 */
proto.services.central.GetShellyUserDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.ShellyDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetShellyUserDevicesResponse} returns this
 */
proto.services.central.GetShellyUserDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.UpdateShellyDeviceStatusRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateShellyDeviceStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateShellyDeviceStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    zfUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deviceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    action: jspb.Message.getFieldWithDefault(msg, 6, ""),
    host: jspb.Message.getFieldWithDefault(msg, 7, ""),
    nameList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateShellyDeviceStatusRequest;
  return proto.services.central.UpdateShellyDeviceStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateShellyDeviceStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setZfUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateShellyDeviceStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateShellyDeviceStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZfUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional string zf_user_id = 1;
 * @return {string}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getZfUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setZfUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 device_id = 3;
 * @return {number}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string device_type = 4;
 * @return {string}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_code = 5;
 * @return {string}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string action = 6;
 * @return {string}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string host = 7;
 * @return {string}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string name = 8;
 * @return {!Array<string>}
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.UpdateShellyDeviceStatusRequest} returns this
 */
proto.services.central.UpdateShellyDeviceStatusRequest.prototype.clearNameList = function() {
  return this.setNameList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateShellyDeviceStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateShellyDeviceStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateShellyDeviceStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateShellyDeviceStatusResponse}
 */
proto.services.central.UpdateShellyDeviceStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateShellyDeviceStatusResponse;
  return proto.services.central.UpdateShellyDeviceStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateShellyDeviceStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateShellyDeviceStatusResponse}
 */
proto.services.central.UpdateShellyDeviceStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateShellyDeviceStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateShellyDeviceStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateShellyDeviceStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetShellyDeviceDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetShellyDeviceDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetShellyDeviceDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyDeviceDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetShellyDeviceDetailsRequest}
 */
proto.services.central.GetShellyDeviceDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetShellyDeviceDetailsRequest;
  return proto.services.central.GetShellyDeviceDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetShellyDeviceDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetShellyDeviceDetailsRequest}
 */
proto.services.central.GetShellyDeviceDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetShellyDeviceDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetShellyDeviceDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetShellyDeviceDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyDeviceDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetShellyDeviceDetailsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetShellyDeviceDetailsRequest} returns this
 */
proto.services.central.GetShellyDeviceDetailsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetShellyDeviceDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetShellyDeviceDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetShellyDeviceDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyDeviceDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shellyDevice: (f = msg.getShellyDevice()) && proto.services.central.ShellyDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetShellyDeviceDetailsResponse}
 */
proto.services.central.GetShellyDeviceDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetShellyDeviceDetailsResponse;
  return proto.services.central.GetShellyDeviceDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetShellyDeviceDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetShellyDeviceDetailsResponse}
 */
proto.services.central.GetShellyDeviceDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.ShellyDevice;
      reader.readMessage(value,proto.services.central.ShellyDevice.deserializeBinaryFromReader);
      msg.setShellyDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetShellyDeviceDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetShellyDeviceDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetShellyDeviceDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetShellyDeviceDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShellyDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.ShellyDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShellyDevice shelly_device = 1;
 * @return {?proto.services.central.ShellyDevice}
 */
proto.services.central.GetShellyDeviceDetailsResponse.prototype.getShellyDevice = function() {
  return /** @type{?proto.services.central.ShellyDevice} */ (
    jspb.Message.getWrapperField(this, proto.services.central.ShellyDevice, 1));
};


/**
 * @param {?proto.services.central.ShellyDevice|undefined} value
 * @return {!proto.services.central.GetShellyDeviceDetailsResponse} returns this
*/
proto.services.central.GetShellyDeviceDetailsResponse.prototype.setShellyDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetShellyDeviceDetailsResponse} returns this
 */
proto.services.central.GetShellyDeviceDetailsResponse.prototype.clearShellyDevice = function() {
  return this.setShellyDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetShellyDeviceDetailsResponse.prototype.hasShellyDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shellyDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channnelDirectionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateShellyDeviceChannelDirectionsRequest;
  return proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShellyDeviceId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.types.Direction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannnelDirections(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShellyDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannnelDirectionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string shelly_device_id = 1;
 * @return {string}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.getShellyDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} returns this
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.setShellyDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated types.Direction channnel_directions = 2;
 * @return {!Array<!proto.types.Direction>}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.getChannnelDirectionsList = function() {
  return /** @type {!Array<!proto.types.Direction>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.types.Direction>} value
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} returns this
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.setChannnelDirectionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.types.Direction} value
 * @param {number=} opt_index
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} returns this
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.addChannnelDirections = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsRequest} returns this
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsRequest.prototype.clearChannnelDirectionsList = function() {
  return this.setChannnelDirectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateShellyDeviceChannelDirectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsResponse}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateShellyDeviceChannelDirectionsResponse;
  return proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateShellyDeviceChannelDirectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateShellyDeviceChannelDirectionsResponse}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateShellyDeviceChannelDirectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateShellyDeviceChannelDirectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBlueMeteringDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBlueMeteringDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBlueMeteringDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bluemeteringApiKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    doNotSaveApiKey: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBlueMeteringDevicesRequest}
 */
proto.services.central.GetBlueMeteringDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBlueMeteringDevicesRequest;
  return proto.services.central.GetBlueMeteringDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBlueMeteringDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBlueMeteringDevicesRequest}
 */
proto.services.central.GetBlueMeteringDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBluemeteringApiKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotSaveApiKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBlueMeteringDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBlueMeteringDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBlueMeteringDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDoNotSaveApiKey();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string bluemetering_api_key = 1;
 * @return {string}
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.getBluemeteringApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetBlueMeteringDevicesRequest} returns this
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.setBluemeteringApiKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetBlueMeteringDevicesRequest} returns this
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.clearBluemeteringApiKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.hasBluemeteringApiKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool do_not_save_api_key = 2;
 * @return {boolean}
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.getDoNotSaveApiKey = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetBlueMeteringDevicesRequest} returns this
 */
proto.services.central.GetBlueMeteringDevicesRequest.prototype.setDoNotSaveApiKey = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetBlueMeteringDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBlueMeteringDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBlueMeteringDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBlueMeteringDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBlueMeteringDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.BlueMeteringDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBlueMeteringDevicesResponse}
 */
proto.services.central.GetBlueMeteringDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBlueMeteringDevicesResponse;
  return proto.services.central.GetBlueMeteringDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBlueMeteringDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBlueMeteringDevicesResponse}
 */
proto.services.central.GetBlueMeteringDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.BlueMeteringDevice;
      reader.readMessage(value,proto.services.central.BlueMeteringDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBlueMeteringDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBlueMeteringDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBlueMeteringDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBlueMeteringDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.BlueMeteringDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlueMeteringDevice devices = 1;
 * @return {!Array<!proto.services.central.BlueMeteringDevice>}
 */
proto.services.central.GetBlueMeteringDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.BlueMeteringDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.BlueMeteringDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.BlueMeteringDevice>} value
 * @return {!proto.services.central.GetBlueMeteringDevicesResponse} returns this
*/
proto.services.central.GetBlueMeteringDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.BlueMeteringDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.BlueMeteringDevice}
 */
proto.services.central.GetBlueMeteringDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.BlueMeteringDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetBlueMeteringDevicesResponse} returns this
 */
proto.services.central.GetBlueMeteringDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ImportBluemeteringDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImportBluemeteringDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImportBluemeteringDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportBluemeteringDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.BlueMeteringDevice.toObject, includeInstance),
    doNotAddOwnerToSpace: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    bluemeteringApiKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImportBluemeteringDataRequest}
 */
proto.services.central.ImportBluemeteringDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImportBluemeteringDataRequest;
  return proto.services.central.ImportBluemeteringDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImportBluemeteringDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImportBluemeteringDataRequest}
 */
proto.services.central.ImportBluemeteringDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.BlueMeteringDevice;
      reader.readMessage(value,proto.services.central.BlueMeteringDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotAddOwnerToSpace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBluemeteringApiKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImportBluemeteringDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImportBluemeteringDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportBluemeteringDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.BlueMeteringDevice.serializeBinaryToWriter
    );
  }
  f = message.getDoNotAddOwnerToSpace();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated BlueMeteringDevice devices = 1;
 * @return {!Array<!proto.services.central.BlueMeteringDevice>}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.BlueMeteringDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.BlueMeteringDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.BlueMeteringDevice>} value
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
*/
proto.services.central.ImportBluemeteringDataRequest.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.BlueMeteringDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.BlueMeteringDevice}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.BlueMeteringDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional bool do_not_add_owner_to_space = 2;
 * @return {boolean}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.getDoNotAddOwnerToSpace = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.setDoNotAddOwnerToSpace = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string bluemetering_api_key = 3;
 * @return {string}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.getBluemeteringApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.setBluemeteringApiKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.clearBluemeteringApiKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.hasBluemeteringApiKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string partner_id = 4;
 * @return {string}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ImportBluemeteringDataRequest} returns this
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ImportBluemeteringDataRequest.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImportBluemeteringDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImportBluemeteringDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImportBluemeteringDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportBluemeteringDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImportBluemeteringDataResponse}
 */
proto.services.central.ImportBluemeteringDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImportBluemeteringDataResponse;
  return proto.services.central.ImportBluemeteringDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImportBluemeteringDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImportBluemeteringDataResponse}
 */
proto.services.central.ImportBluemeteringDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImportBluemeteringDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImportBluemeteringDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImportBluemeteringDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportBluemeteringDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBluemeteringApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBluemeteringApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBluemeteringApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBluemeteringApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBluemeteringApiKeyRequest}
 */
proto.services.central.GetBluemeteringApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBluemeteringApiKeyRequest;
  return proto.services.central.GetBluemeteringApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBluemeteringApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBluemeteringApiKeyRequest}
 */
proto.services.central.GetBluemeteringApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBluemeteringApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBluemeteringApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBluemeteringApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBluemeteringApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBluemeteringApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBluemeteringApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBluemeteringApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBluemeteringApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBluemeteringApiKeyResponse}
 */
proto.services.central.GetBluemeteringApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBluemeteringApiKeyResponse;
  return proto.services.central.GetBluemeteringApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBluemeteringApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBluemeteringApiKeyResponse}
 */
proto.services.central.GetBluemeteringApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBluemeteringApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBluemeteringApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBluemeteringApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBluemeteringApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string api_key = 1;
 * @return {string}
 */
proto.services.central.GetBluemeteringApiKeyResponse.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetBluemeteringApiKeyResponse} returns this
 */
proto.services.central.GetBluemeteringApiKeyResponse.prototype.setApiKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetBluemeteringApiKeyResponse} returns this
 */
proto.services.central.GetBluemeteringApiKeyResponse.prototype.clearApiKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetBluemeteringApiKeyResponse.prototype.hasApiKey = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.BlueMeteringDevice.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.BlueMeteringDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.BlueMeteringDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.BlueMeteringDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BlueMeteringDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meloId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tafsList: jspb.Message.toObjectList(msg.getTafsList(),
    proto.services.central.BlueMeteringDevice.Taf.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.BlueMeteringDevice}
 */
proto.services.central.BlueMeteringDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.BlueMeteringDevice;
  return proto.services.central.BlueMeteringDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.BlueMeteringDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.BlueMeteringDevice}
 */
proto.services.central.BlueMeteringDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeloId(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    case 4:
      var value = new proto.services.central.BlueMeteringDevice.Taf;
      reader.readMessage(value,proto.services.central.BlueMeteringDevice.Taf.deserializeBinaryFromReader);
      msg.addTafs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.BlueMeteringDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.BlueMeteringDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.BlueMeteringDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BlueMeteringDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeloId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.types.Device.Medium} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTafsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.services.central.BlueMeteringDevice.Taf.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.BlueMeteringDevice.Taf.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.BlueMeteringDevice.Taf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.BlueMeteringDevice.Taf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BlueMeteringDevice.Taf.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    captureperiod: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.BlueMeteringDevice.Taf}
 */
proto.services.central.BlueMeteringDevice.Taf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.BlueMeteringDevice.Taf;
  return proto.services.central.BlueMeteringDevice.Taf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.BlueMeteringDevice.Taf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.BlueMeteringDevice.Taf}
 */
proto.services.central.BlueMeteringDevice.Taf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCaptureperiod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.BlueMeteringDevice.Taf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.BlueMeteringDevice.Taf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.BlueMeteringDevice.Taf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BlueMeteringDevice.Taf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCaptureperiod();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.services.central.BlueMeteringDevice.Taf.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.BlueMeteringDevice.Taf} returns this
 */
proto.services.central.BlueMeteringDevice.Taf.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 capturePeriod = 2;
 * @return {number}
 */
proto.services.central.BlueMeteringDevice.Taf.prototype.getCaptureperiod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.BlueMeteringDevice.Taf} returns this
 */
proto.services.central.BlueMeteringDevice.Taf.prototype.setCaptureperiod = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.services.central.BlueMeteringDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BlueMeteringDevice} returns this
 */
proto.services.central.BlueMeteringDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string melo_id = 2;
 * @return {string}
 */
proto.services.central.BlueMeteringDevice.prototype.getMeloId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BlueMeteringDevice} returns this
 */
proto.services.central.BlueMeteringDevice.prototype.setMeloId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional types.Device.Medium medium = 3;
 * @return {!proto.types.Device.Medium}
 */
proto.services.central.BlueMeteringDevice.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.services.central.BlueMeteringDevice} returns this
 */
proto.services.central.BlueMeteringDevice.prototype.setMedium = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.BlueMeteringDevice} returns this
 */
proto.services.central.BlueMeteringDevice.prototype.clearMedium = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.BlueMeteringDevice.prototype.hasMedium = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Taf tafs = 4;
 * @return {!Array<!proto.services.central.BlueMeteringDevice.Taf>}
 */
proto.services.central.BlueMeteringDevice.prototype.getTafsList = function() {
  return /** @type{!Array<!proto.services.central.BlueMeteringDevice.Taf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.BlueMeteringDevice.Taf, 4));
};


/**
 * @param {!Array<!proto.services.central.BlueMeteringDevice.Taf>} value
 * @return {!proto.services.central.BlueMeteringDevice} returns this
*/
proto.services.central.BlueMeteringDevice.prototype.setTafsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.services.central.BlueMeteringDevice.Taf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.BlueMeteringDevice.Taf}
 */
proto.services.central.BlueMeteringDevice.prototype.addTafs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.services.central.BlueMeteringDevice.Taf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.BlueMeteringDevice} returns this
 */
proto.services.central.BlueMeteringDevice.prototype.clearTafsList = function() {
  return this.setTafsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetPartnerDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetPartnerDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPartnerDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    spaceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    searchFilter: jspb.Message.getFieldWithDefault(msg, 4, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetPartnerDevicesRequest}
 */
proto.services.central.GetPartnerDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetPartnerDevicesRequest;
  return proto.services.central.GetPartnerDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetPartnerDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetPartnerDevicesRequest}
 */
proto.services.central.GetPartnerDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchFilter(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetPartnerDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetPartnerDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPartnerDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string space_id = 3;
 * @return {string}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string search_filter = 4;
 * @return {string}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.getSearchFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.setSearchFilter = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.clearSearchFilter = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.hasSearchFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string partner_id = 5;
 * @return {string}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetPartnerDevicesRequest} returns this
 */
proto.services.central.GetPartnerDevicesRequest.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetPartnerDevicesRequest.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetPartnerDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetPartnerDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetPartnerDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetPartnerDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPartnerDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    types_device_pb.Device.toObject, includeInstance),
    totalNumPages: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetPartnerDevicesResponse}
 */
proto.services.central.GetPartnerDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetPartnerDevicesResponse;
  return proto.services.central.GetPartnerDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetPartnerDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetPartnerDevicesResponse}
 */
proto.services.central.GetPartnerDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetPartnerDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetPartnerDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetPartnerDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPartnerDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated types.Device devices = 1;
 * @return {!Array<!proto.types.Device>}
 */
proto.services.central.GetPartnerDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.types.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {!Array<!proto.types.Device>} value
 * @return {!proto.services.central.GetPartnerDevicesResponse} returns this
*/
proto.services.central.GetPartnerDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Device}
 */
proto.services.central.GetPartnerDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetPartnerDevicesResponse} returns this
 */
proto.services.central.GetPartnerDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional int32 total_num_pages = 2;
 * @return {number}
 */
proto.services.central.GetPartnerDevicesResponse.prototype.getTotalNumPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetPartnerDevicesResponse} returns this
 */
proto.services.central.GetPartnerDevicesResponse.prototype.setTotalNumPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMetergridProjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMetergridProjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMetergridProjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridProjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMetergridProjectsRequest}
 */
proto.services.central.GetMetergridProjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMetergridProjectsRequest;
  return proto.services.central.GetMetergridProjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMetergridProjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMetergridProjectsRequest}
 */
proto.services.central.GetMetergridProjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMetergridProjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMetergridProjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMetergridProjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridProjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.services.central.GetMetergridProjectsRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMetergridProjectsRequest} returns this
 */
proto.services.central.GetMetergridProjectsRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.services.central.GetMetergridProjectsRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMetergridProjectsRequest} returns this
 */
proto.services.central.GetMetergridProjectsRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMetergridProjectsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMetergridProjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMetergridProjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMetergridProjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridProjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metergridUserCredentialsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectsList: jspb.Message.toObjectList(msg.getProjectsList(),
    proto.services.central.MetergridProject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMetergridProjectsResponse}
 */
proto.services.central.GetMetergridProjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMetergridProjectsResponse;
  return proto.services.central.GetMetergridProjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMetergridProjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMetergridProjectsResponse}
 */
proto.services.central.GetMetergridProjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetergridUserCredentialsId(value);
      break;
    case 2:
      var value = new proto.services.central.MetergridProject;
      reader.readMessage(value,proto.services.central.MetergridProject.deserializeBinaryFromReader);
      msg.addProjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMetergridProjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMetergridProjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMetergridProjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridProjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetergridUserCredentialsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.MetergridProject.serializeBinaryToWriter
    );
  }
};


/**
 * optional string metergrid_user_credentials_id = 1;
 * @return {string}
 */
proto.services.central.GetMetergridProjectsResponse.prototype.getMetergridUserCredentialsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMetergridProjectsResponse} returns this
 */
proto.services.central.GetMetergridProjectsResponse.prototype.setMetergridUserCredentialsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MetergridProject projects = 2;
 * @return {!Array<!proto.services.central.MetergridProject>}
 */
proto.services.central.GetMetergridProjectsResponse.prototype.getProjectsList = function() {
  return /** @type{!Array<!proto.services.central.MetergridProject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.MetergridProject, 2));
};


/**
 * @param {!Array<!proto.services.central.MetergridProject>} value
 * @return {!proto.services.central.GetMetergridProjectsResponse} returns this
*/
proto.services.central.GetMetergridProjectsResponse.prototype.setProjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.MetergridProject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.MetergridProject}
 */
proto.services.central.GetMetergridProjectsResponse.prototype.addProjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.MetergridProject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMetergridProjectsResponse} returns this
 */
proto.services.central.GetMetergridProjectsResponse.prototype.clearProjectsList = function() {
  return this.setProjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MetergridProject.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MetergridProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MetergridProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MetergridProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bdewNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MetergridProject}
 */
proto.services.central.MetergridProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MetergridProject;
  return proto.services.central.MetergridProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MetergridProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MetergridProject}
 */
proto.services.central.MetergridProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBdewNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MetergridProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MetergridProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MetergridProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MetergridProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBdewNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.services.central.MetergridProject.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.MetergridProject} returns this
 */
proto.services.central.MetergridProject.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.central.MetergridProject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridProject} returns this
 */
proto.services.central.MetergridProject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 3;
 * @return {string}
 */
proto.services.central.MetergridProject.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridProject} returns this
 */
proto.services.central.MetergridProject.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bdew_number = 4;
 * @return {string}
 */
proto.services.central.MetergridProject.prototype.getBdewNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridProject} returns this
 */
proto.services.central.MetergridProject.prototype.setBdewNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMetergridTenantsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMetergridTenantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMetergridTenantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMetergridTenantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridTenantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    metergridUserCredentialsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMetergridTenantsRequest}
 */
proto.services.central.GetMetergridTenantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMetergridTenantsRequest;
  return proto.services.central.GetMetergridTenantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMetergridTenantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMetergridTenantsRequest}
 */
proto.services.central.GetMetergridTenantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetergridUserCredentialsId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMetergridTenantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMetergridTenantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMetergridTenantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridTenantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetergridUserCredentialsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional string metergrid_user_credentials_id = 1;
 * @return {string}
 */
proto.services.central.GetMetergridTenantsRequest.prototype.getMetergridUserCredentialsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMetergridTenantsRequest} returns this
 */
proto.services.central.GetMetergridTenantsRequest.prototype.setMetergridUserCredentialsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 project_ids = 2;
 * @return {!Array<number>}
 */
proto.services.central.GetMetergridTenantsRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.GetMetergridTenantsRequest} returns this
 */
proto.services.central.GetMetergridTenantsRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetMetergridTenantsRequest} returns this
 */
proto.services.central.GetMetergridTenantsRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMetergridTenantsRequest} returns this
 */
proto.services.central.GetMetergridTenantsRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMetergridTenantsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMetergridTenantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMetergridTenantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMetergridTenantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridTenantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectsTenantsList: jspb.Message.toObjectList(msg.getProjectsTenantsList(),
    proto.services.central.MetergridProjectTenants.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMetergridTenantsResponse}
 */
proto.services.central.GetMetergridTenantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMetergridTenantsResponse;
  return proto.services.central.GetMetergridTenantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMetergridTenantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMetergridTenantsResponse}
 */
proto.services.central.GetMetergridTenantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.MetergridProjectTenants;
      reader.readMessage(value,proto.services.central.MetergridProjectTenants.deserializeBinaryFromReader);
      msg.addProjectsTenants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMetergridTenantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMetergridTenantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMetergridTenantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetergridTenantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectsTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.MetergridProjectTenants.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MetergridProjectTenants projects_tenants = 1;
 * @return {!Array<!proto.services.central.MetergridProjectTenants>}
 */
proto.services.central.GetMetergridTenantsResponse.prototype.getProjectsTenantsList = function() {
  return /** @type{!Array<!proto.services.central.MetergridProjectTenants>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.MetergridProjectTenants, 1));
};


/**
 * @param {!Array<!proto.services.central.MetergridProjectTenants>} value
 * @return {!proto.services.central.GetMetergridTenantsResponse} returns this
*/
proto.services.central.GetMetergridTenantsResponse.prototype.setProjectsTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.MetergridProjectTenants=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.MetergridProjectTenants}
 */
proto.services.central.GetMetergridTenantsResponse.prototype.addProjectsTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.MetergridProjectTenants, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMetergridTenantsResponse} returns this
 */
proto.services.central.GetMetergridTenantsResponse.prototype.clearProjectsTenantsList = function() {
  return this.setProjectsTenantsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.MetergridProjectTenants.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MetergridProjectTenants.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MetergridProjectTenants.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MetergridProjectTenants} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MetergridProjectTenants.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && proto.services.central.MetergridProject.toObject(includeInstance, f),
    tenantsList: jspb.Message.toObjectList(msg.getTenantsList(),
    proto.services.central.MetergridTenant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MetergridProjectTenants}
 */
proto.services.central.MetergridProjectTenants.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MetergridProjectTenants;
  return proto.services.central.MetergridProjectTenants.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MetergridProjectTenants} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MetergridProjectTenants}
 */
proto.services.central.MetergridProjectTenants.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.MetergridProject;
      reader.readMessage(value,proto.services.central.MetergridProject.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = new proto.services.central.MetergridTenant;
      reader.readMessage(value,proto.services.central.MetergridTenant.deserializeBinaryFromReader);
      msg.addTenants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MetergridProjectTenants.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MetergridProjectTenants.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MetergridProjectTenants} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MetergridProjectTenants.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.MetergridProject.serializeBinaryToWriter
    );
  }
  f = message.getTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.MetergridTenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional MetergridProject project = 1;
 * @return {?proto.services.central.MetergridProject}
 */
proto.services.central.MetergridProjectTenants.prototype.getProject = function() {
  return /** @type{?proto.services.central.MetergridProject} */ (
    jspb.Message.getWrapperField(this, proto.services.central.MetergridProject, 1));
};


/**
 * @param {?proto.services.central.MetergridProject|undefined} value
 * @return {!proto.services.central.MetergridProjectTenants} returns this
*/
proto.services.central.MetergridProjectTenants.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.MetergridProjectTenants} returns this
 */
proto.services.central.MetergridProjectTenants.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.MetergridProjectTenants.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MetergridTenant tenants = 2;
 * @return {!Array<!proto.services.central.MetergridTenant>}
 */
proto.services.central.MetergridProjectTenants.prototype.getTenantsList = function() {
  return /** @type{!Array<!proto.services.central.MetergridTenant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.MetergridTenant, 2));
};


/**
 * @param {!Array<!proto.services.central.MetergridTenant>} value
 * @return {!proto.services.central.MetergridProjectTenants} returns this
*/
proto.services.central.MetergridProjectTenants.prototype.setTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.MetergridTenant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.MetergridTenant}
 */
proto.services.central.MetergridProjectTenants.prototype.addTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.MetergridTenant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.MetergridProjectTenants} returns this
 */
proto.services.central.MetergridProjectTenants.prototype.clearTenantsList = function() {
  return this.setTenantsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MetergridTenant.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MetergridTenant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MetergridTenant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MetergridTenant.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apartmentName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    melo: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MetergridTenant}
 */
proto.services.central.MetergridTenant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MetergridTenant;
  return proto.services.central.MetergridTenant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MetergridTenant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MetergridTenant}
 */
proto.services.central.MetergridTenant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApartmentName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMelo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MetergridTenant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MetergridTenant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MetergridTenant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MetergridTenant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApartmentName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMelo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 subscription_id = 1;
 * @return {number}
 */
proto.services.central.MetergridTenant.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.MetergridTenant} returns this
 */
proto.services.central.MetergridTenant.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.services.central.MetergridTenant.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridTenant} returns this
 */
proto.services.central.MetergridTenant.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_name = 3;
 * @return {string}
 */
proto.services.central.MetergridTenant.prototype.getTenantName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridTenant} returns this
 */
proto.services.central.MetergridTenant.prototype.setTenantName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string apartment_name = 4;
 * @return {string}
 */
proto.services.central.MetergridTenant.prototype.getApartmentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridTenant} returns this
 */
proto.services.central.MetergridTenant.prototype.setApartmentName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string melo = 5;
 * @return {string}
 */
proto.services.central.MetergridTenant.prototype.getMelo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MetergridTenant} returns this
 */
proto.services.central.MetergridTenant.prototype.setMelo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.ImportMetergridDataRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImportMetergridDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImportMetergridDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImportMetergridDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportMetergridDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    metergridUserCredentialsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    partnerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImportMetergridDataRequest}
 */
proto.services.central.ImportMetergridDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImportMetergridDataRequest;
  return proto.services.central.ImportMetergridDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImportMetergridDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImportMetergridDataRequest}
 */
proto.services.central.ImportMetergridDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetergridUserCredentialsId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubscriptionIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImportMetergridDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImportMetergridDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImportMetergridDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportMetergridDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetergridUserCredentialsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string metergrid_user_credentials_id = 1;
 * @return {string}
 */
proto.services.central.ImportMetergridDataRequest.prototype.getMetergridUserCredentialsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportMetergridDataRequest} returns this
 */
proto.services.central.ImportMetergridDataRequest.prototype.setMetergridUserCredentialsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 subscription_ids = 2;
 * @return {!Array<number>}
 */
proto.services.central.ImportMetergridDataRequest.prototype.getSubscriptionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.central.ImportMetergridDataRequest} returns this
 */
proto.services.central.ImportMetergridDataRequest.prototype.setSubscriptionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.central.ImportMetergridDataRequest} returns this
 */
proto.services.central.ImportMetergridDataRequest.prototype.addSubscriptionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.ImportMetergridDataRequest} returns this
 */
proto.services.central.ImportMetergridDataRequest.prototype.clearSubscriptionIdsList = function() {
  return this.setSubscriptionIdsList([]);
};


/**
 * optional string partner_id = 3;
 * @return {string}
 */
proto.services.central.ImportMetergridDataRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportMetergridDataRequest} returns this
 */
proto.services.central.ImportMetergridDataRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.ImportMetergridDataRequest} returns this
 */
proto.services.central.ImportMetergridDataRequest.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.ImportMetergridDataRequest.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImportMetergridDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImportMetergridDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImportMetergridDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportMetergridDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImportMetergridDataResponse}
 */
proto.services.central.ImportMetergridDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImportMetergridDataResponse;
  return proto.services.central.ImportMetergridDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImportMetergridDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImportMetergridDataResponse}
 */
proto.services.central.ImportMetergridDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImportMetergridDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImportMetergridDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImportMetergridDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportMetergridDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDataImportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDataImportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDataImportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDataImportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDataImportsRequest}
 */
proto.services.central.GetDataImportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDataImportsRequest;
  return proto.services.central.GetDataImportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDataImportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDataImportsRequest}
 */
proto.services.central.GetDataImportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDataImportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDataImportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDataImportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDataImportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDataImportsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDataImportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDataImportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDataImportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDataImportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataImportsList: jspb.Message.toObjectList(msg.getDataImportsList(),
    types_data_import_pb.DataImport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDataImportsResponse}
 */
proto.services.central.GetDataImportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDataImportsResponse;
  return proto.services.central.GetDataImportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDataImportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDataImportsResponse}
 */
proto.services.central.GetDataImportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_data_import_pb.DataImport;
      reader.readMessage(value,types_data_import_pb.DataImport.deserializeBinaryFromReader);
      msg.addDataImports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDataImportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDataImportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDataImportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDataImportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataImportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_data_import_pb.DataImport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.DataImport data_imports = 1;
 * @return {!Array<!proto.types.DataImport>}
 */
proto.services.central.GetDataImportsResponse.prototype.getDataImportsList = function() {
  return /** @type{!Array<!proto.types.DataImport>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_data_import_pb.DataImport, 1));
};


/**
 * @param {!Array<!proto.types.DataImport>} value
 * @return {!proto.services.central.GetDataImportsResponse} returns this
*/
proto.services.central.GetDataImportsResponse.prototype.setDataImportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DataImport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DataImport}
 */
proto.services.central.GetDataImportsResponse.prototype.addDataImports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DataImport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDataImportsResponse} returns this
 */
proto.services.central.GetDataImportsResponse.prototype.clearDataImportsList = function() {
  return this.setDataImportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImovisMeterReading.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImovisMeterReading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImovisMeterReading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImovisMeterReading.toObject = function(includeInstance, msg) {
  var f, obj = {
    obisCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImovisMeterReading}
 */
proto.services.central.ImovisMeterReading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImovisMeterReading;
  return proto.services.central.ImovisMeterReading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImovisMeterReading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImovisMeterReading}
 */
proto.services.central.ImovisMeterReading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObisCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImovisMeterReading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImovisMeterReading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImovisMeterReading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImovisMeterReading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObisCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string obis_code = 1;
 * @return {string}
 */
proto.services.central.ImovisMeterReading.prototype.getObisCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImovisMeterReading} returns this
 */
proto.services.central.ImovisMeterReading.prototype.setObisCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.services.central.ImovisMeterReading.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ImovisMeterReading} returns this
 */
proto.services.central.ImovisMeterReading.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.services.central.ImovisMeterReading.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ImovisMeterReading} returns this
 */
proto.services.central.ImovisMeterReading.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.StoreImovisReadingsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreImovisReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreImovisReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreImovisReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreImovisReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meloId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    readingsList: jspb.Message.toObjectList(msg.getReadingsList(),
    proto.services.central.ImovisMeterReading.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreImovisReadingsRequest}
 */
proto.services.central.StoreImovisReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreImovisReadingsRequest;
  return proto.services.central.StoreImovisReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreImovisReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreImovisReadingsRequest}
 */
proto.services.central.StoreImovisReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeloId(value);
      break;
    case 2:
      var value = new proto.services.central.ImovisMeterReading;
      reader.readMessage(value,proto.services.central.ImovisMeterReading.deserializeBinaryFromReader);
      msg.addReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreImovisReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreImovisReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreImovisReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreImovisReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeloId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReadingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.ImovisMeterReading.serializeBinaryToWriter
    );
  }
};


/**
 * optional string melo_id = 1;
 * @return {string}
 */
proto.services.central.StoreImovisReadingsRequest.prototype.getMeloId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.StoreImovisReadingsRequest} returns this
 */
proto.services.central.StoreImovisReadingsRequest.prototype.setMeloId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ImovisMeterReading readings = 2;
 * @return {!Array<!proto.services.central.ImovisMeterReading>}
 */
proto.services.central.StoreImovisReadingsRequest.prototype.getReadingsList = function() {
  return /** @type{!Array<!proto.services.central.ImovisMeterReading>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ImovisMeterReading, 2));
};


/**
 * @param {!Array<!proto.services.central.ImovisMeterReading>} value
 * @return {!proto.services.central.StoreImovisReadingsRequest} returns this
*/
proto.services.central.StoreImovisReadingsRequest.prototype.setReadingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.ImovisMeterReading=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ImovisMeterReading}
 */
proto.services.central.StoreImovisReadingsRequest.prototype.addReadings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.ImovisMeterReading, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.StoreImovisReadingsRequest} returns this
 */
proto.services.central.StoreImovisReadingsRequest.prototype.clearReadingsList = function() {
  return this.setReadingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreImovisReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreImovisReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreImovisReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreImovisReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreImovisReadingsResponse}
 */
proto.services.central.StoreImovisReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreImovisReadingsResponse;
  return proto.services.central.StoreImovisReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreImovisReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreImovisReadingsResponse}
 */
proto.services.central.StoreImovisReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreImovisReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreImovisReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreImovisReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreImovisReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceTypesRequest}
 */
proto.services.central.GetDeviceTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceTypesRequest;
  return proto.services.central.GetDeviceTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceTypesRequest}
 */
proto.services.central.GetDeviceTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetDeviceTypesRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDeviceTypesRequest} returns this
 */
proto.services.central.GetDeviceTypesRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDeviceTypesRequest} returns this
 */
proto.services.central.GetDeviceTypesRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceTypesRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDeviceTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceTypesList: jspb.Message.toObjectList(msg.getDeviceTypesList(),
    types_device_pb.DeviceTypeInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceTypesResponse}
 */
proto.services.central.GetDeviceTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceTypesResponse;
  return proto.services.central.GetDeviceTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceTypesResponse}
 */
proto.services.central.GetDeviceTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.DeviceTypeInfo;
      reader.readMessage(value,types_device_pb.DeviceTypeInfo.deserializeBinaryFromReader);
      msg.addDeviceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_pb.DeviceTypeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.DeviceTypeInfo device_types = 1;
 * @return {!Array<!proto.types.DeviceTypeInfo>}
 */
proto.services.central.GetDeviceTypesResponse.prototype.getDeviceTypesList = function() {
  return /** @type{!Array<!proto.types.DeviceTypeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_pb.DeviceTypeInfo, 1));
};


/**
 * @param {!Array<!proto.types.DeviceTypeInfo>} value
 * @return {!proto.services.central.GetDeviceTypesResponse} returns this
*/
proto.services.central.GetDeviceTypesResponse.prototype.setDeviceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DeviceTypeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceTypeInfo}
 */
proto.services.central.GetDeviceTypesResponse.prototype.addDeviceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DeviceTypeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDeviceTypesResponse} returns this
 */
proto.services.central.GetDeviceTypesResponse.prototype.clearDeviceTypesList = function() {
  return this.setDeviceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceProvidersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceProvidersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceProvidersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    includeDisabledProviders: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    spaceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceProvidersRequest}
 */
proto.services.central.GetDeviceProvidersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceProvidersRequest;
  return proto.services.central.GetDeviceProvidersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceProvidersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceProvidersRequest}
 */
proto.services.central.GetDeviceProvidersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Device.Type} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDisabledProviders(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceProvidersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceProvidersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceProvidersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.types.Device.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIncludeDisabledProviders();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional types.Device.Type device_type = 1;
 * @return {!proto.types.Device.Type}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.getDeviceType = function() {
  return /** @type {!proto.types.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Device.Type} value
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.setDeviceType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.clearDeviceType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.hasDeviceType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool include_disabled_providers = 2;
 * @return {boolean}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.getIncludeDisabledProviders = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.setIncludeDisabledProviders = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string space_id = 3;
 * @return {string}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string partner_id = 4;
 * @return {string}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.setPartnerId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetDeviceProvidersRequest} returns this
 */
proto.services.central.GetDeviceProvidersRequest.prototype.clearPartnerId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceProvidersRequest.prototype.hasPartnerId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDeviceProvidersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceProvidersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceProvidersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceProvidersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceProvidersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceProvidersList: jspb.Message.toObjectList(msg.getDeviceProvidersList(),
    types_device_pb.DeviceProviderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceProvidersResponse}
 */
proto.services.central.GetDeviceProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceProvidersResponse;
  return proto.services.central.GetDeviceProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceProvidersResponse}
 */
proto.services.central.GetDeviceProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.DeviceProviderInfo;
      reader.readMessage(value,types_device_pb.DeviceProviderInfo.deserializeBinaryFromReader);
      msg.addDeviceProviders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_pb.DeviceProviderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.DeviceProviderInfo device_providers = 1;
 * @return {!Array<!proto.types.DeviceProviderInfo>}
 */
proto.services.central.GetDeviceProvidersResponse.prototype.getDeviceProvidersList = function() {
  return /** @type{!Array<!proto.types.DeviceProviderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_pb.DeviceProviderInfo, 1));
};


/**
 * @param {!Array<!proto.types.DeviceProviderInfo>} value
 * @return {!proto.services.central.GetDeviceProvidersResponse} returns this
*/
proto.services.central.GetDeviceProvidersResponse.prototype.setDeviceProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DeviceProviderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceProviderInfo}
 */
proto.services.central.GetDeviceProvidersResponse.prototype.addDeviceProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DeviceProviderInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDeviceProvidersResponse} returns this
 */
proto.services.central.GetDeviceProvidersResponse.prototype.clearDeviceProvidersList = function() {
  return this.setDeviceProvidersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.RegisterDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.RegisterDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.RegisterDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RegisterDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registration: (f = msg.getRegistration()) && types_device_registration_pb.DeviceRegistration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.RegisterDeviceRequest}
 */
proto.services.central.RegisterDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.RegisterDeviceRequest;
  return proto.services.central.RegisterDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.RegisterDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.RegisterDeviceRequest}
 */
proto.services.central.RegisterDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_registration_pb.DeviceRegistration;
      reader.readMessage(value,types_device_registration_pb.DeviceRegistration.deserializeBinaryFromReader);
      msg.setRegistration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.RegisterDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.RegisterDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.RegisterDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RegisterDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_device_registration_pb.DeviceRegistration.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.DeviceRegistration registration = 1;
 * @return {?proto.types.DeviceRegistration}
 */
proto.services.central.RegisterDeviceRequest.prototype.getRegistration = function() {
  return /** @type{?proto.types.DeviceRegistration} */ (
    jspb.Message.getWrapperField(this, types_device_registration_pb.DeviceRegistration, 1));
};


/**
 * @param {?proto.types.DeviceRegistration|undefined} value
 * @return {!proto.services.central.RegisterDeviceRequest} returns this
*/
proto.services.central.RegisterDeviceRequest.prototype.setRegistration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.RegisterDeviceRequest} returns this
 */
proto.services.central.RegisterDeviceRequest.prototype.clearRegistration = function() {
  return this.setRegistration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.RegisterDeviceRequest.prototype.hasRegistration = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.RegisterDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.RegisterDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.RegisterDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RegisterDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ocppWebsocketUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bitShakeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.RegisterDeviceResponse}
 */
proto.services.central.RegisterDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.RegisterDeviceResponse;
  return proto.services.central.RegisterDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.RegisterDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.RegisterDeviceResponse}
 */
proto.services.central.RegisterDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOcppWebsocketUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitShakeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.RegisterDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.RegisterDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.RegisterDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RegisterDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.RegisterDeviceResponse.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.RegisterDeviceResponse} returns this
 */
proto.services.central.RegisterDeviceResponse.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ocpp_websocket_url = 2;
 * @return {string}
 */
proto.services.central.RegisterDeviceResponse.prototype.getOcppWebsocketUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.RegisterDeviceResponse} returns this
 */
proto.services.central.RegisterDeviceResponse.prototype.setOcppWebsocketUrl = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.RegisterDeviceResponse} returns this
 */
proto.services.central.RegisterDeviceResponse.prototype.clearOcppWebsocketUrl = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.RegisterDeviceResponse.prototype.hasOcppWebsocketUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string bit_shake_token = 3;
 * @return {string}
 */
proto.services.central.RegisterDeviceResponse.prototype.getBitShakeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.RegisterDeviceResponse} returns this
 */
proto.services.central.RegisterDeviceResponse.prototype.setBitShakeToken = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.RegisterDeviceResponse} returns this
 */
proto.services.central.RegisterDeviceResponse.prototype.clearBitShakeToken = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.RegisterDeviceResponse.prototype.hasBitShakeToken = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImportReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImportReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImportReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileFormat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fileContent: msg.getFileContent_asB64(),
    unit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    measurement: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeFormat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    indexTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    indexValue: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeIntervalInMinutes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    csvDelimiter: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImportReadingsRequest}
 */
proto.services.central.ImportReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImportReadingsRequest;
  return proto.services.central.ImportReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImportReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImportReadingsRequest}
 */
proto.services.central.ImportReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.services.central.ImportReadingsRequest.FileFormat} */ (reader.readEnum());
      msg.setFileFormat(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFileContent(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Measurement} */ (reader.readEnum());
      msg.setMeasurement(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeFormat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndexValue(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeIntervalInMinutes(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsvDelimiter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImportReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImportReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImportReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFileContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMeasurement();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimeFormat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIndexTime();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIndexValue();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTimeIntervalInMinutes();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCsvDelimiter();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.services.central.ImportReadingsRequest.FileFormat = {
  CSV: 0
};

/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.ImportReadingsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FileFormat file_format = 2;
 * @return {!proto.services.central.ImportReadingsRequest.FileFormat}
 */
proto.services.central.ImportReadingsRequest.prototype.getFileFormat = function() {
  return /** @type {!proto.services.central.ImportReadingsRequest.FileFormat} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.services.central.ImportReadingsRequest.FileFormat} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setFileFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bytes file_content = 3;
 * @return {!(string|Uint8Array)}
 */
proto.services.central.ImportReadingsRequest.prototype.getFileContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes file_content = 3;
 * This is a type-conversion wrapper around `getFileContent()`
 * @return {string}
 */
proto.services.central.ImportReadingsRequest.prototype.getFileContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFileContent()));
};


/**
 * optional bytes file_content = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFileContent()`
 * @return {!Uint8Array}
 */
proto.services.central.ImportReadingsRequest.prototype.getFileContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFileContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setFileContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional types.Unit unit = 4;
 * @return {!proto.types.Unit}
 */
proto.services.central.ImportReadingsRequest.prototype.getUnit = function() {
  return /** @type {!proto.types.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Unit} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional types.Direction direction = 5;
 * @return {!proto.types.Direction}
 */
proto.services.central.ImportReadingsRequest.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional types.Measurement measurement = 6;
 * @return {!proto.types.Measurement}
 */
proto.services.central.ImportReadingsRequest.prototype.getMeasurement = function() {
  return /** @type {!proto.types.Measurement} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Measurement} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setMeasurement = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string time_format = 7;
 * @return {string}
 */
proto.services.central.ImportReadingsRequest.prototype.getTimeFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setTimeFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 index_time = 8;
 * @return {number}
 */
proto.services.central.ImportReadingsRequest.prototype.getIndexTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setIndexTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 index_value = 9;
 * @return {number}
 */
proto.services.central.ImportReadingsRequest.prototype.getIndexValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setIndexValue = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 time_interval_in_minutes = 10;
 * @return {number}
 */
proto.services.central.ImportReadingsRequest.prototype.getTimeIntervalInMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setTimeIntervalInMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string csv_delimiter = 11;
 * @return {string}
 */
proto.services.central.ImportReadingsRequest.prototype.getCsvDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ImportReadingsRequest} returns this
 */
proto.services.central.ImportReadingsRequest.prototype.setCsvDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ImportReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ImportReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ImportReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ImportReadingsResponse}
 */
proto.services.central.ImportReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ImportReadingsResponse;
  return proto.services.central.ImportReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ImportReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ImportReadingsResponse}
 */
proto.services.central.ImportReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ImportReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ImportReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ImportReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ImportReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMyStromDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMyStromDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMyStromDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMyStromDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMyStromDevicesRequest}
 */
proto.services.central.GetMyStromDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMyStromDevicesRequest;
  return proto.services.central.GetMyStromDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMyStromDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMyStromDevicesRequest}
 */
proto.services.central.GetMyStromDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMyStromDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMyStromDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMyStromDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMyStromDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.services.central.GetMyStromDevicesRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMyStromDevicesRequest} returns this
 */
proto.services.central.GetMyStromDevicesRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.services.central.GetMyStromDevicesRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMyStromDevicesRequest} returns this
 */
proto.services.central.GetMyStromDevicesRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MyStromDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MyStromDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MyStromDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MyStromDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    power: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MyStromDevice}
 */
proto.services.central.MyStromDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MyStromDevice;
  return proto.services.central.MyStromDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MyStromDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MyStromDevice}
 */
proto.services.central.MyStromDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPower(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MyStromDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MyStromDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MyStromDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MyStromDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPower();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.MyStromDevice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MyStromDevice} returns this
 */
proto.services.central.MyStromDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.central.MyStromDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MyStromDevice} returns this
 */
proto.services.central.MyStromDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double power = 3;
 * @return {number}
 */
proto.services.central.MyStromDevice.prototype.getPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.MyStromDevice} returns this
 */
proto.services.central.MyStromDevice.prototype.setPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.services.central.MyStromDevice.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MyStromDevice} returns this
 */
proto.services.central.MyStromDevice.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.services.central.MyStromDevice.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MyStromDevice} returns this
 */
proto.services.central.MyStromDevice.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMyStromDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMyStromDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMyStromDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMyStromDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMyStromDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.MyStromDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMyStromDevicesResponse}
 */
proto.services.central.GetMyStromDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMyStromDevicesResponse;
  return proto.services.central.GetMyStromDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMyStromDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMyStromDevicesResponse}
 */
proto.services.central.GetMyStromDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.MyStromDevice;
      reader.readMessage(value,proto.services.central.MyStromDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMyStromDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMyStromDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMyStromDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMyStromDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.MyStromDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MyStromDevice devices = 1;
 * @return {!Array<!proto.services.central.MyStromDevice>}
 */
proto.services.central.GetMyStromDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.MyStromDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.MyStromDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.MyStromDevice>} value
 * @return {!proto.services.central.GetMyStromDevicesResponse} returns this
*/
proto.services.central.GetMyStromDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.MyStromDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.MyStromDevice}
 */
proto.services.central.GetMyStromDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.MyStromDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMyStromDevicesResponse} returns this
 */
proto.services.central.GetMyStromDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTsunDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTsunDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTsunDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTsunDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTsunDevicesRequest}
 */
proto.services.central.GetTsunDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTsunDevicesRequest;
  return proto.services.central.GetTsunDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTsunDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTsunDevicesRequest}
 */
proto.services.central.GetTsunDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTsunDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTsunDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTsunDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTsunDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.services.central.GetTsunDevicesRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTsunDevicesRequest} returns this
 */
proto.services.central.GetTsunDevicesRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.services.central.GetTsunDevicesRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTsunDevicesRequest} returns this
 */
proto.services.central.GetTsunDevicesRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTsunDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTsunDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTsunDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTsunDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTsunDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.TsunDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTsunDevicesResponse}
 */
proto.services.central.GetTsunDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTsunDevicesResponse;
  return proto.services.central.GetTsunDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTsunDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTsunDevicesResponse}
 */
proto.services.central.GetTsunDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.TsunDevice;
      reader.readMessage(value,proto.services.central.TsunDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTsunDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTsunDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTsunDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTsunDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.TsunDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TsunDevice devices = 1;
 * @return {!Array<!proto.services.central.TsunDevice>}
 */
proto.services.central.GetTsunDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.TsunDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.TsunDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.TsunDevice>} value
 * @return {!proto.services.central.GetTsunDevicesResponse} returns this
*/
proto.services.central.GetTsunDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.TsunDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.TsunDevice}
 */
proto.services.central.GetTsunDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.TsunDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTsunDevicesResponse} returns this
 */
proto.services.central.GetTsunDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TsunDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TsunDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TsunDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TsunDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    powerStationGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TsunDevice}
 */
proto.services.central.TsunDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TsunDevice;
  return proto.services.central.TsunDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TsunDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TsunDevice}
 */
proto.services.central.TsunDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerStationGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TsunDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TsunDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TsunDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TsunDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPowerStationGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string power_station_guid = 1;
 * @return {string}
 */
proto.services.central.TsunDevice.prototype.getPowerStationGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.TsunDevice} returns this
 */
proto.services.central.TsunDevice.prototype.setPowerStationGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.central.TsunDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.TsunDevice} returns this
 */
proto.services.central.TsunDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 3;
 * @return {string}
 */
proto.services.central.TsunDevice.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.TsunDevice} returns this
 */
proto.services.central.TsunDevice.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreNonSmartMeterReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreNonSmartMeterReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    manualReading: (f = msg.getManualReading()) && types_device_reading_pb.ManualReading.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreNonSmartMeterReadingsRequest}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreNonSmartMeterReadingsRequest;
  return proto.services.central.StoreNonSmartMeterReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreNonSmartMeterReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreNonSmartMeterReadingsRequest}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new types_device_reading_pb.ManualReading;
      reader.readMessage(value,types_device_reading_pb.ManualReading.deserializeBinaryFromReader);
      msg.setManualReading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreNonSmartMeterReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreNonSmartMeterReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getManualReading();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_device_reading_pb.ManualReading.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.StoreNonSmartMeterReadingsRequest} returns this
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.ManualReading manual_reading = 2;
 * @return {?proto.types.ManualReading}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.getManualReading = function() {
  return /** @type{?proto.types.ManualReading} */ (
    jspb.Message.getWrapperField(this, types_device_reading_pb.ManualReading, 2));
};


/**
 * @param {?proto.types.ManualReading|undefined} value
 * @return {!proto.services.central.StoreNonSmartMeterReadingsRequest} returns this
*/
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.setManualReading = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.StoreNonSmartMeterReadingsRequest} returns this
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.clearManualReading = function() {
  return this.setManualReading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.StoreNonSmartMeterReadingsRequest.prototype.hasManualReading = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreNonSmartMeterReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreNonSmartMeterReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreNonSmartMeterReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreNonSmartMeterReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreNonSmartMeterReadingsResponse}
 */
proto.services.central.StoreNonSmartMeterReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreNonSmartMeterReadingsResponse;
  return proto.services.central.StoreNonSmartMeterReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreNonSmartMeterReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreNonSmartMeterReadingsResponse}
 */
proto.services.central.StoreNonSmartMeterReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreNonSmartMeterReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreNonSmartMeterReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreNonSmartMeterReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreNonSmartMeterReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetViessmannAuthConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetViessmannAuthConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetViessmannAuthConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetViessmannAuthConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetViessmannAuthConfigRequest}
 */
proto.services.central.GetViessmannAuthConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetViessmannAuthConfigRequest;
  return proto.services.central.GetViessmannAuthConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetViessmannAuthConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetViessmannAuthConfigRequest}
 */
proto.services.central.GetViessmannAuthConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetViessmannAuthConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetViessmannAuthConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetViessmannAuthConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetViessmannAuthConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.services.central.GetViessmannAuthConfigRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetViessmannAuthConfigRequest} returns this
 */
proto.services.central.GetViessmannAuthConfigRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.services.central.GetViessmannAuthConfigRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetViessmannAuthConfigRequest} returns this
 */
proto.services.central.GetViessmannAuthConfigRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetViessmannAuthConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetViessmannAuthConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetViessmannAuthConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isFirstLogin: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    redirectUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetViessmannAuthConfigResponse}
 */
proto.services.central.GetViessmannAuthConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetViessmannAuthConfigResponse;
  return proto.services.central.GetViessmannAuthConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetViessmannAuthConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetViessmannAuthConfigResponse}
 */
proto.services.central.GetViessmannAuthConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetViessmannAuthConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetViessmannAuthConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetViessmannAuthConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsFirstLogin();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRedirectUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string auth_url = 1;
 * @return {string}
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetViessmannAuthConfigResponse} returns this
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_first_login = 2;
 * @return {boolean}
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.getIsFirstLogin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.GetViessmannAuthConfigResponse} returns this
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.setIsFirstLogin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string redirect_uri = 3;
 * @return {string}
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.getRedirectUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetViessmannAuthConfigResponse} returns this
 */
proto.services.central.GetViessmannAuthConfigResponse.prototype.setRedirectUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAvailableViessmannDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAvailableViessmannDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAvailableViessmannDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableViessmannDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticationCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAvailableViessmannDevicesRequest}
 */
proto.services.central.GetAvailableViessmannDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAvailableViessmannDevicesRequest;
  return proto.services.central.GetAvailableViessmannDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAvailableViessmannDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAvailableViessmannDevicesRequest}
 */
proto.services.central.GetAvailableViessmannDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthenticationCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAvailableViessmannDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAvailableViessmannDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAvailableViessmannDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableViessmannDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticationCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string authentication_code = 1;
 * @return {string}
 */
proto.services.central.GetAvailableViessmannDevicesRequest.prototype.getAuthenticationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAvailableViessmannDevicesRequest} returns this
 */
proto.services.central.GetAvailableViessmannDevicesRequest.prototype.setAuthenticationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAvailableViessmannDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAvailableViessmannDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAvailableViessmannDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAvailableViessmannDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableViessmannDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableViessmannDevicesList: jspb.Message.toObjectList(msg.getAvailableViessmannDevicesList(),
    proto.services.central.ViessmannDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAvailableViessmannDevicesResponse}
 */
proto.services.central.GetAvailableViessmannDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAvailableViessmannDevicesResponse;
  return proto.services.central.GetAvailableViessmannDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAvailableViessmannDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAvailableViessmannDevicesResponse}
 */
proto.services.central.GetAvailableViessmannDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.ViessmannDevice;
      reader.readMessage(value,proto.services.central.ViessmannDevice.deserializeBinaryFromReader);
      msg.addAvailableViessmannDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAvailableViessmannDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAvailableViessmannDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAvailableViessmannDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAvailableViessmannDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableViessmannDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.ViessmannDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ViessmannDevice available_viessmann_devices = 1;
 * @return {!Array<!proto.services.central.ViessmannDevice>}
 */
proto.services.central.GetAvailableViessmannDevicesResponse.prototype.getAvailableViessmannDevicesList = function() {
  return /** @type{!Array<!proto.services.central.ViessmannDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ViessmannDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.ViessmannDevice>} value
 * @return {!proto.services.central.GetAvailableViessmannDevicesResponse} returns this
*/
proto.services.central.GetAvailableViessmannDevicesResponse.prototype.setAvailableViessmannDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.ViessmannDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ViessmannDevice}
 */
proto.services.central.GetAvailableViessmannDevicesResponse.prototype.addAvailableViessmannDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.ViessmannDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAvailableViessmannDevicesResponse} returns this
 */
proto.services.central.GetAvailableViessmannDevicesResponse.prototype.clearAvailableViessmannDevicesList = function() {
  return this.setAvailableViessmannDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ViessmannDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ViessmannDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ViessmannDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ViessmannDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    viessmanDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    installationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gatewaySerial: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    modelId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    boilerSerial: jspb.Message.getFieldWithDefault(msg, 9, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isSupported: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ViessmannDevice}
 */
proto.services.central.ViessmannDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ViessmannDevice;
  return proto.services.central.ViessmannDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ViessmannDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ViessmannDevice}
 */
proto.services.central.ViessmannDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setViessmanDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewaySerial(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoilerSerial(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSupported(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ViessmannDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ViessmannDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ViessmannDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ViessmannDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViessmanDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstallationId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGatewaySerial();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBoilerSerial();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsSupported();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string viessman_device_id = 1;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getViessmanDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setViessmanDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 installation_id = 3;
 * @return {number}
 */
proto.services.central.ViessmannDevice.prototype.getInstallationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setInstallationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string gateway_serial = 4;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getGatewaySerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setGatewaySerial = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_type = 5;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.services.central.ViessmannDevice.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string model_id = 8;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string boiler_serial = 9;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getBoilerSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setBoilerSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string image_url = 10;
 * @return {string}
 */
proto.services.central.ViessmannDevice.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_supported = 11;
 * @return {boolean}
 */
proto.services.central.ViessmannDevice.prototype.getIsSupported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.ViessmannDevice} returns this
 */
proto.services.central.ViessmannDevice.prototype.setIsSupported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteViessmannClientInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteViessmannClientInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteViessmannClientInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteViessmannClientInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteViessmannClientInfoRequest}
 */
proto.services.central.DeleteViessmannClientInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteViessmannClientInfoRequest;
  return proto.services.central.DeleteViessmannClientInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteViessmannClientInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteViessmannClientInfoRequest}
 */
proto.services.central.DeleteViessmannClientInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteViessmannClientInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteViessmannClientInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteViessmannClientInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteViessmannClientInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.services.central.DeleteViessmannClientInfoRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteViessmannClientInfoRequest} returns this
 */
proto.services.central.DeleteViessmannClientInfoRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeleteViessmannClientInfoRequest} returns this
 */
proto.services.central.DeleteViessmannClientInfoRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteViessmannClientInfoRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteViessmannClientInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteViessmannClientInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteViessmannClientInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteViessmannClientInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteViessmannClientInfoResponse}
 */
proto.services.central.DeleteViessmannClientInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteViessmannClientInfoResponse;
  return proto.services.central.DeleteViessmannClientInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteViessmannClientInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteViessmannClientInfoResponse}
 */
proto.services.central.DeleteViessmannClientInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteViessmannClientInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteViessmannClientInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteViessmannClientInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteViessmannClientInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.StoreReadingsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreReadingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreReadingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreReadingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreReadingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceReadingsList: jspb.Message.toObjectList(msg.getDeviceReadingsList(),
    types_external_reading_pb.ExternalDeviceReadings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreReadingsRequest}
 */
proto.services.central.StoreReadingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreReadingsRequest;
  return proto.services.central.StoreReadingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreReadingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreReadingsRequest}
 */
proto.services.central.StoreReadingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_external_reading_pb.ExternalDeviceReadings;
      reader.readMessage(value,types_external_reading_pb.ExternalDeviceReadings.deserializeBinaryFromReader);
      msg.addDeviceReadings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreReadingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreReadingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreReadingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreReadingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceReadingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_external_reading_pb.ExternalDeviceReadings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.ExternalDeviceReadings device_readings = 1;
 * @return {!Array<!proto.types.ExternalDeviceReadings>}
 */
proto.services.central.StoreReadingsRequest.prototype.getDeviceReadingsList = function() {
  return /** @type{!Array<!proto.types.ExternalDeviceReadings>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_external_reading_pb.ExternalDeviceReadings, 1));
};


/**
 * @param {!Array<!proto.types.ExternalDeviceReadings>} value
 * @return {!proto.services.central.StoreReadingsRequest} returns this
*/
proto.services.central.StoreReadingsRequest.prototype.setDeviceReadingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.ExternalDeviceReadings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.ExternalDeviceReadings}
 */
proto.services.central.StoreReadingsRequest.prototype.addDeviceReadings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.ExternalDeviceReadings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.StoreReadingsRequest} returns this
 */
proto.services.central.StoreReadingsRequest.prototype.clearDeviceReadingsList = function() {
  return this.setDeviceReadingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.StoreReadingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.StoreReadingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.StoreReadingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreReadingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.StoreReadingsResponse}
 */
proto.services.central.StoreReadingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.StoreReadingsResponse;
  return proto.services.central.StoreReadingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.StoreReadingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.StoreReadingsResponse}
 */
proto.services.central.StoreReadingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.StoreReadingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.StoreReadingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.StoreReadingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.StoreReadingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceReadingStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceReadingStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceReadingStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceReadingStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceReadingStatsRequest}
 */
proto.services.central.GetDeviceReadingStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceReadingStatsRequest;
  return proto.services.central.GetDeviceReadingStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceReadingStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceReadingStatsRequest}
 */
proto.services.central.GetDeviceReadingStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceReadingStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceReadingStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceReadingStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceReadingStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetDeviceReadingStatsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDeviceReadingStatsRequest} returns this
 */
proto.services.central.GetDeviceReadingStatsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDeviceReadingStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDeviceReadingStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDeviceReadingStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceReadingStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceReadingStats: (f = msg.getDeviceReadingStats()) && types_device_reading_pb.DeviceReadingStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDeviceReadingStatsResponse}
 */
proto.services.central.GetDeviceReadingStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDeviceReadingStatsResponse;
  return proto.services.central.GetDeviceReadingStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDeviceReadingStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDeviceReadingStatsResponse}
 */
proto.services.central.GetDeviceReadingStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_reading_pb.DeviceReadingStats;
      reader.readMessage(value,types_device_reading_pb.DeviceReadingStats.deserializeBinaryFromReader);
      msg.setDeviceReadingStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDeviceReadingStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDeviceReadingStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDeviceReadingStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDeviceReadingStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceReadingStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_device_reading_pb.DeviceReadingStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.DeviceReadingStats device_reading_stats = 1;
 * @return {?proto.types.DeviceReadingStats}
 */
proto.services.central.GetDeviceReadingStatsResponse.prototype.getDeviceReadingStats = function() {
  return /** @type{?proto.types.DeviceReadingStats} */ (
    jspb.Message.getWrapperField(this, types_device_reading_pb.DeviceReadingStats, 1));
};


/**
 * @param {?proto.types.DeviceReadingStats|undefined} value
 * @return {!proto.services.central.GetDeviceReadingStatsResponse} returns this
*/
proto.services.central.GetDeviceReadingStatsResponse.prototype.setDeviceReadingStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDeviceReadingStatsResponse} returns this
 */
proto.services.central.GetDeviceReadingStatsResponse.prototype.clearDeviceReadingStats = function() {
  return this.setDeviceReadingStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDeviceReadingStatsResponse.prototype.hasDeviceReadingStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTibberHomesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTibberHomesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTibberHomesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberHomesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTibberHomesRequest}
 */
proto.services.central.GetTibberHomesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTibberHomesRequest;
  return proto.services.central.GetTibberHomesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTibberHomesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTibberHomesRequest}
 */
proto.services.central.GetTibberHomesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTibberHomesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTibberHomesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTibberHomesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberHomesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.services.central.GetTibberHomesRequest.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTibberHomesRequest} returns this
 */
proto.services.central.GetTibberHomesRequest.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TibberHome.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TibberHome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TibberHome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TibberHome.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TibberHome}
 */
proto.services.central.TibberHome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TibberHome;
  return proto.services.central.TibberHome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TibberHome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TibberHome}
 */
proto.services.central.TibberHome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TibberHome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TibberHome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TibberHome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TibberHome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.TibberHome.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.TibberHome} returns this
 */
proto.services.central.TibberHome.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address1 = 2;
 * @return {string}
 */
proto.services.central.TibberHome.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.TibberHome} returns this
 */
proto.services.central.TibberHome.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTibberHomesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTibberHomesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTibberHomesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTibberHomesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberHomesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    homesList: jspb.Message.toObjectList(msg.getHomesList(),
    proto.services.central.TibberHome.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTibberHomesResponse}
 */
proto.services.central.GetTibberHomesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTibberHomesResponse;
  return proto.services.central.GetTibberHomesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTibberHomesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTibberHomesResponse}
 */
proto.services.central.GetTibberHomesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.TibberHome;
      reader.readMessage(value,proto.services.central.TibberHome.deserializeBinaryFromReader);
      msg.addHomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTibberHomesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTibberHomesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTibberHomesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberHomesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.TibberHome.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TibberHome homes = 1;
 * @return {!Array<!proto.services.central.TibberHome>}
 */
proto.services.central.GetTibberHomesResponse.prototype.getHomesList = function() {
  return /** @type{!Array<!proto.services.central.TibberHome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.TibberHome, 1));
};


/**
 * @param {!Array<!proto.services.central.TibberHome>} value
 * @return {!proto.services.central.GetTibberHomesResponse} returns this
*/
proto.services.central.GetTibberHomesResponse.prototype.setHomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.TibberHome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.TibberHome}
 */
proto.services.central.GetTibberHomesResponse.prototype.addHomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.TibberHome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTibberHomesResponse} returns this
 */
proto.services.central.GetTibberHomesResponse.prototype.clearHomesList = function() {
  return this.setHomesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTibberAuthURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTibberAuthURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTibberAuthURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTibberAuthURLRequest}
 */
proto.services.central.GetTibberAuthURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTibberAuthURLRequest;
  return proto.services.central.GetTibberAuthURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTibberAuthURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTibberAuthURLRequest}
 */
proto.services.central.GetTibberAuthURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTibberAuthURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTibberAuthURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTibberAuthURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.services.central.GetTibberAuthURLRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTibberAuthURLRequest} returns this
 */
proto.services.central.GetTibberAuthURLRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTibberAuthURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTibberAuthURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTibberAuthURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTibberAuthURLResponse}
 */
proto.services.central.GetTibberAuthURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTibberAuthURLResponse;
  return proto.services.central.GetTibberAuthURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTibberAuthURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTibberAuthURLResponse}
 */
proto.services.central.GetTibberAuthURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTibberAuthURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTibberAuthURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTibberAuthURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_url = 1;
 * @return {string}
 */
proto.services.central.GetTibberAuthURLResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTibberAuthURLResponse} returns this
 */
proto.services.central.GetTibberAuthURLResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTibberAuthTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTibberAuthTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTibberAuthTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizationCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTibberAuthTokenRequest}
 */
proto.services.central.GetTibberAuthTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTibberAuthTokenRequest;
  return proto.services.central.GetTibberAuthTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTibberAuthTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTibberAuthTokenRequest}
 */
proto.services.central.GetTibberAuthTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizationCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTibberAuthTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTibberAuthTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTibberAuthTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizationCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string authorization_code = 1;
 * @return {string}
 */
proto.services.central.GetTibberAuthTokenRequest.prototype.getAuthorizationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTibberAuthTokenRequest} returns this
 */
proto.services.central.GetTibberAuthTokenRequest.prototype.setAuthorizationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTibberAuthTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTibberAuthTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTibberAuthTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTibberAuthTokenResponse}
 */
proto.services.central.GetTibberAuthTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTibberAuthTokenResponse;
  return proto.services.central.GetTibberAuthTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTibberAuthTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTibberAuthTokenResponse}
 */
proto.services.central.GetTibberAuthTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTibberAuthTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTibberAuthTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTibberAuthTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTibberAuthTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.services.central.GetTibberAuthTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTibberAuthTokenResponse} returns this
 */
proto.services.central.GetTibberAuthTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LobaroDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LobaroDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LobaroDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LobaroDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    gatewayId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    latestReading: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    readingUnit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    idDataLinkLayer: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LobaroDevice}
 */
proto.services.central.LobaroDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LobaroDevice;
  return proto.services.central.LobaroDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LobaroDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LobaroDevice}
 */
proto.services.central.LobaroDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGatewayId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisabled(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatestReading(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReadingUnit(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdDataLinkLayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LobaroDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LobaroDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LobaroDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LobaroDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGatewayId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsDisabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLatestReading();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getReadingUnit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIdDataLinkLayer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 gateway_id = 1;
 * @return {number}
 */
proto.services.central.LobaroDevice.prototype.getGatewayId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setGatewayId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.central.LobaroDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.services.central.LobaroDevice.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_disabled = 4;
 * @return {boolean}
 */
proto.services.central.LobaroDevice.prototype.getIsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setIsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional double latest_reading = 5;
 * @return {number}
 */
proto.services.central.LobaroDevice.prototype.getLatestReading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setLatestReading = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string reading_unit = 6;
 * @return {string}
 */
proto.services.central.LobaroDevice.prototype.getReadingUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setReadingUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id_data_link_layer = 7;
 * @return {string}
 */
proto.services.central.LobaroDevice.prototype.getIdDataLinkLayer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LobaroDevice} returns this
 */
proto.services.central.LobaroDevice.prototype.setIdDataLinkLayer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLobaroDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLobaroDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLobaroDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLobaroDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLobaroDevicesRequest}
 */
proto.services.central.GetLobaroDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLobaroDevicesRequest;
  return proto.services.central.GetLobaroDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLobaroDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLobaroDevicesRequest}
 */
proto.services.central.GetLobaroDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLobaroDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLobaroDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLobaroDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLobaroDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.services.central.GetLobaroDevicesRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetLobaroDevicesRequest} returns this
 */
proto.services.central.GetLobaroDevicesRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetLobaroDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetLobaroDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetLobaroDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetLobaroDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLobaroDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.LobaroDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetLobaroDevicesResponse}
 */
proto.services.central.GetLobaroDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetLobaroDevicesResponse;
  return proto.services.central.GetLobaroDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetLobaroDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetLobaroDevicesResponse}
 */
proto.services.central.GetLobaroDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.LobaroDevice;
      reader.readMessage(value,proto.services.central.LobaroDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetLobaroDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetLobaroDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetLobaroDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetLobaroDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.LobaroDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LobaroDevice devices = 1;
 * @return {!Array<!proto.services.central.LobaroDevice>}
 */
proto.services.central.GetLobaroDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.LobaroDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.LobaroDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.LobaroDevice>} value
 * @return {!proto.services.central.GetLobaroDevicesResponse} returns this
*/
proto.services.central.GetLobaroDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.LobaroDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.LobaroDevice}
 */
proto.services.central.GetLobaroDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.LobaroDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetLobaroDevicesResponse} returns this
 */
proto.services.central.GetLobaroDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarEdgeSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarEdgeSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarEdgeSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarEdgeSitesRequest}
 */
proto.services.central.GetSolarEdgeSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarEdgeSitesRequest;
  return proto.services.central.GetSolarEdgeSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarEdgeSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarEdgeSitesRequest}
 */
proto.services.central.GetSolarEdgeSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarEdgeSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarEdgeSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarEdgeSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string api_key = 1;
 * @return {string}
 */
proto.services.central.GetSolarEdgeSitesRequest.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSolarEdgeSitesRequest} returns this
 */
proto.services.central.GetSolarEdgeSitesRequest.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarEdgeDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarEdgeDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarEdgeDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarEdgeDevicesRequest}
 */
proto.services.central.GetSolarEdgeDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarEdgeDevicesRequest;
  return proto.services.central.GetSolarEdgeDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarEdgeDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarEdgeDevicesRequest}
 */
proto.services.central.GetSolarEdgeDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarEdgeDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarEdgeDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarEdgeDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string api_key = 1;
 * @return {string}
 */
proto.services.central.GetSolarEdgeDevicesRequest.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSolarEdgeDevicesRequest} returns this
 */
proto.services.central.GetSolarEdgeDevicesRequest.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 site_id = 2;
 * @return {number}
 */
proto.services.central.GetSolarEdgeDevicesRequest.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetSolarEdgeDevicesRequest} returns this
 */
proto.services.central.GetSolarEdgeDevicesRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SolarEdgeSite.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SolarEdgeSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SolarEdgeSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SolarEdgeSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SolarEdgeSite}
 */
proto.services.central.SolarEdgeSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SolarEdgeSite;
  return proto.services.central.SolarEdgeSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SolarEdgeSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SolarEdgeSite}
 */
proto.services.central.SolarEdgeSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SolarEdgeSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SolarEdgeSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SolarEdgeSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SolarEdgeSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.services.central.SolarEdgeSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SolarEdgeSite} returns this
 */
proto.services.central.SolarEdgeSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 site_id = 2;
 * @return {number}
 */
proto.services.central.SolarEdgeSite.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.SolarEdgeSite} returns this
 */
proto.services.central.SolarEdgeSite.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetSolarEdgeSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarEdgeSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarEdgeSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarEdgeSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.services.central.SolarEdgeSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarEdgeSitesResponse}
 */
proto.services.central.GetSolarEdgeSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarEdgeSitesResponse;
  return proto.services.central.GetSolarEdgeSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarEdgeSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarEdgeSitesResponse}
 */
proto.services.central.GetSolarEdgeSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.SolarEdgeSite;
      reader.readMessage(value,proto.services.central.SolarEdgeSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarEdgeSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarEdgeSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarEdgeSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.SolarEdgeSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SolarEdgeSite sites = 1;
 * @return {!Array<!proto.services.central.SolarEdgeSite>}
 */
proto.services.central.GetSolarEdgeSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.services.central.SolarEdgeSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.SolarEdgeSite, 1));
};


/**
 * @param {!Array<!proto.services.central.SolarEdgeSite>} value
 * @return {!proto.services.central.GetSolarEdgeSitesResponse} returns this
*/
proto.services.central.GetSolarEdgeSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.SolarEdgeSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.SolarEdgeSite}
 */
proto.services.central.GetSolarEdgeSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.SolarEdgeSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetSolarEdgeSitesResponse} returns this
 */
proto.services.central.GetSolarEdgeSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SolarEdgeDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SolarEdgeDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SolarEdgeDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SolarEdgeDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deviceType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    model: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SolarEdgeDevice}
 */
proto.services.central.SolarEdgeDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SolarEdgeDevice;
  return proto.services.central.SolarEdgeDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SolarEdgeDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SolarEdgeDevice}
 */
proto.services.central.SolarEdgeDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SolarEdgeDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SolarEdgeDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SolarEdgeDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SolarEdgeDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 site_id = 1;
 * @return {number}
 */
proto.services.central.SolarEdgeDevice.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.SolarEdgeDevice} returns this
 */
proto.services.central.SolarEdgeDevice.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string device_type = 2;
 * @return {string}
 */
proto.services.central.SolarEdgeDevice.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SolarEdgeDevice} returns this
 */
proto.services.central.SolarEdgeDevice.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serial_number = 3;
 * @return {string}
 */
proto.services.central.SolarEdgeDevice.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SolarEdgeDevice} returns this
 */
proto.services.central.SolarEdgeDevice.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string manufacturer = 4;
 * @return {string}
 */
proto.services.central.SolarEdgeDevice.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SolarEdgeDevice} returns this
 */
proto.services.central.SolarEdgeDevice.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.services.central.SolarEdgeDevice.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SolarEdgeDevice} returns this
 */
proto.services.central.SolarEdgeDevice.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetSolarEdgeDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarEdgeDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarEdgeDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarEdgeDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.services.central.SolarEdgeDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarEdgeDevicesResponse}
 */
proto.services.central.GetSolarEdgeDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarEdgeDevicesResponse;
  return proto.services.central.GetSolarEdgeDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarEdgeDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarEdgeDevicesResponse}
 */
proto.services.central.GetSolarEdgeDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.SolarEdgeDevice;
      reader.readMessage(value,proto.services.central.SolarEdgeDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarEdgeDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarEdgeDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarEdgeDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarEdgeDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.SolarEdgeDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SolarEdgeDevice devices = 1;
 * @return {!Array<!proto.services.central.SolarEdgeDevice>}
 */
proto.services.central.GetSolarEdgeDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.services.central.SolarEdgeDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.SolarEdgeDevice, 1));
};


/**
 * @param {!Array<!proto.services.central.SolarEdgeDevice>} value
 * @return {!proto.services.central.GetSolarEdgeDevicesResponse} returns this
*/
proto.services.central.GetSolarEdgeDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.SolarEdgeDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.SolarEdgeDevice}
 */
proto.services.central.GetSolarEdgeDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.SolarEdgeDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetSolarEdgeDevicesResponse} returns this
 */
proto.services.central.GetSolarEdgeDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetVirtualDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetVirtualDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetVirtualDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetVirtualDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetVirtualDeviceRequest}
 */
proto.services.central.GetVirtualDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetVirtualDeviceRequest;
  return proto.services.central.GetVirtualDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetVirtualDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetVirtualDeviceRequest}
 */
proto.services.central.GetVirtualDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetVirtualDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetVirtualDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetVirtualDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetVirtualDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetVirtualDeviceRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetVirtualDeviceRequest} returns this
 */
proto.services.central.GetVirtualDeviceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.GetVirtualDeviceRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetVirtualDeviceRequest} returns this
 */
proto.services.central.GetVirtualDeviceRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetVirtualDeviceResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetVirtualDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetVirtualDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetVirtualDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetVirtualDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualDevice: (f = msg.getVirtualDevice()) && types_device_pb.Device.toObject(includeInstance, f),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    types_device_pb.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetVirtualDeviceResponse}
 */
proto.services.central.GetVirtualDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetVirtualDeviceResponse;
  return proto.services.central.GetVirtualDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetVirtualDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetVirtualDeviceResponse}
 */
proto.services.central.GetVirtualDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setVirtualDevice(value);
      break;
    case 2:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetVirtualDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetVirtualDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetVirtualDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetVirtualDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.Device virtual_device = 1;
 * @return {?proto.types.Device}
 */
proto.services.central.GetVirtualDeviceResponse.prototype.getVirtualDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 1));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.services.central.GetVirtualDeviceResponse} returns this
*/
proto.services.central.GetVirtualDeviceResponse.prototype.setVirtualDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetVirtualDeviceResponse} returns this
 */
proto.services.central.GetVirtualDeviceResponse.prototype.clearVirtualDevice = function() {
  return this.setVirtualDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetVirtualDeviceResponse.prototype.hasVirtualDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated types.Device devices = 2;
 * @return {!Array<!proto.types.Device>}
 */
proto.services.central.GetVirtualDeviceResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.types.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_pb.Device, 2));
};


/**
 * @param {!Array<!proto.types.Device>} value
 * @return {!proto.services.central.GetVirtualDeviceResponse} returns this
*/
proto.services.central.GetVirtualDeviceResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Device}
 */
proto.services.central.GetVirtualDeviceResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetVirtualDeviceResponse} returns this
 */
proto.services.central.GetVirtualDeviceResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMetiundoMeteringPointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMetiundoMeteringPointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMetiundoMeteringPointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetiundoMeteringPointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMetiundoMeteringPointsRequest}
 */
proto.services.central.GetMetiundoMeteringPointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMetiundoMeteringPointsRequest;
  return proto.services.central.GetMetiundoMeteringPointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMetiundoMeteringPointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMetiundoMeteringPointsRequest}
 */
proto.services.central.GetMetiundoMeteringPointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMetiundoMeteringPointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMetiundoMeteringPointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMetiundoMeteringPointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetiundoMeteringPointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.services.central.GetMetiundoMeteringPointsRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMetiundoMeteringPointsRequest} returns this
 */
proto.services.central.GetMetiundoMeteringPointsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.services.central.GetMetiundoMeteringPointsRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMetiundoMeteringPointsRequest} returns this
 */
proto.services.central.GetMetiundoMeteringPointsRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMetiundoMeteringPointsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMetiundoMeteringPointsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMetiundoMeteringPointsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMetiundoMeteringPointsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetiundoMeteringPointsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meteringPointsList: jspb.Message.toObjectList(msg.getMeteringPointsList(),
    types_device_registration_pb.MetiundoMeteringPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMetiundoMeteringPointsResponse}
 */
proto.services.central.GetMetiundoMeteringPointsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMetiundoMeteringPointsResponse;
  return proto.services.central.GetMetiundoMeteringPointsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMetiundoMeteringPointsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMetiundoMeteringPointsResponse}
 */
proto.services.central.GetMetiundoMeteringPointsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_registration_pb.MetiundoMeteringPoint;
      reader.readMessage(value,types_device_registration_pb.MetiundoMeteringPoint.deserializeBinaryFromReader);
      msg.addMeteringPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMetiundoMeteringPointsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMetiundoMeteringPointsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMetiundoMeteringPointsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMetiundoMeteringPointsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeteringPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_registration_pb.MetiundoMeteringPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.MetiundoMeteringPoint metering_points = 1;
 * @return {!Array<!proto.types.MetiundoMeteringPoint>}
 */
proto.services.central.GetMetiundoMeteringPointsResponse.prototype.getMeteringPointsList = function() {
  return /** @type{!Array<!proto.types.MetiundoMeteringPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_registration_pb.MetiundoMeteringPoint, 1));
};


/**
 * @param {!Array<!proto.types.MetiundoMeteringPoint>} value
 * @return {!proto.services.central.GetMetiundoMeteringPointsResponse} returns this
*/
proto.services.central.GetMetiundoMeteringPointsResponse.prototype.setMeteringPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.MetiundoMeteringPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.MetiundoMeteringPoint}
 */
proto.services.central.GetMetiundoMeteringPointsResponse.prototype.addMeteringPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.MetiundoMeteringPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMetiundoMeteringPointsResponse} returns this
 */
proto.services.central.GetMetiundoMeteringPointsResponse.prototype.clearMeteringPointsList = function() {
  return this.setMeteringPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBitShakeMeterScriptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBitShakeMeterScriptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBitShakeMeterScriptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeMeterScriptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meterValue: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBitShakeMeterScriptRequest}
 */
proto.services.central.GetBitShakeMeterScriptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBitShakeMeterScriptRequest;
  return proto.services.central.GetBitShakeMeterScriptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBitShakeMeterScriptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBitShakeMeterScriptRequest}
 */
proto.services.central.GetBitShakeMeterScriptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBitShakeMeterScriptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBitShakeMeterScriptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBitShakeMeterScriptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeMeterScriptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeterValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string meter_value = 1;
 * @return {string}
 */
proto.services.central.GetBitShakeMeterScriptRequest.prototype.getMeterValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetBitShakeMeterScriptRequest} returns this
 */
proto.services.central.GetBitShakeMeterScriptRequest.prototype.setMeterValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBitShakeMeterScriptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBitShakeMeterScriptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBitShakeMeterScriptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeMeterScriptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    script: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBitShakeMeterScriptResponse}
 */
proto.services.central.GetBitShakeMeterScriptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBitShakeMeterScriptResponse;
  return proto.services.central.GetBitShakeMeterScriptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBitShakeMeterScriptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBitShakeMeterScriptResponse}
 */
proto.services.central.GetBitShakeMeterScriptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBitShakeMeterScriptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBitShakeMeterScriptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBitShakeMeterScriptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeMeterScriptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScript();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string script = 1;
 * @return {string}
 */
proto.services.central.GetBitShakeMeterScriptResponse.prototype.getScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetBitShakeMeterScriptResponse} returns this
 */
proto.services.central.GetBitShakeMeterScriptResponse.prototype.setScript = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBitShakeSupportedMetersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBitShakeSupportedMetersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBitShakeSupportedMetersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeSupportedMetersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBitShakeSupportedMetersRequest}
 */
proto.services.central.GetBitShakeSupportedMetersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBitShakeSupportedMetersRequest;
  return proto.services.central.GetBitShakeSupportedMetersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBitShakeSupportedMetersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBitShakeSupportedMetersRequest}
 */
proto.services.central.GetBitShakeSupportedMetersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBitShakeSupportedMetersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBitShakeSupportedMetersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBitShakeSupportedMetersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeSupportedMetersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetBitShakeSupportedMetersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetBitShakeSupportedMetersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetBitShakeSupportedMetersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetBitShakeSupportedMetersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeSupportedMetersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metersList: jspb.Message.toObjectList(msg.getMetersList(),
    proto.services.central.BitShakeMeter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetBitShakeSupportedMetersResponse}
 */
proto.services.central.GetBitShakeSupportedMetersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetBitShakeSupportedMetersResponse;
  return proto.services.central.GetBitShakeSupportedMetersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetBitShakeSupportedMetersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetBitShakeSupportedMetersResponse}
 */
proto.services.central.GetBitShakeSupportedMetersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.BitShakeMeter;
      reader.readMessage(value,proto.services.central.BitShakeMeter.deserializeBinaryFromReader);
      msg.addMeters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetBitShakeSupportedMetersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetBitShakeSupportedMetersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetBitShakeSupportedMetersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetBitShakeSupportedMetersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.BitShakeMeter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BitShakeMeter meters = 1;
 * @return {!Array<!proto.services.central.BitShakeMeter>}
 */
proto.services.central.GetBitShakeSupportedMetersResponse.prototype.getMetersList = function() {
  return /** @type{!Array<!proto.services.central.BitShakeMeter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.BitShakeMeter, 1));
};


/**
 * @param {!Array<!proto.services.central.BitShakeMeter>} value
 * @return {!proto.services.central.GetBitShakeSupportedMetersResponse} returns this
*/
proto.services.central.GetBitShakeSupportedMetersResponse.prototype.setMetersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.BitShakeMeter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.BitShakeMeter}
 */
proto.services.central.GetBitShakeSupportedMetersResponse.prototype.addMeters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.BitShakeMeter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetBitShakeSupportedMetersResponse} returns this
 */
proto.services.central.GetBitShakeSupportedMetersResponse.prototype.clearMetersList = function() {
  return this.setMetersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.BitShakeMeter.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.BitShakeMeter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.BitShakeMeter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BitShakeMeter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.BitShakeMeter}
 */
proto.services.central.BitShakeMeter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.BitShakeMeter;
  return proto.services.central.BitShakeMeter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.BitShakeMeter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.BitShakeMeter}
 */
proto.services.central.BitShakeMeter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.BitShakeMeter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.BitShakeMeter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.BitShakeMeter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.BitShakeMeter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.services.central.BitShakeMeter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BitShakeMeter} returns this
 */
proto.services.central.BitShakeMeter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.services.central.BitShakeMeter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.BitShakeMeter} returns this
 */
proto.services.central.BitShakeMeter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetIometerQRCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetIometerQRCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetIometerQRCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetIometerQRCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetIometerQRCodeRequest}
 */
proto.services.central.GetIometerQRCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetIometerQRCodeRequest;
  return proto.services.central.GetIometerQRCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetIometerQRCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetIometerQRCodeRequest}
 */
proto.services.central.GetIometerQRCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetIometerQRCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetIometerQRCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetIometerQRCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetIometerQRCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetIometerQRCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetIometerQRCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetIometerQRCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetIometerQRCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    qrCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetIometerQRCodeResponse}
 */
proto.services.central.GetIometerQRCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetIometerQRCodeResponse;
  return proto.services.central.GetIometerQRCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetIometerQRCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetIometerQRCodeResponse}
 */
proto.services.central.GetIometerQRCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQrCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetIometerQRCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetIometerQRCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetIometerQRCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetIometerQRCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQrCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetIometerQRCodeResponse.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetIometerQRCodeResponse} returns this
 */
proto.services.central.GetIometerQRCodeResponse.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string qr_code = 2;
 * @return {string}
 */
proto.services.central.GetIometerQRCodeResponse.prototype.getQrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetIometerQRCodeResponse} returns this
 */
proto.services.central.GetIometerQRCodeResponse.prototype.setQrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMeteocontrolSystemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMeteocontrolSystemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMeteocontrolSystemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolSystemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMeteocontrolSystemsRequest}
 */
proto.services.central.GetMeteocontrolSystemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMeteocontrolSystemsRequest;
  return proto.services.central.GetMeteocontrolSystemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMeteocontrolSystemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMeteocontrolSystemsRequest}
 */
proto.services.central.GetMeteocontrolSystemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMeteocontrolSystemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMeteocontrolSystemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMeteocontrolSystemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolSystemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.services.central.GetMeteocontrolSystemsRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMeteocontrolSystemsRequest} returns this
 */
proto.services.central.GetMeteocontrolSystemsRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMeteocontrolSystemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMeteocontrolSystemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMeteocontrolSystemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMeteocontrolSystemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolSystemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemsList: jspb.Message.toObjectList(msg.getSystemsList(),
    proto.services.central.MeteocontrolSystem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMeteocontrolSystemsResponse}
 */
proto.services.central.GetMeteocontrolSystemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMeteocontrolSystemsResponse;
  return proto.services.central.GetMeteocontrolSystemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMeteocontrolSystemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMeteocontrolSystemsResponse}
 */
proto.services.central.GetMeteocontrolSystemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.MeteocontrolSystem;
      reader.readMessage(value,proto.services.central.MeteocontrolSystem.deserializeBinaryFromReader);
      msg.addSystems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMeteocontrolSystemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMeteocontrolSystemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMeteocontrolSystemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolSystemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.MeteocontrolSystem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MeteocontrolSystem systems = 1;
 * @return {!Array<!proto.services.central.MeteocontrolSystem>}
 */
proto.services.central.GetMeteocontrolSystemsResponse.prototype.getSystemsList = function() {
  return /** @type{!Array<!proto.services.central.MeteocontrolSystem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.MeteocontrolSystem, 1));
};


/**
 * @param {!Array<!proto.services.central.MeteocontrolSystem>} value
 * @return {!proto.services.central.GetMeteocontrolSystemsResponse} returns this
*/
proto.services.central.GetMeteocontrolSystemsResponse.prototype.setSystemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.MeteocontrolSystem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.MeteocontrolSystem}
 */
proto.services.central.GetMeteocontrolSystemsResponse.prototype.addSystems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.MeteocontrolSystem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMeteocontrolSystemsResponse} returns this
 */
proto.services.central.GetMeteocontrolSystemsResponse.prototype.clearSystemsList = function() {
  return this.setSystemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MeteocontrolSystem.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MeteocontrolSystem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MeteocontrolSystem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MeteocontrolSystem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MeteocontrolSystem}
 */
proto.services.central.MeteocontrolSystem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MeteocontrolSystem;
  return proto.services.central.MeteocontrolSystem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MeteocontrolSystem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MeteocontrolSystem}
 */
proto.services.central.MeteocontrolSystem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MeteocontrolSystem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MeteocontrolSystem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MeteocontrolSystem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MeteocontrolSystem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.services.central.MeteocontrolSystem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MeteocontrolSystem} returns this
 */
proto.services.central.MeteocontrolSystem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string system_id = 2;
 * @return {string}
 */
proto.services.central.MeteocontrolSystem.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MeteocontrolSystem} returns this
 */
proto.services.central.MeteocontrolSystem.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMeteocontrolInvertersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMeteocontrolInvertersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMeteocontrolInvertersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolInvertersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMeteocontrolInvertersRequest}
 */
proto.services.central.GetMeteocontrolInvertersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMeteocontrolInvertersRequest;
  return proto.services.central.GetMeteocontrolInvertersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMeteocontrolInvertersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMeteocontrolInvertersRequest}
 */
proto.services.central.GetMeteocontrolInvertersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMeteocontrolInvertersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMeteocontrolInvertersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMeteocontrolInvertersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolInvertersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.services.central.GetMeteocontrolInvertersRequest.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMeteocontrolInvertersRequest} returns this
 */
proto.services.central.GetMeteocontrolInvertersRequest.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.services.central.GetMeteocontrolInvertersRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMeteocontrolInvertersRequest} returns this
 */
proto.services.central.GetMeteocontrolInvertersRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetMeteocontrolInvertersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetMeteocontrolInvertersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetMeteocontrolInvertersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolInvertersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invertersList: jspb.Message.toObjectList(msg.getInvertersList(),
    proto.services.central.MeteocontrolInverters.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetMeteocontrolInvertersResponse}
 */
proto.services.central.GetMeteocontrolInvertersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetMeteocontrolInvertersResponse;
  return proto.services.central.GetMeteocontrolInvertersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetMeteocontrolInvertersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetMeteocontrolInvertersResponse}
 */
proto.services.central.GetMeteocontrolInvertersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = new proto.services.central.MeteocontrolInverters;
      reader.readMessage(value,proto.services.central.MeteocontrolInverters.deserializeBinaryFromReader);
      msg.addInverters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetMeteocontrolInvertersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetMeteocontrolInvertersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetMeteocontrolInvertersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvertersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.central.MeteocontrolInverters.serializeBinaryToWriter
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetMeteocontrolInvertersResponse} returns this
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MeteocontrolInverters inverters = 2;
 * @return {!Array<!proto.services.central.MeteocontrolInverters>}
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.getInvertersList = function() {
  return /** @type{!Array<!proto.services.central.MeteocontrolInverters>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.MeteocontrolInverters, 2));
};


/**
 * @param {!Array<!proto.services.central.MeteocontrolInverters>} value
 * @return {!proto.services.central.GetMeteocontrolInvertersResponse} returns this
*/
proto.services.central.GetMeteocontrolInvertersResponse.prototype.setInvertersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.central.MeteocontrolInverters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.MeteocontrolInverters}
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.addInverters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.central.MeteocontrolInverters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetMeteocontrolInvertersResponse} returns this
 */
proto.services.central.GetMeteocontrolInvertersResponse.prototype.clearInvertersList = function() {
  return this.setInvertersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.MeteocontrolInverters.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.MeteocontrolInverters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.MeteocontrolInverters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MeteocontrolInverters.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serial: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.MeteocontrolInverters}
 */
proto.services.central.MeteocontrolInverters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.MeteocontrolInverters;
  return proto.services.central.MeteocontrolInverters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.MeteocontrolInverters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.MeteocontrolInverters}
 */
proto.services.central.MeteocontrolInverters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.MeteocontrolInverters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.MeteocontrolInverters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.MeteocontrolInverters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.MeteocontrolInverters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.MeteocontrolInverters.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MeteocontrolInverters} returns this
 */
proto.services.central.MeteocontrolInverters.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.central.MeteocontrolInverters.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MeteocontrolInverters} returns this
 */
proto.services.central.MeteocontrolInverters.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serial = 3;
 * @return {string}
 */
proto.services.central.MeteocontrolInverters.prototype.getSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.MeteocontrolInverters} returns this
 */
proto.services.central.MeteocontrolInverters.prototype.setSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetExportDataTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetExportDataTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetExportDataTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetExportDataTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetExportDataTypesRequest}
 */
proto.services.central.GetExportDataTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetExportDataTypesRequest;
  return proto.services.central.GetExportDataTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetExportDataTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetExportDataTypesRequest}
 */
proto.services.central.GetExportDataTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetExportDataTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetExportDataTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetExportDataTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetExportDataTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.services.central.GetExportDataTypesRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetExportDataTypesRequest} returns this
 */
proto.services.central.GetExportDataTypesRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetExportDataTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetExportDataTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetExportDataTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetExportDataTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetExportDataTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableTypesList: jspb.Message.toObjectList(msg.getAvailableTypesList(),
    proto.services.central.ExportDataTypeWithLabel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetExportDataTypesResponse}
 */
proto.services.central.GetExportDataTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetExportDataTypesResponse;
  return proto.services.central.GetExportDataTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetExportDataTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetExportDataTypesResponse}
 */
proto.services.central.GetExportDataTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.ExportDataTypeWithLabel;
      reader.readMessage(value,proto.services.central.ExportDataTypeWithLabel.deserializeBinaryFromReader);
      msg.addAvailableTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetExportDataTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetExportDataTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetExportDataTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetExportDataTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.ExportDataTypeWithLabel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExportDataTypeWithLabel available_types = 1;
 * @return {!Array<!proto.services.central.ExportDataTypeWithLabel>}
 */
proto.services.central.GetExportDataTypesResponse.prototype.getAvailableTypesList = function() {
  return /** @type{!Array<!proto.services.central.ExportDataTypeWithLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.ExportDataTypeWithLabel, 1));
};


/**
 * @param {!Array<!proto.services.central.ExportDataTypeWithLabel>} value
 * @return {!proto.services.central.GetExportDataTypesResponse} returns this
*/
proto.services.central.GetExportDataTypesResponse.prototype.setAvailableTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.ExportDataTypeWithLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.ExportDataTypeWithLabel}
 */
proto.services.central.GetExportDataTypesResponse.prototype.addAvailableTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.ExportDataTypeWithLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetExportDataTypesResponse} returns this
 */
proto.services.central.GetExportDataTypesResponse.prototype.clearAvailableTypesList = function() {
  return this.setAvailableTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.ExportDataTypeWithLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.ExportDataTypeWithLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.ExportDataTypeWithLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportDataTypeWithLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.ExportDataTypeWithLabel}
 */
proto.services.central.ExportDataTypeWithLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.ExportDataTypeWithLabel;
  return proto.services.central.ExportDataTypeWithLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.ExportDataTypeWithLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.ExportDataTypeWithLabel}
 */
proto.services.central.ExportDataTypeWithLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.types.ExportDataType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.ExportDataTypeWithLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.ExportDataTypeWithLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.ExportDataTypeWithLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.ExportDataTypeWithLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.services.central.ExportDataTypeWithLabel.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.ExportDataTypeWithLabel} returns this
 */
proto.services.central.ExportDataTypeWithLabel.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.ExportDataType type = 2;
 * @return {!proto.types.ExportDataType}
 */
proto.services.central.ExportDataTypeWithLabel.prototype.getType = function() {
  return /** @type {!proto.types.ExportDataType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.ExportDataType} value
 * @return {!proto.services.central.ExportDataTypeWithLabel} returns this
 */
proto.services.central.ExportDataTypeWithLabel.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarizeSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarizeSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarizeSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && types_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarizeSitesRequest}
 */
proto.services.central.GetSolarizeSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarizeSitesRequest;
  return proto.services.central.GetSolarizeSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarizeSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarizeSitesRequest}
 */
proto.services.central.GetSolarizeSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    case 2:
      var value = new types_pagination_pb.Pagination;
      reader.readMessage(value,types_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarizeSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarizeSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarizeSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string api_token = 1;
 * @return {string}
 */
proto.services.central.GetSolarizeSitesRequest.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSolarizeSitesRequest} returns this
 */
proto.services.central.GetSolarizeSitesRequest.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Pagination pagination = 2;
 * @return {?proto.types.Pagination}
 */
proto.services.central.GetSolarizeSitesRequest.prototype.getPagination = function() {
  return /** @type{?proto.types.Pagination} */ (
    jspb.Message.getWrapperField(this, types_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.types.Pagination|undefined} value
 * @return {!proto.services.central.GetSolarizeSitesRequest} returns this
*/
proto.services.central.GetSolarizeSitesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetSolarizeSitesRequest} returns this
 */
proto.services.central.GetSolarizeSitesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetSolarizeSitesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetSolarizeSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarizeSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarizeSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarizeSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    types_device_registration_pb.SolarizeSite.toObject, includeInstance),
    totalNumPages: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarizeSitesResponse}
 */
proto.services.central.GetSolarizeSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarizeSitesResponse;
  return proto.services.central.GetSolarizeSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarizeSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarizeSitesResponse}
 */
proto.services.central.GetSolarizeSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_registration_pb.SolarizeSite;
      reader.readMessage(value,types_device_registration_pb.SolarizeSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarizeSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarizeSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarizeSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_registration_pb.SolarizeSite.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated types.SolarizeSite sites = 1;
 * @return {!Array<!proto.types.SolarizeSite>}
 */
proto.services.central.GetSolarizeSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.types.SolarizeSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_registration_pb.SolarizeSite, 1));
};


/**
 * @param {!Array<!proto.types.SolarizeSite>} value
 * @return {!proto.services.central.GetSolarizeSitesResponse} returns this
*/
proto.services.central.GetSolarizeSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.SolarizeSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SolarizeSite}
 */
proto.services.central.GetSolarizeSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.SolarizeSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetSolarizeSitesResponse} returns this
 */
proto.services.central.GetSolarizeSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional int32 total_num_pages = 2;
 * @return {number}
 */
proto.services.central.GetSolarizeSitesResponse.prototype.getTotalNumPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetSolarizeSitesResponse} returns this
 */
proto.services.central.GetSolarizeSitesResponse.prototype.setTotalNumPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarizeMetersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarizeMetersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarizeMetersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeMetersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagination: (f = msg.getPagination()) && types_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarizeMetersRequest}
 */
proto.services.central.GetSolarizeMetersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarizeMetersRequest;
  return proto.services.central.GetSolarizeMetersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarizeMetersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarizeMetersRequest}
 */
proto.services.central.GetSolarizeMetersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    case 3:
      var value = new types_pagination_pb.Pagination;
      reader.readMessage(value,types_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarizeMetersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarizeMetersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarizeMetersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeMetersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.services.central.GetSolarizeMetersRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSolarizeMetersRequest} returns this
 */
proto.services.central.GetSolarizeMetersRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_token = 2;
 * @return {string}
 */
proto.services.central.GetSolarizeMetersRequest.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSolarizeMetersRequest} returns this
 */
proto.services.central.GetSolarizeMetersRequest.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional types.Pagination pagination = 3;
 * @return {?proto.types.Pagination}
 */
proto.services.central.GetSolarizeMetersRequest.prototype.getPagination = function() {
  return /** @type{?proto.types.Pagination} */ (
    jspb.Message.getWrapperField(this, types_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.types.Pagination|undefined} value
 * @return {!proto.services.central.GetSolarizeMetersRequest} returns this
*/
proto.services.central.GetSolarizeMetersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetSolarizeMetersRequest} returns this
 */
proto.services.central.GetSolarizeMetersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetSolarizeMetersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetSolarizeMetersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSolarizeMetersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSolarizeMetersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSolarizeMetersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeMetersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metersList: jspb.Message.toObjectList(msg.getMetersList(),
    types_device_registration_pb.SolarizeMeter.toObject, includeInstance),
    totalNumPages: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSolarizeMetersResponse}
 */
proto.services.central.GetSolarizeMetersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSolarizeMetersResponse;
  return proto.services.central.GetSolarizeMetersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSolarizeMetersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSolarizeMetersResponse}
 */
proto.services.central.GetSolarizeMetersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_device_registration_pb.SolarizeMeter;
      reader.readMessage(value,types_device_registration_pb.SolarizeMeter.deserializeBinaryFromReader);
      msg.addMeters(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSolarizeMetersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSolarizeMetersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSolarizeMetersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSolarizeMetersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_device_registration_pb.SolarizeMeter.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated types.SolarizeMeter meters = 1;
 * @return {!Array<!proto.types.SolarizeMeter>}
 */
proto.services.central.GetSolarizeMetersResponse.prototype.getMetersList = function() {
  return /** @type{!Array<!proto.types.SolarizeMeter>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_device_registration_pb.SolarizeMeter, 1));
};


/**
 * @param {!Array<!proto.types.SolarizeMeter>} value
 * @return {!proto.services.central.GetSolarizeMetersResponse} returns this
*/
proto.services.central.GetSolarizeMetersResponse.prototype.setMetersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.SolarizeMeter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SolarizeMeter}
 */
proto.services.central.GetSolarizeMetersResponse.prototype.addMeters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.SolarizeMeter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetSolarizeMetersResponse} returns this
 */
proto.services.central.GetSolarizeMetersResponse.prototype.clearMetersList = function() {
  return this.setMetersList([]);
};


/**
 * optional int32 total_num_pages = 2;
 * @return {number}
 */
proto.services.central.GetSolarizeMetersResponse.prototype.getTotalNumPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetSolarizeMetersResponse} returns this
 */
proto.services.central.GetSolarizeMetersResponse.prototype.setTotalNumPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.services.central);
