
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ZaehlerfreundeLogo extends Vue {
  @Prop({ default: false }) dark: boolean;

  onLogoClicked(): void {
    this.$emit("logo-clicked");
  }
}
