// source: types/forwarding.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
var types_device_reading_pb = require('../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
goog.exportSymbol('proto.types.DynamicTopic', null, global);
goog.exportSymbol('proto.types.DynamicTopic.DynamicPart', null, global);
goog.exportSymbol('proto.types.ForwardingDestination', null, global);
goog.exportSymbol('proto.types.ForwardingDestination.DestinationCase', null, global);
goog.exportSymbol('proto.types.ForwardingDestination.Format', null, global);
goog.exportSymbol('proto.types.ForwardingRule', null, global);
goog.exportSymbol('proto.types.MqttDestination', null, global);
goog.exportSymbol('proto.types.MqttDestination.TopicCase', null, global);
goog.exportSymbol('proto.types.NoDestination', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ForwardingRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.ForwardingRule.repeatedFields_, null);
};
goog.inherits(proto.types.ForwardingRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ForwardingRule.displayName = 'proto.types.ForwardingRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ForwardingDestination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.ForwardingDestination.oneofGroups_);
};
goog.inherits(proto.types.ForwardingDestination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ForwardingDestination.displayName = 'proto.types.ForwardingDestination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MqttDestination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.MqttDestination.oneofGroups_);
};
goog.inherits(proto.types.MqttDestination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MqttDestination.displayName = 'proto.types.MqttDestination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.NoDestination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.NoDestination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.NoDestination.displayName = 'proto.types.NoDestination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DynamicTopic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DynamicTopic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DynamicTopic.displayName = 'proto.types.DynamicTopic';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.ForwardingRule.repeatedFields_ = [3,4,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ForwardingRule.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ForwardingRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ForwardingRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ForwardingRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceProvidersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    measurementsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    destination: (f = msg.getDestination()) && proto.types.ForwardingDestination.toObject(includeInstance, f),
    deviceProviderNamesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    measurementNamesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ForwardingRule}
 */
proto.types.ForwardingRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ForwardingRule;
  return proto.types.ForwardingRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ForwardingRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ForwardingRule}
 */
proto.types.ForwardingRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.types.Device.Provider>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceProviders(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.types.Measurement>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMeasurements(values[i]);
      }
      break;
    case 5:
      var value = new proto.types.ForwardingDestination;
      reader.readMessage(value,proto.types.ForwardingDestination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceProviderNames(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addMeasurementNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ForwardingRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ForwardingRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ForwardingRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ForwardingRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceProvidersList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.types.ForwardingDestination.serializeBinaryToWriter
    );
  }
  f = message.getDeviceProviderNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getMeasurementNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.ForwardingRule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partner_id = 2;
 * @return {string}
 */
proto.types.ForwardingRule.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Device.Provider device_providers = 3;
 * @return {!Array<!proto.types.Device.Provider>}
 */
proto.types.ForwardingRule.prototype.getDeviceProvidersList = function() {
  return /** @type {!Array<!proto.types.Device.Provider>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.types.Device.Provider>} value
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.setDeviceProvidersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.types.Device.Provider} value
 * @param {number=} opt_index
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.addDeviceProviders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.clearDeviceProvidersList = function() {
  return this.setDeviceProvidersList([]);
};


/**
 * repeated Measurement measurements = 4;
 * @return {!Array<!proto.types.Measurement>}
 */
proto.types.ForwardingRule.prototype.getMeasurementsList = function() {
  return /** @type {!Array<!proto.types.Measurement>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.types.Measurement>} value
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.types.Measurement} value
 * @param {number=} opt_index
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.addMeasurements = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * optional ForwardingDestination destination = 5;
 * @return {?proto.types.ForwardingDestination}
 */
proto.types.ForwardingRule.prototype.getDestination = function() {
  return /** @type{?proto.types.ForwardingDestination} */ (
    jspb.Message.getWrapperField(this, proto.types.ForwardingDestination, 5));
};


/**
 * @param {?proto.types.ForwardingDestination|undefined} value
 * @return {!proto.types.ForwardingRule} returns this
*/
proto.types.ForwardingRule.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ForwardingRule.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string device_provider_names = 6;
 * @return {!Array<string>}
 */
proto.types.ForwardingRule.prototype.getDeviceProviderNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.setDeviceProviderNamesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.addDeviceProviderNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.clearDeviceProviderNamesList = function() {
  return this.setDeviceProviderNamesList([]);
};


/**
 * repeated string measurement_names = 7;
 * @return {!Array<string>}
 */
proto.types.ForwardingRule.prototype.getMeasurementNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.setMeasurementNamesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.addMeasurementNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.ForwardingRule} returns this
 */
proto.types.ForwardingRule.prototype.clearMeasurementNamesList = function() {
  return this.setMeasurementNamesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.ForwardingDestination.oneofGroups_ = [[5,7]];

/**
 * @enum {number}
 */
proto.types.ForwardingDestination.DestinationCase = {
  DESTINATION_NOT_SET: 0,
  MQTT_DESTINATION: 5,
  NO_DESTINATION: 7
};

/**
 * @return {proto.types.ForwardingDestination.DestinationCase}
 */
proto.types.ForwardingDestination.prototype.getDestinationCase = function() {
  return /** @type {proto.types.ForwardingDestination.DestinationCase} */(jspb.Message.computeOneofCase(this, proto.types.ForwardingDestination.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ForwardingDestination.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ForwardingDestination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ForwardingDestination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ForwardingDestination.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    format: jspb.Message.getFieldWithDefault(msg, 3, 0),
    formatName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    device: (f = msg.getDevice()) && types_device_pb.Device.toObject(includeInstance, f),
    mqttDestination: (f = msg.getMqttDestination()) && proto.types.MqttDestination.toObject(includeInstance, f),
    noDestination: (f = msg.getNoDestination()) && proto.types.NoDestination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ForwardingDestination}
 */
proto.types.ForwardingDestination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ForwardingDestination;
  return proto.types.ForwardingDestination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ForwardingDestination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ForwardingDestination}
 */
proto.types.ForwardingDestination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.types.ForwardingDestination.Format} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormatName(value);
      break;
    case 4:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 5:
      var value = new proto.types.MqttDestination;
      reader.readMessage(value,proto.types.MqttDestination.deserializeBinaryFromReader);
      msg.setMqttDestination(value);
      break;
    case 7:
      var value = new proto.types.NoDestination;
      reader.readMessage(value,proto.types.NoDestination.deserializeBinaryFromReader);
      msg.setNoDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ForwardingDestination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ForwardingDestination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ForwardingDestination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ForwardingDestination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFormatName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getMqttDestination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.types.MqttDestination.serializeBinaryToWriter
    );
  }
  f = message.getNoDestination();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.types.NoDestination.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.types.ForwardingDestination.Format = {
  E3: 0,
  RAW: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.ForwardingDestination.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.ForwardingDestination.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Format format = 3;
 * @return {!proto.types.ForwardingDestination.Format}
 */
proto.types.ForwardingDestination.prototype.getFormat = function() {
  return /** @type {!proto.types.ForwardingDestination.Format} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.ForwardingDestination.Format} value
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string format_name = 6;
 * @return {string}
 */
proto.types.ForwardingDestination.prototype.getFormatName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.setFormatName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Device device = 4;
 * @return {?proto.types.Device}
 */
proto.types.ForwardingDestination.prototype.getDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 4));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.types.ForwardingDestination} returns this
*/
proto.types.ForwardingDestination.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ForwardingDestination.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MqttDestination mqtt_destination = 5;
 * @return {?proto.types.MqttDestination}
 */
proto.types.ForwardingDestination.prototype.getMqttDestination = function() {
  return /** @type{?proto.types.MqttDestination} */ (
    jspb.Message.getWrapperField(this, proto.types.MqttDestination, 5));
};


/**
 * @param {?proto.types.MqttDestination|undefined} value
 * @return {!proto.types.ForwardingDestination} returns this
*/
proto.types.ForwardingDestination.prototype.setMqttDestination = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.types.ForwardingDestination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.clearMqttDestination = function() {
  return this.setMqttDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ForwardingDestination.prototype.hasMqttDestination = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NoDestination no_destination = 7;
 * @return {?proto.types.NoDestination}
 */
proto.types.ForwardingDestination.prototype.getNoDestination = function() {
  return /** @type{?proto.types.NoDestination} */ (
    jspb.Message.getWrapperField(this, proto.types.NoDestination, 7));
};


/**
 * @param {?proto.types.NoDestination|undefined} value
 * @return {!proto.types.ForwardingDestination} returns this
*/
proto.types.ForwardingDestination.prototype.setNoDestination = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.types.ForwardingDestination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.ForwardingDestination} returns this
 */
proto.types.ForwardingDestination.prototype.clearNoDestination = function() {
  return this.setNoDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ForwardingDestination.prototype.hasNoDestination = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.MqttDestination.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.types.MqttDestination.TopicCase = {
  TOPIC_NOT_SET: 0,
  STATIC_TOPIC: 6,
  DYNAMIC_TOPIC: 7
};

/**
 * @return {proto.types.MqttDestination.TopicCase}
 */
proto.types.MqttDestination.prototype.getTopicCase = function() {
  return /** @type {proto.types.MqttDestination.TopicCase} */(jspb.Message.computeOneofCase(this, proto.types.MqttDestination.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MqttDestination.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MqttDestination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MqttDestination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MqttDestination.toObject = function(includeInstance, msg) {
  var f, obj = {
    broker: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tls: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    certificate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    staticTopic: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dynamicTopic: (f = msg.getDynamicTopic()) && proto.types.DynamicTopic.toObject(includeInstance, f),
    heartbeatTopic: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MqttDestination}
 */
proto.types.MqttDestination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MqttDestination;
  return proto.types.MqttDestination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MqttDestination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MqttDestination}
 */
proto.types.MqttDestination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTls(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCertificate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaticTopic(value);
      break;
    case 7:
      var value = new proto.types.DynamicTopic;
      reader.readMessage(value,proto.types.DynamicTopic.deserializeBinaryFromReader);
      msg.setDynamicTopic(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeartbeatTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MqttDestination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MqttDestination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MqttDestination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MqttDestination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTls();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDynamicTopic();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.types.DynamicTopic.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string broker = 1;
 * @return {string}
 */
proto.types.MqttDestination.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.types.MqttDestination.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setUsername = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.clearUsername = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.types.MqttDestination.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setPassword = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.clearPassword = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool tls = 4;
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.getTls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setTls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string certificate = 5;
 * @return {string}
 */
proto.types.MqttDestination.prototype.getCertificate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setCertificate = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.clearCertificate = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.hasCertificate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string static_topic = 6;
 * @return {string}
 */
proto.types.MqttDestination.prototype.getStaticTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setStaticTopic = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.types.MqttDestination.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.clearStaticTopic = function() {
  return jspb.Message.setOneofField(this, 6, proto.types.MqttDestination.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.hasStaticTopic = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DynamicTopic dynamic_topic = 7;
 * @return {?proto.types.DynamicTopic}
 */
proto.types.MqttDestination.prototype.getDynamicTopic = function() {
  return /** @type{?proto.types.DynamicTopic} */ (
    jspb.Message.getWrapperField(this, proto.types.DynamicTopic, 7));
};


/**
 * @param {?proto.types.DynamicTopic|undefined} value
 * @return {!proto.types.MqttDestination} returns this
*/
proto.types.MqttDestination.prototype.setDynamicTopic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.types.MqttDestination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.clearDynamicTopic = function() {
  return this.setDynamicTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.hasDynamicTopic = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string heartbeat_topic = 8;
 * @return {string}
 */
proto.types.MqttDestination.prototype.getHeartbeatTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.setHeartbeatTopic = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.MqttDestination} returns this
 */
proto.types.MqttDestination.prototype.clearHeartbeatTopic = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.MqttDestination.prototype.hasHeartbeatTopic = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.NoDestination.prototype.toObject = function(opt_includeInstance) {
  return proto.types.NoDestination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.NoDestination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NoDestination.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.NoDestination}
 */
proto.types.NoDestination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.NoDestination;
  return proto.types.NoDestination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.NoDestination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.NoDestination}
 */
proto.types.NoDestination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.NoDestination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.NoDestination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.NoDestination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NoDestination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DynamicTopic.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DynamicTopic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DynamicTopic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DynamicTopic.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dynamicPart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dynamicPartName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DynamicTopic}
 */
proto.types.DynamicTopic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DynamicTopic;
  return proto.types.DynamicTopic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DynamicTopic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DynamicTopic}
 */
proto.types.DynamicTopic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase(value);
      break;
    case 2:
      var value = /** @type {!proto.types.DynamicTopic.DynamicPart} */ (reader.readEnum());
      msg.setDynamicPart(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDynamicPartName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DynamicTopic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DynamicTopic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DynamicTopic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DynamicTopic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDynamicPart();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDynamicPartName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.DynamicTopic.DynamicPart = {
  DEVICE_ID: 0,
  MELO_ID: 1,
  SERIAL_NUMBER: 2
};

/**
 * optional string base = 1;
 * @return {string}
 */
proto.types.DynamicTopic.prototype.getBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DynamicTopic} returns this
 */
proto.types.DynamicTopic.prototype.setBase = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DynamicPart dynamic_part = 2;
 * @return {!proto.types.DynamicTopic.DynamicPart}
 */
proto.types.DynamicTopic.prototype.getDynamicPart = function() {
  return /** @type {!proto.types.DynamicTopic.DynamicPart} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.DynamicTopic.DynamicPart} value
 * @return {!proto.types.DynamicTopic} returns this
 */
proto.types.DynamicTopic.prototype.setDynamicPart = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string dynamic_part_name = 3;
 * @return {string}
 */
proto.types.DynamicTopic.prototype.getDynamicPartName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DynamicTopic} returns this
 */
proto.types.DynamicTopic.prototype.setDynamicPartName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.types);
