import VueI18n from "vue-i18n";
import de from "./de.json";
import en from "./en.json";
import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.$cookies.config("7d");

export type locale = "de" | "en";

export const availableLocales = ["de", "en"];
export const localeCookie = "locale";

export function getLocale(): string {
  const storedLocale = Vue.$cookies.get(localeCookie);
  if (availableLocales.includes(storedLocale)) {
    return storedLocale;
  }

  let browserLocale = "";
  if (navigator.languages != undefined) {
    browserLocale = navigator.languages[0];
  } else {
    browserLocale = navigator.language;
  }

  if (browserLocale === "en-GB") {
    browserLocale = "en";
  }

  if (availableLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return "de";
}

export const messages = {
  de,
  en,
};

export const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: "de",
  messages,
});
