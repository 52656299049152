import { DateTime } from "google-type/datetime_pb";
import { Money } from "google-type/money_pb";
import moment from "moment";
import { Unit } from "zaehlerfreunde-proto-types/device_reading_pb";
import { formatNumber } from "./number-utils";
import { GetTreeSelectResponse } from "zaehlerfreunde-central/ui_service_pb";

const currencyCodeToSymbol = {
  EUR: "€",
};

export const formatMoney = (m: Money) => formatNumber(moneyToNumber(m));

export const formatCurrency = (m: Money) => currencyCodeToSymbol[m.getCurrencyCode()];

export const momentToDateTime = (m: moment.Moment) => dateToDateTime(m.toDate());

export const dateToDateTime = (date: Date) =>
  new DateTime()
    .setYear(date.getFullYear())
    .setMonth(date.getMonth() + 1)
    .setDay(date.getDate())
    .setHours(date.getHours())
    .setMinutes(date.getMinutes())
    .setSeconds(date.getSeconds());

export const dateTimeToDate = (dateTime: DateTime | undefined) => {
  if (!dateTime) {
    return undefined;
  }

  const date = new Date();
  date.setFullYear(dateTime.getYear());
  date.setMonth(dateTime.getMonth() - 1);
  date.setDate(dateTime.getDay());
  date.setHours(dateTime.getHours());
  date.setMinutes(dateTime.getMinutes());
  date.setSeconds(dateTime.getSeconds());

  const offset = new Date().getTimezoneOffset();
  const correctTimezone = moment(date).add(-offset, "minute").toDate();

  return correctTimezone;
};

export const dateTimeToMoment = (dateTime: DateTime | undefined) => {
  return dateTime ? moment(dateTimeToDate(dateTime)) : undefined;
};

export const moneyToNumber = (m: Money): number => m.getUnits() + m.getNanos() / 1000000000;

export const numberToMoney = (n: number, currencyCode: string): Money => {
  const m = new Money();
  m.setUnits(Math.floor(n));
  m.setNanos((n - Math.floor(n)) * 1000000000);
  m.setCurrencyCode(currencyCode);

  return m;
};

export const timeStampToFormattedDate = (t: number): string => moment(t * 1000).format("DD.MM.YYYY HH:mm:SS");

export const timeStampToDateTime = (t: number): DateTime => momentToDateTime(moment(t * 1000));

export const getUnitLabel = (u: Unit) => {
  switch (u) {
    case Unit.CUBIC_METRE:
      return "m³";
    case Unit.KILO_WATT_HOUR:
      return "kWh";
    case Unit.EURO:
      return "€";
    case Unit.EURO_PER_KILO_WATT_HOUR:
      return "€/kWh";
    case Unit.KILO_GRAMM:
      return "kg";
    case Unit.KILO_GRAMM_CO2:
      return "kg CO2";
    case Unit.KILO_WATT:
      return "kW";
    case Unit.LITERS_PER_MINUTE:
      return "l/min";
    case Unit.CENT_PER_KWH:
      return "Cent/kWh";
    case Unit.PERCENT:
      return "%";
    case Unit.WATT:
      return "W";
    default:
      return "Unbekannt";
  }
};

export interface TreeSelectionNode {
  id: string;
  label: string;
  children?: TreeSelectionNode[] | undefined;
}

export const toTreeSelectionNode = (node: GetTreeSelectResponse.Node): TreeSelectionNode => {
  return {
    ...node.toObject(),
    children: node.getChildrenList().length ? node.getChildrenList().map((n) => toTreeSelectionNode(n)) : undefined,
  };
};
