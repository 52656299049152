
import { themeModule } from "@/store/modules/theme";
import { Component, Vue } from "vue-property-decorator";
import { PartnerTheme } from "zaehlerfreunde-proto-types/partners_pb";

@Component({})
export default class SupportedProductsCollection extends Vue {
  @themeModule.State partnerTheme: PartnerTheme | null;

  primaryColor: string = "";

  mounted(): void {
    this.primaryColor = this.partnerTheme?.getPrimaryColor() ?? "#05843b";
    let script = document.createElement("script");
    script.setAttribute("src", "/supported-products-collection.js");
    script.type = "text/javascript";
    document.head.appendChild(script);
  }
}
