
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { SearchResult, Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import SavingsCalculationExplanation from "./SavingsCalculationExplanation.vue";

@Component({
  components: {
    SavingsCalculationExplanation,
  },
})
export default class Savings extends Vue {
  @tariffModule.Getter tariffSearchResults: RemoteData<UserError, SearchResult[]>;
  @tariffModule.State currentElectricityTariff: RemoteData<UserError, Tariff | null>;

  showSavingsCalculationExplanation: boolean = false;

  onCalculationExplanationButtonClicked(): void {
    this.showSavingsCalculationExplanation = true;
  }

  get isFlexibleTariff(): boolean {
    return (
      this.currentElectricityTariff.data
        ?.getPriceComponentsList()
        ?.some((pc) => pc.getType() === Tariff.PriceComponent.Type.EPEX_SPOT_PRICE) ?? false
    );
  }
}
