
import { initialized, initializedFactory, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ChartData, ChartType } from "zaehlerfreunde-central/ui_service_pb";
import TimePeriodPicker, { TimePeriod, TimePeriodChangeEvent } from "./TimePeriodPicker.vue";
import TimeSeriesChart from "./TimeSeriesChart.vue";
import ChartAggregates from "./ChartAggregates.vue";
import BarChart from "./BarChart.vue";
import ChartTabs from "./ChartTabs.vue";
import TabularChart from "./TabularChart.vue";

import Chart from "chart.js";
import ChartA from "chartjs-plugin-annotation";
import moment from "moment";
import "moment-timezone";
import { formatNumber } from "@/utils/number-utils";
import { themeModule } from "@/store/modules/theme";

@Component({
  components: {
    TimePeriodPicker,
    TimeSeriesChart,
    BarChart,
    ChartTabs,
    ChartAggregates,
    TabularChart,
  },
})
export default class CompositeChart extends Vue {
  ChartType = ChartType;

  @Prop({ default: initializedFactory })
  chartData: RemoteData<UserError, ChartData>;

  cachedChartData: RemoteData<UserError, ChartData> = initialized;

  @Prop({ default: null })
  selectedTab: ChartData.Tab.Type | null;

  @Prop({ default: TimePeriod.DAY })
  selectedTimePeriod: TimePeriod;

  @themeModule.Getter apperance: "light" | "dark";

  @Watch("chartData")
  onChartDataChanged(): void {
    if (this.chartData.succeeded) {
      this.cachedChartData = this.chartData;
    }
  }

  get chartType(): ChartType {
    return this.cachedChartData.data?.getChartType() ?? ChartType.LINE;
  }

  get noDataAvailable(): boolean {
    if (this.chartData.succeeded) {
      if (this.chartData.data?.getChartType() === ChartType.LINE) {
        const lineChartData = this.chartData.data.getLineChartData();
        return lineChartData?.getDatasetsList().every((set) => set.getDataPointsList().length === 0) ?? true;
      } else if (this.chartData.data?.getChartType() === ChartType.BAR) {
        const barChartData = this.chartData.data?.getBarChartData();
        return barChartData?.getDatasetsList().every((set) => set.getDataList().every((v) => v === 0)) ?? true;
      } else if (this.chartData.data?.getChartType() === ChartType.TABULAR) {
        const tabularData = this.chartData.data?.getTabularData();
        return (tabularData?.getRowsList()?.length ?? 0) === 0;
      }
    }

    return false;
  }

  get isPreviousDayData(): boolean {
    return this.cachedChartData.data?.getIsPreviousDayData() ?? false;
  }

  get chartTabs(): ChartData.Tab[] {
    return this.cachedChartData.data?.getTabsList() ?? [];
  }

  get lineChartData(): Chart.ChartData {
    const data = this.cachedChartData.data?.getLineChartData();

    if (!data) {
      return {
        datasets: [],
      };
    }

    return {
      datasets:
        data.getDatasetsList()?.map((dataset) => ({
          label: dataset.getLabel(),
          data: dataset.getDataPointsList()?.map((dataPoint) => ({
            x: data.getIsXAxisDatetime() ? dataPoint.getX() * 1000 : dataPoint.getX(),
            y: dataPoint.getY(),
          })),
          fill: !!dataset.getFillColor(),
          borderColor: dataset.getLineColor(),
          backgroundColor: dataset.getFillColor(),
          borderWidth: 1,
          radius: 0,
          pointRadius: 0,
          pointHitRadius: 5,
          lineTension: 0,
        })) ?? [],
    };
  }

  get lineChartOptions(): Chart.ChartOptions | any {
    const data = this.cachedChartData.data?.getLineChartData();
    return {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        intersect: false,
        mode: "index",
        displayColors: true,
        callbacks: {
          label: (item) => {
            const cachedChartData = this.cachedChartData.data?.getLineChartData();
            const dataset = (cachedChartData?.getDatasetsList() ?? [])[item.datasetIndex ?? 0];

            return dataset
              ? `${dataset.getLabel()}: ${
                  this.selectedTab != ChartData.Tab.Type.CHANNELS
                    ? formatNumber(Math.abs(dataset.getDataPointsList()[item.index ?? 0].getY()))
                    : formatNumber(dataset.getDataPointsList()[item.index ?? 0].getY())
                } ${cachedChartData?.getUnit()}`
              : "";
          },
          labelColor: (item) => {
            const color = (this.cachedChartData.data?.getLineChartData()?.getDatasetsList() ?? [])[
              item.datasetIndex ?? 0
            ]?.getLineColor();

            return {
              borderColor: color,
              backgroundColor: color,
            };
          },
        },
      },
      scales: {
        xAxes: [
          {
            type: data?.getIsXAxisDatetime() ? "time" : "linear",
            time: {
              unit: this.selectedTimePeriod.xAxesTimeUnit,
              displayFormats: {
                hour: "HH:mm",
                day: this.selectedTimePeriod === TimePeriod.WEEK ? "dddd" : "DD MMM",
                week: "DD MMM",
              },
              tooltipFormat: this.selectedTimePeriod === TimePeriod.DAY ? "DD MMM YYYY HH:mm" : "DD MMM YYYY",
              stepSize: 1,
              parser: (utcTime: Date) => {
                const offsetToUtc = new Date(utcTime).getTimezoneOffset();
                const offsetUtcToBerlin = moment(utcTime).tz("Europe/Berlin").utcOffset();
                return moment(utcTime).add(offsetToUtc + offsetUtcToBerlin, "minutes");
              },
            },
            scaleLabel: {
              display: true,
              labelString: data?.getIsXAxisDatetime() ? "" : "Stunden",
            },
            gridLines: {
              display: true,
              color: this.lineColor,
              zeroLineColor: this.lineColor,
            },
            ticks: {
              fontColor: this.fontColor,
              min: data?.getIsXAxisDatetime() ? undefined : 1,
              major: {
                enabled: true,
              },
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.cachedChartData.data?.getLineChartData()?.getYAxisLabel() ?? "",
              fontColor: this.fontColor,
            },
            gridLines: {
              display: true,
              lineWidth: 0,
              zeroLineWidth: 1,
              zeroLineBorderDashOffset: 8,
              zeroLineBorderDash: [5],
            },
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              callback: (value: number) => formatNumber(Math.abs(value)),
              fontColor: this.fontColor,
            },
          },
        ],
      },
      annotation: this.lineChartAnnotation,
      plugins: {
        colorschemes: {
          scheme: "brewer.Paired12",
        },
      },
    };
  }

  get lineChartAnnotation(): any {
    const data = this.cachedChartData.data?.getLineChartData();

    if (!data) {
      return {};
    }

    return {
      annotations: data.getLineAnnotionsList().map((a, i) => ({
        type: "line",
        mode: "horizontal",
        scaleID: `y-axis-${i}`,
        value: a.getY(),
        borderColor: a.getColor(),
        borderDash: [5],
        borderWidth: 1,
        label: {
          enabled: true,
          content: a.getLabel(),
          fontColor: a.getColor(),
          backgroundColor: "transparent",
          position: "right",
          yAdjust: -10,
          fontStyle: "regular",
        },
      })),
    };
  }

  get barChartData(): Chart.ChartData {
    const data = this.cachedChartData.data?.getBarChartData();

    if (!data) {
      return {
        labels: [],
        datasets: [],
      };
    }

    return {
      labels: data.getLabelsList() ?? [],
      datasets:
        data.getDatasetsList()?.map((dataset) => ({
          label: dataset.getLabel(),
          data: dataset.getDataList(),
          backgroundColor: dataset.getColor(),
          borderColor: dataset.getColor(),
          borderWidth: 1,
          barPercentage: 0.8,
          stack: dataset.getStackId(),
        })) ?? [],
    };
  }

  get barChartOptions(): Chart.ChartOptions {
    return {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        intersect: false,
        mode: this.isStackedBarChart() ? "nearest" : "index",
        displayColors: true,
        callbacks: {
          label: (item) => {
            const cachedChartData = this.cachedChartData.data?.getBarChartData();
            const dataset = (cachedChartData?.getDatasetsList() ?? [])[item.datasetIndex ?? 0];

            return dataset
              ? `${dataset.getLabel()}: ${formatNumber(
                  Math.abs(dataset.getDataList()[item.index ?? 0])
                )} ${cachedChartData?.getUnit()}`
              : "";
          },
          labelColor: (item) => {
            const color = (this.cachedChartData.data?.getBarChartData()?.getDatasetsList() ?? [])[
              item.datasetIndex ?? 0
            ]?.getColor();

            return {
              borderColor: color,
              backgroundColor: color,
            };
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: this.isStackedBarChart(),
            scaleLabel: {
              display: true,
              fontColor: this.fontColor,
            },
            gridLines: {
              display: true,
              color: this.lineColor,
              zeroLineColor: this.lineColor,
            },
            // ticks: {
            //   fontColor: this.fontColor,
            // },
          },
        ],
        yAxes: [
          {
            stacked: this.isStackedBarChart(),
            scaleLabel: {
              display: true,
              fontColor: this.fontColor,
              labelString: this.cachedChartData.data?.getBarChartData()?.getYAxisLabel() ?? "",
              lineHeight: 0,
            },
            gridLines: {
              display: true,
              lineWidth: 0,
              zeroLineWidth: 1,
              zeroLineBorderDashOffset: 8,
              zeroLineBorderDash: [5],
            },
            ticks: {
              suggestedMin: 0,
              fontColor: this.fontColor,
              callback: (value: number) => formatNumber(Math.abs(value)),
            },
          },
        ],
      },
      plugins: {
        colorschemes: {
          scheme: "brewer.Paired12",
        },
      },
    };
  }

  onTimePeriodChanged(event: TimePeriodChangeEvent): void {
    this.$emit("time-period-change", event);
  }

  onTabSelected(tab: ChartData.Tab.Type): void {
    this.$emit("tab-selected", tab);
  }

  get fontColor(): string | undefined {
    return this.apperance === "dark" ? "white" : "rgba(0, 0, 0, 0.87)";
  }

  get lineColor(): string | undefined {
    return this.apperance === "dark" ? "#3b3b3b" : "#e7e7e7";
  }

  isStackedBarChart(): boolean {
    if (this.chartData.data?.getBarChartData()?.getStackedBarChartData()?.getBarsDataList().length) {
      return true;
    }
    return false;
  }
}
