
import { Vue, Component, Watch } from "vue-property-decorator";
import HomepageTopBar from "@/components/layout/top-bar/HomepageTopBar.vue";
import Footer from "@/components/layout/Footer.vue";

@Component({
  components: {
    HomepageTopBar,
    Footer,
  },
})
export default class Homepage extends Vue {
  gradientBackground: boolean = false;

  title: string | null = null;
  titleImg: string | null = null;
  subtitle: string | null = null;

  showTopBarInCard = false;

  background: string;
  showImage = false;

  addSpaceForBanner = false;

  mounted(): void {
    this.onRouteChanged();
  }

  @Watch("$route")
  onRouteChanged(): void {
    const meta = this.$route.meta ?? {};
    this.title = meta.title ?? null;
    this.titleImg = meta.titleImg ?? null;
    this.subtitle = meta.subtitle ?? null;
    this.background = `linear-gradient(${Math.floor(
      Math.random() * 360
    )}deg, var(--zf-primary-color), var(--zf-secondary-color))`;
    this.gradientBackground = meta.gradientBackground ?? false;
    this.addSpaceForBanner = meta.addSpaceForBanner ?? false;
  }

  resizeEventHandler(): void {
    this.showImage = window.innerWidth > 1000;
  }

  scrollEventHandler(): void {
    this.showTopBarInCard = window.scrollY > 300;
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
    window.addEventListener("scroll", this.scrollEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
    window.removeEventListener("scroll", this.scrollEventHandler);
  }

  beforeMount(): void {
    this.resizeEventHandler();
    this.scrollEventHandler();
  }
}
