
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import CurrentMonth from "./CurrentMonth.vue";
import MonthlyPayments from "../costs/MonthlyPayments.vue";
import CostPrediction from "../costs/CostPrediction.vue";
import {
  AdvancePaymentWidgetData,
  GetAdvancePaymentWidgetDataRequest,
  GetCurrentMonthWidgetDataRequest,
} from "zaehlerfreunde-central/ui_service_pb";
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { devicesModule } from "@/store/modules/devices";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { uiServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import AddTariffReminder from "../AddTariffReminder.vue";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
@Component({
  components: {
    CurrentMonth,
    MonthlyPayments,
    CostPrediction,
    AddTariffReminder,
  },
})
export default class CurrentMonthCosts extends Vue {
  Feature = Feature;
  @Ref("carousel") readonly carousel: HTMLDivElement;

  @tariffModule.State currentElectricityTariff: RemoteData<UserError, Tariff | null>;
  @devicesModule.Getter mainDevice: RemoteData<UserError, Device>;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  advancePaymentData: RemoteData<UserError, AdvancePaymentWidgetData | null> = initialized;
  selectedDeviceId: string = "";
  CurrentMonthDataType = GetCurrentMonthWidgetDataRequest.DataType;

  get showAdvancePaymentWidget(): boolean {
    return (
      this.mainDevice.data?.getMedium() == Device.Medium.ELECTRICITY &&
      !(
        this.currentElectricityTariff.data
          ?.getPriceComponentsList()
          ?.some((pc) => pc.getType() === Tariff.PriceComponent.Type.EPEX_SPOT_PRICE) ?? true
      ) &&
      this.mainDevice.data?.getId() == this.selectedDeviceId &&
      this.enabledFeatures.has(Feature.TARIFF_ADVANCE_PAYMENTS)
    );
  }

  @Watch("selectedDeviceId")
  loadAdvancePaymentData(): void {
    if (this.selectedDeviceId && this.enabledFeatures.has(Feature.TARIFF_ADVANCE_PAYMENTS)) {
      this.advancePaymentData = pending;
      const request = new GetAdvancePaymentWidgetDataRequest();
      request.setDeviceId(this.selectedDeviceId);
      request.setSpaceId(spaces.selectedSpaceId);
      uiServiceClient
        .getAdvancePaymentWidgetData(request, {})
        .then((response) => (this.advancePaymentData = success(response.getData() ?? null)))
        .catch((error) => (this.advancePaymentData = failure(userErrorFrom(error))));
    }
  }
}
