
import { Vue, Component } from "vue-property-decorator";

@Component
export default class SuccessStories extends Vue {
  height: number = 0;

  stories = [
    {
      name: "Stephan Köslin",
      position: this.$t("landingPage.whiteLabel.successStories.bluemetering.position"),
      company: "BlueMetering GmbH",
      profilePicture: "koesling.png",
      companyLogo: "bluemetering.jpeg",
      companyLogoWhite: "bluemetering.svg",
      feedback: this.$t("landingPage.whiteLabel.successStories.bluemetering.text"),
    },
    {
      name: "Manuel Frohnholzer",
      position: this.$t("landingPage.whiteLabel.successStories.wattline.position"),
      company: "wattline GmbH",
      profilePicture: "frohnholzer.png",
      companyLogo: "wattline.jpg",
      companyLogoWhite: "wattline.svg",
      feedback: this.$t("landingPage.whiteLabel.successStories.wattline.text"),
    },
    {
      name: "Erik Jenner",
      position: this.$t("landingPage.whiteLabel.successStories.e3.position"),
      company: "e3 Energieanlagen GmbH",
      profilePicture: "jenner.png",
      companyLogo: "e3.gif",
      companyLogoWhite: "e3.gif",
      feedback: this.$t("landingPage.whiteLabel.successStories.e3.text"),
    },
    {
      name: "Markus Lemberger",
      position: this.$t("landingPage.whiteLabel.successStories.zaehlerhelden.position"),
      company: "Zählerhelden GmbH",
      profilePicture: "markus.png",
      companyLogo: "zaehlerhelden.png",
      companyLogoWhite: "zaehlerhelden.png",
      feedback: this.$t("landingPage.whiteLabel.successStories.zaehlerhelden.text"),
    },
    {
      name: "Thomas Voß",
      position: this.$t("landingPage.whiteLabel.successStories.energielandwerker.position"),
      company: "Die Energielandwerker eG",
      profilePicture: "thomas.png",
      companyLogo: "energielandwerker.png",
      companyLogoWhite: "energielandwerker.png",
      feedback: this.$t("landingPage.whiteLabel.successStories.energielandwerker.text"),
    },
  ];

  get isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  resizeEventHandler(): void {
    if (window.innerWidth < 600) {
      this.height = 430;
    } else if (window.innerWidth < 800) {
      this.height = 400;
    } else {
      this.height = 340;
    }
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }
  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  mounted() {
    this.resizeEventHandler();
  }
}
