
import { initialized, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import partner, { partnerModule } from "@/store/modules/partner";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { GetTreeSelectResponse } from "zaehlerfreunde-central/ui_service_pb";
import { TreeSelectionNode, toTreeSelectionNode } from "@/utils/proto-utils";

@Component({
  components: { Treeselect },
})
export default class SwitchChildPartnerDialog extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  @Prop() partner: Partner;
  @Prop({ default: [] }) childPartnersNodes: GetTreeSelectResponse.Node[];

  switchPartnerCall: RemoteCall<UserError> = initialized;
  selectedChildPartnerNode: TreeSelectionNode = {
    id: "",
    label: "",
  };

  mounted(): void {
    this.selectedChildPartnerNode = {
      id: this.partner.getId(),
      label: this.partner.getName(),
      children: this.childPartnersNodes.map((node) => toTreeSelectionNode(node)),
    };
    if (this.selectedChildPartner.data) {
      this.selectedChildPartnerNode = {
        ...this.selectedChildPartnerNode,
        id: this.selectedChildPartner.data.getId(),
        label: this.selectedChildPartner.data.getName(),
      };
    }
  }

  get options(): TreeSelectionNode[] {
    return [
      {
        id: this.partner.getId(),
        label: this.partner.getName(),
        children: this.childPartnersNodes.length
          ? this.childPartnersNodes.map((node) => toTreeSelectionNode(node))
          : undefined,
      },
    ];
  }

  async switchPartner(): Promise<void> {
    await partner.switchChildPartner(this.selectedChildPartnerNode.id);
    this.switchPartnerCall = success(void 0);
  }
}
