
import { partnerModule } from "@/store/modules/partner";
import { initializedFactory, RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VerifyEmail extends Vue {
  @partnerModule.Getter supportEmail: string;
  @Prop({ default: initializedFactory }) emailVerified: RemoteData<UserError, boolean>;
  @Prop({ default: initializedFactory }) confirmEmailVerifiedCall: RemoteCall<UserError>;
  @Prop({ default: initializedFactory }) verificationEmailSent: RemoteData<UserError, boolean>;

  onCheckStatusClicked(): void {
    this.$emit("check-email-verified");
  }

  resendEmail(): void {
    this.$emit("resend-verification-email");
  }
}
