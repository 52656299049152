
import { paths } from "@/router/routes";
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { formatNumber } from "@/utils/number-utils";
import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
import { TariffSearchInfo } from "zaehlerfreunde-proto-types/tariff_pb";

@Component
export default class TariffSearchActive extends Vue {
  @tariffModule.Getter tariffSearchInfo: RemoteData<UserError, TariffSearchInfo | null>;

  get rows(): { name: string; value: string; icon: string }[] {
    const latestSearch = this.tariffSearchInfo.data?.getLatestSearch();

    if (latestSearch) {
      return [
        {
          name: "PLZ",
          value: latestSearch.getPostcode() ?? "",
          icon: "mdi-crosshairs-gps",
        },
        {
          name: "Verbrauch",
          value: `${formatNumber(latestSearch.getConsumption() ?? 0)} kWh/Jahr`,
          icon: "mdi-counter",
        },
        {
          name: "Aktuelle Kosten",
          value: `${formatNumber(latestSearch.getCurrentCost() ?? 0)} €/Jahr`,
          icon: "mdi-cash",
        },
        {
          name: "Letzte Tarifsuche",
          value: moment.unix(latestSearch.getTimestamp()).fromNow(),
          icon: "mdi-update",
        },
      ];
    } else {
      return [];
    }
  }

  onSetupWatchdogClicked(): void {
    this.$router.push(paths.platform.tariffSwitch);
  }
}
