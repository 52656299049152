
import { paths } from "@/router/routes";
import { TranslateResult } from "vue-i18n";
import { Vue, Component } from "vue-property-decorator";

interface CustomerGroup {
  title: TranslateResult;
  description: TranslateResult;
  path: string;
  image: string;
}

@Component
export default class CustomerGroups extends Vue {
  customerGroups: CustomerGroup[] = [
    {
      title: this.$t("landingPage.whiteLabel.customerGroups.municipalUtilities.name"),
      description: this.$t("landingPage.whiteLabel.customerGroups.municipalUtilities.teaser"),
      path: paths.homepage.whiteLabel.municipalUtilities,
      image: "utilities.jpg",
    },
    {
      title: this.$t("landingPage.whiteLabel.customerGroups.meteringOperator.name"),
      description: this.$t("landingPage.whiteLabel.customerGroups.meteringOperator.teaser"),
      path: paths.homepage.whiteLabel.meteringOperators,
      image: "meter-operator.jpg",
    },
    {
      title: this.$t("landingPage.whiteLabel.customerGroups.meterToTenant.name"),
      description: this.$t("landingPage.whiteLabel.customerGroups.meterToTenant.teaser"),
      path: paths.homepage.whiteLabel.meterToTenantProjects,
      image: "landlord-to-tenant.jpg",
    },
    {
      title: this.$t("landingPage.whiteLabel.customerGroups.utilityProvider.name"),
      description: this.$t("landingPage.whiteLabel.customerGroups.utilityProvider.teaser"),
      path: paths.homepage.whiteLabel.utilityProviders,
      image: "energy-provider.jpg",
    },
    // {
    //   title: this.$t("landingPage.whiteLabel.customerGroups.propertyManagement.name"),
    //   description: this.$t("landingPage.whiteLabel.customerGroups.propertyManagement.teaser"),
    //   path: paths.homepage.whiteLabel.propertyManagement,
    //   image: "property-management.jpg",
    // },
  ];

  getCustomerGroupImage(src: string): string {
    var images = require.context("@/assets/customer-groups", false, /\.jpg$/);
    return images("./" + src);
  }
}
