
import { Vue, Component } from "vue-property-decorator";
import ZaehlerfreundeLogo from "@/components/layout/ZaehlerfreundeLogo.vue";

@Component({
  components: {
    ZaehlerfreundeLogo,
  },
})
export default class NotFound extends Vue {}
