
import { Vue, Component } from "vue-property-decorator";
import ChangeEnergyConsumers from "@/components/ChangeEnergyConsumers.vue";

@Component({
  components: {
    ChangeEnergyConsumers,
  },
})
export default class AddEnergyConsumersReminder extends Vue {
  showChangeConsumersDialog: boolean = false;
}
