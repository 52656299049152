import { render, staticRenderFns } from "./BluemeteringSuccessStory.vue?vue&type=template&id=45ad2728&scoped=true"
var script = {}
import style0 from "./BluemeteringSuccessStory.vue?vue&type=style&index=0&id=45ad2728&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ad2728",
  null
  
)

export default component.exports