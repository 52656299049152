
import { Vue, Component } from "vue-property-decorator";
import ClientSecretsList from "./ClientSecretsList.vue";
import ApiDocumentation from "./ApiDocumentation.vue";

@Component({
  components: {
    ClientSecretsList,
    ApiDocumentation,
  },
})
export default class ApiOverview extends Vue {}
