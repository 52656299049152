
import { Vue, Component, Watch, Prop } from "vue-property-decorator";

@Component
export default class ChooseCustomerType extends Vue {
  tab = 0;

  yearlyConsumption = "1500";
  householdSize = 1;

  @Prop() value: "residential" | "commercial";

  @Watch("value")
  onValueChanged() {
    this.tab = this.value === "residential" ? 0 : 1;
  }

  @Watch("tab")
  onTabChanged() {
    this.$emit("input", this.tab === 0 ? "residential" : "commercial");
  }

  mounted(): void {
    this.onValueChanged();
  }

  @Watch("householdSize")
  onHouseholdSizeChanged() {
    switch (this.householdSize) {
      case 1:
        this.yearlyConsumption = "1500";
        break;
      case 2:
        this.yearlyConsumption = "2500";
        break;
      case 3:
        this.yearlyConsumption = "3500";
        break;
      case 4:
        this.yearlyConsumption = "4250";
        break;
    }
  }
}
