
import account, { accountModule } from "@/store/modules/account";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RegistrationStatus, RegistrationStep } from "zaehlerfreunde-central/user_service_pb";
import UserName from "./UserName.vue";
import VerifyEmail from "./VerifyEmail.vue";
import CompanyName from "./CompanyName.vue";
import subscription from "@/store/modules/subscription";
import spaces from "@/store/modules/spaces";
import { paths } from "@/router/routes";
import { partnerAuthConfig } from "@/config/auth-config";
import AcceptInviteCheck from "./AcceptInviteCheck.vue";

@Component({
  components: {
    UserName,
    CompanyName,
    VerifyEmail,
    AcceptInviteCheck,
  },
})
export default class Registration extends Vue {
  @accountModule.State registrationStatus: RemoteData<UserError, RegistrationStatus>;
  @accountModule.State addNameAndAcceptTermsCall: RemoteCall<UserError>;
  @accountModule.State emailVerified: RemoteData<UserError, boolean>;
  @accountModule.State verificationEmailSent: RemoteData<UserError, boolean>;
  @accountModule.State confirmEmailVerifiedCall: RemoteCall<UserError>;

  RegistrationStep = RegistrationStep;

  isPartnerBlueMetering = true;

  get registrationStep(): RegistrationStep | undefined {
    return this.registrationStatus.data?.getCurrentStep();
  }

  onNameAdded(name: string): void {
    account.addNameAndAcceptTerms(name);
  }

  onCompanyNameAdded(names: { company: string; user: string }): void {
    account.createPartnerAndAcceptTerms(names);
  }

  onCheckEmailVerified(): void {
    account.checkEmailVerificationStatus();
  }

  onResendVerificationEmail(): void {
    account.resendVerificationEmail();
  }

  mounted() {
    this.onRegistrationStatusChanged();
  }

  async onClose(): Promise<void> {
    const authConfig = await partnerAuthConfig;
    this.$auth.logout({
      logoutParams: {
        returnTo: authConfig.getLogoutRedirectUrl(),
      },
    });
  }

  @Watch("registrationStatus")
  onRegistrationStatusChanged(): void {
    if (this.registrationStatus.data?.getIsComplete()) {
      this.$gtag.event("registration-finished", {
        event_category: "user-action",
        event_label: "Registration finished",
      });

      subscription.getCurrentSubscriptionPlan();
      spaces.getSpaces();
      account.getUser();
      account.getUserDetails();

      this.$router.push(paths.platform.dashboard);
    }
  }
}
