
import { paths } from "@/router/routes";
import devices, { devicesModule } from "@/store/modules/devices";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { ConnectPowerfoxAccountRequest } from "zaehlerfreunde-central/device_service_pb";

@Component({
  components: {},
})
export default class PowerfoxCallbackPage extends Vue {
  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  paths = paths;
  isAdminAction: boolean = false;

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.isAdminAction ? this.$router.push(paths.admin.devices) : this.$router.push(paths.platform.devices);
    }
  }

  created(): void {
    const code = this.$route.query["code"] as string;

    if (code) {
      this.connectPowerfoxAccount(code);
    }
  }

  connectPowerfoxAccount(code: string): void {
    const stateJSON = this.$route.query["state"] as string;
    const state = JSON.parse(stateJSON);
    this.isAdminAction = state.isAdminAction;
    const request = new ConnectPowerfoxAccountRequest();
    request.setCode(code);
    request.setDeviceName(state.deviceName);
    request.setSpaceId(state.spaceId);
    request.setIsMainDevice(state.isMainDevice);
    request.setDeviceCount(state.deviceCount);

    devices.connectPowerfoxAccount(request);
  }
}
