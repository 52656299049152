import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { failure, initialized, pending, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { SpaceData } from "../utils/space-data";
import spaces from "./spaces";
import { namespace } from "vuex-class";
import { CharityProject } from "zaehlerfreunde-proto-types/tariff_pb";
import { tariffServiceClient } from "@/config/service-clients";
import { GetCharityProjectsRequest } from "zaehlerfreunde-central/tariff_service_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "projects",
})
class ProjectsModule extends VuexModule {
  projectsBySpaceId: SpaceData<RemoteData<UserError, CharityProject[]>> = {};

  get projects(): RemoteData<UserError, CharityProject[]> {
    return this.projectsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  @Action
  async getProjects() {
    const request = new GetCharityProjectsRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    this.setProjects(pending);

    try {
      const response = await tariffServiceClient.getCharityProjects(request, {});
      this.setProjects(success(response.getCharityProjectsList()));
    } catch (error) {
      this.setProjects(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setProjects(projects: RemoteData<UserError, CharityProject[]>) {
    this.projectsBySpaceId = {
      ...this.projectsBySpaceId,
      [spaces.selectedSpaceId]: projects,
    };
  }
}

export const projectsModule = namespace("projects");
export default getModule(ProjectsModule);
