
import { userServiceClient } from "@/config/service-clients";

import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetUsersRequest } from "zaehlerfreunde-central/user_service_pb";
import moment from "moment";
import UsersTable from "@/components/users-table/UsersTable.vue";
import SendInviteDialog from "@/components/send-invite/SendInviteDialog.vue";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SearchParams } from "@/components/devices-table/DevicesTable.vue";

interface UserRow {
  id: string;
  name: string;
  email: string;
  picture: string;
  lastLogin: string;
  loginCount: number;
}

@Component({
  components: {
    UsersTable,
    SendInviteDialog,
  },
})
export default class AdminUsersOverview extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  partnerUsers: RemoteData<UserError, User[]> = initialized;
  userPages: RemoteData<UserError, number> = initialized;

  search: string = "";
  itemsPerPage = 10;
  page = 1;

  showSendInviteDialog: boolean = false;

  headerControls = [
    {
      icon: "mdi-account-plus",
      event: "send-invite",
      color: "primary",
    },
  ];

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    if (this.selectedChildPartner.data) {
      this.loadUsers();
    }
  }

  beforeMount(): void {
    const search = (this.$route.query.search as string) ?? "";
    const page = typeof this.$route.query.page === "string" ? parseInt(this.$route.query.page, 10) : 1;
    if (search == "" && page == 1) {
      this.loadUsers();
    }
  }

  async searchParamsInput(params: SearchParams): Promise<void> {
    this.page = params.page;
    this.search = params.search;
    this.loadUsers();
  }

  get users(): UserRow[] {
    let partnerUsers: UserRow[] = [];
    this.partnerUsers.list.forEach((u) => {
      partnerUsers.push({
        id: u.getId(),
        name: u.getName(),
        email: u.getEmail(),
        picture: u.getUserProfileInfo()?.getPicture() ?? "",
        loginCount: u.getUserProfileInfo()?.getLoginCount() ?? 0,
        lastLogin: moment
          .unix(u.getUserProfileInfo()?.getLastLogin() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
      });
    });
    return partnerUsers;
  }

  async loadUsers(): Promise<void> {
    const request = new GetUsersRequest();
    request.setPageSize(this.itemsPerPage);
    request.setPage(this.page - 1);
    if (this.search != "") {
      request.setEmail(this.search);
    }

    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      this.partnerUsers = pending;
      const response = await userServiceClient.getUsers(request, {});
      this.partnerUsers = success(response.getUsersList());
      this.userPages = success(response.getTotalNumPages());
    } catch (error) {
      this.partnerUsers = failure(userErrorFrom(error));
      this.userPages = failure(userErrorFrom(error));
    }
  }
}
