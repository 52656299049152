
import { Vue, Component, Prop } from "vue-property-decorator";
import ProsAndConsList from "@/components/ProsAndConsList.vue";

@Component({
  components: {
    ProsAndConsList,
  },
})
export default class WatchdogService extends Vue {
  @Prop({ default: false }) singleTariffSwitchEnabled: boolean;

  pros: string[] = [
    "Jährlich Geld sparen - wir wechseln automatisch für dich",
    "Verbrauchsoptimierte Tarife",
    "Ökolabel garantiert",
    "Förderung gemeinnütziger Projekte",
  ];

  onEnableWatchdogServiceClicked(): void {
    this.$emit("enable-watchdog-service");
  }
}
