
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TeamMember extends Vue {
  @Prop({ default: null }) image: string | null;
  @Prop({ default: null }) name: string | null;
  @Prop({ default: null }) description: string | null;

  get imageUrl(): string {
    var images = require.context("@/assets/team", false, /\.jpg$/);
    return images("./" + this.image + ".jpg");
  }
}
