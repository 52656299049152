
import SendInviteDialog from "@/components/send-invite/SendInviteDialog.vue";
import RemoveUserDialog from "@/components/remove-user-dialog/RemoveUserDialog.vue";

import { spaceServiceClient, userServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Invite } from "zaehlerfreunde-proto-types/invite_pb";
import { DeleteInviteRequest, ResendInviteRequest } from "zaehlerfreunde-central/user_service_pb";
import { GetSpaceAccessRolesRequest } from "zaehlerfreunde-central/space_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { AccessPermission, AccessRole } from "zaehlerfreunde-proto-types/permissions_pb";
import { spacesModule } from "@/store/modules/spaces";

export interface DataRow {
  id: string;
  picture: string;
  name: string;
  email: string;
  accesslevel: string;
  data: User | Invite;
  isUser: boolean;
}

@Component({
  components: {
    SendInviteDialog,
    RemoveUserDialog,
  },
})
export default class UsersOrInvitesTable extends Vue {
  @Prop() data: RemoteData<UserError, (User | Invite)[]>;
  @Prop() space: Space;

  @spacesModule.Getter accessPermissions: AccessPermission[];

  showCreateInviteDialog: boolean = false;
  showDeleteInviteDialog: boolean = false;
  showResendInviteDialog: boolean = false;
  showRemoveUserDialog: boolean = false;
  itemUser: User | null = null;
  itemInvite: Invite | null = null;

  actionCall: RemoteCall<UserError> = initialized;
  spaceAccessRoles: RemoteData<UserError, AccessRole[]> = initialized;

  async mounted(): Promise<void> {
    if (this.space !== null) {
      try {
        this.spaceAccessRoles = pending;
        const response = await spaceServiceClient.getSpaceAccessRoles(new GetSpaceAccessRolesRequest(), {});
        this.spaceAccessRoles = success(response.getAccessRolesList());
      } catch (error) {
        this.spaceAccessRoles = failure(userErrorFrom(error));
      }
    }
  }

  get headers() {
    return [
      { text: "", value: "picture" },
      { text: "Nutzername", value: "name" },
      { text: "E-mail", value: "email" },
      { text: "Rolle", value: "accesslevel" },
      { text: "", value: "controls", sortable: false, align: "right" },
    ];
  }

  updateUsersList(): void {
    this.showRemoveUserDialog = false;
    this.$emit("actionTaken");
  }

  getSpaceAccessRoleName(role: string): string {
    return (
      this.spaceAccessRoles.list
        .find((item) => {
          return item.getId() === role;
        })
        ?.getName() ?? ""
    );
  }

  get userAllowedToEdit(): boolean {
    return this.accessPermissions.some((a) => a === AccessPermission.EDIT);
  }

  get dataRows(): DataRow[] {
    return this.data.list.map((userOrInvite: User | Invite) => {
      if (userOrInvite instanceof User) {
        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-non-null-assertion
        const user = userOrInvite as User;
        return {
          id: user.getId(),
          picture: user.getUserProfileInfo()?.getPicture() ?? "",
          name: user.getName(),
          email: user.getEmail(),
          accesslevel: this.getSpaceAccessRoleName(user.getSpaceAccessPermissions()),
          data: user,
          isUser: true,
        };
      } else {
        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-non-null-assertion
        const invite = userOrInvite as Invite;
        const expiresAt = moment.unix(invite.getExpiresAt());
        return {
          id: invite.getId(),
          picture: "",
          name: expiresAt.isBefore(new Date()) ? "Einladung abgelaufen" : "Einladung versendet",
          email: invite.getEmail(),
          accesslevel: this.getSpaceAccessRoleName(invite.getSpaceAccessRole()),
          data: invite,
          isUser: false,
        };
      }
    });
  }

  onCreateInviteClicked() {
    this.showCreateInviteDialog = true;
  }

  onDeleteInviteClicked(invite: Invite) {
    this.itemInvite = invite;
    this.showDeleteInviteDialog = true;
  }

  onResendInviteClicked(invite: Invite) {
    this.itemInvite = invite;
    this.showResendInviteDialog = true;
  }

  onDeleteUserClicked(user: User) {
    this.itemUser = user;
    this.showRemoveUserDialog = true;
  }

  async resendInvite(): Promise<void> {
    this.actionCall = pending;

    try {
      await userServiceClient.resendInvite(new ResendInviteRequest().setInviteId(this.itemInvite?.getId() ?? ""), {});
      this.actionCall = success(void 0);
      this.showResendInviteDialog = false;
      this.$emit("actionTaken");
    } catch (error) {
      this.actionCall = failure(userErrorFrom(error));
    }
  }

  async deleteInvite(): Promise<void> {
    this.actionCall = pending;

    try {
      await userServiceClient.deleteInvite(new DeleteInviteRequest().setInviteId(this.itemInvite?.getId() ?? ""), {});
      this.actionCall = success(void 0);
      this.showDeleteInviteDialog = false;
      this.$emit("actionTaken");
    } catch (error) {
      this.actionCall = failure(userErrorFrom(error));
    }
  }
}
