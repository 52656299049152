import { render, staticRenderFns } from "./DynamicTariffChange.vue?vue&type=template&id=4ed7fc9a&scoped=true"
import script from "./DynamicTariffChange.vue?vue&type=script&lang=ts"
export * from "./DynamicTariffChange.vue?vue&type=script&lang=ts"
import style0 from "./DynamicTariffChange.vue?vue&type=style&index=0&id=4ed7fc9a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed7fc9a",
  null
  
)

export default component.exports