
import { Vue, Component } from "vue-property-decorator";
import {
  GetMetergridProjectsResponse,
  MetergridProject,
  MetergridProjectTenants,
} from "zaehlerfreunde-central/device_service_pb";
import MetergridApiCredentials from "./MetergridApiCredentials.vue";
import MetergridProjectSelection from "./MetergridProjectSelection.vue";
import MetergridDeviceSelection from "./MetergridTenantSelection.vue";

@Component({
  components: {
    MetergridApiCredentials,
    MetergridProjectSelection,
    MetergridDeviceSelection,
  },
})
export default class MetergridImport extends Vue {
  step: "credentials" | "project-selection" | "tenant-selection" = "credentials";

  metergridCredentialsId: string | null = null;
  projects: MetergridProject[] = [];
  projectsTenants: MetergridProjectTenants[] = [];

  onApiCredentialsReceived(response: GetMetergridProjectsResponse): void {
    this.metergridCredentialsId = response.getMetergridUserCredentialsId();
    this.projects = response.getProjectsList();
    this.step = "project-selection";
  }

  onProjectsSelected(projectsTenants: MetergridProjectTenants[]): void {
    this.projectsTenants = projectsTenants;
    this.step = "tenant-selection";
  }
}
