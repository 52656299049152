export default class ArrayUtils {
  static asList<T>(o: T | T[] | null): T[] {
    Array.isArray;
    if (o === null) {
      return [];
    } else if (o instanceof Array) {
      return o;
    } else {
      return [o];
    }
  }
}
