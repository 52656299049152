
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { paths } from "@/router/routes";

@Component
export default class DeleteSpaceDialog extends Vue {
  @Prop() value: boolean;
  @Prop() selectedSpace: Space | null;
  @Prop({ default: false }) isAdmin: boolean;
  deleteSpaceCall: RemoteCall<UserError> = initialized;

  get spaceName(): string {
    return this.selectedSpace?.getName() ?? "";
  }

  async deleteSpace(): Promise<void> {
    try {
      this.deleteSpaceCall = pending;
      const request = new DeleteSpaceRequest();
      request.setSpaceId(this.selectedSpace?.getId() ?? "");
      await spaceServiceClient.deleteSpace(request, {});
      this.deleteSpaceCall = success(void 0);
      if (this.isAdmin) {
        this.$router.push(paths.admin.spaces);
      } else {
        await spaces.getSpaces();
        this.$router.push(paths.home);
      }
    } catch (error) {
      this.deleteSpaceCall = failure(userErrorFrom(error));
    }
  }
}
