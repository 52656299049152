
import { Vue, Component } from "vue-property-decorator";
import { VisitorType, visitorType$ } from "@/observables/visitor-type-observables";
import { paths } from "@/router/routes";

interface VisitorTypeOption {
  emoji: string;
  name: string;
  description: string;
  path: string;
  value: VisitorType;
}

@Component
export default class VisitorTypePrompt extends Vue {
  options: VisitorTypeOption[] = [
    {
      emoji: "🏠",
      name: this.$t("privateCustomer").toString(),
      description: this.$t("visitorTypes.useCases.privateCustomer").toString(),
      path: paths.homepage.privateCustomers.b2cLandingPage,
      value: VisitorType.PrivateCustomer,
    },
    {
      emoji: "🏭",
      name: this.$t("company").toString(),
      description: this.$t("visitorTypes.useCases.commercialCustomer").toString(),
      path: paths.homepage.commercialCustomers,
      value: VisitorType.CommercialCustomer,
    },
    {
      emoji: "⚡",
      name: this.$t("whiteLabel").toString(),
      description: this.$t("visitorTypes.useCases.whiteLabel").toString(),
      path: paths.homepage.whiteLabel.landingPage,
      value: VisitorType.WhiteLabelCustomer,
    },
  ];

  onOptionSelected(option: VisitorTypeOption) {
    visitorType$.next(option.value);
    this.$router.replace(option.path);
  }
}
