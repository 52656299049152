
import { formatNumber } from "@/utils/number-utils";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ValueWithUnit extends Vue {
  @Prop({ default: "" }) value: string | number;
  @Prop({ default: null }) unit: string | null;

  get valueFormatted(): string {
    if (typeof this.value == "string") {
      return this.value;
    } else {
      return formatNumber(this.value);
    }
  }
}
