
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetSpacesRequest, Pagination } from "zaehlerfreunde-central/space_service_pb";
import {
  RemoteData,
  RemoteCall,
  failure,
  initialized,
  pending,
  success,
  initializedFactory,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import SpacesTable from "@/components/spaces-table/SpacesTable.vue";
import AddDeviceToSpaceDialog from "./AddDeviceToSpaceDialog.vue";
import DeleteDeviceDialog from "./DeleteDeviceDialog.vue";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SearchParams } from "@/components/devices-table/DevicesTable.vue";

@Component({
  components: {
    SpacesTable,
    AddDeviceToSpaceDialog,
    DeleteDeviceDialog,
  },
})
export default class DeviceSpaces extends Vue {
  @Prop({ default: initializedFactory }) deviceInfo: RemoteData<UserError, Device>;

  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  deleteDeviceFromSpaceCall: RemoteCall<UserError> = initialized;
  spacesList: RemoteData<UserError, Space[]> = initialized;
  ownerSpaces: RemoteData<UserError, Space[]> = initialized;

  itemsPerPage = 10;
  page = 1;

  showAddDeviceToSpaceDialog: boolean = false;
  showDeleteDeviceDialog: boolean = false;
  selectedSpaceForDeleteDevice: Space | null = null;
  itemControls = [
    {
      icon: "mdi-link-variant-off",
      event: "delete-device-from-space",
    },
  ];
  headerControls = [
    {
      icon: "mdi-plus-circle",
      event: "map-device-to-space",
    },
  ];

  async mounted(): Promise<void> {
    this.getSpacesOfDevice();
  }

  async getSpacesOfDevice() {
    this.spacesList = pending;
    const spaceRequest = new GetSpacesRequest();
    spaceRequest.setDeviceId(this.$route.params.deviceId);
    spaceRequest.setPartnerSpaces(true);

    if (this.selectedChildPartner.data) {
      spaceRequest.setPartnerId(this.selectedChildPartner.data.getId());
    }

    try {
      const response = await spaceServiceClient.getSpaces(spaceRequest, {});
      this.spacesList = success(response.getSpacesList());
    } catch (error) {
      this.spacesList = failure(userErrorFrom(error));
    }
  }

  onDeleteDeviceFromSpaceClicked(spaceId: string) {
    this.selectedSpaceForDeleteDevice = this.spacesList.list.find((s) => s.getId() === spaceId) ?? null;
    this.showDeleteDeviceDialog = true;
  }

  onAddDeviceToSpace() {
    this.getSpacesOfDevice();
    this.showAddDeviceToSpaceDialog = false;
  }
}
