
import devices, { devicesModule } from "@/store/modules/devices";
import spaces from "@/store/modules/spaces";
import { DeviceRegistration, SolarEdgeRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { deviceServiceClient } from "@/config/service-clients";
import {
  SolarEdgeDevice,
  SolarEdgeSite,
  GetSolarEdgeSitesRequest,
  GetSolarEdgeDevicesRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { RemoteData, RemoteCall, initialized, failure, success, pending } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { paths } from "@/router/routes";

@Component({
  components: {},
})
export default class SolaredgeConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;
  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  page: number = 1;
  sites: RemoteData<UserError, SolarEdgeSite[]> = initialized;
  devices: RemoteData<UserError, SolarEdgeDevice[]> = initialized;

  selectedSite: SolarEdgeSite;
  selectedSites: SolarEdgeSite[] = [];
  selectedDevices: SolarEdgeDevice[] = [];
  apiKey: string = "";

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.push(paths.platform.devices);
    }
  }

  get explanation() {
    if (this.page === 1) {
      return "Damit wir die Solaredge-Daten abrufen können, benötigen wir Ihren API Key von der SolarEdge Monitoring Platform.";
    } else {
      return "Bitte wählen Sie die Site aus, die Sie hinzufügen möchten.";
    }
  }

  async onNextPageToSitesClicked(): Promise<void> {
    this.sites = pending;
    const request = new GetSolarEdgeSitesRequest();
    request.setApiKey(this.apiKey);

    try {
      const response = await deviceServiceClient.getSolarEdgeSites(request, {});
      this.sites = success(response.getSitesList());
      this.page = 2;
    } catch (error) {
      this.sites = failure(userErrorFrom(error));
    }
  }

  async onNextPageToDevicesClicked(site: SolarEdgeSite): Promise<void> {
    this.selectedSite = site;
    this.devices = pending;
    this.selectedDevices = [];
    const request = new GetSolarEdgeDevicesRequest();
    request.setApiKey(this.apiKey);
    request.setSiteId(this.selectedSite.getSiteId());

    try {
      const response = await deviceServiceClient.getSolarEdgeDevices(request, {});
      this.devices = success(response.getDevicesList());
      this.page = 3;
    } catch (error) {
      this.devices = failure(userErrorFrom(error));
    }
  }

  onRegisterClicked(): void {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new SolarEdgeRegistrationDetails();
    details.setApiKey(this.apiKey);

    this.selectedSites.forEach((site) => {
      let deviceData = new SolarEdgeRegistrationDetails.DeviceData();
      deviceData = deviceData.setSiteId(site.getSiteId());
      details.addDevices(deviceData);
    });

    registration.setSolaredge(details);
    devices.registerDevice(registration);
  }
  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }
}
