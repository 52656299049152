import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { namespace } from "vuex-class";
import { GetCurrentSubscriptionPlanRequest } from "zaehlerfreunde-central/user_service_pb";
import { failure, initialized, pending, RemoteData, successOptional } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { userServiceClient } from "@/config/service-clients";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/subscription_pb";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "subscription",
})
class SubscriptionModule extends VuexModule {
  subscriptionPlan: RemoteData<UserError, SubscriptionPlan> = initialized;

  get includedFeatures(): Feature[] {
    return this.subscriptionPlan.data?.getFeaturesList() ?? (Object.values(Feature) as Feature[]);
  }

  @Action
  async getCurrentSubscriptionPlan() {
    try {
      this.setSubscriptionPlan(pending);
      const request = new GetCurrentSubscriptionPlanRequest();
      const response = await userServiceClient.getCurrentSubscriptionPlan(request, {});
      this.setSubscriptionPlan(successOptional(response.getSubscriptionPlan()));
    } catch (error) {
      this.setSubscriptionPlan(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setSubscriptionPlan(plan: RemoteData<UserError, SubscriptionPlan>) {
    this.subscriptionPlan = plan;
  }
}

export const subscriptionModule = namespace("subscription");
export default getModule(SubscriptionModule);
