
import { failure, initialized, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  LandlordToTenantProjectSpace,
  LandlordToTenantSpaceType,
} from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { AddLandlordToTenantSpaceRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { getSpaceAddress } from "@/utils/space-utils";
import { GetSpacesRequest, Pagination } from "zaehlerfreunde-central/space_service_pb";

import { spaceServiceClient } from "@/config/service-clients";

@Component({
  components: {},
})
export default class AddLandlordToTenantSpaceDialog extends Vue {
  @Prop() projectId: string;

  @Prop({ default: initialized }) spacesList: RemoteData<UserError, LandlordToTenantProjectSpace[]>;

  createProjectSpaceDialog: RemoteCall<UserError> = initialized;
  spaces: RemoteData<UserError, Space[]> = initialized;
  LandlordToTenantSpaceType = LandlordToTenantSpaceType;
  selectedType: LandlordToTenantSpaceType | null = null;
  name: string | null = null;
  itemsPerPage: number = 10;
  page: number = 1;
  search: string = "";
  id: string | null = null;
  alreadyAddedSpaces: string[] = [];

  spaceTypes = [
    { text: "Gebäude", value: LandlordToTenantSpaceType.BUILDING },
    { text: "Wohnung", value: LandlordToTenantSpaceType.FLAT },
  ];

  get infoComplete(): boolean {
    return this.id != null && this.selectedType != null;
  }

  spaceName(space: Space): string {
    return space.getName() + ", " + getSpaceAddress(space);
  }

  spaceValue(space: Space): string {
    return space.getId();
  }

  spaceIcon(space: Space): string {
    return space.getCategoryIcon();
  }

  async mounted(): Promise<void> {
    this.spacesList.data?.forEach((space) => {
      this.alreadyAddedSpaces.push(space.getSpaceId());
    });
    this.loadSpaces();
  }

  async loadSpaces(): Promise<void> {
    const request = new GetSpacesRequest();
    request.setPartnerSpaces(true);
    request.setPagination(new Pagination().setPageSize(this.itemsPerPage).setPage(this.page - 1));
    if (this.search != "") {
      request.setFilter(this.search);
    }

    request.setAvoidSpaceIdsList(this.alreadyAddedSpaces);

    try {
      const response = await spaceServiceClient.getSpaces(request, {});
      this.spaces = success(response.getSpacesList());
    } catch (error) {
      this.spaces = failure(userErrorFrom(error));
    }
  }

  async addSpace(): Promise<void> {
    const request = new AddLandlordToTenantSpaceRequest();
    if (this.selectedType) {
      request.setSpaceType(this.selectedType);
    }
    if (this.id) request.setSpaceId(this.id);

    request.setProjectId(this.projectId);

    try {
      await landlordToTenantServiceClient.addLandlordToTenantSpace(request, {});
      this.createProjectSpaceDialog = success(void 0);
      this.$emit("space-added");
    } catch (error) {
      this.createProjectSpaceDialog = failure(userErrorFrom(error));
    }
  }
}
