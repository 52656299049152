
import { Vue, Component } from "vue-property-decorator";
import BookMeeting from "@/components/book-meeting/BookMeeting.vue";
import { getAuthService } from "@/auth/auth";

interface DeviceType {
  name: string;
  icon: string;
  supportedDevices: string[];
}

@Component({
  components: {
    BookMeeting,
  },
})
export default class B2BOnboardingPath extends Vue {
  hasSmartMeter: boolean | null = null;
  selectedDeviceType: number = 0;
  hasNumDevices: number = 0;
  hasNoOtherDevices: boolean | null = null;
  smallWindow: boolean = false;
  showBookMeetingDialog: boolean = false;

  deviceTypes: DeviceType[] = [
    {
      name: this.$t("energyMeters").toString(),
      icon: "mdi-meter-electric",
      supportedDevices: ["Shelly 3EM", "Shelly Pro 3EM"],
    },
    {
      name: this.$t("wallboxes").toString(),
      icon: "mdi-car-electric",
      supportedDevices: ["Easee", "Go-E"],
    },
    {
      name: this.$t("smartPlugs").toString(),
      icon: "mdi-connection",
      supportedDevices: [
        "Shelly 1PM",
        "Shelly Plus 1PM",
        "Shelly Plus 1",
        "Shelly Plug S",
        "Shelly Plug",
        "Shelly Plus Plug S",
        "myStrom WiFi Switch",
      ],
    },
    {
      name: this.$t("heatPumps").toString(),
      icon: "mdi-heat-pump",
      supportedDevices: ["Viessmann"],
    },
  ];

  choiceOnSmartMeter(choice: boolean): void {
    this.hasSmartMeter = choice;
    let element = document.getElementById("end-of-first-step");
    if (element) {
      element.scrollIntoView({ inline: "end", behavior: "smooth" });
    }
  }

  choiceOnOtherDevices(otherDevices: boolean, num: number): void {
    this.hasNoOtherDevices = !otherDevices;
    this.hasNumDevices += num;
    let element = document.getElementById("end-of-second-step");
    if (element) {
      element.scrollIntoView({ inline: "end", behavior: "smooth" });
    }
  }

  onRegisterClicked(): void {
    this.$gtag.event("registration-clicked", {
      event_label: "Registration clicked",
      event_category: "user-action",
    });

    this.$gtag.event("conversion", {
      send_to: "AW-10831568791/--0CCJP1sI0YEJe_8qwo",
      value: 10,
      currency: "USD",
    });

    getAuthService().loginWithRedirect({ authorizationParams: { screen_hint: "signup" } });
  }

  resizeEventHandler(): void {
    this.smallWindow = window.innerWidth < 700;
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  beforeMount(): void {
    this.resizeEventHandler();
  }
}
