
import { tariffServiceClient } from "@/config/service-clients";
import AddTariffReminder from "../../dashboard/AddTariffReminder.vue";
import TariffCard from "./TariffCard.vue";
import { RemoteData, success, failure, pending, initialized } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { GetSpacePermissionsRequest } from "zaehlerfreunde-central/space_service_pb";
import { AccessPermission } from "zaehlerfreunde-proto-types/permissions_pb";
import { GetTariffsOfSpaceRequest } from "zaehlerfreunde-central/tariff_service_pb";
import tariff from "@/store/modules/tariff";
import { spacesModule } from "@/store/modules/spaces";

interface TariffItem {
  title: string;
  value: Device.Medium;
}

@Component({
  components: {
    AddTariffReminder,
    TariffCard,
  },
})
export default class SpaceTariffs extends Vue {
  @Prop() spaceId: string;
  @Prop({ default: false }) isAdminView: boolean;

  @spacesModule.Getter accessPermissions: AccessPermission[];
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  tariffs: RemoteData<UserError, Tariff[]> = initialized;
  deviceMedium: Device.Medium = Device.Medium.ELECTRICITY;
  title: string = "Strom Tarif";

  get userCanEditTariffs(): boolean {
    return (
      this.accessPermissions.some((a) => a === AccessPermission.EDIT) &&
      this.enabledFeatures.has(Feature.EDIT_TARIFF_DETAILS)
    );
  }

  get tariffItems(): TariffItem[] {
    const items = [
      { title: "Strom Tarif", value: Device.Medium.ELECTRICITY },
      { title: "Wasser Tarif", value: Device.Medium.WATER },
      { title: "Gas Tarif", value: Device.Medium.GAS },
    ];
    var resItems: TariffItem[] = [];
    if (this.isAdminView) {
      resItems = [...items];
    } else {
      if (this.enabledFeatures.has(Feature.ENERGY_TARIFF)) {
        resItems.push(items[0]);
      } else {
        this.deviceMedium = Device.Medium.WATER;
      }
      if (this.enabledFeatures.has(Feature.WATER_TARIFF)) resItems.push(items[1]);
      if (this.enabledFeatures.has(Feature.GAS_TARIFF)) resItems.push(items[2]);
    }

    return resItems;
  }
  mounted(): void {
    this.getTariffs();
  }

  get deviceTitle() {
    switch (this.deviceMedium) {
      case Device.Medium.ELECTRICITY:
        return "Strom Tarif";
      case Device.Medium.WATER:
        return "Wasser Tarif";
      case Device.Medium.GAS:
        return "Gas Tarif";
      default:
        return "";
    }
  }

  async getTariffs(): Promise<void> {
    tariff.getCurrentElectricityTariff();

    try {
      this.tariffs = pending;
      const request = new GetTariffsOfSpaceRequest();
      request.setSpaceId(this.spaceId ?? "");
      request.setMedium(this.deviceMedium);
      const response = await tariffServiceClient.getTariffsOfSpace(request, {});
      this.tariffs = success(response.getTariffsList());
    } catch (error) {
      this.tariffs = failure(userErrorFrom(error));
    }
  }
}
