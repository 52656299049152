
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  ImportMetergridDataRequest,
  MetergridProjectTenants,
  MetergridTenant,
} from "zaehlerfreunde-central/device_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class MetergridTennatSelection extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  call: RemoteCall<UserError> = initialized;

  @Prop() metergridCredentialsId: string;
  @Prop() projectsTenants: MetergridProjectTenants[];

  selectedProjectId: number | null = null;
  allSelected: boolean = true;

  unselectedTenants: MetergridTenant[] = [];

  get headers() {
    return [
      { value: "selected" },
      { text: "Name", value: "tenantName" },
      { text: "Email", value: "email" },
      { text: "Wohnung", value: "apartment" },
      { text: "Melo", value: "melo" },
    ];
  }

  get selectedTenants() {
    return this.projectsTenants
      .flatMap((e) => e.getTenantsList())
      .filter((t) => !this.unselectedTenants.some((ut) => t === ut));
  }

  onTenantSelected(tenant: MetergridTenant, selected: boolean) {
    if (selected) {
      this.unselectedTenants = this.unselectedTenants.filter((t) => t !== tenant);
    } else {
      this.unselectedTenants.push(tenant);
    }
  }

  getItems(pt: MetergridProjectTenants) {
    return pt.getTenantsList().map((t) => ({
      selected: !this.unselectedTenants.some((ut) => ut == t),
      tenantName: t.getTenantName(),
      email: t.getEmail(),
      apartment: t.getApartmentName(),
      melo: t.getMelo(),
      tenant: t,
    }));
  }

  async onStartImportClicked(): Promise<void> {
    try {
      this.call = pending;

      const request = new ImportMetergridDataRequest();
      request.setMetergridUserCredentialsId(this.metergridCredentialsId);
      request.setSubscriptionIdsList(this.selectedTenants.map((d) => d.getSubscriptionId()));

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      await deviceServiceClient.importMetergridData(request, {});

      this.$router.go(-1);

      this.call = success(void 0);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }
}
