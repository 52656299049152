import { BehaviorSubject, skip } from "rxjs";
import Vue from "vue";

const visitorTypeCookieKey = "visitor-type";

export enum VisitorType {
  PrivateCustomer = "private-customer",
  CommercialCustomer = "commercial-customer",
  WhiteLabelCustomer = "energy-company-customer",
}

export const visitorType$ = new BehaviorSubject<VisitorType | null>(null);

visitorType$.pipe(skip(1)).subscribe((visitorType) => {
  if (visitorType !== null) {
    setVisitorType(visitorType);
  }
});

export const getVisitorType = (): VisitorType | null => {
  const visitorType = Vue.$cookies.get(visitorTypeCookieKey);

  if (visitorType) {
    return visitorType;
  } else {
    return null;
  }
};

export const setVisitorType = (visitorType: VisitorType) => {
  Vue.$cookies.set(visitorTypeCookieKey, visitorType.valueOf());
};
