
import { Component, Vue } from "vue-property-decorator";
import TeamMember from "./TeamMember.vue";

@Component({
  components: {
    TeamMember,
  },
})
export default class AboutUs extends Vue {
  metaInfo(): { title: string } {
    return {
      title: "Über Uns",
    };
  }
}
