
import { Vue, Component, Watch } from "vue-property-decorator";

export interface PreviousContractDetails {
  orderReason: string;
  previousSupplierContract: string;
}

@Component
export default class PreviousContract extends Vue {
  sameBillingAddress = true;
  orderReason: string | null = null;
  previousSupplierContract: string | null = null;

  @Watch("sameBillingAddress")
  @Watch("orderReason")
  @Watch("previousSupplierContract")
  onChange() {
    this.$emit("input", {
      orderReason: this.orderReason,
      previousSupplierContract: this.previousSupplierContract,
    });
  }
}
