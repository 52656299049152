
import { advisoryServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteData, successOptional } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { GetReportContentRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { Report } from "zaehlerfreunde-proto-types/reporting_pb";
import ReportingMetric from "@/components/reporting/reporting-metric/ReportingMetric.vue";
import Breakdown from "@/components/reporting/breakdown/Breakdown.vue";

@Component({
  components: {
    ReportingMetric,
    Breakdown,
  },
})
export default class ReportContent extends Vue {
  report: Report | null = null;
  content: RemoteData<UserError, Report.Content> = initialized;

  mounted() {
    this.loadReportContent();
  }

  get metaData() {
    const metaData: string[][] = [];

    this.content.data?.getMetaDataMap().forEach((k, v) => {
      metaData.push([v, k]);
    });

    return metaData;
  }

  async loadReportContent(): Promise<void> {
    const reportId = this.$route.params["reportId"] as string;

    const request = new GetReportContentRequest();
    request.setReportId(reportId);

    try {
      this.content = pending;
      const response = await advisoryServiceClient.getReportContent(request, {});
      this.content = successOptional(response.getContent());
      this.report = response.getReport() ?? null;
    } catch (error) {
      this.content = failure(userErrorFrom(error));
    }
  }
}
