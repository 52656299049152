
import devices from "@/store/modules/devices";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component } from "vue-property-decorator";
import {
  ConnectSmartMeAccountRequest,
  RegisterDeviceRequest,
  SmartMeDevice,
} from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceRegistration, SmartMeRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { paths } from "@/router/routes";

@Component({
  components: {},
})
export default class SmartMeSelectDevice extends Vue {
  smartMeDevices: RemoteData<UserError, SmartMeDevice[]> = initialized;
  registerDeviceCall: { call: RemoteCall<UserError>; deviceId?: string } = { call: initialized };

  paths = paths;

  get error(): string | undefined {
    return this.smartMeDevices.error?.message || this.registerDeviceCall.call.error?.message;
  }

  async onConnectDeviceClicked(device: SmartMeDevice): Promise<void> {
    const stateJSON = this.$route.query["state"] as string;
    const state = JSON.parse(stateJSON);

    const registration = new DeviceRegistration();
    registration.setDeviceName(state.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(Device.Type.SMART_METER);
    registration.setDeviceProvider(Device.Provider.SMARTME);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(state.spaceId);
    spaceMapping.setIsMainDevice(state.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new SmartMeRegistrationDetails();
    details.setSmartMeDeviceId(device.getId());
    details.setSmartMeDeviceSerial(device.getSerial());

    registration.setSmartme(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    try {
      this.registerDeviceCall.call = pending;
      this.registerDeviceCall.deviceId = device.getId();
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall.call = success(void 0);

      const newDevice = new Device();
      newDevice.setName(registration.getDeviceName());
      newDevice.setStatus(Device.Status.CONNECTING);
      newDevice.setProvider(Device.Provider.SMARTME);
      devices.getDevices();
      state.isAdminAction ? this.$router.push(paths.admin.devices) : this.$router.push(paths.platform.devices);
    } catch (error) {
      this.registerDeviceCall.call = failure(userErrorFrom(error));
    }
  }

  mounted(): void {
    const code = this.$route.query["code"] as string;

    if (code) {
      this.connectSmartMeAccount(code);
    }
  }

  async connectSmartMeAccount(code: string): Promise<void> {
    const request = new ConnectSmartMeAccountRequest();
    request.setCode(code);

    try {
      this.smartMeDevices = pending;
      const response = await deviceServiceClient.connectSmartMeAccount(request, {});
      this.smartMeDevices = success(response.getSmartMeDevicesList());
    } catch (error) {
      this.smartMeDevices = failure(userErrorFrom(error));
    }
  }
}
