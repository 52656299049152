export const emailRules = [
  (value: string): string | boolean => !!value || "Pflichtfeld.",
  (value: string): string | boolean => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Keine gültige Email Adresse.";
  },
];

export const ipRules = [
  (value: string): string | boolean => !!value || "Pflichtfeld.",
  (value: string): string | boolean => {
    const pattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return pattern.test(value) || "Keine gültige IP Adresse.";
  },
];

export const hexCodeRule = (val: string) => {
  if (/^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/.test(val)) {
    return true;
  } else {
    return "Color hex code must be valid";
  }
};

export const imageUrlRule = (val: string) => {
  if (/^http.+(png|jpe?g|svg|eps|bmp|gif|ico|webp)$/.test(val)) {
    return true;
  } else {
    return "Image URL must be valid";
  }
};

export const urlRule = (val: string) => {
  const pattern = new RegExp(
    "^(https:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  if (pattern.test(val)) {
    return true;
  } else {
    return "must be a valid HTTPS Url";
  }
};
