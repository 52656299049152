
import { Component, Vue } from "vue-property-decorator";
import AddAutomationDialog from "../../automations/AddAutomationDialog.vue";

@Component({
  components: {
    AddAutomationDialog,
  },
})
export default class AutomationReminder extends Vue {
  showAddAutomationDialog: boolean = false;
}
