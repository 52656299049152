
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Report } from "zaehlerfreunde-proto-types/reporting_pb";

@Component
export default class EditReportScheduleDialog extends Vue {
  @Prop() value: boolean;
  @Prop() metrics: { value: Report.Metric; text: string }[];
  @Prop() frequencies: { value: Report.ScheduleFrequency; text: string }[];
  @Prop({ default: null }) existingSchedule: Report.Schedule | null;
  @Prop({ default: false }) loading: boolean;

  ScheduleFrequency = Report.ScheduleFrequency;

  startDate: Date | null = null;
  selectedMetrics: Report.Metric[] = [];
  selectedFrequency: Report.ScheduleFrequency | null = null;

  name: string = "Energiereport";

  @Watch("existingSchedule")
  onExistingScheduleChanged() {
    const schedule = this.existingSchedule;

    this.name = schedule?.getName() ?? "Energiereport";
    this.startDate = schedule ? new Date(schedule.getNextExecution() * 1000) : null;
    this.selectedFrequency = schedule?.getFrequency() ?? null;
    this.selectedMetrics = schedule?.getMetricsList() ?? [];
  }

  saveSchedule() {
    const schedule = this.existingSchedule ?? new Report.Schedule();

    const frequency = this.selectedFrequency;
    const startDate = this.startDate;
    const metrics = this.selectedMetrics;

    if (frequency !== null && startDate !== null && metrics.length > 0) {
      schedule.setFrequency(frequency);
      schedule.setMetricsList(metrics);
      schedule.setName(this.name);
      schedule.setNextExecution(startDate.getTime() / 1000);

      this.$emit("save:schedule", schedule);
    }
  }
}
