
import spaces from "@/store/modules/spaces";
import tariff from "@/store/modules/tariff";
import { failure, initialized, pending, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { tariffServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class DeleteTariff extends Vue {
  @Prop() value: boolean;
  @Prop() tariffId: string;
  @Prop() deviceMedium: Device.Medium;

  call: RemoteCall<UserError> = initialized;

  async deleteTariff(): Promise<void> {
    try {
      this.call = pending;
      const request = new DeleteTariffRequest();
      request.setTariffId(this.tariffId);

      await tariffServiceClient.deleteTariff(request, {});
      this.$emit("tariff-deleted");
      this.$emit("input", false);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }
}
