
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DatePicker extends Vue {
  @Prop({ default: null }) value: Date | null;
  @Prop({ default: "" }) label: string;
  @Prop({ default: null }) prependIcon: string | null;
  @Prop({ default: true }) allowFutureDates: boolean;
  @Prop({ default: null }) customMax: Date | null;
  @Prop({ default: null }) customMin: Date | null;
  @Prop({ default: null }) rules: ((string) => string | undefined)[] | null;
  @Prop({ default: false }) dense: boolean;
  @Prop({ default: null }) hint: string;
  @Prop({ default: false }) time: boolean;

  menu: boolean = false;

  get max(): string | null {
    if (this.customMax) {
      return moment(this.customMax).format(this.datePickerFormat);
    } else if (!this.allowFutureDates) {
      return moment().format(this.datePickerFormat);
    } else {
      return null;
    }
  }

  get min(): string | null {
    if (this.customMin) {
      return moment(this.customMin).format(this.datePickerFormat);
    } else {
      return "1930-01-01";
    }
  }

  get textFieldFormat(): string {
    if (this.time) {
      return "HH:mm";
    } else {
      return "DD.MM.YYYY";
    }
  }

  get datePickerFormat(): string {
    if (this.time) {
      return "HH:mm";
    } else {
      return "YYYY-MM-DD";
    }
  }

  get textFieldDate(): string {
    if (this.value) {
      return moment(this.value).locale("de").format(this.textFieldFormat);
    } else {
      return "";
    }
  }

  get datePickerDate(): string | undefined {
    if (this.value) {
      return moment(this.value).locale("de").format(this.datePickerFormat);
    } else {
      return undefined;
    }
  }

  set datePickerDate(date: string | undefined) {
    if (date) {
      this.menu = false;
      this.$emit("input", moment(date, this.datePickerFormat).toDate());
    }
  }
}
