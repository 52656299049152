<template>
  <div class="content-container">
    <p class="search-emoji">&#128269;</p>
    <p>
      Wir suchen aktiv nach deinem neuen Ökostromtarif. Sobald wir einen Tarif gefunden haben der günstiger als dein
      aktueller ist, kannst du ihn hier sehen.
      <br />
      <br />
      Durch die momentane angespannte Marktlage, bieten Versorger nur eingeschränkt Tarife an. Umso besser, dass wir
      stetig für dich Ausschau halten!
    </p>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NoTariffFound extends Vue {}
</script>

<style scoped>
.content-container {
  margin-top: 16px;
  border: var(--zf-border);
  padding: 12px 30px 12px 30px;
  border-radius: var(--zf-border-radius-small);
  display: flex;
  gap: 30px;
  align-items: center;
}

.search-emoji {
  font-size: 60px;
}
</style>
