
import { Vue, Component, Prop } from "vue-property-decorator";

export interface Option {
  title: string;
  subtitle?: string;
  image?: string;
  icon?: string;
  value: unknown;
  disabled?: boolean;
  props?: { [name: string]: unknown };
}

@Component
export default class OptionSelection extends Vue {
  @Prop({ default: [] }) options: Option[];
  @Prop({ default: "300px" }) maxHeight: string;

  onOptionSelected(option: Option): void {
    this.$emit("option-selected", option);
  }
}
