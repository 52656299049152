
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { DeviceRegistration, MyStromRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";
import { MyStromDevice, GetMyStromDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { paths } from "@/router/routes";
import devices, { devicesModule } from "@/store/modules/devices";

@Component
export default class MyStromConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  devices: RemoteData<UserError, MyStromDevice[]> = initialized;
  email: string = "";
  password: string = "";
  selectedDevice: string = "";
  acceptedTerms: boolean = false;

  selectedDirection: Direction;
  directionAnswers: { text: string; value: Direction }[] = [
    { text: "Einspeisung/Produktion", value: Direction.OUT },
    { text: "Verbrauch", value: Direction.IN },
  ];

  deviceName(device: MyStromDevice): string {
    return device.getName();
  }

  deviceValue(device: MyStromDevice): string {
    return device.getId();
  }

  @Watch("registerDeviceCall")
  onRegisterDeviceCallChanged(): void {
    if (this.registerDeviceCall.succeeded) {
      devices.getDevices();
      this.$router.push(paths.platform.devices);
    }
  }

  async onLoginClicked(): Promise<void> {
    try {
      this.devices = pending;
      const request = new GetMyStromDevicesRequest();
      request.setEmail(this.email);
      request.setPassword(this.password);
      const response = await deviceServiceClient.getMyStromDevices(request, {});
      this.devices = success(response.getDevicesList());
    } catch (error) {
      this.devices = failure(userErrorFrom(error));
    }
  }

  back(): void {
    this.$router.go(-1);
  }

  async onRegisterClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new MyStromRegistrationDetails();
    details.setMyStromDeviceId(this.selectedDevice);
    details.setEmail(this.email);
    details.setPassword(this.password);
    details.setDirection(this.selectedDirection);

    registration.setMystrom(details);
    devices.registerDevice(registration);
  }
}
