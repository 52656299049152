
import { Vue, Component } from "vue-property-decorator";
import HeroHeader from "@/components/hero-header/HeroHeader.vue";
import KnownFrom from "../../b2c/landing-page/KnownFrom.vue";
import Benefits from "./B2BBenefits.vue";
import OnboardingPath from "./B2BOnboardingPath.vue";
import Newsletter from "@/components/newsletter/Newsletter.vue";
import BookMeeting from "@/components/book-meeting/BookMeeting.vue";

@Component({
  components: {
    HeroHeader,
    KnownFrom,
    Benefits,
    OnboardingPath,
    Newsletter,
    BookMeeting,
  },
})
export default class B2BLandingPage extends Vue {
  showBookMeetingDialog: boolean = false;

  showOnboardingPath(): void {
    let element = document.getElementById("onboarding");
    if (element) {
      element.scrollIntoView({ inline: "end", behavior: "smooth" });
    }
  }
}
