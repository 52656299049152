
import { spaceServiceClient } from "@/config/service-clients";
import { RemoteData, initialized, failure, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetSpaceCategoriesRequest, SpaceCategoryItem } from "zaehlerfreunde-central/space_service_pb";

interface CategoryNode {
  text: string;
  icon: string;
  value: Space.Category;
  item: SpaceCategoryItem;
  children: CategoryNode[];
}

@Component
export default class SelectSpaceCategory extends Vue {
  spaceCategories: RemoteData<UserError, SpaceCategoryItem[]> = initialized;

  selectedNode: CategoryNode[] = [];

  @Watch("selectedNode")
  onSelectedCategoryChanged(): void {
    if (this.selectedNode.length > 0) {
      const node = this.selectedNode[0];
      this.$emit("input", node.item);
    } else {
      this.$emit("input", null);
    }
  }

  get categoryRoots(): CategoryNode[] {
    return this.spaceCategories.list.map((c) => this.toCategoryNode(c));
  }

  toCategoryNode(item: SpaceCategoryItem): CategoryNode {
    return {
      text: item.getCategoryName(),
      icon: item.getCategoryIcon(),
      value: item.getCategory(),
      item: item,
      children: item.getSubCategoriesList().map((c) => this.toCategoryNode(c)),
    };
  }

  async mounted(): Promise<void> {
    try {
      this.spaceCategories = pending;
      const response = await spaceServiceClient.getSpaceCategories(new GetSpaceCategoriesRequest(), {});
      this.spaceCategories = success(response.getCategoriesList());
    } catch (error) {
      this.spaceCategories = failure(userErrorFrom(error));
    }
  }
}
