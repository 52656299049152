
import { localeCookie } from "@/locales/i18n";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class LocalePicker extends Vue {
  locales = [
    {
      name: "DE",
      img: require("@/assets/locales/de.png"),
      value: "de",
    },
    {
      name: "EN",
      img: require("@/assets/locales/en.png"),
      value: "en",
    },
  ];

  updateLocale(locale: string): void {
    this.$root.$i18n.locale = locale;
    this.$cookies.set(localeCookie, locale);
    this.$router.go(0);
  }
}
