
import { deviceServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  GetSolarizeMetersRequest,
  GetSolarizeSitesRequest,
  RegisterDeviceRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import {
  DeviceRegistration,
  SolarizeMeter,
  SolarizeRegistrationDetails,
  SolarizeSite,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { timeStampToFormattedDate } from "@/utils/proto-utils";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";

export interface SiteItem {
  selected: boolean;
  name: string;
  type: string;
  address: string;
  creationDate?: string;
  value: string;
}

export interface MeterItem {
  selected?: boolean;
  name?: string;
  type?: SolarizeMeter.Type;
  typeLabel?: string;
  serial?: string;
  directionLabel?: string;
  state?: string;
  provider?: string;
  value?: string;
}

@Component({
  components: {},
})
export default class SolarizeImportDialog extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  apiToken: string = "";
  step = 1;
  sitesPage = 1;
  sitesItemsPerPage = 5;
  metersPage = 1;
  metersItemsPerPage = 10;

  sitesTotalNumPages = 0;
  metersTotalNumPages = 0;

  sitesHeaders = [
    { value: "selected", sortable: false },
    { text: "Name", value: "name" },
    { text: "Address", value: "address", sortable: false },
    { text: "Type", value: "type", sortable: false },
    { text: "Datum der Erstellung", value: "creationDate", sortable: false },
  ];

  metersHeaders = [
    { value: "selected", sortable: false },
    { text: "Name", value: "name" },
    { text: "Serial", value: "serial", sortable: false },
    { text: "Type", value: "typeLabel", sortable: false },
    { text: "Provider", value: "provider", sortable: false },
    { text: "Direction", value: "directionLabel", sortable: false },
    { text: "State", value: "state", sortable: false },
  ];

  @Watch("metersPage")
  async onMeterPageChanged(): Promise<void> {
    await this.loadMeters();
    if (this.selectAllTenantMeters) this.selectAllTenantMeters = false;
  }

  tenantMeterType = SolarizeMeter.Type.TENANT_METER;

  get sitesItems(): SiteItem[] {
    return this.sites.list.map((site: SolarizeSite) => {
      let siteItem: SiteItem = {
        selected: this.selectedSiteItem?.value === site.getId(),
        name: site.getName(),
        type: site.getType(),
        address:
          site.getAddress()?.getAddressLineOne() +
          ", " +
          site.getAddress()?.getPostcode() +
          ", " +
          site.getAddress()?.getCity(),
        value: site.getId(),
        creationDate: timeStampToFormattedDate(site.getCreatedAt()),
      };
      return siteItem;
    });
  }

  get metersItems(): MeterItem[] {
    let resItems: MeterItem[] = [];

    this.meters.list.forEach((meter: SolarizeMeter) => {
      let meterItem: MeterItem = {
        name: meter.getName(),
        type: meter.getType(),
        typeLabel: meter.getTypeLabel(),
        directionLabel: meter.getDirectionLabel() ?? "",
        serial: meter.getSerial(),
        state: meter.getState(),
        value: meter.getId(),
        provider: meter.getProvider(),
      };
      if (meter.getType() === SolarizeMeter.Type.PV) {
        meterItem = { ...meterItem, selected: this.selectedPVMeterItem?.value === meter.getId() };
      } else if (meter.getType() === SolarizeMeter.Type.BUILDING_METER && meter.getDirection() == Direction.IN) {
        meterItem = { ...meterItem, selected: this.selectedConsumerBuidlingMeterItem?.value === meter.getId() };
      } else if (meter.getType() === SolarizeMeter.Type.BUILDING_METER && meter.getDirection() == Direction.OUT) {
        meterItem = { ...meterItem, selected: this.selectedProducerBuidlingMeterItem?.value === meter.getId() };
      } else {
        meterItem = { ...meterItem, selected: this.selectedTenantMeterItems.some((m) => m.value === meter.getId()) };
      }

      resItems.push(meterItem);
    });

    return resItems;
  }

  get tenantMeterItems(): MeterItem[] {
    return this.metersItems.filter((mI) => mI.type === SolarizeMeter.Type.TENANT_METER);
  }

  get isPVandBuildingMeterSelected(): boolean {
    return (
      !!this.selectedConsumerBuidlingMeterItem && !!this.selectedProducerBuidlingMeterItem && !!this.selectedPVMeterItem
    );
  }

  selectedSiteItem: SiteItem | null = null;
  selectedConsumerBuidlingMeterItem: MeterItem | null = null;
  selectedProducerBuidlingMeterItem: MeterItem | null = null;

  selectedPVMeterItem: MeterItem | null = null;
  selectedTenantMeterItems: MeterItem[] = [];
  selectAllTenantMeters: boolean = false;

  sites: RemoteData<UserError, SolarizeSite[]> = initialized;
  meters: RemoteData<UserError, SolarizeMeter[]> = initialized;
  importCall: RemoteCall<UserError> = initialized;

  onSiteSelected(siteItem: SiteItem, selected: boolean): void {
    if (selected) {
      this.selectedSiteItem = siteItem;
    } else {
      this.selectedSiteItem = null;
    }
  }

  onMeterSelected(meterItem: MeterItem, selected: boolean): void {
    if (meterItem.type === SolarizeMeter.Type.BUILDING_METER) {
      console.log(meterItem);
      if (selected) {
        if (meterItem.directionLabel == "producer") {
          this.selectedConsumerBuidlingMeterItem = meterItem;
        } else {
          this.selectedProducerBuidlingMeterItem = meterItem;
        }
      } else {
        if (meterItem.directionLabel == "producer") {
          this.selectedConsumerBuidlingMeterItem = null;
        } else {
          this.selectedProducerBuidlingMeterItem = null;
        }
      }
    }
    if (meterItem.type === SolarizeMeter.Type.PV) {
      if (selected) {
        this.selectedPVMeterItem = meterItem;
      } else {
        this.selectedPVMeterItem = null;
      }
    }
  }

  onTenantMeterSelected(meterItem: MeterItem, selected: boolean): void {
    if (selected) {
      this.selectedTenantMeterItems.push(meterItem);
    } else {
      this.selectedTenantMeterItems = this.selectedTenantMeterItems.filter((item) => item.value !== meterItem.value);
    }
  }

  onSelectAllTenantMeters(selected: boolean): void {
    this.selectAllTenantMeters = selected;
    if (selected) {
      this.selectedTenantMeterItems = this.selectedTenantMeterItems.concat(this.tenantMeterItems);
    } else {
      this.selectedTenantMeterItems = [];
    }
  }

  async loadSites(): Promise<void> {
    try {
      this.sites = pending;

      const request = new GetSolarizeSitesRequest();

      request.setApiToken(this.apiToken);

      request.setPagination(new Pagination().setPage(this.sitesPage - 1).setPageSize(this.sitesItemsPerPage));

      const response = await deviceServiceClient.getSolarizeSites(request, {});
      this.sites = success(response.getSitesList());
      this.sitesTotalNumPages = response.getTotalNumPages();

      this.step = 2;
    } catch (error) {
      this.sites = failure(userErrorFrom(error));
    }
  }

  async loadMeters(step?: number): Promise<void> {
    try {
      this.meters = pending;

      const request = new GetSolarizeMetersRequest();

      request.setApiToken(this.apiToken);
      if (this.selectedSiteItem?.value) request.setSiteId(this.selectedSiteItem.value);

      request.setPagination(new Pagination().setPage(this.metersPage - 1).setPageSize(this.metersItemsPerPage));

      const response = await deviceServiceClient.getSolarizeMeters(request, {});
      this.meters = success(response.getMetersList());
      this.metersTotalNumPages = response.getTotalNumPages();

      if (step) this.step = step;
    } catch (error) {
      this.meters = failure(userErrorFrom(error));
    }
  }

  async submitImport(): Promise<void> {
    try {
      this.importCall = pending;

      const details = new SolarizeRegistrationDetails();

      details.setApiToken(this.apiToken);
      details.setSite(this.sites.list.find((site) => site.getId() === this.selectedSiteItem?.value));
      details.setBuildingMeter(
        this.meters.list.find((meter) => meter.getId() === this.selectedConsumerBuidlingMeterItem?.value)
      );
      details.setBuildingMeterProducer(
        this.meters.list.find((meter) => meter.getId() === this.selectedProducerBuidlingMeterItem?.value)
      );

      details.setPvMeter(this.meters.list.find((meter) => meter.getId() === this.selectedPVMeterItem?.value));

      details.setTenantMetersList(
        this.meters.list.filter((meter) => this.selectedTenantMeterItems.find((tm) => tm.value === meter.getId()))
      );
      const registration = new DeviceRegistration();
      registration.setSolarize(details);
      registration.setDeviceProvider(Device.Provider.SOLARIZE);

      if (this.selectedChildPartner.data) registration.setPartnerId(this.selectedChildPartner.data.getId());

      const request = new RegisterDeviceRequest();
      request.setRegistration(registration);

      await deviceServiceClient.registerDevice(request, {});

      this.importCall = success(void 0);
      this.sites = initialized;
      this.meters = initialized;
      this.selectedSiteItem = null;
      this.selectedConsumerBuidlingMeterItem = null;
      this.selectedProducerBuidlingMeterItem = null;
      this.selectedPVMeterItem = null;
      this.selectedTenantMeterItems = [];
      this.step = 1;
      this.apiToken = "";

      this.importCall = success(void 0);
      this.$emit("input", false);
    } catch (error) {
      this.importCall = failure(userErrorFrom(error));
    }
  }
}
