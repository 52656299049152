import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { namespace } from "vuex-class";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import {
  GetSimulationsRequest,
  LoadMiniPVSimulationRequest,
  RunMiniPVSimulationRequest,
  DeleteSimulationRequest,
} from "zaehlerfreunde-central/advisory_service_pb";
import { advisoryServiceClient } from "@/config/service-clients";
import { SpaceData } from "../utils/space-data";
import spaces from "./spaces";
import router from "@/router";
import { paths } from "@/router/routes";
import { MiniPVSimulationResult, Simulation } from "zaehlerfreunde-proto-types/simulations_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "simulation",
})
class SimulationModule extends VuexModule {
  pastSimulationsBySpaceId: SpaceData<RemoteData<UserError, Simulation[]>> = {};
  simulationCall: RemoteCall<UserError> = initialized;
  deleteSimulationCall: RemoteCall<UserError> = initialized;
  simulationResult: RemoteData<UserError, MiniPVSimulationResult> = initialized;

  get spacePastSimulations(): RemoteData<UserError, Simulation[]> {
    return this.pastSimulationsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  @Action
  async loadPastSimulations() {
    const request = new GetSimulationsRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    try {
      const response = await advisoryServiceClient.getSimulations(request, {});
      const simulations = response.getSimulationsList();
      this.setPastSimulations(success(simulations));
    } catch (error) {
      this.setPastSimulations(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setPastSimulations(simulations: RemoteData<UserError, Simulation[]>) {
    this.pastSimulationsBySpaceId = {
      ...this.pastSimulationsBySpaceId,
      [spaces.selectedSpaceId]: simulations,
    };
  }

  @Action
  async runMiniPVSimulation(request: RunMiniPVSimulationRequest) {
    request.setSpaceId(spaces.selectedSpaceId);
    try {
      this.setSimulationCall(pending);
      const response = await advisoryServiceClient.runMiniPVSimulation(request, {});
      this.setSimulationCall(success(void 0));
      router.push(`${paths.platform.simulation}/${response.getSimulationId()}`);
    } catch (error) {
      this.setSimulationCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async loadMiniPVSimulation(request: LoadMiniPVSimulationRequest) {
    try {
      this.setSimulationCall(pending);
      const response = await advisoryServiceClient.loadMiniPVSimulation(request, {});
      const result = response.getResult();
      if (result) {
        this.setSimulationResult(success(result));
      } else {
        this.setSimulationResult(failure(userErrorFrom("Beim Laden der Simulation ist ein Fehler aufgetreten")));
      }
    } catch (error) {
      this.setSimulationResult(failure(userErrorFrom(error)));
    }
  }

  @Action
  async deleteSimulation(request: DeleteSimulationRequest) {
    request.setSpaceId(spaces.selectedSpaceId);
    try {
      this.setDeleteSimulationCall(pending);
      await advisoryServiceClient.deleteSimulation(request, {});
      this.setDeleteSimulationCall(success(void 0));
      this.loadPastSimulations();
    } catch (error) {
      this.setDeleteSimulationCall(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setSimulationCall(call: RemoteCall<UserError>) {
    this.simulationCall = call;
  }

  @Mutation
  setSimulationResult(result: RemoteData<UserError, MiniPVSimulationResult>) {
    this.simulationResult = result;
  }

  @Mutation
  setDeleteSimulationCall(call: RemoteCall<UserError>) {
    this.deleteSimulationCall = call;
  }
}

export const simulationModule = namespace("simulation");
export default getModule(SimulationModule);
