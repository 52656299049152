
import { devicesModule } from "@/store/modules/devices";
import { map, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { StateOfChargeTrigger } from "zaehlerfreunde-proto-types/automations_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class StateOfChargeTriggerComponent extends Vue {
  stateOfCharge: number = 20;
  selectedDevice: Device | null = null;

  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  @Prop()
  trigger: StateOfChargeTrigger;

  @Prop({ default: false })
  editing: boolean;

  get socDevices(): RemoteData<UserError, Device[]> {
    return map(this.devices, (devices) =>
      devices.filter((d) => d.getMediumsList().some((m) => m === Device.Medium.STATE_OF_CHARGE))
    );
  }

  mounted() {
    this.stateOfCharge = this.trigger.getStateOfCharge();
    this.selectedDevice =
      this.socDevices.list.find((val) => {
        return val.getId() === this.trigger.getDeviceId();
      }) ?? null;
  }

  @Watch("stateOfCharge")
  onStateOfChargeChanged(): void {
    this.trigger.setStateOfCharge(this.stateOfCharge);
  }

  @Watch("selectedDevice")
  onDeviceSelected(): void {
    this.$emit("valid", this.selectedDevice !== null);
    this.trigger.setDeviceId(this.selectedDevice?.getId() ?? "");
  }
}
