
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import devices, { devicesModule } from "@/store/modules/devices";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { DeviceRegistration, EaseeRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {},
})
export default class EaseeConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  serialNumber = "";
  pinCode = "";
  showPW = false;

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoCompleted(): boolean {
    return this.pinCode.length == 4 && !!this.serialNumber;
  }

  @Watch("registerDeviceCall")
  onRegisterDeviceCallChanged(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  onConnectMeterClicked(): void {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new EaseeRegistrationDetails();
    details.setEaseeSerialNumber(this.serialNumber);
    details.setEaseePinCode(this.pinCode);

    registration.setEasee(details);

    devices.registerDevice(registration);
  }
}
