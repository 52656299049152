
import { Component, Vue, Watch } from "vue-property-decorator";
import Footer from "@/components/layout/Footer.vue";
import SideMenu from "@/components/layout/SideMenu.vue";
import TopBar from "@/components/layout/top-bar/PlatformTopBar.vue";
import theme, { themeModule } from "@/store/modules/theme";
import { PartnerTheme } from "zaehlerfreunde-proto-types/partners_pb";
import { spacesModule } from "@/store/modules/spaces";
import partner from "@/store/modules/partner";
import Announcement from "@/components/announcement/Announcement.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";

const MIN_SIDE_MENU_WIDTH = 1300;

@Component({
  components: {
    TopBar,
    SideMenu,
    Footer,
    Announcement,
  },
})
export default class Platform extends Vue {
  title: string = "";
  hideNavigation: boolean = false;

  sideMenuHidden: boolean = window.innerWidth < MIN_SIDE_MENU_WIDTH;
  showSideMenuAsPopup: boolean = false;

  @themeModule.State partnerTheme: PartnerTheme | null;
  @themeModule.Getter apperance: "light" | "dark";

  @spacesModule.State selectedSpace: Space | null;

  metaInfo(): { titleTemplate: string } {
    return {
      titleTemplate: this.selectedSpace != null ? `%s - ${this.selectedSpace.getName()}` : "%s",
    };
  }

  mounted(): void {
    this.onRouteChanged();
    this.onApperanceChanged();
    this.onPartnerThemeChanged();

    theme.setPreferredApperance(
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
    );

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
      theme.setPreferredApperance(event.matches ? "dark" : "light");
    });

    partner.getPartner();
    partner.getCustomizationConfig();
  }

  @Watch("$route")
  onRouteChanged(): void {
    const meta = this.$route.meta ?? {};
    this.title = meta.title ?? "";
    this.hideNavigation = meta.hideNavigation ?? false;
  }

  resizeEventHandler(): void {
    this.sideMenuHidden = window.innerWidth < MIN_SIDE_MENU_WIDTH;
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  onShowSideMenu(): void {
    (this.$refs.sideMenu as SideMenu).show();
  }

  @Watch("partnerTheme")
  onPartnerThemeChanged(): void {
    if (this.partnerTheme !== null) {
      var r = document.querySelector(":root") as HTMLElement;
      r.style.setProperty("--zf-font-family", this.partnerTheme.getFontFamily());
      r.style.setProperty("--zf-primary-color", this.partnerTheme.getPrimaryColor());
      r.style.setProperty("--zf-secondary-color", this.partnerTheme.getSecondaryColor());
      r.style.setProperty("--zf-accent-color", this.partnerTheme.getAccentColor());
      r.style.setProperty("--zf-error-color", this.partnerTheme.getErrorColor());
      r.style.setProperty("--zf-info-color", this.partnerTheme.getInfoColor());
      r.style.setProperty("--zf-success-color", this.partnerTheme.getSuccessColor());
      r.style.setProperty("--zf-warning-color", this.partnerTheme.getWarningColor());

      if (this.partnerTheme.getFavicon()) {
        var link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href = this.partnerTheme.getFavicon();
      }
    }
  }

  @Watch("apperance")
  onApperanceChanged(): void {
    var r = document.querySelector(":root") as HTMLElement;

    if (this.apperance === "dark") {
      this.$vuetify.theme.dark = true;
      r.style.setProperty("color-scheme", "dark");
      r.style.setProperty("--zf-text-color", "var(--zf-text-color-dark)");
      r.style.setProperty("--zf-background", "var(--zf-background-dark)");
      r.style.setProperty("--zf-box-shadow", "var(--zf-box-shadow-dark)");
      r.style.setProperty("--zf-box-shadow-highlight", "var(--zf-box-shadow-highlight-dark)");
      r.style.setProperty("--zf-elevated-background", "var(--zf-elevated-background-dark)");
      r.style.setProperty("--zf-gray", "var(--zf-gray-dark)");
      r.style.setProperty("--zf-border-color", "var(--zf-border-color-dark)");
      r.style.setProperty("--zf-option-selected", "var(--zf-option-selected-dark)");
      r.style.setProperty("--zf-option-hover", "var(--zf-option-hover-dark)");
    } else {
      this.$vuetify.theme.dark = false;
      r.style.setProperty("color-scheme", "light");
      r.style.setProperty("--zf-text-color", "var(--zf-text-color-light)");
      r.style.setProperty("--zf-background", "var(--zf-background-light)");
      r.style.setProperty("--zf-box-shadow", "var(--zf-box-shadow-light)");
      r.style.setProperty("--zf-box-shadow-highlight", "var(--zf-box-shadow-highlight-light)");
      r.style.setProperty("--zf-elevated-background", "var(--zf-elevated-background-light)");
      r.style.setProperty("--zf-gray", "var(--zf-gray-light)");
      r.style.setProperty("--zf-border-color", "var(--zf-border-color-light)");
      r.style.setProperty("--zf-option-selected", "var(--zf-option-selected-light)");
      r.style.setProperty("--zf-option-hover", "var(--zf-option-hover-light)");
    }
  }
}
