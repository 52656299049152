
import SubscriptionPlans from "@/components/subscription/SubscriptionPlans.vue";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: { SubscriptionPlans },
})
export default class Pricing extends Vue {
  //   onActivatePlanClicked(plan: SubscriptionPlan): void {
  //     if (plan === SubscriptionPlan.FREE) {
  //       this.$gtag.event("registration-clicked", {
  //         event_label: "Free Plan Registration clicked",
  //         event_category: "user-action",
  //       });
  //     } else {
  //       this.$gtag.event("registration-clicked", {
  //         event_label: "Pro Plan Registration clicked",
  //         event_category: "user-action",
  //       });
  //     }
  //     this.$gtag.event("conversion", {
  //       send_to: "AW-10831568791/--0CCJP1sI0YEJe_8qwo",
  //       value: 10,
  //       currency: "USD",
  //     });
  //     getAuthService().loginWithRedirect({
  //       appState: {
  //         targetUrl: plan === SubscriptionPlan.PRO ? paths.platform.account.subscriptions : undefined,
  //       },
  //       authorizationParams: {
  //         screen_hint: "signup",
  //       },
  //     });
  //   }
}
