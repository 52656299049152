
import { Component, Vue, Watch } from "vue-property-decorator";
import GeneralProductsCollection from "./GeneralProductsCollection.vue";
import SupportedProductsCollection from "./SupportedProductsCollection.vue";
import MiniPvModulesCollection from "./MiniPvModulesCollection.vue";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";

@Component({
  components: {
    GeneralProductsCollection,
    SupportedProductsCollection,
    MiniPvModulesCollection,
  },
})
export default class Shop extends Vue {
  @featuresModules.Getter enabledFeatures: Set<Feature>;
  @partnerModule.Getter isPartnerHerne: boolean;

  @Watch("enabledFeatures")
  onEnabledFeaturesChanged() {
    if (!this.enabledFeatures.has(Feature.SHOP)) {
      this.$router.replace(paths.platform.dashboard);
    }
  }

  mounted() {
    if (this.isPartnerHerne) {
      let divScripts = document.getElementById("main");
      let newScript = document.createElement("script");
      newScript.src = "https://configscript.twntydigital.de/priwatt-configurator.js";
      if (divScripts) {
        divScripts.appendChild(newScript);
      }
    }
  }
}
