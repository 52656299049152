// source: types/reporting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.Report', null, global);
goog.exportSymbol('proto.types.Report.Breakdown', null, global);
goog.exportSymbol('proto.types.Report.Content', null, global);
goog.exportSymbol('proto.types.Report.Metric', null, global);
goog.exportSymbol('proto.types.Report.MetricEvaluation', null, global);
goog.exportSymbol('proto.types.Report.PreviousPeriod', null, global);
goog.exportSymbol('proto.types.Report.Schedule', null, global);
goog.exportSymbol('proto.types.Report.ScheduleFrequency', null, global);
goog.exportSymbol('proto.types.Trend', null, global);
goog.exportSymbol('proto.types.Trend.Sentiment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Report.displayName = 'proto.types.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Report.Schedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Report.Schedule.repeatedFields_, null);
};
goog.inherits(proto.types.Report.Schedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Report.Schedule.displayName = 'proto.types.Report.Schedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Report.MetricEvaluation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Report.MetricEvaluation.repeatedFields_, null);
};
goog.inherits(proto.types.Report.MetricEvaluation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Report.MetricEvaluation.displayName = 'proto.types.Report.MetricEvaluation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Report.PreviousPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Report.PreviousPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Report.PreviousPeriod.displayName = 'proto.types.Report.PreviousPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Report.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Report.Content.repeatedFields_, null);
};
goog.inherits(proto.types.Report.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Report.Content.displayName = 'proto.types.Report.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Report.Breakdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Report.Breakdown.repeatedFields_, null);
};
goog.inherits(proto.types.Report.Breakdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Report.Breakdown.displayName = 'proto.types.Report.Breakdown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Trend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Trend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Trend.displayName = 'proto.types.Trend';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scheduleName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timePeriodDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creationTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Report}
 */
proto.types.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Report;
  return proto.types.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Report}
 */
proto.types.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimePeriodDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScheduleName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimePeriodDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreationTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.Report.ScheduleFrequency = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
  YEARLY: 3
};

/**
 * @enum {number}
 */
proto.types.Report.Metric = {
  CONSUMPTION: 0,
  PRODUCTION: 1,
  COST: 2,
  EARNINGS: 3,
  CO2: 4,
  CONSUMPTION_PER_SQM: 5,
  CONSUMPTION_PER_EMPLOYEE: 6
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Report.Schedule.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Report.Schedule.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Report.Schedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Report.Schedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.Schedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequencydescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nextExecution: jspb.Message.getFieldWithDefault(msg, 5, 0),
    metricsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    metricDescriptionsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    spaceId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Report.Schedule}
 */
proto.types.Report.Schedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Report.Schedule;
  return proto.types.Report.Schedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Report.Schedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Report.Schedule}
 */
proto.types.Report.Schedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Report.ScheduleFrequency} */ (reader.readEnum());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequencydescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextExecution(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.types.Report.Metric>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMetrics(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addMetricDescriptions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Report.Schedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Report.Schedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Report.Schedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.Schedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFrequencydescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNextExecution();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getMetricDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Report.Schedule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.Report.Schedule.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ScheduleFrequency frequency = 3;
 * @return {!proto.types.Report.ScheduleFrequency}
 */
proto.types.Report.Schedule.prototype.getFrequency = function() {
  return /** @type {!proto.types.Report.ScheduleFrequency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Report.ScheduleFrequency} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string frequencyDescription = 4;
 * @return {string}
 */
proto.types.Report.Schedule.prototype.getFrequencydescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setFrequencydescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 next_execution = 5;
 * @return {number}
 */
proto.types.Report.Schedule.prototype.getNextExecution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setNextExecution = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Metric metrics = 6;
 * @return {!Array<!proto.types.Report.Metric>}
 */
proto.types.Report.Schedule.prototype.getMetricsList = function() {
  return /** @type {!Array<!proto.types.Report.Metric>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.types.Report.Metric>} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setMetricsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.types.Report.Metric} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.addMetrics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};


/**
 * repeated string metric_descriptions = 7;
 * @return {!Array<string>}
 */
proto.types.Report.Schedule.prototype.getMetricDescriptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setMetricDescriptionsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.addMetricDescriptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.clearMetricDescriptionsList = function() {
  return this.setMetricDescriptionsList([]);
};


/**
 * optional string space_id = 8;
 * @return {string}
 */
proto.types.Report.Schedule.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Schedule} returns this
 */
proto.types.Report.Schedule.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Report.MetricEvaluation.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Report.MetricEvaluation.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Report.MetricEvaluation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Report.MetricEvaluation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.MetricEvaluation.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    previousPeriodsList: jspb.Message.toObjectList(msg.getPreviousPeriodsList(),
    proto.types.Report.PreviousPeriod.toObject, includeInstance),
    metric: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trend: (f = msg.getTrend()) && proto.types.Trend.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Report.MetricEvaluation}
 */
proto.types.Report.MetricEvaluation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Report.MetricEvaluation;
  return proto.types.Report.MetricEvaluation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Report.MetricEvaluation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Report.MetricEvaluation}
 */
proto.types.Report.MetricEvaluation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = new proto.types.Report.PreviousPeriod;
      reader.readMessage(value,proto.types.Report.PreviousPeriod.deserializeBinaryFromReader);
      msg.addPreviousPeriods(value);
      break;
    case 5:
      var value = /** @type {!proto.types.Report.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 6:
      var value = new proto.types.Trend;
      reader.readMessage(value,proto.types.Trend.deserializeBinaryFromReader);
      msg.setTrend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Report.MetricEvaluation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Report.MetricEvaluation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Report.MetricEvaluation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.MetricEvaluation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreviousPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.types.Report.PreviousPeriod.serializeBinaryToWriter
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTrend();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.types.Trend.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.Report.MetricEvaluation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.MetricEvaluation} returns this
 */
proto.types.Report.MetricEvaluation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.types.Report.MetricEvaluation.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report.MetricEvaluation} returns this
 */
proto.types.Report.MetricEvaluation.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.types.Report.MetricEvaluation.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.MetricEvaluation} returns this
 */
proto.types.Report.MetricEvaluation.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PreviousPeriod previous_periods = 4;
 * @return {!Array<!proto.types.Report.PreviousPeriod>}
 */
proto.types.Report.MetricEvaluation.prototype.getPreviousPeriodsList = function() {
  return /** @type{!Array<!proto.types.Report.PreviousPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Report.PreviousPeriod, 4));
};


/**
 * @param {!Array<!proto.types.Report.PreviousPeriod>} value
 * @return {!proto.types.Report.MetricEvaluation} returns this
*/
proto.types.Report.MetricEvaluation.prototype.setPreviousPeriodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.types.Report.PreviousPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Report.PreviousPeriod}
 */
proto.types.Report.MetricEvaluation.prototype.addPreviousPeriods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.types.Report.PreviousPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.MetricEvaluation} returns this
 */
proto.types.Report.MetricEvaluation.prototype.clearPreviousPeriodsList = function() {
  return this.setPreviousPeriodsList([]);
};


/**
 * optional Metric metric = 5;
 * @return {!proto.types.Report.Metric}
 */
proto.types.Report.MetricEvaluation.prototype.getMetric = function() {
  return /** @type {!proto.types.Report.Metric} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.Report.Metric} value
 * @return {!proto.types.Report.MetricEvaluation} returns this
 */
proto.types.Report.MetricEvaluation.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Trend trend = 6;
 * @return {?proto.types.Trend}
 */
proto.types.Report.MetricEvaluation.prototype.getTrend = function() {
  return /** @type{?proto.types.Trend} */ (
    jspb.Message.getWrapperField(this, proto.types.Trend, 6));
};


/**
 * @param {?proto.types.Trend|undefined} value
 * @return {!proto.types.Report.MetricEvaluation} returns this
*/
proto.types.Report.MetricEvaluation.prototype.setTrend = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Report.MetricEvaluation} returns this
 */
proto.types.Report.MetricEvaluation.prototype.clearTrend = function() {
  return this.setTrend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Report.MetricEvaluation.prototype.hasTrend = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Report.PreviousPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Report.PreviousPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Report.PreviousPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.PreviousPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Report.PreviousPeriod}
 */
proto.types.Report.PreviousPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Report.PreviousPeriod;
  return proto.types.Report.PreviousPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Report.PreviousPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Report.PreviousPeriod}
 */
proto.types.Report.PreviousPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Report.PreviousPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Report.PreviousPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Report.PreviousPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.PreviousPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.types.Report.PreviousPeriod.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report.PreviousPeriod} returns this
 */
proto.types.Report.PreviousPeriod.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.types.Report.PreviousPeriod.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.PreviousPeriod} returns this
 */
proto.types.Report.PreviousPeriod.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Report.Content.repeatedFields_ = [2,3,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Report.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Report.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Report.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mainDeviceNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    metricsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    numDevices: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numChildSpaces: jspb.Message.getFieldWithDefault(msg, 5, 0),
    evaluationsList: jspb.Message.toObjectList(msg.getEvaluationsList(),
    proto.types.Report.MetricEvaluation.toObject, includeInstance),
    deviceBreakdownsList: jspb.Message.toObjectList(msg.getDeviceBreakdownsList(),
    proto.types.Report.Breakdown.toObject, includeInstance),
    childSpaceBreakdownsList: jspb.Message.toObjectList(msg.getChildSpaceBreakdownsList(),
    proto.types.Report.Breakdown.toObject, includeInstance),
    metaDataMap: (f = msg.getMetaDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Report.Content}
 */
proto.types.Report.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Report.Content;
  return proto.types.Report.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Report.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Report.Content}
 */
proto.types.Report.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addMainDeviceNames(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addMetrics(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumDevices(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumChildSpaces(value);
      break;
    case 6:
      var value = new proto.types.Report.MetricEvaluation;
      reader.readMessage(value,proto.types.Report.MetricEvaluation.deserializeBinaryFromReader);
      msg.addEvaluations(value);
      break;
    case 7:
      var value = new proto.types.Report.Breakdown;
      reader.readMessage(value,proto.types.Report.Breakdown.deserializeBinaryFromReader);
      msg.addDeviceBreakdowns(value);
      break;
    case 8:
      var value = new proto.types.Report.Breakdown;
      reader.readMessage(value,proto.types.Report.Breakdown.deserializeBinaryFromReader);
      msg.addChildSpaceBreakdowns(value);
      break;
    case 9:
      var value = msg.getMetaDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Report.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Report.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Report.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMainDeviceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getNumDevices();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumChildSpaces();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEvaluationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.types.Report.MetricEvaluation.serializeBinaryToWriter
    );
  }
  f = message.getDeviceBreakdownsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.types.Report.Breakdown.serializeBinaryToWriter
    );
  }
  f = message.getChildSpaceBreakdownsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.types.Report.Breakdown.serializeBinaryToWriter
    );
  }
  f = message.getMetaDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string space_name = 1;
 * @return {string}
 */
proto.types.Report.Content.prototype.getSpaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.setSpaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string main_device_names = 2;
 * @return {!Array<string>}
 */
proto.types.Report.Content.prototype.getMainDeviceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.setMainDeviceNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.addMainDeviceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.clearMainDeviceNamesList = function() {
  return this.setMainDeviceNamesList([]);
};


/**
 * repeated string metrics = 3;
 * @return {!Array<string>}
 */
proto.types.Report.Content.prototype.getMetricsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.setMetricsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.addMetrics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};


/**
 * optional int32 num_devices = 4;
 * @return {number}
 */
proto.types.Report.Content.prototype.getNumDevices = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.setNumDevices = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 num_child_spaces = 5;
 * @return {number}
 */
proto.types.Report.Content.prototype.getNumChildSpaces = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.setNumChildSpaces = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated MetricEvaluation evaluations = 6;
 * @return {!Array<!proto.types.Report.MetricEvaluation>}
 */
proto.types.Report.Content.prototype.getEvaluationsList = function() {
  return /** @type{!Array<!proto.types.Report.MetricEvaluation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Report.MetricEvaluation, 6));
};


/**
 * @param {!Array<!proto.types.Report.MetricEvaluation>} value
 * @return {!proto.types.Report.Content} returns this
*/
proto.types.Report.Content.prototype.setEvaluationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.types.Report.MetricEvaluation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Report.MetricEvaluation}
 */
proto.types.Report.Content.prototype.addEvaluations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.types.Report.MetricEvaluation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.clearEvaluationsList = function() {
  return this.setEvaluationsList([]);
};


/**
 * repeated Breakdown device_breakdowns = 7;
 * @return {!Array<!proto.types.Report.Breakdown>}
 */
proto.types.Report.Content.prototype.getDeviceBreakdownsList = function() {
  return /** @type{!Array<!proto.types.Report.Breakdown>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Report.Breakdown, 7));
};


/**
 * @param {!Array<!proto.types.Report.Breakdown>} value
 * @return {!proto.types.Report.Content} returns this
*/
proto.types.Report.Content.prototype.setDeviceBreakdownsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.types.Report.Breakdown=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Breakdown}
 */
proto.types.Report.Content.prototype.addDeviceBreakdowns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.types.Report.Breakdown, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.clearDeviceBreakdownsList = function() {
  return this.setDeviceBreakdownsList([]);
};


/**
 * repeated Breakdown child_space_breakdowns = 8;
 * @return {!Array<!proto.types.Report.Breakdown>}
 */
proto.types.Report.Content.prototype.getChildSpaceBreakdownsList = function() {
  return /** @type{!Array<!proto.types.Report.Breakdown>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Report.Breakdown, 8));
};


/**
 * @param {!Array<!proto.types.Report.Breakdown>} value
 * @return {!proto.types.Report.Content} returns this
*/
proto.types.Report.Content.prototype.setChildSpaceBreakdownsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.types.Report.Breakdown=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Breakdown}
 */
proto.types.Report.Content.prototype.addChildSpaceBreakdowns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.types.Report.Breakdown, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.clearChildSpaceBreakdownsList = function() {
  return this.setChildSpaceBreakdownsList([]);
};


/**
 * map<string, string> meta_data = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.types.Report.Content.prototype.getMetaDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.types.Report.Content} returns this
 */
proto.types.Report.Content.prototype.clearMetaDataMap = function() {
  this.getMetaDataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Report.Breakdown.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Report.Breakdown.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Report.Breakdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Report.Breakdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.Breakdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    entitiesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    entityIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    metric: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Report.Breakdown}
 */
proto.types.Report.Breakdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Report.Breakdown;
  return proto.types.Report.Breakdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Report.Breakdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Report.Breakdown}
 */
proto.types.Report.Breakdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntities(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntityIds(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Report.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Report.Breakdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Report.Breakdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Report.Breakdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Report.Breakdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEntityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.Report.Breakdown.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.types.Report.Breakdown.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated double values = 3;
 * @return {!Array<number>}
 */
proto.types.Report.Breakdown.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * repeated string entities = 4;
 * @return {!Array<string>}
 */
proto.types.Report.Breakdown.prototype.getEntitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.setEntitiesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.addEntities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};


/**
 * repeated string entity_ids = 5;
 * @return {!Array<string>}
 */
proto.types.Report.Breakdown.prototype.getEntityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.setEntityIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.addEntityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.clearEntityIdsList = function() {
  return this.setEntityIdsList([]);
};


/**
 * optional Metric metric = 6;
 * @return {!proto.types.Report.Metric}
 */
proto.types.Report.Breakdown.prototype.getMetric = function() {
  return /** @type {!proto.types.Report.Metric} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Report.Metric} value
 * @return {!proto.types.Report.Breakdown} returns this
 */
proto.types.Report.Breakdown.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Report.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string schedule_name = 3;
 * @return {string}
 */
proto.types.Report.prototype.getScheduleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setScheduleName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string time_period_description = 4;
 * @return {string}
 */
proto.types.Report.prototype.getTimePeriodDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setTimePeriodDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string frequency = 5;
 * @return {string}
 */
proto.types.Report.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 creation_time = 6;
 * @return {number}
 */
proto.types.Report.prototype.getCreationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setCreationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 start_time = 7;
 * @return {number}
 */
proto.types.Report.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 end_time = 8;
 * @return {number}
 */
proto.types.Report.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Report} returns this
 */
proto.types.Report.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Trend.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Trend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Trend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Trend.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentChanged: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    sentiment: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Trend}
 */
proto.types.Trend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Trend;
  return proto.types.Trend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Trend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Trend}
 */
proto.types.Trend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentChanged(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Trend.Sentiment} */ (reader.readEnum());
      msg.setSentiment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Trend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Trend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Trend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Trend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentChanged();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSentiment();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.Trend.Sentiment = {
  GOOD: 0,
  BAD: 1
};

/**
 * optional double percent_changed = 1;
 * @return {number}
 */
proto.types.Trend.prototype.getPercentChanged = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Trend} returns this
 */
proto.types.Trend.prototype.setPercentChanged = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Sentiment sentiment = 2;
 * @return {!proto.types.Trend.Sentiment}
 */
proto.types.Trend.prototype.getSentiment = function() {
  return /** @type {!proto.types.Trend.Sentiment} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Trend.Sentiment} value
 * @return {!proto.types.Trend} returns this
 */
proto.types.Trend.prototype.setSentiment = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.types);
