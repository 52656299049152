
import { uiServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, successOptional } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { CurrentMonthWidgetData, GetCurrentMonthWidgetDataRequest } from "zaehlerfreunde-central/ui_service_pb";

@Component
export default class CurrentMonth extends Vue {
  @Prop() dataType: GetCurrentMonthWidgetDataRequest.DataType;
  @Prop() selectedDeviceId: string;

  data: RemoteData<UserError, CurrentMonthWidgetData> = initialized;
  Tendency = CurrentMonthWidgetData.Delta.Tendency;

  get values(): CurrentMonthWidgetData.Value[] {
    return this.data.data?.getValuesList() ?? [];
  }

  get delta(): CurrentMonthWidgetData.Delta | undefined {
    return this.data.data?.getDelta();
  }

  mounted(): void {
    this.loadData();
  }

  @Watch("selectedDeviceId")
  loadData(): void {
    if (this.selectedDeviceId) {
      this.data = pending;

      const request = new GetCurrentMonthWidgetDataRequest();
      request.setDeviceId(this.selectedDeviceId);
      request.setDataType(this.dataType);
      request.setSpaceId(spaces.selectedSpaceId);

      const selectedDeviceId = this.selectedDeviceId;

      uiServiceClient
        .getCurrentMonthWidgetData(request, {})
        .then((response) => {
          if (this.selectedDeviceId === selectedDeviceId) {
            this.data = successOptional(response.getData());
          }
        })
        .catch((error) => {
          if (this.selectedDeviceId === selectedDeviceId) {
            this.data = failure(userErrorFrom(error));
          }
        });
    }
  }
}
