
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { advisoryServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { TrackTipInteractionRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { Tip, TipInteraction } from "zaehlerfreunde-proto-types/tips_pb";

@Component
export default class TipExpanded extends Vue {
  @Prop() tip: Tip;
  @Prop() value: boolean;

  updateTipCall: RemoteCall<UserError> = initialized;

  async onCompleteTipClicked(completed: boolean): Promise<void> {
    try {
      this.updateTipCall = pending;

      const interaction = new TipInteraction();
      interaction.setTipId(this.tip.getId());
      interaction.setKind(completed ? TipInteraction.Kind.COMPLETED : TipInteraction.Kind.UNCOMPLETED);

      const request = new TrackTipInteractionRequest();
      request.setInteraction(interaction);

      await advisoryServiceClient.trackTipInteraction(request, {});

      this.tip.setIsCompleted(completed);
      this.updateTipCall = success(void 0);
    } catch (error) {
      this.updateTipCall = failure(userErrorFrom(error));
    }
  }

  onAffiliateLinkClicked(): void {
    this.$gtag.event("affiliate-link-opened", {
      event_category: "user-action",
      event_label: `Affiliate link of ${this.tip.getTitle()} tip opened`,
    });
  }
}
