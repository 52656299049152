
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HeroHeader extends Vue {
  @Prop() title: string;
  @Prop() subtitle: string;
  @Prop() img: string;
  @Prop() primaryActionIcon: string;
  @Prop() primaryActionTitle: string;
  @Prop() secondaryActionTitle: string;
  @Prop({ default: false }) animateBackgroundColors: boolean;

  get imageUrl(): string {
    var images = require.context("@/assets/hero-image", false, /\.png$/);
    return images("./" + this.img);
  }

  get isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
