
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AdvancePaymentWidgetData } from "zaehlerfreunde-central/ui_service_pb";

@Component
export default class CostPrediction extends Vue {
  @Prop() advancePaymentData: RemoteData<UserError, AdvancePaymentWidgetData>;
}
