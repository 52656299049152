
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient, userServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { CreateInviteRequest } from "zaehlerfreunde-central/user_service_pb";
import { AccessRole } from "zaehlerfreunde-proto-types/permissions_pb";
import { GetSpaceAccessRolesRequest } from "zaehlerfreunde-central/space_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class SendSpaceInvite extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  @Prop() space: Space | null;
  @Prop() withoutAdminOption: boolean;

  inviteToSpaceCall: RemoteCall<UserError> = initialized;
  spaceAccessRoles: RemoteData<UserError, AccessRole[]> = initialized;

  email: string = "";
  inviteAsAdmin: boolean = false;
  selectedAccessRole: string | null = null;

  validEmailRule = (email: string) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? true
      : "Keine Gültige E-Mail Adresse";

  get spaceAccessRoleOptions(): { text: string; value: string }[] {
    return this.spaceAccessRoles.list.map((role) => ({
      text: role.getName(),
      value: role.getId(),
    }));
  }

  async mounted(): Promise<void> {
    try {
      this.spaceAccessRoles = pending;
      const response = await spaceServiceClient.getSpaceAccessRoles(new GetSpaceAccessRolesRequest(), {});
      this.spaceAccessRoles = success(response.getAccessRolesList());
    } catch (error) {
      this.spaceAccessRoles = failure(userErrorFrom(error));
    }
  }

  async onSaveClicked(): Promise<void> {
    try {
      this.inviteToSpaceCall = pending;

      const request = new CreateInviteRequest();
      request.setSendEmail(true);
      request.setEmail(this.email);
      request.setInviteAsAdmin(this.inviteAsAdmin);

      if (this.space) {
        request.setSpaceId(this.space.getId() ?? "");

        if (this.selectedAccessRole) {
          request.setAccessRole(this.selectedAccessRole);
        }
      }

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      await userServiceClient.createInvite(request, {});

      this.inviteToSpaceCall = success(void 0);
      this.$emit("invite-sent");
      this.$emit("input", false);
    } catch (error) {
      this.inviteToSpaceCall = failure(userErrorFrom(error));
    }
  }
}
