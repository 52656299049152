
import { getEnvironment } from "@/config/env";
import { Vue, Component } from "vue-property-decorator";

const COOKIES_CONSENT_KEY = "cookies_consent";
const environment = getEnvironment();

interface FacebookPixel {
  fbq(method: string, param: string);
}

@Component
export default class CookieBanner extends Vue {
  showBanner: boolean = true;
  customSettings: boolean = false;

  essentialCookies = true;
  marketingCookies = false;

  beforeMount(): void {
    if (environment.isProd) {
      this.showBanner = !this.$cookies.isKey(COOKIES_CONSENT_KEY);

      if (this.$cookies.get(COOKIES_CONSENT_KEY) === "true") {
        this.enableTracking();
      }
    } else {
      this.showBanner = false;
    }
  }

  onDecisionMade(accepted: boolean): void {
    this.showBanner = false;
    this.$cookies.set(COOKIES_CONSENT_KEY, accepted, "1y");

    if (accepted) {
      this.enableTracking();
    }
  }

  enableTracking(): void {
    const pixel = window as unknown as FacebookPixel;
    pixel.fbq("init", "717916320066012");
    pixel.fbq("track", "PageView");
    this.$gtag.optIn();
  }
}
