
import { Option } from "@/components/core/funnel/OptionSelection.vue";
import { deviceServiceClient } from "@/config/service-clients";
import { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { GetDeviceTypesRequest } from "zaehlerfreunde-central/device_service_pb";
import { DeviceTypeInfo } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class DeviceType extends Vue {
  deviceTypes: RemoteData<UserError, DeviceTypeInfo[]> = initialized;

  @spacesModule.Getter selectedSpaceId: string;

  async mounted(): Promise<void> {
    try {
      this.deviceTypes = pending;

      const request = new GetDeviceTypesRequest();

      if (this.selectedSpaceId) {
        request.setSpaceId(this.selectedSpaceId);
      }

      const response = await deviceServiceClient.getDeviceTypes(request, {});
      this.deviceTypes = success(response.getDeviceTypesList());
    } catch (error) {
      this.deviceTypes = failure(userErrorFrom(error));
    }
  }

  get deviceTypeOptions(): Option[] {
    return this.deviceTypes.list.map((dt: DeviceTypeInfo) => ({
      title: dt.getName(),
      subtitle: dt.getDescription(),
      image: dt.getImageUrl(),
      value: dt,
    }));
  }

  onDeviceTypeSelected(deviceTypeInfoOption: Option): void {
    this.$emit("device-type-selected", deviceTypeInfoOption.value as DeviceTypeInfo);
  }
}
