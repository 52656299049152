
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ApiDocumentation extends Vue {
  JSON = JSON;
  url = "https://api.prod.zaehlerfreunde.com:38866";
  showUrlCopiedSnackbar = false;

  endpoints = [
    {
      method: "POST",
      shortDescription: "API Token Erstellen",
      description:
        "Erstellt einen API Token welcher zum Aufrufen der anderen API Endpunkte benötigt wird. Client ID & Secret können oben erstellt werden.",
      path: "/Partner.PartnerService/CreateApiToken",
      requestBody: {
        client_id: "string",
        client_secret: "string",
      },
      responseBody: {
        access_token: "string",
      },
    },
    {
      method: "POST",
      shortDescription: "Objekt Erstellen",
      description: "Erstellt ein neues Objekt und gibt die Objekt-ID zurück.",
      path: "/Space.SpaceService/CreateSpace",
      requestBody: {
        name: "string",
        address_line_one: "string",
        address_line_two: "string (Optional)",
        postcode: "string",
        city: "string",
        category: [
          "0 = HOTEL",
          "1 = RESTAURANT",
          "2 = HOUSEHOLD",
          "3 = OFFICE",
          "4 = PV_SYSTEM",
          "5 = COMMERCIAL",
          "6 = BIOGAS",
          "7 = RETAIL",
          "8 = SINGLE_FAMILY_HOME",
          "9 = PRODUCTION",
          "10 = FLAT",
          "11 = WIND_ENERGY",
          "12 = DOCTORS_OFFICE",
        ],
        parent_space_id: "string (Optional)",
      },
      responseBody: {
        space_id: "string",
      },
    },
    {
      method: "POST",
      shortDescription: "Nutzer Einladen",
      description:
        "Lädt einen Nutzer ein, ein Objekt einzusehen. Damit kann der Nutzer alle Gerätedaten verbunden mit dem Objekt einsehen.",
      path: "/Space.SpaceService/InviteUserToSpace",
      requestBody: {
        space_id: "string",
        send_invite_email: "boolean",
        new_user_invitation: {
          email: "string",
        },
      },
      responseBody: {
        invite_link: "string",
      },
    },
    {
      method: "POST",
      shortDescription: "Nutzer von Objekt trennen",
      description: "Trennt den Nutzer vom Objekt. Damit kann er keine Objekt Daten mehr einsehen.",
      path: "/Space.SpaceService/RemoveUserFromSpace",
      requestBody: {
        space_id: "string",
        user_id: "string",
      },
      responseBody: {},
    },
    {
      method: "POST",
      shortDescription: "Alle Nutzer von Objekt trennen",
      description: "Trennt alle Nutzer vom Objekt. Damit kann niemand mehr Objekt Daten einsehen.",
      path: "/Space.SpaceService/RemoveAllUsersFromSpace",
      requestBody: {
        space_id: "string",
      },
      responseBody: {},
    },
    {
      method: "POST",
      shortDescription: "Alle Objekte",
      description: "Gibt alle Partner Objekte zurück. Der parameter 'partner_spaces' muss auf true gesetzt werden.",
      path: "/Space.SpaceService/GetSpaces",
      requestBody: {
        partner_spaces: "true",
        pagination: {
          page: "int",
          page_size: "int",
        },
      },
      responseBody: {
        total_num_pages: "int",
        spaces: "Space[]",
      },
    },
    {
      method: "POST",
      shortDescription: "Geräte registrieren",
      description:
        "Fügt ein neues Gerät zu einem Objekt hinzu. Bei externen Geräten müssen die Daten an unsere API übetragen werden. Um z.b. ein externes Gerät hinzuzufügen muss 'external' gesetzt werden. Bei BlueMetering z.b. 'bluemetering'.",
      path: "/Device.DeviceService/RegisterDevice",
      requestBody: {
        registration: {
          device_name: "string",
          space_mapping: {
            space_id: "string",
            is_main_device: "boolean",
          },
          device_type: [
            "0 = SMART_METER",
            "1 = OPTICAL_METER_SENSOR",
            "2 = WALLBOX",
            "3 = SMART_PLUG",
            "4 = SMART_RELAY",
            "5 = VIRTUAL_LANDLORD_TO_TENANT_METER",
            "6 = ENERGY_METER",
            "7 = NON_SMART_METER",
            "8 = HEAT_PUMP",
            "9 = MICRO_INVERTER",
          ],
          device_provider: ["0 = BLUEMETERING", "18 = EXTERNAL"],
          device_medium: ["0 = ELECTRICITY", "1 = GAS", "2 = WATER", "3 = HEATING"],
          bluemetering: {
            metering_location_id: "string",
            bluemetering_api_key: "string",
            meter_pin: "string (Optional)",
          },
          external: {
            external_device_id: "string (Optional, kann benutzt werden für den Datenversand)",
            metering_location_id: "string (Optional)",
            serial_number: "string (Optional)",
          },
        },
      },
      responseBody: {
        device_id: "string",
      },
    },
    {
      method: "POST",
      shortDescription: "Messwerte speichern",
      description: "Speichert Messwerte für ein oder mehrere Geräte.",
      path: "/Device.DeviceService/StoreReadings",
      requestBody: {
        device_readings: [
          {
            external_device_id: "string",
            readings: [
              {
                meter_reading: {
                  obis_code: "string",
                  timestamp: "Unix timestamp in seconds",
                  value: "float",
                },
              },
            ],
          },
        ],
      },
      responseBody: {},
    },
    {
      method: "POST",
      shortDescription: "Geräte im Objekt",
      description: "Gibt alle Geräte in einem Objekt zurück.",
      path: "/Device.DeviceService/GetDevicesInSpace",
      requestBody: {
        space_id: "string",
      },
      responseBody: {
        devices: "Device[]",
      },
    },
    {
      method: "POST",
      shortDescription: "Gerät löschen",
      description: "Löscht ein Gerät entweder von einem Objekt oder komplett.",
      path: "/Device.DeviceService/DeleteDevice",
      requestBody: {
        device_id: "string",
        space_id: "string (optional, only required if delete_for_all is false)",
        delete_for_all: "boolean (whether device is deleted from space or for completely)",
      },
      responseBody: {},
    },
    {
      method: "POST",
      shortDescription: "Nutzer anfragen",
      description: "Gibt alle Nutzer in einem Objekt zurück",
      path: "/UserService.UserService/GetUsers",
      requestBody: {
        page: "int",
        page_size: "int",
        space_id: "string",
      },
      responseBody: {
        total_num_pages: "int",
        users: "User[]",
      },
    },
    {
      method: "POST",
      shortDescription: "Objekt löschen",
      description:
        "Löschen Sie ein Objekt anhand der ID. Die mit dem Objekt verbundenen Geräte werden ebenfalls gelöscht.",
      path: "/Space.SpaceService/DeleteSpace",
      requestBody: {
        space_id: "string",
      },
      responseBody: {},
    },
  ];
  showDoc: boolean = false;

  copyPathToClipboard(path: string, event: Event): void {
    navigator.clipboard.writeText(`${this.url}${path}`);
    this.showUrlCopiedSnackbar = true;
    event.stopPropagation();
  }
}
