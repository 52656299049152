
import { tariffServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  AddContractRequest,
  DeleteContractRequest,
  GetContractsRequest,
} from "zaehlerfreunde-central/tariff_service_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import SpaceSelect from "../spaces/SpaceSelect.vue";

interface Row {
  contractId: string;
  spaceId: string;
  spaceName: string;
  spaceAddress: string;
  startDate: string;
  customerId: string;
}

interface NewContract {
  spaceId?: string;
  startDate?: Date;
  customerId?: string;
  earliestCancelDate?: Date;
}

@Component({
  components: {
    SpaceSelect,
  },
})
export default class TariffContracts extends Vue {
  @Prop() tariffId: string;

  pageSize = 10;
  page = 1;
  pages = 0;

  showAddContractDialog = false;
  newContract: NewContract = {};
  addContractCall: RemoteCall<UserError> = initialized;

  showDeleteContractDialog = false;
  contractIdToDelete: string | null = null;
  deleteContractCall: RemoteCall<UserError> = initialized;

  contracts: RemoteData<UserError, Tariff.Contract[]> = initialized;

  headers = [
    { text: "Objekt", value: "spaceName" },
    { text: "Addresse", value: "spaceAddress" },
    { text: "Startdatum", value: "startDate" },
    { text: "Kundennummer", value: "customerId" },
    { text: "", value: "controls" },
  ];

  get addContractBtnDisabled(): boolean {
    return !this.newContract.spaceId || !this.newContract.startDate;
  }

  get rows(): Row[] {
    return (
      this.contracts.data?.map((c) => {
        const startDate = c.getStartDate();
        var startDateFormatted = "-";

        if (startDate) {
          startDateFormatted = new Date(startDate).toLocaleDateString();
        }

        return {
          contractId: c.getId(),
          spaceId: c.getSpaceId(),
          spaceAddress: c.getSpaceAddress(),
          spaceName: c.getSpaceName(),
          startDate: startDateFormatted,
          customerId: c.getCustomerId() || "-",
        };
      }) ?? []
    );
  }

  mounted(): void {
    this.getContracts();
  }

  handleRowClick(row: Row) {
    this.$router.push(`${paths.admin.spaces}/${row.spaceId}`);
  }

  showDeleteDialog(contractId: string, event: Event) {
    event.stopPropagation();
    this.contractIdToDelete = contractId;
    this.showDeleteContractDialog = true;
  }

  async getContracts(): Promise<void> {
    const pagination = new Pagination();
    pagination.setPage(this.page - 1);
    pagination.setPageSize(this.pageSize);

    const request = new GetContractsRequest();
    request.setTariffId(this.tariffId);
    request.setPagination(pagination);

    try {
      this.contracts = pending;
      const response = await tariffServiceClient.getContracts(request, {});
      this.contracts = success(response.getContractsList());
      this.pages = response.getPages();
    } catch (error) {
      this.contracts = failure(userErrorFrom(error));
    }
  }

  async addContract(): Promise<void> {
    const spaceId = this.newContract.spaceId;
    const startDate = this.newContract.startDate;
    const earliestCancelDate = this.newContract.earliestCancelDate;
    const customerId = this.newContract.customerId;

    if (spaceId && startDate) {
      const contract = new Tariff.Contract();
      contract.setSpaceId(spaceId);
      contract.setStartDate(startDate.toDateString());
      contract.setTariffId(this.tariffId);

      if (customerId) {
        contract.setCustomerId(customerId);
      }

      if (earliestCancelDate) {
        contract.setEarliestCancelDate(earliestCancelDate.toDateString());
      }

      const request = new AddContractRequest();
      request.setContract(contract);

      try {
        this.addContractCall = pending;
        await tariffServiceClient.addContract(request, {});
        this.addContractCall = success(void 0);
        this.showAddContractDialog = false;
        this.newContract = {};
        this.getContracts();
      } catch (error) {
        this.addContractCall = failure(userErrorFrom(error));
      }
    }
  }

  async deleteContract(): Promise<void> {
    const contractId = this.contractIdToDelete;

    if (contractId) {
      const request = new DeleteContractRequest();
      request.setContractId(contractId);

      try {
        this.deleteContractCall = pending;
        await tariffServiceClient.deleteContract(request, {});
        this.deleteContractCall = success(void 0);
        this.showDeleteContractDialog = false;
        this.getContracts();
      } catch (error) {
        this.deleteContractCall = failure(userErrorFrom(error));
      }
    }
  }
}
