
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ProsAndConsList extends Vue {
  @Prop({ default: () => [] })
  pros: string[];

  @Prop({ default: () => [] })
  cons: string[];
}
