// source: types/simulations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.MiniPVInstallation', null, global);
goog.exportSymbol('proto.types.MiniPVProductSimulation', null, global);
goog.exportSymbol('proto.types.MiniPVSimulationResult', null, global);
goog.exportSymbol('proto.types.Mounting', null, global);
goog.exportSymbol('proto.types.Orientation', null, global);
goog.exportSymbol('proto.types.Simulation', null, global);
goog.exportSymbol('proto.types.Slope', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Simulation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Simulation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Simulation.displayName = 'proto.types.Simulation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MiniPVSimulationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.MiniPVSimulationResult.repeatedFields_, null);
};
goog.inherits(proto.types.MiniPVSimulationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MiniPVSimulationResult.displayName = 'proto.types.MiniPVSimulationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MiniPVProductSimulation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MiniPVProductSimulation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MiniPVProductSimulation.displayName = 'proto.types.MiniPVProductSimulation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MiniPVInstallation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MiniPVInstallation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MiniPVInstallation.displayName = 'proto.types.MiniPVInstallation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Simulation.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Simulation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Simulation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Simulation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Simulation}
 */
proto.types.Simulation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Simulation;
  return proto.types.Simulation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Simulation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Simulation}
 */
proto.types.Simulation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Simulation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Simulation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Simulation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Simulation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Simulation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Simulation} returns this
 */
proto.types.Simulation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.types.Simulation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Simulation} returns this
 */
proto.types.Simulation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.types.Simulation.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Simulation} returns this
 */
proto.types.Simulation.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.MiniPVSimulationResult.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MiniPVSimulationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MiniPVSimulationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MiniPVSimulationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MiniPVSimulationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    slope: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mounting: jspb.Message.getFieldWithDefault(msg, 3, 0),
    availableWidth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    details: jspb.Message.getFieldWithDefault(msg, 5, ""),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.types.MiniPVProductSimulation.toObject, includeInstance),
    spaceId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MiniPVSimulationResult}
 */
proto.types.MiniPVSimulationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MiniPVSimulationResult;
  return proto.types.MiniPVSimulationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MiniPVSimulationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MiniPVSimulationResult}
 */
proto.types.MiniPVSimulationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Slope} */ (reader.readEnum());
      msg.setSlope(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Orientation} */ (reader.readEnum());
      msg.setOrientation(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Mounting} */ (reader.readEnum());
      msg.setMounting(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableWidth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    case 6:
      var value = new proto.types.MiniPVProductSimulation;
      reader.readMessage(value,proto.types.MiniPVProductSimulation.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MiniPVSimulationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MiniPVSimulationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MiniPVSimulationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MiniPVSimulationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlope();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMounting();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAvailableWidth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.types.MiniPVProductSimulation.serializeBinaryToWriter
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional Slope slope = 1;
 * @return {!proto.types.Slope}
 */
proto.types.MiniPVSimulationResult.prototype.getSlope = function() {
  return /** @type {!proto.types.Slope} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Slope} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.setSlope = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Orientation orientation = 2;
 * @return {!proto.types.Orientation}
 */
proto.types.MiniPVSimulationResult.prototype.getOrientation = function() {
  return /** @type {!proto.types.Orientation} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Orientation} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Mounting mounting = 3;
 * @return {!proto.types.Mounting}
 */
proto.types.MiniPVSimulationResult.prototype.getMounting = function() {
  return /** @type {!proto.types.Mounting} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Mounting} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.setMounting = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string available_width = 4;
 * @return {string}
 */
proto.types.MiniPVSimulationResult.prototype.getAvailableWidth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.setAvailableWidth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string details = 5;
 * @return {string}
 */
proto.types.MiniPVSimulationResult.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated MiniPVProductSimulation results = 6;
 * @return {!Array<!proto.types.MiniPVProductSimulation>}
 */
proto.types.MiniPVSimulationResult.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.types.MiniPVProductSimulation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.MiniPVProductSimulation, 6));
};


/**
 * @param {!Array<!proto.types.MiniPVProductSimulation>} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
*/
proto.types.MiniPVSimulationResult.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.types.MiniPVProductSimulation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.MiniPVProductSimulation}
 */
proto.types.MiniPVSimulationResult.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.types.MiniPVProductSimulation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional string space_id = 7;
 * @return {string}
 */
proto.types.MiniPVSimulationResult.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVSimulationResult} returns this
 */
proto.types.MiniPVSimulationResult.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MiniPVProductSimulation.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MiniPVProductSimulation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MiniPVProductSimulation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MiniPVProductSimulation.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    producedEnergy: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    usableEnergy: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    moneySavings: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    co2Savings: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    weight: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dimensions: jspb.Message.getFieldWithDefault(msg, 9, ""),
    peakPower: jspb.Message.getFieldWithDefault(msg, 10, ""),
    affiliateLink: jspb.Message.getFieldWithDefault(msg, 11, ""),
    shippingCosts: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MiniPVProductSimulation}
 */
proto.types.MiniPVProductSimulation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MiniPVProductSimulation;
  return proto.types.MiniPVProductSimulation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MiniPVProductSimulation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MiniPVProductSimulation}
 */
proto.types.MiniPVProductSimulation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProducedEnergy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUsableEnergy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoneySavings(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCo2Savings(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDimensions(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeakPower(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAffiliateLink(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShippingCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MiniPVProductSimulation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MiniPVProductSimulation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MiniPVProductSimulation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MiniPVProductSimulation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProducedEnergy();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUsableEnergy();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMoneySavings();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCo2Savings();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDimensions();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPeakPower();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAffiliateLink();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getShippingCosts();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.types.MiniPVProductSimulation.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier = 2;
 * @return {string}
 */
proto.types.MiniPVProductSimulation.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float produced_energy = 3;
 * @return {number}
 */
proto.types.MiniPVProductSimulation.prototype.getProducedEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setProducedEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float usable_energy = 4;
 * @return {number}
 */
proto.types.MiniPVProductSimulation.prototype.getUsableEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setUsableEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float money_savings = 5;
 * @return {number}
 */
proto.types.MiniPVProductSimulation.prototype.getMoneySavings = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setMoneySavings = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float co2_savings = 6;
 * @return {number}
 */
proto.types.MiniPVProductSimulation.prototype.getCo2Savings = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setCo2Savings = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float price = 7;
 * @return {number}
 */
proto.types.MiniPVProductSimulation.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string weight = 8;
 * @return {string}
 */
proto.types.MiniPVProductSimulation.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string dimensions = 9;
 * @return {string}
 */
proto.types.MiniPVProductSimulation.prototype.getDimensions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setDimensions = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string peak_power = 10;
 * @return {string}
 */
proto.types.MiniPVProductSimulation.prototype.getPeakPower = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setPeakPower = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string affiliate_link = 11;
 * @return {string}
 */
proto.types.MiniPVProductSimulation.prototype.getAffiliateLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setAffiliateLink = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float shipping_costs = 12;
 * @return {number}
 */
proto.types.MiniPVProductSimulation.prototype.getShippingCosts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVProductSimulation} returns this
 */
proto.types.MiniPVProductSimulation.prototype.setShippingCosts = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MiniPVInstallation.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MiniPVInstallation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MiniPVInstallation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MiniPVInstallation.toObject = function(includeInstance, msg) {
  var f, obj = {
    mounting: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    slope: jspb.Message.getFieldWithDefault(msg, 3, 0),
    availableWidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    installationAbove4mHeight: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MiniPVInstallation}
 */
proto.types.MiniPVInstallation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MiniPVInstallation;
  return proto.types.MiniPVInstallation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MiniPVInstallation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MiniPVInstallation}
 */
proto.types.MiniPVInstallation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Mounting} */ (reader.readEnum());
      msg.setMounting(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Orientation} */ (reader.readEnum());
      msg.setOrientation(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Slope} */ (reader.readEnum());
      msg.setSlope(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailableWidth(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInstallationAbove4mHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MiniPVInstallation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MiniPVInstallation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MiniPVInstallation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MiniPVInstallation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMounting();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSlope();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAvailableWidth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getInstallationAbove4mHeight();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Mounting mounting = 1;
 * @return {!proto.types.Mounting}
 */
proto.types.MiniPVInstallation.prototype.getMounting = function() {
  return /** @type {!proto.types.Mounting} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Mounting} value
 * @return {!proto.types.MiniPVInstallation} returns this
 */
proto.types.MiniPVInstallation.prototype.setMounting = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Orientation orientation = 2;
 * @return {!proto.types.Orientation}
 */
proto.types.MiniPVInstallation.prototype.getOrientation = function() {
  return /** @type {!proto.types.Orientation} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Orientation} value
 * @return {!proto.types.MiniPVInstallation} returns this
 */
proto.types.MiniPVInstallation.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Slope slope = 3;
 * @return {!proto.types.Slope}
 */
proto.types.MiniPVInstallation.prototype.getSlope = function() {
  return /** @type {!proto.types.Slope} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Slope} value
 * @return {!proto.types.MiniPVInstallation} returns this
 */
proto.types.MiniPVInstallation.prototype.setSlope = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 available_width = 4;
 * @return {number}
 */
proto.types.MiniPVInstallation.prototype.getAvailableWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.MiniPVInstallation} returns this
 */
proto.types.MiniPVInstallation.prototype.setAvailableWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool installation_above4M_height = 5;
 * @return {boolean}
 */
proto.types.MiniPVInstallation.prototype.getInstallationAbove4mHeight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.MiniPVInstallation} returns this
 */
proto.types.MiniPVInstallation.prototype.setInstallationAbove4mHeight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.types.Mounting = {
  BALCONY: 0,
  GARDEN: 1,
  ALL: 2,
  WALL: 3,
  ROOF: 4
};

/**
 * @enum {number}
 */
proto.types.Orientation = {
  NORTH: 0,
  NORTH_EAST: 1,
  EAST: 2,
  SOUTH_EAST: 3,
  SOUTH: 4,
  SOUTH_WEST: 5,
  WEST: 6,
  NORTH_WEST: 7
};

/**
 * @enum {number}
 */
proto.types.Slope = {
  SLOPE_0: 0,
  SLOPE_15: 1,
  SLOPE_30: 2,
  SLOPE_45: 3,
  SLOPE_60: 4,
  SLOPE_75: 5,
  SLOPE_90: 6
};

goog.object.extend(exports, proto.types);
