
import { spaceServiceClient } from "@/config/service-clients";
import { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { GetSpaceKPIsRequest } from "zaehlerfreunde-central/space_service_pb";
import { KPI } from "zaehlerfreunde-proto-types/kpis_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import Kpi from "@/components/kpi/Kpi.vue";

@Component({
  components: {
    Kpi,
  },
})
export default class SpaceKpis extends Vue {
  @spacesModule.State selectedSpace: Space | null;

  kpis: RemoteData<UserError, KPI[]> = initialized;

  async mounted(): Promise<void> {
    const spaceId = this.selectedSpace?.getId();

    if (spaceId) {
      const request = new GetSpaceKPIsRequest();
      request.setSpaceId(spaceId);

      try {
        this.kpis = pending;
        const response = await spaceServiceClient.getSpaceKPIs(request, {});
        this.kpis = success(response.getKpisList());
      } catch (error) {
        this.kpis = failure(userErrorFrom(error));
      }
    }
  }
}
