
import { Component, Vue } from "vue-property-decorator";
import Overview from "./Overview.vue";
import KnownFrom from "../../b2c/landing-page/KnownFrom.vue";
import Newsletter from "@/components/newsletter/Newsletter.vue";
import SuccessStories from "./SuccessStories.vue";
import ExistingCustomers from "./ExistingCustomers.vue";
import CustomerGroups from "./CustomerGroups.vue";
import HeroHeader from "@/components/hero-header/HeroHeader.vue";
import BookMeeting from "@/components/book-meeting/BookMeeting.vue";
import TopBanner from "./TopBanner.vue";
import WebinarSignUpDialog from "./WebinarSignUpDialog.vue";

@Component({
  components: {
    HeroHeader,
    Overview,
    KnownFrom,
    Newsletter,
    SuccessStories,
    ExistingCustomers,
    CustomerGroups,
    BookMeeting,
    TopBanner,
    WebinarSignUpDialog,
  },
})
export default class WhiteLabelLandingPage extends Vue {
  showBookMeetingDialog: boolean = false;
}
