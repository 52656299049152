
import { RemoteData, initialized } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { paths } from "@/router/routes";
import { TableControl } from "../spaces-table/SpacesTable.vue";
import {
  LandlordToTenantSpaceType,
  LandlordToTenantProjectSpace,
} from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";

export interface ProjectSpaceTable {
  id: string;
  name: string;
  status: string;
  type: string;
  deviceId: string;
  deviceName: string;
}

@Component({})
export default class ProjectSpacesTable extends Vue {
  @Prop({ default: initialized }) spacesList: RemoteData<UserError, LandlordToTenantProjectSpace[]>;
  @Prop({ default: null }) itemControls: TableControl[] | null;
  @Prop({ default: null }) headerControls: TableControl[] | null;

  itemsPerPage = 10;
  paths = paths;

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  get headers() {
    return [
      { text: "Name", value: "name" },
      { text: "Zähler", value: "deviceName" },
      { text: "Status", value: "status" },
      { text: "Objektart", value: "type" },
      { text: "", value: "controls" },
    ];
  }

  get spaces(): ProjectSpaceTable[] {
    let spaces: ProjectSpaceTable[] = [];
    this.spacesList.list.forEach((d) => {
      spaces.push({
        id: d.getId() ?? "",
        name: d.getName() ?? "",
        deviceName: d.getMainDevice()?.getName() ?? "",
        status: this.getStatus(d.getMainDevice()?.getStatus() ?? Device.Status.FAILED),
        type: this.getSpaceType(d.getSpaceType()),
        deviceId: d.getMainDevice()?.getId() ?? "",
      });
    });
    return spaces;
  }

  getStatus(status: Device.Status): string {
    switch (status) {
      case Device.Status.CONNECTED:
        return "Verbunden";
      case Device.Status.CONNECTING:
        return "Verbindung wird aufgebaut";
      case Device.Status.FAILED:
        return "Verbindung fehlgeschlagen";
      default:
        return "Unknown Status";
    }
  }

  getSpaceType(meter: LandlordToTenantSpaceType): string {
    switch (meter) {
      case LandlordToTenantSpaceType.BUILDING:
        return "Gebäude";
      case LandlordToTenantSpaceType.FLAT:
        return "Wohnung";
      default:
        return "Unknown Space Type";
    }
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }
  onItemControlsClicked(meter: ProjectSpaceTable, event: string) {
    this.$emit(event, meter);
  }
}
