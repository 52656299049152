import { User } from "@/auth/User";
import { UserError, userErrorFrom, unknownError } from "@/types/user-error";
import axios, { AxiosRequestConfig } from "axios";
import { namespace } from "vuex-class";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";

import { failure, initialized, pending, RemoteCall, RemoteData, success, successOptional } from "../utils/remote-data";
import {
  DeleteAccountRequest,
  GetEmailVerificationStatusRequest,
  ConfirmEmailVerifiedRequest,
  ResendVerificationEmailRequest,
  GetRegistrationStatusRequest,
  AddNameAndAcceptTermsRequest,
  RegistrationStatus,
  ChangeUserNameRequest,
  GetUserRequest,
  DownloadInvoiceRequest,
  DownloadInvoiceResponse,
  GetInvoiceHTMLRequest,
  CreatePartnerAndAcceptTermsRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { User as UserDetails } from "zaehlerfreunde-proto-types/user_pb";
import { getAuthService } from "@/auth";
import { userServiceClient } from "@/config/service-clients";
import { getVisitorType, VisitorType } from "@/observables/visitor-type-observables";
import { getEnvironment } from "@/config/env";
import { partnerAuthConfig } from "@/config/auth-config";
import { Invoice } from "zaehlerfreunde-proto-types/invoice_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "account",
})
class AccountModule extends VuexModule {
  user: RemoteData<UserError, User> = initialized;
  userDetails: RemoteData<UserError, UserDetails> = initialized;
  passwordUpdated: RemoteData<UserError, boolean> = initialized;
  paymentActive: RemoteData<UserError, boolean> = initialized;
  emailVerified: RemoteData<UserError, boolean> = initialized;
  verificationEmailSent: RemoteData<UserError, boolean> = initialized;

  registrationStatus: RemoteData<UserError, RegistrationStatus> = initialized;
  addNameAndAcceptTermsCall: RemoteCall<UserError> = initialized;
  confirmEmailVerifiedCall: RemoteCall<UserError> = initialized;
  deleteAccountCall: RemoteCall<UserError> = initialized;
  changeUserNameCall: RemoteCall<UserError> = initialized;
  createPartnerAndAcceptTermsCall: RemoteCall<UserError> = initialized;

  downloadInvoiceResponse: RemoteData<UserError, DownloadInvoiceResponse> = initialized;
  invoiceHTML: RemoteData<UserError, string> = initialized;

  get isAuth0Account(): boolean {
    return this.user.data?.provider === "auth0";
  }

  @Action
  async deleteAccount() {
    const request = new DeleteAccountRequest();
    try {
      this.setDeleteAccountCall(pending);
      await userServiceClient.deleteAccount(request, {});
      this.setDeleteAccountCall(success(void 0));
      const authService = getAuthService();
      authService.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (error) {
      this.setDeleteAccountCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getUser() {
    this.setUser(pending);
    const authService = getAuthService();

    try {
      const user = await authService.getUser();

      if (user) {
        this.setUser(success(user));
      } else {
        this.setUser(failure(userErrorFrom("Beim Laden des Users ist ein Fehler aufgetreten")));
      }
    } catch (error) {
      this.setUser(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getUserDetails() {
    try {
      this.setUserDetails(pending);

      const request = new GetUserRequest();
      const response = await userServiceClient.getUser(request, {});

      this.setUserDetails(successOptional(response.getUser()));
    } catch (error) {
      this.setUserDetails(failure(userErrorFrom(error)));
    }
  }

  @Action
  async setNewPassword() {
    const authService = getAuthService();
    const user = await authService.getUser();

    if (user) {
      const environment = getEnvironment();
      const authConfig = await partnerAuthConfig;

      const options: AxiosRequestConfig = {
        method: "POST",
        url: environment.domain + "/dbconnections/change_password",
        headers: { "content-type": "application/json" },
        data: {
          client_id: authConfig.getAuth0ClientId(),
          email: user.email,
          connection: "Username-Password-Authentication",
        },
      };

      try {
        axios.request(options);
        this.setPasswordUpdated(success(true));
      } catch (error) {
        this.setPasswordUpdated(failure(userErrorFrom(error)));
      }
    } else {
      this.setPasswordUpdated(failure(unknownError));
    }
  }

  @Action
  async changeUserName(name: string) {
    this.setChangeUserNameCall(pending);
    const request = new ChangeUserNameRequest();
    request.setNewName(name);

    try {
      await userServiceClient.changeUserName(request, {});
      this.setChangeUserNameCall(success(void 0));

      this.getUser();
    } catch (error) {
      this.setChangeUserNameCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async checkEmailVerificationStatus() {
    this.setEmailVerified(pending);
    const request = new GetEmailVerificationStatusRequest();
    try {
      const response = await userServiceClient.getEmailVerificationStatus(request, {});
      if (response.getIsVerified()) {
        this.setEmailVerified(success(true));
        this.confirmEmailVerified();
      } else {
        this.setEmailVerified(success(false));
      }
    } catch (error) {
      this.setEmailVerified(failure(userErrorFrom("Beim Verifizieren der E-Mail ist ein Fehler aufgetreten.")));
    }
  }

  @Action
  async confirmEmailVerified() {
    this.setConfirmEmailVerifiedCall(pending);
    const request = new ConfirmEmailVerifiedRequest();

    try {
      const response = await userServiceClient.confirmEmailVerified(request, {});
      const registrationStatus = response.getRegistrationStatus();

      if (registrationStatus) {
        this.setConfirmEmailVerifiedCall(success(void 0));
        this.setRegistrationStatus(success(registrationStatus));
      } else {
        this.setConfirmEmailVerifiedCall(
          failure(userErrorFrom("Beim Verifizieren der E-Mail ist ein Fehler aufgetreten."))
        );
      }
    } catch (error) {
      this.setConfirmEmailVerifiedCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async resendVerificationEmail() {
    this.setVerificationEmailSent(pending);
    const request = new ResendVerificationEmailRequest();
    try {
      const response = await userServiceClient.resendVerificationEmail(request, {});
      this.setVerificationEmailSent(success(response.getSucessfullySent()));
    } catch (error) {
      this.setVerificationEmailSent(failure(userErrorFrom("Beim Senden der E-Mail ist ein Fehler aufgetreten.")));
    }
  }

  @Action
  async getRegistrationStatus(partnerId: string | undefined) {
    this.setRegistrationStatus(pending);
    const request = new GetRegistrationStatusRequest();

    if (partnerId) {
      request.setPartnerId(partnerId);
    }

    const visitorType = getVisitorType();

    if (visitorType) {
      request.setIsB2bUser(visitorType == VisitorType.CommercialCustomer);
    }

    try {
      const response = await userServiceClient.getRegistrationStatus(request, {});
      const registrationStatus = response.getRegistrationStatus();

      if (registrationStatus) {
        this.setRegistrationStatus(success(registrationStatus));
      } else {
        this.setRegistrationStatus(failure(userErrorFrom("Beim Laden der Registrierung ist ein Fehler aufgetreten.")));
      }
    } catch (error) {
      this.setRegistrationStatus(failure(userErrorFrom(error)));
    }
  }

  @Action
  async addNameAndAcceptTerms(name: string) {
    this.setAddNameAndAcceptTermsCall(pending);
    const request = new AddNameAndAcceptTermsRequest();
    request.setName(name);

    try {
      const response = await userServiceClient.addNameAndAcceptTerms(request, {});
      const registrationStatus = response.getRegistrationStatus();

      if (registrationStatus) {
        this.setAddNameAndAcceptTermsCall(success(void 0));
        this.setRegistrationStatus(success(registrationStatus));
      } else {
        this.setAddNameAndAcceptTermsCall(
          failure(userErrorFrom("Beim Speichern des Namens ist ein Fehler aufgetreten."))
        );
      }
    } catch (error) {
      this.setAddNameAndAcceptTermsCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async createPartnerAndAcceptTerms(names: { company: string; user: string }) {
    this.setCreatePartnerAndAcceptTermsCall(pending);
    const request = new CreatePartnerAndAcceptTermsRequest();
    request.setCompanyName(names.company);
    request.setUserName(names.user);

    try {
      const response = await userServiceClient.createPartnerAndAcceptTerms(request, {});
      const registrationStatus = response.getRegistrationStatus();

      if (registrationStatus) {
        this.setCreatePartnerAndAcceptTermsCall(success(void 0));
        this.setRegistrationStatus(success(registrationStatus));
      } else {
        this.setCreatePartnerAndAcceptTermsCall(
          failure(userErrorFrom("Beim Speichern des Namens ist ein Fehler aufgetreten."))
        );
      }
    } catch (error) {
      this.setCreatePartnerAndAcceptTermsCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async downloadInvoice(invoiceId: string | undefined) {
    this.setDownloadInvoiceResponse(pending);
    const request = new DownloadInvoiceRequest();

    if (invoiceId) {
      request.setInvoiceId(invoiceId);
    }

    try {
      const response = await userServiceClient.downloadInvoice(request, {});
      if (response.getFileBytes()?.length > 0) {
        this.setDownloadInvoiceResponse(success(response));
      } else {
        this.setDownloadInvoiceResponse(failure(userErrorFrom("Die Rechnung konnte nicht heruntergeladen werden")));
      }
    } catch (error) {
      this.setDownloadInvoiceResponse(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getInvoiceHTML(invoiceId: string | undefined) {
    this.setInvoiceHTML(pending);
    const request = new GetInvoiceHTMLRequest();

    if (invoiceId) {
      request.setInvoiceId(invoiceId);
    }

    try {
      const response = await userServiceClient.getInvoiceHTML(request, {});
      this.setInvoiceHTML(success(response.getHtml()));
    } catch (error) {
      this.setInvoiceHTML(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setUser(user: RemoteData<UserError, User>) {
    this.user = user;
  }

  @Mutation
  setPasswordUpdated(passwordUpdated: RemoteData<UserError, boolean>) {
    this.passwordUpdated = passwordUpdated;
  }

  @Mutation
  setPaymentActive(paymentActive: RemoteData<UserError, boolean>) {
    this.paymentActive = paymentActive;
  }

  @Mutation
  setEmailVerified(emailVerified: RemoteData<UserError, boolean>) {
    this.emailVerified = emailVerified;
  }

  @Mutation
  setRegistrationStatus(registrationStatus: RemoteData<UserError, RegistrationStatus>) {
    this.registrationStatus = registrationStatus;
  }

  @Mutation
  setDownloadInvoiceResponse(dowResp: RemoteData<UserError, DownloadInvoiceResponse>) {
    this.downloadInvoiceResponse = dowResp;
  }

  @Mutation
  setInvoiceHTML(invHtml: RemoteData<UserError, string>) {
    this.invoiceHTML = invHtml;
  }

  @Mutation
  setAddNameAndAcceptTermsCall(addNameAndAcceptTermsCall: RemoteCall<UserError>) {
    this.addNameAndAcceptTermsCall = addNameAndAcceptTermsCall;
  }

  @Mutation
  setConfirmEmailVerifiedCall(confirmEmailVerifiedCall: RemoteCall<UserError>) {
    this.confirmEmailVerifiedCall = confirmEmailVerifiedCall;
  }

  @Mutation
  setDeleteAccountCall(deleteAccountCall: RemoteCall<UserError>) {
    this.deleteAccountCall = deleteAccountCall;
  }

  @Mutation
  setVerificationEmailSent(emailSent: RemoteData<UserError, boolean>) {
    this.verificationEmailSent = emailSent;
  }

  @Mutation
  setChangeUserNameCall(changeUserNameCall: RemoteCall<UserError>) {
    this.changeUserNameCall = changeUserNameCall;
  }

  @Mutation
  setUserDetails(v: RemoteData<UserError, UserDetails>) {
    this.userDetails = v;
  }

  @Mutation
  setCreatePartnerAndAcceptTermsCall(createPartnerAndAcceptTermsCall: RemoteCall<UserError>) {
    this.createPartnerAndAcceptTermsCall = createPartnerAndAcceptTermsCall;
  }
}

export const accountModule = namespace("account");
export default getModule(AccountModule);
