
import { Component, Vue } from "vue-property-decorator";
import Features from "./Features.vue";
import KnownFrom from "./KnownFrom.vue";
import HeroHeader from "../../../../components/hero-header/HeroHeader.vue";
import Newsletter from "@/components/newsletter/Newsletter.vue";
import SupportedDevices from "./SupportedDevices.vue";
import { getAuthService } from "@/auth";

@Component({
  components: {
    HeroHeader,
    Features,
    KnownFrom,
    Newsletter,
    SupportedDevices,
  },
})
export default class B2CLandingPage extends Vue {
  onRegisterClicked(): void {
    this.$gtag.event("registration-clicked", {
      event_label: "Registration clicked",
      event_category: "user-action",
    });

    this.$gtag.event("conversion", {
      send_to: "AW-10831568791/--0CCJP1sI0YEJe_8qwo",
      value: 10,
      currency: "USD",
    });

    getAuthService().loginWithRedirect({ authorizationParams: { screen_hint: "signup" } });
  }
}
