
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Button extends Vue {
  onClick(): void {
    const buttonText = (this.$slots.default ?? [{}])[0].text;

    if (buttonText) {
      this.$gtag.event(`${buttonText.trim().toLowerCase().replaceAll(" ", "-")}-button-click`, {
        event_category: "button-click",
        event_label: `${(this.$slots.default ?? [{ text: "" }])[0].text} clicked`,
      });
    }
  }
}
