
import { RemoteDataVue } from "@/store/utils/remote-data";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class FunnelStep extends RemoteDataVue {
  @Prop({ default: null }) title: string | null;
  @Prop({ default: null }) explanation: string | null;
  @Prop({ default: false }) scrollable: boolean;
}
