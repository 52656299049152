
import { deviceServiceClient } from "@/config/service-clients";
import devices, { devicesModule } from "@/store/modules/devices";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  DeleteViessmannClientInfoRequest,
  RegisterDeviceRequest,
  ViessmannDevice,
} from "zaehlerfreunde-central/device_service_pb";
import { GetAvailableViessmannDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { Option } from "@/components/core/funnel/OptionSelection.vue";
import { DeviceRegistration, ViessmannRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import ResetViessmannClientDialog from "./ResetViessmannClientDialog.vue";
import { StatusCode } from "grpc-web";
import { paths } from "@/router/routes";

@Component({
  components: { ResetViessmannClientDialog },
})
export default class ViessmannAvailableDevicesPage extends Vue {
  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;
  availableViessmannDevices: RemoteData<UserError, ViessmannDevice[]> = initialized;
  deleteCall: RemoteCall<UserError> = initialized;
  devicesOptions: Option[] = [];
  showDialog: boolean = false;
  isAdminAction: boolean = false;

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.isAdminAction ? this.$router.push(paths.admin.devices) : this.$router.push(paths.platform.devices);
    }
  }

  created(): void {
    const code = this.$route.query["code"] as string;
    this.getAvailableViessmannDevices(code);
  }

  async getAvailableViessmannDevices(code: string): Promise<void> {
    try {
      this.availableViessmannDevices = pending;
      const request = new GetAvailableViessmannDevicesRequest();
      request.setAuthenticationCode(code);

      const response = await deviceServiceClient.getAvailableViessmannDevices(request, {});
      this.availableViessmannDevices = success(response.getAvailableViessmannDevicesList());

      this.devicesOptions = this.availableViessmannDevices.list.map((device: ViessmannDevice) => {
        var option: Option = {
          title: device.getModelId() + " - " + device.getDeviceType(),
          subtitle: device.getIsSupported() ? device.getBoilerSerial() : "nicht unterstützt",
          value: device,
          disabled: !device.getIsSupported(),
          image: device.getIsSupported() ? device.getImageUrl() : "",
          icon: !device.getIsSupported() ? "mdi-cancel" : "",
        };
        return option;
      });
    } catch (error) {
      this.availableViessmannDevices = failure(userErrorFrom(error));
    }
  }

  onViessmannDeviceSelected(viessmannDeviceOption: Option): void {
    const viessmannDevice = viessmannDeviceOption.value as ViessmannDevice;
    if (viessmannDevice.getIsSupported()) {
      this.registerViessmannDevice(viessmannDevice);
    }
  }

  registerViessmannDevice(device: ViessmannDevice): void {
    const stateJSON = this.$route.query["state"] as string;
    const state = JSON.parse(stateJSON);
    this.isAdminAction = state.isAdminAction;
    const registration = new DeviceRegistration();
    registration.setDeviceName(state.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(Device.Type.HEAT_PUMP);
    registration.setDeviceProvider(Device.Provider.VIESSMANN);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(state.spaceId);
    spaceMapping.setIsMainDevice(state.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new ViessmannRegistrationDetails();
    details.setViessmannDeviceId(device.getViessmanDeviceId());
    details.setGatewaySerial(device.getGatewaySerial());
    details.setInstallationId(device.getInstallationId());
    details.setBoilerSerial(device.getBoilerSerial());

    registration.setViessmann(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    devices.registerDevice(registration);
  }

  onClose() {
    this.$router.push(paths.platform.devices);
  }
}
