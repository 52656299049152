
import { deviceServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetMetiundoMeteringPointsRequest, RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  DeviceRegistration,
  MetiundoLoginDetails,
  MetiundoMeteringPoint,
  MetiundoRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { Address, Country } from "zaehlerfreunde-proto-types/space_pb";
import MeteringPointsTable from "./MeteringPointsTable.vue";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component({
  components: {
    MeteringPointsTable,
  },
})
export default class MetiundoImportDialog extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  name: string = "";
  address = {
    addressLineOne: "",
    zipCode: "",
    city: "",
  };
  email: string = "";
  password: string = "";
  acceptedTerms: boolean = false;
  step = 1;

  pvMeteringPoint: string | null = null;
  generalMeteringPoint: string | null = null;
  tenantMeteringPoints: string[] = [];

  meteringPoints: RemoteData<UserError, MetiundoMeteringPoint[]> = initialized;
  importProjectCall: RemoteCall<UserError> = initialized;

  get isProjectDetailsValid(): boolean {
    return !!this.name && !!this.address.addressLineOne && !!this.address.zipCode && !!this.address.city;
  }

  @Watch("pvMeteringPoint")
  onPVMeteringPointSelected(): void {
    if (this.pvMeteringPoint) {
      this.step += 1;
    }
  }

  @Watch("generalMeteringPoint")
  onGeneralMeteringPointSelected(): void {
    if (this.generalMeteringPoint) {
      this.step += 1;
    }
  }

  async loadMeteringPoints(): Promise<void> {
    try {
      this.meteringPoints = pending;

      const request = new GetMetiundoMeteringPointsRequest();
      request.setEmail(this.email);
      request.setPassword(this.password);

      const response = await deviceServiceClient.getMetiundoMeteringPoints(request, {});
      const meteringPoints = response.getMeteringPointsList();
      this.meteringPoints = success(meteringPoints);
      this.step += 1;
    } catch (error) {
      this.meteringPoints = failure(userErrorFrom(error));
    }
  }

  async importProject(): Promise<void> {
    try {
      this.importProjectCall = pending;

      const address = new Address();
      address.setAddressLineOne(this.address.addressLineOne);
      address.setPostcode(this.address.zipCode);
      address.setCity(this.address.city);
      address.setCountry(new Country().setId("DEU"));

      const loginDetails = new MetiundoLoginDetails();
      loginDetails.setEmail(this.email);
      loginDetails.setPassword(this.password);

      const details = new MetiundoRegistrationDetails();
      details.setLoginDetails(loginDetails);
      details.setProjectName(this.name);
      details.setAddress(address);
      details.setPvMeteringPointId(this.pvMeteringPoint ?? "");
      details.setBuildingMeteringPointId(this.generalMeteringPoint ?? "");
      details.setTenantMeteringPointIdsList(this.tenantMeteringPoints);

      const registration = new DeviceRegistration();
      registration.setMetiundo(details);
      registration.setDeviceProvider(Device.Provider.METIUNDO);

      if (this.selectedChildPartner.data) registration.setPartnerId(this.selectedChildPartner.data.getId());

      const request = new RegisterDeviceRequest();
      request.setRegistration(registration);

      await deviceServiceClient.registerDevice(request, {});

      this.importProjectCall = success(void 0);
      this.$emit("input", false);
    } catch (error) {
      this.importProjectCall = failure(userErrorFrom(error));
    }
  }
}
