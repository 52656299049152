
import { Vue, Component } from "vue-property-decorator";

interface Customer {
  url: string;
  logo: string;
}

@Component
export default class ExistingCustomers extends Vue {
  customers: Customer[] = [
    {
      url: "https://www.bluemetering.de",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/bluemetering.webp",
    },
    {
      url: "https://die-energielandwerker.de",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/energielandwerker.png",
    },
    {
      url: "https://www.wattline.de",
      logo: "https://www.kreishandwerkerschaft-freiburg.de/fileadmin/_processed_/5/b/csm_wattlein_1_613a6a0bc1.jpg",
    },
    {
      url: "https://e3-energie.org",
      logo: "https://e3-energie.org/wp-content/uploads/2020/12/logo-e3-1.gif",
    },
    {
      url: "https://www.stadtwerke-herne.de",
      logo: "https://www.stadtwerke-herne.de/stadtwerke-herne_logo.png",
    },
    {
      url: "https://zaehlerhelden.de/",
      logo: "https://zaehlerhelden.de/wp-content/uploads/2022/08/Logo_Zahlerhelden.png",
    },
    {
      url: "https://westbridge-advisory.com/",
      logo: "https://westbridge-advisory.com/media/site/0ee9a68b54-1688120849/full.bold.horizontal.svg",
    },
    {
      url: "https://rohrer-firmengruppe.de/hausverwaltung.html",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/rohrer.svg",
    },

    {
      url: "https://www.mako365.com/",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/mako365.webp",
    },
    {
      url: "https://spotmyenergy.com/",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/spotmyenergy.svg",
    },
    {
      url: "https://imovis.de/",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/imovis.png",
    },
    {
      url: "https://consolinno.de/",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/consolinno.png",
    },
    {
      url: "https://www.netzservice-swka.de/",
      logo: "https://cdn.zaehlerfreunde.com/white-label-logos/swkn.png",
    },
  ];
}
