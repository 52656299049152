
import { Component, Vue, Watch } from "vue-property-decorator";

interface Feature {
  title: string;
  subtitle: string;
  text: string;
  video: string;
  videoLength: number;
}

@Component
export default class Features extends Vue {
  features = [
    {
      title: this.$t("landingPage.b2c.features.0.title").toString(),
      subtitle: this.$t("landingPage.b2c.features.0.subtitle").toString(),
      text: this.$t("landingPage.b2c.features.0.text").toString(),
      video: "screenvideos/dashboard",
      videoLength: 28,
    },
    {
      title: this.$t("landingPage.b2c.features.1.title").toString(),
      subtitle: this.$t("landingPage.b2c.features.1.subtitle").toString(),
      text: this.$t("landingPage.b2c.features.1.text").toString(),
      video: "screenvideos/automations",
      videoLength: 46,
    },
    {
      title: this.$t("landingPage.b2c.features.2.title").toString(),
      subtitle: this.$t("landingPage.b2c.features.2.subtitle").toString(),
      text: this.$t("landingPage.b2c.features.2.text").toString(),
      video: "screenvideos/tarifwechsel",
      videoLength: 19,
    },
    {
      title: this.$t("landingPage.b2c.features.3.title").toString(),
      subtitle: this.$t("landingPage.b2c.features.3.subtitle").toString(),
      text: this.$t("landingPage.b2c.features.3.text").toString(),
      video: "screenvideos/devices",
      videoLength: 33,
    },
  ];

  activeTimeout: number;
  selectedFeatureIndex = 0;

  get selectedFeature(): Feature {
    return this.features[this.selectedFeatureIndex];
  }

  mounted(): void {
    this.onSelectedFeatureChanged();
  }

  @Watch("selectedFeatureIndex")
  onSelectedFeatureChanged(): void {
    var video = document.getElementById("video") as any;
    video.load();
    video.play();

    clearTimeout(this.activeTimeout);
    this.activeTimeout = setTimeout(() => {
      this.selectedFeatureIndex = (this.selectedFeatureIndex + 1) % this.features.length;
    }, this.selectedFeature.videoLength * 1000);
  }
}
