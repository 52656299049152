
import { initialized, RemoteData, RemoteCall, failure, success } from "@/store/utils/remote-data";
import { advisoryServiceClient, uiServiceClient } from "@/config/service-clients";
import { GetAutomationWidgetDataRequest } from "zaehlerfreunde-central/ui_service_pb";
import { GetDispatchedActionsRequest, RetryDispatchedActionRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { DispatchedAction, DispatchedActionStatus } from "zaehlerfreunde-proto-types/automations_pb";
import { spacesModule } from "@/store/modules/spaces";
import DispatchedActionsTable from "../../automations/DispatchedActionsTable.vue";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import moment from "moment";

export interface DispatchedActionOverviewRow {
  id: string;
  dispatchedTime: string;
  description: string;
  isFailed: boolean;
}

@Component({
  components: {
    DispatchedActionsTable,
  },
})
export default class AutomationOverview extends Vue {
  @spacesModule.Getter selectedSpaceId: string;

  dispatchedActionStatus = DispatchedActionStatus;

  retryActionCall: RemoteCall<UserError> = initialized;
  dispatchedActions: RemoteData<UserError, DispatchedAction[]> = initialized;
  automationsCount: RemoteData<UserError, number> = initialized;
  actionsFailedCount: number = 0;
  recentActionsCount: number = 0;

  mounted() {
    this.loadData();
  }

  async loadData(): Promise<void> {
    const request = new GetAutomationWidgetDataRequest();
    request.setSpaceId(this.selectedSpaceId);

    try {
      let result = await uiServiceClient.getAutomationWidgetData(request, {});
      this.automationsCount = success(result.getActiveAutomationsCount());
      this.dispatchedActions = success(result.getDispatchedActionsList());
      this.recentActionsCount = result.getRecentActionsCount();
      this.actionsFailedCount = result.getRecentFailedActionsCount();
    } catch (error) {
      this.automationsCount = failure(userErrorFrom(error));
    }
  }

  headers: { value: string; align?: string; sortable?: boolean; cellClass?: string }[] = [
    { value: "dispatchedTime", sortable: false, cellClass: "pl-2 pr-0" },
    { value: "description", sortable: false, cellClass: "px-2" },
    { value: "controls", align: "right", sortable: false, cellClass: "pl-0 pr-2" },
  ];

  get dispatchedActionsRows(): DispatchedActionOverviewRow[] {
    return this.dispatchedActions.list.map((d: DispatchedAction) => {
      const dispatchedAt = moment.unix(d.getDispatchedTime()).locale("de").fromNow();
      return {
        id: d.getId(),
        dispatchedTime: dispatchedAt,
        description: d.getActionDescription(),
        isFailed: d.getStatus() === DispatchedActionStatus.FAILED,
      };
    });
  }

  async onRetryActionClicked(actionId: string): Promise<void> {
    const request = new RetryDispatchedActionRequest();
    request.setDispatchedActionId(actionId);
    try {
      await advisoryServiceClient.retryDispatchedAction(request, {});
      this.retryActionCall = success(void 0);
    } catch (error) {
      this.retryActionCall = failure(userErrorFrom(error));
    }
  }
}
