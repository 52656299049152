
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient, uiServiceClient } from "@/config/service-clients";
import { dateToDateTime, timeStampToDateTime } from "@/utils/proto-utils";
import spaces from "@/store/modules/spaces";
import {
  DeviceReadingsTableRequest,
  GetTabularDataRequest,
  GetTabularDataResponse,
} from "zaehlerfreunde-central/ui_service_pb";
import TabularChart from "@/components/charts/TabularChart.vue";
import { Unit } from "zaehlerfreunde-proto-types/device_reading_pb";
import { GetDeviceReadingStatsRequest } from "zaehlerfreunde-central/device_service_pb";

export interface TabularChartRowData {
  label: string;
  formattedDate: string;
  timestamp: number;
  value: number;
  unit: Unit;
}

@Component({
  components: {
    TabularChart,
  },
})
export default class LatestManualReadings extends Vue {
  tabularData: RemoteData<UserError, GetTabularDataResponse> = initialized;
  @Prop({ default: null }) chosenDevice: Device;
  @Prop({ default: false }) isUpdate: boolean;
  @Prop() updateCall: RemoteCall<UserError>;

  measurementDate: string = "";
  consumptionValue: string = "";
  ingestionValue: string = "";
  consumptionLabel: string = "";
  ingestionLabel: string = "";
  hasIngestion: boolean = false;

  mounted(): void {
    this.loadLatestReadings();
  }

  get headers() {
    let colWidth = (1 / (this.tabularData?.data?.getHeadersList()?.length ?? 1)) * 100;
    return this.tabularData?.data?.getHeadersList().map((header, i) => ({
      text: header,
      value: header != "Aktion" ? i.toString() : "action",
      width: header != "Aktion" ? `${colWidth}%` : "1%",
      sortable: header != "Aktion",
    }));
  }

  get items() {
    return this.tabularData.data?.getRowsList().map((row) => {
      const rowData = row.getRowData()?.getDeviceReadingRow();
      if (rowData) {
        return row.getColumnsList().reduce(
          (curr, column, i) => ({
            ...curr,
            [i]: column,
            value: rowData.getValue(),
            timestamp: rowData.getTimeStamp() * 1000,
            unit: rowData.getUnit(),
          }),
          {}
        );
      }
    });
  }

  editRow(item) {
    let rowData: TabularChartRowData = {
      label: item[0],
      timestamp: item.timestamp,
      value: item.value,
      unit: item.unit,
      formattedDate: item[1],
    };
    this.$emit("edit-row", rowData);
  }

  @Watch("isUpdate")
  @Watch("chosenDevice")
  @Watch("updateCall")
  private async loadLatestReadings(): Promise<void> {
    this.consumptionValue = "";
    this.ingestionValue = "";
    this.hasIngestion = false;

    try {
      this.tabularData = pending;

      if (this.chosenDevice) {
        var firstReadingDateTime = timeStampToDateTime(this.chosenDevice.getFirstReadingTime());

        const request = new GetTabularDataRequest();
        const tableRequest = new DeviceReadingsTableRequest();
        const statsRequest = new GetDeviceReadingStatsRequest();
        statsRequest.setDeviceId(this.chosenDevice.getId());
        tableRequest.setDeviceId(this.chosenDevice.getId());
        tableRequest.setSpaceId(spaces.selectedSpaceId);
        tableRequest.setStartDate(firstReadingDateTime);
        tableRequest.setEndDate(dateToDateTime(new Date(Date.now())));

        request.setDeviceReadingsTable(tableRequest);

        if (this.isUpdate) {
          request.setIsEditable(true);
        }

        const response = await uiServiceClient.getTabularData(request, {});
        const statsResponse = await deviceServiceClient.getDeviceReadingStats(statsRequest, {});

        if (response && statsResponse) {
          this.tabularData = success(response);
          this.hasIngestion = statsResponse.getDeviceReadingStats()?.getHasIngestion() ?? false;
          this.$emit("hasIngestion", this.hasIngestion);

          var latestConsumptionRow;
          if (this.hasIngestion) {
            latestConsumptionRow = this.tabularData.data?.getRowsList().slice(-2)[0];
            let latestIngestionRow = this.tabularData.data?.getRowsList().slice(-1)[0];

            this.ingestionLabel = latestIngestionRow?.getColumnsList()[0] ?? "";
            this.ingestionValue = latestIngestionRow?.getColumnsList()[2] ?? "";
          } else {
            latestConsumptionRow = this.tabularData.data?.getRowsList().slice(-1)[0];
          }

          this.consumptionLabel = latestConsumptionRow?.getColumnsList()[0] ?? "";
          this.measurementDate = latestConsumptionRow?.getColumnsList()[1] ?? "";
          this.consumptionValue = latestConsumptionRow?.getColumnsList()[2] ?? "";
        }
      }
    } catch (error) {
      this.tabularData = failure(userErrorFrom(error));
    }
  }
}
