
import { Component, Prop, Vue } from "vue-property-decorator";
import CreatePartnerDialog from "@/pages/platform/Partner/CreatePartner.vue";
import SwitchChildPartnerDialog from "./SwitchChildPartnerDialog.vue";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import partner, { partnerModule } from "@/store/modules/partner";
import {
  GetTreeSelectRequest,
  GetTreeSelectResponse,
  PartnerTreeSelectRequest,
} from "zaehlerfreunde-central/ui_service_pb";
import { uiServiceClient } from "@/config/service-clients";
import FeatureNotIncludedDialog from "@/components/subscription/FeatureNotIncludedDialog.vue";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

@Component({
  components: { CreatePartnerDialog, SwitchChildPartnerDialog, FeatureNotIncludedDialog },
})
export default class ChildPartnerMenu extends Vue {
  @Prop({ default: false }) isSuperAdmin: boolean;

  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @featuresModules.Getter subscribedFeatures: Set<Feature>;

  childPartnersTreeSelect: RemoteData<UserError, GetTreeSelectResponse.Node[]> = initialized;
  showAddPartnerDialog: boolean = false;
  showSwitchPartnerDialog: boolean = false;
  featureNotIncludedError: boolean = false;

  get selectedChildPartnerName(): string {
    return this.selectedChildPartner.data
      ? this.selectedChildPartner.data?.getName()
      : this.partner.data?.getName() ?? "";
  }

  async mounted(): Promise<void> {
    await this.loadChildPartnersTree();
  }

  async loadChildPartnersTree(): Promise<void> {
    const request = new GetTreeSelectRequest();
    const partnerTreeSelectRequest = new PartnerTreeSelectRequest();

    if (!this.partner.data) {
      await partner.getPartner();
    }
    partnerTreeSelectRequest.setPartnerId(this.partner.data?.getId() ?? "");

    request.setPartnerRequest(partnerTreeSelectRequest);

    try {
      this.childPartnersTreeSelect = pending;
      const response = await uiServiceClient.getTreeSelect(request, {});
      if (response) {
        this.childPartnersTreeSelect = success(response.getNodesList());
      }
    } catch (error) {
      this.childPartnersTreeSelect = failure(userErrorFrom(error));
    }
  }

  addPartnerDialog() {
    if (!this.isSuperAdmin && !this.subscribedFeatures.has(Feature.CHILD_PARTNER_CREATION)) {
      this.featureNotIncludedError = true;
    } else {
      this.showAddPartnerDialog = true;
    }
  }
}
