
import devices, { devicesModule } from "@/store/modules/devices";
import spaces from "@/store/modules/spaces";
import { DeviceRegistration, LobaroRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { deviceServiceClient } from "@/config/service-clients";
import { LobaroDevice, GetLobaroDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { RemoteData, RemoteCall, initialized, failure, success, pending } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getMediumIcon } from "@/utils/device-utils";
import { paths } from "@/router/routes";

@Component({
  components: {},
})
export default class LobaroConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;
  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  devices: RemoteData<UserError, LobaroDevice[]> = initialized;

  page: number = 1;
  getMediumIcon = getMediumIcon;

  selectedDevices: LobaroDevice[] = [];
  accessToken: string = "";

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  get explanation() {
    if (this.page === 1) {
      return "Um Zugriff auf deine Lobaro Geräte zu erhalten, brauchen wir einen Access Token für die Lobaro Platform.";
    } else {
      return "Bitte, wähle die Geräte aus welche du hinzufügen möchstest.";
    }
  }

  deviceName(device: LobaroDevice): string {
    return device.getName();
  }

  deviceValue(device: LobaroDevice): number {
    return device.getGatewayId();
  }

  async onNextPageClicked(): Promise<void> {
    this.devices = pending;
    const request = new GetLobaroDevicesRequest();
    request.setAccessToken(this.accessToken);

    try {
      const response = await deviceServiceClient.getLobaroDevices(request, {});
      this.devices = success(response.getDevicesList());
      this.page = 2;
    } catch (error) {
      this.devices = failure(userErrorFrom(error));
    }
  }

  onContinueClicked(): void {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new LobaroRegistrationDetails();
    details.setAuthToken(this.accessToken);

    this.selectedDevices.forEach((device) => {
      let deviceDetails = new LobaroRegistrationDetails.DeviceDetails();
      deviceDetails = deviceDetails.setGatewayId(device.getGatewayId());
      deviceDetails = deviceDetails.setIdDataLinkLayer(device.getIdDataLinkLayer());
      details.addLobaroDevices(deviceDetails);
    });

    registration.setLobaro(details);

    devices.registerDevice(registration);
  }

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }
}
