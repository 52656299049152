
import { failure, success, initialized, pending, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  GetShellyPortalInformationRequest,
  GetShellyUserDevicesRequest,
  ShellyDevice,
} from "zaehlerfreunde-central/device_service_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { Option } from "@/components/core/funnel/OptionSelection.vue";
import devices, { devicesModule } from "@/store/modules/devices";
import { DeviceRegistration, ShellyRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";
import { paths } from "@/router/routes";

export interface ShellyConnectionState {
  spaceId: string;
  deviceName: string;
  isMainDevice: boolean;
}

@Component
export default class ShellyConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  deviceOptions: RemoteData<UserError, Option[]> = initialized;

  shellyPortalURL: RemoteData<UserError, string> = initialized;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  timer: number;
  selectedDeviceId: string | null = null;
  shellyDevices: ShellyDevice[] = [];
  directions: Direction[] = [];
  directionAnswers: { text: string; value: Direction }[] = [
    { text: "Einspeisung/Produktion", value: Direction.OUT },
    { text: "Verbrauch", value: Direction.IN },
  ];

  mounted(): void {
    this.getShellyPortalInformation();
    this.getShellyUserDevices();

    this.timer = setInterval(() => {
      this.getShellyUserDevices();
    }, 3000);
  }

  beforeDestroy(): void {
    clearInterval(this.timer);
  }

  onPortalClicked(): void {
    if (this.shellyPortalURL.data) {
      window.open(this.shellyPortalURL.data);
    }
  }

  onShellyDeviceSelected(shellyDeviceOption: Option): void {
    const shellyDeviceId = shellyDeviceOption.value as string;
    let shellyDevice = this.shellyDevices.find((d) => d.getDeviceId() == shellyDeviceId);
    if (shellyDevice?.getIsDirectionsEditable()) {
      this.selectedDeviceId = shellyDeviceId;
    } else {
      this.registerShellyDevice(shellyDeviceId, this.directions);
    }
  }

  onDirectionsSelected(): void {
    if (this.selectedDeviceId) {
      this.registerShellyDevice(this.selectedDeviceId, this.directions);
    }
  }

  @Watch("registerDeviceCall")
  onRegisterDeviceCallChanged(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  async getShellyPortalInformation(): Promise<void> {
    this.shellyPortalURL = pending;

    try {
      const request = new GetShellyPortalInformationRequest();
      const response = await deviceServiceClient.getShellyPortalInformation(request, {});
      this.shellyPortalURL = success(response.getUrl());
    } catch (error) {
      this.shellyPortalURL = failure(userErrorFrom(error));
    }
  }

  async getShellyUserDevices(): Promise<void> {
    try {
      const request = new GetShellyUserDevicesRequest();
      request.setDeviceType(this.generalInfo.deviceType);
      const response = await deviceServiceClient.getShellyUserDevices(request, {});
      this.shellyDevices = response.getDevicesList();

      var options: Option[] = [];

      for (const device of response.getDevicesList()) {
        options.push({
          title: device.getTitle(),
          subtitle: device.getSubtitle(),
          value: device.getDeviceId(),
          image: device.getImageurl(),
        });
      }
      this.deviceOptions = success(options);
    } catch (error) {
      this.deviceOptions = failure(userErrorFrom(error));
    }
  }

  get channelNamesForSelectedDevice(): string[] {
    for (var i = 0; i < this.shellyDevices.length; i++) {
      if (this.shellyDevices[i].getDeviceId() == this.selectedDeviceId) {
        return this.shellyDevices[i].getChannelNamesList();
      }
    }
    return [];
  }

  async registerShellyDevice(shellyDeviceID: string, directions: Direction[]): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new ShellyRegistrationDetails();
    details.setShellyDeviceId(shellyDeviceID);
    details.setChannelDirectionsList(directions);

    registration.setShelly(details);

    devices.registerDevice(registration);
  }
}
