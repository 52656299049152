
import { devicesModule } from "@/store/modules/devices";
import { isSuccess, RemoteData, RemoteDataVue } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import CsvExport from "@/components/CSVExport.vue";
import { subscriptionModule } from "@/store/modules/subscription";
import { paths } from "@/router/routes";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

@Component({
  components: {
    CsvExport,
  },
})
export default class Widget extends RemoteDataVue {
  @Prop({ default: false }) fullWidth: boolean;
  @Prop({ default: false }) actionRequired: boolean;
  @Prop({ default: false }) allowDeviceSelection: boolean;
  @Prop({ default: null }) selectedDeviceId: string | null;
  @Prop({ default: false }) showDownloadOption: boolean;
  @Prop({ default: false }) hideDeviceSelection: boolean;

  smallWindow: boolean = false;
  selectedDeviceId_: string | null = null;
  showCsvExport: boolean = false;
  @subscriptionModule.Getter includedFeatures: Feature[];
  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  get showTooltip(): boolean {
    return !this.hideDeviceSelection && (this.devices.list.length > 1 || this.showDownloadOption);
  }

  get showDeviceSelection(): boolean {
    return this.devices.list.length > 1 && this.allowDeviceSelection;
  }

  mounted(): void {
    this.onSuccessfullyConnectedDevicesChanged();
    this.$watch("successfullyConnectedDevices", this.onSuccessfullyConnectedDevicesChanged);
  }

  get selectedDevice(): Device | undefined {
    return this.devices.list.find((d) => d.getId() === this.selectedDeviceId_);
  }

  getDeviceName(device: Device): string {
    return device.getName();
  }

  getDeviceId(device: Device): string {
    return device.getId();
  }

  onSuccessfullyConnectedDevicesChanged(): void {
    if (isSuccess(this.devices) && this.allowDeviceSelection) {
      this.selectedDeviceId_ = this.devices.list[0]?.getId() ?? null;
      this.$emit("device-selected", this.selectedDeviceId_);
    }
  }

  @Watch("selectedDeviceId")
  onSelectedDeviceIdChanged(): void {
    this.selectedDeviceId_ = this.selectedDeviceId;
  }

  csvExport(): void {
    if (!this.includedFeatures.includes(Feature.CSV_EXPORT)) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "csv-export" },
      });
    } else {
      this.showCsvExport = true;
    }
  }

  onDeviceSelected(device: Device): void {
    this.selectedDeviceId_ = device.getId();
    this.$emit("device-selected", device.getId());
  }

  resizeEventHandler(): void {
    this.smallWindow = window.innerWidth < 501;
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  beforeMount(): void {
    this.resizeEventHandler();
  }
}
