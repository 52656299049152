
import { Component, Prop, Vue } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import spaces from "@/store/modules/spaces";
import AddEditTariff from "../space-settings/tariff/AddEditTariff.vue";

@Component({
  components: {
    AddEditTariff,
  },
})
export default class AddTariffReminder extends Vue {
  @Prop({ default: false }) reason: "tariff-switch" | "monthly-costs" | "simulator" | "another-tariff" | "first-tariff";
  @Prop({ default: Device.Medium.ELECTRICITY }) deviceMedium: Device.Medium;
  @Prop({ default: "" }) spaceId: string;

  zfProtoDeviceMedium = Device.Medium;
  showAddUpdateTariffDialog = false;

  get selectedSpaceId(): string {
    if (this.spaceId) {
      return this.spaceId;
    } else {
      return spaces.selectedSpace?.getId() ?? "";
    }
  }

  get currentMonth(): string {
    return new Date().toLocaleString("de-DE", { month: "long" });
  }
}
