import { namespace } from "vuex-class";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "..";
import { RemoteData, failure, initialized, isSuccess, pending, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { partnerServiceClient, uiServiceClient } from "@/config/service-clients";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { GetAvailableFeaturesRequest } from "zaehlerfreunde-central/ui_service_pb";
import spaces from "./spaces";
import { GetSubscribedFeaturesRequest } from "zaehlerfreunde-central/partner_service_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "features",
})
class FeaturesModule extends VuexModule {
  subscribedPartnerFeatures: RemoteData<UserError, Feature[]> = initialized;
  features: RemoteData<UserError, Feature[]> = initialized;

  get enabledFeatures(): Set<Feature> {
    const f = new Set<Feature>();

    if (isSuccess(this.features)) {
      this.features.data.forEach((feature) => {
        f.add(feature);
      });
    }

    return f;
  }

  get subscribedFeatures(): Set<Feature> {
    const f = new Set<Feature>();

    if (isSuccess(this.subscribedPartnerFeatures)) {
      this.subscribedPartnerFeatures.data.forEach((feature) => {
        f.add(feature);
      });
    }

    return f;
  }

  @Action
  async getFeatures() {
    try {
      this.setFeatures(pending);

      const request = new GetAvailableFeaturesRequest();

      const selectedSpace = spaces.selectedSpace;

      if (selectedSpace) {
        request.setSpaceId(selectedSpace.getId());
      }

      const response = await uiServiceClient.getAvailableFeatures(request, {});
      this.setFeatures(success(response.getFeaturesList()));
    } catch (error) {
      this.setFeatures(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getSubscribedFeatures() {
    try {
      this.setSubscribedFeatures(pending);

      const request = new GetSubscribedFeaturesRequest();
      const response = await partnerServiceClient.getSubscribedFeatures(request, {});
      this.setSubscribedFeatures(success(response.getSubscribedFeaturesList()));
    } catch (error) {
      this.setSubscribedFeatures(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setFeatures(features: RemoteData<UserError, Feature[]>) {
    this.features = features;
  }

  @Mutation
  setSubscribedFeatures(subscribedPartnerFeatures: RemoteData<UserError, Feature[]>) {
    this.subscribedPartnerFeatures = subscribedPartnerFeatures;
  }
}

export const featuresModules = namespace("features");
export default getModule(FeaturesModule);
