
import { getAuthService } from "@/auth";
import { Component, Prop, Vue } from "vue-property-decorator";
import ZaehlerfreundeLogo from "../ZaehlerfreundeLogo.vue";
import { VisitorType, visitorType$ } from "@/observables/visitor-type-observables";
import { bind } from "@/observables/bind-observables";
import BookMeeting from "@/components/book-meeting/BookMeeting.vue";
import LocalePicker from "@/components/locale-picker/LocalePicker.vue";
import { paths } from "@/router/routes";
import { TranslateResult } from "vue-i18n";

interface MenuItem {
  label: TranslateResult;
  path?: string;
  subitems?: MenuItem[];
}

interface ActionItem {
  label: TranslateResult;
  icon?: string;
  callback: () => void;
}

@Component({
  components: {
    ZaehlerfreundeLogo,
    BookMeeting,
    LocalePicker,
  },
})
export default class HomepageTopBar extends Vue {
  @Prop({ default: false }) dark: boolean;

  smallWindow: boolean = false;
  showBookMeetingDialog: boolean = false;

  VisitorType = VisitorType;
  visitorType = bind(visitorType$);

  companyItem: MenuItem = {
    label: this.$t("aboutUs").toString(),
    subitems: [
      { label: this.$t("aboutUs").toString(), path: paths.homepage.aboutUs },
      { label: this.$t("blog").toString(), path: paths.homepage.blog },
      { label: this.$t("press").toString(), path: paths.homepage.press },
      { label: this.$t("imprint").toString(), path: paths.homepage.imprint },
    ],
  };

  successStoriesSubItems: MenuItem = {
    label: this.$t("successStories"),
    subitems: [
      {
        label: "BlueMetering",
        path: paths.homepage.whiteLabel.successStories.bluemetering,
      },
      {
        label: "Rohrer",
        path: paths.homepage.whiteLabel.successStories.rohrer,
      },
      {
        label: "e3",
        path: paths.homepage.whiteLabel.successStories.e3,
      },
      {
        label: "Energielandwerker",
        path: paths.homepage.whiteLabel.successStories.energielandwerker,
      },
    ],
  };

  whiteLabelSolutionsItem: MenuItem = {
    label: this.$t("solutions"),
    subitems: [
      {
        label: this.$t("landingPage.whiteLabel.customerGroups.meteringOperator.name"),
        path: paths.homepage.whiteLabel.meteringOperators,
      },
      {
        label: this.$t("landingPage.whiteLabel.customerGroups.utilityProvider.name"),
        path: paths.homepage.whiteLabel.utilityProviders,
      },
      {
        label: this.$t("landingPage.whiteLabel.customerGroups.municipalUtilities.name"),
        path: paths.homepage.whiteLabel.municipalUtilities,
      },
      {
        label: this.$t("landingPage.whiteLabel.customerGroups.meterToTenant.name"),
        path: paths.homepage.whiteLabel.meterToTenantProjects,
      },
      this.successStoriesSubItems,
      //   {
      //     label: this.$t("landingPage.whiteLabel.customerGroups.propertyManagement.name"),
      //     path: paths.homepage.whiteLabel.propertyManagement,
      //   },
    ],
  };

  privateCustomerItem: MenuItem = {
    label: this.$t("privateCustomer").toString(),
    path: paths.homepage.privateCustomers.b2cLandingPage,
  };

  commercialCustomeritem: MenuItem = {
    label: this.$t("commercialCustomer").toString(),
    path: paths.homepage.commercialCustomers,
  };

  whiteLabelItem: MenuItem = {
    label: this.$t("whiteLabel").toString(),
    path: paths.homepage.whiteLabel.landingPage,
  };

  get menuItems(): MenuItem[] {
    if (this.visitorType) {
      return this.menuItemsByVisitorType[this.visitorType];
    } else {
      return [];
    }
  }

  menuItemsByVisitorType: { [visitorType: string]: MenuItem[] } = {
    [VisitorType.PrivateCustomer]: [
      {
        label: this.$t("shop").toString(),
        path: paths.homepage.privateCustomers.shop,
      },
      {
        label: this.$t("prices").toString(),
        path: paths.homepage.privateCustomers.pricing,
      },
      this.companyItem,
      {
        label: this.$t("privateCustomer").toString(),
        subitems: [this.commercialCustomeritem, this.whiteLabelItem],
      },
    ],
    [VisitorType.CommercialCustomer]: [
      this.companyItem,
      {
        label: this.$t("commercialCustomer").toString(),
        subitems: [this.privateCustomerItem, this.whiteLabelItem],
      },
    ],
    [VisitorType.WhiteLabelCustomer]: [
      this.companyItem,
      this.whiteLabelSolutionsItem,
      {
        label: this.$t("whiteLabel").toString(),
        subitems: [this.privateCustomerItem, this.commercialCustomeritem],
      },
    ],
  };

  get actionItem(): ActionItem {
    if (this.visitorType) {
      return this.actionItemByVisitorType[this.visitorType];
    } else {
      return {
        label: "",
        callback: this.onLoginClicked,
      };
    }
  }

  actionItemByVisitorType: { [visitorType: string]: ActionItem } = {
    [VisitorType.PrivateCustomer]: {
      label: this.$t("login").toString(),
      callback: this.onLoginClicked,
    },
    [VisitorType.CommercialCustomer]: {
      label: this.$t("login").toString(),
      callback: this.onLoginClicked,
    },
    [VisitorType.WhiteLabelCustomer]: {
      label: this.$t("bookDemo").toString(),
      callback: this.onBookMeetingClicked,
    },
  };

  noRegistrationDialog: boolean = false;
  allowRegistration =
    window.location.href.startsWith("https://internal.") || window.location.href.startsWith("http://localhost:");

  disableDialog(): void {
    this.noRegistrationDialog = false;
  }

  onLoginClicked(): void {
    getAuthService().loginWithRedirect({});
  }

  onBookMeetingClicked(): void {
    this.showBookMeetingDialog = true;
  }

  resizeEventHandler(): void {
    this.smallWindow = window.innerWidth < 1000;
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  beforeMount(): void {
    this.resizeEventHandler();
  }
}
