/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_api_httpbody_pb from '../../google/api/httpbody_pb';
import * as services_central_user_service_pb from '../../services/central/user_service_pb';


export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetRegistrationStatus = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetRegistrationStatus',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetRegistrationStatusRequest,
    services_central_user_service_pb.GetRegistrationStatusResponse,
    (request: services_central_user_service_pb.GetRegistrationStatusRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetRegistrationStatusResponse.deserializeBinary
  );

  getRegistrationStatus(
    request: services_central_user_service_pb.GetRegistrationStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetRegistrationStatusResponse>;

  getRegistrationStatus(
    request: services_central_user_service_pb.GetRegistrationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetRegistrationStatusResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetRegistrationStatusResponse>;

  getRegistrationStatus(
    request: services_central_user_service_pb.GetRegistrationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetRegistrationStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetRegistrationStatus',
        request,
        metadata || {},
        this.methodInfoGetRegistrationStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetRegistrationStatus',
    request,
    metadata || {},
    this.methodInfoGetRegistrationStatus);
  }

  methodInfoAddNameAndAcceptTerms = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/AddNameAndAcceptTerms',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.AddNameAndAcceptTermsRequest,
    services_central_user_service_pb.AddNameAndAcceptTermsResponse,
    (request: services_central_user_service_pb.AddNameAndAcceptTermsRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.AddNameAndAcceptTermsResponse.deserializeBinary
  );

  addNameAndAcceptTerms(
    request: services_central_user_service_pb.AddNameAndAcceptTermsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.AddNameAndAcceptTermsResponse>;

  addNameAndAcceptTerms(
    request: services_central_user_service_pb.AddNameAndAcceptTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.AddNameAndAcceptTermsResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.AddNameAndAcceptTermsResponse>;

  addNameAndAcceptTerms(
    request: services_central_user_service_pb.AddNameAndAcceptTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.AddNameAndAcceptTermsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/AddNameAndAcceptTerms',
        request,
        metadata || {},
        this.methodInfoAddNameAndAcceptTerms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/AddNameAndAcceptTerms',
    request,
    metadata || {},
    this.methodInfoAddNameAndAcceptTerms);
  }

  methodInfoCreatePartnerAndAcceptTerms = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CreatePartnerAndAcceptTerms',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CreatePartnerAndAcceptTermsRequest,
    services_central_user_service_pb.CreatePartnerAndAcceptTermsResponse,
    (request: services_central_user_service_pb.CreatePartnerAndAcceptTermsRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CreatePartnerAndAcceptTermsResponse.deserializeBinary
  );

  createPartnerAndAcceptTerms(
    request: services_central_user_service_pb.CreatePartnerAndAcceptTermsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CreatePartnerAndAcceptTermsResponse>;

  createPartnerAndAcceptTerms(
    request: services_central_user_service_pb.CreatePartnerAndAcceptTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreatePartnerAndAcceptTermsResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CreatePartnerAndAcceptTermsResponse>;

  createPartnerAndAcceptTerms(
    request: services_central_user_service_pb.CreatePartnerAndAcceptTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreatePartnerAndAcceptTermsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CreatePartnerAndAcceptTerms',
        request,
        metadata || {},
        this.methodInfoCreatePartnerAndAcceptTerms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CreatePartnerAndAcceptTerms',
    request,
    metadata || {},
    this.methodInfoCreatePartnerAndAcceptTerms);
  }

  methodInfoConfirmEmailVerified = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/ConfirmEmailVerified',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.ConfirmEmailVerifiedRequest,
    services_central_user_service_pb.ConfirmEmailVerifiedResponse,
    (request: services_central_user_service_pb.ConfirmEmailVerifiedRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.ConfirmEmailVerifiedResponse.deserializeBinary
  );

  confirmEmailVerified(
    request: services_central_user_service_pb.ConfirmEmailVerifiedRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.ConfirmEmailVerifiedResponse>;

  confirmEmailVerified(
    request: services_central_user_service_pb.ConfirmEmailVerifiedRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ConfirmEmailVerifiedResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.ConfirmEmailVerifiedResponse>;

  confirmEmailVerified(
    request: services_central_user_service_pb.ConfirmEmailVerifiedRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ConfirmEmailVerifiedResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/ConfirmEmailVerified',
        request,
        metadata || {},
        this.methodInfoConfirmEmailVerified,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/ConfirmEmailVerified',
    request,
    metadata || {},
    this.methodInfoConfirmEmailVerified);
  }

  methodInfoDeleteAccount = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/DeleteAccount',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.DeleteAccountRequest,
    services_central_user_service_pb.DeleteAccountResponse,
    (request: services_central_user_service_pb.DeleteAccountRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.DeleteAccountResponse.deserializeBinary
  );

  deleteAccount(
    request: services_central_user_service_pb.DeleteAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.DeleteAccountResponse>;

  deleteAccount(
    request: services_central_user_service_pb.DeleteAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DeleteAccountResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.DeleteAccountResponse>;

  deleteAccount(
    request: services_central_user_service_pb.DeleteAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DeleteAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/DeleteAccount',
        request,
        metadata || {},
        this.methodInfoDeleteAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/DeleteAccount',
    request,
    metadata || {},
    this.methodInfoDeleteAccount);
  }

  methodInfoGetEmailVerificationStatus = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetEmailVerificationStatus',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetEmailVerificationStatusRequest,
    services_central_user_service_pb.GetEmailVerificationStatusResponse,
    (request: services_central_user_service_pb.GetEmailVerificationStatusRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetEmailVerificationStatusResponse.deserializeBinary
  );

  getEmailVerificationStatus(
    request: services_central_user_service_pb.GetEmailVerificationStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetEmailVerificationStatusResponse>;

  getEmailVerificationStatus(
    request: services_central_user_service_pb.GetEmailVerificationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetEmailVerificationStatusResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetEmailVerificationStatusResponse>;

  getEmailVerificationStatus(
    request: services_central_user_service_pb.GetEmailVerificationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetEmailVerificationStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetEmailVerificationStatus',
        request,
        metadata || {},
        this.methodInfoGetEmailVerificationStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetEmailVerificationStatus',
    request,
    metadata || {},
    this.methodInfoGetEmailVerificationStatus);
  }

  methodInfoGetUser = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetUser',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetUserRequest,
    services_central_user_service_pb.GetUserResponse,
    (request: services_central_user_service_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetUserResponse.deserializeBinary
  );

  getUser(
    request: services_central_user_service_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetUserResponse>;

  getUser(
    request: services_central_user_service_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetUserResponse>;

  getUser(
    request: services_central_user_service_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetUser',
        request,
        metadata || {},
        this.methodInfoGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetUser',
    request,
    metadata || {},
    this.methodInfoGetUser);
  }

  methodInfoGetUserById = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetUserById',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetUserByIdRequest,
    services_central_user_service_pb.GetUserByIdResponse,
    (request: services_central_user_service_pb.GetUserByIdRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetUserByIdResponse.deserializeBinary
  );

  getUserById(
    request: services_central_user_service_pb.GetUserByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetUserByIdResponse>;

  getUserById(
    request: services_central_user_service_pb.GetUserByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUserByIdResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetUserByIdResponse>;

  getUserById(
    request: services_central_user_service_pb.GetUserByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUserByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetUserById',
        request,
        metadata || {},
        this.methodInfoGetUserById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetUserById',
    request,
    metadata || {},
    this.methodInfoGetUserById);
  }

  methodInfoGetUsers = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetUsers',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetUsersRequest,
    services_central_user_service_pb.GetUsersResponse,
    (request: services_central_user_service_pb.GetUsersRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetUsersResponse.deserializeBinary
  );

  getUsers(
    request: services_central_user_service_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetUsersResponse>;

  getUsers(
    request: services_central_user_service_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUsersResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetUsersResponse>;

  getUsers(
    request: services_central_user_service_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetUsers',
        request,
        metadata || {},
        this.methodInfoGetUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetUsers',
    request,
    metadata || {},
    this.methodInfoGetUsers);
  }

  methodInfoGetUserEmailById = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetUserEmailById',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetUserEmailByIdRequest,
    services_central_user_service_pb.GetUserEmailByIdResponse,
    (request: services_central_user_service_pb.GetUserEmailByIdRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetUserEmailByIdResponse.deserializeBinary
  );

  getUserEmailById(
    request: services_central_user_service_pb.GetUserEmailByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetUserEmailByIdResponse>;

  getUserEmailById(
    request: services_central_user_service_pb.GetUserEmailByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUserEmailByIdResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetUserEmailByIdResponse>;

  getUserEmailById(
    request: services_central_user_service_pb.GetUserEmailByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUserEmailByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetUserEmailById',
        request,
        metadata || {},
        this.methodInfoGetUserEmailById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetUserEmailById',
    request,
    metadata || {},
    this.methodInfoGetUserEmailById);
  }

  methodInfoResendVerificationEmail = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/ResendVerificationEmail',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.ResendVerificationEmailRequest,
    services_central_user_service_pb.ResendVerificationEmailResponse,
    (request: services_central_user_service_pb.ResendVerificationEmailRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.ResendVerificationEmailResponse.deserializeBinary
  );

  resendVerificationEmail(
    request: services_central_user_service_pb.ResendVerificationEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.ResendVerificationEmailResponse>;

  resendVerificationEmail(
    request: services_central_user_service_pb.ResendVerificationEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ResendVerificationEmailResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.ResendVerificationEmailResponse>;

  resendVerificationEmail(
    request: services_central_user_service_pb.ResendVerificationEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ResendVerificationEmailResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/ResendVerificationEmail',
        request,
        metadata || {},
        this.methodInfoResendVerificationEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/ResendVerificationEmail',
    request,
    metadata || {},
    this.methodInfoResendVerificationEmail);
  }

  methodInfoChangeUserName = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/ChangeUserName',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.ChangeUserNameRequest,
    services_central_user_service_pb.ChangeUserNameResponse,
    (request: services_central_user_service_pb.ChangeUserNameRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.ChangeUserNameResponse.deserializeBinary
  );

  changeUserName(
    request: services_central_user_service_pb.ChangeUserNameRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.ChangeUserNameResponse>;

  changeUserName(
    request: services_central_user_service_pb.ChangeUserNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ChangeUserNameResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.ChangeUserNameResponse>;

  changeUserName(
    request: services_central_user_service_pb.ChangeUserNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ChangeUserNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/ChangeUserName',
        request,
        metadata || {},
        this.methodInfoChangeUserName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/ChangeUserName',
    request,
    metadata || {},
    this.methodInfoChangeUserName);
  }

  methodInfoGetSubscriptionPlans = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetSubscriptionPlans',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetSubscriptionPlansRequest,
    services_central_user_service_pb.GetSubscriptionPlansResponse,
    (request: services_central_user_service_pb.GetSubscriptionPlansRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetSubscriptionPlansResponse.deserializeBinary
  );

  getSubscriptionPlans(
    request: services_central_user_service_pb.GetSubscriptionPlansRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetSubscriptionPlansResponse>;

  getSubscriptionPlans(
    request: services_central_user_service_pb.GetSubscriptionPlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetSubscriptionPlansResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetSubscriptionPlansResponse>;

  getSubscriptionPlans(
    request: services_central_user_service_pb.GetSubscriptionPlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetSubscriptionPlansResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetSubscriptionPlans',
        request,
        metadata || {},
        this.methodInfoGetSubscriptionPlans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetSubscriptionPlans',
    request,
    metadata || {},
    this.methodInfoGetSubscriptionPlans);
  }

  methodInfoGetSubscriptionPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetSubscriptionPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetSubscriptionPlanRequest,
    services_central_user_service_pb.GetSubscriptionPlanResponse,
    (request: services_central_user_service_pb.GetSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetSubscriptionPlanResponse.deserializeBinary
  );

  getSubscriptionPlan(
    request: services_central_user_service_pb.GetSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetSubscriptionPlanResponse>;

  getSubscriptionPlan(
    request: services_central_user_service_pb.GetSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetSubscriptionPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetSubscriptionPlanResponse>;

  getSubscriptionPlan(
    request: services_central_user_service_pb.GetSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetSubscriptionPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetSubscriptionPlan',
        request,
        metadata || {},
        this.methodInfoGetSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetSubscriptionPlan',
    request,
    metadata || {},
    this.methodInfoGetSubscriptionPlan);
  }

  methodInfoGetCurrentSubscriptionPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetCurrentSubscriptionPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetCurrentSubscriptionPlanRequest,
    services_central_user_service_pb.GetCurrentSubscriptionPlanResponse,
    (request: services_central_user_service_pb.GetCurrentSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetCurrentSubscriptionPlanResponse.deserializeBinary
  );

  getCurrentSubscriptionPlan(
    request: services_central_user_service_pb.GetCurrentSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetCurrentSubscriptionPlanResponse>;

  getCurrentSubscriptionPlan(
    request: services_central_user_service_pb.GetCurrentSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetCurrentSubscriptionPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetCurrentSubscriptionPlanResponse>;

  getCurrentSubscriptionPlan(
    request: services_central_user_service_pb.GetCurrentSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetCurrentSubscriptionPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetCurrentSubscriptionPlan',
        request,
        metadata || {},
        this.methodInfoGetCurrentSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetCurrentSubscriptionPlan',
    request,
    metadata || {},
    this.methodInfoGetCurrentSubscriptionPlan);
  }

  methodInfoSubscribeToPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/SubscribeToPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.SubscribeToPlanRequest,
    services_central_user_service_pb.SubscribeToPlanResponse,
    (request: services_central_user_service_pb.SubscribeToPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.SubscribeToPlanResponse.deserializeBinary
  );

  subscribeToPlan(
    request: services_central_user_service_pb.SubscribeToPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.SubscribeToPlanResponse>;

  subscribeToPlan(
    request: services_central_user_service_pb.SubscribeToPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.SubscribeToPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.SubscribeToPlanResponse>;

  subscribeToPlan(
    request: services_central_user_service_pb.SubscribeToPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.SubscribeToPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/SubscribeToPlan',
        request,
        metadata || {},
        this.methodInfoSubscribeToPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/SubscribeToPlan',
    request,
    metadata || {},
    this.methodInfoSubscribeToPlan);
  }

  methodInfoCancelSubscription = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CancelSubscription',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CancelSubscriptionRequest,
    services_central_user_service_pb.CancelSubscriptionResponse,
    (request: services_central_user_service_pb.CancelSubscriptionRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CancelSubscriptionResponse.deserializeBinary
  );

  cancelSubscription(
    request: services_central_user_service_pb.CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CancelSubscriptionResponse>;

  cancelSubscription(
    request: services_central_user_service_pb.CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CancelSubscriptionResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CancelSubscriptionResponse>;

  cancelSubscription(
    request: services_central_user_service_pb.CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CancelSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CancelSubscription',
        request,
        metadata || {},
        this.methodInfoCancelSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CancelSubscription',
    request,
    metadata || {},
    this.methodInfoCancelSubscription);
  }

  methodInfoHandleStripeEvent = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/HandleStripeEvent',
    grpcWeb.MethodType.UNARY,
    google_api_httpbody_pb.HttpBody,
    services_central_user_service_pb.HandleStripeEventResponse,
    (request: google_api_httpbody_pb.HttpBody) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.HandleStripeEventResponse.deserializeBinary
  );

  handleStripeEvent(
    request: google_api_httpbody_pb.HttpBody,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.HandleStripeEventResponse>;

  handleStripeEvent(
    request: google_api_httpbody_pb.HttpBody,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.HandleStripeEventResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.HandleStripeEventResponse>;

  handleStripeEvent(
    request: google_api_httpbody_pb.HttpBody,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.HandleStripeEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/HandleStripeEvent',
        request,
        metadata || {},
        this.methodInfoHandleStripeEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/HandleStripeEvent',
    request,
    metadata || {},
    this.methodInfoHandleStripeEvent);
  }

  methodInfoGetUsersSubscribedToPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetUsersSubscribedToPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetUsersSubscribedToPlanRequest,
    services_central_user_service_pb.GetUsersSubscribedToPlanResponse,
    (request: services_central_user_service_pb.GetUsersSubscribedToPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetUsersSubscribedToPlanResponse.deserializeBinary
  );

  getUsersSubscribedToPlan(
    request: services_central_user_service_pb.GetUsersSubscribedToPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetUsersSubscribedToPlanResponse>;

  getUsersSubscribedToPlan(
    request: services_central_user_service_pb.GetUsersSubscribedToPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUsersSubscribedToPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetUsersSubscribedToPlanResponse>;

  getUsersSubscribedToPlan(
    request: services_central_user_service_pb.GetUsersSubscribedToPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUsersSubscribedToPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetUsersSubscribedToPlan',
        request,
        metadata || {},
        this.methodInfoGetUsersSubscribedToPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetUsersSubscribedToPlan',
    request,
    metadata || {},
    this.methodInfoGetUsersSubscribedToPlan);
  }

  methodInfoGetSubscriptionFeatures = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetSubscriptionFeatures',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetSubscriptionFeaturesRequest,
    services_central_user_service_pb.GetSubscriptionFeaturesResponse,
    (request: services_central_user_service_pb.GetSubscriptionFeaturesRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetSubscriptionFeaturesResponse.deserializeBinary
  );

  getSubscriptionFeatures(
    request: services_central_user_service_pb.GetSubscriptionFeaturesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetSubscriptionFeaturesResponse>;

  getSubscriptionFeatures(
    request: services_central_user_service_pb.GetSubscriptionFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetSubscriptionFeaturesResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetSubscriptionFeaturesResponse>;

  getSubscriptionFeatures(
    request: services_central_user_service_pb.GetSubscriptionFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetSubscriptionFeaturesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetSubscriptionFeatures',
        request,
        metadata || {},
        this.methodInfoGetSubscriptionFeatures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetSubscriptionFeatures',
    request,
    metadata || {},
    this.methodInfoGetSubscriptionFeatures);
  }

  methodInfoCreateSubscriptionPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CreateSubscriptionPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CreateSubscriptionPlanRequest,
    services_central_user_service_pb.CreateSubscriptionPlanResponse,
    (request: services_central_user_service_pb.CreateSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CreateSubscriptionPlanResponse.deserializeBinary
  );

  createSubscriptionPlan(
    request: services_central_user_service_pb.CreateSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CreateSubscriptionPlanResponse>;

  createSubscriptionPlan(
    request: services_central_user_service_pb.CreateSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateSubscriptionPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CreateSubscriptionPlanResponse>;

  createSubscriptionPlan(
    request: services_central_user_service_pb.CreateSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateSubscriptionPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CreateSubscriptionPlan',
        request,
        metadata || {},
        this.methodInfoCreateSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CreateSubscriptionPlan',
    request,
    metadata || {},
    this.methodInfoCreateSubscriptionPlan);
  }

  methodInfoUpdateSubscriptionPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/UpdateSubscriptionPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.UpdateSubscriptionPlanRequest,
    services_central_user_service_pb.UpdateSubscriptionPlanResponse,
    (request: services_central_user_service_pb.UpdateSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.UpdateSubscriptionPlanResponse.deserializeBinary
  );

  updateSubscriptionPlan(
    request: services_central_user_service_pb.UpdateSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.UpdateSubscriptionPlanResponse>;

  updateSubscriptionPlan(
    request: services_central_user_service_pb.UpdateSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.UpdateSubscriptionPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.UpdateSubscriptionPlanResponse>;

  updateSubscriptionPlan(
    request: services_central_user_service_pb.UpdateSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.UpdateSubscriptionPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/UpdateSubscriptionPlan',
        request,
        metadata || {},
        this.methodInfoUpdateSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/UpdateSubscriptionPlan',
    request,
    metadata || {},
    this.methodInfoUpdateSubscriptionPlan);
  }

  methodInfoDeleteSubscriptionPlan = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/DeleteSubscriptionPlan',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.DeleteSubscriptionPlanRequest,
    services_central_user_service_pb.DeleteSubscriptionPlanResponse,
    (request: services_central_user_service_pb.DeleteSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.DeleteSubscriptionPlanResponse.deserializeBinary
  );

  deleteSubscriptionPlan(
    request: services_central_user_service_pb.DeleteSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.DeleteSubscriptionPlanResponse>;

  deleteSubscriptionPlan(
    request: services_central_user_service_pb.DeleteSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DeleteSubscriptionPlanResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.DeleteSubscriptionPlanResponse>;

  deleteSubscriptionPlan(
    request: services_central_user_service_pb.DeleteSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DeleteSubscriptionPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/DeleteSubscriptionPlan',
        request,
        metadata || {},
        this.methodInfoDeleteSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/DeleteSubscriptionPlan',
    request,
    metadata || {},
    this.methodInfoDeleteSubscriptionPlan);
  }

  methodInfoCreateSubscription = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CreateSubscription',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CreateSubscriptionRequest,
    services_central_user_service_pb.CreateSubscriptionResponse,
    (request: services_central_user_service_pb.CreateSubscriptionRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CreateSubscriptionResponse.deserializeBinary
  );

  createSubscription(
    request: services_central_user_service_pb.CreateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CreateSubscriptionResponse>;

  createSubscription(
    request: services_central_user_service_pb.CreateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateSubscriptionResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CreateSubscriptionResponse>;

  createSubscription(
    request: services_central_user_service_pb.CreateSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateSubscriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CreateSubscription',
        request,
        metadata || {},
        this.methodInfoCreateSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CreateSubscription',
    request,
    metadata || {},
    this.methodInfoCreateSubscription);
  }

  methodInfoCheckUserPermission = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CheckUserPermission',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CheckUserPermissionRequest,
    services_central_user_service_pb.CheckUserPermissionResponse,
    (request: services_central_user_service_pb.CheckUserPermissionRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CheckUserPermissionResponse.deserializeBinary
  );

  checkUserPermission(
    request: services_central_user_service_pb.CheckUserPermissionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CheckUserPermissionResponse>;

  checkUserPermission(
    request: services_central_user_service_pb.CheckUserPermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CheckUserPermissionResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CheckUserPermissionResponse>;

  checkUserPermission(
    request: services_central_user_service_pb.CheckUserPermissionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CheckUserPermissionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CheckUserPermission',
        request,
        metadata || {},
        this.methodInfoCheckUserPermission,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CheckUserPermission',
    request,
    metadata || {},
    this.methodInfoCheckUserPermission);
  }

  methodInfoGetInvoices = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetInvoices',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetInvoicesRequest,
    services_central_user_service_pb.GetInvoicesResponse,
    (request: services_central_user_service_pb.GetInvoicesRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetInvoicesResponse.deserializeBinary
  );

  getInvoices(
    request: services_central_user_service_pb.GetInvoicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetInvoicesResponse>;

  getInvoices(
    request: services_central_user_service_pb.GetInvoicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInvoicesResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetInvoicesResponse>;

  getInvoices(
    request: services_central_user_service_pb.GetInvoicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInvoicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetInvoices',
        request,
        metadata || {},
        this.methodInfoGetInvoices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetInvoices',
    request,
    metadata || {},
    this.methodInfoGetInvoices);
  }

  methodInfoDownloadInvoice = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/DownloadInvoice',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.DownloadInvoiceRequest,
    services_central_user_service_pb.DownloadInvoiceResponse,
    (request: services_central_user_service_pb.DownloadInvoiceRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.DownloadInvoiceResponse.deserializeBinary
  );

  downloadInvoice(
    request: services_central_user_service_pb.DownloadInvoiceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.DownloadInvoiceResponse>;

  downloadInvoice(
    request: services_central_user_service_pb.DownloadInvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DownloadInvoiceResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.DownloadInvoiceResponse>;

  downloadInvoice(
    request: services_central_user_service_pb.DownloadInvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DownloadInvoiceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/DownloadInvoice',
        request,
        metadata || {},
        this.methodInfoDownloadInvoice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/DownloadInvoice',
    request,
    metadata || {},
    this.methodInfoDownloadInvoice);
  }

  methodInfoGetInvoiceHTML = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetInvoiceHTML',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetInvoiceHTMLRequest,
    services_central_user_service_pb.GetInvoiceHTMLResponse,
    (request: services_central_user_service_pb.GetInvoiceHTMLRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetInvoiceHTMLResponse.deserializeBinary
  );

  getInvoiceHTML(
    request: services_central_user_service_pb.GetInvoiceHTMLRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetInvoiceHTMLResponse>;

  getInvoiceHTML(
    request: services_central_user_service_pb.GetInvoiceHTMLRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInvoiceHTMLResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetInvoiceHTMLResponse>;

  getInvoiceHTML(
    request: services_central_user_service_pb.GetInvoiceHTMLRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInvoiceHTMLResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetInvoiceHTML',
        request,
        metadata || {},
        this.methodInfoGetInvoiceHTML,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetInvoiceHTML',
    request,
    metadata || {},
    this.methodInfoGetInvoiceHTML);
  }

  methodInfoCreateInvite = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CreateInvite',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CreateInviteRequest,
    services_central_user_service_pb.CreateInviteResponse,
    (request: services_central_user_service_pb.CreateInviteRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CreateInviteResponse.deserializeBinary
  );

  createInvite(
    request: services_central_user_service_pb.CreateInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CreateInviteResponse>;

  createInvite(
    request: services_central_user_service_pb.CreateInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateInviteResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CreateInviteResponse>;

  createInvite(
    request: services_central_user_service_pb.CreateInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CreateInvite',
        request,
        metadata || {},
        this.methodInfoCreateInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CreateInvite',
    request,
    metadata || {},
    this.methodInfoCreateInvite);
  }

  methodInfoGetInvites = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetInvites',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetInvitesRequest,
    services_central_user_service_pb.GetInvitesResponse,
    (request: services_central_user_service_pb.GetInvitesRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetInvitesResponse.deserializeBinary
  );

  getInvites(
    request: services_central_user_service_pb.GetInvitesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetInvitesResponse>;

  getInvites(
    request: services_central_user_service_pb.GetInvitesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInvitesResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetInvitesResponse>;

  getInvites(
    request: services_central_user_service_pb.GetInvitesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInvitesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetInvites',
        request,
        metadata || {},
        this.methodInfoGetInvites,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetInvites',
    request,
    metadata || {},
    this.methodInfoGetInvites);
  }

  methodInfoAcceptInvite = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/AcceptInvite',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.AcceptInviteRequest,
    services_central_user_service_pb.AcceptInviteResponse,
    (request: services_central_user_service_pb.AcceptInviteRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.AcceptInviteResponse.deserializeBinary
  );

  acceptInvite(
    request: services_central_user_service_pb.AcceptInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.AcceptInviteResponse>;

  acceptInvite(
    request: services_central_user_service_pb.AcceptInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.AcceptInviteResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.AcceptInviteResponse>;

  acceptInvite(
    request: services_central_user_service_pb.AcceptInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.AcceptInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/AcceptInvite',
        request,
        metadata || {},
        this.methodInfoAcceptInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/AcceptInvite',
    request,
    metadata || {},
    this.methodInfoAcceptInvite);
  }

  methodInfoGetInvite = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetInvite',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetInviteRequest,
    services_central_user_service_pb.GetInviteResponse,
    (request: services_central_user_service_pb.GetInviteRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetInviteResponse.deserializeBinary
  );

  getInvite(
    request: services_central_user_service_pb.GetInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetInviteResponse>;

  getInvite(
    request: services_central_user_service_pb.GetInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInviteResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetInviteResponse>;

  getInvite(
    request: services_central_user_service_pb.GetInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetInvite',
        request,
        metadata || {},
        this.methodInfoGetInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetInvite',
    request,
    metadata || {},
    this.methodInfoGetInvite);
  }

  methodInfoDeleteInvite = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/DeleteInvite',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.DeleteInviteRequest,
    services_central_user_service_pb.DeleteInviteResponse,
    (request: services_central_user_service_pb.DeleteInviteRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.DeleteInviteResponse.deserializeBinary
  );

  deleteInvite(
    request: services_central_user_service_pb.DeleteInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.DeleteInviteResponse>;

  deleteInvite(
    request: services_central_user_service_pb.DeleteInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DeleteInviteResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.DeleteInviteResponse>;

  deleteInvite(
    request: services_central_user_service_pb.DeleteInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.DeleteInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/DeleteInvite',
        request,
        metadata || {},
        this.methodInfoDeleteInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/DeleteInvite',
    request,
    metadata || {},
    this.methodInfoDeleteInvite);
  }

  methodInfoResendInvite = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/ResendInvite',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.ResendInviteRequest,
    services_central_user_service_pb.ResendInviteResponse,
    (request: services_central_user_service_pb.ResendInviteRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.ResendInviteResponse.deserializeBinary
  );

  resendInvite(
    request: services_central_user_service_pb.ResendInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.ResendInviteResponse>;

  resendInvite(
    request: services_central_user_service_pb.ResendInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ResendInviteResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.ResendInviteResponse>;

  resendInvite(
    request: services_central_user_service_pb.ResendInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.ResendInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/ResendInvite',
        request,
        metadata || {},
        this.methodInfoResendInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/ResendInvite',
    request,
    metadata || {},
    this.methodInfoResendInvite);
  }

  methodInfoGetInviteEmail = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetInviteEmail',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetInviteEmailRequest,
    services_central_user_service_pb.GetInviteEmailResponse,
    (request: services_central_user_service_pb.GetInviteEmailRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetInviteEmailResponse.deserializeBinary
  );

  getInviteEmail(
    request: services_central_user_service_pb.GetInviteEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetInviteEmailResponse>;

  getInviteEmail(
    request: services_central_user_service_pb.GetInviteEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInviteEmailResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetInviteEmailResponse>;

  getInviteEmail(
    request: services_central_user_service_pb.GetInviteEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetInviteEmailResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetInviteEmail',
        request,
        metadata || {},
        this.methodInfoGetInviteEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetInviteEmail',
    request,
    metadata || {},
    this.methodInfoGetInviteEmail);
  }

  methodInfoGetAdminUsers = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetAdminUsers',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetAdminUsersRequest,
    services_central_user_service_pb.GetAdminUsersResponse,
    (request: services_central_user_service_pb.GetAdminUsersRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetAdminUsersResponse.deserializeBinary
  );

  getAdminUsers(
    request: services_central_user_service_pb.GetAdminUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetAdminUsersResponse>;

  getAdminUsers(
    request: services_central_user_service_pb.GetAdminUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetAdminUsersResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetAdminUsersResponse>;

  getAdminUsers(
    request: services_central_user_service_pb.GetAdminUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetAdminUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetAdminUsers',
        request,
        metadata || {},
        this.methodInfoGetAdminUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetAdminUsers',
    request,
    metadata || {},
    this.methodInfoGetAdminUsers);
  }

  methodInfoRemoveUserFromAdminRole = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/RemoveUserFromAdminRole',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.RemoveUserFromAdminRoleRequest,
    services_central_user_service_pb.RemoveUserFromAdminRoleResponse,
    (request: services_central_user_service_pb.RemoveUserFromAdminRoleRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.RemoveUserFromAdminRoleResponse.deserializeBinary
  );

  removeUserFromAdminRole(
    request: services_central_user_service_pb.RemoveUserFromAdminRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.RemoveUserFromAdminRoleResponse>;

  removeUserFromAdminRole(
    request: services_central_user_service_pb.RemoveUserFromAdminRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.RemoveUserFromAdminRoleResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.RemoveUserFromAdminRoleResponse>;

  removeUserFromAdminRole(
    request: services_central_user_service_pb.RemoveUserFromAdminRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.RemoveUserFromAdminRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/RemoveUserFromAdminRole',
        request,
        metadata || {},
        this.methodInfoRemoveUserFromAdminRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/RemoveUserFromAdminRole',
    request,
    metadata || {},
    this.methodInfoRemoveUserFromAdminRole);
  }

  methodInfoAddUserToAdminRole = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/AddUserToAdminRole',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.AddUserToAdminRoleRequest,
    services_central_user_service_pb.AddUserToAdminRoleResponse,
    (request: services_central_user_service_pb.AddUserToAdminRoleRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.AddUserToAdminRoleResponse.deserializeBinary
  );

  addUserToAdminRole(
    request: services_central_user_service_pb.AddUserToAdminRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.AddUserToAdminRoleResponse>;

  addUserToAdminRole(
    request: services_central_user_service_pb.AddUserToAdminRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.AddUserToAdminRoleResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.AddUserToAdminRoleResponse>;

  addUserToAdminRole(
    request: services_central_user_service_pb.AddUserToAdminRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.AddUserToAdminRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/AddUserToAdminRole',
        request,
        metadata || {},
        this.methodInfoAddUserToAdminRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/AddUserToAdminRole',
    request,
    metadata || {},
    this.methodInfoAddUserToAdminRole);
  }

  methodInfoUpdatePartner = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/UpdatePartner',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.UpdatePartnerRequest,
    services_central_user_service_pb.UpdatePartnerResponse,
    (request: services_central_user_service_pb.UpdatePartnerRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.UpdatePartnerResponse.deserializeBinary
  );

  updatePartner(
    request: services_central_user_service_pb.UpdatePartnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.UpdatePartnerResponse>;

  updatePartner(
    request: services_central_user_service_pb.UpdatePartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.UpdatePartnerResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.UpdatePartnerResponse>;

  updatePartner(
    request: services_central_user_service_pb.UpdatePartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.UpdatePartnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/UpdatePartner',
        request,
        metadata || {},
        this.methodInfoUpdatePartner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/UpdatePartner',
    request,
    metadata || {},
    this.methodInfoUpdatePartner);
  }

  methodInfoGetUsersBySpace = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/GetUsersBySpace',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.GetUsersBySpaceRequest,
    services_central_user_service_pb.GetUsersBySpaceResponse,
    (request: services_central_user_service_pb.GetUsersBySpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.GetUsersBySpaceResponse.deserializeBinary
  );

  getUsersBySpace(
    request: services_central_user_service_pb.GetUsersBySpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.GetUsersBySpaceResponse>;

  getUsersBySpace(
    request: services_central_user_service_pb.GetUsersBySpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUsersBySpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.GetUsersBySpaceResponse>;

  getUsersBySpace(
    request: services_central_user_service_pb.GetUsersBySpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.GetUsersBySpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/GetUsersBySpace',
        request,
        metadata || {},
        this.methodInfoGetUsersBySpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/GetUsersBySpace',
    request,
    metadata || {},
    this.methodInfoGetUsersBySpace);
  }

  methodInfoCreateDemoToken = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/CreateDemoToken',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.CreateDemoTokenRequest,
    services_central_user_service_pb.CreateDemoTokenResponse,
    (request: services_central_user_service_pb.CreateDemoTokenRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.CreateDemoTokenResponse.deserializeBinary
  );

  createDemoToken(
    request: services_central_user_service_pb.CreateDemoTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.CreateDemoTokenResponse>;

  createDemoToken(
    request: services_central_user_service_pb.CreateDemoTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateDemoTokenResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.CreateDemoTokenResponse>;

  createDemoToken(
    request: services_central_user_service_pb.CreateDemoTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.CreateDemoTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/CreateDemoToken',
        request,
        metadata || {},
        this.methodInfoCreateDemoToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/CreateDemoToken',
    request,
    metadata || {},
    this.methodInfoCreateDemoToken);
  }

  methodInfoStoreUserDeviceToken = new grpcWeb.MethodDescriptor(
    '/services.central.UserService/StoreUserDeviceToken',
    grpcWeb.MethodType.UNARY,
    services_central_user_service_pb.StoreUserDeviceTokenRequest,
    services_central_user_service_pb.StoreUserDeviceTokenResponse,
    (request: services_central_user_service_pb.StoreUserDeviceTokenRequest) => {
      return request.serializeBinary();
    },
    services_central_user_service_pb.StoreUserDeviceTokenResponse.deserializeBinary
  );

  storeUserDeviceToken(
    request: services_central_user_service_pb.StoreUserDeviceTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_user_service_pb.StoreUserDeviceTokenResponse>;

  storeUserDeviceToken(
    request: services_central_user_service_pb.StoreUserDeviceTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.StoreUserDeviceTokenResponse) => void): grpcWeb.ClientReadableStream<services_central_user_service_pb.StoreUserDeviceTokenResponse>;

  storeUserDeviceToken(
    request: services_central_user_service_pb.StoreUserDeviceTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_user_service_pb.StoreUserDeviceTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UserService/StoreUserDeviceToken',
        request,
        metadata || {},
        this.methodInfoStoreUserDeviceToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UserService/StoreUserDeviceToken',
    request,
    metadata || {},
    this.methodInfoStoreUserDeviceToken);
  }

}

