
import { advisoryServiceClient } from "@/config/service-clients";
import tips, { tipsModule } from "@/store/modules/tips";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { TrackTipInteractionRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { Tip, TipInteraction } from "zaehlerfreunde-proto-types/tips_pb";

@Component({})
export default class DashboardTipPresenter extends Vue {
  @tipsModule.State tips: RemoteData<UserError, Tip[]>;
  trackInteractionCall: RemoteCall<UserError> = initialized;

  showCloseTipDialog = false;
  selectedReason: number | null = null;

  reasons: string[] = [
    "Angebot ist für mich nicht interessant.",
    "Ich nutze bereits einen anderen Anbieter.",
    "Ich will mich gerade nicht damit beschäftigen.",
  ];

  get tip(): Tip | undefined {
    return this.tips.list.find((t) => t.getDisplayProminently());
  }

  async closeTip(): Promise<void> {
    if (!this.tip) {
      return;
    }

    try {
      this.trackInteractionCall = pending;

      const interaction = new TipInteraction();
      interaction.setTipId(this.tip.getId());
      interaction.setKind(TipInteraction.Kind.CLOSED);

      if (this.selectedReason !== null) {
        interaction.setDetails(this.reasons[this.selectedReason]);
      }

      const request = new TrackTipInteractionRequest();
      request.setInteraction(interaction);

      await advisoryServiceClient.trackTipInteraction(request, {});

      this.showCloseTipDialog = false;
      this.trackInteractionCall = success(void 0);

      tips.getTips();
    } catch (error) {
      this.trackInteractionCall = failure(userErrorFrom(error));
    }
  }

  async clickedOnButton(): Promise<void> {
    if (!this.tip) {
      return;
    }

    const interaction = new TipInteraction();
    interaction.setTipId(this.tip.getId());
    interaction.setKind(TipInteraction.Kind.LINK_OPENED);

    const request = new TrackTipInteractionRequest();
    request.setInteraction(interaction);

    await advisoryServiceClient.trackTipInteraction(request, {});
  }
}
