
import { paths } from "@/router/routes";
import { Component, Vue } from "vue-property-decorator";
import { articleList, BlogArticle } from "./blog-articles";

@Component
export default class Blog extends Vue {
  showImages: boolean = true;

  blogArticles: Map<string, BlogArticle> = articleList;

  paths = paths;

  metaInfo(): { title: string } {
    return {
      title: "Blog",
    };
  }

  resizeEventHandler(): void {
    this.showImages = window.innerWidth > 960;
  }
  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }
  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }
  beforeMount(): void {
    this.resizeEventHandler();
  }
}
