
import { formatNumerOptional } from "@/utils/number-utils";

import { Vue, Component, Prop } from "vue-property-decorator";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import DeleteTariff from "./DeleteTariff.vue";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import AddEditTariff from "./AddEditTariff.vue";

@Component({
  components: {
    DeleteTariff,
    AddEditTariff,
  },
})
export default class TariffCard extends Vue {
  @Prop() deviceMedium: Device.Medium;
  @Prop() tariff: Tariff;
  @Prop() spaceId: string;
  @Prop({ default: true }) userHasEditPermissions: boolean;

  tariffToUpdate: Tariff | null = null;

  zfProtoDeviceMedium = Device.Medium;
  showUpdateTariffDialog = false;
  showDeleteTariffDialog = false;

  get tariffContent(): string[][] {
    const content = [["Anbieter", this.tariff.getSupplier()]];

    const startDate = this.tariff.getContract()?.getStartDate();
    if (startDate) {
      content.push(["Startdatum", new Date(startDate).toLocaleDateString()]);
    }

    content.push(
      ...this.tariff
        .getPriceComponentsList()
        .map((pc) => [
          pc.getName(),
          `${pc.getValue() ? formatNumerOptional(pc.getValue()) : ""} ${pc.getUnitDescription()}`,
        ])
    );

    return content;
  }
}
