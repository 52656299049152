
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { RemoveUserFromSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";

@Component
export default class RemoveUserDialog extends Vue {
  @Prop({ default: false }) value: boolean;
  @Prop({ default: null }) user: User | null;
  @Prop({ default: null }) spaceId: string | null;
  openDialog: boolean = false;
  removeUserCall: RemoteCall<UserError> = initialized;

  get userName(): string {
    return this.user?.getName() ?? "";
  }

  @Watch("value")
  openDialogFunction(): void {
    this.openDialog = this.value;
  }

  async removeUser(): Promise<void> {
    if (this.user?.getId() && this.spaceId) {
      this.removeUserCall = pending;
      const request = new RemoveUserFromSpaceRequest();
      request.setSpaceId(this.spaceId);
      request.setUserId(this.user.getId());
      try {
        await spaceServiceClient.removeUserFromSpace(request, {});
        this.removeUserCall = success(void 0);
        this.$emit("user-removed");
      } catch (error) {
        this.removeUserCall = failure(userErrorFrom(error));
      }
    }
  }
}
