
import { userServiceClient } from "@/config/service-clients";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  CancelSubscriptionRequest,
  CreateSubscriptionRequest,
  GetCurrentSubscriptionPlanRequest,
  GetSubscriptionPlansRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/subscription_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";

export interface UserDetailsData {
  title: string;
  value: string;
  editKey?: string;
}

@Component({
  components: {},
})
export default class UserDetails extends Vue {
  @Prop({ default: initialized }) userInfo: RemoteData<UserError, User>;

  subscriptionPlan: SubscriptionPlan | null = null;
  subscriptionPlans: RemoteData<UserError, SubscriptionPlan[]> = initialized;
  addSubscriptionDialog = false;
  cancelSubscriptionDialog = false;
  subscriptionPlanToAdd: string | null = null;
  editSubscriptionCall: RemoteCall<UserError> = initialized;

  detailsItems: UserDetailsData[] = [];

  get userDetails(): UserDetailsData[] {
    return [
      {
        title: "Name",
        value: this.userInfo?.data?.getUserProfileInfo()?.getName() ?? "",
      },
      {
        title: "Email",
        value: this.userInfo?.data?.getEmail() ?? "",
      },
      {
        title: "Letzte Anmeldung",
        value: moment
          .unix(this.userInfo?.data?.getUserProfileInfo()?.getLastLogin() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
      },
      {
        title: "Erste Anmeldung",
        value: moment
          .unix(this.userInfo?.data?.getUserProfileInfo()?.getCreatedAt() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
      },
      {
        title: "Anzahl der Anmeldungen",
        value: (this.userInfo?.data?.getUserProfileInfo()?.getLoginCount() ?? 0).toString(),
      },
      ...(this.subscriptionPlan
        ? [
            {
              title: "Abo",
              value: this.subscriptionPlan?.getName(),
              editKey: "subscription",
            },
          ]
        : []),
    ];
  }

  get subscriptionPlanItems() {
    return (
      this.subscriptionPlans.data?.map((p) => ({
        text: p.getName(),
        value: p.getId(),
      })) ?? []
    );
  }

  mounted() {
    this.getSubscriptionPlan();
  }

  editDetail(key: string) {
    if (key === "subscription") {
      if (this.subscriptionPlan?.getDefaultPlan()) {
        this.addSubscriptionDialog = true;
        this.getSubscriptionPlans();
      } else {
        this.cancelSubscriptionDialog = true;
      }
    }
  }

  async addSubscription() {
    const planId = this.subscriptionPlanToAdd;

    if (planId) {
      const request = new CreateSubscriptionRequest();
      request.setPlanId(planId);
      request.setUserId(this.userInfo.data?.getId() ?? "");

      try {
        this.editSubscriptionCall = pending;
        await userServiceClient.createSubscription(request, {});
        this.getSubscriptionPlan();
        this.addSubscriptionDialog = false;
        this.editSubscriptionCall = success(void 0);
      } catch (error) {
        this.editSubscriptionCall = failure(userErrorFrom(error));
      }
    }
  }

  async cancelSubscription() {
    const request = new CancelSubscriptionRequest();
    request.setUserId(this.userInfo.data?.getId() ?? "");

    try {
      this.editSubscriptionCall = pending;
      await userServiceClient.cancelSubscription(request, {});
      this.editSubscriptionCall = success(void 0);
      this.getSubscriptionPlan();
      this.cancelSubscriptionDialog = false;
    } catch (error) {
      this.editSubscriptionCall = failure(userErrorFrom(error));
    }
  }

  async getSubscriptionPlan() {
    const request = new GetCurrentSubscriptionPlanRequest();
    request.setUserId(this.userInfo.data?.getId() ?? "");

    const response = await userServiceClient.getCurrentSubscriptionPlan(request, {});

    this.subscriptionPlan = response.getSubscriptionPlan() ?? null;
  }

  async getSubscriptionPlans() {
    try {
      this.subscriptionPlans = pending;
      const request = new GetSubscriptionPlansRequest();
      request.setPartnerId(this.userInfo.data?.getPartnerId() ?? "");
      const response = await userServiceClient.getSubscriptionPlans(request, {});
      this.subscriptionPlans = success(response.getSubscriptionPlansList());
    } catch (error) {
      this.subscriptionPlans = failure(userErrorFrom(error));
    }
  }
}
