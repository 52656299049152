
import { paths } from "@/router/routes";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import moment from "moment";
import { TableControl } from "../spaces-table/SpacesTable.vue";
import { SearchParams } from "../devices-table/DevicesTable.vue";

export interface UserRow {
  id: string;
  name: string;
  email: string;
  picture: string;
  lastLogin: string;
  loginCount: number;
  user: User;
}

@Component
export default class UsersTable extends Vue {
  @Prop({ default: 10 }) userPages: RemoteData<UserError, number>;
  @Prop() userInfo: RemoteData<UserError, User[]>;
  @Prop({ default: false }) isAdminUsersView: boolean;
  @Prop({ default: false }) hideSearchBar: boolean;
  @Prop({ default: true }) showPagination: boolean;
  @Prop({ default: () => [] }) itemControls: TableControl[];
  @Prop({ default: () => [] }) headerControls: TableControl[];
  @Prop({ default: 10 }) itemsPerPage: number;

  searchParams: SearchParams = {
    search: "",
    page: 1,
  };

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  get headers(): { text: string; value: string }[] {
    if (this.isAdminUsersView) {
      return [
        { text: "", value: "picture" },
        { text: "Nutzername", value: "name" },
        { text: "E-Mail", value: "email" },
        { text: "", value: "controls" },
      ];
    } else {
      return [
        { text: "", value: "picture" },
        { text: "Nutzername", value: "name" },
        { text: "E-Mail", value: "email" },
        { text: "Letzte Anmeldung", value: "lastLogin" },
        { text: "Anzahl der Anmeldung", value: "loginCount" },
        { text: "", value: "controls" },
      ];
    }
  }

  mounted(): void {
    const pageQuery = this.$route.query.page;
    this.searchParams.search = (this.$route.query.search as string) ?? "";
    this.searchParams.page = typeof pageQuery === "string" ? parseInt(pageQuery, 10) : 1;
  }

  @Watch("searchParams.page")
  onPageChanged(): void {
    this.updateRoute();
    this.$emit("search-params-input", this.searchParams);
  }
  @Watch("searchParams.search")
  async onSearchChanged(): Promise<void> {
    this.updateRoute();
    if (this.searchParams.search === "") {
      this.$emit("search-params-input", this.searchParams);
    } else {
      const searchTerm = this.searchParams.search;
      await this.sleep(500);
      if (searchTerm === this.searchParams.search) {
        this.$emit("search-params-input", this.searchParams);
      }
    }
  }

  updateRoute(): void {
    this.$router.push({
      path: paths.admin.users,
      query: { page: this.searchParams.page.toString(), search: this.searchParams.search },
    });
  }

  handleRowClick(user: { id: string; name: string; email: string }): void {
    this.$router.push({
      path: `${paths.admin.users}/${user.id}`,
    });
  }

  get users(): UserRow[] {
    let partnerUsers: UserRow[] = [];
    this.userInfo.list.forEach((u) => {
      partnerUsers.push({
        id: u.getId(),
        name: u.getName(),
        email: u.getEmail(),
        picture: u.getUserProfileInfo()?.getPicture() ?? "",
        loginCount: u.getUserProfileInfo()?.getLoginCount() ?? 0,
        lastLogin: moment
          .unix(u.getUserProfileInfo()?.getLastLogin() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
        user: u,
      });
    });
    return partnerUsers;
  }

  get totalPages(): number {
    return this.userPages.data ?? 1;
  }
  onItemControlsClicked(event: Event, user: UserRow, customEvent: string) {
    event.stopPropagation();
    this.$emit(customEvent, user);
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }
}
