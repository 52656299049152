
import { Component, Vue } from "vue-property-decorator";
import misc, { miscModule } from "@/store/modules/misc";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { paths } from "@/router/routes";
import { emailRules } from "@/utils/rules";

@Component
export default class Newsletter extends Vue {
  email: string = "";
  invalidEmail: boolean = false;
  emailRules = emailRules;

  @miscModule.State subscribeToNewsletterCall: RemoteCall<UserError>;

  paths = paths;

  get subscribeBtnDisabled(): boolean {
    return (
      this.subscribeToNewsletterCall.pending ||
      this.subscribeToNewsletterCall.succeeded ||
      emailRules.some((rule) => typeof rule(this.email) === "string")
    );
  }

  subscribeToNewsletter(): void {
    misc.subscribeToNewsletter(this.email);
  }
}
