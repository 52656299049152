
import { Component, Vue } from "vue-property-decorator";
import { userServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { paths } from "@/router/routes";
import { UserError, userErrorFrom } from "@/types/user-error";
import { partnerAuthConfig } from "@/config/auth-config";
import { setDemoToken } from "@/utils/login-utils";
import { CreateDemoTokenRequest } from "zaehlerfreunde-central/user_service_pb";

@Component({})
export default class DemoPage extends Vue {
  demoTokenCall: RemoteCall<UserError> = initialized;

  async mounted(): Promise<void> {
    const authConfig = await partnerAuthConfig;
    const partnerId = authConfig.getPartnerId();
    await this.getDemoToken(partnerId);
  }

  async getDemoToken(id: string): Promise<void> {
    const request = new CreateDemoTokenRequest();
    request.setPartnerId(id ?? "zaehlerfreunde");

    try {
      this.demoTokenCall = pending;
      const response = await userServiceClient.createDemoToken(request, {});
      const token = success(response.getToken());
      this.demoTokenCall = success(void 0);

      if (token.data) {
        setDemoToken(token.data);
        this.$router.push(paths.platform.dashboard);
      }
    } catch (error) {
      this.demoTokenCall = failure(userErrorFrom(error));
    }
  }
}
