
import { userServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import moment from "moment";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Invite } from "zaehlerfreunde-proto-types/invite_pb";
import { DeleteInviteRequest } from "zaehlerfreunde-central/user_service_pb";
import { TableControl } from "../spaces-table/SpacesTable.vue";

export interface InviteRow {
  id: string;
  spaceId?: string;
  email: string;
  expiresIn: string;
  accepted: string;
}

@Component({})
export default class InvitesTable extends Vue {
  @Prop() invites: RemoteData<UserError, Invite[]>;
  @Prop() totalNumPages: RemoteData<UserError, number>;
  @Prop({ default: 10 }) itemsPerPage: number;

  page: number = 1;

  @Prop({ default: false }) showEmailSearch: boolean;
  @Prop({ default: false }) clickableRows: boolean;

  @Prop({ default: () => [] }) headerControls: TableControl[];

  search: string = "";
  searchTimeoutHandle: number | null = null;

  showDeleteInviteDialog: boolean = false;
  inviteToDelete: InviteRow | null = null;

  deleteInviteCall: RemoteCall<UserError> = initialized;

  headers: { text: string; value: string }[] = [
    { text: "E-Mail", value: "email" },
    { text: "Angenommen", value: "accepted" },
    { text: "Läuft ab", value: "expiresIn" },
    { text: "", value: "controls" },
  ];

  @Watch("page")
  onPageChanged(): void {
    this.$emit("page-clicked", this.page - 1);
  }

  @Watch("search")
  onSearchClicked(): void {
    if (this.searchTimeoutHandle) {
      clearTimeout(this.searchTimeoutHandle);
    }

    this.searchTimeoutHandle = setTimeout(() => this.$emit("search-clicked", this.search), 500);
  }

  get inviteRows(): InviteRow[] {
    return this.invites.list.map((s: Invite) => {
      const expiresAt = moment.unix(s.getExpiresAt());

      return {
        id: s.getId() ?? "",
        spaceId: s.getSpaceId(),
        email: s.getEmail(),
        expiresIn: s.getAccepted() ? "-" : expiresAt.isAfter(new Date()) ? expiresAt.fromNow() : "Abgelaufen",
        accepted: s.getAccepted() ? "Ja" : "Nein",
      };
    });
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }

  onDeleteInviteClicked(invite: InviteRow) {
    this.inviteToDelete = invite;
    this.showDeleteInviteDialog = true;
  }

  async deleteInvite(): Promise<void> {
    this.deleteInviteCall = pending;

    try {
      await userServiceClient.deleteInvite(new DeleteInviteRequest().setInviteId(this.inviteToDelete?.id ?? ""), {});
      this.deleteInviteCall = success(void 0);
      this.showDeleteInviteDialog = false;
      this.inviteToDelete = null;
      this.$emit("page-clicked", this.page - 1);
    } catch (error) {
      this.deleteInviteCall = failure(userErrorFrom(error));
    }
  }
}
