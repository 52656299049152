// source: types/latest_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.LatestStatus', null, global);
goog.exportSymbol('proto.types.LatestStatus.Info', null, global);
goog.exportSymbol('proto.types.LatestStatus.Value', null, global);
goog.exportSymbol('proto.types.LatestStatusHandle', null, global);
goog.exportSymbol('proto.types.LatestStatusHandle.IdCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LatestStatusHandle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.LatestStatusHandle.oneofGroups_);
};
goog.inherits(proto.types.LatestStatusHandle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LatestStatusHandle.displayName = 'proto.types.LatestStatusHandle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LatestStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.LatestStatus.repeatedFields_, null);
};
goog.inherits(proto.types.LatestStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LatestStatus.displayName = 'proto.types.LatestStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LatestStatus.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LatestStatus.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LatestStatus.Value.displayName = 'proto.types.LatestStatus.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LatestStatus.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LatestStatus.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LatestStatus.Info.displayName = 'proto.types.LatestStatus.Info';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.LatestStatusHandle.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.types.LatestStatusHandle.IdCase = {
  ID_NOT_SET: 0,
  SPACE_ID: 1,
  DEVICE_ID: 2
};

/**
 * @return {proto.types.LatestStatusHandle.IdCase}
 */
proto.types.LatestStatusHandle.prototype.getIdCase = function() {
  return /** @type {proto.types.LatestStatusHandle.IdCase} */(jspb.Message.computeOneofCase(this, proto.types.LatestStatusHandle.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LatestStatusHandle.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LatestStatusHandle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LatestStatusHandle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatusHandle.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LatestStatusHandle}
 */
proto.types.LatestStatusHandle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LatestStatusHandle;
  return proto.types.LatestStatusHandle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LatestStatusHandle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LatestStatusHandle}
 */
proto.types.LatestStatusHandle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LatestStatusHandle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LatestStatusHandle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LatestStatusHandle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatusHandle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.types.LatestStatusHandle.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatusHandle} returns this
 */
proto.types.LatestStatusHandle.prototype.setSpaceId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.types.LatestStatusHandle.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.LatestStatusHandle} returns this
 */
proto.types.LatestStatusHandle.prototype.clearSpaceId = function() {
  return jspb.Message.setOneofField(this, 1, proto.types.LatestStatusHandle.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LatestStatusHandle.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.types.LatestStatusHandle.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatusHandle} returns this
 */
proto.types.LatestStatusHandle.prototype.setDeviceId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.types.LatestStatusHandle.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.LatestStatusHandle} returns this
 */
proto.types.LatestStatusHandle.prototype.clearDeviceId = function() {
  return jspb.Message.setOneofField(this, 2, proto.types.LatestStatusHandle.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LatestStatusHandle.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.LatestStatus.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LatestStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LatestStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LatestStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.types.LatestStatus.Value.toObject, includeInstance),
    info: (f = msg.getInfo()) && proto.types.LatestStatus.Info.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deviceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    allowManualReading: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LatestStatus}
 */
proto.types.LatestStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LatestStatus;
  return proto.types.LatestStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LatestStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LatestStatus}
 */
proto.types.LatestStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.types.LatestStatus.Value;
      reader.readMessage(value,proto.types.LatestStatus.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 4:
      var value = new proto.types.LatestStatus.Info;
      reader.readMessage(value,proto.types.LatestStatus.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowManualReading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LatestStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LatestStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LatestStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.types.LatestStatus.Value.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.types.LatestStatus.Info.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAllowManualReading();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LatestStatus.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LatestStatus.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LatestStatus.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatus.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueFormatted: jspb.Message.getFieldWithDefault(msg, 7, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LatestStatus.Value}
 */
proto.types.LatestStatus.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LatestStatus.Value;
  return proto.types.LatestStatus.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LatestStatus.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LatestStatus.Value}
 */
proto.types.LatestStatus.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFormatted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LatestStatus.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LatestStatus.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LatestStatus.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatus.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueFormatted();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string value_formatted = 7;
 * @return {string}
 */
proto.types.LatestStatus.Value.prototype.getValueFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus.Value} returns this
 */
proto.types.LatestStatus.Value.prototype.setValueFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.types.LatestStatus.Value.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus.Value} returns this
 */
proto.types.LatestStatus.Value.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.types.LatestStatus.Value.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus.Value} returns this
 */
proto.types.LatestStatus.Value.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LatestStatus.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LatestStatus.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LatestStatus.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatus.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isError: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LatestStatus.Info}
 */
proto.types.LatestStatus.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LatestStatus.Info;
  return proto.types.LatestStatus.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LatestStatus.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LatestStatus.Info}
 */
proto.types.LatestStatus.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LatestStatus.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LatestStatus.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LatestStatus.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LatestStatus.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsError();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string content = 1;
 * @return {string}
 */
proto.types.LatestStatus.Info.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus.Info} returns this
 */
proto.types.LatestStatus.Info.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_error = 2;
 * @return {boolean}
 */
proto.types.LatestStatus.Info.prototype.getIsError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.LatestStatus.Info} returns this
 */
proto.types.LatestStatus.Info.prototype.setIsError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.types.LatestStatus.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.LatestStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Value values = 3;
 * @return {!Array<!proto.types.LatestStatus.Value>}
 */
proto.types.LatestStatus.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.types.LatestStatus.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.LatestStatus.Value, 3));
};


/**
 * @param {!Array<!proto.types.LatestStatus.Value>} value
 * @return {!proto.types.LatestStatus} returns this
*/
proto.types.LatestStatus.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.types.LatestStatus.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.LatestStatus.Value}
 */
proto.types.LatestStatus.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.types.LatestStatus.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional Info info = 4;
 * @return {?proto.types.LatestStatus.Info}
 */
proto.types.LatestStatus.prototype.getInfo = function() {
  return /** @type{?proto.types.LatestStatus.Info} */ (
    jspb.Message.getWrapperField(this, proto.types.LatestStatus.Info, 4));
};


/**
 * @param {?proto.types.LatestStatus.Info|undefined} value
 * @return {!proto.types.LatestStatus} returns this
*/
proto.types.LatestStatus.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LatestStatus.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.types.LatestStatus.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string device_id = 6;
 * @return {string}
 */
proto.types.LatestStatus.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.setDeviceId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.clearDeviceId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LatestStatus.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool allow_manual_reading = 7;
 * @return {boolean}
 */
proto.types.LatestStatus.prototype.getAllowManualReading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.LatestStatus} returns this
 */
proto.types.LatestStatus.prototype.setAllowManualReading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


goog.object.extend(exports, proto.types);
