
import { Vue, Component, Prop } from "vue-property-decorator";
import PieChart from "./PieChart.vue";
import Chart from "chart.js";
import { Report } from "zaehlerfreunde-proto-types/reporting_pb";
import { formatNumber } from "@/utils/number-utils";

@Component({
  components: {
    PieChart,
  },
})
export default class Breakdown extends Vue {
  @Prop() breakdown: Report.Breakdown;

  get chartData(): Chart.ChartData {
    return {
      labels: this.breakdown.getEntitiesList(),
      datasets: [
        {
          data: this.breakdown.getValuesList(),
        },
      ],
    };
  }

  get chartOptions(): Chart.ChartOptions {
    return {
      tooltips: {
        callbacks: {
          label: (item) => {
            return `${formatNumber(this.breakdown.getValuesList()[item.index ?? 0])} ${this.breakdown.getUnit()}`;
          },
        },
      },
      legend: {
        position: "bottom",
      },
    };
  }
}
