
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { advisoryServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TriggerAutomationRequest } from "zaehlerfreunde-central/advisory_service_pb";

@Component
export default class TriggerAutomation extends Vue {
  @Prop() automationId: string;

  triggerAutomationCall: RemoteCall<UserError> = initialized;
  showDialog: boolean = false;

  @Watch("automationId")
  onAutomationIdChanged(): void {
    if (this.automationId != "") {
      this.showDialog = true;
    }
  }

  hideDialog(): void {
    this.showDialog = false;
    this.$emit("closed");
  }

  async onConfirm(): Promise<void> {
    if (this.automationId) {
      this.triggerAutomationCall = pending;
      const request = new TriggerAutomationRequest();
      request.setAutomationId(this.automationId);

      try {
        await advisoryServiceClient.triggerAutomation(request, null);
        this.triggerAutomationCall = success(void 0);
        this.$emit("automation-triggered");

        this.hideDialog();
      } catch (error) {
        this.triggerAutomationCall = failure(userErrorFrom(error));
      }
    }
  }
}
