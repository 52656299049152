
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import SelectSpaceCategory from "@/components/create-space/SelectSpaceCategory.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { SpaceCategoryItem, UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";

@Component({
  components: {
    SelectSpaceCategory,
  },
})
export default class ChangeCategory extends Vue {
  @Prop() value: boolean;

  selectedCategory: SpaceCategoryItem | null = null;
  updateCategoryCall: RemoteCall<UserError> = initialized;

  async updateSpaceCategory(): Promise<void> {
    if (this.selectedCategory) {
      const request = new UpdateSpaceRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setCategory(this.selectedCategory.getCategory());

      this.updateCategoryCall = pending;

      try {
        await spaceServiceClient.updateSpace(request, {});
        this.updateCategoryCall = success(void 0);
        spaces.getSpaces();
        this.$emit("input", false);
      } catch (error) {
        this.updateCategoryCall = failure(userErrorFrom(error));
      }
    }
  }
}
