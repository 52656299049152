
import devices from "@/store/modules/devices";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DeleteDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class DeleteDevice extends Vue {
  @Prop() device: Device | null;

  deleteDeviceCall: RemoteCall<UserError> = initialized;

  showDialog: boolean = false;
  DeviceProvider = Device.Provider;
  pinCode = "";
  showPW = false;

  get infoCompleted(): boolean {
    return this.pinCode.length == 4;
  }

  @Watch("device")
  onDeviceChanged(): void {
    this.showDialog = true;
  }

  hideDialog(): void {
    this.showDialog = false;
    this.$emit("closed");
  }

  async onDeleteDeviceConfirmed(deleteForAll: boolean): Promise<void> {
    if (this.device) {
      const request = new DeleteDeviceRequest();
      request.setDeviceId(this.device.getId());
      request.setSpaceId(spaces.selectedSpaceId);
      request.setDeleteForAll(deleteForAll);
      request.setEaseePinCode(this.pinCode);

      try {
        this.deleteDeviceCall = pending;
        await deviceServiceClient.deleteDevice(request, {});
        this.deleteDeviceCall = success(void 0);

        this.hideDialog();
        devices.getDevices();
      } catch (error) {
        this.deleteDeviceCall = failure(userErrorFrom(error));
      }
    }
  }
}
