import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { apiClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { namespace } from "vuex-class";
import {
  BlueMeteringApplication,
  FaqTopic,
  LoadFAQContentRequest,
  SubscribeToNewsletterRequest,
} from "zaehlerfreunde-central/misc_service_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "misc",
})
class MiscModule extends VuexModule {
  faqTopics: RemoteData<UserError, FaqTopic[]> = initialized;
  subscribeToNewsletterCall: RemoteCall<UserError> = initialized;
  blueMeteringApplicationCall: RemoteCall<UserError> = initialized;

  @Action
  async subscribeToNewsletter(email: string) {
    try {
      const request = new SubscribeToNewsletterRequest();
      request.setEmail(email);

      this.setSubscribeToNewsletterCall(pending);
      await apiClient.subscribeToNewsletter(request, null);
      this.setSubscribeToNewsletterCall(success(void 0));
    } catch (error) {
      this.setSubscribeToNewsletterCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async loadFAQContent() {
    try {
      this.setFaqTopics(pending);
      const request = new LoadFAQContentRequest();
      const response = await apiClient.loadFAQContent(request, null);
      this.setFaqTopics(success(response.getTopicsList()));
    } catch (error) {
      this.setFaqTopics(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setFaqTopics(topics: RemoteData<UserError, FaqTopic[]>) {
    this.faqTopics = topics;
  }

  @Mutation
  setSubscribeToNewsletterCall(subscribeToNewsletterCall: RemoteCall<UserError>) {
    this.subscribeToNewsletterCall = subscribeToNewsletterCall;
  }

  @Mutation
  setBlueMeteringApplicationCall(blueMeteringApplicationCall: RemoteCall<UserError>) {
    this.blueMeteringApplicationCall = blueMeteringApplicationCall;
  }
}

export const miscModule = namespace("misc");
export default getModule(MiscModule);
