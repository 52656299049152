
import ValueWithUnit from "@/components/core/ValueWithUnit.vue";
import { spaceServiceClient } from "@/config/service-clients";
import { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { parseFloatOptional } from "@/utils/number-utils";
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  DeleteReferenceValueRequest,
  GetReferenceValuesRequest,
  GetReferenceValueTypesRequest,
  SetReferenceValueRequest,
} from "zaehlerfreunde-central/space_service_pb";
import { ReferenceValue } from "zaehlerfreunde-proto-types/reference_values_pb";

@Component({
  components: {
    ValueWithUnit,
  },
})
export default class ReferenceValueSettings extends Vue {
  @spacesModule.Getter selectedSpaceId: string;
  referenceValues: RemoteData<UserError, ReferenceValue[]> = initialized;
  referenceValueTypes: RemoteData<UserError, ReferenceValue.TypeDescription[]> = initialized;
  setReferenceValueCall: RemoteCall<UserError> = initialized;
  deleteReferenceValueCall: RemoteCall<UserError> = initialized;

  showSetReferenceValueDialog = false;
  isExistingReferenceValue = false;
  selectedValueType: ReferenceValue.Type | null = null;
  newValue: string | null = null;

  get valueTypeOptions() {
    return this.referenceValueTypes.list.map((r: ReferenceValue.TypeDescription) => ({
      value: r.getType(),
      text: r.getName(),
    }));
  }

  get selectedValueTypeUnit(): string {
    return this.referenceValueTypes.list.find((vt) => vt.getType() === this.selectedValueType)?.getUnit() ?? "";
  }

  mounted(): void {
    this.loadReferenceValues();
    this.loadReferenceValueTypes();
  }

  toggleSetReferenceValueTypeDialog(referenceValue?: ReferenceValue) {
    this.selectedValueType = referenceValue?.getType() ?? null;
    this.newValue = referenceValue?.getValue().toString() ?? null;
    this.isExistingReferenceValue = !!referenceValue;

    this.showSetReferenceValueDialog = true;
  }

  async setReferenceValue(): Promise<void> {
    const value = parseFloatOptional(this.newValue ?? "");
    const valueType = this.selectedValueType;

    if (value && valueType !== null) {
      const referenceValue = new ReferenceValue();
      referenceValue.setType(valueType);
      referenceValue.setValue(value);

      const request = new SetReferenceValueRequest();
      request.setReferenceValue(referenceValue);
      request.setSpaceId(this.selectedSpaceId);

      try {
        this.setReferenceValueCall = pending;
        await spaceServiceClient.setReferenceValue(request, {});
        this.setReferenceValueCall = success(void 0);
        this.showSetReferenceValueDialog = false;
        this.loadReferenceValues();
      } catch (error) {
        this.setReferenceValueCall = failure(userErrorFrom(error));
      }
    }
  }

  async deleteReferenceValue(): Promise<void> {
    const valueType = this.selectedValueType;

    if (valueType !== null) {
      const request = new DeleteReferenceValueRequest();
      request.setReferenceValueType(valueType);
      request.setSpaceId(this.selectedSpaceId);

      try {
        this.deleteReferenceValueCall = pending;
        await spaceServiceClient.deleteReferenceValue(request, {});
        this.deleteReferenceValueCall = success(void 0);
        this.showSetReferenceValueDialog = false;
        this.loadReferenceValues();
      } catch (error) {
        this.deleteReferenceValueCall = failure(userErrorFrom(error));
      }
    }
  }

  @Watch("selectedSpaceId")
  async loadReferenceValues(): Promise<void> {
    const request = new GetReferenceValuesRequest();
    request.setSpaceId(this.selectedSpaceId);

    try {
      this.referenceValues = pending;
      const response = await spaceServiceClient.getReferenceValues(request, {});
      this.referenceValues = success(response.getReferenceValuesList());
    } catch (error) {
      this.referenceValues = failure(userErrorFrom(error));
    }
  }

  async loadReferenceValueTypes(): Promise<void> {
    const request = new GetReferenceValueTypesRequest();

    try {
      this.referenceValueTypes = pending;
      const response = await spaceServiceClient.getReferenceValueTypes(request, {});
      this.referenceValueTypes = success(response.getTypesList());
    } catch (error) {
      this.referenceValueTypes = failure(userErrorFrom(error));
    }
  }
}
