
import { Component, Vue } from "vue-property-decorator";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import simulations, { simulationModule } from "@/store/modules/simulations";
import { LoadMiniPVSimulationRequest } from "zaehlerfreunde-central/advisory_service_pb";
import MiniPVProductResult from "./MiniPVProductResult.vue";
import { themeModule } from "@/store/modules/theme";
import { paths } from "@/router/routes";
import { MiniPVProductSimulation, MiniPVSimulationResult } from "zaehlerfreunde-proto-types/simulations_pb";

export function getElementsPerPage(): number {
  if (window.innerWidth > 900) {
    return 3;
  } else if (window.innerWidth > 600) {
    return 2;
  } else {
    return 1;
  }
}

@Component({
  components: {
    MiniPVProductResult,
  },
})
export default class MiniPVSimulationResults extends Vue {
  @simulationModule.State simulationResult: RemoteData<UserError, MiniPVSimulationResult>;
  @simulationModule.State simulationCall: RemoteCall<UserError>;
  @themeModule.Getter apperance: "light" | "dark";

  paths = paths;

  elementsPerPage: number = getElementsPerPage();
  orientationLabels: string[] = [
    "Norden",
    "Nord-Osten",
    "Osten",
    "Süd-Osten",
    "Süden",
    "Süd-Westen",
    "Westen",
    "Nord-Westen",
  ];
  slopeLabels: string[] = ["0°", "15°", "30°", "45°", "60°", "75°", "90°"];
  mountingLabels: string[] = ["Balkon", "Garten", "Sonstiges", "Fassade", "Schrägdach"];

  mounted(): void {
    const request = new LoadMiniPVSimulationRequest();
    request.setSimulationId(this.$route.params.id);
    simulations.loadMiniPVSimulation(request);
  }

  get resultPages(): MiniPVProductSimulation[][] {
    var pages: MiniPVProductSimulation[][] = [];

    for (var i = 0; i < this.productSimulationResults.length; i += this.elementsPerPage) {
      pages.push(this.productSimulationResults.slice(i, i + this.elementsPerPage));
    }

    return pages;
  }

  get productSimulationResults(): MiniPVProductSimulation[] {
    if (this.simulationResult.data) {
      return this.simulationResult.data?.getResultsList();
    }
    return [];
  }

  get simulation(): MiniPVSimulationResult {
    if (this.simulationResult.data) {
      return this.simulationResult.data;
    } else {
      const emptyResult = new MiniPVSimulationResult();
      return emptyResult;
    }
  }

  get simulationDetails(): undefined | string {
    return this.simulationResult.data?.getDetails();
  }

  resizeEventHandler(): void {
    this.elementsPerPage = getElementsPerPage();
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }
}
