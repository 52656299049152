
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component } from "vue-property-decorator";
import { GetMetergridProjectsRequest } from "zaehlerfreunde-central/device_service_pb";

@Component
export default class MetergridApiCredentials extends Vue {
  call: RemoteCall<UserError> = initialized;

  username: string = "";
  password: string = "";

  async onNextClicked(): Promise<void> {
    try {
      this.call = pending;

      const request = new GetMetergridProjectsRequest();
      request.setUsername(this.username);
      request.setPassword(this.password);

      const response = await deviceServiceClient.getMetergridProjects(request, {});
      this.$emit("next", response);

      this.call = success(void 0);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }
}
