import { render, staticRenderFns } from "./WallboxOcppInstruction.vue?vue&type=template&id=54e9d264&scoped=true"
import script from "./WallboxOcppInstruction.vue?vue&type=script&lang=ts"
export * from "./WallboxOcppInstruction.vue?vue&type=script&lang=ts"
import style0 from "./WallboxOcppInstruction.vue?vue&type=style&index=0&id=54e9d264&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e9d264",
  null
  
)

export default component.exports