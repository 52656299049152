
import { deviceServiceClient } from "@/config/service-clients";
import { RemoteCall, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteViessmannClientInfoRequest } from "zaehlerfreunde-central/device_service_pb";
import { paths } from "@/router/routes";
@Component
export default class ResetViessmannClientDialog extends Vue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop({ default: false }) isAdminAction: boolean;

  deleteCall: RemoteCall<UserError> = initialized;
  async deleteViessmannApiClient(): Promise<void> {
    try {
      this.deleteCall = pending;
      const request = new DeleteViessmannClientInfoRequest();
      await deviceServiceClient.deleteViessmannClientInfo(request, {});
      this.deleteCall = success(void 0);
      this.isAdminAction ? this.$router.push(paths.admin.devices) : this.$router.push(paths.platform.devices);
    } catch (error) {
      this.deleteCall = failure(userErrorFrom(error));
    }
  }
}
