
import { RemoteCall, initialized } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import ExportDataDialog from "./ExportDataDialog.vue";

@Component({
  components: {
    ExportDataDialog,
  },
})
export default class ExportData extends Vue {
  call: RemoteCall<UserError> = initialized;
  date: Date | null = null;
  exportFormats: { text: string; value: string }[] = [{ text: "E3", value: "E3_XAP_EXPORT" }];
  selectedFormat: string | null = null;
  showExportDataDialog: boolean = false;

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }
}
