
import { Vue, Component, Prop } from "vue-property-decorator";

export interface TableEntry {
  value: string;
  bold?: boolean;
}

@Component
export default class Table extends Vue {
  @Prop() data: TableEntry[][];
}
