
import { partnerServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteClientSecretRequest } from "zaehlerfreunde-central/partner_service_pb";
import { ClientSecret } from "zaehlerfreunde-proto-types/api_token_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class DeleteClientSecret extends Vue {
  @Prop() clientSecret: ClientSecret | null;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  deleteSecretCall: RemoteCall<UserError> = initialized;

  async deleteSecret(): Promise<void> {
    if (this.clientSecret) {
      try {
        this.deleteSecretCall = pending;
        const request = new DeleteClientSecretRequest();
        request.setClientSecretId(this.clientSecret.getId());

        if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

        await partnerServiceClient.deleteClientSecret(request, {});
        this.deleteSecretCall = success(void 0);
        this.$emit("secret-deleted");
        this.$emit("input", false);
      } catch (error) {
        this.deleteSecretCall = failure(userErrorFrom(error));
      }
    }
  }
}
