
import { paths } from "@/router/routes";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SearchResult } from "zaehlerfreunde-proto-types/tariff_pb";

@Component
export default class TariffFound extends Vue {
  @Prop({ default: null }) tariffSearchResult: SearchResult;

  onOfferClicked(): void {
    this.$router.push(paths.platform.tariffSwitch);
  }
}
