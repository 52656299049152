
import { TimeUnit } from "chart.js";
import moment from "moment";
import { unitOfTime } from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { TimeRange } from "zaehlerfreunde-central/ui_service_pb";
import { TimeInterval } from "zaehlerfreunde-proto-types/time_data_pb";

export class TimePeriod {
  public static DAY = new TimePeriod(0, "Tag", "hour", "day", TimeInterval.CUSTOM);
  public static WEEK = new TimePeriod(1, "Woche", "day", "week", TimeInterval.DAY);
  public static MONTH = new TimePeriod(2, "Monat", "day", "month", TimeInterval.DAY);
  public static YEAR = new TimePeriod(3, "Jahr", "month", "year", TimeInterval.MONTH);

  public static options = [TimePeriod.DAY, TimePeriod.WEEK, TimePeriod.MONTH, TimePeriod.YEAR];

  private constructor(
    public id: number,
    public label: string,
    public xAxesTimeUnit: TimeUnit,
    public timeRange: unitOfTime.StartOf,
    public timeFrame: TimeInterval
  ) {}
}

export interface TimePeriodChangeEvent {
  start: Date;
  end: Date;
  timePeriod: TimePeriod;
}

@Component
export default class TimePeriodPicker extends Vue {
  @Prop({ default: () => TimePeriod.DAY })
  selectedTimePeriod: TimePeriod;

  @Prop()
  showPreviousDay: boolean;

  @Prop()
  availableTimeRange: TimeRange | null;

  timePeriodOptions = TimePeriod.options;
  TimePeriod = TimePeriod;

  selectedTab: 3;
  private menu: boolean = false;
  private isCustomDate: boolean = false;
  private day: string = new Date().toISOString().substr(0, 10);
  private today: string = new Date().toISOString().substr(0, 10);
  private month: string = new Date().toISOString().substr(0, 7);
  private dateRange: string[] = [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)];
  private thisMonth: string = new Date().toISOString().substr(0, 7);
  private year: number = new Date().getFullYear();
  private week: string = this.weeks[0];
  private minGraphDate: string = new Date("2018-01-01").toISOString();

  get maxGraphDate(): Date {
    const endTime = this.availableTimeRange?.getEndTime();
    if (endTime) {
      return new Date(endTime * 1000);
    } else {
      return new Date();
    }
  }

  get maxGraphDateStr(): string {
    return this.maxGraphDate.toISOString().substr(0, 10);
  }

  @Watch("selectedTimePeriod")
  onChange(): void {
    this.day = new Date().toISOString().substr(0, 10);
    this.dateRange = [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)];
    this.month = new Date().toISOString().substr(0, 7);
    this.year = new Date().getFullYear();
    this.week = this.weeks[0];
  }

  @Watch("showPreviousDay")
  onShowPreviousDayChanged() {
    if (this.showPreviousDay) {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      this.day = today.toISOString().substr(0, 10);
    }
  }

  onTimePeriodSelected(timePeriod: TimePeriod): void {
    this.selectedTimePeriod = timePeriod;
    this.$emit("time-period-change", {
      start: moment().startOf(timePeriod.timeRange).toDate(),
      end: moment().endOf(timePeriod.timeRange).toDate(),
      timePeriod,
    } as TimePeriodChangeEvent);
  }

  formatDay(date: string): string {
    return new Date(date).toLocaleDateString("de-DE", { year: "2-digit", month: "2-digit", day: "2-digit" });
  }

  yearSelected(): void {
    this.$emit("time-period-change", {
      start: moment().year(this.year).startOf("year").toDate(),
      end: moment().year(this.year).endOf("year").toDate(),
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  monthSelected(): void {
    const month = new Date(this.month).getMonth();
    const year = new Date(this.month).getFullYear();

    this.$emit("time-period-change", {
      start: moment().year(year).month(month).startOf("month").toDate(),
      end: moment().year(year).month(month).endOf("month").toDate(),
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  weekSelected(): void {
    const startDay: number = Number(this.week.substring(0, 2));
    const startMonth: number = Number(this.week.substring(3, 5)) - 1;
    const startYear: number = 2000 + Number(this.week.substring(6, 8));

    this.$emit("time-period-change", {
      start: moment().month(startMonth).year(startYear).date(startDay).startOf("week").toDate(),
      end: moment().month(startMonth).year(startYear).date(startDay).endOf("week").toDate(),
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  daySelected(): void {
    this.$emit("time-period-change", {
      start: moment(this.day).startOf("day").toDate(),
      end: moment(this.day).endOf("day").toDate(),
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  customDateSelected(): void {
    this.menu = false;

    let start = moment(this.dateRange[0]);
    let end = moment(this.dateRange[1]);

    this.$emit("time-period-change", {
      start: start.startOf("day").toDate(),
      end: end.endOf("day").toDate(),
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  nextDay(): void {
    let start = new Date();
    let end = new Date();

    if (this.selectedTimePeriod === TimePeriod.DAY && !this.isCustomDate) {
      let selectedDay = moment(this.day).add(1, "days");
      if (selectedDay.isAfter(this.maxGraphDate)) {
        return;
      }
      this.day = selectedDay.toISOString(true).substr(0, 10);
      start = selectedDay.startOf("day").toDate();
      end = selectedDay.endOf("day").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.DAY && this.isCustomDate) {
      let startDay = moment(this.dateRange[0]);
      let endDay = moment(this.dateRange[1]);
      let dayRange = endDay.diff(startDay, "days");

      startDay.add(1, "days").add(dayRange, "days");
      endDay.add(1, "days").add(dayRange, "days");

      if (startDay.isAfter(this.maxGraphDate)) {
        return;
      }

      this.dateRange = [startDay.toISOString(true).substr(0, 10), endDay.toISOString(true).substr(0, 10)];
      start = startDay.startOf("day").toDate();
      end = endDay.endOf("day").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.WEEK) {
      const availableWeeks = this.weeks;
      let weekIndex = availableWeeks.indexOf(this.week) - 1;
      if (weekIndex < 0) {
        return;
      }
      this.week = this.weeks[weekIndex];
      let selectedWeek = moment(this.week.substr(0, 8), "DD.MM.YY");
      start = selectedWeek.startOf("week").toDate();
      end = selectedWeek.endOf("week").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.MONTH) {
      let selectedMonth = moment(this.month, "YYYY-MM").add(1, "months");
      if (selectedMonth.isAfter(this.maxGraphDate)) {
        return;
      }
      this.month = selectedMonth.toISOString(true).substr(0, 7);
      start = selectedMonth.startOf("month").toDate();
      end = selectedMonth.endOf("month").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.YEAR) {
      let selectedYear = this.year + 1;
      if (selectedYear >= moment(this.maxGraphDate).year()) {
        return;
      }
      this.year = selectedYear;
      start = moment().year(this.year).startOf("year").toDate();
      end = moment().year(this.year).endOf("year").toDate();
    }

    this.$emit("time-period-change", {
      start: start,
      end: end,
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  prevDay(): void {
    let start = new Date();
    let end = new Date();

    if (this.selectedTimePeriod === TimePeriod.DAY && !this.isCustomDate) {
      let selectedDay = moment(this.day).subtract(1, "days");

      if (selectedDay.isBefore(this.minGraphDate)) {
        return;
      }
      this.day = selectedDay.toISOString(true).substr(0, 10);
      start = selectedDay.startOf("day").toDate();
      end = selectedDay.endOf("day").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.DAY && this.isCustomDate) {
      let startDay = moment(this.dateRange[0]);
      let endDay = moment(this.dateRange[1]);
      let dayRange = endDay.diff(startDay, "days");

      startDay.subtract(1, "days").subtract(dayRange, "days");
      endDay.subtract(1, "days").subtract(dayRange, "days");

      if (endDay.isBefore(this.minGraphDate)) {
        return;
      }

      // this.dateRange = [startDay.toISOString(true).substr(0, 10), endDay.toISOString(true).substr(0, 10)];
      this.dateRange = [startDay.toISOString(true).substr(0, 10), endDay.toISOString(true).substr(0, 10)];
      start = startDay.startOf("day").toDate();
      end = endDay.endOf("day").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.WEEK) {
      const availableWeeks = this.weeks;
      let weekIndex = availableWeeks.indexOf(this.week) + 1;
      if (weekIndex >= availableWeeks.length) {
        return;
      }
      this.week = this.weeks[weekIndex];
      let selectedWeek = moment(this.week.substr(0, 8), "DD.MM.YY");
      start = selectedWeek.startOf("week").toDate();
      end = selectedWeek.endOf("week").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.MONTH) {
      let selectedMonth = moment(this.month, "YYYY-MM").subtract(1, "months");
      if (selectedMonth.isBefore(this.minGraphDate)) {
        return;
      }
      this.month = selectedMonth.toISOString(true).substr(0, 7);
      start = selectedMonth.startOf("month").toDate();
      end = selectedMonth.endOf("month").toDate();
    } else if (this.selectedTimePeriod === TimePeriod.YEAR) {
      let selectedYear = this.year - 1;
      if (selectedYear <= moment(this.minGraphDate).year()) {
        return;
      }
      this.year = selectedYear;
      start = moment().year(this.year).startOf("year").toDate();
      end = moment().year(this.year).endOf("year").toDate();
    }

    this.$emit("time-period-change", {
      start: start,
      end: end,
      timePeriod: this.selectedTimePeriod,
    } as TimePeriodChangeEvent);
  }

  get years(): number[] {
    const thisYear: number = new Date().getFullYear();
    const firstYear: number = thisYear - 5;
    var years: number[] = [];

    for (var i = thisYear; i >= firstYear; i--) {
      years.push(i);
    }
    return years;
  }

  get weeks(): string[] {
    const weeks: string[] = [];

    for (var i = 0; i < 52; i++) {
      const curr = new Date();
      const first = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1 - i * 7));
      const last = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

      const startDate: string = first.toLocaleDateString("de-DE", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      const endDate: string = last.toLocaleDateString("de-DE", { year: "2-digit", month: "2-digit", day: "2-digit" });
      weeks.push(startDate + " - " + endDate);
    }

    this.week = weeks[0];
    return weeks;
  }

  get formattedDateRange(): string | null {
    return this.dateRange.map((date) => this.formatDay(date)).join(" - ");
  }

  get formattedDay(): string | null {
    return this.formatDay(this.day);
  }

  get formattedMonth(): string | null {
    return new Date(this.month).toLocaleDateString("de-DE", { year: "numeric", month: "long" });
  }
}
