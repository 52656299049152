
import { Vue, Component, Prop } from "vue-property-decorator";
import BarChart from "@/components/charts/BarChart.vue";
import Chart from "chart.js";
import { Report, Trend } from "zaehlerfreunde-proto-types/reporting_pb";
import { formatNumber, formatNumerOptional } from "@/utils/number-utils";

@Component({
  components: {
    BarChart,
  },
})
export default class ReportingMetric extends Vue {
  @Prop() metric: Report.MetricEvaluation;

  formatNumber = formatNumber;

  get goodTrend(): boolean {
    return this.metric.getTrend()?.getSentiment() === Trend.Sentiment.GOOD;
  }

  get upwardTrend(): boolean {
    return (this.metric.getTrend()?.getPercentChanged() ?? 0) > 0;
  }

  get trend(): string {
    return formatNumerOptional(this.metric.getTrend()?.getPercentChanged()) ?? "";
  }

  get chartOptions(): Chart.ChartOptions {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      responsive: true,
      tooltips: {
        callbacks: {
          label: (item) => {
            if (this.metric) {
              return `${formatNumber(
                this.metric.getPreviousPeriodsList()[item.index ?? 0].getValue()
              )} ${this.metric.getUnit()}`;
            } else {
              return "";
            }
          },
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: false,
            },
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: false,
              lineHeight: 0,
            },
            gridLines: {
              display: true,
              lineWidth: 1,
              zeroLineWidth: 1,
            },
            ticks: {
              suggestedMin: 0,
              maxTicksLimit: 3,
            },
          },
        ],
      },
    };
  }

  get chartData() {
    return {
      labels: this.metric.getPreviousPeriodsList().map((p) => p.getLabel()),
      datasets: [
        {
          label: "label",
          data: this.metric.getPreviousPeriodsList().map((p) => p.getValue()),
          backgroundColor: getComputedStyle(document.body).getPropertyValue("--zf-primary-color"),
          borderColor: getComputedStyle(document.body).getPropertyValue("--zf-primary-color"),
          borderWidth: 1,
          barPercentage: 0.8,
        },
      ],
    };
  }
}
