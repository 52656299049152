
import account, { accountModule } from "@/store/modules/account";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class DeleteAccount extends Vue {
  @accountModule.State deleteAccountCall: RemoteCall<UserError>;

  onCancelClicked(): void {
    this.$emit("close");
  }

  onSaveClicked(): void {
    account.deleteAccount();
    this.$emit("close");
  }
}
