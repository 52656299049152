
import { userServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import partner, { partnerModule } from "@/store/modules/partner";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  CreateSubscriptionPlanRequest,
  GetSubscriptionFeaturesRequest,
  GetSubscriptionPlansRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/subscription_pb";

export function getPaymentTypeLabel(paymentType?: SubscriptionPlan.PaymentType): string {
  if (paymentType === undefined) {
    return "-";
  }

  switch (paymentType) {
    case SubscriptionPlan.PaymentType.STRIPE:
      return "Stripe";
    case SubscriptionPlan.PaymentType.INVOICE:
      return "Rechnung";
    case SubscriptionPlan.PaymentType.EXTERNAL_URL:
      return "Externer Bestellprozess";
    default:
      return "-";
  }
}

@Component
export default class SubscriptionPlans extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  plans: RemoteData<UserError, SubscriptionPlan[]> = initialized;
  createNewPlanCall: RemoteCall<UserError> = initialized;
  features: RemoteData<UserError, Feature[]> = initialized;
  featureDescriptions: string[] = [];
  newPlanDialog = false;
  externalPaymentUrl = "";

  PaymentType = SubscriptionPlan.PaymentType;

  newPlan = {
    emoji: "",
    name: "",
    description: "",
    price: "",
    trialPeriodDays: "",
    paymentType: SubscriptionPlan.PaymentType.INVOICE,
    features: [] as Feature[],
    defaultPlan: false,
    recommendedPlan: false,
  };

  headers = [
    { text: "", value: "emoji" },
    { text: "Name", value: "name" },
    { text: "Preis", value: "price" },
    { text: "Default", value: "default" },
    { text: "Zahlungsart", value: "paymentType" },
    { text: "", value: "controls" },
  ];

  paymentTypeItems = [
    { text: "Stripe", value: SubscriptionPlan.PaymentType.STRIPE },
    { text: "Rechnung", value: SubscriptionPlan.PaymentType.INVOICE },
    { text: "Externer Bestellprozess", value: SubscriptionPlan.PaymentType.EXTERNAL_URL },
  ];

  get rows() {
    return (
      this.plans.data?.map((p) => ({
        id: p.getId(),
        emoji: p.getEmoji(),
        name: p.getName(),
        price: `${p.getPrice()} ${p.getPriceUnit()}`,
        default: p.getDefaultPlan() ? "Ja" : "Nein",
        paymentType: getPaymentTypeLabel(p.getPaymentType()),
      })) ?? []
    );
  }

  get featureItems() {
    return (
      this.features.data?.map((feature, i) => ({
        value: feature,
        text: this.featureDescriptions[i],
      })) ?? []
    );
  }

  get newPlanBtnDisabled(): boolean {
    return !this.newPlan.name || !this.newPlan.price || !this.newPlan.features;
  }

  @Watch("selectedChildPartner")
  onSelectedPartnerChanged() {
    this.getPlans();
  }

  handleRowClick(plan: { id: string }) {
    this.$router.push(`${paths.admin.subscriptions}/${plan.id}`);
  }

  mounted() {
    this.getPlans();
    this.getFeatures();
  }

  async getPlans(): Promise<void> {
    const request = new GetSubscriptionPlansRequest();

    if (partner.selectedChildPartner.data) {
      request.setPartnerId(partner.selectedChildPartner.data?.getId());
    }

    try {
      this.plans = pending;
      const response = await userServiceClient.getSubscriptionPlans(request, {});
      this.plans = success(response.getSubscriptionPlansList());
    } catch (error) {
      this.plans = failure(userErrorFrom(error));
    }
  }

  async getFeatures(): Promise<void> {
    try {
      this.features = pending;
      const response = await userServiceClient.getSubscriptionFeatures(new GetSubscriptionFeaturesRequest(), {});
      this.features = success(response.getFeaturesList());
      this.featureDescriptions = response.getFeatureDescriptionsList();
    } catch (error) {
      this.features = failure(userErrorFrom(error));
    }
  }

  async createNewPlan(): Promise<void> {
    const plan = new SubscriptionPlan();
    plan.setName(this.newPlan.name);
    plan.setDescription(this.newPlan.description);
    plan.setEmoji(this.newPlan.emoji);
    plan.setDefaultPlan(this.newPlan.defaultPlan);
    plan.setRecommendedPlan(this.newPlan.recommendedPlan);
    plan.setFeaturesList(this.newPlan.features);
    plan.setPaymentType(this.newPlan.paymentType);
    plan.setTrialPeriodDays(parseInt(this.newPlan.trialPeriodDays));
    plan.setPrice(parseFloat(this.newPlan.price));

    if (this.externalPaymentUrl) {
      plan.setExternalPaymentUrl(this.externalPaymentUrl);
    }

    const request = new CreateSubscriptionPlanRequest();
    request.setSubscriptionPlan(plan);

    if (partner.selectedChildPartner.data) {
      request.setPartnerId(partner.selectedChildPartner.data?.getId());
    }

    try {
      this.createNewPlanCall = pending;
      const response = await userServiceClient.createSubscriptionPlan(request, {});
      this.createNewPlanCall = success(void 0);
      this.$router.push(`${paths.admin.subscriptions}/${response.getSubscriptionPlanId()}`);
    } catch (error) {
      this.createNewPlanCall = failure(userErrorFrom(error));
    }
  }
}
