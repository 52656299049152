
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { subscriptionModule } from "@/store/modules/subscription";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import AccountDropdown from "./AccountDropdown.vue";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/subscription_pb";

@Component({
  components: {
    AccountDropdown,
  },
})
export default class PlatformTopBar extends Vue {
  @Prop({ default: false }) showSideMenuButton: boolean;
  @Prop({ default: "" }) title: string;

  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @subscriptionModule.State subscriptionPlan: RemoteData<UserError, SubscriptionPlan>;

  paths = paths;

  showSideMenuClicked(): void {
    this.$emit("show-side-menu");
  }

  get planName(): string {
    return this.subscriptionPlan.data?.getName() ?? "";
  }
}
