import { Vue, Component } from "vue-property-decorator";
import {
  Auth0Client,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  LogoutOptions,
  createAuth0Client,
} from "@auth0/auth0-spa-js";
import { User } from "./User";
import { isAuth0Redirect, setAuthenticatedCookie } from "@/utils/login-utils";

export type Auth0Options = {
  domain: string;
  clientId: string;
  audience?: string;
  organization: string | undefined;
  [key: string]: string | undefined;
};

export type RedirectCallback = (appState: RedirectLoginOptions) => void;

interface PromiseHandle<T> {
  resolve: (value: T) => void;
  reject: (reason?: unknown) => void;
}

@Component
export class VueAuth extends Vue {
  private auth0ClientHandle: PromiseHandle<Auth0Client>;
  private isAuthenticatedHandle: PromiseHandle<boolean>;
  private appStateHandle: PromiseHandle<any>;

  auth0Client: Promise<Auth0Client> = new Promise((resolve, reject) => {
    this.auth0ClientHandle = { resolve, reject };
  });

  isAuthenticated: Promise<boolean> = new Promise((resolve, reject) => {
    this.isAuthenticatedHandle = { resolve, reject };
  });

  appState: Promise<any> = new Promise((resolve, reject) => {
    this.appStateHandle = { resolve, reject };
  });

  async getUser(): Promise<User | undefined> {
    const userDetails: { [key: string]: string | boolean | undefined } | undefined = await (
      await this.auth0Client
    ).getUser();
    if (userDetails) {
      return new User(userDetails);
    }
  }

  /** Authenticates the user using the redirect method */
  async loginWithRedirect(o: RedirectLoginOptions): Promise<void> {
    return (await this.auth0Client).loginWithRedirect(o);
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getTokenSilently(o: GetTokenSilentlyOptions): Promise<any> {
    return (await this.auth0Client).getTokenSilently(o);
  }

  /** Logs the user out and removes their session on the authorization server */
  async logout(o: LogoutOptions): Promise<void> {
    setAuthenticatedCookie(false);
    return (await this.auth0Client).logout(o);
  }

  /** Use this lifecycle method to instantiate the SDK client */
  async init(onRedirectCallback: RedirectCallback, redirectUri: string, auth0Options: Auth0Options): Promise<void> {
    // Create a new instance of the SDK client using members of the given options object
    const auth0Client = await createAuth0Client({
      domain: auth0Options.domain,
      authorizationParams: {
        audience: auth0Options.audience,
        redirect_uri: redirectUri,
        organization: auth0Options.organization,
      },
      clientId: auth0Options.clientId,
      useRefreshTokens: true,
    });

    this.auth0ClientHandle.resolve(auth0Client);

    try {
      // If the user is returning to the app after authentication..
      if (isAuth0Redirect()) {
        // handle the redirect and retrieve tokens
        const { appState } = (await auth0Client.handleRedirectCallback()) ?? {
          appState: undefined,
        };

        this.appStateHandle.resolve(appState);

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState);
      }
    } catch (e) {
      console.error(e);
    } finally {
      // Initialize our internal authentication state when the page is reloaded
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        setAuthenticatedCookie(true);
      }

      history.replaceState({}, document.title, window.location.pathname);
      this.isAuthenticatedHandle.resolve(isAuthenticated);
    }
  }
}
