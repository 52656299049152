
import { Component, Vue } from "vue-property-decorator";
import ChangeUserName from "./ChangeUserName.vue";
import account, { accountModule } from "@/store/modules/account";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { User } from "zaehlerfreunde-proto-types/user_pb";

@Component({
  components: {
    ChangeUserName,
  },
})
export default class PersonalInfos extends Vue {
  @accountModule.State userDetails: RemoteData<UserError, User>;

  showChangeNameDialog: boolean = false;

  beforeMount(): void {
    account.getUserDetails();
  }

  get name(): string {
    return this.userDetails.data?.getName() ?? "";
  }
}
