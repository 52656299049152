import { userErrorFrom } from "@/types/user-error";
import { VueGtag } from "vue-gtag";
import { getToken } from "./login-utils";

class AuthInterceptor {
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any*/
  async intercept(request: any, invoker: any): Promise<any> {
    try {
      const token = await getToken();
      const metadata = request.getMetadata();

      metadata.Authorization = token;
      return invoker(request);
    } catch (error) {
      return invoker(request);
    }
  }
}

class GtagInterceptor {
  gtag?: VueGtag;

  async intercept(request: any, invoker: any): Promise<any> {
    try {
      return await invoker(request);
    } catch (error) {
      const message = userErrorFrom(error).message;
      const description = `Error invoking ${request.getMethodDescriptor().name}: ${message}`;

      this.gtag?.event("request-error", {
        event_category: "error",
        event_label: description,
      });

      throw error;
    }
  }
}

export const gtagInterceptor = new GtagInterceptor();
export const authInterceptor = new AuthInterceptor();
export const interceptors = [authInterceptor, gtagInterceptor];
