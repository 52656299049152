
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MainDevice extends Vue {
  onButtonClicked(isMainDevice: boolean): void {
    this.$emit("is-main-device-selected", isMainDevice);
  }
}
