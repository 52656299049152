
import spaces, { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { GetViessmannAuthConfigRequest } from "zaehlerfreunde-central/device_service_pb";
import ResetViessmannClientDialog from "./ResetViessmannClientDialog.vue";

export interface ViessmannConnectionState {
  spaceId: string;
  deviceName: string;
  isMainDevice: boolean;
  isAdminAction: boolean;
}

@Component({
  components: { ResetViessmannClientDialog },
})
export default class ViessmannConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  authURI: RemoteData<UserError, string> = initialized;
  clientId: string = "";
  redirectUri: string = "";
  page: number = 1;
  isFirstLogin: boolean = false;
  showDialog: boolean = false;

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  async mounted(): Promise<void> {
    await this.fetchViessmannAuthUrl();
    if (!this.isFirstLogin && this.authURI.data) {
      window.open(this.authURI.data, "_self");
    }
  }

  copyUrl() {
    navigator.clipboard.writeText(this.redirectUri);
  }

  async fetchViessmannAuthUrl(): Promise<void> {
    try {
      this.authURI = pending;
      const state: ViessmannConnectionState = {
        spaceId: this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId,
        deviceName: this.generalInfo.deviceName,
        isMainDevice: this.generalInfo.isMainDevice,
        isAdminAction: this.adminAction,
      };

      const request = new GetViessmannAuthConfigRequest();
      request.setState(JSON.stringify(state));
      request.setClientId(this.clientId);
      const response = await deviceServiceClient.getViessmannAuthConfig(request, {});
      this.isFirstLogin = response.getIsFirstLogin();
      this.redirectUri = response.getRedirectUri();
      this.authURI = success(response.getAuthUrl());
    } catch (error) {
      this.authURI = failure(userErrorFrom(error));
    }
  }

  async connectViessmannAccount(): Promise<void> {
    await this.fetchViessmannAuthUrl();
    if (this.authURI.data) {
      window.open(this.authURI.data, "_self");
    }
  }
}
