
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Kpi extends Vue {
  @Prop({ default: null }) label: string | null;
  @Prop({ default: null }) value: string | number | null;
  @Prop({ default: null }) unit: string | null;
  @Prop({ default: null }) icon: string | null;
  @Prop({ default: "black" }) iconColor: string;
  @Prop({ default: null }) detail: string | null;
}
