
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetAllCountriesRequest, UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { Address, Country, Space } from "zaehlerfreunde-proto-types/space_pb";

@Component
export default class ChangeSpaceAddress extends Vue {
  @Prop() value: boolean;
  @Prop() space: Space | null;

  updateAddressCall: RemoteCall<UserError> = initialized;
  countriesList: RemoteData<UserError, Country[]> = initialized;

  address = {
    addressLineOne: "",
    addressLineTwo: "",
    zipCode: "",
    city: "",
  };
  selectedCountry: Country | null = null;

  @Watch("space")
  onSpaceChanged(): void {
    this.address.addressLineOne = this.space?.getAddress()?.getAddressLineOne() ?? "";
    this.address.addressLineTwo = this.space?.getAddress()?.getAddressLineTwo() ?? "";
    this.address.city = this.space?.getAddress()?.getCity() ?? "";
    this.address.zipCode = this.space?.getAddress()?.getPostcode() ?? "";
    this.selectedCountry = this.space?.getAddress()?.getCountry() ?? null;
  }

  async mounted(): Promise<void> {
    const request = new GetAllCountriesRequest();
    this.countriesList = pending;

    try {
      const response = await spaceServiceClient.getAllCountries(request, {});
      this.countriesList = success(response.getCountryList());
    } catch (error) {
      this.countriesList = failure(userErrorFrom(error));
    }
  }

  countryName(country: Country): string {
    return country.getCountryName();
  }

  countryValue(country: Country): string {
    return country.getId();
  }

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoComplete(): boolean {
    return (
      !!this.address.addressLineOne && !!this.address.zipCode && !!this.address.city && !!this.selectedCountry?.getId()
    );
  }

  async onSaveClicked(): Promise<void> {
    const country = this.selectedCountry;

    if (!country) {
      return;
    }

    const address = new Address();
    address.setAddressLineOne(this.address.addressLineOne);
    address.setAddressLineTwo(this.address.addressLineTwo);
    address.setCity(this.address.city);
    address.setPostcode(this.address.zipCode);
    address.setCountry(country);

    const request = new UpdateSpaceRequest();
    request.setSpaceId(spaces.selectedSpaceId);
    request.setAddress(address);

    this.updateAddressCall = pending;

    try {
      await spaceServiceClient.updateSpace(request, {});

      this.$emit("input", false);

      spaces.getSpaces();

      this.updateAddressCall = success(void 0);
    } catch (error) {
      this.updateAddressCall = failure(userErrorFrom(error));
    }
  }
}
