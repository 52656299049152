
import { isFailure, isPending, isSuccess, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { getTextWidth } from "@/utils/text-width";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { LatestStatus } from "zaehlerfreunde-proto-types/latest_status_pb";

const textTextMargin = 16;
const textUnitMargin = 8;
const logoWidth = 44;
const logoTextMargin = 16;
const cardTextMargin = 16;
const cardLogoMargin = 16;
const editBtnWidth = 44;

const deviceNameFont = "14px Poppins, sans-serif";
const unitFont = "900 16px Poppins, sans-serif";
const font = "900 24px Poppins, sans-serif";
const labelFont = "14px Poppins, sans-serif";
const timespanFont = "14px Poppins, sans-serif ";

@Component
export default class LatestStatusView extends Vue {
  @Prop()
  latestStatus: RemoteData<UserError, LatestStatus>;

  get values(): LatestStatus.Value[] {
    return this.latestStatus.data?.getValuesList() ?? [];
  }

  get details(): string {
    return this.latestStatus.data?.getInfo()?.getContent() ?? "";
  }

  get timestamp(): number {
    return this.latestStatus.data?.getTimestamp() ?? 0;
  }

  get hasEditBtn(): boolean {
    return this.latestStatus.data?.getAllowManualReading() ?? false;
  }

  static getWidth(remoteStats: RemoteData<UserError, LatestStatus>): number {
    if (isSuccess(remoteStats)) {
      const status = remoteStats.data;
      var detailsWidth = 0;

      const valueList = status.getValuesList().map((v) => v.getValueFormatted());
      const unitsList = status.getValuesList().map((v) => v.getUnit());
      const labelsList = status.getValuesList().map((v) => v.getLabel());
      const deviceName = status.getName();
      const details = status.getInfo()?.getContent() ?? "";
      const timestamp = moment(status.getTimestamp() * 1000).fromNow();

      const deviceNameWidth = getTextWidth(deviceName, deviceNameFont);

      const timespanWidth = getTextWidth(timestamp, timespanFont);

      if (details !== "") {
        detailsWidth = details.length > 30 ? 250 : 140;
      }

      const valuesWidth = valueList.reduce(
        (totalWidth, value, index) =>
          totalWidth +
          Math.max(
            getTextWidth(labelsList[index], labelFont),
            getTextWidth(value, font) + textUnitMargin + getTextWidth(unitsList[index], unitFont)
          ),
        (valueList.length - 1) * textTextMargin
      );

      return (
        Math.max(valuesWidth, detailsWidth, deviceNameWidth, timespanWidth) +
        logoTextMargin +
        logoWidth +
        cardTextMargin +
        cardLogoMargin +
        (status.getAllowManualReading() ? editBtnWidth : 0)
      );
    } else if (isPending(remoteStats)) {
      return 122;
    } else if (isFailure(remoteStats)) {
      return 400;
    } else {
      return 0;
    }
  }
}
