
import { Component, Prop, Vue } from "vue-property-decorator";
import ChangeEnergyConsumers from "@/components/ChangeEnergyConsumers.vue";

@Component({
  components: {
    ChangeEnergyConsumers,
  },
})
export default class AddEnergyConsumersReminder extends Vue {
  @Prop() tipsPerPage: number;

  showChangeConsumersDialog = false;
}
