
import { userServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import {
  DeleteSubscriptionPlanRequest,
  GetSubscriptionFeaturesRequest,
  GetSubscriptionPlanRequest,
  UpdateSubscriptionPlanRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/subscription_pb";
import { getPaymentTypeLabel } from "./SubscriptionPlans.vue";
import SubscribedUsers from "./SubscribedUsers.vue";
import { formatNumber } from "@/utils/number-utils";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

@Component({
  components: {
    SubscribedUsers,
  },
})
export default class SubscriptionPlanDetails extends Vue {
  plan: RemoteData<UserError, SubscriptionPlan> = initialized;
  features: RemoteData<UserError, Feature[]> = initialized;
  featureDescriptions: string[] = [];
  tab = 0;
  deletePlanDialog = false;
  deletePlanCall: RemoteCall<UserError> = initialized;
  updatePlanDialog = false;
  updatePlanCall: RemoteCall<UserError> = initialized;
  addFeatureDialog = false;
  featuresToAdd: Feature[] = [];

  updatedPlan = {
    emoji: "",
    name: "",
    description: "",
    price: "",
    trialPeriodDays: "",
    defaultPlan: false,
    recommendedPlan: false,
  };

  get updatePlanBtnDisabled(): boolean {
    return !this.updatedPlan.name || !this.updatedPlan.price;
  }

  get details(): { key: string; value: string }[] {
    const plan = this.plan.data;

    if (plan) {
      return [
        {
          key: "Beschreibung",
          value: plan.getDescription(),
        },
        {
          key: "Default Abo",
          value: plan.getDefaultPlan() ? "Ja" : "Nein",
        },
        {
          key: "Zahlungsart",
          value: getPaymentTypeLabel(plan.getPaymentType()),
        },
        {
          key: "Testphase",
          value: `${plan.getTrialPeriodDays()} Tage`,
        },
        {
          key: "Preis",
          value: `${plan.getPrice()} ${plan.getPriceUnit()}`,
        },
        {
          key: "Empfohlenes Abo",
          value: plan.getRecommendedPlan() ? "Ja" : "Nein",
        },
        ...(plan.getExternalPaymentUrl() ? [{ key: "Bestellprozess URL ", value: plan.getExternalPaymentUrl() }] : []),
      ];
    } else {
      return [];
    }
  }

  get featureItems() {
    return (
      this.features.data
        ?.map((f, i) => ({
          text: this.featureDescriptions[i],
          value: f,
        }))
        .filter((i) => !this.plan.data?.getFeaturesList().some((f) => i.value === f)) ?? []
    );
  }

  mounted(): void {
    this.getPlan();
    this.getFeatures();
  }

  async getPlan(): Promise<void> {
    const request = new GetSubscriptionPlanRequest();
    const planId = this.$route.params["planId"] as string;

    request.setSubscriptionPlanId(planId);

    try {
      this.plan = pending;
      const response = await userServiceClient.getSubscriptionPlan(request, {});
      const plan = response.getSubscriptionPlan();

      if (plan) {
        this.plan = success(plan);

        this.updatedPlan = {
          emoji: plan.getEmoji(),
          name: plan.getName(),
          description: plan.getDescription(),
          price: formatNumber(plan.getPrice()),
          trialPeriodDays: plan.getTrialPeriodDays().toLocaleString(),
          defaultPlan: plan.getDefaultPlan(),
          recommendedPlan: plan.getRecommendedPlan(),
        };
      }
    } catch (error) {
      this.plan = failure(userErrorFrom(error));
    }
  }

  async getFeatures(): Promise<void> {
    try {
      this.features = pending;
      const request = new GetSubscriptionFeaturesRequest();
      const response = await userServiceClient.getSubscriptionFeatures(request, {});
      this.features = success(response.getFeaturesList());
      this.featureDescriptions = response.getFeatureDescriptionsList();
    } catch (error) {
      this.features = failure(userErrorFrom(error));
    }
  }

  async deletePlan(): Promise<void> {
    try {
      this.deletePlanCall = pending;
      const request = new DeleteSubscriptionPlanRequest();
      const planId = this.$route.params["planId"] as string;
      request.setSubscriptionPlanId(planId);
      await userServiceClient.deleteSubscriptionPlan(request, {});
      this.deletePlanCall = success(void 0);
      this.goBackToSubscriptions();
    } catch (error) {
      this.deletePlanCall = failure(userErrorFrom(error));
    }
  }

  updateDetails() {
    const plan = this.plan.data;

    if (plan) {
      plan.setName(this.updatedPlan.name);
      plan.setEmoji(this.updatedPlan.emoji);
      plan.setDescription(this.updatedPlan.description);
      plan.setPrice(parseFloat(this.updatedPlan.price));
      plan.setTrialPeriodDays(parseInt(this.updatedPlan.trialPeriodDays));
      plan.setDefaultPlan(this.updatedPlan.defaultPlan);
      plan.setRecommendedPlan(this.updatedPlan.recommendedPlan);

      this.updatePlan(plan);
    }
  }

  async addFeature(): Promise<void> {
    const plan = this.plan.data;

    if (plan) {
      this.featuresToAdd.forEach((f) => plan.addFeatures(f));
      this.updatePlan(plan);
    }
  }

  async deleteFeature(feature: Feature): Promise<void> {
    const plan = this.plan.data;

    if (plan) {
      plan.setFeaturesList(plan.getFeaturesList().filter((f) => f !== feature));
      this.updatePlan(plan);
    }
  }

  async updatePlan(plan: SubscriptionPlan): Promise<void> {
    const request = new UpdateSubscriptionPlanRequest();
    request.setSubscriptionPlan(plan);

    try {
      this.updatePlanCall = pending;
      await userServiceClient.updateSubscriptionPlan(request, {});
      this.updatePlanCall = success(void 0);
      this.updatePlanDialog = false;
      this.addFeatureDialog = false;
      this.getPlan();
    } catch (error) {
      this.updatePlanCall = failure(userErrorFrom(error));
    }
  }

  goBackToSubscriptions(): void {
    this.$router.replace(paths.admin.subscriptions);
  }
}
