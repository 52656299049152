import { getAuthService } from "@/auth";
import Vue from "vue";

export const setAuthenticatedCookie = (authenticated: boolean): void => {
  Vue.$cookies.set("hasAuthenticated", authenticated ? "yes" : "no", "1y");
};

export const hasAuthenticated = (): boolean => Vue.$cookies.get("hasAuthenticated") === "yes";

export const demoToken = (): string | null => Vue.$cookies.get("demoToken");

export const isDemoAccount = (): boolean => demoToken() !== null;

export const setDemoToken = (token: string) => Vue.$cookies.set("demoToken", token, "1h");

export const getToken = async (): Promise<string | null> => {
  if (isDemoAccount()) {
    return demoToken();
  }
  const authService = getAuthService();
  const token = await authService.getTokenSilently({ authorizationParams: { scope: "openid" } });
  return token;
};

export const isAuth0Redirect = (): boolean =>
  window.location.search.includes("error=") ||
  (window.location.search.includes("code=") && window.location.search.includes("state="));
