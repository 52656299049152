
import spaces from "@/store/modules/spaces";
import tips, { tipsModule } from "@/store/modules/tips";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { advisoryServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SetEnergyConsumersRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { EnergyConsumer } from "zaehlerfreunde-proto-types/tips_pb";

@Component
export default class ChangeEnergyConsumers extends Vue {
  @tipsModule.State energyConsumers: RemoteData<UserError, EnergyConsumer[]>;

  setEnergyConsumersCall: RemoteCall<UserError> = initialized;

  @Prop() value: boolean;

  updates = 0;

  updateCount(consumer: EnergyConsumer, delta: number): void {
    consumer.setCount(consumer.getCount() + delta);
    this.updates += 1;
  }

  async onSaveClicked(): Promise<void> {
    try {
      this.setEnergyConsumersCall = pending;

      const request = new SetEnergyConsumersRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setEnergyConsumersList(this.energyConsumers.list);

      await advisoryServiceClient.setEnergyConsumers(request, {});

      this.setEnergyConsumersCall = success(void 0);
      tips.getTips();
      tips.getEnergyConsumers();
      this.$emit("input", false);
    } catch (error) {
      this.setEnergyConsumersCall = failure(userErrorFrom(error));
    }
  }
}
