
import { RemoteDataVue } from "@/store/utils/remote-data";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Card extends RemoteDataVue {
  @Prop({ default: true }) boxShadow: boolean;
  @Prop({ default: false }) actionRequired: boolean;
  @Prop({ default: "400px" }) flexBasis: string;
  @Prop({ default: null }) height: string | null;
  @Prop({ default: null }) maxWidth: string | null;
  @Prop({ default: true }) padding: boolean;
  @Prop({ default: false }) highlightOnHover: boolean;
  @Prop({ default: false }) clickable: boolean;
  @Prop({ default: null }) background: "primary" | "secondary" | null;
  @Prop({ default: false }) border: boolean;
  @Prop({ default: true }) flexGrow: boolean;
  @Prop({ default: true }) minHeight: boolean;
}
