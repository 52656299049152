
import { RemoteDataVue } from "@/store/utils/remote-data";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Dialog extends RemoteDataVue {
  @Prop({ default: null }) title: string | null;
  @Prop({ default: null }) subtitle: string | null;
  @Prop({ default: true }) padding: boolean;
  @Prop({ default: null }) maxWidth: string;
  @Prop({ default: true }) closable: boolean;

  onClose(): void {
    if (this.closable) {
      this.$emit("input", false);
      this.$emit("close");
    }
  }
}
