
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceReadingStats, ManualReading, Unit } from "zaehlerfreunde-proto-types/device_reading_pb";
import {
  GetDeviceReadingStatsRequest,
  GetDeviceRequest,
  StoreNonSmartMeterReadingsRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { deviceServiceClient } from "@/config/service-clients";
import LatestManualReadings, {
  TabularChartRowData,
} from "../../dashboard/update-manual-readings/LatestManualReadings.vue";
import { paths } from "@/router/routes";
import CorrectManualReading from "./CorrectManualReading.vue";
import { GetTabularDataResponse } from "zaehlerfreunde-central/ui_service_pb";
import de from "vuetify/src/locale/de";

export const NON_SMART_METER_INITIALISATION = "non-smart-meter-init";

@Component({
  components: {
    LatestManualReadings,
    CorrectManualReading,
  },
})
export default class UpdateManualReadingsPage extends Vue {
  Device = Device;
  chosenDevice: RemoteData<UserError, Device> = initialized;
  deviceStats: RemoteData<UserError, DeviceReadingStats>;
  updateCall: RemoteCall<UserError> = initialized;
  addCall: RemoteCall<UserError> = initialized;

  deviceMedium: Device.Medium | undefined;
  totalConsumption: number | null = null;
  totalIngestion: number | null = null;
  measurementDate: Date = new Date(Date.now());
  isBidirectional: boolean = false;
  statusLoading: boolean = false;
  chosenUnit: Unit;
  isUpdate: boolean = false;
  editRow: GetTabularDataResponse.Row | null = null;
  isManualReadingCorrection: boolean = false;
  title: string = "Zählerstand hinzufügen";

  async mounted(): Promise<void> {
    const chosenDeviceId = this.$route.params.deviceId;

    await this.getDevice(chosenDeviceId);

    if (!chosenDeviceId) {
      this.$router.go(-1);
    } else {
      this.deviceMedium = this.chosenDevice?.data?.getMedium();
    }

    await this.getStats(chosenDeviceId);
  }

  async getDevice(deviceId: string): Promise<void> {
    const request = new GetDeviceRequest();
    request.setDeviceId(deviceId);
    try {
      const response = await deviceServiceClient.getDevice(request, {});
      const device = response.getDevice();
      if (device) {
        this.chosenDevice = success(device);
      }
    } catch (error) {
      this.chosenDevice = failure(userErrorFrom(error));
    }
  }

  async getStats(id: string): Promise<void> {
    const request = new GetDeviceReadingStatsRequest();
    request.setDeviceId(id);
    try {
      const response = await deviceServiceClient.getDeviceReadingStats(request, {});
      const stats = response.getDeviceReadingStats();
      if (stats) {
        this.deviceStats = success(stats);
        this.isBidirectional = this.deviceStats.data?.getHasIngestion() ?? false;
      }
    } catch (error) {
      this.deviceStats = failure(userErrorFrom(error));
    }
  }

  onRowEdit(row: GetTabularDataResponse.Row): void {
    if (row) {
      this.editRow = row;
      this.isManualReadingCorrection = true;
      this.title = "Zählerstand korrigieren";
    }
  }

  onRowUpdate(updatedRow: TabularChartRowData) {
    if (updatedRow) {
      this.measurementDate = new Date(updatedRow.timestamp);
      this.chosenUnit = updatedRow.unit;

      if (updatedRow.label.includes("Bezug")) {
        this.totalConsumption = updatedRow.value;
        this.totalIngestion = 0;
      } else {
        this.totalIngestion = updatedRow.value;
        this.totalConsumption = 0;
      }
    }

    this.storeManualReadings();
  }

  onBack() {
    if (this.isUpdate) {
      if (this.isManualReadingCorrection) {
        this.isManualReadingCorrection = false;
      } else {
        this.isUpdate = false;
      }
    }
    this.updateCall = initialized;
    this.totalConsumption = 0;
    this.totalIngestion = 0;
    this.measurementDate = new Date(Date.now());
    this.title = "Zählerstand hinzufügen";
  }

  getMediumUnit() {
    switch (this.deviceMedium) {
      case Device.Medium.ELECTRICITY:
      case Device.Medium.HEATING:
        this.chosenUnit = Unit.KILO_WATT_HOUR;
        return "kWh";
      case Device.Medium.GAS:
      case Device.Medium.WATER:
        this.chosenUnit = Unit.CUBIC_METRE;
        return "m3";
    }
  }

  requiredRule(input: string): string | boolean {
    return input?.length > 0 ? true : "Dieses Feld ist erforderlich";
  }

  @Watch("addCall")
  onAddManualReadingsCall(): void {
    if (this.addCall.succeeded) {
      this.$router.push(paths.platform.dashboard);
    }
  }

  chosenDeviceHasIngestion(hasIngestion: boolean) {
    this.isBidirectional = hasIngestion;
    this.statusLoading = false;
  }

  async storeManualReadings(): Promise<void> {
    try {
      const request = new StoreNonSmartMeterReadingsRequest();
      request.setDeviceId(this.chosenDevice?.data?.getId() as string);

      const manualReading = new ManualReading();

      if (this.isUpdate) {
        this.updateCall = pending;
        if (this.totalIngestion == 0) {
          manualReading.setConsumptionReadingValue(this.totalConsumption ?? 0);
        } else {
          manualReading.setIngestionReadingValue(this.totalIngestion ?? 0);
        }
      } else {
        this.addCall = pending;
        manualReading.setConsumptionReadingValue(this.totalConsumption ?? 0);
        if (this.isBidirectional) {
          manualReading.setIngestionReadingValue(this.totalIngestion ?? 0);
        }
      }

      manualReading.setMeasurementTime(Date.parse(this.measurementDate?.toDateString() as string) / 1000);
      manualReading.setUnit(this.chosenUnit);
      manualReading.setIsUpdate(this.isUpdate);

      request.setManualReading(manualReading);
      await deviceServiceClient.storeNonSmartMeterReadings(request, {});

      if (this.isUpdate) {
        // some time to update the reading in the latestReadings table
        setTimeout(() => {
          this.updateCall = success(void 0);
        }, 2000);
      } else {
        this.addCall = success(void 0);
      }
    } catch (error) {
      if (this.isUpdate) {
        this.updateCall = failure(userErrorFrom(error));
      } else {
        this.addCall = failure(userErrorFrom(error));
      }
    }
  }

  isSubmitDisabled() {
    return (
      !this.totalConsumption ||
      !this.measurementDate ||
      !(this.totalConsumption >= 0) ||
      (this.isBidirectional ? !this.totalIngestion || !(this.totalIngestion >= 0) : false)
    );
  }
}
