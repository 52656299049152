
import SearchBar from "@/components/core/SearchBar.vue";
import { tariffServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import partner, { partnerModule } from "@/store/modules/partner";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { getMediumName } from "@/utils/device-utils";
import { Vue, Component, Watch } from "vue-property-decorator";
import { AddTariffRequest, GetPartnerTariffsRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";

export interface TariffRow {
  id: string;
  name: string;
  supplier: string;
  medium: string;
}

@Component({
  components: {
    SearchBar,
  },
})
export default class TariffsTable extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  tariffs: RemoteData<UserError, Tariff[]> = initialized;
  addTariffCall: RemoteCall<UserError> = initialized;

  pageSize = 10;
  page = 1;
  pages = 0;
  search = "";

  showAddTariffDialog = false;
  newTariffDetails = {
    name: "",
    supplier: "",
    isGreen: false,
    medium: Device.Medium.ELECTRICITY,
  };

  mediums = [Device.Medium.ELECTRICITY, Device.Medium.GAS, Device.Medium.WATER].map((m) => ({
    text: getMediumName(m),
    value: m,
  }));

  @Watch("search")
  onSearchChanged(): void {
    this.page = 1;
    this.onParamsChanged();
  }

  @Watch("page")
  onPageChanged() {
    this.onParamsChanged();
  }

  @Watch("selectedChildPartner")
  onSelectedPartnerChanged() {
    this.getTariffs();
  }

  onParamsChanged(): void {
    this.$router.push({
      path: paths.admin.tariffs,
      query: { page: this.page.toString(), search: this.search },
    });

    this.getTariffs();
  }

  mounted(): void {
    this.search = (this.$route.query.search as string) ?? "";
    this.page = typeof this.$route.query.page === "string" ? parseInt(this.$route.query.page, 10) : 1;

    this.getTariffs();
  }

  get headers() {
    return [
      { text: "Lieferant", value: "supplier" },
      { text: "Name", value: "name" },
      { text: "Sparte", value: "medium" },
      { text: "", value: "controls" },
    ];
  }

  get rows(): TariffRow[] {
    return (
      this.tariffs.data?.map((t) => ({
        id: t.getId(),
        medium: getMediumName(t.getMedium()),
        name: t.getName(),
        supplier: t.getSupplier(),
      })) ?? []
    );
  }

  handleRowClick(row: TariffRow): void {
    this.$router.push(`${paths.admin.tariffs}/${row.id}`);
  }

  async getTariffs(): Promise<void> {
    const pagination = new Pagination();
    pagination.setPage(this.page - 1);
    pagination.setPageSize(this.pageSize);

    const request = new GetPartnerTariffsRequest();
    request.setPagination(pagination);
    request.setSearch(this.search);

    if (partner.selectedChildPartner.data) {
      request.setPartnerId(partner.selectedChildPartner.data?.getId());
    }

    try {
      this.tariffs = pending;
      const response = await tariffServiceClient.getPartnerTariffs(request, {});
      this.tariffs = success(response.getTariffsList());
      this.pages = response.getPages();
    } catch (error) {
      this.tariffs = failure(userErrorFrom(error));
    }
  }

  async addTariff(): Promise<void> {
    const tariff = new Tariff();
    tariff.setIsGreen(this.newTariffDetails.isGreen);
    tariff.setName(this.newTariffDetails.name);
    tariff.setMedium(this.newTariffDetails.medium);
    tariff.setSupplier(this.newTariffDetails.supplier);

    const request = new AddTariffRequest();
    request.setTariff(tariff);

    try {
      this.addTariffCall = pending;
      const response = await tariffServiceClient.addTariff(request, {});
      this.addTariffCall = success(void 0);
      this.showAddTariffDialog = false;
      this.$router.push(`${paths.admin.tariffs}/${response.getTariffId()}`);
    } catch (error) {
      this.addTariffCall = failure(userErrorFrom(error));
    }
  }
}
