
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Press extends Vue {
  metaInfo(): { title: string } {
    return {
      title: "Presse-Informationen",
    };
  }
}
