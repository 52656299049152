
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { AddDeviceToSpaceRequest, GetPartnerDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { deviceServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import admin, { adminModule } from "@/store/modules/admin";

@Component
export default class LinkSpaceToDeviceDialog extends Vue {
  @adminModule.State partnerDevices: RemoteData<UserError, Device[]>;

  @Prop() value: boolean;
  @Prop() space: Space | null;
  devices: RemoteData<UserError, Device[]> = initialized;
  searchFilter: string = "";

  linkSpaceToDeviceCall: RemoteCall<UserError> = initialized;
  isDisabled: boolean = true;
  selectedDeviceId: string | null = null;
  itemsPerPage = 10;
  page = 1;

  mounted(): void {
    this.devices = this.partnerDevices;
  }

  deviceName(device: Device): string {
    return device.getName();
  }

  deviceId(device: Device): string {
    return device.getId();
  }

  get spaceName(): string {
    return this.space?.getName() ?? "";
  }

  onCancelClicked() {
    this.$emit("input", false);
  }

  async onAddDeviceToSpaceClicked(): Promise<void> {
    try {
      this.linkSpaceToDeviceCall = pending;
      const request = new AddDeviceToSpaceRequest();
      request.setDeviceId(this.selectedDeviceId ?? "");
      request.setSpaceId(this.$route.params.spaceId);
      const response = await deviceServiceClient.addDeviceToSpace(request, {});
      this.linkSpaceToDeviceCall = success(void response);
      this.$emit("input", false);
      this.$emit("reload-devices");
    } catch (error) {
      this.linkSpaceToDeviceCall = failure(userErrorFrom(error));
    }
  }

  @Watch("searchFilter")
  async onSearchFilterChanged(): Promise<void> {
    if (this.selectedDeviceId) {
      return;
    }

    try {
      this.devices = pending;

      const request = new GetPartnerDevicesRequest();
      request.setPage(0);
      request.setPageSize(30);
      request.setSearchFilter(this.searchFilter);

      const response = await deviceServiceClient.getPartnerDevices(request, {});

      this.devices = success(response.getDevicesList());
    } catch (error) {
      this.devices = failure(userErrorFrom(error));
    }
  }

  get deviceSelectionItems(): { id: string; label: string }[] {
    return this.devices.list.map((d) => ({
      id: d.getId(),
      label: d.getName(),
    }));
  }
}
