
import spaces, { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GetSmartMeAuthURLRequest } from "zaehlerfreunde-central/device_service_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { paths } from "@/router/routes";

export interface SmartMeConnectionState {
  spaceId: string;
  deviceName: string;
  isMainDevice: boolean;
  isAdminAction: boolean;
}

@Component
export default class SmartMeConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  redirectURI: RemoteData<UserError, string> = initialized;

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  mounted(): void {
    this.openSmartMeLogin();
  }

  async openSmartMeLogin(): Promise<void> {
    const state: SmartMeConnectionState = {
      spaceId: this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId,
      deviceName: this.generalInfo.deviceName,
      isMainDevice: this.generalInfo.isMainDevice,
      isAdminAction: this.adminAction,
    };

    this.redirectURI = pending;

    try {
      const request = new GetSmartMeAuthURLRequest();
      request.setState(JSON.stringify(state));
      const response = await deviceServiceClient.getSmartMeAuthURL(request, {});
      window.open(response.getAuthUrl(), "_self");
    } catch (error) {
      this.redirectURI = failure(userErrorFrom(error));
    }
  }
}
