
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Report } from "zaehlerfreunde-proto-types/reporting_pb";

@Component
export default class ReportingSchedule extends Vue {
  @Prop() schedule: Report.Schedule;
  @Prop({ default: false }) deleteLoading: boolean;

  get details() {
    return [
      {
        icon: "mdi-calendar-sync",
        text: this.capitalizeFirstLetter(this.schedule.getFrequencydescription()),
      },
      {
        icon: "mdi-skip-next",
        text: this.capitalizeFirstLetter(moment(new Date(this.schedule.getNextExecution() * 1000)).fromNow()),
      },
      {
        icon: "mdi-chart-bar",
        text: this.schedule.getMetricDescriptionsList().join(", "),
      },
    ];
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
