
import { Vue, Component, Watch } from "vue-property-decorator";
import { EmailTemplate, EmailTemplateParam } from "zaehlerfreunde-proto-types/email_template_pb";
import { VueEditor } from "vue2-editor";
import { partnerServiceClient } from "@/config/service-clients";
import { GetEmailTemplateRequest, SetEmailTemplateRequest } from "zaehlerfreunde-central/partner_service_pb";
import {
  failure,
  initialized,
  pending,
  RemoteCall,
  RemoteData,
  success,
  successOptional,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

interface TemplateType {
  text: string;
  value: EmailTemplate.Type;
}

@Component({
  components: {
    VueEditor,
  },
})
export default class EditInviteEmail extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  paramHeaders = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Beschreibung",
      value: "description",
    },
    {
      text: "Beispiel",
      value: "example",
    },
    { text: "", value: "controls" },
  ];

  templateTypes: TemplateType[] = [
    {
      text: "Reguläre Einladung",
      value: EmailTemplate.Type.USER_INVITE,
    },
    {
      text: "Objekt Einladung",
      value: EmailTemplate.Type.SPACE_INVITE,
    },
  ];

  selectedTemplateType = EmailTemplate.Type.USER_INVITE;

  showEmailTemplateSavedSnackbar: boolean = false;

  emailTemplate: RemoteData<UserError, EmailTemplate> = initialized;
  setEmailTemplateCall: RemoteCall<UserError> = initialized;
  content = "";

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    if (this.selectedChildPartner.data) {
      this.loadEmailTemplate();
    }
  }

  get availableParams(): EmailTemplateParam.AsObject[] {
    return this.emailTemplate.data?.getAvailableParamsList()?.map((param) => param.toObject()) ?? [];
  }

  insertParam(param: EmailTemplateParam.AsObject): void {
    this.content = this.content + param.placeholder;
  }

  mounted(): void {
    this.loadEmailTemplate();
  }

  @Watch("selectedTemplateType")
  async loadEmailTemplate(): Promise<void> {
    try {
      this.emailTemplate = pending;
      const request = new GetEmailTemplateRequest();
      request.setEmailTemplateType(this.selectedTemplateType);
      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      const response = await partnerServiceClient.getEmailTemplate(request, {});
      this.emailTemplate = successOptional(response.getEmailTemplate());
      this.content = response.getEmailTemplate()?.getContent() ?? "";
    } catch (error) {
      this.emailTemplate = failure(userErrorFrom(error));
    }
  }

  async saveEmailTemplate(): Promise<void> {
    try {
      this.setEmailTemplateCall = pending;
      const request = new SetEmailTemplateRequest()
        .setEmailTemplateType(this.selectedTemplateType)
        .setTemplateContent(this.content);

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      await partnerServiceClient.setEmailTemplate(request, {});

      this.setEmailTemplateCall = success(void 0);
      this.showEmailTemplateSavedSnackbar = true;
    } catch (error) {
      this.setEmailTemplateCall = failure(userErrorFrom(error));
    }
  }
}
