// source: types/subscription.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_features_pb = require('../types/features_pb.js');
goog.object.extend(proto, types_features_pb);
goog.exportSymbol('proto.types.SubscriptionPlan', null, global);
goog.exportSymbol('proto.types.SubscriptionPlan.PaymentType', null, global);
goog.exportSymbol('proto.types.SubscriptionPlanOld', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SubscriptionPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.SubscriptionPlan.repeatedFields_, null);
};
goog.inherits(proto.types.SubscriptionPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SubscriptionPlan.displayName = 'proto.types.SubscriptionPlan';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.SubscriptionPlan.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SubscriptionPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SubscriptionPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SubscriptionPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SubscriptionPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emoji: jspb.Message.getFieldWithDefault(msg, 4, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    priceUnit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    trialPeriodDays: jspb.Message.getFieldWithDefault(msg, 7, 0),
    subscribed: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    billingFrequency: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    featuresList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    featureDescriptionsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    recommendedPlan: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    defaultPlan: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    partnerId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    externalPaymentUrl: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SubscriptionPlan}
 */
proto.types.SubscriptionPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SubscriptionPlan;
  return proto.types.SubscriptionPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SubscriptionPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SubscriptionPlan}
 */
proto.types.SubscriptionPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmoji(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceUnit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrialPeriodDays(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribed(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingFrequency(value);
      break;
    case 10:
      var value = /** @type {!proto.types.SubscriptionPlan.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 11:
      var values = /** @type {!Array<!proto.types.Feature>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFeatures(values[i]);
      }
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addFeatureDescriptions(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecommendedPlan(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultPlan(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalPaymentUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SubscriptionPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SubscriptionPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SubscriptionPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SubscriptionPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPriceUnit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTrialPeriodDays();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSubscribed();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBillingFrequency();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {!proto.types.SubscriptionPlan.PaymentType} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getFeaturesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
  f = message.getFeatureDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getRecommendedPlan();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDefaultPlan();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.SubscriptionPlan.PaymentType = {
  STRIPE: 0,
  INVOICE: 1,
  EXTERNAL_URL: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string emoji = 4;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getEmoji = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setEmoji = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.clearEmoji = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.hasEmoji = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double price = 5;
 * @return {number}
 */
proto.types.SubscriptionPlan.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string price_unit = 6;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getPriceUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setPriceUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 trial_period_days = 7;
 * @return {number}
 */
proto.types.SubscriptionPlan.prototype.getTrialPeriodDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setTrialPeriodDays = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool subscribed = 8;
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.getSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string billing_frequency = 9;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getBillingFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setBillingFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional PaymentType payment_type = 10;
 * @return {!proto.types.SubscriptionPlan.PaymentType}
 */
proto.types.SubscriptionPlan.prototype.getPaymentType = function() {
  return /** @type {!proto.types.SubscriptionPlan.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.types.SubscriptionPlan.PaymentType} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setPaymentType = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.clearPaymentType = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.hasPaymentType = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated Feature features = 11;
 * @return {!Array<!proto.types.Feature>}
 */
proto.types.SubscriptionPlan.prototype.getFeaturesList = function() {
  return /** @type {!Array<!proto.types.Feature>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.types.Feature>} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setFeaturesList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.types.Feature} value
 * @param {number=} opt_index
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.addFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.clearFeaturesList = function() {
  return this.setFeaturesList([]);
};


/**
 * repeated string feature_descriptions = 12;
 * @return {!Array<string>}
 */
proto.types.SubscriptionPlan.prototype.getFeatureDescriptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setFeatureDescriptionsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.addFeatureDescriptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.clearFeatureDescriptionsList = function() {
  return this.setFeatureDescriptionsList([]);
};


/**
 * optional bool recommended_plan = 13;
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.getRecommendedPlan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setRecommendedPlan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool default_plan = 14;
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.getDefaultPlan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setDefaultPlan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string partner_id = 15;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string external_payment_url = 16;
 * @return {string}
 */
proto.types.SubscriptionPlan.prototype.getExternalPaymentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.setExternalPaymentUrl = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SubscriptionPlan} returns this
 */
proto.types.SubscriptionPlan.prototype.clearExternalPaymentUrl = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SubscriptionPlan.prototype.hasExternalPaymentUrl = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * @enum {number}
 */
proto.types.SubscriptionPlanOld = {
  FREE: 0,
  PRO: 1
};

goog.object.extend(exports, proto.types);
