
import { RemoteDataVue } from "@/store/utils/remote-data";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Progress extends RemoteDataVue {
  @Prop({ default: "primary" }) color: string;
  @Prop({ default: null }) alignment: string | null;

  get clazz(): { [clazz: string]: boolean } {
    return {
      center: this.alignment === "center",
    };
  }
}
