
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import BitShakeManualInstructions from "./BitShakeManualInstructions.vue";
import BitShakeAppInstructions from "./BitShakeAppInstructions.vue";

@Component({
  components: {
    BitShakeManualInstructions,
    BitShakeAppInstructions,
  },
})
export default class BitShakeInstructions extends Vue {
  @partnerModule.Getter supportEmail: string;
  @partnerModule.Getter partnerName: string;
  paths = paths;
  tab: number = 0;
  get items(): any {
    return ["Manuell Einrichten", `Über ${this.partnerName} App`];
  }
  changeTab(tab: number): void {
    this.tab = tab;
  }
}
