import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { namespace } from "vuex-class";
import { failure, initialized, pending, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";

import spaces from "./spaces";
import { advisoryServiceClient } from "@/config/service-clients";
import { GetTipsRequest, GetEnergyConsumersRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { EnergyConsumer, Tip } from "zaehlerfreunde-proto-types/tips_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "tips",
})
class TipsModule extends VuexModule {
  tips: RemoteData<UserError, Tip[]> = initialized;
  energyConsumers: RemoteData<UserError, EnergyConsumer[]> = initialized;

  @Action
  async getTips() {
    try {
      this.setTips(pending);

      const request = new GetTipsRequest();
      const selectedSpace = spaces.selectedSpace;
      if (selectedSpace != null) {
        request.setSpaceId(selectedSpace.getId());
      }

      const response = await advisoryServiceClient.getTips(request, {});

      this.setTips(success(response.getTipsList()));
    } catch (error) {
      this.setTips(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getEnergyConsumers() {
    try {
      this.setEnergyConsumers(pending);

      const request = new GetEnergyConsumersRequest();
      request.setSpaceId(spaces.selectedSpaceId);

      const response = await advisoryServiceClient.getEnergyConsumers(request, {});

      this.setEnergyConsumers(success(response.getEnergyConsumersList()));
    } catch (error) {
      this.setEnergyConsumers(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setTips(v: RemoteData<UserError, Tip[]>) {
    this.tips = v;
  }

  @Mutation
  setEnergyConsumers(v: RemoteData<UserError, EnergyConsumer[]>) {
    this.energyConsumers = v;
  }
}

export const tipsModule = namespace("tips");
export default getModule(TipsModule);
