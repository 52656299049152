
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class TariffWatchdogExplanation extends Vue {
  @Prop() value: boolean;

  show: boolean = false;
  supplierCustomerId: string = "";

  steps = [
    {
      title: "Tarifdetails Angeben",
      description:
        "Bitte gib deine Tarifdetails an. Diese nutzen wir um für deinen individuellen Verbrauch den passenden Tarif zu finden.",
      icon: "mdi-pen",
    },
    {
      title: "Gefundene Tarife Einsehen",
      description: "Die von uns gefundenen Tarife, lassen sich stets auf unserer Plattform einsehen.",
      icon: "mdi-magnify",
    },
    {
      title: "Automatischer Wechsel",
      description:
        "Sobald es dein Vertrag zulässt, wechseln wir automatisch in den günstigsten Tarif. Über geplante Wechsel wirst du eine Woche im Vorraus informiert und kannst den Wechsel bei Bedarf widerrufen.",
      icon: "mdi-swap-horizontal",
    },
    {
      title: "Jedes Jahr sparen",
      description:
        "Auch nach dem Wechsel suchen wir weiter nach günstigeren Tarifen und wechseln erneut wenn es deine Fristen zulassen. Sollten wir übrigens von deinem neuen Anbieter eine Wechselprovision erhalten, spenden wir 10% davon an ein gemeinnütziges Projekt deiner Wahl.",
      icon: "mdi-calendar-refresh",
    },
  ];

  @Watch("value")
  onValueChanged(): void {
    this.show = this.value;
  }

  @Watch("show")
  onShowChanged(): void {
    this.$emit("input", this.show);
  }
}
