import { render, staticRenderFns } from "./MonthlyPayments.vue?vue&type=template&id=3970c86e&scoped=true"
import script from "./MonthlyPayments.vue?vue&type=script&lang=ts"
export * from "./MonthlyPayments.vue?vue&type=script&lang=ts"
import style0 from "./MonthlyPayments.vue?vue&type=style&index=0&id=3970c86e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3970c86e",
  null
  
)

export default component.exports