
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import ChangeEnergyConsumers from "@/components/ChangeEnergyConsumers.vue";
import { tipsModule } from "@/store/modules/tips";
import { EnergyConsumer } from "zaehlerfreunde-proto-types/tips_pb";

@Component({
  components: {
    ChangeEnergyConsumers,
  },
})
export default class EnergyConsumerInfos extends Vue {
  @tipsModule.State energyConsumers: RemoteData<UserError, EnergyConsumer[]>;

  getConsumersColumn(col: number): EnergyConsumer[] {
    const energyConsumers = this.energyConsumers.list.filter((e) => e.getCount() > 0);

    return energyConsumers.slice(
      Math.ceil((energyConsumers.length / 3) * col),
      Math.ceil((energyConsumers.length * (col + 1)) / 3)
    );
  }

  showChangeConsumersDialog: boolean = false;

  get noConsumers(): boolean {
    return this.energyConsumers.succeeded && !this.energyConsumers.list.some((e) => e.getCount() > 0);
  }
}
