
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { DeviceRegistration, TsunRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { TsunDevice, GetTsunDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { paths } from "@/router/routes";

@Component
export default class TsunConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  devices: RemoteData<UserError, TsunDevice[]> = initialized;
  email: string = "";
  password: string = "";
  selectedDevice: string = "";
  acceptedTerms: boolean = false;

  deviceName(device: TsunDevice): string {
    return device.getName();
  }

  deviceValue(device: TsunDevice): string {
    return device.getPowerStationGuid();
  }

  deviceLocation(device: TsunDevice): string {
    return device.getLocation();
  }

  async onLoginClicked(): Promise<void> {
    try {
      this.devices = pending;
      const request = new GetTsunDevicesRequest();
      request.setEmail(this.email);
      request.setPassword(this.password);

      const response = await deviceServiceClient.getTsunDevices(request, {});
      this.devices = success(response.getDevicesList());
    } catch (error) {
      this.devices = failure(userErrorFrom(error));
    }
  }

  back(): void {
    this.$router.go(-1);
  }

  async onRegisterClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice ?? "false");
    registration.setSpaceMapping(spaceMapping);

    const details = new TsunRegistrationDetails();
    details.setTsunDeviceGuid(this.selectedDevice);
    details.setEmail(this.email);
    details.setPassword(this.password);

    registration.setTsun(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
