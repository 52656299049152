
import { isSuccess, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import ConsumptionTargetProgress from "./ConsumptionTargetProgress.vue";
import SetConsumptionTarget from "./SetConsumptionTarget.vue";
import ConsumptionTargetMissing from "./ConsumptionTargetMissing.vue";
import consumption, { consumptionModule } from "@/store/modules/consumption";
import devices, { devicesModule } from "@/store/modules/devices";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { AccumulatedValue } from "zaehlerfreunde-central/reading_service_pb";
import { paths } from "@/router/routes";

@Component({
  components: {
    ConsumptionTargetProgress,
    SetConsumptionTarget,
    ConsumptionTargetMissing,
  },
})
export default class ConsumptionTarget extends Vue {
  @consumptionModule.Getter consumptionTarget: RemoteData<UserError, number>;
  @consumptionModule.Getter currentMonthConsumptionOfSelectedSpace: RemoteData<UserError, AccumulatedValue>;

  @devicesModule.Getter mainDevice: RemoteData<UserError, Device>;

  showSetTargetDialog: boolean = false;

  paths = paths;

  mounted(): void {
    devices.getMainDevice();
  }

  get currentTargetPercentage(): number {
    const currentConsumption = this.currentMonthConsumptionOfSelectedSpace.data?.getValue() ?? 0;

    if (isSuccess(this.consumptionTarget)) {
      return (currentConsumption * 100) / this.consumptionTarget.data;
    }

    return 0;
  }

  get consumptionTargetIsMissing(): boolean {
    return this.consumptionTarget.succeeded && !this.consumptionTarget.data;
  }

  get noMainDevice(): boolean {
    return this.mainDevice.succeeded && !this.mainDevice.data;
  }

  beforeMount(): void {
    consumption.getConsumptionTarget();
  }

  onSetTargetCancel(): void {
    this.showSetTargetDialog = false;
  }

  onSetTargetSave(target: number): void {
    consumption.updateConsumptionTarget(target);

    this.$watch("consumptionTarget", (target: RemoteData<UserError, number>) => {
      if (isSuccess(target)) {
        this.showSetTargetDialog = false;
      }
    });
  }
}
