
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { InstructionData } from "./Instructions.vue";

@Component
export default class OcppInstructions extends Vue {
  @partnerModule.Getter supportEmail: string;
  paths = paths;

  goToInstruction(instructiondata: InstructionData): void {
    this.$router.push(instructiondata.instructionsPath);
  }

  goBack(): void {
    this.$router.replace(paths.instructionsPath);
  }

  ocppInstructions: InstructionData[] = [
    {
      name: "GO-E",
      description: "go-e charger V3",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/go-e.png",
      instructionsPath: paths.instructions.goe,
    },
    {
      name: "KEBA",
      description: "",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/keba.png",
      instructionsPath: paths.instructions.keba,
    },
    {
      name: "Webasto",
      description: "",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/webasto.png",
      instructionsPath: paths.instructions.webasto,
    },
    {
      name: "EV Box",
      description: "",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/evbox.png",
      instructionsPath: paths.instructions.evbox,
    },
    {
      name: "Juice",
      description: "Juice Charger me3",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/juice.png",
      instructionsPath: paths.instructions.juice,
    },
    {
      name: "Myenergi",
      description: "Myenergi Zappi",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/myenerghi.png",
      instructionsPath: paths.instructions.myenergi,
    },
    {
      name: "Heidelberg",
      description: "",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/heidelberg.png",
      instructionsPath: paths.instructions.heidelberg,
    },
    {
      name: "ABL",
      description: "ABL eMH3",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/abl.png",
      instructionsPath: paths.instructions.abl,
    },
    {
      name: "Wallbox",
      description: "Wallbox COPPER SB & Pulsar Plus",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/wallbox.png",
      instructionsPath: paths.instructions.wallbox,
    },
    {
      name: "Charge Amps",
      description: "Charge Amps Dawn",
      imageURL: "https://cdn.zaehlerfreunde.com/device-provider-logos/chargeamps.png",
      instructionsPath: paths.instructions.chargeamps,
    },
  ];
}
