export const formatNumber = (value: number): string =>
  value?.toLocaleString(window.navigator.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatNumerOptional = (value: number | undefined): string | undefined =>
  value?.toLocaleString(window.navigator.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export function parseFloatOptional(value: string): number | undefined {
  if (value.includes(",")) {
    value = value.replace(".", "").replace(",", ".");
  }

  const floatValue = parseFloat(value);
  if (!isNaN(floatValue)) {
    return floatValue;
  }
}
