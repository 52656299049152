// source: types/tariff.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_datetime_pb = require('../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
var types_device_pb = require('../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
goog.exportSymbol('proto.types.CharityProject', null, global);
goog.exportSymbol('proto.types.GasEnergyValue', null, global);
goog.exportSymbol('proto.types.SearchResult', null, global);
goog.exportSymbol('proto.types.SearchResult.Certificate', null, global);
goog.exportSymbol('proto.types.SearchResult.Detail', null, global);
goog.exportSymbol('proto.types.SearchResult.Supplier', null, global);
goog.exportSymbol('proto.types.SepaDetails', null, global);
goog.exportSymbol('proto.types.Tariff', null, global);
goog.exportSymbol('proto.types.Tariff.AdvancePayment', null, global);
goog.exportSymbol('proto.types.Tariff.Contract', null, global);
goog.exportSymbol('proto.types.Tariff.PriceComponent', null, global);
goog.exportSymbol('proto.types.Tariff.PriceComponent.Type', null, global);
goog.exportSymbol('proto.types.Tariff.PriceComponent.Unit', null, global);
goog.exportSymbol('proto.types.Tariff.PriceComponentInfo', null, global);
goog.exportSymbol('proto.types.TariffSearchInfo', null, global);
goog.exportSymbol('proto.types.TariffSearchInfo.LatestSearch', null, global);
goog.exportSymbol('proto.types.TariffSwitch', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Tariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Tariff.repeatedFields_, null);
};
goog.inherits(proto.types.Tariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Tariff.displayName = 'proto.types.Tariff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Tariff.PriceComponent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Tariff.PriceComponent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Tariff.PriceComponent.displayName = 'proto.types.Tariff.PriceComponent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Tariff.PriceComponentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Tariff.PriceComponentInfo.repeatedFields_, null);
};
goog.inherits(proto.types.Tariff.PriceComponentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Tariff.PriceComponentInfo.displayName = 'proto.types.Tariff.PriceComponentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Tariff.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Tariff.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Tariff.Contract.displayName = 'proto.types.Tariff.Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Tariff.AdvancePayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Tariff.AdvancePayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Tariff.AdvancePayment.displayName = 'proto.types.Tariff.AdvancePayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.SearchResult.repeatedFields_, null);
};
goog.inherits(proto.types.SearchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.displayName = 'proto.types.SearchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult.Supplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SearchResult.Supplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.Supplier.displayName = 'proto.types.SearchResult.Supplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult.Certificate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SearchResult.Certificate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.Certificate.displayName = 'proto.types.SearchResult.Certificate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult.Detail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SearchResult.Detail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.Detail.displayName = 'proto.types.SearchResult.Detail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TariffSwitch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TariffSwitch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TariffSwitch.displayName = 'proto.types.TariffSwitch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TariffSearchInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TariffSearchInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TariffSearchInfo.displayName = 'proto.types.TariffSearchInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TariffSearchInfo.LatestSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TariffSearchInfo.LatestSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TariffSearchInfo.LatestSearch.displayName = 'proto.types.TariffSearchInfo.LatestSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SepaDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SepaDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SepaDetails.displayName = 'proto.types.SepaDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.CharityProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.CharityProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.CharityProject.displayName = 'proto.types.CharityProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.GasEnergyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.GasEnergyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.GasEnergyValue.displayName = 'proto.types.GasEnergyValue';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Tariff.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Tariff.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Tariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Tariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 6, 0),
    priceComponentsList: jspb.Message.toObjectList(msg.getPriceComponentsList(),
    proto.types.Tariff.PriceComponent.toObject, includeInstance),
    isGreen: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    contract: (f = msg.getContract()) && proto.types.Tariff.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Tariff}
 */
proto.types.Tariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Tariff;
  return proto.types.Tariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Tariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Tariff}
 */
proto.types.Tariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplier(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    case 7:
      var value = new proto.types.Tariff.PriceComponent;
      reader.readMessage(value,proto.types.Tariff.PriceComponent.deserializeBinaryFromReader);
      msg.addPriceComponents(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGreen(value);
      break;
    case 9:
      var value = new proto.types.Tariff.Contract;
      reader.readMessage(value,proto.types.Tariff.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Tariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Tariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Tariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPriceComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.types.Tariff.PriceComponent.serializeBinaryToWriter
    );
  }
  f = message.getIsGreen();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.types.Tariff.Contract.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Tariff.PriceComponent.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Tariff.PriceComponent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Tariff.PriceComponent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.PriceComponent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unitDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Tariff.PriceComponent}
 */
proto.types.Tariff.PriceComponent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Tariff.PriceComponent;
  return proto.types.Tariff.PriceComponent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Tariff.PriceComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Tariff.PriceComponent}
 */
proto.types.Tariff.PriceComponent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Tariff.PriceComponent.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Tariff.PriceComponent.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Tariff.PriceComponent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Tariff.PriceComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Tariff.PriceComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.PriceComponent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUnitDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.Tariff.PriceComponent.Unit = {
  CENT_PER_KILO_WATT_HOUR: 0,
  EURO_PER_MONTH: 1,
  EURO_PER_YEAR: 2,
  KG_CO2_PER_KILO_WATT_HOUR: 3,
  EURO_PER_CUBIC_METER: 4
};

/**
 * @enum {number}
 */
proto.types.Tariff.PriceComponent.Type = {
  STATIC_PRICE: 0,
  EPEX_SPOT_PRICE: 1,
  CO2_EMISSIONS: 2,
  MAX_PRICE: 3,
  MIN_PRICE: 4,
  RESIDUAL_PRICE: 5,
  SOLAR_PRICE: 6,
  GRID_COST: 7
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Tariff.PriceComponent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.Tariff.PriceComponent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.types.Tariff.PriceComponent.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.clearValue = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.PriceComponent.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Unit unit = 4;
 * @return {!proto.types.Tariff.PriceComponent.Unit}
 */
proto.types.Tariff.PriceComponent.prototype.getUnit = function() {
  return /** @type {!proto.types.Tariff.PriceComponent.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Tariff.PriceComponent.Unit} value
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string unit_description = 5;
 * @return {string}
 */
proto.types.Tariff.PriceComponent.prototype.getUnitDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.setUnitDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Type type = 6;
 * @return {!proto.types.Tariff.PriceComponent.Type}
 */
proto.types.Tariff.PriceComponent.prototype.getType = function() {
  return /** @type {!proto.types.Tariff.PriceComponent.Type} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Tariff.PriceComponent.Type} value
 * @return {!proto.types.Tariff.PriceComponent} returns this
 */
proto.types.Tariff.PriceComponent.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Tariff.PriceComponentInfo.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Tariff.PriceComponentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Tariff.PriceComponentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Tariff.PriceComponentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.PriceComponentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    typeDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allowedUnitsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    allowedUnitsDescriptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    requiresValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Tariff.PriceComponentInfo}
 */
proto.types.Tariff.PriceComponentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Tariff.PriceComponentInfo;
  return proto.types.Tariff.PriceComponentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Tariff.PriceComponentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Tariff.PriceComponentInfo}
 */
proto.types.Tariff.PriceComponentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Tariff.PriceComponent.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeDescription(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.types.Tariff.PriceComponent.Unit>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllowedUnits(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedUnitsDescriptions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiresValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Tariff.PriceComponentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Tariff.PriceComponentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Tariff.PriceComponentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.PriceComponentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTypeDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllowedUnitsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getAllowedUnitsDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRequiresValue();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional PriceComponent.Type type = 1;
 * @return {!proto.types.Tariff.PriceComponent.Type}
 */
proto.types.Tariff.PriceComponentInfo.prototype.getType = function() {
  return /** @type {!proto.types.Tariff.PriceComponent.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Tariff.PriceComponent.Type} value
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string type_description = 2;
 * @return {string}
 */
proto.types.Tariff.PriceComponentInfo.prototype.getTypeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.setTypeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PriceComponent.Unit allowed_units = 3;
 * @return {!Array<!proto.types.Tariff.PriceComponent.Unit>}
 */
proto.types.Tariff.PriceComponentInfo.prototype.getAllowedUnitsList = function() {
  return /** @type {!Array<!proto.types.Tariff.PriceComponent.Unit>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.types.Tariff.PriceComponent.Unit>} value
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.setAllowedUnitsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.types.Tariff.PriceComponent.Unit} value
 * @param {number=} opt_index
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.addAllowedUnits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.clearAllowedUnitsList = function() {
  return this.setAllowedUnitsList([]);
};


/**
 * repeated string allowed_units_descriptions = 4;
 * @return {!Array<string>}
 */
proto.types.Tariff.PriceComponentInfo.prototype.getAllowedUnitsDescriptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.setAllowedUnitsDescriptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.addAllowedUnitsDescriptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.clearAllowedUnitsDescriptionsList = function() {
  return this.setAllowedUnitsDescriptionsList([]);
};


/**
 * optional bool requires_value = 5;
 * @return {boolean}
 */
proto.types.Tariff.PriceComponentInfo.prototype.getRequiresValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.Tariff.PriceComponentInfo} returns this
 */
proto.types.Tariff.PriceComponentInfo.prototype.setRequiresValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Tariff.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Tariff.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Tariff.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tariffId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    earliestCancelDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    spaceName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    spaceAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Tariff.Contract}
 */
proto.types.Tariff.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Tariff.Contract;
  return proto.types.Tariff.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Tariff.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Tariff.Contract}
 */
proto.types.Tariff.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTariffId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEarliestCancelDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceAddress(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Tariff.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Tariff.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Tariff.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTariffId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tariff_id = 3;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setTariffId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_date = 4;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.Contract.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string earliest_cancel_date = 5;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getEarliestCancelDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setEarliestCancelDate = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.clearEarliestCancelDate = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.Contract.prototype.hasEarliestCancelDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string customer_id = 6;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setCustomerId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.clearCustomerId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.Contract.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string space_name = 7;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getSpaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setSpaceName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.clearSpaceName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.Contract.prototype.hasSpaceName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string space_address = 8;
 * @return {string}
 */
proto.types.Tariff.Contract.prototype.getSpaceAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setSpaceAddress = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.clearSpaceAddress = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.Contract.prototype.hasSpaceAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_active = 9;
 * @return {boolean}
 */
proto.types.Tariff.Contract.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.Tariff.Contract} returns this
 */
proto.types.Tariff.Contract.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Tariff.AdvancePayment.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Tariff.AdvancePayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Tariff.AdvancePayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.AdvancePayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contractId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    period: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Tariff.AdvancePayment}
 */
proto.types.Tariff.AdvancePayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Tariff.AdvancePayment;
  return proto.types.Tariff.AdvancePayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Tariff.AdvancePayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Tariff.AdvancePayment}
 */
proto.types.Tariff.AdvancePayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Tariff.AdvancePayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Tariff.AdvancePayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Tariff.AdvancePayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariff.AdvancePayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContractId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStartMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPeriod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Tariff.AdvancePayment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.AdvancePayment} returns this
 */
proto.types.Tariff.AdvancePayment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contract_id = 2;
 * @return {string}
 */
proto.types.Tariff.AdvancePayment.prototype.getContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.AdvancePayment} returns this
 */
proto.types.Tariff.AdvancePayment.prototype.setContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 start_year = 3;
 * @return {number}
 */
proto.types.Tariff.AdvancePayment.prototype.getStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Tariff.AdvancePayment} returns this
 */
proto.types.Tariff.AdvancePayment.prototype.setStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 start_month = 4;
 * @return {number}
 */
proto.types.Tariff.AdvancePayment.prototype.getStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Tariff.AdvancePayment} returns this
 */
proto.types.Tariff.AdvancePayment.prototype.setStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double amount = 5;
 * @return {number}
 */
proto.types.Tariff.AdvancePayment.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Tariff.AdvancePayment} returns this
 */
proto.types.Tariff.AdvancePayment.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string period = 6;
 * @return {string}
 */
proto.types.Tariff.AdvancePayment.prototype.getPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff.AdvancePayment} returns this
 */
proto.types.Tariff.AdvancePayment.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Tariff.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_user_id = 2;
 * @return {string}
 */
proto.types.Tariff.prototype.getOwnerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setOwnerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string partner_id = 3;
 * @return {string}
 */
proto.types.Tariff.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.types.Tariff.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier = 5;
 * @return {string}
 */
proto.types.Tariff.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Device.Medium medium = 6;
 * @return {!proto.types.Device.Medium}
 */
proto.types.Tariff.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated PriceComponent price_components = 7;
 * @return {!Array<!proto.types.Tariff.PriceComponent>}
 */
proto.types.Tariff.prototype.getPriceComponentsList = function() {
  return /** @type{!Array<!proto.types.Tariff.PriceComponent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Tariff.PriceComponent, 7));
};


/**
 * @param {!Array<!proto.types.Tariff.PriceComponent>} value
 * @return {!proto.types.Tariff} returns this
*/
proto.types.Tariff.prototype.setPriceComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.types.Tariff.PriceComponent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Tariff.PriceComponent}
 */
proto.types.Tariff.prototype.addPriceComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.types.Tariff.PriceComponent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.clearPriceComponentsList = function() {
  return this.setPriceComponentsList([]);
};


/**
 * optional bool is_green = 8;
 * @return {boolean}
 */
proto.types.Tariff.prototype.getIsGreen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.setIsGreen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional Contract contract = 9;
 * @return {?proto.types.Tariff.Contract}
 */
proto.types.Tariff.prototype.getContract = function() {
  return /** @type{?proto.types.Tariff.Contract} */ (
    jspb.Message.getWrapperField(this, proto.types.Tariff.Contract, 9));
};


/**
 * @param {?proto.types.Tariff.Contract|undefined} value
 * @return {!proto.types.Tariff} returns this
*/
proto.types.Tariff.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Tariff} returns this
 */
proto.types.Tariff.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariff.prototype.hasContract = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.SearchResult.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: (f = msg.getSupplier()) && proto.types.SearchResult.Supplier.toObject(includeInstance, f),
    pricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    basePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    priceFirstYear: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    savings: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    certificatesList: jspb.Message.toObjectList(msg.getCertificatesList(),
    proto.types.SearchResult.Certificate.toObject, includeInstance),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.types.SearchResult.Detail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult}
 */
proto.types.SearchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult;
  return proto.types.SearchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult}
 */
proto.types.SearchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTariffName(value);
      break;
    case 2:
      var value = new proto.types.SearchResult.Supplier;
      reader.readMessage(value,proto.types.SearchResult.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerKwh(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasePrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceFirstYear(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSavings(value);
      break;
    case 7:
      var value = new proto.types.SearchResult.Certificate;
      reader.readMessage(value,proto.types.SearchResult.Certificate.deserializeBinaryFromReader);
      msg.addCertificates(value);
      break;
    case 8:
      var value = new proto.types.SearchResult.Detail;
      reader.readMessage(value,proto.types.SearchResult.Detail.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.SearchResult.Supplier.serializeBinaryToWriter
    );
  }
  f = message.getPricePerKwh();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBasePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPriceFirstYear();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSavings();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.types.SearchResult.Certificate.serializeBinaryToWriter
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.types.SearchResult.Detail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.Supplier.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.Supplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult.Supplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Supplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult.Supplier}
 */
proto.types.SearchResult.Supplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult.Supplier;
  return proto.types.SearchResult.Supplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult.Supplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult.Supplier}
 */
proto.types.SearchResult.Supplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.Supplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.Supplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult.Supplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Supplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.SearchResult.Supplier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.types.SearchResult.Supplier.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.setLink = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.clearLink = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.Supplier.prototype.hasLink = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.types.SearchResult.Supplier.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.setLogoUrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.clearLogoUrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.Supplier.prototype.hasLogoUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.Certificate.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.Certificate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult.Certificate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Certificate.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    getagId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult.Certificate}
 */
proto.types.SearchResult.Certificate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult.Certificate;
  return proto.types.SearchResult.Certificate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult.Certificate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult.Certificate}
 */
proto.types.SearchResult.Certificate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGetagId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.Certificate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.Certificate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult.Certificate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Certificate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGetagId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.SearchResult.Certificate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo = 2;
 * @return {string}
 */
proto.types.SearchResult.Certificate.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.setLogo = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.clearLogo = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.Certificate.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 getag_id = 3;
 * @return {number}
 */
proto.types.SearchResult.Certificate.prototype.getGetagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.setGetagId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.Detail.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.Detail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult.Detail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Detail.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult.Detail}
 */
proto.types.SearchResult.Detail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult.Detail;
  return proto.types.SearchResult.Detail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult.Detail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult.Detail}
 */
proto.types.SearchResult.Detail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.Detail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.Detail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult.Detail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Detail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.types.SearchResult.Detail.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Detail} returns this
 */
proto.types.SearchResult.Detail.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.types.SearchResult.Detail.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Detail} returns this
 */
proto.types.SearchResult.Detail.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tariff_name = 1;
 * @return {string}
 */
proto.types.SearchResult.prototype.getTariffName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setTariffName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Supplier supplier = 2;
 * @return {?proto.types.SearchResult.Supplier}
 */
proto.types.SearchResult.prototype.getSupplier = function() {
  return /** @type{?proto.types.SearchResult.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.types.SearchResult.Supplier, 2));
};


/**
 * @param {?proto.types.SearchResult.Supplier|undefined} value
 * @return {!proto.types.SearchResult} returns this
*/
proto.types.SearchResult.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double price_per_kwh = 3;
 * @return {number}
 */
proto.types.SearchResult.prototype.getPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setPricePerKwh = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double base_price = 4;
 * @return {number}
 */
proto.types.SearchResult.prototype.getBasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setBasePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double price_first_year = 5;
 * @return {number}
 */
proto.types.SearchResult.prototype.getPriceFirstYear = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setPriceFirstYear = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double savings = 6;
 * @return {number}
 */
proto.types.SearchResult.prototype.getSavings = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setSavings = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * repeated Certificate certificates = 7;
 * @return {!Array<!proto.types.SearchResult.Certificate>}
 */
proto.types.SearchResult.prototype.getCertificatesList = function() {
  return /** @type{!Array<!proto.types.SearchResult.Certificate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.SearchResult.Certificate, 7));
};


/**
 * @param {!Array<!proto.types.SearchResult.Certificate>} value
 * @return {!proto.types.SearchResult} returns this
*/
proto.types.SearchResult.prototype.setCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.types.SearchResult.Certificate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SearchResult.Certificate}
 */
proto.types.SearchResult.prototype.addCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.types.SearchResult.Certificate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.clearCertificatesList = function() {
  return this.setCertificatesList([]);
};


/**
 * repeated Detail details = 8;
 * @return {!Array<!proto.types.SearchResult.Detail>}
 */
proto.types.SearchResult.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.types.SearchResult.Detail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.SearchResult.Detail, 8));
};


/**
 * @param {!Array<!proto.types.SearchResult.Detail>} value
 * @return {!proto.types.SearchResult} returns this
*/
proto.types.SearchResult.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.types.SearchResult.Detail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SearchResult.Detail}
 */
proto.types.SearchResult.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.types.SearchResult.Detail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TariffSwitch.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TariffSwitch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TariffSwitch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSwitch.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchResult: (f = msg.getSearchResult()) && proto.types.SearchResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TariffSwitch}
 */
proto.types.TariffSwitch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TariffSwitch;
  return proto.types.TariffSwitch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TariffSwitch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TariffSwitch}
 */
proto.types.TariffSwitch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.SearchResult;
      reader.readMessage(value,proto.types.SearchResult.deserializeBinaryFromReader);
      msg.setSearchResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TariffSwitch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TariffSwitch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TariffSwitch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSwitch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.types.SearchResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchResult search_result = 1;
 * @return {?proto.types.SearchResult}
 */
proto.types.TariffSwitch.prototype.getSearchResult = function() {
  return /** @type{?proto.types.SearchResult} */ (
    jspb.Message.getWrapperField(this, proto.types.SearchResult, 1));
};


/**
 * @param {?proto.types.SearchResult|undefined} value
 * @return {!proto.types.TariffSwitch} returns this
*/
proto.types.TariffSwitch.prototype.setSearchResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TariffSwitch} returns this
 */
proto.types.TariffSwitch.prototype.clearSearchResult = function() {
  return this.setSearchResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TariffSwitch.prototype.hasSearchResult = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TariffSearchInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TariffSearchInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TariffSearchInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isWatchdogEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    latestSearch: (f = msg.getLatestSearch()) && proto.types.TariffSearchInfo.LatestSearch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TariffSearchInfo}
 */
proto.types.TariffSearchInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TariffSearchInfo;
  return proto.types.TariffSearchInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TariffSearchInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TariffSearchInfo}
 */
proto.types.TariffSearchInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWatchdogEnabled(value);
      break;
    case 2:
      var value = new proto.types.TariffSearchInfo.LatestSearch;
      reader.readMessage(value,proto.types.TariffSearchInfo.LatestSearch.deserializeBinaryFromReader);
      msg.setLatestSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TariffSearchInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TariffSearchInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TariffSearchInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsWatchdogEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLatestSearch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.TariffSearchInfo.LatestSearch.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TariffSearchInfo.LatestSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TariffSearchInfo.LatestSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.LatestSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    postcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currentCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TariffSearchInfo.LatestSearch}
 */
proto.types.TariffSearchInfo.LatestSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TariffSearchInfo.LatestSearch;
  return proto.types.TariffSearchInfo.LatestSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TariffSearchInfo.LatestSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TariffSearchInfo.LatestSearch}
 */
proto.types.TariffSearchInfo.LatestSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConsumption(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentCost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TariffSearchInfo.LatestSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TariffSearchInfo.LatestSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.LatestSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsumption();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrentCost();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string postcode = 1;
 * @return {string}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double consumption = 2;
 * @return {number}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setConsumption = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double current_cost = 3;
 * @return {number}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getCurrentCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setCurrentCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {number}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_watchdog_enabled = 1;
 * @return {boolean}
 */
proto.types.TariffSearchInfo.prototype.getIsWatchdogEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.TariffSearchInfo} returns this
 */
proto.types.TariffSearchInfo.prototype.setIsWatchdogEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional LatestSearch latest_search = 2;
 * @return {?proto.types.TariffSearchInfo.LatestSearch}
 */
proto.types.TariffSearchInfo.prototype.getLatestSearch = function() {
  return /** @type{?proto.types.TariffSearchInfo.LatestSearch} */ (
    jspb.Message.getWrapperField(this, proto.types.TariffSearchInfo.LatestSearch, 2));
};


/**
 * @param {?proto.types.TariffSearchInfo.LatestSearch|undefined} value
 * @return {!proto.types.TariffSearchInfo} returns this
*/
proto.types.TariffSearchInfo.prototype.setLatestSearch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TariffSearchInfo} returns this
 */
proto.types.TariffSearchInfo.prototype.clearLatestSearch = function() {
  return this.setLatestSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TariffSearchInfo.prototype.hasLatestSearch = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SepaDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SepaDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SepaDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SepaDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountHolder: jspb.Message.getFieldWithDefault(msg, 1, ""),
    street: jspb.Message.getFieldWithDefault(msg, 2, ""),
    streetNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    iban: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bic: jspb.Message.getFieldWithDefault(msg, 7, ""),
    birthDate: (f = msg.getBirthDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SepaDetails}
 */
proto.types.SepaDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SepaDetails;
  return proto.types.SepaDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SepaDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SepaDetails}
 */
proto.types.SepaDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountHolder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIban(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBic(value);
      break;
    case 8:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setBirthDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SepaDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SepaDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SepaDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SepaDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountHolder();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStreetNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIban();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBic();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBirthDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_holder = 1;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getAccountHolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setAccountHolder = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string street = 2;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string street_no = 3;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getStreetNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setStreetNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string postcode = 4;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string iban = 6;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getIban = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setIban = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bic = 7;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getBic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setBic = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.DateTime birth_date = 8;
 * @return {?proto.google.type.DateTime}
 */
proto.types.SepaDetails.prototype.getBirthDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 8));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.types.SepaDetails} returns this
*/
proto.types.SepaDetails.prototype.setBirthDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.clearBirthDate = function() {
  return this.setBirthDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SepaDetails.prototype.hasBirthDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.CharityProject.prototype.toObject = function(opt_includeInstance) {
  return proto.types.CharityProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.CharityProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.CharityProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partner: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    image: jspb.Message.getFieldWithDefault(msg, 5, ""),
    moneyNeeded: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    moneyRaised: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    link: jspb.Message.getFieldWithDefault(msg, 8, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.CharityProject}
 */
proto.types.CharityProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.CharityProject;
  return proto.types.CharityProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.CharityProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.CharityProject}
 */
proto.types.CharityProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoneyNeeded(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoneyRaised(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.CharityProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.CharityProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.CharityProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.CharityProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMoneyNeeded();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMoneyRaised();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.CharityProject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partner = 2;
 * @return {string}
 */
proto.types.CharityProject.prototype.getPartner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setPartner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.types.CharityProject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.types.CharityProject.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.types.CharityProject.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float money_needed = 6;
 * @return {number}
 */
proto.types.CharityProject.prototype.getMoneyNeeded = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setMoneyNeeded = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float money_raised = 7;
 * @return {number}
 */
proto.types.CharityProject.prototype.getMoneyRaised = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setMoneyRaised = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string link = 8;
 * @return {string}
 */
proto.types.CharityProject.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool selected = 9;
 * @return {boolean}
 */
proto.types.CharityProject.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.GasEnergyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.types.GasEnergyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.GasEnergyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GasEnergyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    correctionFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.GasEnergyValue}
 */
proto.types.GasEnergyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.GasEnergyValue;
  return proto.types.GasEnergyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.GasEnergyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.GasEnergyValue}
 */
proto.types.GasEnergyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnergyValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCorrectionFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.GasEnergyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.GasEnergyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.GasEnergyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GasEnergyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCorrectionFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double energy_value = 1;
 * @return {number}
 */
proto.types.GasEnergyValue.prototype.getEnergyValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasEnergyValue} returns this
 */
proto.types.GasEnergyValue.prototype.setEnergyValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double correction_factor = 2;
 * @return {number}
 */
proto.types.GasEnergyValue.prototype.getCorrectionFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasEnergyValue} returns this
 */
proto.types.GasEnergyValue.prototype.setCorrectionFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


goog.object.extend(exports, proto.types);
