
import account, { accountModule } from "@/store/modules/account";
import { RemoteCall, toNullableError } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class ChangeUserName extends Vue {
  @accountModule.State changeUserNameCall: RemoteCall<UserError>;

  userName: string = "";

  get validInput(): boolean {
    return this.userName.length > 0;
  }

  get error(): string | undefined {
    return toNullableError(this.changeUserNameCall)?.message;
  }

  onCancelClicked(): void {
    this.$emit("close");
  }

  onSaveClicked(): void {
    account.changeUserName(this.userName);
  }

  @Watch("changeUserNameCall")
  onChangeUserNameCallChanged(): void {
    if (this.changeUserNameCall.succeeded) {
      this.$emit("close");
    }
  }
}
