
import { accountModule } from "@/store/modules/account";
import admin, { adminModule } from "@/store/modules/admin";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { User } from "@auth0/auth0-spa-js";
import { User as UserDetails } from "zaehlerfreunde-proto-types/user_pb";
import { Vue, Component } from "vue-property-decorator";
import { paths } from "@/router/routes";
import { partnerAuthConfig } from "@/config/auth-config";

@Component
export default class AccountDropdown extends Vue {
  @accountModule.State user: RemoteData<UserError, User>;
  @accountModule.State userDetails: RemoteData<UserError, UserDetails>;
  @adminModule.State isAdminUser: RemoteData<UserError, boolean>;

  paths = paths;

  get name(): string {
    return this.userDetails.data?.getName() ?? "";
  }

  get initials(): string {
    return (this.userDetails.data?.getName() ?? " ")[0];
  }

  get emailAddress(): string | undefined {
    return this.user.data?.email;
  }

  get isAdmin(): boolean {
    return this.isAdminUser.data?.valueOf() ?? false;
  }

  get isOnAdminPlatform(): boolean {
    return this.$route.path.includes("admin");
  }

  beforeMount(): void {
    admin.checkAdminStatus();
  }

  async logout(): Promise<void> {
    Vue.$cookies.remove("demoToken");
    const authConfig = await partnerAuthConfig;
    this.$auth.logout({
      logoutParams: {
        returnTo: authConfig.getLogoutRedirectUrl(),
      },
    });
  }
}
