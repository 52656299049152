
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";

@Component({
  components: {},
})
export default class PendingSwitchBanner extends Vue {
  @tariffModule.Getter pendingTariffSwitch: RemoteData<UserError, TariffSwitch | null>;

  get newSupplier(): string {
    return this.pendingTariffSwitch.data?.getSearchResult()?.getSupplier()?.getName() ?? "";
  }
}
