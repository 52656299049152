/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_tariff_service_pb from '../../services/central/tariff_service_pb';


export class TariffServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetTariffsOfSpace = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetTariffsOfSpace',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetTariffsOfSpaceRequest,
    services_central_tariff_service_pb.GetTariffsOfSpaceResponse,
    (request: services_central_tariff_service_pb.GetTariffsOfSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetTariffsOfSpaceResponse.deserializeBinary
  );

  getTariffsOfSpace(
    request: services_central_tariff_service_pb.GetTariffsOfSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetTariffsOfSpaceResponse>;

  getTariffsOfSpace(
    request: services_central_tariff_service_pb.GetTariffsOfSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetTariffsOfSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetTariffsOfSpaceResponse>;

  getTariffsOfSpace(
    request: services_central_tariff_service_pb.GetTariffsOfSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetTariffsOfSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetTariffsOfSpace',
        request,
        metadata || {},
        this.methodInfoGetTariffsOfSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetTariffsOfSpace',
    request,
    metadata || {},
    this.methodInfoGetTariffsOfSpace);
  }

  methodInfoGetPartnerTariffs = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetPartnerTariffs',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetPartnerTariffsRequest,
    services_central_tariff_service_pb.GetPartnerTariffsResponse,
    (request: services_central_tariff_service_pb.GetPartnerTariffsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetPartnerTariffsResponse.deserializeBinary
  );

  getPartnerTariffs(
    request: services_central_tariff_service_pb.GetPartnerTariffsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetPartnerTariffsResponse>;

  getPartnerTariffs(
    request: services_central_tariff_service_pb.GetPartnerTariffsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPartnerTariffsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetPartnerTariffsResponse>;

  getPartnerTariffs(
    request: services_central_tariff_service_pb.GetPartnerTariffsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPartnerTariffsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetPartnerTariffs',
        request,
        metadata || {},
        this.methodInfoGetPartnerTariffs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetPartnerTariffs',
    request,
    metadata || {},
    this.methodInfoGetPartnerTariffs);
  }

  methodInfoGetTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetTariffRequest,
    services_central_tariff_service_pb.GetTariffResponse,
    (request: services_central_tariff_service_pb.GetTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetTariffResponse.deserializeBinary
  );

  getTariff(
    request: services_central_tariff_service_pb.GetTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetTariffResponse>;

  getTariff(
    request: services_central_tariff_service_pb.GetTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetTariffResponse>;

  getTariff(
    request: services_central_tariff_service_pb.GetTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetTariff',
        request,
        metadata || {},
        this.methodInfoGetTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetTariff',
    request,
    metadata || {},
    this.methodInfoGetTariff);
  }

  methodInfoGetContracts = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetContracts',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetContractsRequest,
    services_central_tariff_service_pb.GetContractsResponse,
    (request: services_central_tariff_service_pb.GetContractsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetContractsResponse.deserializeBinary
  );

  getContracts(
    request: services_central_tariff_service_pb.GetContractsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetContractsResponse>;

  getContracts(
    request: services_central_tariff_service_pb.GetContractsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetContractsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetContractsResponse>;

  getContracts(
    request: services_central_tariff_service_pb.GetContractsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetContractsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetContracts',
        request,
        metadata || {},
        this.methodInfoGetContracts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetContracts',
    request,
    metadata || {},
    this.methodInfoGetContracts);
  }

  methodInfoAddContract = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/AddContract',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.AddContractRequest,
    services_central_tariff_service_pb.AddContractResponse,
    (request: services_central_tariff_service_pb.AddContractRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.AddContractResponse.deserializeBinary
  );

  addContract(
    request: services_central_tariff_service_pb.AddContractRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.AddContractResponse>;

  addContract(
    request: services_central_tariff_service_pb.AddContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddContractResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.AddContractResponse>;

  addContract(
    request: services_central_tariff_service_pb.AddContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/AddContract',
        request,
        metadata || {},
        this.methodInfoAddContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/AddContract',
    request,
    metadata || {},
    this.methodInfoAddContract);
  }

  methodInfoDeleteContract = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/DeleteContract',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.DeleteContractRequest,
    services_central_tariff_service_pb.DeleteContractResponse,
    (request: services_central_tariff_service_pb.DeleteContractRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.DeleteContractResponse.deserializeBinary
  );

  deleteContract(
    request: services_central_tariff_service_pb.DeleteContractRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.DeleteContractResponse>;

  deleteContract(
    request: services_central_tariff_service_pb.DeleteContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteContractResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.DeleteContractResponse>;

  deleteContract(
    request: services_central_tariff_service_pb.DeleteContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/DeleteContract',
        request,
        metadata || {},
        this.methodInfoDeleteContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/DeleteContract',
    request,
    metadata || {},
    this.methodInfoDeleteContract);
  }

  methodInfoGetCurrentTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetCurrentTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetCurrentTariffRequest,
    services_central_tariff_service_pb.GetCurrentTariffResponse,
    (request: services_central_tariff_service_pb.GetCurrentTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetCurrentTariffResponse.deserializeBinary
  );

  getCurrentTariff(
    request: services_central_tariff_service_pb.GetCurrentTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetCurrentTariffResponse>;

  getCurrentTariff(
    request: services_central_tariff_service_pb.GetCurrentTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCurrentTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetCurrentTariffResponse>;

  getCurrentTariff(
    request: services_central_tariff_service_pb.GetCurrentTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCurrentTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetCurrentTariff',
        request,
        metadata || {},
        this.methodInfoGetCurrentTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetCurrentTariff',
    request,
    metadata || {},
    this.methodInfoGetCurrentTariff);
  }

  methodInfoAddTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/AddTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.AddTariffRequest,
    services_central_tariff_service_pb.AddTariffResponse,
    (request: services_central_tariff_service_pb.AddTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.AddTariffResponse.deserializeBinary
  );

  addTariff(
    request: services_central_tariff_service_pb.AddTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.AddTariffResponse>;

  addTariff(
    request: services_central_tariff_service_pb.AddTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.AddTariffResponse>;

  addTariff(
    request: services_central_tariff_service_pb.AddTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/AddTariff',
        request,
        metadata || {},
        this.methodInfoAddTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/AddTariff',
    request,
    metadata || {},
    this.methodInfoAddTariff);
  }

  methodInfoUpdateTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/UpdateTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.UpdateTariffRequest,
    services_central_tariff_service_pb.UpdateTariffResponse,
    (request: services_central_tariff_service_pb.UpdateTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.UpdateTariffResponse.deserializeBinary
  );

  updateTariff(
    request: services_central_tariff_service_pb.UpdateTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.UpdateTariffResponse>;

  updateTariff(
    request: services_central_tariff_service_pb.UpdateTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.UpdateTariffResponse>;

  updateTariff(
    request: services_central_tariff_service_pb.UpdateTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/UpdateTariff',
        request,
        metadata || {},
        this.methodInfoUpdateTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/UpdateTariff',
    request,
    metadata || {},
    this.methodInfoUpdateTariff);
  }

  methodInfoDeleteTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/DeleteTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.DeleteTariffRequest,
    services_central_tariff_service_pb.DeleteTariffResponse,
    (request: services_central_tariff_service_pb.DeleteTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.DeleteTariffResponse.deserializeBinary
  );

  deleteTariff(
    request: services_central_tariff_service_pb.DeleteTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.DeleteTariffResponse>;

  deleteTariff(
    request: services_central_tariff_service_pb.DeleteTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.DeleteTariffResponse>;

  deleteTariff(
    request: services_central_tariff_service_pb.DeleteTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/DeleteTariff',
        request,
        metadata || {},
        this.methodInfoDeleteTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/DeleteTariff',
    request,
    metadata || {},
    this.methodInfoDeleteTariff);
  }

  methodInfoGetPriceComponentInfos = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetPriceComponentInfos',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetPriceComponentInfosRequest,
    services_central_tariff_service_pb.GetPriceComponentInfosResponse,
    (request: services_central_tariff_service_pb.GetPriceComponentInfosRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetPriceComponentInfosResponse.deserializeBinary
  );

  getPriceComponentInfos(
    request: services_central_tariff_service_pb.GetPriceComponentInfosRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetPriceComponentInfosResponse>;

  getPriceComponentInfos(
    request: services_central_tariff_service_pb.GetPriceComponentInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPriceComponentInfosResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetPriceComponentInfosResponse>;

  getPriceComponentInfos(
    request: services_central_tariff_service_pb.GetPriceComponentInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPriceComponentInfosResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetPriceComponentInfos',
        request,
        metadata || {},
        this.methodInfoGetPriceComponentInfos,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetPriceComponentInfos',
    request,
    metadata || {},
    this.methodInfoGetPriceComponentInfos);
  }

  methodInfoUpdateSupplierCustomerId = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/UpdateSupplierCustomerId',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse,
    (request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse.deserializeBinary
  );

  updateSupplierCustomerId(
    request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse>;

  updateSupplierCustomerId(
    request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse>;

  updateSupplierCustomerId(
    request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/UpdateSupplierCustomerId',
        request,
        metadata || {},
        this.methodInfoUpdateSupplierCustomerId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/UpdateSupplierCustomerId',
    request,
    metadata || {},
    this.methodInfoUpdateSupplierCustomerId);
  }

  methodInfoGetSearchResults = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetSearchResults',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetSearchResultsRequest,
    services_central_tariff_service_pb.GetSearchResultsResponse,
    (request: services_central_tariff_service_pb.GetSearchResultsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetSearchResultsResponse.deserializeBinary
  );

  getSearchResults(
    request: services_central_tariff_service_pb.GetSearchResultsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetSearchResultsResponse>;

  getSearchResults(
    request: services_central_tariff_service_pb.GetSearchResultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchResultsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetSearchResultsResponse>;

  getSearchResults(
    request: services_central_tariff_service_pb.GetSearchResultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchResultsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetSearchResults',
        request,
        metadata || {},
        this.methodInfoGetSearchResults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetSearchResults',
    request,
    metadata || {},
    this.methodInfoGetSearchResults);
  }

  methodInfoGetPendingSwitch = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetPendingSwitch',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetPendingSwitchRequest,
    services_central_tariff_service_pb.GetPendingSwitchResponse,
    (request: services_central_tariff_service_pb.GetPendingSwitchRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetPendingSwitchResponse.deserializeBinary
  );

  getPendingSwitch(
    request: services_central_tariff_service_pb.GetPendingSwitchRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetPendingSwitchResponse>;

  getPendingSwitch(
    request: services_central_tariff_service_pb.GetPendingSwitchRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPendingSwitchResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetPendingSwitchResponse>;

  getPendingSwitch(
    request: services_central_tariff_service_pb.GetPendingSwitchRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPendingSwitchResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetPendingSwitch',
        request,
        metadata || {},
        this.methodInfoGetPendingSwitch,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetPendingSwitch',
    request,
    metadata || {},
    this.methodInfoGetPendingSwitch);
  }

  methodInfoGetSearchInfo = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetSearchInfo',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetSearchInfoRequest,
    services_central_tariff_service_pb.GetSearchInfoResponse,
    (request: services_central_tariff_service_pb.GetSearchInfoRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetSearchInfoResponse.deserializeBinary
  );

  getSearchInfo(
    request: services_central_tariff_service_pb.GetSearchInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetSearchInfoResponse>;

  getSearchInfo(
    request: services_central_tariff_service_pb.GetSearchInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchInfoResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetSearchInfoResponse>;

  getSearchInfo(
    request: services_central_tariff_service_pb.GetSearchInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetSearchInfo',
        request,
        metadata || {},
        this.methodInfoGetSearchInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetSearchInfo',
    request,
    metadata || {},
    this.methodInfoGetSearchInfo);
  }

  methodInfoGetEstimatedConsumption = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetEstimatedConsumption',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    services_central_tariff_service_pb.GetEstimatedConsumptionResponse,
    (request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetEstimatedConsumptionResponse.deserializeBinary
  );

  getEstimatedConsumption(
    request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetEstimatedConsumptionResponse>;

  getEstimatedConsumption(
    request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetEstimatedConsumptionResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetEstimatedConsumptionResponse>;

  getEstimatedConsumption(
    request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetEstimatedConsumptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetEstimatedConsumption',
        request,
        metadata || {},
        this.methodInfoGetEstimatedConsumption,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetEstimatedConsumption',
    request,
    metadata || {},
    this.methodInfoGetEstimatedConsumption);
  }

  methodInfoSetEstimatedConsumption = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/SetEstimatedConsumption',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    services_central_tariff_service_pb.SetEstimatedConsumptionResponse,
    (request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.SetEstimatedConsumptionResponse.deserializeBinary
  );

  setEstimatedConsumption(
    request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.SetEstimatedConsumptionResponse>;

  setEstimatedConsumption(
    request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetEstimatedConsumptionResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.SetEstimatedConsumptionResponse>;

  setEstimatedConsumption(
    request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetEstimatedConsumptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/SetEstimatedConsumption',
        request,
        metadata || {},
        this.methodInfoSetEstimatedConsumption,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/SetEstimatedConsumption',
    request,
    metadata || {},
    this.methodInfoSetEstimatedConsumption);
  }

  methodInfoSetIsWatchdogEnabled = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/SetIsWatchdogEnabled',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    services_central_tariff_service_pb.SetIsWatchdogEnabledResponse,
    (request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.SetIsWatchdogEnabledResponse.deserializeBinary
  );

  setIsWatchdogEnabled(
    request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.SetIsWatchdogEnabledResponse>;

  setIsWatchdogEnabled(
    request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetIsWatchdogEnabledResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.SetIsWatchdogEnabledResponse>;

  setIsWatchdogEnabled(
    request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetIsWatchdogEnabledResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/SetIsWatchdogEnabled',
        request,
        metadata || {},
        this.methodInfoSetIsWatchdogEnabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/SetIsWatchdogEnabled',
    request,
    metadata || {},
    this.methodInfoSetIsWatchdogEnabled);
  }

  methodInfoGetCharityProjects = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetCharityProjects',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetCharityProjectsRequest,
    services_central_tariff_service_pb.GetCharityProjectsResponse,
    (request: services_central_tariff_service_pb.GetCharityProjectsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetCharityProjectsResponse.deserializeBinary
  );

  getCharityProjects(
    request: services_central_tariff_service_pb.GetCharityProjectsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetCharityProjectsResponse>;

  getCharityProjects(
    request: services_central_tariff_service_pb.GetCharityProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCharityProjectsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetCharityProjectsResponse>;

  getCharityProjects(
    request: services_central_tariff_service_pb.GetCharityProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCharityProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetCharityProjects',
        request,
        metadata || {},
        this.methodInfoGetCharityProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetCharityProjects',
    request,
    metadata || {},
    this.methodInfoGetCharityProjects);
  }

  methodInfoSelectCharityProject = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/SelectCharityProject',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.SelectCharityProjectRequest,
    services_central_tariff_service_pb.SelectCharityProjectResponse,
    (request: services_central_tariff_service_pb.SelectCharityProjectRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.SelectCharityProjectResponse.deserializeBinary
  );

  selectCharityProject(
    request: services_central_tariff_service_pb.SelectCharityProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.SelectCharityProjectResponse>;

  selectCharityProject(
    request: services_central_tariff_service_pb.SelectCharityProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SelectCharityProjectResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.SelectCharityProjectResponse>;

  selectCharityProject(
    request: services_central_tariff_service_pb.SelectCharityProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SelectCharityProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/SelectCharityProject',
        request,
        metadata || {},
        this.methodInfoSelectCharityProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/SelectCharityProject',
    request,
    metadata || {},
    this.methodInfoSelectCharityProject);
  }

  methodInfoGetAdvancePayments = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetAdvancePayments',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    services_central_tariff_service_pb.GetAdvancePaymentsResponse,
    (request: services_central_tariff_service_pb.GetAdvancePaymentsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetAdvancePaymentsResponse.deserializeBinary
  );

  getAdvancePayments(
    request: services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetAdvancePaymentsResponse>;

  getAdvancePayments(
    request: services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetAdvancePaymentsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetAdvancePaymentsResponse>;

  getAdvancePayments(
    request: services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetAdvancePaymentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetAdvancePayments',
        request,
        metadata || {},
        this.methodInfoGetAdvancePayments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetAdvancePayments',
    request,
    metadata || {},
    this.methodInfoGetAdvancePayments);
  }

  methodInfoDeleteAdvancePayment = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/DeleteAdvancePayment',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    services_central_tariff_service_pb.DeleteAdvancePaymentResponse,
    (request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.DeleteAdvancePaymentResponse.deserializeBinary
  );

  deleteAdvancePayment(
    request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.DeleteAdvancePaymentResponse>;

  deleteAdvancePayment(
    request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteAdvancePaymentResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.DeleteAdvancePaymentResponse>;

  deleteAdvancePayment(
    request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteAdvancePaymentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/DeleteAdvancePayment',
        request,
        metadata || {},
        this.methodInfoDeleteAdvancePayment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/DeleteAdvancePayment',
    request,
    metadata || {},
    this.methodInfoDeleteAdvancePayment);
  }

  methodInfoUploadGridData = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/UploadGridData',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.UploadGridDataRequest,
    services_central_tariff_service_pb.UploadGridDataResponse,
    (request: services_central_tariff_service_pb.UploadGridDataRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.UploadGridDataResponse.deserializeBinary
  );

  uploadGridData(
    request: services_central_tariff_service_pb.UploadGridDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.UploadGridDataResponse>;

  uploadGridData(
    request: services_central_tariff_service_pb.UploadGridDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UploadGridDataResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.UploadGridDataResponse>;

  uploadGridData(
    request: services_central_tariff_service_pb.UploadGridDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UploadGridDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/UploadGridData',
        request,
        metadata || {},
        this.methodInfoUploadGridData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/UploadGridData',
    request,
    metadata || {},
    this.methodInfoUploadGridData);
  }

}

