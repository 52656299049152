
import { Component, Vue, Watch } from "vue-property-decorator";
import { spacesModule } from "@/store/modules/spaces";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import TipsCarousel from "./TipsCarousel.vue";
import AddEnergyConsumersReminder from "./AddEnergyConsumersReminder.vue";
import tips, { tipsModule } from "@/store/modules/tips";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { paths } from "@/router/routes";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { EnergyConsumer, Tip } from "zaehlerfreunde-proto-types/tips_pb";

@Component({
  components: {
    TipsCarousel,
    AddEnergyConsumersReminder,
  },
})
export default class Tips extends Vue {
  @spacesModule.State selectedSpace: Space | null;

  @tipsModule.State energyConsumers: RemoteData<UserError, EnergyConsumer[]>;
  @tipsModule.State tips: RemoteData<UserError, Tip[]>;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  TipCategory = Tip.Category;

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    this.loadData();
  }

  @Watch("enabledFeatures")
  onEnabledFeaturesChanged() {
    if (!this.enabledFeatures.has(Feature.SHOP)) {
      this.$router.replace(paths.platform.dashboard);
    }
  }

  tipsByCategory(category: Tip.Category): Tip[] {
    return this.tips.list.filter((t) => t.getCategory() === category);
  }

  metaInfo(): { title: string } {
    return {
      title: "Tipps",
    };
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    tips.getTips();
    tips.getEnergyConsumers();
  }

  get consumersMissing(): boolean {
    return this.energyConsumers.succeeded && this.energyConsumers.list.every((d) => d.getCount() === 0);
  }
}
