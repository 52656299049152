
import { advisoryServiceClient } from "@/config/service-clients";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  CreateReportScheduleRequest,
  DeleteReportScheduleRequest,
  EditReportScheduleRequest,
  GetReportMetricsRequest,
  GetReportScheduleFrequenciesRequest,
  GetReportSchedulesRequest,
  GetReportsRequest,
} from "zaehlerfreunde-central/advisory_service_pb";
import { Report } from "zaehlerfreunde-proto-types/reporting_pb";
import ReportingSchedule from "@/components/reporting/reporting-schedule/ReportingSchedule.vue";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import moment from "moment";
import { paths } from "@/router/routes";
import EditReportScheduleDialog from "@/components/reporting/edit-report-schedule-dialog/EditReportScheduleDialog.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { subscriptionModule } from "@/store/modules/subscription";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

@Component({
  components: {
    ReportingSchedule,
    EditReportScheduleDialog,
  },
})
export default class Reporting extends Vue {
  @spacesModule.State selectedSpace: Space | null;
  @subscriptionModule.Getter includedFeatures: Feature[];

  reportingSchedules: RemoteData<UserError, Report.Schedule[]> = initialized;
  reports: RemoteData<UserError, Report[]> = initialized;
  metrics: { value: Report.Metric; text: string }[] = [];
  frequencies: { value: Report.ScheduleFrequency; text: string }[] = [];

  scheduleToEdit: Report.Schedule | null = null;
  scheduleToDelete: Report.Schedule | null = null;

  showEditReportScheduleDialog: boolean = false;
  showDeleteReportScheduleDialog: boolean = false;

  deletingSchedule: boolean;
  savingSchedule: boolean = false;

  snackbarText = "";
  showSnackbar = false;

  numReports = 0;
  pageSize = 15;
  page = 1;

  headers = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Erstellt",
      value: "createdAt",
    },
    {
      text: "Von",
      value: "from",
    },
    {
      text: "Bis",
      value: "to",
    },
    {
      text: "",
      value: "controls",
    },
  ];

  mounted() {
    this.loadData();
  }

  loadData() {
    this.loadSchedules();
    this.loadReports();
    this.loadMetrics();
    this.loadFrequencies();
  }

  get items() {
    return this.reports.list.map((r: Report) => ({
      id: r.getId(),
      name: r.getName(),
      createdAt: moment(new Date(r.getCreationTime() * 1000)).fromNow(),
      from: moment(new Date(r.getStartTime() * 1000)).format("DD.MM.YYYY"),
      to: moment(new Date(r.getEndTime() * 1000)).format("DD.MM.YYYY"),
    }));
  }

  @Watch("selectedSpace")
  onSelectedSpaceChanged() {
    this.loadData();
  }

  showEditScheduleDialog(schedule?: Report.Schedule) {
    if (!this.includedFeatures.includes(Feature.REPORTING)) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "reporting" },
      });

      return;
    }

    this.scheduleToEdit = schedule ?? null;
    this.showEditReportScheduleDialog = true;
  }

  showDeleteScheduleDialog(schedule: Report.Schedule) {
    this.scheduleToDelete = schedule;
    this.showDeleteReportScheduleDialog = true;
  }

  async loadSchedules(): Promise<void> {
    const request = new GetReportSchedulesRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    try {
      this.reportingSchedules = pending;

      const response = await advisoryServiceClient.getReportSchedules(request, {});
      this.reportingSchedules = success(response.getSchedulesList());
    } catch (error) {
      this.reportingSchedules = failure(userErrorFrom(error));
    }
  }

  @Watch("page")
  async loadReports(): Promise<void> {
    const pagination = new Pagination();
    pagination.setPage(this.page - 1);
    pagination.setPageSize(this.pageSize);

    const request = new GetReportsRequest();
    request.setPagination(pagination);
    request.setSpaceId(spaces.selectedSpaceId);

    try {
      this.reports = pending;
      const response = await advisoryServiceClient.getReports(request, {});
      this.reports = success(response.getReportsList());
      this.numReports = response.getTotalNumReports();
    } catch (error) {
      this.reports = failure(userErrorFrom(error));
    }
  }

  async deleteSchedule(scheduleId: string): Promise<void> {
    const request = new DeleteReportScheduleRequest();
    request.setScheduleId(scheduleId);

    try {
      this.deletingSchedule = true;
      await advisoryServiceClient.deleteReportSchedule(request, {});
      this.loadSchedules();
      this.showDeleteReportScheduleDialog = false;
    } catch (error) {
      this.snackbarText = userErrorFrom(error).message;
      this.showSnackbar = true;
    } finally {
      this.deletingSchedule = false;
    }
  }

  async loadMetrics(): Promise<void> {
    const request = new GetReportMetricsRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    try {
      const response = await advisoryServiceClient.getReportMetrics(request, {});
      this.metrics = response.getMetricsList().map((m, i) => ({ value: m, text: response.getLabelsList()[i] }));
    } catch (error) {
      this.snackbarText = userErrorFrom(error).message;
      this.showSnackbar = true;
    }
  }

  async loadFrequencies(): Promise<void> {
    const request = new GetReportScheduleFrequenciesRequest();

    try {
      const response = await advisoryServiceClient.getReportScheduleFrequencies(request, {});
      this.frequencies = response.getFrequenciesList().map((f, i) => ({ value: f, text: response.getLabelsList()[i] }));
    } catch (error) {
      this.snackbarText = userErrorFrom(error).message;
      this.showSnackbar = true;
    }
  }

  async saveSchedule(schedule: Report.Schedule): Promise<void> {
    try {
      this.savingSchedule = true;

      if (schedule.getId()) {
        const request = new EditReportScheduleRequest();
        request.setSchedule(schedule);
        await advisoryServiceClient.editReportSchedule(request, {});
      } else {
        const request = new CreateReportScheduleRequest();
        request.setNewSchedule(schedule);
        request.setSpaceId(spaces.selectedSpaceId);
        await advisoryServiceClient.createReportSchedule(request, {});
      }

      this.showEditReportScheduleDialog = false;
      this.loadSchedules();
    } catch (error) {
      this.snackbarText = userErrorFrom(error).message;
      this.showSnackbar = true;
    } finally {
      this.savingSchedule = false;
    }
  }

  openReport(reportId: string): void {
    this.$router.push(`${paths.platform.reporting}/${reportId}`);
  }
}
