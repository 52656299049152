
import { RemoteDataVue } from "@/store/utils/remote-data";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Alert extends RemoteDataVue {
  @Prop({ default: "info" }) type: string;
  @Prop({ default: null }) alignment: string | null;
  @Prop({ default: "unset" }) width: string;
  @Prop({ default: false }) hint: boolean;

  get clazz(): { [clazz: string]: boolean } {
    return {
      center: this.alignment === "center",
      alert: true,
    };
  }
}
