
import { Component, Prop, Vue } from "vue-property-decorator";
import { failure, initialized, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import {
  DeleteDashboardItemRequest,
  MoveDashboardItemRequest,
  ResetDashboardLayoutRequest,
} from "zaehlerfreunde-central/ui_service_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GridItemHTMLElement, GridStack, GridStackNode } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import { DashboardItem, DashboardItemPosition, DashboardLayout } from "zaehlerfreunde-proto-types/dashboard_layout_pb";
import { uiServiceClient } from "@/config/service-clients";
import DashboardItemComponent from "./DashboardItemComponent.vue";

interface DashboardItemGridNode extends GridStackNode {
  item: DashboardItem;
}

export interface LayoutChangedEvent {
  newLayout: DashboardLayout;
  scrollY: number;
}

@Component({
  components: {
    DashboardItemComponent,
  },
})
export default class EditDashboardLayout extends Vue {
  @Prop() selectedSpace: Space | null;
  @Prop() dashboardLayout: DashboardLayout;
  @Prop() scrollY: number;

  Kind = DashboardItem.Kind;
  updateLayoutCall: RemoteCall<UserError> = initialized;
  grid: GridStack;

  async mounted() {
    this.gridSetup();
    window.scrollTo(0, this.scrollY);
    this.grid.on("dragstop", this.onNodeMoved);
  }

  async deleteItem(item: DashboardItem) {
    const request = new DeleteDashboardItemRequest();
    request.setItem(item.getKind());
    if (this.selectedSpace) {
      request.setSpaceId(this.selectedSpace.getId());
    }

    try {
      const response = await uiServiceClient.deleteDashboardItem(request, {});
      this.$emit("layout-changed", {
        newLayout: response.getNewLayout(),
        scrollY: window.scrollY,
      });
    } catch (error) {
      this.updateLayoutCall = failure(userErrorFrom(error));
    }
  }

  gridSetup(): void {
    this.grid = GridStack.init({
      float: false,
      cellHeight: 31,
      column: 2,
      disableResize: true,
    });
  }

  get gridNodes(): DashboardItemGridNode[] {
    var y = 0;
    var nodes: DashboardItemGridNode[] = [];

    this.dashboardLayout
      .getWeb()
      ?.getRowsList()
      .forEach((row) => {
        var tallestItem = 0;

        row.getItemsList().forEach((item, i) => {
          var itemHeight = this.getDashboardItemHeight(item.getKind());
          tallestItem = Math.max(itemHeight, tallestItem);

          nodes.push({
            id: item.getKind().toString(),
            w: item.getWidth() === DashboardItem.Width.FULL_WIDTH ? 2 : 1,
            h: itemHeight,
            x: i,
            y: y,
            item: item,
          });
        });

        y += tallestItem;
      });

    return nodes;
  }

  getDashboardItemHeight(item: DashboardItem.Kind): number {
    switch (item) {
      case DashboardItem.Kind.CONSUMPTION_TARGET:
        return 13;
      case DashboardItem.Kind.CONSUMPTION_GRAPH:
        return 21;
      case DashboardItem.Kind.CURRENT_MONTH_CONSUMPTION:
        return 9;
      case DashboardItem.Kind.CURRENT_MONTH_COSTS:
        return 9;
      case DashboardItem.Kind.DEVICE_STATUS_OVERVIEW:
        return 6;
      case DashboardItem.Kind.SPACE_COMPARISON:
        return 9;
      case DashboardItem.Kind.TARIFF_SWITCH_STATUS:
        return 13;
      case DashboardItem.Kind.TIP_CAROUSEL:
        return 11;
      case DashboardItem.Kind.HEADER_MEASUREMENT:
        return 3;
      case DashboardItem.Kind.HEADER_TIPS:
        return 3;
      case DashboardItem.Kind.CREATE_FIRST_SPACE_REMINDER:
        return 9;
      case DashboardItem.Kind.PROMINENT_TIP:
        return 11;
      case DashboardItem.Kind.WELCOME_MESSAGE:
        return 9;
      case DashboardItem.Kind.CONNECT_DEVICE_REMINDER:
        return 9;
      case DashboardItem.Kind.UPDATE_MANUAL_READINGS:
        return 9;
      case DashboardItem.Kind.MARKET_PREMIUM_HEADER:
        return 3;
      case DashboardItem.Kind.MARKET_PREMIUM:
        return 9;
      case DashboardItem.Kind.CREATE_FIRST_AUTOMATION_REMINDER:
        return 9;
      case DashboardItem.Kind.AUTOMATION_OVERVIEW:
        return 12;

      default:
        return 0;
    }
  }

  async onNodeMoved(event: Event, el: GridItemHTMLElement) {
    const movedNode = el.gridstackNode;

    if (!movedNode) {
      return;
    }

    let row = 0;
    let y = 0;

    for (let i = 0; i < this.grid.engine.nodes.length; i++) {
      const node = this.grid.engine.nodes[i];

      if ((node.y ?? 0) > y) {
        y = node.y ?? 0;
        row += 1;
      }

      if (node.y === movedNode.y) {
        break;
      }
    }

    const newPosition = new DashboardItemPosition().setWeb(
      new DashboardItemPosition.Web().setColumn(movedNode.x ?? 0).setRow(row)
    );

    const request = new MoveDashboardItemRequest();
    request.setItem(parseInt(el.id));
    request.setNewPosition(newPosition);

    if (this.selectedSpace) {
      request.setSpaceId(this.selectedSpace.getId());
    }

    try {
      const response = await uiServiceClient.moveDashboardItem(request, {});

      this.$emit("layout-changed", {
        newLayout: response.getNewLayout(),
        scrollY: window.scrollY,
      });
    } catch (error) {
      this.updateLayoutCall = failure(userErrorFrom(error));
    }
  }

  async resetLayout(): Promise<void> {
    try {
      const request = new ResetDashboardLayoutRequest();
      if (this.selectedSpace) {
        request.setSpaceId(this.selectedSpace.getId());
      }

      const response = await uiServiceClient.resetDashboardLayout(request, {});

      this.$emit("layout-changed", {
        newLayout: response.getNewLayout(),
        scrollY: window.scrollY,
      });
      this.$emit("done");
    } catch (error) {
      this.updateLayoutCall = failure(userErrorFrom(error));
    }
  }
}
