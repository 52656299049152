
import { paths } from "@/router/routes";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BlueMeteringDevice } from "zaehlerfreunde-central/device_service_pb";
import SelectBluemeteringDevice from "./SelectBluemeteringDevice.vue";

@Component({
  components: {
    SelectBluemeteringDevice,
  },
})
export default class ImportBluemeteringData extends Vue {
  @Prop({ default: false }) isAdminImport: boolean;
  @Prop() value: boolean;

  paths = paths;

  @Watch("value")
  onValueChanged(): void {
    this.$emit("input", this.value);
  }

  step: "enter-api-key" | "select-device" = "enter-api-key";

  selectedDevices: BlueMeteringDevice[] = [];

  get title(): string {
    if (this.step === "enter-api-key") {
      return "BlueMetering API Schlüssel";
    } else {
      return "Zähler auswählen";
    }
  }

  get subTitle(): string {
    if (this.step === "enter-api-key") {
      return "Um dein Smart Meter verbinden zu können gib deinen BlueMetering API Schlüssel an. Diesen solltest du per E-Mail oder Post erhalten haben.";
    } else {
      return "Bitte wähle den Zähler aus, den du verbinden möchtest.";
    }
  }
}
