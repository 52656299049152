import { Device } from "zaehlerfreunde-proto-types/device_pb";

export function getMediumName(medium: Device.Medium): string {
  switch (medium) {
    case Device.Medium.ELECTRICITY:
      return "Strom";
    case Device.Medium.GAS:
      return "Gas";
    case Device.Medium.WATER:
      return "Wasser";
    case Device.Medium.HEATING:
      return "Heizung";
    default:
      return "Unbekannt";
  }
}

export function getMediumIcon(medium: Device.Medium): string {
  switch (medium) {
    case Device.Medium.ELECTRICITY:
      return "mdi-lightning-bolt";
    case Device.Medium.GAS:
      return "mdi-fire";
    case Device.Medium.WATER:
      return "mdi-water";
    case Device.Medium.HEATING:
      return "mdi-radiator";
    default:
      return "mdi-help";
  }
}

export function getMediumColor(medium: Device.Medium): string {
  switch (medium) {
    case Device.Medium.ELECTRICITY:
      return "#F0DE36";
    case Device.Medium.GAS:
      return "error";
    case Device.Medium.WATER:
      return "#1D5D9B";
    case Device.Medium.HEATING:
      return "#C51605";
    default:
      return "#000000";
  }
}
