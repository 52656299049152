
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { formatNumber, parseFloatOptional } from "@/utils/number-utils";

@Component
export default class ChangeIngestPrice extends Vue {
  @Prop() value: boolean;
  @Prop() price: number;
  @Prop() isIngestDeletion: boolean;

  ingestPriceStr: string = "";

  @Watch("price")
  onPriceUpdated() {
    this.ingestPriceStr = formatNumber(this.price);
  }

  get ingestPrice(): number | null {
    return parseFloatOptional(this.ingestPriceStr) ?? null;
  }

  updateSpaceIngestPriceCall: RemoteCall<UserError> = initialized;

  get validInput(): boolean {
    return this.ingestPriceStr != null && !!this.ingestPrice;
  }

  get title(): string {
    return this.isIngestDeletion ? "Ingest Price Löschen" : "Ingest Price Ändern";
  }

  get subtitle(): string {
    return this.isIngestDeletion
      ? "Bist du sicher, dass du den Ingest-Preis für Objekt löschen möchtest?"
      : "Hier kannst du die Einpreisvergütung ändern";
  }

  async onSaveClicked(): Promise<void> {
    const request = new UpdateSpaceRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    if (!this.isIngestDeletion) request.setIngestPrice(this.ingestPrice ?? 0);

    this.updateSpaceIngestPriceCall = pending;

    try {
      await spaceServiceClient.updateSpace(request, {});

      this.$emit("input", false);

      spaces.getSpaces();

      this.updateSpaceIngestPriceCall = success(void 0);
    } catch (error) {
      this.updateSpaceIngestPriceCall = failure(userErrorFrom(error));
    }
  }
}
