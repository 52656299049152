
import { Vue, Component } from "vue-property-decorator";
import GeneralSteps, { GeneralDeviceInfo } from "./general/GeneralSteps.vue";
import DiscovergyConnection from "./discovergy/DiscovergyConnection.vue";
import SmartMeConnection from "./smartme/SmartMeConnection.vue";
import EaseeConnection from "./easee/EaseeConnection.vue";
import PowerfoxConnection from "./powerfox/PowerfoxConnection.vue";
import ShellyConnection from "./shelly/ShellyConnection.vue";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import WattlineConnection from "./wattline/WattlineConnection.vue";
import MsconsConnection from "./mscons/MsconsConnection.vue";
import MyStromConnection from "./mystrom/MyStromConnection.vue";
import GoEConnection from "./go-e/GoEConnection.vue";
import NonSmartMeterConnection from "./non-smart-meter/NonSmartMeterConnection.vue";
import TsunConnection from "./tsun/TsunConnection.vue";
import ViessmannConnection from "./viessmann/ViessmannConnection.vue";
import EdaConnection from "./eda/EdaConnection.vue";
import AuxiliusConnection from "./auxilius/AuxiliusConnection.vue";
import TibberConnection from "./tibber/TibberConnection.vue";
import LobaroConnection from "./lobaro/LobaroConnection.vue";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import OcppConnection from "./ocpp/OcppConnection.vue";
import BitShakeConnection from "./bitshake/BitShakeConnection.vue";
import SolaredgeConnection from "./solaredge/SolarEdgeConnection.vue";
import VirtualDeviceConnection from "./virtual-device/VirtualDeviceConnection.vue";
import EnbwConnection from "./enbw/EnbwConnection.vue";
import IometerConnection from "./iometer/IometerConnection.vue";
import MeteocontrolConnection from "./meteocontrol/MeteocontrolConnection.vue";
import ChargecloudConnection from "./chargecloud/ChargecloudConnection.vue";
@Component({
  components: {
    GeneralSteps,
    DiscovergyConnection,
    SmartMeConnection,
    EaseeConnection,
    PowerfoxConnection,
    ShellyConnection,
    WattlineConnection,
    MsconsConnection,
    MyStromConnection,
    GoEConnection,
    NonSmartMeterConnection,
    ViessmannConnection,
    TsunConnection,
    EdaConnection,
    AuxiliusConnection,
    TibberConnection,
    LobaroConnection,
    OcppConnection,
    BitShakeConnection,
    SolaredgeConnection,
    VirtualDeviceConnection,
    EnbwConnection,
    IometerConnection,
    MeteocontrolConnection,
    ChargecloudConnection,
  },
})
export default class ConnectDevice extends Vue {
  @spacesModule.Getter selectedSpaceId: string;

  adminAction: boolean = window.location.pathname.includes("admin");

  generalInfo: GeneralDeviceInfo | null = null;

  DeviceProvider = Device.Provider;

  spaceId: string | null = null;

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  onGeneralInfoCompleted(generalInfo: GeneralDeviceInfo): void {
    this.generalInfo = { ...generalInfo };
  }

  mounted(): void {
    const query = this.$route.query;

    if ("spaceId" in query) {
      spaces.setSelectedSpace(new Space().setId(query["spaceId"] as string));

      const genInfoJSON = this.$route.query["genInfo"] as string;
      this.generalInfo = JSON.parse(genInfoJSON);
    }
  }
}
