
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { DeleteDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { deviceServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class DeleteDeviceDialog extends Vue {
  @Prop() space: Space | null;
  @Prop() device: Device | null;
  @Prop({ default: false }) deleteForAll: boolean;

  deleteDeviceCall: RemoteCall<UserError> = initialized;

  async onDeleteClicked(): Promise<void> {
    try {
      this.deleteDeviceCall = pending;
      const request = new DeleteDeviceRequest();
      request.setDeviceId(this.device?.getId() ?? "");
      request.setSpaceId(this.space?.getId() ?? "");
      request.setDeleteForAll(this.deleteForAll);

      await deviceServiceClient.deleteDevice(request, {});

      this.deleteDeviceCall = success(void 0);
      this.$emit("device-deleted");
      this.$emit("input", false);
    } catch (error) {
      this.deleteDeviceCall = failure(userErrorFrom(error));
    }
  }
}
