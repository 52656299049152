import { getAuthService } from "@/auth";
import { VisitorType, visitorType$ } from "@/observables/visitor-type-observables";
import { ErrorCode } from "@/pages/shared";
import account from "@/store/modules/account";
import admin from "@/store/modules/admin";
import partner from "@/store/modules/partner";
import spaces from "@/store/modules/spaces";
import subscription from "@/store/modules/subscription";
import theme from "@/store/modules/theme";
import { hasAuthenticated, isAuth0Redirect, setAuthenticatedCookie, isDemoAccount } from "@/utils/login-utils";
import { NavigationGuardNext, Route } from "vue-router";
import { paths } from "./routes";
import features from "@/store/modules/features";
import { getEnvironment } from "@/config/env";
import { partnerAuthConfig } from "@/config/auth-config";
import { partnerServiceClient } from "@/config/service-clients";
import { ValidatePartnerDomainRequest } from "zaehlerfreunde-central/partner_service_pb";
import Vue from "vue";

export const isLoggedInGuard = async (_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
  const authService = getAuthService();
  const environment = getEnvironment();
  const isAuthenticated = await authService.isAuthenticated;

  if (isAuthenticated || isDemoAccount()) {
    const authConfig = await partnerAuthConfig;
    await account.getRegistrationStatus(authConfig.getPartnerId());

    if (account.registrationStatus.succeeded) {
      if (environment.isProd) {
        const request = new ValidatePartnerDomainRequest();
        request.setDomain(window.location.origin);
        const response = await partnerServiceClient.validatePartnerDomain(request, {});
        if (!response.getIsDomainValid()) {
          window.location.replace(response.getValidDomain());
        }
      }

      if (account.registrationStatus.data?.getIsComplete()) {
        theme.getPartnerTheme();
        subscription.getCurrentSubscriptionPlan();
        spaces.getSpaces();
        account.getUser();
        account.getUserDetails();
        features.getFeatures();
        features.getSubscribedFeatures();

        next();
      } else if (_to.path === paths.platform.registration) {
        theme.getPartnerTheme();

        next();
      } else {
        next({ path: paths.platform.registration });
      }
    } else if (account.registrationStatus.failed) {
      const query: { [key: string]: string } = {
        code: ErrorCode.UnknownError,
      };

      if (account.registrationStatus.error?.message === "Http response at 400 or 500 level") {
        query["code"] = ErrorCode.UnableToReachServer;
      }

      if (account.registrationStatus.error?.traceId) {
        query["traceId"] = account.registrationStatus.error?.traceId;
      }

      next({
        path: paths.error,
        query,
      });
    }
  } else if (!isAuth0Redirect()) {
    const authConfig = await partnerAuthConfig;

    authService.loginWithRedirect({
      appState: {
        targetUrl: _to.fullPath,
      },
      authorizationParams: {
        connection: authConfig.getAuth0Connection() ?? undefined,
      },
    });
  } else {
    setAuthenticatedCookie(false);
    next({ path: paths.home });
  }
};

export const notLoggedInGuard = async (_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
  const authConfig = await partnerAuthConfig;

  if (isAuth0Redirect()) {
    const authService = getAuthService();
    const appState = (await authService.appState) ?? {};

    next({ path: appState["targetUrl"] ?? paths.platform.dashboard });
  } else if (hasAuthenticated() || isDemoAccount()) {
    next({ path: paths.platform.dashboard });
  } else if (!authConfig.getAllowLandingPage()) {
    next({ path: paths.platform.dashboard });
  } else {
    next();
  }
};

export const isAdminGuard = async (_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
  await isLoggedInGuard(_to, _from, async (to) => {
    if (to) {
      next(to);
    } else {
      await admin.checkAdminStatus();
      if (admin.isAdminUser.succeeded) {
        if (admin.isAdminUser.data?.valueOf() == true) {
          partner
            .getSelectedChildPartner()
            .then(() => next())
            .catch(() => next({ path: paths.platform.dashboard }));
        } else {
          next({ path: paths.platform.dashboard });
        }
      } else {
        next({ path: paths.platform.dashboard });
      }
    }
  });
};

export const visitorTypeCookieKey = "visitor-type";

export const visitorTypeGuard =
  (expectedType: VisitorType | null) => (_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    const visitorType = visitorType$.getValue();

    if (expectedType === null && visitorType !== null) {
      switch (visitorType) {
        case VisitorType.PrivateCustomer:
          next({ path: paths.homepage.privateCustomersPath });
          break;
        case VisitorType.CommercialCustomer:
          next({ path: paths.homepage.commercialCustomers });
          break;
        case VisitorType.WhiteLabelCustomer:
          next({ path: paths.homepage.whiteLabel.landingPage });
          break;
      }
    } else {
      visitorType$.next(expectedType);
      next();
    }
  };

export const isAllowedAutomationsGuard = async (_to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
  partner
    .getCustomizationConfig()
    .then(() =>
      partner.customizationConfig.data?.getHideAutomations() ? next({ path: paths.platform.dashboard }) : next()
    )
    .catch(() => next({ path: paths.platform.dashboard }));
};
