
import { Vue, Component, Ref } from "vue-property-decorator";
import AddAutomationDialog from "./AddAutomationDialog.vue";
import DispatchedActionsTable from "./DispatchedActionsTable.vue";
import DeleteAutomationDialog from "./DeleteAutomationDialog.vue";
import TriggerAutomationDialog from "./TriggerAutomationDialog.vue";

import { GetAutomationDetailRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { Automation } from "zaehlerfreunde-proto-types/automations_pb";

import { advisoryServiceClient } from "@/config/service-clients";

import { failure, initialized, pending, RemoteData, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";

@Component({
  components: {
    AddAutomationDialog,
    DeleteAutomationDialog,
    DispatchedActionsTable,
    TriggerAutomationDialog,
  },
})
export default class AutomationDetail extends Vue {
  automationDetail: RemoteData<UserError, Automation> = initialized;
  automationTrigger: RemoteCall<UserError> = initialized;

  automationDescription: string = "";
  actionIcons: string[] = [];
  triggerIcons: string[] = [];

  showAddAutomationDialog: boolean = false;
  showAutomationEditDialog: boolean = false;
  automationIdToDelete: string = "";
  automationIdToTrigger: string = "";

  automationId: string = "";
  @Ref() readonly dispatchedActionsTable!: DispatchedActionsTable;

  mounted(): void {
    this.loadData();
    this.automationId = this.$route.params.automationId;
  }

  goBackToAutomations() {
    this.$router.go(-1);
  }

  async loadData(): Promise<void> {
    const automationId = this.$route.params.automationId;

    this.automationDetail = pending;
    const request = new GetAutomationDetailRequest();
    request.setAutomationId(automationId);

    try {
      const response = await advisoryServiceClient.getAutomationDetail(request, null);
      this.automationDetail = success(response.getAutomation()!);
      this.automationDescription = response.getDescription();
      this.actionIcons = response.getActionIconsList();
      this.triggerIcons = response.getTriggerIconsList();
    } catch (error) {
      this.automationDetail = failure(userErrorFrom(error));
    }
  }

  reloadDispatchedActions(): void {
    this.dispatchedActionsTable.loadData();
  }

  onAddAutomationClicked(): void {
    this.showAddAutomationDialog = true;
  }
}
