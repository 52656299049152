
import { tariffServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { getMediumName } from "@/utils/device-utils";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  AddContractRequest,
  DeleteContractRequest,
  GetTariffsOfSpaceRequest,
} from "zaehlerfreunde-central/tariff_service_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import TariffSelect from "../../tariffs/TariffSelect.vue";

@Component({
  components: {
    TariffSelect,
  },
})
export default class SpaceTariffs extends Vue {
  @Prop() spaceId: string;

  tariffs: RemoteData<UserError, Tariff[]> = initialized;
  addContractCall: RemoteCall<UserError> = initialized;
  deleteContractCall: RemoteCall<UserError> = initialized;

  showAddContractDialog: boolean = false;
  showDeleteContractDialog: boolean = false;
  contractIdToDelete: string | null = null;
  newContract = {
    tariffId: "",
    startDate: null as Date | null,
    earliestCancelDate: null as Date | null,
    customerId: "",
  };

  headers = [
    { text: "Tarif", value: "name" },
    { text: "Lieferant", value: "supplier" },
    { text: "Sparte", value: "medium" },
    { text: "Startdatum", value: "startDate" },
    { text: "Kundennummer", value: "customerId" },
    { text: "", value: "controls" },
  ];

  mounted() {
    this.getTariffs();
  }

  get addContractBtnDisabled(): boolean {
    return !this.newContract.tariffId || !this.newContract.startDate;
  }

  get rows() {
    return this.tariffs.data?.map((t) => {
      var startDate = "";

      if (t.getContract()?.getStartDate()) {
        startDate = new Date(t.getContract()?.getStartDate() ?? "").toLocaleDateString();
      }

      return {
        tariffId: t.getId(),
        contractId: t.getContract()?.getId() ?? "",
        name: t.getName(),
        supplier: t.getSupplier(),
        medium: getMediumName(t.getMedium()),
        customerId: t.getContract()?.getCustomerId() || "-",
        startDate,
      };
    });
  }

  showDeleteDialog(contractId: string, event: Event) {
    event.stopPropagation();
    this.contractIdToDelete = contractId;
    this.showDeleteContractDialog = true;
  }

  handleRowClick(row: { tariffId: string }) {
    this.$router.push(`${paths.admin.tariffs}/${row.tariffId}`);
  }

  async getTariffs(): Promise<void> {
    const request = new GetTariffsOfSpaceRequest();
    request.setSpaceId(this.spaceId);

    try {
      this.tariffs = pending;
      const response = await tariffServiceClient.getTariffsOfSpace(request, {});
      this.tariffs = success(response.getTariffsList());
    } catch (error) {
      this.tariffs = failure(userErrorFrom(error));
    }
  }

  async addContract(): Promise<void> {
    const contract = new Tariff.Contract();
    contract.setSpaceId(this.spaceId);
    contract.setTariffId(this.newContract.tariffId);

    if (this.newContract.startDate) {
      contract.setStartDate(this.newContract.startDate.toDateString());
    }

    if (this.newContract.earliestCancelDate) {
      contract.setEarliestCancelDate(this.newContract.earliestCancelDate.toDateString());
    }

    if (this.newContract.customerId) {
      contract.setCustomerId(this.newContract.customerId);
    }

    const request = new AddContractRequest();
    request.setContract(contract);

    try {
      this.addContractCall = pending;
      await tariffServiceClient.addContract(request, {});
      this.addContractCall = success(void 0);
      this.showAddContractDialog = false;
      this.getTariffs();
      this.newContract = {
        earliestCancelDate: null,
        startDate: null,
        tariffId: "",
        customerId: "",
      };
    } catch (error) {
      this.addContractCall = failure(userErrorFrom(error));
    }
  }

  async deleteContract(): Promise<void> {
    const contractId = this.contractIdToDelete;

    if (contractId) {
      const request = new DeleteContractRequest();
      request.setContractId(contractId);

      try {
        this.deleteContractCall = pending;
        await tariffServiceClient.deleteContract(request, {});
        this.deleteContractCall = success(void 0);
        this.showDeleteContractDialog = false;
        this.getTariffs();
      } catch (error) {
        this.deleteContractCall = failure(userErrorFrom(error));
      }
    }
  }
}
