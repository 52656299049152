
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AddUpdateLandlordToTenantEnergyTariffRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { LandlordToTenantEnergyTariff } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { formatNumber, parseFloatOptional } from "@/utils/number-utils";

@Component
export default class AddUpdateLandlordToTenantEnergyTariff extends Vue {
  @Prop({ default: null }) projectId: string | null;
  @Prop({ default: null }) tariff: LandlordToTenantEnergyTariff | null;

  solarPrice = "";
  residualPrice = "";
  mixedPrice = "";
  basePrice = "";
  addTariffCall: RemoteCall<UserError> = initialized;

  showTariffSavedSnackbar = false;

  pricingModel: "mixedPrice" | "twoPrices" = "mixedPrice";

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoComplete(): boolean {
    return this.solarPrice != null && this.residualPrice != null && this.basePrice != null;
  }

  mounted() {
    if (this.tariff) {
      this.solarPrice = formatNumber(this.tariff.getSolarPrice());
      this.residualPrice = formatNumber(this.tariff.getResidualPrice());
      this.mixedPrice = formatNumber(this.tariff.getMixedPrice());
      this.basePrice = formatNumber(this.tariff.getBasePricePerMonth());
      this.pricingModel = this.tariff.getMixedPriceTariff() ? "mixedPrice" : "twoPrices";
    }
  }

  async addUpdateEnergyTariff(): Promise<void> {
    this.addTariffCall = pending;
    const request = new AddUpdateLandlordToTenantEnergyTariffRequest();

    if (
      ((this.solarPrice && this.residualPrice && this.pricingModel === "twoPrices") ||
        (this.mixedPrice && this.pricingModel === "mixedPrice")) &&
      this.basePrice &&
      this.projectId
    ) {
      const tariff = new LandlordToTenantEnergyTariff();
      if (this.tariff) {
        tariff.setId(this.tariff.getId());
      }

      tariff.setSolarPrice(parseFloatOptional(this.solarPrice) ?? 0);
      tariff.setResidualPrice(parseFloatOptional(this.residualPrice) ?? 0);
      tariff.setMixedPrice(parseFloatOptional(this.mixedPrice) ?? 0);
      tariff.setMixedPriceTariff(this.pricingModel === "mixedPrice");
      tariff.setBasePricePerMonth(parseFloatOptional(this.basePrice) ?? 0);
      request.setTariff(tariff);
      request.setProjectId(this.projectId);
      try {
        await landlordToTenantServiceClient.addUpdateLandlordToTenantEnergyTariff(request, {});
        this.addTariffCall = success(void 0);
        this.showTariffSavedSnackbar = true;
      } catch (error) {
        this.addTariffCall = failure(userErrorFrom(error));
      }
    }
  }
}
