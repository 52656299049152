
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import devices, { devicesModule } from "@/store/modules/devices";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceRegistration, DiscovergyRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";

@Component({
  components: {},
})
export default class DiscovergyConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  page: number = 1;
  fullSerialNumber: string = "";
  acceptedTerms: boolean = false;

  @Watch("registerDeviceCall")
  onRegisterDeviceCallChanged(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  copyText(): void {
    navigator.clipboard.writeText("discovergy@zaehlerfreunde.com");
  }

  onNextClicked(): void {
    if (this.fullSerialNumber) {
      const registration = new DeviceRegistration();
      registration.setDeviceName(this.generalInfo.deviceName);
      registration.setDeviceMedium(Device.Medium.ELECTRICITY);
      registration.setDeviceType(this.generalInfo.deviceType);
      registration.setDeviceProvider(this.generalInfo.deviceProvider);

      const spaceMapping = new DeviceRegistration.SpaceMapping();
      spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
      spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
      registration.setSpaceMapping(spaceMapping);

      const details = new DiscovergyRegistrationDetails();
      details.setFullSerialNumber(this.fullSerialNumber);

      registration.setDiscovergy(details);

      devices.registerDevice(registration);
    }
  }
}
