import { UserError } from "@/types/user-error";
import ArrayUtils from "@/utils/array-utils";
import { Prop, Vue } from "vue-property-decorator";

export type RemoteCall<E> = Initialized | Pending | Failure<E> | Success<void>;

export type Remote<D> = RemoteData<UserError, D>;

export type RemoteData<E, D> = Initialized | Pending | Failure<E> | Success<D>;

export enum Kinds {
  Initialized = "Initialized",
  Pending = "Pending",
  Failure = "Failure",
  Success = "Success",
}

export type Data<D> = {
  data: D;
};

export type List<D> = {
  list: D;
};

export type Error<E> = {
  error: E;
};

export type LoadingStates = {
  pending: boolean;
  failed: boolean;
  succeeded: boolean;
};

export interface Initialized extends Data<null>, List<[]>, Error<null>, LoadingStates {
  readonly kind: Kinds.Initialized;
}

export interface Pending extends Data<null>, List<[]>, Error<null>, LoadingStates {
  readonly kind: Kinds.Pending;
}

export interface Failure<E> extends Data<null>, List<[]>, Error<E>, LoadingStates {
  readonly kind: Kinds.Failure;
}

export interface Success<D> extends Data<D>, List<D>, Error<null>, LoadingStates {
  readonly kind: Kinds.Success;
}

export const initializedFactory = (): Initialized => initialized;

export const initialized: Initialized = {
  kind: Kinds.Initialized,
  data: null,
  list: [],
  error: null,
  pending: false,
  failed: false,
  succeeded: false,
};

export const pending: Pending = {
  kind: Kinds.Pending,
  data: null,
  list: [],
  error: null,
  pending: true,
  failed: false,
  succeeded: false,
};

export const failure = <E, D>(error: E): RemoteData<E, D> => ({
  kind: Kinds.Failure,
  data: null,
  list: [],
  error,
  pending: false,
  failed: true,
  succeeded: false,
});

export const success = <E, D>(data: D): RemoteData<E, D> => ({
  kind: Kinds.Success,
  data,
  list: data,
  error: null,
  pending: false,
  failed: false,
  succeeded: true,
});

export const successOptional = <E, D>(data: D | undefined): RemoteData<E, D> => {
  if (!data) {
    throw new Error("Ein Fehler ist aufgetreten.");
  }

  return {
    kind: Kinds.Success,
    data,
    list: data,
    error: null,
    pending: false,
    failed: false,
    succeeded: true,
  };
};

export const isFailure = <E>(data: RemoteData<E, unknown>): data is Failure<E> => data.kind === Kinds.Failure;
export const isSuccess = <D>(data: RemoteData<unknown, D>): data is Success<D> => data.kind === Kinds.Success;
export const isPending = (data: RemoteData<unknown, unknown>): data is Pending => data.kind === Kinds.Pending;
export const isInitial = (data: RemoteData<unknown, unknown>): data is Initialized => data.kind === Kinds.Initialized;

export const toNullable = <L, A>(ma: RemoteData<L, A>): A | null => (isSuccess(ma) ? ma.data : null);
export const toList = <L, A>(ma: RemoteData<L, A>): A | [] => (isSuccess(ma) ? ma.data : []);
export const toNullableError = <L, A>(ma: RemoteData<L, A>): L | null => (isFailure(ma) ? ma.error : null);

export const combine = <E, D, T>(
  r1: RemoteData<E, D>,
  r2: RemoteData<E, D>,
  callbackfn: (v1: D, v2: D) => T,
): RemoteData<E, T> => {
  if (isSuccess(r1)) {
    if (isSuccess(r2)) {
      return success(callbackfn(r1.data, r2.data));
    } else {
      return r2;
    }
  } else {
    return r1;
  }
};

export const map = <E, D, T>(r: RemoteData<E, D>, callbackfn: (data: D) => T): RemoteData<E, T> => {
  if (isSuccess(r)) {
    return success(callbackfn(r.data));
  } else {
    return r;
  }
};

export abstract class RemoteDataVue extends Vue {
  @Prop({ default: null }) remoteData: RemoteData<UserError, unknown> | RemoteData<UserError, unknown>[] | null;

  get remoteDataError(): string | undefined {
    return ArrayUtils.asList(this.remoteData).find((d) => d.failed)?.error?.message;
  }

  get remoteDataFailed(): boolean {
    return ArrayUtils.asList(this.remoteData).some((d) => d.failed);
  }

  get remoteDataPending(): boolean {
    return ArrayUtils.asList(this.remoteData).some((r) => r.pending);
  }

  get remoteDataPendingOrError(): boolean {
    return ArrayUtils.asList(this.remoteData).some((d) => d.pending || d.error);
  }

  get remoteDataSucceeded(): boolean {
    return ArrayUtils.asList(this.remoteData).every((d) => d.succeeded);
  }
}
