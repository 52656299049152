
import { formatNumber } from "@/utils/number-utils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ComparisonOperator, PriceTrigger } from "zaehlerfreunde-proto-types/automations_pb";

@Component
export default class PriceTriggerComponent extends Vue {
  @Prop()
  trigger: PriceTrigger;

  @Prop({ default: false })
  editing: boolean;

  operator: ComparisonOperator = ComparisonOperator.GREATER_THAN;
  price: string = "0";

  @Watch("trigger")
  onTriggerChanged(): void {
    this.price = formatNumber(this.trigger.getPricePerKwh());
  }

  @Watch("operator")
  onOperatorChanged(): void {
    this.trigger.setOperator(this.operator);
  }

  @Watch("price")
  onPriceChanged() {
    this.trigger.setPricePerKwh(Number.parseFloat(this.price));
  }

  mounted(): void {
    this.onOperatorChanged();
    this.$emit("valid", true);

    this.operator = this.trigger.getOperator();
    this.price = this.trigger.getPricePerKwh().toString();
  }

  operatorLabel(operator: ComparisonOperator) {
    switch (operator) {
      case ComparisonOperator.GREATER_THAN:
        return "teurer";
      case ComparisonOperator.LESS_THAN:
        return "günstiger";
      default:
        return "";
    }
  }

  operatorOptions = [
    {
      label: this.operatorLabel(ComparisonOperator.GREATER_THAN),
      value: ComparisonOperator.GREATER_THAN,
    },
    {
      label: this.operatorLabel(ComparisonOperator.LESS_THAN),
      value: ComparisonOperator.LESS_THAN,
    },
  ];
}
