import { Space } from "zaehlerfreunde-proto-types/space_pb";

export function getSpaceAddress(space: Space): string {
  const address = space.getAddress();

  if (address) {
    return address.getAddressLineOne() + ", " + address.getPostcode() + ", " + address.getCity();
  } else {
    return space.getCategoryName();
  }
}

export const notSpecifiedIngestPriceLabel: string = "Nicht angegeben";
