
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Icon extends Vue {
  @Prop({ default: "" }) icon: string;
  @Prop({ default: "black" }) color: string;
  @Prop({ default: "medium" }) size: "small" | "medium" | "large";

  get iconWithPrefix(): string {
    if (!this.icon.startsWith("mdi")) {
      return "mdi-" + this.icon;
    } else {
      return this.icon;
    }
  }

  get fontSize(): number {
    switch (this.size) {
      case "small":
        return 18;
      case "large":
        return 28;
      default:
        return 24;
    }
  }
}
