
import { paths } from "@/router/routes";
import { Vue, Component } from "vue-property-decorator";
import LatestManualReadings from "./LatestManualReadings.vue";

export const NON_SMART_METER_INITIALISATION = "non-smart-meter-init";

@Component({
  components: {
    LatestManualReadings,
  },
})
export default class UpdateManualReadings extends Vue {
  onInitManualReadings(): void {
    this.$router.push(`${paths.platform.connectDevice}/${NON_SMART_METER_INITIALISATION}`);
  }
}
