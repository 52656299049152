
import { Vue, Component, Prop } from "vue-property-decorator";
import Certificates from "./Certificates.vue";
import { SearchResult } from "zaehlerfreunde-proto-types/tariff_pb";

@Component({
  components: {
    Certificates,
  },
})
export default class TariffDetails extends Vue {
  @Prop({ default: null }) tariffSearchResult: SearchResult;
  @Prop() value: boolean;
}
