
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { initialized, pending, success, failure, RemoteData, RemoteCall } from "../../../../store/utils/remote-data";
import { UserError, userErrorFrom } from "../../../../types/user-error";
import spaces from "@/store/modules/spaces";
import { deviceServiceClient, readingsServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { DeviceRegistration, OcppRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { SetBatteryCapacityRequest } from "zaehlerfreunde-central/reading_service_pb";
import { parseFloatOptional } from "@/utils/number-utils";

@Component
export default class OcppConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  step: "generateUrl" | "copyUrl" | "batteryCapacity" = "generateUrl";

  paths = paths;
  ocppRegisterDeviceCall: RemoteData<UserError, string> = initialized;
  setBatteryCapacityCall: RemoteCall<UserError> = initialized;

  acceptedTerms: boolean = false;
  copied: boolean = false;
  ocppWebsocketURL: string = "";
  batteryCapacity: string = "";
  deviceId: string | null = null;

  get title(): string {
    return this.generalInfo.deviceProviderName + " wallbox verbinden";
  }

  copyUrl(): void {
    navigator.clipboard.writeText(this.ocppWebsocketURL);
    this.copied = true;
  }

  async setBatteryCapacity(): Promise<void> {
    const request = new SetBatteryCapacityRequest();
    request.setDeviceId(this.deviceId ?? "");
    request.setBatteryCapacity(parseFloatOptional(this.batteryCapacity) ?? 0);

    try {
      this.setBatteryCapacityCall = pending;
      await readingsServiceClient.setBatteryCapacity(request, {});
      this.setBatteryCapacityCall = success(void 0);
    } catch (error) {
      this.setBatteryCapacityCall = failure(userErrorFrom(error));
    }
  }

  async submit(): Promise<void> {
    this.ocppRegisterDeviceCall = pending;

    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);
    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new OcppRegistrationDetails();
    if (this.generalInfo.deviceProviderName) {
      details.setProviderName(this.generalInfo.deviceProviderName);
    }
    registration.setOcpp(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    try {
      const response = await deviceServiceClient.registerDevice(request, {});
      this.deviceId = response.getDeviceId();
      this.ocppRegisterDeviceCall = success(response.getOcppWebsocketUrl());
      this.step = "copyUrl";
    } catch (error) {
      this.ocppRegisterDeviceCall = failure(userErrorFrom(error));
    }

    if (this.ocppRegisterDeviceCall.succeeded && this.ocppRegisterDeviceCall.data) {
      this.ocppWebsocketURL = this.ocppRegisterDeviceCall.data;
    }
  }

  goToInstruction(): void {
    if (this.generalInfo.instructionsPath) {
      window.open(window.location.origin + this.generalInfo.instructionsPath);
    }
  }

  onClose(): void {
    this.$router.go(-1);
  }
}
