import { render, staticRenderFns } from "./MarketPremium.vue?vue&type=template&id=5cc1818b&scoped=true"
import script from "./MarketPremium.vue?vue&type=script&lang=ts"
export * from "./MarketPremium.vue?vue&type=script&lang=ts"
import style0 from "./MarketPremium.vue?vue&type=style&index=0&id=5cc1818b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc1818b",
  null
  
)

export default component.exports