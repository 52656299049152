import { UserError, userErrorFrom } from "@/types/user-error";
import { namespace } from "vuex-class";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";

import { failure, initialized, pending, RemoteData, success } from "../utils/remote-data";
import { spaceServiceClient, userServiceClient } from "@/config/service-clients";
import {
  GetAdminUsersRequest,
  GetUsersRequest,
  CheckUserPermissionRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { User, UserPermission } from "zaehlerfreunde-proto-types/user_pb";
import { GetPartnerDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { deviceServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetSpacesRequest } from "zaehlerfreunde-central/space_service_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "admin",
})
class AdminModule extends VuexModule {
  partnerSpaces: RemoteData<UserError, Space[]> = initialized;
  adminUsers: RemoteData<UserError, User[]> = initialized;
  partnerDevices: RemoteData<UserError, Device[]> = initialized;
  spacePages: RemoteData<UserError, number> = initialized;
  userPages: RemoteData<UserError, number> = initialized;
  devicePages: RemoteData<UserError, number> = initialized;

  isAdminUser: RemoteData<UserError, boolean> = initialized;
  isSuperAdminUser: RemoteData<UserError, boolean> = initialized;

  @Action
  async getPartnerSpaces(request: GetSpacesRequest) {
    try {
      this.setPartnerSpaces(pending);
      this.setSpacePages(pending);
      const response = await spaceServiceClient.getSpaces(request, {});
      this.setPartnerSpaces(success(response.getSpacesList()));
      this.setSpacePages(success(response.getTotalNumPages()));
    } catch (error) {
      this.setPartnerSpaces(failure(userErrorFrom(error)));
      this.setSpacePages(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getPartnerDevices(request: GetPartnerDevicesRequest) {
    try {
      this.setPartnerDevices(pending);
      this.setDevicePages(pending);
      const response = await deviceServiceClient.getPartnerDevices(request, {});
      this.setPartnerDevices(success(response.getDevicesList()));
      this.setDevicePages(success(response.getTotalNumPages()));
    } catch (error) {
      this.setPartnerDevices(failure(userErrorFrom(error)));
      this.setDevicePages(failure(userErrorFrom(error)));
    }
  }

  @Action
  async checkAdminStatus() {
    this.setAdminStatus(pending);
    const request = new CheckUserPermissionRequest();
    request.setPermission(UserPermission.ADMIN);
    try {
      const response = await userServiceClient.checkUserPermission(request, {});
      if (response.getHasPermission()) {
        this.setAdminStatus(success(true));
      } else {
        this.setAdminStatus(success(false));
      }
    } catch (error) {
      this.setAdminStatus(failure(userErrorFrom("Beim Prüfen des Admin Status ist ein Fehler aufgetreten.")));
    }
  }

  @Action
  async checkSuperAdminStatus() {
    this.setSuperAdminStatus(pending);
    const request = new CheckUserPermissionRequest();
    request.setPermission(UserPermission.SWITCH_PARTNER);
    try {
      const response = await userServiceClient.checkUserPermission(request, {});
      if (response.getHasPermission()) {
        this.setSuperAdminStatus(success(true));
      } else {
        this.setSuperAdminStatus(success(false));
      }
    } catch (error) {
      this.setSuperAdminStatus(failure(userErrorFrom("Beim Prüfen des Admin Status ist ein Fehler aufgetreten.")));
    }
  }

  @Action
  async getAdminUsers(request: GetAdminUsersRequest) {
    try {
      this.setAdminUsers(pending);
      const response = await userServiceClient.getAdminUsers(request, {});
      this.setAdminUsers(success(response.getUsersList()));
    } catch (error) {
      this.setAdminUsers(failure(userErrorFrom(error)));
    }
  }

  // Mutations
  @Mutation
  setPartnerSpaces(partnerSpaces: RemoteData<UserError, Space[]>) {
    this.partnerSpaces = partnerSpaces;
  }

  @Mutation
  setPartnerDevices(partnerDevices: RemoteData<UserError, Device[]>) {
    this.partnerDevices = partnerDevices;
  }

  @Mutation
  setSpacePages(pages: RemoteData<UserError, number>) {
    this.spacePages = pages;
  }

  @Mutation
  setUserPages(pages: RemoteData<UserError, number>) {
    this.userPages = pages;
  }

  @Mutation
  setDevicePages(pages: RemoteData<UserError, number>) {
    this.devicePages = pages;
  }

  @Mutation
  setAdminStatus(adminStatus: RemoteData<UserError, boolean>) {
    this.isAdminUser = adminStatus;
  }

  @Mutation
  setSuperAdminStatus(superAdminStatus: RemoteData<UserError, boolean>) {
    this.isSuperAdminUser = superAdminStatus;
  }

  @Mutation
  setAdminUsers(adminUsers: RemoteData<UserError, User[]>) {
    this.adminUsers = adminUsers;
  }
}

export const adminModule = namespace("admin");
export default getModule(AdminModule);
