
import { RemoteData, failure, initialized, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { GetAuthConfigRequest, GetPartnerRequest, GetPartnersRequest } from "zaehlerfreunde-central/partner_service_pb";
import { partnerServiceClient, userServiceClient } from "@/config/service-clients";
import { UpdatePartnerRequest } from "zaehlerfreunde-central/user_service_pb";
import partner, { partnerModule } from "@/store/modules/partner";
import CreatePartnerDialog from "@/pages/platform/Partner/CreatePartner.vue";

@Component({
  components: { CreatePartnerDialog },
})
export default class PartnerSelect extends Vue {
  partners: RemoteData<UserError, Partner[]> = initialized;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @Prop({ default: null }) userId: string | null;
  @Prop({ default: null }) partnerId: string | null;
  @Prop({ default: false }) isAdminView: boolean;
  RemoteCall: UserError | null = null;
  selectedPartner: Partner | null = null;
  showCreatePartnerDialog: boolean = false;

  mounted(): void {
    this.loadPartner();
  }

  async loadPartner(): Promise<void> {
    const request = new GetPartnersRequest();
    if (this.isAdminView) {
      await this.getUserPartner();
    } else {
      if (!this.partner.data) await partner.getPartner();

      this.selectedPartner = this.partner.data;
    }

    try {
      const response = await partnerServiceClient.getPartners(request, {});
      if (response) {
        this.partners = success(response.getPartnersList());
      }
    } catch (error) {
      this.partners = failure(userErrorFrom(error));
    }
  }

  async getUserPartner(): Promise<void> {
    const request = new GetPartnerRequest();
    if (this.userId && this.partnerId) {
      request.setUserId(this.userId);
      request.setPartnerId(this.partnerId);
      try {
        const response = await partnerServiceClient.getPartner(request, {});
        const p = response.getPartner();
        if (p) {
          this.selectedPartner = p;
        }
      } catch (error) {
        this.RemoteCall = userErrorFrom(error);
      }
    }
  }

  partnerName(partner: Partner): string {
    return partner.getName() ?? "";
  }

  partnerValue(partner: Partner): string {
    return partner.getId() ?? "";
  }

  async onPartnerSelected(p: Partner): Promise<void> {
    if (!p) {
      return;
    }

    const request = new UpdatePartnerRequest();
    request.setPartnerId(p.getId());
    if (this.isAdminView && this.userId) {
      request.setUserId(this.userId);
    }
    try {
      await userServiceClient.updatePartner(request, {});
      partner.getPartner();
    } catch (error) {
      this.RemoteCall = userErrorFrom(error);
    }

    if (!this.isAdminView) {
      this.redirect(p.getId());
    }
  }

  async redirect(id: string): Promise<void> {
    const request = new GetAuthConfigRequest();

    request.setPartnerId(id);
    try {
      const response = await partnerServiceClient.getAuthConfig(request, {});
      const domain = response.getAuthConfig()?.getDomain();
      if (domain) {
        window.location.href = domain;
      }
    } catch (error) {
      this.RemoteCall = userErrorFrom(error);
    }
  }
}
