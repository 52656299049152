
import { paths } from "@/router/routes";
import { BLUEMETERING_PARTNER_ID, partnerModule } from "@/store/modules/partner";
import { subscriptionModule } from "@/store/modules/subscription";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class ConnectDeviceReminder extends Vue {
  @partnerModule.Getter isPartnerBlueMetering: boolean;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @subscriptionModule.Getter includedFeatures: Feature[];

  onConnectDeviceClicked(): void {
    if (this.isPartnerBlueMetering) {
      this.$router.push(`${paths.platform.connectDevice}/${BLUEMETERING_PARTNER_ID}`);
    } else {
      if (this.partner.data?.getType() === Partner.Type.B2B && !this.includedFeatures.includes(Feature.UNLIMITED_DEVICES)) {
        this.$router.push({ path: paths.platform.account.subscriptions, query: { requiredFeature: "b2b" } });
      } else {
        this.$router.push(paths.platform.connectDevice);
      }
    }
  }
}
