
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GetPartnerDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { LandlordToTenantMeterType } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { deviceServiceClient, landlordToTenantServiceClient } from "@/config/service-clients";
import { AddLandlordToTenantMeterRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";

@Component({
  components: {},
})
export default class AddLandlordToTenantMeterDialog extends Vue {
  @Prop() projectId: string;
  createMeterDialog: RemoteCall<UserError> = initialized;
  LandlordToTenantMeterType = LandlordToTenantMeterType;
  selectedType: LandlordToTenantMeterType | null = null;
  name: string | null = null;
  search: string = "";
  id: string | null = null;
  partnerDevices: RemoteData<UserError, Device[]> = initialized;

  meterType = [
    { text: "PV-Anlage", value: LandlordToTenantMeterType.SOLAR_PANEL },
    { text: "PV-Quote", value: LandlordToTenantMeterType.VIRTUAL },
  ];

  get infoComplete(): boolean {
    return (
      (this.selectedType != LandlordToTenantMeterType.VIRTUAL && this.id != null) ||
      (this.selectedType == LandlordToTenantMeterType.VIRTUAL && this.name != null)
    );
  }

  deviceName(d: Device): string {
    return d.getName();
  }
  deviceId(d: Device): string {
    return d.getId();
  }
  devicePhoto(d: Device): string | undefined {
    return d.getDeviceTypeInfo()?.getImageUrl();
  }

  mounted(): void {
    this.loadDevices();
  }

  async loadDevices(): Promise<void> {
    const request = new GetPartnerDevicesRequest();
    request.setPageSize(10);
    request.setPage(0);

    if (this.search != "") {
      request.setSearchFilter(this.search);
    }

    try {
      this.partnerDevices = pending;
      const response = await deviceServiceClient.getPartnerDevices(request, {});
      this.partnerDevices = success(response.getDevicesList());
    } catch (error) {
      this.partnerDevices = failure(userErrorFrom(error));
    }
  }

  async addMeter(): Promise<void> {
    const request = new AddLandlordToTenantMeterRequest();
    if (this.selectedType) {
      request.setMeterType(this.selectedType);
    }

    request.setProjectId(this.projectId);

    if (this.selectedType == LandlordToTenantMeterType.VIRTUAL && this.name) {
      request.setDeviceName(this.name);
    } else if (this.selectedType != LandlordToTenantMeterType.VIRTUAL) {
      request.setDeviceId(this.id ?? "");
    }

    try {
      await landlordToTenantServiceClient.addLandlordToTenantMeter(request, {});
      this.createMeterDialog = success(void 0);
      this.$emit("meter-added");
    } catch (error) {
      this.createMeterDialog = failure(userErrorFrom(error));
    }
  }
}
