
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { RemoteCall } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { TabularChartRowData } from "../../dashboard/update-manual-readings/LatestManualReadings.vue";
import { getUnitLabel } from "@/utils/proto-utils";

@Component
export default class CorrectManualReading extends Vue {
  @Prop() rowData: TabularChartRowData;
  @Prop() device: Device | null;
  @Prop() updateCall: RemoteCall<UserError>;

  readingValue: number = 0;
  unitLabel: string = "";

  mounted(): void {
    this.unitLabel = getUnitLabel(this.rowData.unit);
    this.readingValue = this.rowData.value;
  }

  onUpdate() {
    this.$emit("updated-row", { ...this.rowData, value: this.readingValue });
  }
}
