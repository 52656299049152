
import { Component, Prop, Vue } from "vue-property-decorator";
import { MiniPVProductSimulation } from "zaehlerfreunde-proto-types/simulations_pb";

@Component({})
export default class MiniPVSimulationResult extends Vue {
  @Prop() productSimulation: MiniPVProductSimulation;
  @Prop({ default: false }) bestOption: boolean;
  @Prop() itemsPerPage: number;

  onAffiliateLinkClicked(): void {
    this.$gtag.event("mini-pv-affiliate-link-opened", {
      event_category: "user-action",
      event_label: `Affiliate link of ${this.productSimulation.getProductName()} pv-module opened`,
    });
  }
}
