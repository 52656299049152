
import { userServiceClient } from "@/config/service-clients";
import { RemoteData, failure, initialized, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetUserByIdRequest } from "zaehlerfreunde-central/user_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import ChangeSpaceData from "./ChangeSpaceData.vue";
import { notSpecifiedIngestPriceLabel } from "@/utils/space-utils";

export interface SpaceDetailsData {
  title: string;
  value: string;
}

@Component({
  components: {
    ChangeSpaceData,
  },
})
export default class SpaceDetails extends Vue {
  @Prop() spaceInfo: RemoteData<UserError, Space>;
  ownerDetails: RemoteData<UserError, User> = initialized;
  detailsItems: SpaceDetailsData[] = [];
  showChangeSpaceDataDialog: boolean = false;

  get spaceDetails(): SpaceDetailsData[] {
    return [
      {
        title: "Owner",
        value: this.ownerDetails.data?.getName() ?? "",
      },
      ...(this.spaceInfo?.data?.hasAddress()
        ? [
            {
              title: "Adresszeile 1",
              value: this.spaceInfo?.data?.getAddress()?.getAddressLineOne() ?? "",
            },
            {
              title: "Stadt",
              value: this.spaceInfo.data?.getAddress()?.getCity() ?? "",
            },
            {
              title: "Postleitzahl",
              value: this.spaceInfo.data?.getAddress()?.getPostcode() ?? "",
            },
          ]
        : []),
      {
        title: "Kategorie",
        value: this.spaceInfo.data?.getCategoryName() ?? "",
      },

      {
        title: "Einspeisevergütung",
        value: this.spaceInfo.data?.getIngestPrice()
          ? this.spaceInfo.data?.getIngestPrice()?.toFixed(2).toString() + " " + "Cents/kWh"
          : notSpecifiedIngestPriceLabel,
      },
    ];
  }

  async mounted(): Promise<void> {
    const request = new GetUserByIdRequest();
    request.setUserId(this.spaceInfo.data?.getOwnerUserId() ?? "");

    try {
      const response = await userServiceClient.getUserById(request, {});
      const user = response.getUser();
      if (user) {
        this.ownerDetails = success(user);
      }
    } catch (error) {
      this.ownerDetails = failure(userErrorFrom(error));
    }
  }
}
