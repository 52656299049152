
import misc from "@/store/modules/misc";
import { partnerModule } from "@/store/modules/partner";
import { RemoteData, toList } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { FaqTopic } from "zaehlerfreunde-central/misc_service_pb";

const miscModule = namespace("misc");

@Component
export default class Faq extends Vue {
  @miscModule.State faqTopics: RemoteData<UserError, FaqTopic[]>;
  @partnerModule.Getter supportEmail: string;

  get topicList(): FaqTopic[] {
    return toList(this.faqTopics);
  }

  metaInfo(): { title: string } {
    return {
      title: "FAQ",
    };
  }

  beforeMount(): void {
    misc.loadFAQContent();
  }
}
