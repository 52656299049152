
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GetMetergridTenantsRequest, MetergridProject } from "zaehlerfreunde-central/device_service_pb";

@Component
export default class MetergridProjectSelection extends Vue {
  @Prop() metergridCredentialsId: string;
  @Prop() projects: MetergridProject[];

  call: RemoteCall<UserError> = initialized;

  unselectedProjects: MetergridProject[] = [];

  get headers() {
    return [
      { value: "selected" },
      { value: "name", text: "Name" },
      { value: "location", text: "Ort" },
      { value: "bdew", text: "BDEW Nummer" },
    ];
  }

  get items() {
    return this.projects.map((p) => ({
      selected: !this.unselectedProjects.some((up) => up.getId() === p.getId()),
      name: p.getName(),
      location: p.getLocation(),
      bdew: p.getBdewNumber(),
      project: p,
    }));
  }

  onProjectSelected(project: MetergridProject, selected: boolean) {
    if (selected) {
      this.unselectedProjects = this.unselectedProjects.filter((p) => p.getId() !== project.getId());
    } else {
      this.unselectedProjects.push(project);
    }
  }

  async onNextClicked(): Promise<void> {
    try {
      this.call = pending;

      const selectedProjectIds = this.projects
        .filter((p) => !this.unselectedProjects.some((up) => up.getId() === p.getId()))
        .map((p) => p.getId());

      const request = new GetMetergridTenantsRequest();
      request.setMetergridUserCredentialsId(this.metergridCredentialsId);
      request.setProjectIdsList(selectedProjectIds);

      const response = await deviceServiceClient.getMetergridTenants(request, {});
      this.$emit("next", response.getProjectsTenantsList());

      this.call = success(void 0);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }
}
