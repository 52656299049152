
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";

@Component
export default class SwitchDate extends Vue {
  @tariffModule.State currentElectricityTariff: RemoteData<UserError, Tariff | null>;

  get isCancelDateBeforeNow(): boolean {
    return this.earliestCancelDate.getTime() < Date.now();
  }

  get earliestCancelDate(): Date {
    const date = this.currentElectricityTariff.data?.getContract()?.getEarliestCancelDate();
    if (date) {
      return new Date(date) ?? new Date();
    } else {
      return new Date();
    }
  }

  get earliestCancelDateFormatted(): string {
    return moment(this.earliestCancelDate).locale("de").format("DD. MMMM YYYY");
  }
}
