// source: types/partners.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
var types_user_pb = require('../types/user_pb.js');
goog.object.extend(proto, types_user_pb);
goog.exportSymbol('proto.types.AuthConfig', null, global);
goog.exportSymbol('proto.types.Partner', null, global);
goog.exportSymbol('proto.types.Partner.Type', null, global);
goog.exportSymbol('proto.types.PartnerCustomizationConfig', null, global);
goog.exportSymbol('proto.types.PartnerDomainStatus', null, global);
goog.exportSymbol('proto.types.PartnerLogo', null, global);
goog.exportSymbol('proto.types.PartnerSocialLinks', null, global);
goog.exportSymbol('proto.types.PartnerTheme', null, global);
goog.exportSymbol('proto.types.PartnerUserInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Partner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Partner.repeatedFields_, null);
};
goog.inherits(proto.types.Partner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Partner.displayName = 'proto.types.Partner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.PartnerTheme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.PartnerTheme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.PartnerTheme.displayName = 'proto.types.PartnerTheme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.PartnerLogo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.PartnerLogo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.PartnerLogo.displayName = 'proto.types.PartnerLogo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.PartnerUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.PartnerUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.PartnerUserInfo.displayName = 'proto.types.PartnerUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.AuthConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.AuthConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.AuthConfig.displayName = 'proto.types.AuthConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.PartnerCustomizationConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.PartnerCustomizationConfig.repeatedFields_, null);
};
goog.inherits(proto.types.PartnerCustomizationConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.PartnerCustomizationConfig.displayName = 'proto.types.PartnerCustomizationConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.PartnerSocialLinks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.PartnerSocialLinks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.PartnerSocialLinks.displayName = 'proto.types.PartnerSocialLinks';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Partner.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Partner.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Partner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Partner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Partner.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sendgridListId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    childPartnersList: jspb.Message.toObjectList(msg.getChildPartnersList(),
    proto.types.Partner.toObject, includeInstance),
    parentPartnerId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Partner}
 */
proto.types.Partner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Partner;
  return proto.types.Partner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Partner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Partner}
 */
proto.types.Partner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendgridListId(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Partner.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = new proto.types.Partner;
      reader.readMessage(value,proto.types.Partner.deserializeBinaryFromReader);
      msg.addChildPartners(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Partner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Partner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Partner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Partner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getChildPartnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.types.Partner.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.Partner.Type = {
  ZAEHLERFREUNDE: 0,
  WHITE_LABEL: 1,
  B2B: 2
};

/**
 * optional string id = 3;
 * @return {string}
 */
proto.types.Partner.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.Partner.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sendgrid_list_id = 5;
 * @return {string}
 */
proto.types.Partner.prototype.getSendgridListId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.setSendgridListId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.clearSendgridListId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Partner.prototype.hasSendgridListId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Type type = 6;
 * @return {!proto.types.Partner.Type}
 */
proto.types.Partner.prototype.getType = function() {
  return /** @type {!proto.types.Partner.Type} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Partner.Type} value
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated Partner child_partners = 8;
 * @return {!Array<!proto.types.Partner>}
 */
proto.types.Partner.prototype.getChildPartnersList = function() {
  return /** @type{!Array<!proto.types.Partner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Partner, 8));
};


/**
 * @param {!Array<!proto.types.Partner>} value
 * @return {!proto.types.Partner} returns this
*/
proto.types.Partner.prototype.setChildPartnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.types.Partner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Partner}
 */
proto.types.Partner.prototype.addChildPartners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.types.Partner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.clearChildPartnersList = function() {
  return this.setChildPartnersList([]);
};


/**
 * optional string parent_partner_id = 9;
 * @return {string}
 */
proto.types.Partner.prototype.getParentPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.setParentPartnerId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Partner} returns this
 */
proto.types.Partner.prototype.clearParentPartnerId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Partner.prototype.hasParentPartnerId = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.PartnerTheme.prototype.toObject = function(opt_includeInstance) {
  return proto.types.PartnerTheme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.PartnerTheme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerTheme.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logoLight: (f = msg.getLogoLight()) && proto.types.PartnerLogo.toObject(includeInstance, f),
    logoDark: (f = msg.getLogoDark()) && proto.types.PartnerLogo.toObject(includeInstance, f),
    favicon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fontFamily: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primaryColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    accentColor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    errorColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    infoColor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    successColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    warningColor: jspb.Message.getFieldWithDefault(msg, 12, ""),
    allowDarkMode: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.PartnerTheme}
 */
proto.types.PartnerTheme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.PartnerTheme;
  return proto.types.PartnerTheme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.PartnerTheme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.PartnerTheme}
 */
proto.types.PartnerTheme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerName(value);
      break;
    case 2:
      var value = new proto.types.PartnerLogo;
      reader.readMessage(value,proto.types.PartnerLogo.deserializeBinaryFromReader);
      msg.setLogoLight(value);
      break;
    case 3:
      var value = new proto.types.PartnerLogo;
      reader.readMessage(value,proto.types.PartnerLogo.deserializeBinaryFromReader);
      msg.setLogoDark(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFavicon(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontFamily(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryColor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccentColor(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfoColor(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuccessColor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setWarningColor(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDarkMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.PartnerTheme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.PartnerTheme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.PartnerTheme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerTheme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogoLight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.PartnerLogo.serializeBinaryToWriter
    );
  }
  f = message.getLogoDark();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.PartnerLogo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFontFamily();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimaryColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccentColor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getErrorColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInfoColor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSuccessColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getWarningColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAllowDarkMode();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string partner_name = 1;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getPartnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setPartnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PartnerLogo logo_light = 2;
 * @return {?proto.types.PartnerLogo}
 */
proto.types.PartnerTheme.prototype.getLogoLight = function() {
  return /** @type{?proto.types.PartnerLogo} */ (
    jspb.Message.getWrapperField(this, proto.types.PartnerLogo, 2));
};


/**
 * @param {?proto.types.PartnerLogo|undefined} value
 * @return {!proto.types.PartnerTheme} returns this
*/
proto.types.PartnerTheme.prototype.setLogoLight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.clearLogoLight = function() {
  return this.setLogoLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerTheme.prototype.hasLogoLight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PartnerLogo logo_dark = 3;
 * @return {?proto.types.PartnerLogo}
 */
proto.types.PartnerTheme.prototype.getLogoDark = function() {
  return /** @type{?proto.types.PartnerLogo} */ (
    jspb.Message.getWrapperField(this, proto.types.PartnerLogo, 3));
};


/**
 * @param {?proto.types.PartnerLogo|undefined} value
 * @return {!proto.types.PartnerTheme} returns this
*/
proto.types.PartnerTheme.prototype.setLogoDark = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.clearLogoDark = function() {
  return this.setLogoDark(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerTheme.prototype.hasLogoDark = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string favicon = 4;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getFavicon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setFavicon = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.clearFavicon = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerTheme.prototype.hasFavicon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string font_family = 5;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getFontFamily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setFontFamily = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string primary_color = 6;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getPrimaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setPrimaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string secondary_color = 7;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getSecondaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string accent_color = 8;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getAccentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setAccentColor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string error_color = 9;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getErrorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setErrorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string info_color = 10;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getInfoColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setInfoColor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string success_color = 11;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getSuccessColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setSuccessColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string warning_color = 12;
 * @return {string}
 */
proto.types.PartnerTheme.prototype.getWarningColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setWarningColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool allow_dark_mode = 13;
 * @return {boolean}
 */
proto.types.PartnerTheme.prototype.getAllowDarkMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerTheme} returns this
 */
proto.types.PartnerTheme.prototype.setAllowDarkMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.PartnerLogo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.PartnerLogo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.PartnerLogo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerLogo.toObject = function(includeInstance, msg) {
  var f, obj = {
    src: jspb.Message.getFieldWithDefault(msg, 1, ""),
    width: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.PartnerLogo}
 */
proto.types.PartnerLogo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.PartnerLogo;
  return proto.types.PartnerLogo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.PartnerLogo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.PartnerLogo}
 */
proto.types.PartnerLogo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrc(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.PartnerLogo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.PartnerLogo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.PartnerLogo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerLogo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string src = 1;
 * @return {string}
 */
proto.types.PartnerLogo.prototype.getSrc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerLogo} returns this
 */
proto.types.PartnerLogo.prototype.setSrc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 width = 2;
 * @return {number}
 */
proto.types.PartnerLogo.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.PartnerLogo} returns this
 */
proto.types.PartnerLogo.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.PartnerUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.PartnerUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.PartnerUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    metergridCredentialsId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.PartnerUserInfo}
 */
proto.types.PartnerUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.PartnerUserInfo;
  return proto.types.PartnerUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.PartnerUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.PartnerUserInfo}
 */
proto.types.PartnerUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetergridCredentialsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.PartnerUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.PartnerUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.PartnerUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.PartnerUserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerUserInfo} returns this
 */
proto.types.PartnerUserInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partner_user_id = 2;
 * @return {string}
 */
proto.types.PartnerUserInfo.prototype.getPartnerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerUserInfo} returns this
 */
proto.types.PartnerUserInfo.prototype.setPartnerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.types.PartnerUserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerUserInfo} returns this
 */
proto.types.PartnerUserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.types.PartnerUserInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerUserInfo} returns this
 */
proto.types.PartnerUserInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string metergrid_credentials_id = 5;
 * @return {string}
 */
proto.types.PartnerUserInfo.prototype.getMetergridCredentialsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerUserInfo} returns this
 */
proto.types.PartnerUserInfo.prototype.setMetergridCredentialsId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerUserInfo} returns this
 */
proto.types.PartnerUserInfo.prototype.clearMetergridCredentialsId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerUserInfo.prototype.hasMetergridCredentialsId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.AuthConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.types.AuthConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.AuthConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AuthConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowLandingPage: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    auth0ClientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auth0Connection: jspb.Message.getFieldWithDefault(msg, 6, ""),
    logoutRedirectUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 4, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.AuthConfig}
 */
proto.types.AuthConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.AuthConfig;
  return proto.types.AuthConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.AuthConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.AuthConfig}
 */
proto.types.AuthConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowLandingPage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuth0ClientId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuth0Connection(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoutRedirectUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.AuthConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.AuthConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.AuthConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AuthConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowLandingPage();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAuth0ClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool allow_landing_page = 1;
 * @return {boolean}
 */
proto.types.AuthConfig.prototype.getAllowLandingPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.setAllowLandingPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string auth0_client_id = 2;
 * @return {string}
 */
proto.types.AuthConfig.prototype.getAuth0ClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.setAuth0ClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string auth0_connection = 6;
 * @return {string}
 */
proto.types.AuthConfig.prototype.getAuth0Connection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.setAuth0Connection = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.clearAuth0Connection = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.AuthConfig.prototype.hasAuth0Connection = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string logout_redirect_url = 3;
 * @return {string}
 */
proto.types.AuthConfig.prototype.getLogoutRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.setLogoutRedirectUrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.clearLogoutRedirectUrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.AuthConfig.prototype.hasLogoutRedirectUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string domain = 4;
 * @return {string}
 */
proto.types.AuthConfig.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string partner_id = 5;
 * @return {string}
 */
proto.types.AuthConfig.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AuthConfig} returns this
 */
proto.types.AuthConfig.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.PartnerCustomizationConfig.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.PartnerCustomizationConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.types.PartnerCustomizationConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.PartnerCustomizationConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerCustomizationConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    hideTariffSwitch: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    hideShop: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hideSimulations: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    enabledDeviceProvidersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    hideInvoices: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hideTips: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hideDeviceStatus: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hideOverview: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    hideEnergyTariff: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hideGasTariff: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    hideWaterTariff: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    registrationInviteOnly: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    hideSpaceComparison: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    supportEmail: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hideAutomations: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    hideChatbox: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    alwaysShowMarketPrices: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    hideMarketPremium: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    hideReporting: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    allowUserTariffs: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.PartnerCustomizationConfig}
 */
proto.types.PartnerCustomizationConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.PartnerCustomizationConfig;
  return proto.types.PartnerCustomizationConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.PartnerCustomizationConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.PartnerCustomizationConfig}
 */
proto.types.PartnerCustomizationConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideTariffSwitch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideShop(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideSimulations(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.types.Device.Provider>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnabledDeviceProviders(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideInvoices(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideTips(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideDeviceStatus(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideOverview(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideEnergyTariff(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideGasTariff(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideWaterTariff(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegistrationInviteOnly(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideSpaceComparison(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportEmail(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideAutomations(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideChatbox(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysShowMarketPrices(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideMarketPremium(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideReporting(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowUserTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.PartnerCustomizationConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.PartnerCustomizationConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.PartnerCustomizationConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerCustomizationConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHideTariffSwitch();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHideShop();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHideSimulations();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEnabledDeviceProvidersList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getHideInvoices();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHideTips();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHideDeviceStatus();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHideOverview();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHideEnergyTariff();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHideGasTariff();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getHideWaterTariff();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getRegistrationInviteOnly();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getHideSpaceComparison();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSupportEmail();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHideAutomations();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHideChatbox();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getAlwaysShowMarketPrices();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getHideMarketPremium();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getHideReporting();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getAllowUserTariffs();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional bool hide_tariff_switch = 1;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideTariffSwitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideTariffSwitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool hide_shop = 2;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideShop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideShop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool hide_simulations = 3;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideSimulations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideSimulations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated Device.Provider enabled_device_providers = 4;
 * @return {!Array<!proto.types.Device.Provider>}
 */
proto.types.PartnerCustomizationConfig.prototype.getEnabledDeviceProvidersList = function() {
  return /** @type {!Array<!proto.types.Device.Provider>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.types.Device.Provider>} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setEnabledDeviceProvidersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.types.Device.Provider} value
 * @param {number=} opt_index
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.addEnabledDeviceProviders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.clearEnabledDeviceProvidersList = function() {
  return this.setEnabledDeviceProvidersList([]);
};


/**
 * optional bool hide_invoices = 5;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideInvoices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideInvoices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool hide_tips = 6;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideTips = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideTips = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool hide_device_status = 7;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideDeviceStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideDeviceStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool hide_overview = 8;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideOverview = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideOverview = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool hide_energy_tariff = 9;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideEnergyTariff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideEnergyTariff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool hide_gas_tariff = 10;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideGasTariff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideGasTariff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool hide_water_tariff = 11;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideWaterTariff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideWaterTariff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool registration_invite_only = 12;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getRegistrationInviteOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setRegistrationInviteOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool hide_space_comparison = 13;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideSpaceComparison = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideSpaceComparison = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string support_email = 14;
 * @return {string}
 */
proto.types.PartnerCustomizationConfig.prototype.getSupportEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setSupportEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool hide_automations = 15;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideAutomations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideAutomations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool hide_chatbox = 16;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideChatbox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideChatbox = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool always_show_market_prices = 17;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getAlwaysShowMarketPrices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setAlwaysShowMarketPrices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool hide_market_premium = 18;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideMarketPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideMarketPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool hide_reporting = 19;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getHideReporting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setHideReporting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool allow_user_tariffs = 20;
 * @return {boolean}
 */
proto.types.PartnerCustomizationConfig.prototype.getAllowUserTariffs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.PartnerCustomizationConfig} returns this
 */
proto.types.PartnerCustomizationConfig.prototype.setAllowUserTariffs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.PartnerSocialLinks.prototype.toObject = function(opt_includeInstance) {
  return proto.types.PartnerSocialLinks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.PartnerSocialLinks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerSocialLinks.toObject = function(includeInstance, msg) {
  var f, obj = {
    facebook: jspb.Message.getFieldWithDefault(msg, 1, ""),
    twitter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkedIn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instagram: jspb.Message.getFieldWithDefault(msg, 4, ""),
    xing: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.PartnerSocialLinks}
 */
proto.types.PartnerSocialLinks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.PartnerSocialLinks;
  return proto.types.PartnerSocialLinks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.PartnerSocialLinks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.PartnerSocialLinks}
 */
proto.types.PartnerSocialLinks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacebook(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedIn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagram(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setXing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.PartnerSocialLinks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.PartnerSocialLinks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.PartnerSocialLinks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PartnerSocialLinks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string facebook = 1;
 * @return {string}
 */
proto.types.PartnerSocialLinks.prototype.getFacebook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.setFacebook = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.clearFacebook = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerSocialLinks.prototype.hasFacebook = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string twitter = 2;
 * @return {string}
 */
proto.types.PartnerSocialLinks.prototype.getTwitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.setTwitter = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.clearTwitter = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerSocialLinks.prototype.hasTwitter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string linked_in = 3;
 * @return {string}
 */
proto.types.PartnerSocialLinks.prototype.getLinkedIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.setLinkedIn = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.clearLinkedIn = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerSocialLinks.prototype.hasLinkedIn = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string instagram = 4;
 * @return {string}
 */
proto.types.PartnerSocialLinks.prototype.getInstagram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.setInstagram = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.clearInstagram = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerSocialLinks.prototype.hasInstagram = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string xing = 5;
 * @return {string}
 */
proto.types.PartnerSocialLinks.prototype.getXing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.setXing = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.PartnerSocialLinks} returns this
 */
proto.types.PartnerSocialLinks.prototype.clearXing = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.PartnerSocialLinks.prototype.hasXing = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.types.PartnerDomainStatus = {
  NOT_CREATED: 0,
  IN_PROGRESS_DNS: 1,
  ERROR_DNS: 2,
  IN_PROGRESS_AUTH0_APP: 3,
  ERROR_AUTH0_APP: 4,
  CREATED: 5,
  VERIFY_CREATED: 6
};

goog.object.extend(exports, proto.types);
