
import { userServiceClient } from "@/config/service-clients";

import UsersOrInvitesTable from "./UsersAndInvitesTable.vue";
import { GetUsersBySpaceRequest } from "zaehlerfreunde-central/user_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";

import { Component, Vue, Prop } from "vue-property-decorator";
import { RemoteData, success, failure, initialized, pending } from "@/store/utils/remote-data";

import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { UserError, userErrorFrom } from "@/types/user-error";

import { paths } from "@/router/routes";
import { Invite } from "zaehlerfreunde-proto-types/invite_pb";

@Component({
  components: {
    UsersOrInvitesTable,
  },
})
export default class UserInfos extends Vue {
  data: RemoteData<UserError, (User | Invite)[]> = initialized;
  @Prop() selectedSpace: Space;

  paths = paths;

  beforeMount(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.data = pending;
    const request = new GetUsersBySpaceRequest();
    request.setSpaceId(this.selectedSpace.getId() ?? "");

    try {
      const response = await userServiceClient.getUsersBySpace(request, {});
      this.data = success([...response.getUsersList(), ...response.getInvitesList()]);
    } catch (error) {
      this.data = failure(userErrorFrom(error));
    }
  }
}
