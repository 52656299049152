
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";

@Component
export default class ChangeSpaceName extends Vue {
  @Prop() value: boolean;

  spaceName: string = "";
  updateSpaceNameCall: RemoteCall<UserError> = initialized;

  get validInput(): boolean {
    return this.spaceName.length > 0;
  }

  async onSaveClicked(): Promise<void> {
    const request = new UpdateSpaceRequest();
    request.setSpaceId(spaces.selectedSpaceId);
    request.setName(this.spaceName);

    this.updateSpaceNameCall = pending;

    try {
      await spaceServiceClient.updateSpace(request, {});

      this.$emit("input", false);

      spaces.getSpaces();

      this.updateSpaceNameCall = success(void 0);
    } catch (error) {
      this.updateSpaceNameCall = failure(userErrorFrom(error));
    }
  }
}
