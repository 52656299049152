
import { userServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import moment from "moment";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetUsersSubscribedToPlanRequest } from "zaehlerfreunde-central/user_service_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";

@Component
export default class SubscribedUsers extends Vue {
  page = 1;
  pageSize = 10;
  pages = 1;
  users: RemoteData<UserError, User[]> = initialized;

  headers = [
    { text: "", value: "picture" },
    { text: "Nutzername", value: "name" },
    { text: "E-Mail", value: "email" },
    { text: "", value: "controls" },
  ];

  @Watch("page")
  onPageChanged(): void {
    this.getUsers();
  }

  mounted(): void {
    this.getUsers();
  }

  get rows() {
    return this.users.list.map((u) => ({
      id: u.getId(),
      name: u.getName(),
      email: u.getEmail(),
      picture: u.getUserProfileInfo()?.getPicture() ?? "",
    }));
  }

  async getUsers(): Promise<void> {
    const planId = this.$route.params["planId"] as string;

    const pagination = new Pagination();
    pagination.setPage(this.page - 1);
    pagination.setPageSize(this.pageSize);

    const request = new GetUsersSubscribedToPlanRequest();
    request.setPagination(pagination);
    request.setSubscriptionPlanId(planId);

    try {
      this.users = pending;
      const response = await userServiceClient.getUsersSubscribedToPlan(request, {});
      this.users = success(response.getUsersList());
      this.pages = response.getNumPages();
    } catch (error) {
      this.users = failure(userErrorFrom(error));
    }
  }

  handleRowClick(user: { id: string }) {
    this.$router.push(`${paths.admin.users}/${user.id}`);
  }
}
