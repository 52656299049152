
import { Component, Vue } from "vue-property-decorator";

export interface InstructionData {
  name: string;
  description: string;
  imageURL: string;
  instructionsPath: string;
}

@Component
export default class Instructions extends Vue {}
