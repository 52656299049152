
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { NotificationAction } from "zaehlerfreunde-proto-types/automations_pb";

@Component
export default class NotificationActionComponent extends Vue {
  @Prop()
  notificationAction: NotificationAction;

  @Prop()
  showPushNotificationCheck: boolean;

  @Prop()
  editing: boolean;

  emailsStr: string = "";
  phoneNumbersStr: string = "";
  enablePushNotification: boolean = false;

  mounted() {
    this.emailsStr = this.notificationAction.getEmailsList().join(", ");
    this.phoneNumbersStr = this.notificationAction.getPhoneNumbersList().join(", ");
    this.enablePushNotification = !!this.notificationAction.getPushNotification();
  }

  get emails(): string[] {
    return this.emailsStr
      .replaceAll(" ", "")
      .split(",")
      .filter((e) => e.length > 0);
  }

  get phoneNumbers(): string[] {
    return this.phoneNumbersStr
      .replaceAll(" ", "")
      .split(",")
      .filter((e) => e.length > 0);
  }

  @Watch("emailsStr")
  @Watch("phoneNumbersStr")
  @Watch("enablePushNotification")
  onRecipientsChanged() {
    this.$emit("valid", this.emails.length + this.phoneNumbers.length > 0 || !!this.enablePushNotification);
    this.notificationAction.setEmailsList(this.emails);
    this.notificationAction.setPhoneNumbersList(this.phoneNumbers);

    if (this.enablePushNotification) {
      this.notificationAction.setPushNotification(new NotificationAction.PushNotification());
    } else {
      this.notificationAction.setPushNotification(undefined);
    }
  }
}
