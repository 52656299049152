
import { getAuthService } from "@/auth";
import { userServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import account from "@/store/modules/account";
import {
  failure,
  initialized,
  pending,
  RemoteCall,
  RemoteData,
  success,
  successOptional,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { partnerAuthConfig } from "@/config/auth-config";
import { Vue, Component, Watch } from "vue-property-decorator";
import { AcceptInviteRequest, GetInviteEmailRequest, GetInviteRequest } from "zaehlerfreunde-central/user_service_pb";
import { Invite } from "zaehlerfreunde-proto-types/invite_pb";
import theme, { themeModule } from "@/store/modules/theme";
import { PartnerTheme } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class AcceptInvite extends Vue {
  @themeModule.State partnerTheme: PartnerTheme | null;

  invite: RemoteData<UserError, Invite> = initialized;
  authorizationCheck: RemoteCall<UserError> = initialized;
  acceptInviteCall: RemoteCall<UserError> = initialized;
  getInviteCall: RemoteCall<UserError> = initialized;
  email: string = "";

  @Watch("partnerTheme")
  onPartnerThemeChanged() {
    if (this.partnerTheme) {
      var r = document.querySelector(":root") as HTMLElement;
      r.style.setProperty("--zf-primary-color", this.partnerTheme.getPrimaryColor());
    }
  }

  async mounted(): Promise<void> {
    let partnerId = this.$route.params["partnerId"];
    if (!partnerId) {
      const authConfig = await partnerAuthConfig;
      partnerId = authConfig.getPartnerId();
    }

    await account.getRegistrationStatus(partnerId);

    this.authorizationCheck = pending;
    const authService = getAuthService();
    const isAuthenticated = await authService.isAuthenticated;
    const inviteId = this.$route.params["inviteId"];
    this.authorizationCheck = success(void 0);

    if (isAuthenticated) {
      theme.getPartnerTheme();
      this.getInvite(inviteId);
    } else {
      this.redirectToSignUp(inviteId);
    }
  }

  rejectInvite() {
    this.$router.replace(paths.platform.dashboard);
  }

  async acceptInvite(): Promise<void> {
    const inviteId = this.$route.params["inviteId"];

    this.acceptInviteCall = pending;
    try {
      await userServiceClient.acceptInvite(new AcceptInviteRequest().setInviteId(inviteId), {});
      this.acceptInviteCall = success(void 0);
      this.$router.push(paths.platform.dashboard);
    } catch (error) {
      this.acceptInviteCall = failure(userErrorFrom(error));
    }
  }

  async redirectToSignUp(inviteId: string): Promise<void> {
    const authService = getAuthService();

    this.getInviteCall = pending;

    try {
      const request = new GetInviteEmailRequest();
      request.setInviteId(inviteId);
      const response = await userServiceClient.getInviteEmail(request, {});
      this.email = response.getEmail();
      this.getInviteCall = success(void 0);
    } catch (error) {
      this.getInviteCall = failure(userErrorFrom(error));
    }
    authService.loginWithRedirect({
      appState: {
        targetUrl: this.$route.fullPath,
      },
      authorizationParams: { screen_hint: "signup", login_hint: this.email },
    });
  }

  async getInvite(inviteId: string): Promise<void> {
    const request = new GetInviteRequest();
    request.setInviteId(inviteId);

    try {
      this.invite = pending;
      const response = await userServiceClient.getInvite(request, {});
      this.invite = successOptional(response.getInvite());
    } catch (error) {
      this.invite = failure(userErrorFrom(error));
    }
  }
}
