// source: types/device_reading.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
goog.exportSymbol('proto.types.DeviceReading', null, global);
goog.exportSymbol('proto.types.DeviceReadingStats', null, global);
goog.exportSymbol('proto.types.DeviceReadingStatsUpdates', null, global);
goog.exportSymbol('proto.types.Direction', null, global);
goog.exportSymbol('proto.types.ManualReading', null, global);
goog.exportSymbol('proto.types.Measurement', null, global);
goog.exportSymbol('proto.types.MeasurementWithLabel', null, global);
goog.exportSymbol('proto.types.TariffType', null, global);
goog.exportSymbol('proto.types.Unit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceReading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceReading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceReading.displayName = 'proto.types.DeviceReading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ManualReading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.ManualReading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ManualReading.displayName = 'proto.types.ManualReading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MeasurementWithLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MeasurementWithLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MeasurementWithLabel.displayName = 'proto.types.MeasurementWithLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceReadingStatsUpdates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.DeviceReadingStatsUpdates.repeatedFields_, null);
};
goog.inherits(proto.types.DeviceReadingStatsUpdates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceReadingStatsUpdates.displayName = 'proto.types.DeviceReadingStatsUpdates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceReadingStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.DeviceReadingStats.repeatedFields_, null);
};
goog.inherits(proto.types.DeviceReadingStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceReadingStats.displayName = 'proto.types.DeviceReadingStats';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceReading.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceReading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceReading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReading.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    measureName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    measurementTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    measurement: jspb.Message.getFieldWithDefault(msg, 4, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 7, 0),
    medium: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tariffType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    channel: jspb.Message.getFieldWithDefault(msg, 11, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    provider: jspb.Message.getFieldWithDefault(msg, 13, 0),
    deviceType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    allowAggregation: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceReading}
 */
proto.types.DeviceReading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceReading;
  return proto.types.DeviceReading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceReading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceReading}
 */
proto.types.DeviceReading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasureName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeasurementTime(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Measurement} */ (reader.readEnum());
      msg.setMeasurement(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {!proto.types.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 7:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 8:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    case 10:
      var value = /** @type {!proto.types.TariffType} */ (reader.readEnum());
      msg.setTariffType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 13:
      var value = /** @type {!proto.types.Device.Provider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 14:
      var value = /** @type {!proto.types.Device.Type} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAggregation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceReading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceReading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceReading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeasureName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMeasurementTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMeasurement();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTariffType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getAllowAggregation();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.DeviceReading.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string measure_name = 2;
 * @return {string}
 */
proto.types.DeviceReading.prototype.getMeasureName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setMeasureName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 measurement_time = 3;
 * @return {number}
 */
proto.types.DeviceReading.prototype.getMeasurementTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setMeasurementTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Measurement measurement = 4;
 * @return {!proto.types.Measurement}
 */
proto.types.DeviceReading.prototype.getMeasurement = function() {
  return /** @type {!proto.types.Measurement} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Measurement} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setMeasurement = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional double value = 5;
 * @return {number}
 */
proto.types.DeviceReading.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Unit unit = 6;
 * @return {!proto.types.Unit}
 */
proto.types.DeviceReading.prototype.getUnit = function() {
  return /** @type {!proto.types.Unit} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.Unit} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Direction direction = 7;
 * @return {!proto.types.Direction}
 */
proto.types.DeviceReading.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Device.Medium medium = 8;
 * @return {!proto.types.Device.Medium}
 */
proto.types.DeviceReading.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional TariffType tariff_type = 10;
 * @return {!proto.types.TariffType}
 */
proto.types.DeviceReading.prototype.getTariffType = function() {
  return /** @type {!proto.types.TariffType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.types.TariffType} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setTariffType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string channel = 11;
 * @return {string}
 */
proto.types.DeviceReading.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 duration = 12;
 * @return {number}
 */
proto.types.DeviceReading.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional Device.Provider provider = 13;
 * @return {!proto.types.Device.Provider}
 */
proto.types.DeviceReading.prototype.getProvider = function() {
  return /** @type {!proto.types.Device.Provider} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.types.Device.Provider} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional Device.Type device_type = 14;
 * @return {!proto.types.Device.Type}
 */
proto.types.DeviceReading.prototype.getDeviceType = function() {
  return /** @type {!proto.types.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.types.Device.Type} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool allow_aggregation = 15;
 * @return {boolean}
 */
proto.types.DeviceReading.prototype.getAllowAggregation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceReading} returns this
 */
proto.types.DeviceReading.prototype.setAllowAggregation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ManualReading.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ManualReading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ManualReading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ManualReading.toObject = function(includeInstance, msg) {
  var f, obj = {
    measurementTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    consumptionReadingValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    ingestionReadingValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    isUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ManualReading}
 */
proto.types.ManualReading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ManualReading;
  return proto.types.ManualReading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ManualReading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ManualReading}
 */
proto.types.ManualReading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMeasurementTime(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConsumptionReadingValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIngestionReadingValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ManualReading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ManualReading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ManualReading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ManualReading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasurementTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getConsumptionReadingValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getIsUpdate();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 measurement_time = 1;
 * @return {number}
 */
proto.types.ManualReading.prototype.getMeasurementTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.ManualReading} returns this
 */
proto.types.ManualReading.prototype.setMeasurementTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Unit unit = 2;
 * @return {!proto.types.Unit}
 */
proto.types.ManualReading.prototype.getUnit = function() {
  return /** @type {!proto.types.Unit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Unit} value
 * @return {!proto.types.ManualReading} returns this
 */
proto.types.ManualReading.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double consumption_reading_value = 3;
 * @return {number}
 */
proto.types.ManualReading.prototype.getConsumptionReadingValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.ManualReading} returns this
 */
proto.types.ManualReading.prototype.setConsumptionReadingValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double ingestion_reading_value = 4;
 * @return {number}
 */
proto.types.ManualReading.prototype.getIngestionReadingValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.ManualReading} returns this
 */
proto.types.ManualReading.prototype.setIngestionReadingValue = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.ManualReading} returns this
 */
proto.types.ManualReading.prototype.clearIngestionReadingValue = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ManualReading.prototype.hasIngestionReadingValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_update = 5;
 * @return {boolean}
 */
proto.types.ManualReading.prototype.getIsUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.ManualReading} returns this
 */
proto.types.ManualReading.prototype.setIsUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MeasurementWithLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MeasurementWithLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MeasurementWithLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MeasurementWithLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    measurement: jspb.Message.getFieldWithDefault(msg, 1, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MeasurementWithLabel}
 */
proto.types.MeasurementWithLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MeasurementWithLabel;
  return proto.types.MeasurementWithLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MeasurementWithLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MeasurementWithLabel}
 */
proto.types.MeasurementWithLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Measurement} */ (reader.readEnum());
      msg.setMeasurement(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MeasurementWithLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MeasurementWithLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MeasurementWithLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MeasurementWithLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasurement();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Measurement measurement = 1;
 * @return {!proto.types.Measurement}
 */
proto.types.MeasurementWithLabel.prototype.getMeasurement = function() {
  return /** @type {!proto.types.Measurement} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Measurement} value
 * @return {!proto.types.MeasurementWithLabel} returns this
 */
proto.types.MeasurementWithLabel.prototype.setMeasurement = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.types.MeasurementWithLabel.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.MeasurementWithLabel} returns this
 */
proto.types.MeasurementWithLabel.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.DeviceReadingStatsUpdates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceReadingStatsUpdates.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceReadingStatsUpdates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceReadingStatsUpdates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReadingStatsUpdates.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceReadingStatsUpdatesList: jspb.Message.toObjectList(msg.getDeviceReadingStatsUpdatesList(),
    proto.types.DeviceReadingStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceReadingStatsUpdates}
 */
proto.types.DeviceReadingStatsUpdates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceReadingStatsUpdates;
  return proto.types.DeviceReadingStatsUpdates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceReadingStatsUpdates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceReadingStatsUpdates}
 */
proto.types.DeviceReadingStatsUpdates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.DeviceReadingStats;
      reader.readMessage(value,proto.types.DeviceReadingStats.deserializeBinaryFromReader);
      msg.addDeviceReadingStatsUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceReadingStatsUpdates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceReadingStatsUpdates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceReadingStatsUpdates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReadingStatsUpdates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceReadingStatsUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.DeviceReadingStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeviceReadingStats device_reading_stats_updates = 1;
 * @return {!Array<!proto.types.DeviceReadingStats>}
 */
proto.types.DeviceReadingStatsUpdates.prototype.getDeviceReadingStatsUpdatesList = function() {
  return /** @type{!Array<!proto.types.DeviceReadingStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.DeviceReadingStats, 1));
};


/**
 * @param {!Array<!proto.types.DeviceReadingStats>} value
 * @return {!proto.types.DeviceReadingStatsUpdates} returns this
*/
proto.types.DeviceReadingStatsUpdates.prototype.setDeviceReadingStatsUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DeviceReadingStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceReadingStats}
 */
proto.types.DeviceReadingStatsUpdates.prototype.addDeviceReadingStatsUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DeviceReadingStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.DeviceReadingStatsUpdates} returns this
 */
proto.types.DeviceReadingStatsUpdates.prototype.clearDeviceReadingStatsUpdatesList = function() {
  return this.setDeviceReadingStatsUpdatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.DeviceReadingStats.repeatedFields_ = [7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceReadingStats.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceReadingStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceReadingStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReadingStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numTotalReadings: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numAmountReadings: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numThroughputReadings: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hasConsumption: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasIngestion: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    channelsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    latestReadingTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    firstReadingTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    proto.types.MeasurementWithLabel.toObject, includeInstance),
    hasManualReadings: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    maxPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceReadingStats}
 */
proto.types.DeviceReadingStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceReadingStats;
  return proto.types.DeviceReadingStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceReadingStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceReadingStats}
 */
proto.types.DeviceReadingStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumTotalReadings(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumAmountReadings(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumThroughputReadings(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasConsumption(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasIngestion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addChannels(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestReadingTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstReadingTime(value);
      break;
    case 10:
      var value = new proto.types.MeasurementWithLabel;
      reader.readMessage(value,proto.types.MeasurementWithLabel.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasManualReadings(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPower(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceReadingStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceReadingStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceReadingStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReadingStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumTotalReadings();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNumAmountReadings();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNumThroughputReadings();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHasConsumption();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasIngestion();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getLatestReadingTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getFirstReadingTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.types.MeasurementWithLabel.serializeBinaryToWriter
    );
  }
  f = message.getHasManualReadings();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.DeviceReadingStats.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 num_total_readings = 2;
 * @return {number}
 */
proto.types.DeviceReadingStats.prototype.getNumTotalReadings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setNumTotalReadings = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 num_amount_readings = 3;
 * @return {number}
 */
proto.types.DeviceReadingStats.prototype.getNumAmountReadings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setNumAmountReadings = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 num_throughput_readings = 4;
 * @return {number}
 */
proto.types.DeviceReadingStats.prototype.getNumThroughputReadings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setNumThroughputReadings = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool has_consumption = 5;
 * @return {boolean}
 */
proto.types.DeviceReadingStats.prototype.getHasConsumption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setHasConsumption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_ingestion = 6;
 * @return {boolean}
 */
proto.types.DeviceReadingStats.prototype.getHasIngestion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setHasIngestion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string channels = 7;
 * @return {!Array<string>}
 */
proto.types.DeviceReadingStats.prototype.getChannelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setChannelsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.addChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional int64 latest_reading_time = 8;
 * @return {number}
 */
proto.types.DeviceReadingStats.prototype.getLatestReadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setLatestReadingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 first_reading_time = 9;
 * @return {number}
 */
proto.types.DeviceReadingStats.prototype.getFirstReadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setFirstReadingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated MeasurementWithLabel measurements = 10;
 * @return {!Array<!proto.types.MeasurementWithLabel>}
 */
proto.types.DeviceReadingStats.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.types.MeasurementWithLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.MeasurementWithLabel, 10));
};


/**
 * @param {!Array<!proto.types.MeasurementWithLabel>} value
 * @return {!proto.types.DeviceReadingStats} returns this
*/
proto.types.DeviceReadingStats.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.types.MeasurementWithLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.MeasurementWithLabel}
 */
proto.types.DeviceReadingStats.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.types.MeasurementWithLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * optional bool has_manual_readings = 11;
 * @return {boolean}
 */
proto.types.DeviceReadingStats.prototype.getHasManualReadings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setHasManualReadings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional double max_power = 12;
 * @return {number}
 */
proto.types.DeviceReadingStats.prototype.getMaxPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.setMaxPower = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.DeviceReadingStats} returns this
 */
proto.types.DeviceReadingStats.prototype.clearMaxPower = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceReadingStats.prototype.hasMaxPower = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * @enum {number}
 */
proto.types.Measurement = {
  TOTAL: 0,
  THROUGHPUT: 1,
  AMOUNT: 2,
  OTHER: 3
};

/**
 * @enum {number}
 */
proto.types.Direction = {
  IN: 0,
  OUT: 1,
  NETTED: 2
};

/**
 * @enum {number}
 */
proto.types.Unit = {
  KILO_WATT_HOUR: 0,
  WATT: 1,
  CUBIC_METRE: 2,
  KILO_GRAMM: 3,
  LITERS_PER_MINUTE: 4,
  KILO_GRAMM_CO2: 5,
  EURO: 6,
  EURO_PER_KILO_WATT_HOUR: 7,
  PERCENT: 8,
  CENT_PER_KWH: 9,
  KILO_WATT: 10,
  KILO_WATT_HOURS_PER_SQUARE_METER: 11,
  KILO_WATT_HOURS_PER_EMPLOYEE: 12,
  WATT_PER_SQUARE_METER: 13,
  WATT_PER_EMPLOYEE: 14
};

/**
 * @enum {number}
 */
proto.types.TariffType = {
  FIXED: 0
};

goog.object.extend(exports, proto.types);
