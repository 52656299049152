import { render, staticRenderFns } from "./Automations.vue?vue&type=template&id=51e9b683&scoped=true"
import script from "./Automations.vue?vue&type=script&lang=ts"
export * from "./Automations.vue?vue&type=script&lang=ts"
import style0 from "./Automations.vue?vue&type=style&index=0&id=51e9b683&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e9b683",
  null
  
)

export default component.exports