
import { Component, Vue } from "vue-property-decorator";
import CurrentMonth from "./CurrentMonth.vue";

import { GetCurrentMonthWidgetDataRequest } from "zaehlerfreunde-central/ui_service_pb";

@Component({
  components: {
    CurrentMonth,
  },
})
export default class CurrentMonthConsumption extends Vue {
  CurrentMonthDataType = GetCurrentMonthWidgetDataRequest.DataType;
  selectedDeviceId: string = "";
}
