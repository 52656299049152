
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import {
  GetAutomationDispatchedActionsRequest,
  GetDispatchedActionsRequest,
  RetryDispatchedActionRequest,
} from "zaehlerfreunde-central/advisory_service_pb";
import { DispatchedAction, DispatchedActionStatus } from "zaehlerfreunde-proto-types/automations_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";
import { advisoryServiceClient } from "@/config/service-clients";
import moment from "moment";

export interface DispatchedActionRow {
  id: string;
  automationId: string;
  automationName: string;
  dispatchedTime: string;
  status: string;
  isFailed: boolean;
  actionDescription: string;
}

@Component({
  components: {},
})
export default class DispatchedActionsTable extends Vue {
  @Prop() selectedSpaceId: string;
  @Prop({ default: false }) hidePagination: boolean;
  @Prop({ default: 10 }) itemsPerPage: number;

  @Prop() selectedAutomationId: string;
  @Prop({ default: false }) isDashboard: boolean;

  dispatchedActions: RemoteData<UserError, DispatchedAction[]> = initialized;
  totalNumPages: RemoteData<UserError, number> = initialized;
  retryActionCall: RemoteCall<UserError> = initialized;

  page: number = 1;

  headers: { text: string; value: string; align?: string; sortable?: boolean }[] = [
    { text: "Name", value: "automationName", sortable: false },
    { text: "Ausgelöste Zeit", value: "dispatchedTime", sortable: false },
    { text: "Status", value: "status", sortable: false },
    { text: "Beschreibung", value: "actionDescription", sortable: false },
    { text: "", value: "controls", align: "right", sortable: false },
  ];

  @Watch("registerDeviceCall")
  onRetryActionCall(): void {
    if (this.retryActionCall.succeeded) {
      this.loadData();
    }
  }

  @Watch("page")
  onPageChanged(): void {
    this.loadData();
  }

  @Watch("selectedAutomationId")
  onAutomationChanged(): void {
    this.loadData();
  }

  statusIcon(actionStatus: DispatchedActionStatus): string {
    switch (actionStatus) {
      case DispatchedActionStatus.PENDING:
        return "mdi-timer-sand-empty";
      case DispatchedActionStatus.DONE:
        return "mdi-check";
      case DispatchedActionStatus.FAILED:
        return "mdi-alert";
    }
  }

  get dispatchedActionRows(): DispatchedActionRow[] {
    return this.dispatchedActions.list.map((d: DispatchedAction) => {
      const dispatchedAt = moment.unix(d.getDispatchedTime()).locale("de").fromNow();

      return {
        id: d.getId(),
        automationId: d.getAutomationId(),
        automationName: d.getAutomationName(),
        dispatchedTime: dispatchedAt,
        status: this.statusIcon(d.getStatus()),
        isFailed: d.getStatus() === DispatchedActionStatus.FAILED,
        actionDescription: d.getActionDescription(),
      };
    });
  }

  mounted(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.dispatchedActions = pending;

    if (this.selectedSpaceId) {
      const request = new GetDispatchedActionsRequest();
      request.setSpaceId(this.selectedSpaceId);
      request.setPagination(new Pagination().setPage(this.page - 1).setPageSize(this.itemsPerPage));

      try {
        const response = await advisoryServiceClient.getDispatchedActions(request, {});
        this.dispatchedActions = success(response.getDispatchedActionsList());
        this.totalNumPages = success(response.getTotalNumPages());
      } catch (error) {
        this.dispatchedActions = failure(userErrorFrom(error));
      }
    } else if (this.selectedAutomationId) {
      const request = new GetAutomationDispatchedActionsRequest();
      request.setAutomationId(this.selectedAutomationId);
      request.setPagination(new Pagination().setPage(this.page - 1).setPageSize(10));

      try {
        const response = await advisoryServiceClient.getAutomationDispatchedActions(request, {});
        this.dispatchedActions = success(response.getDispatchedActionsList());
        this.totalNumPages = success(response.getTotalNumPages());
      } catch (error) {
        this.dispatchedActions = failure(userErrorFrom(error));
      }
    }
  }

  async onRetryActionClicked(action: DispatchedActionRow): Promise<void> {
    const request = new RetryDispatchedActionRequest();
    request.setDispatchedActionId(action.id);
    try {
      await advisoryServiceClient.retryDispatchedAction(request, {});
      this.retryActionCall = success(void 0);
    } catch (error) {
      this.retryActionCall = failure(userErrorFrom(error));
    }
  }
}
