// source: types/automations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_reading_pb = require('../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
var types_spot_price_pb = require('../types/spot_price_pb.js');
goog.object.extend(proto, types_spot_price_pb);
goog.exportSymbol('proto.types.Action', null, global);
goog.exportSymbol('proto.types.Action.ActionCase', null, global);
goog.exportSymbol('proto.types.AmountOverDuration', null, global);
goog.exportSymbol('proto.types.AmountOverDuration.Amount', null, global);
goog.exportSymbol('proto.types.AmountOverDuration.Total', null, global);
goog.exportSymbol('proto.types.Automation', null, global);
goog.exportSymbol('proto.types.AutomationDescription', null, global);
goog.exportSymbol('proto.types.ComparisonOperator', null, global);
goog.exportSymbol('proto.types.DeviceAction', null, global);
goog.exportSymbol('proto.types.DeviceActionType', null, global);
goog.exportSymbol('proto.types.DeviceReadingTrigger', null, global);
goog.exportSymbol('proto.types.DispatchedAction', null, global);
goog.exportSymbol('proto.types.DispatchedActionStatus', null, global);
goog.exportSymbol('proto.types.NotificationAction', null, global);
goog.exportSymbol('proto.types.NotificationAction.PushNotification', null, global);
goog.exportSymbol('proto.types.PriceTrigger', null, global);
goog.exportSymbol('proto.types.StateOfChargeTrigger', null, global);
goog.exportSymbol('proto.types.Trigger', null, global);
goog.exportSymbol('proto.types.Trigger.TypeCase', null, global);
goog.exportSymbol('proto.types.TriggerResult', null, global);
goog.exportSymbol('proto.types.WallboxAction', null, global);
goog.exportSymbol('proto.types.WallboxActionType', null, global);
goog.exportSymbol('proto.types.WallboxStatus', null, global);
goog.exportSymbol('proto.types.WallboxStatusTrigger', null, global);
goog.exportSymbol('proto.types.WallboxStatusUpdate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Automation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Automation.repeatedFields_, null);
};
goog.inherits(proto.types.Automation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Automation.displayName = 'proto.types.Automation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.Action.oneofGroups_);
};
goog.inherits(proto.types.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Action.displayName = 'proto.types.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceAction.displayName = 'proto.types.DeviceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.WallboxAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.WallboxAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.WallboxAction.displayName = 'proto.types.WallboxAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.NotificationAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.NotificationAction.repeatedFields_, null);
};
goog.inherits(proto.types.NotificationAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.NotificationAction.displayName = 'proto.types.NotificationAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.NotificationAction.PushNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.NotificationAction.PushNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.NotificationAction.PushNotification.displayName = 'proto.types.NotificationAction.PushNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Trigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.Trigger.oneofGroups_);
};
goog.inherits(proto.types.Trigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Trigger.displayName = 'proto.types.Trigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TriggerResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TriggerResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TriggerResult.displayName = 'proto.types.TriggerResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceReadingTrigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceReadingTrigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceReadingTrigger.displayName = 'proto.types.DeviceReadingTrigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.PriceTrigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.PriceTrigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.PriceTrigger.displayName = 'proto.types.PriceTrigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.WallboxStatusTrigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.WallboxStatusTrigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.WallboxStatusTrigger.displayName = 'proto.types.WallboxStatusTrigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.StateOfChargeTrigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.StateOfChargeTrigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.StateOfChargeTrigger.displayName = 'proto.types.StateOfChargeTrigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.WallboxStatusUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.WallboxStatusUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.WallboxStatusUpdate.displayName = 'proto.types.WallboxStatusUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.AutomationDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.AutomationDescription.repeatedFields_, null);
};
goog.inherits(proto.types.AutomationDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.AutomationDescription.displayName = 'proto.types.AutomationDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DispatchedAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DispatchedAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DispatchedAction.displayName = 'proto.types.DispatchedAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.AmountOverDuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.AmountOverDuration.repeatedFields_, null);
};
goog.inherits(proto.types.AmountOverDuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.AmountOverDuration.displayName = 'proto.types.AmountOverDuration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.AmountOverDuration.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.AmountOverDuration.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.AmountOverDuration.Amount.displayName = 'proto.types.AmountOverDuration.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.AmountOverDuration.Total = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.AmountOverDuration.Total, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.AmountOverDuration.Total.displayName = 'proto.types.AmountOverDuration.Total';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Automation.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Automation.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Automation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Automation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Automation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    triggersList: jspb.Message.toObjectList(msg.getTriggersList(),
    proto.types.Trigger.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.types.Action.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Automation}
 */
proto.types.Automation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Automation;
  return proto.types.Automation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Automation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Automation}
 */
proto.types.Automation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.types.Trigger;
      reader.readMessage(value,proto.types.Trigger.deserializeBinaryFromReader);
      msg.addTriggers(value);
      break;
    case 5:
      var value = new proto.types.Action;
      reader.readMessage(value,proto.types.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Automation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Automation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Automation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Automation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTriggersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.types.Trigger.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.types.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Automation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Automation} returns this
 */
proto.types.Automation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.types.Automation.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Automation} returns this
 */
proto.types.Automation.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.types.Automation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Automation} returns this
 */
proto.types.Automation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Trigger triggers = 4;
 * @return {!Array<!proto.types.Trigger>}
 */
proto.types.Automation.prototype.getTriggersList = function() {
  return /** @type{!Array<!proto.types.Trigger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Trigger, 4));
};


/**
 * @param {!Array<!proto.types.Trigger>} value
 * @return {!proto.types.Automation} returns this
*/
proto.types.Automation.prototype.setTriggersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.types.Trigger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Trigger}
 */
proto.types.Automation.prototype.addTriggers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.types.Trigger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Automation} returns this
 */
proto.types.Automation.prototype.clearTriggersList = function() {
  return this.setTriggersList([]);
};


/**
 * repeated Action actions = 5;
 * @return {!Array<!proto.types.Action>}
 */
proto.types.Automation.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.types.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Action, 5));
};


/**
 * @param {!Array<!proto.types.Action>} value
 * @return {!proto.types.Automation} returns this
*/
proto.types.Automation.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.types.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Action}
 */
proto.types.Automation.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.types.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Automation} returns this
 */
proto.types.Automation.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.Action.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.types.Action.ActionCase = {
  ACTION_NOT_SET: 0,
  DEVICE_ACTION: 3,
  WALLBOX_ACTION: 4,
  NOTIFICATION_ACTION: 5
};

/**
 * @return {proto.types.Action.ActionCase}
 */
proto.types.Action.prototype.getActionCase = function() {
  return /** @type {proto.types.Action.ActionCase} */(jspb.Message.computeOneofCase(this, proto.types.Action.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    automationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceAction: (f = msg.getDeviceAction()) && proto.types.DeviceAction.toObject(includeInstance, f),
    wallboxAction: (f = msg.getWallboxAction()) && proto.types.WallboxAction.toObject(includeInstance, f),
    notificationAction: (f = msg.getNotificationAction()) && proto.types.NotificationAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Action}
 */
proto.types.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Action;
  return proto.types.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Action}
 */
proto.types.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    case 3:
      var value = new proto.types.DeviceAction;
      reader.readMessage(value,proto.types.DeviceAction.deserializeBinaryFromReader);
      msg.setDeviceAction(value);
      break;
    case 4:
      var value = new proto.types.WallboxAction;
      reader.readMessage(value,proto.types.WallboxAction.deserializeBinaryFromReader);
      msg.setWallboxAction(value);
      break;
    case 5:
      var value = new proto.types.NotificationAction;
      reader.readMessage(value,proto.types.NotificationAction.deserializeBinaryFromReader);
      msg.setNotificationAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceAction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.DeviceAction.serializeBinaryToWriter
    );
  }
  f = message.getWallboxAction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.types.WallboxAction.serializeBinaryToWriter
    );
  }
  f = message.getNotificationAction();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.types.NotificationAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Action.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Action} returns this
 */
proto.types.Action.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string automation_id = 2;
 * @return {string}
 */
proto.types.Action.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Action} returns this
 */
proto.types.Action.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceAction device_action = 3;
 * @return {?proto.types.DeviceAction}
 */
proto.types.Action.prototype.getDeviceAction = function() {
  return /** @type{?proto.types.DeviceAction} */ (
    jspb.Message.getWrapperField(this, proto.types.DeviceAction, 3));
};


/**
 * @param {?proto.types.DeviceAction|undefined} value
 * @return {!proto.types.Action} returns this
*/
proto.types.Action.prototype.setDeviceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.types.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Action} returns this
 */
proto.types.Action.prototype.clearDeviceAction = function() {
  return this.setDeviceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Action.prototype.hasDeviceAction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WallboxAction wallbox_action = 4;
 * @return {?proto.types.WallboxAction}
 */
proto.types.Action.prototype.getWallboxAction = function() {
  return /** @type{?proto.types.WallboxAction} */ (
    jspb.Message.getWrapperField(this, proto.types.WallboxAction, 4));
};


/**
 * @param {?proto.types.WallboxAction|undefined} value
 * @return {!proto.types.Action} returns this
*/
proto.types.Action.prototype.setWallboxAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.types.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Action} returns this
 */
proto.types.Action.prototype.clearWallboxAction = function() {
  return this.setWallboxAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Action.prototype.hasWallboxAction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NotificationAction notification_action = 5;
 * @return {?proto.types.NotificationAction}
 */
proto.types.Action.prototype.getNotificationAction = function() {
  return /** @type{?proto.types.NotificationAction} */ (
    jspb.Message.getWrapperField(this, proto.types.NotificationAction, 5));
};


/**
 * @param {?proto.types.NotificationAction|undefined} value
 * @return {!proto.types.Action} returns this
*/
proto.types.Action.prototype.setNotificationAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.types.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Action} returns this
 */
proto.types.Action.prototype.clearNotificationAction = function() {
  return this.setNotificationAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Action.prototype.hasNotificationAction = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceAction}
 */
proto.types.DeviceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceAction;
  return proto.types.DeviceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceAction}
 */
proto.types.DeviceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.DeviceActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.DeviceAction.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceAction} returns this
 */
proto.types.DeviceAction.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceActionType action_type = 2;
 * @return {!proto.types.DeviceActionType}
 */
proto.types.DeviceAction.prototype.getActionType = function() {
  return /** @type {!proto.types.DeviceActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.DeviceActionType} value
 * @return {!proto.types.DeviceAction} returns this
 */
proto.types.DeviceAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.WallboxAction.prototype.toObject = function(opt_includeInstance) {
  return proto.types.WallboxAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.WallboxAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WallboxAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.WallboxAction}
 */
proto.types.WallboxAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.WallboxAction;
  return proto.types.WallboxAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.WallboxAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.WallboxAction}
 */
proto.types.WallboxAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.WallboxActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.WallboxAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.WallboxAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.WallboxAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WallboxAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.WallboxAction.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WallboxAction} returns this
 */
proto.types.WallboxAction.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WallboxActionType action_type = 2;
 * @return {!proto.types.WallboxActionType}
 */
proto.types.WallboxAction.prototype.getActionType = function() {
  return /** @type {!proto.types.WallboxActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.WallboxActionType} value
 * @return {!proto.types.WallboxAction} returns this
 */
proto.types.WallboxAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.NotificationAction.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.NotificationAction.prototype.toObject = function(opt_includeInstance) {
  return proto.types.NotificationAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.NotificationAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NotificationAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    phoneNumbersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    pushNotification: (f = msg.getPushNotification()) && proto.types.NotificationAction.PushNotification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.NotificationAction}
 */
proto.types.NotificationAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.NotificationAction;
  return proto.types.NotificationAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.NotificationAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.NotificationAction}
 */
proto.types.NotificationAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPhoneNumbers(value);
      break;
    case 3:
      var value = new proto.types.NotificationAction.PushNotification;
      reader.readMessage(value,proto.types.NotificationAction.PushNotification.deserializeBinaryFromReader);
      msg.setPushNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.NotificationAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.NotificationAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.NotificationAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NotificationAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPhoneNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getPushNotification();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.NotificationAction.PushNotification.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.NotificationAction.PushNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.types.NotificationAction.PushNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.NotificationAction.PushNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NotificationAction.PushNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.NotificationAction.PushNotification}
 */
proto.types.NotificationAction.PushNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.NotificationAction.PushNotification;
  return proto.types.NotificationAction.PushNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.NotificationAction.PushNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.NotificationAction.PushNotification}
 */
proto.types.NotificationAction.PushNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.NotificationAction.PushNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.NotificationAction.PushNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.NotificationAction.PushNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.NotificationAction.PushNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.types.NotificationAction.PushNotification.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.NotificationAction.PushNotification} returns this
 */
proto.types.NotificationAction.PushNotification.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string emails = 1;
 * @return {!Array<string>}
 */
proto.types.NotificationAction.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated string phone_numbers = 2;
 * @return {!Array<string>}
 */
proto.types.NotificationAction.prototype.getPhoneNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.setPhoneNumbersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.addPhoneNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.clearPhoneNumbersList = function() {
  return this.setPhoneNumbersList([]);
};


/**
 * optional PushNotification push_notification = 3;
 * @return {?proto.types.NotificationAction.PushNotification}
 */
proto.types.NotificationAction.prototype.getPushNotification = function() {
  return /** @type{?proto.types.NotificationAction.PushNotification} */ (
    jspb.Message.getWrapperField(this, proto.types.NotificationAction.PushNotification, 3));
};


/**
 * @param {?proto.types.NotificationAction.PushNotification|undefined} value
 * @return {!proto.types.NotificationAction} returns this
*/
proto.types.NotificationAction.prototype.setPushNotification = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.NotificationAction} returns this
 */
proto.types.NotificationAction.prototype.clearPushNotification = function() {
  return this.setPushNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.NotificationAction.prototype.hasPushNotification = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.Trigger.oneofGroups_ = [[3,4,6,7]];

/**
 * @enum {number}
 */
proto.types.Trigger.TypeCase = {
  TYPE_NOT_SET: 0,
  READING_TRIGGER: 3,
  PRICE_TRIGGER: 4,
  WALLBOX_STATUS_TRIGGER: 6,
  STATE_OF_CHARGE_TRIGGER: 7
};

/**
 * @return {proto.types.Trigger.TypeCase}
 */
proto.types.Trigger.prototype.getTypeCase = function() {
  return /** @type {proto.types.Trigger.TypeCase} */(jspb.Message.computeOneofCase(this, proto.types.Trigger.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Trigger.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Trigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Trigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Trigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    automationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minInterval: jspb.Message.getFieldWithDefault(msg, 5, 0),
    readingTrigger: (f = msg.getReadingTrigger()) && proto.types.DeviceReadingTrigger.toObject(includeInstance, f),
    priceTrigger: (f = msg.getPriceTrigger()) && proto.types.PriceTrigger.toObject(includeInstance, f),
    wallboxStatusTrigger: (f = msg.getWallboxStatusTrigger()) && proto.types.WallboxStatusTrigger.toObject(includeInstance, f),
    stateOfChargeTrigger: (f = msg.getStateOfChargeTrigger()) && proto.types.StateOfChargeTrigger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Trigger}
 */
proto.types.Trigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Trigger;
  return proto.types.Trigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Trigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Trigger}
 */
proto.types.Trigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinInterval(value);
      break;
    case 3:
      var value = new proto.types.DeviceReadingTrigger;
      reader.readMessage(value,proto.types.DeviceReadingTrigger.deserializeBinaryFromReader);
      msg.setReadingTrigger(value);
      break;
    case 4:
      var value = new proto.types.PriceTrigger;
      reader.readMessage(value,proto.types.PriceTrigger.deserializeBinaryFromReader);
      msg.setPriceTrigger(value);
      break;
    case 6:
      var value = new proto.types.WallboxStatusTrigger;
      reader.readMessage(value,proto.types.WallboxStatusTrigger.deserializeBinaryFromReader);
      msg.setWallboxStatusTrigger(value);
      break;
    case 7:
      var value = new proto.types.StateOfChargeTrigger;
      reader.readMessage(value,proto.types.StateOfChargeTrigger.deserializeBinaryFromReader);
      msg.setStateOfChargeTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Trigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Trigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Trigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Trigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinInterval();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getReadingTrigger();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.DeviceReadingTrigger.serializeBinaryToWriter
    );
  }
  f = message.getPriceTrigger();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.types.PriceTrigger.serializeBinaryToWriter
    );
  }
  f = message.getWallboxStatusTrigger();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.types.WallboxStatusTrigger.serializeBinaryToWriter
    );
  }
  f = message.getStateOfChargeTrigger();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.types.StateOfChargeTrigger.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Trigger.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string automation_id = 2;
 * @return {string}
 */
proto.types.Trigger.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 min_interval = 5;
 * @return {number}
 */
proto.types.Trigger.prototype.getMinInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.setMinInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional DeviceReadingTrigger reading_trigger = 3;
 * @return {?proto.types.DeviceReadingTrigger}
 */
proto.types.Trigger.prototype.getReadingTrigger = function() {
  return /** @type{?proto.types.DeviceReadingTrigger} */ (
    jspb.Message.getWrapperField(this, proto.types.DeviceReadingTrigger, 3));
};


/**
 * @param {?proto.types.DeviceReadingTrigger|undefined} value
 * @return {!proto.types.Trigger} returns this
*/
proto.types.Trigger.prototype.setReadingTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.types.Trigger.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.clearReadingTrigger = function() {
  return this.setReadingTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Trigger.prototype.hasReadingTrigger = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PriceTrigger price_trigger = 4;
 * @return {?proto.types.PriceTrigger}
 */
proto.types.Trigger.prototype.getPriceTrigger = function() {
  return /** @type{?proto.types.PriceTrigger} */ (
    jspb.Message.getWrapperField(this, proto.types.PriceTrigger, 4));
};


/**
 * @param {?proto.types.PriceTrigger|undefined} value
 * @return {!proto.types.Trigger} returns this
*/
proto.types.Trigger.prototype.setPriceTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.types.Trigger.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.clearPriceTrigger = function() {
  return this.setPriceTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Trigger.prototype.hasPriceTrigger = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WallboxStatusTrigger wallbox_status_trigger = 6;
 * @return {?proto.types.WallboxStatusTrigger}
 */
proto.types.Trigger.prototype.getWallboxStatusTrigger = function() {
  return /** @type{?proto.types.WallboxStatusTrigger} */ (
    jspb.Message.getWrapperField(this, proto.types.WallboxStatusTrigger, 6));
};


/**
 * @param {?proto.types.WallboxStatusTrigger|undefined} value
 * @return {!proto.types.Trigger} returns this
*/
proto.types.Trigger.prototype.setWallboxStatusTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.types.Trigger.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.clearWallboxStatusTrigger = function() {
  return this.setWallboxStatusTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Trigger.prototype.hasWallboxStatusTrigger = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StateOfChargeTrigger state_of_charge_trigger = 7;
 * @return {?proto.types.StateOfChargeTrigger}
 */
proto.types.Trigger.prototype.getStateOfChargeTrigger = function() {
  return /** @type{?proto.types.StateOfChargeTrigger} */ (
    jspb.Message.getWrapperField(this, proto.types.StateOfChargeTrigger, 7));
};


/**
 * @param {?proto.types.StateOfChargeTrigger|undefined} value
 * @return {!proto.types.Trigger} returns this
*/
proto.types.Trigger.prototype.setStateOfChargeTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.types.Trigger.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Trigger} returns this
 */
proto.types.Trigger.prototype.clearStateOfChargeTrigger = function() {
  return this.setStateOfChargeTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Trigger.prototype.hasStateOfChargeTrigger = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TriggerResult.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TriggerResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TriggerResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TriggerResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    trigger: (f = msg.getTrigger()) && proto.types.Trigger.toObject(includeInstance, f),
    result: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TriggerResult}
 */
proto.types.TriggerResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TriggerResult;
  return proto.types.TriggerResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TriggerResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TriggerResult}
 */
proto.types.TriggerResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.Trigger;
      reader.readMessage(value,proto.types.Trigger.deserializeBinaryFromReader);
      msg.setTrigger(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TriggerResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TriggerResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TriggerResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TriggerResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrigger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.types.Trigger.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Trigger trigger = 1;
 * @return {?proto.types.Trigger}
 */
proto.types.TriggerResult.prototype.getTrigger = function() {
  return /** @type{?proto.types.Trigger} */ (
    jspb.Message.getWrapperField(this, proto.types.Trigger, 1));
};


/**
 * @param {?proto.types.Trigger|undefined} value
 * @return {!proto.types.TriggerResult} returns this
*/
proto.types.TriggerResult.prototype.setTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TriggerResult} returns this
 */
proto.types.TriggerResult.prototype.clearTrigger = function() {
  return this.setTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TriggerResult.prototype.hasTrigger = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool result = 2;
 * @return {boolean}
 */
proto.types.TriggerResult.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.TriggerResult} returns this
 */
proto.types.TriggerResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceReadingTrigger.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceReadingTrigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceReadingTrigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReadingTrigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    measurement: jspb.Message.getFieldWithDefault(msg, 2, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 3, 0),
    operator: jspb.Message.getFieldWithDefault(msg, 4, 0),
    comparand: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    showInChart: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    duration: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceReadingTrigger}
 */
proto.types.DeviceReadingTrigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceReadingTrigger;
  return proto.types.DeviceReadingTrigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceReadingTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceReadingTrigger}
 */
proto.types.DeviceReadingTrigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Measurement} */ (reader.readEnum());
      msg.setMeasurement(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 4:
      var value = /** @type {!proto.types.ComparisonOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setComparand(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowInChart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceReadingTrigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceReadingTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceReadingTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceReadingTrigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeasurement();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getComparand();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getShowInChart();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.DeviceReadingTrigger.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Measurement measurement = 2;
 * @return {!proto.types.Measurement}
 */
proto.types.DeviceReadingTrigger.prototype.getMeasurement = function() {
  return /** @type {!proto.types.Measurement} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Measurement} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setMeasurement = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Direction direction = 3;
 * @return {!proto.types.Direction}
 */
proto.types.DeviceReadingTrigger.prototype.getDirection = function() {
  return /** @type {!proto.types.Direction} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Direction} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ComparisonOperator operator = 4;
 * @return {!proto.types.ComparisonOperator}
 */
proto.types.DeviceReadingTrigger.prototype.getOperator = function() {
  return /** @type {!proto.types.ComparisonOperator} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.ComparisonOperator} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional double comparand = 5;
 * @return {number}
 */
proto.types.DeviceReadingTrigger.prototype.getComparand = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setComparand = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool show_in_chart = 6;
 * @return {boolean}
 */
proto.types.DeviceReadingTrigger.prototype.getShowInChart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setShowInChart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 duration = 7;
 * @return {number}
 */
proto.types.DeviceReadingTrigger.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.setDuration = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.DeviceReadingTrigger} returns this
 */
proto.types.DeviceReadingTrigger.prototype.clearDuration = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceReadingTrigger.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.PriceTrigger.prototype.toObject = function(opt_includeInstance) {
  return proto.types.PriceTrigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.PriceTrigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PriceTrigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    exchange: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operator: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.PriceTrigger}
 */
proto.types.PriceTrigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.PriceTrigger;
  return proto.types.PriceTrigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.PriceTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.PriceTrigger}
 */
proto.types.PriceTrigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.EnergySpotPrice.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.types.EnergySpotPrice.Exchange} */ (reader.readEnum());
      msg.setExchange(value);
      break;
    case 3:
      var value = /** @type {!proto.types.ComparisonOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerKwh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.PriceTrigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.PriceTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.PriceTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.PriceTrigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExchange();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPricePerKwh();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional EnergySpotPrice.Type type = 1;
 * @return {!proto.types.EnergySpotPrice.Type}
 */
proto.types.PriceTrigger.prototype.getType = function() {
  return /** @type {!proto.types.EnergySpotPrice.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.EnergySpotPrice.Type} value
 * @return {!proto.types.PriceTrigger} returns this
 */
proto.types.PriceTrigger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional EnergySpotPrice.Exchange exchange = 2;
 * @return {!proto.types.EnergySpotPrice.Exchange}
 */
proto.types.PriceTrigger.prototype.getExchange = function() {
  return /** @type {!proto.types.EnergySpotPrice.Exchange} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.EnergySpotPrice.Exchange} value
 * @return {!proto.types.PriceTrigger} returns this
 */
proto.types.PriceTrigger.prototype.setExchange = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ComparisonOperator operator = 3;
 * @return {!proto.types.ComparisonOperator}
 */
proto.types.PriceTrigger.prototype.getOperator = function() {
  return /** @type {!proto.types.ComparisonOperator} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.ComparisonOperator} value
 * @return {!proto.types.PriceTrigger} returns this
 */
proto.types.PriceTrigger.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double price_per_kwh = 4;
 * @return {number}
 */
proto.types.PriceTrigger.prototype.getPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.PriceTrigger} returns this
 */
proto.types.PriceTrigger.prototype.setPricePerKwh = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.WallboxStatusTrigger.prototype.toObject = function(opt_includeInstance) {
  return proto.types.WallboxStatusTrigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.WallboxStatusTrigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WallboxStatusTrigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.WallboxStatusTrigger}
 */
proto.types.WallboxStatusTrigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.WallboxStatusTrigger;
  return proto.types.WallboxStatusTrigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.WallboxStatusTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.WallboxStatusTrigger}
 */
proto.types.WallboxStatusTrigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.WallboxStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.WallboxStatusTrigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.WallboxStatusTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.WallboxStatusTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WallboxStatusTrigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.WallboxStatusTrigger.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WallboxStatusTrigger} returns this
 */
proto.types.WallboxStatusTrigger.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WallboxStatus status = 2;
 * @return {!proto.types.WallboxStatus}
 */
proto.types.WallboxStatusTrigger.prototype.getStatus = function() {
  return /** @type {!proto.types.WallboxStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.WallboxStatus} value
 * @return {!proto.types.WallboxStatusTrigger} returns this
 */
proto.types.WallboxStatusTrigger.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.StateOfChargeTrigger.prototype.toObject = function(opt_includeInstance) {
  return proto.types.StateOfChargeTrigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.StateOfChargeTrigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.StateOfChargeTrigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stateOfCharge: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.StateOfChargeTrigger}
 */
proto.types.StateOfChargeTrigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.StateOfChargeTrigger;
  return proto.types.StateOfChargeTrigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.StateOfChargeTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.StateOfChargeTrigger}
 */
proto.types.StateOfChargeTrigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStateOfCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.StateOfChargeTrigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.StateOfChargeTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.StateOfChargeTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.StateOfChargeTrigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateOfCharge();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.StateOfChargeTrigger.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.StateOfChargeTrigger} returns this
 */
proto.types.StateOfChargeTrigger.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double state_of_charge = 2;
 * @return {number}
 */
proto.types.StateOfChargeTrigger.prototype.getStateOfCharge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.StateOfChargeTrigger} returns this
 */
proto.types.StateOfChargeTrigger.prototype.setStateOfCharge = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.WallboxStatusUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.types.WallboxStatusUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.WallboxStatusUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WallboxStatusUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.WallboxStatusUpdate}
 */
proto.types.WallboxStatusUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.WallboxStatusUpdate;
  return proto.types.WallboxStatusUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.WallboxStatusUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.WallboxStatusUpdate}
 */
proto.types.WallboxStatusUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.WallboxStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.WallboxStatusUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.WallboxStatusUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.WallboxStatusUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WallboxStatusUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.types.WallboxStatusUpdate.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WallboxStatusUpdate} returns this
 */
proto.types.WallboxStatusUpdate.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WallboxStatus status = 2;
 * @return {!proto.types.WallboxStatus}
 */
proto.types.WallboxStatusUpdate.prototype.getStatus = function() {
  return /** @type {!proto.types.WallboxStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.WallboxStatus} value
 * @return {!proto.types.WallboxStatusUpdate} returns this
 */
proto.types.WallboxStatusUpdate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.AutomationDescription.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.AutomationDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.types.AutomationDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.AutomationDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AutomationDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionIconsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    triggerIconsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.AutomationDescription}
 */
proto.types.AutomationDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.AutomationDescription;
  return proto.types.AutomationDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.AutomationDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.AutomationDescription}
 */
proto.types.AutomationDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addActionIcons(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTriggerIcons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.AutomationDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.AutomationDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.AutomationDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AutomationDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionIconsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getTriggerIconsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string automation_id = 1;
 * @return {string}
 */
proto.types.AutomationDescription.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.AutomationDescription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.types.AutomationDescription.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string action_icons = 4;
 * @return {!Array<string>}
 */
proto.types.AutomationDescription.prototype.getActionIconsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.setActionIconsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.addActionIcons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.clearActionIconsList = function() {
  return this.setActionIconsList([]);
};


/**
 * repeated string trigger_icons = 5;
 * @return {!Array<string>}
 */
proto.types.AutomationDescription.prototype.getTriggerIconsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.setTriggerIconsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.addTriggerIcons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.AutomationDescription} returns this
 */
proto.types.AutomationDescription.prototype.clearTriggerIconsList = function() {
  return this.setTriggerIconsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DispatchedAction.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DispatchedAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DispatchedAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DispatchedAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    automationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    action: (f = msg.getAction()) && proto.types.Action.toObject(includeInstance, f),
    actionDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    automationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dispatchedTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DispatchedAction}
 */
proto.types.DispatchedAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DispatchedAction;
  return proto.types.DispatchedAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DispatchedAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DispatchedAction}
 */
proto.types.DispatchedAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    case 3:
      var value = new proto.types.Action;
      reader.readMessage(value,proto.types.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDispatchedTime(value);
      break;
    case 7:
      var value = /** @type {!proto.types.DispatchedActionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DispatchedAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DispatchedAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DispatchedAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DispatchedAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.Action.serializeBinaryToWriter
    );
  }
  f = message.getActionDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAutomationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDispatchedTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.DispatchedAction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string automation_id = 2;
 * @return {string}
 */
proto.types.DispatchedAction.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Action action = 3;
 * @return {?proto.types.Action}
 */
proto.types.DispatchedAction.prototype.getAction = function() {
  return /** @type{?proto.types.Action} */ (
    jspb.Message.getWrapperField(this, proto.types.Action, 3));
};


/**
 * @param {?proto.types.Action|undefined} value
 * @return {!proto.types.DispatchedAction} returns this
*/
proto.types.DispatchedAction.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DispatchedAction.prototype.hasAction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string action_description = 4;
 * @return {string}
 */
proto.types.DispatchedAction.prototype.getActionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.setActionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string automation_name = 5;
 * @return {string}
 */
proto.types.DispatchedAction.prototype.getAutomationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.setAutomationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 dispatched_time = 6;
 * @return {number}
 */
proto.types.DispatchedAction.prototype.getDispatchedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.setDispatchedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DispatchedActionStatus status = 7;
 * @return {!proto.types.DispatchedActionStatus}
 */
proto.types.DispatchedAction.prototype.getStatus = function() {
  return /** @type {!proto.types.DispatchedActionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.types.DispatchedActionStatus} value
 * @return {!proto.types.DispatchedAction} returns this
 */
proto.types.DispatchedAction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.AmountOverDuration.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.AmountOverDuration.prototype.toObject = function(opt_includeInstance) {
  return proto.types.AmountOverDuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.AmountOverDuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AmountOverDuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountsList: jspb.Message.toObjectList(msg.getAmountsList(),
    proto.types.AmountOverDuration.Amount.toObject, includeInstance),
    totalsList: jspb.Message.toObjectList(msg.getTotalsList(),
    proto.types.AmountOverDuration.Total.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.AmountOverDuration}
 */
proto.types.AmountOverDuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.AmountOverDuration;
  return proto.types.AmountOverDuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.AmountOverDuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.AmountOverDuration}
 */
proto.types.AmountOverDuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.AmountOverDuration.Amount;
      reader.readMessage(value,proto.types.AmountOverDuration.Amount.deserializeBinaryFromReader);
      msg.addAmounts(value);
      break;
    case 2:
      var value = new proto.types.AmountOverDuration.Total;
      reader.readMessage(value,proto.types.AmountOverDuration.Total.deserializeBinaryFromReader);
      msg.addTotals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.AmountOverDuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.AmountOverDuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.AmountOverDuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AmountOverDuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.AmountOverDuration.Amount.serializeBinaryToWriter
    );
  }
  f = message.getTotalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.types.AmountOverDuration.Total.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.AmountOverDuration.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.types.AmountOverDuration.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.AmountOverDuration.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AmountOverDuration.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.AmountOverDuration.Amount}
 */
proto.types.AmountOverDuration.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.AmountOverDuration.Amount;
  return proto.types.AmountOverDuration.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.AmountOverDuration.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.AmountOverDuration.Amount}
 */
proto.types.AmountOverDuration.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.AmountOverDuration.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.AmountOverDuration.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.AmountOverDuration.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AmountOverDuration.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.types.AmountOverDuration.Amount.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.AmountOverDuration.Amount} returns this
 */
proto.types.AmountOverDuration.Amount.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 duration = 2;
 * @return {number}
 */
proto.types.AmountOverDuration.Amount.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.AmountOverDuration.Amount} returns this
 */
proto.types.AmountOverDuration.Amount.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.types.AmountOverDuration.Amount.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.AmountOverDuration.Amount} returns this
 */
proto.types.AmountOverDuration.Amount.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.AmountOverDuration.Total.prototype.toObject = function(opt_includeInstance) {
  return proto.types.AmountOverDuration.Total.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.AmountOverDuration.Total} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AmountOverDuration.Total.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.AmountOverDuration.Total}
 */
proto.types.AmountOverDuration.Total.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.AmountOverDuration.Total;
  return proto.types.AmountOverDuration.Total.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.AmountOverDuration.Total} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.AmountOverDuration.Total}
 */
proto.types.AmountOverDuration.Total.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.AmountOverDuration.Total.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.AmountOverDuration.Total.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.AmountOverDuration.Total} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.AmountOverDuration.Total.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.types.AmountOverDuration.Total.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.AmountOverDuration.Total} returns this
 */
proto.types.AmountOverDuration.Total.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.types.AmountOverDuration.Total.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.AmountOverDuration.Total} returns this
 */
proto.types.AmountOverDuration.Total.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated Amount amounts = 1;
 * @return {!Array<!proto.types.AmountOverDuration.Amount>}
 */
proto.types.AmountOverDuration.prototype.getAmountsList = function() {
  return /** @type{!Array<!proto.types.AmountOverDuration.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.AmountOverDuration.Amount, 1));
};


/**
 * @param {!Array<!proto.types.AmountOverDuration.Amount>} value
 * @return {!proto.types.AmountOverDuration} returns this
*/
proto.types.AmountOverDuration.prototype.setAmountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.AmountOverDuration.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.AmountOverDuration.Amount}
 */
proto.types.AmountOverDuration.prototype.addAmounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.AmountOverDuration.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.AmountOverDuration} returns this
 */
proto.types.AmountOverDuration.prototype.clearAmountsList = function() {
  return this.setAmountsList([]);
};


/**
 * repeated Total totals = 2;
 * @return {!Array<!proto.types.AmountOverDuration.Total>}
 */
proto.types.AmountOverDuration.prototype.getTotalsList = function() {
  return /** @type{!Array<!proto.types.AmountOverDuration.Total>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.AmountOverDuration.Total, 2));
};


/**
 * @param {!Array<!proto.types.AmountOverDuration.Total>} value
 * @return {!proto.types.AmountOverDuration} returns this
*/
proto.types.AmountOverDuration.prototype.setTotalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.AmountOverDuration.Total=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.AmountOverDuration.Total}
 */
proto.types.AmountOverDuration.prototype.addTotals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.AmountOverDuration.Total, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.AmountOverDuration} returns this
 */
proto.types.AmountOverDuration.prototype.clearTotalsList = function() {
  return this.setTotalsList([]);
};


/**
 * @enum {number}
 */
proto.types.DeviceActionType = {
  TURN_OFF: 0,
  TURN_ON: 1,
  TOGGLE: 2
};

/**
 * @enum {number}
 */
proto.types.WallboxActionType = {
  START_CHARGING: 0,
  STOP_CHARGING: 1
};

/**
 * @enum {number}
 */
proto.types.ComparisonOperator = {
  EQUAL: 0,
  NOT_EQUAL: 1,
  GREATER_THAN: 2,
  GREATER_EQUAL_THAN: 3,
  LESS_THAN: 4,
  LESS_EQUAL_THAN: 5
};

/**
 * @enum {number}
 */
proto.types.WallboxStatus = {
  READY: 0,
  NOT_READY: 1,
  CHARGING: 2
};

/**
 * @enum {number}
 */
proto.types.DispatchedActionStatus = {
  PENDING: 0,
  DONE: 1,
  FAILED: 2
};

goog.object.extend(exports, proto.types);
