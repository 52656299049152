var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'widget-container': true,
    'min-height': _vm.minHeight,
    'widget-container-flex-grow': _vm.flexGrow,
    'box-shadow': _vm.boxShadow && !_vm.actionRequired,
    'box-shadow-hover': _vm.highlightOnHover,
    'action-required': _vm.actionRequired,
    'primary-color-background': _vm.background === 'primary',
    'secondary-color-background': _vm.background === 'secondary',
    'elevated-background': !_vm.background && !_vm.actionRequired,
    border: _vm.border,
    padding: _vm.padding,
    clickable: _vm.clickable,
  },style:({
    'flex-basis': _vm.flexGrow ? _vm.flexBasis : 'unset',
    height: _vm.height !== null ? _vm.height : 'unset',
    'max-width': _vm.maxWidth !== null ? _vm.maxWidth : null,
  }),on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.remoteDataPendingOrError)?_c('zf-request-status',{attrs:{"remoteData":_vm.remoteData,"color":_vm.background === 'primary' ? 'secondary' : 'primary',"alignment":"center"}}):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }