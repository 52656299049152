
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class UserName extends Vue {
  name = "";
  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";
  acceptedTerms: boolean = false;
  acceptedDataExchange: boolean = false;

  paths = paths;

  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @partnerModule.Getter isPartnerZaehlerfreunde: boolean;

  @Prop({ default: false }) loading: boolean;
  @Prop({ default: null }) error: UserError;

  onContinueClicked(): void {
    this.$emit("name-added", this.name);
  }
}
