
import { tariffServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import moment from "moment";
import { success, failure, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { formatNumber } from "@/utils/number-utils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DeleteAdvancePaymentRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { GetAdvancePaymentsRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";

@Component
export default class TariffAdvancePayments extends Vue {
  @Prop() contractId: string | null;
  @Prop() tariffStartDate: Date | null;
  @Prop() medium: Device.Medium;

  advancePayments: RemoteData<UserError, Tariff.AdvancePayment[]> = initialized;
  deleteCall: RemoteCall<UserError> = initialized;

  startDate: string = new Date().toISOString().substr(0, 7);
  amount: number = 0;
  menu: boolean = false;
  newAdvancePayment: Tariff.AdvancePayment | null = null;

  headers: { text: string; value: string; align: string }[] = [
    { text: "Zeitraum", value: "period", align: "start" },
    { text: "Betrag", value: "amount", align: "center" },
    { text: "", value: "controls", align: "end" },
  ];

  get items(): { id: string; period: string; amount: string }[] {
    let advancePayments: { id: string; period: string; amount: string }[] = [];
    this.advancePayments.list.forEach((p) => {
      advancePayments.push({
        id: p.getId(),
        period: p.getPeriod(),
        amount: `${formatNumber(p.getAmount())} €`,
      });
    });
    return advancePayments;
  }

  get minGraphDate(): string {
    if (this.advancePayments.list.length > 0) {
      const year = this.advancePayments.list[0].getStartYear();
      const month = this.advancePayments.list[0].getStartMonth();

      return new Date(year, month + 1, 1).toISOString();
    } else {
      if (this.tariffStartDate) {
        return moment(this.tariffStartDate.valueOf()).toISOString(true);
      } else {
        return new Date(2000, 0, 1).toISOString();
      }
    }
  }

  @Watch("startDate")
  onStartDateChanged(): void {
    const month = new Date(this.startDate).getMonth();
    const year = new Date(this.startDate).getFullYear();
    this.newAdvancePayment?.setStartMonth(month + 1);
    this.newAdvancePayment?.setStartYear(year);
    this.$emit("changed-advance-payment-details", this.newAdvancePayment);
  }

  @Watch("amount")
  onAmounChanged(): void {
    this.newAdvancePayment?.setAmount(this.amount);
    this.$emit("changed-advance-payment-details", this.newAdvancePayment);
  }

  onPlusClicked(): void {
    this.newAdvancePayment = new Tariff.AdvancePayment();
    const month = new Date(this.startDate).getMonth();
    const year = new Date(this.startDate).getFullYear();

    if (this.contractId) {
      this.newAdvancePayment?.setContractId(this.contractId);
    }

    this.newAdvancePayment?.setStartMonth(month + 1);
    this.newAdvancePayment?.setStartYear(year);
    this.newAdvancePayment?.setAmount(this.amount);
    this.$emit("changed-advance-payment-details", this.newAdvancePayment);
  }

  cancelNewAdvancePayment(): void {
    this.newAdvancePayment = null;
    this.$emit("changed-advance-payment-details", null);
  }

  get formattedMonth(): string | null {
    return new Date(this.startDate).toLocaleDateString("de-DE", { year: "numeric", month: "short" });
  }

  async loadAdvancePayments(): Promise<void> {
    if (this.contractId) {
      try {
        const request = new GetAdvancePaymentsRequest();
        request.setContractId(this.contractId);
        const response = await tariffServiceClient.getAdvancePayments(request, {});
        this.advancePayments = success(response.getAdvancePaymentsList());
        if (this.advancePayments.list.length > 0) {
          const year = this.advancePayments.list[0].getStartYear();
          const month = this.advancePayments.list[0].getStartMonth();
          this.startDate = new Date(year, month, 1).toISOString();
        }
      } catch (error) {
        this.advancePayments = failure(userErrorFrom(error));
      }
    }
  }

  async deleteAdvancePayment(advancePaymentId: string): Promise<void> {
    if (this.contractId) {
      try {
        const request = new DeleteAdvancePaymentRequest();
        request.setAdvancePaymentId(advancePaymentId);
        await tariffServiceClient.deleteAdvancePayment(request, {});
        this.deleteCall = success(void 0);
      } catch (error) {
        this.deleteCall = failure(userErrorFrom(error));
      }
      this.loadAdvancePayments();
    }
  }

  beforeMount(): void {
    this.loadAdvancePayments();
  }
}
