
import { Vue, Component } from "vue-property-decorator";
import { apiClient } from "@/config/service-clients";
import { GetAnnouncementRequest } from "zaehlerfreunde-central/misc_service_pb";

@Component
export default class Announcement extends Vue {
  announcement: string = "";

  async mounted(): Promise<void> {
    this.announcement = await apiClient
      .getAnnouncement(new GetAnnouncementRequest(), {})
      .then((response) => response.getAnnouncement());
  }
}
