
import { readingsServiceClient, uiServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { themeModule } from "@/store/modules/theme";
import { failure, initialized, pending, RemoteData, success, successOptional } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetLatestStatusHandlesRequest, GetLatestStatusRequest } from "zaehlerfreunde-central/reading_service_pb";
import { LatestStatus, LatestStatusHandle } from "zaehlerfreunde-proto-types/latest_status_pb";
import LatestStatusView from "./LatestStatusView.vue";
import { paths } from "@/router/routes";

const cardCardMargin = 16;

@Component({
  components: {
    LatestStatusView,
  },
})
export default class LatestStatusOverview extends Vue {
  latestStatusHandles: RemoteData<UserError, LatestStatusHandle[]> = initialized;
  latestStatuses: RemoteData<UserError, LatestStatus>[] = [];

  @themeModule.Getter apperance: "light" | "dark";

  statusWidths: number[] = [];
  width: number = 0;

  @Watch("latestStatuses")
  onLatestStatusesChanged() {
    this.statusWidths = this.latestStatuses.map((s) => LatestStatusView.getWidth(s));
  }

  get latestStatusPages(): RemoteData<UserError, LatestStatus>[][] {
    var pages: RemoteData<UserError, LatestStatus>[][] = [];
    var sum: number = 0;
    var index: number = 0;

    this.statusWidths.forEach((width, i) => {
      sum += width + cardCardMargin;

      if (sum + this.statusWidths[i + 1] > this.width || i === this.statusWidths.length - 1) {
        pages.push(this.latestStatuses.slice(index, i + 1));
        index = i + 1;
        sum = 0;
      }
    });

    return pages;
  }

  onEditBtnClicked(latestStatus: LatestStatus | null) {
    if (latestStatus) {
      this.$router.push(`${paths.platform.updateManualReadings}/${latestStatus.getDeviceId()}`);
    }
  }

  mounted(): void {
    this.resizeEventHandler();

    this.latestStatusHandles = pending;
    readingsServiceClient
      .getLatestStatusHandles(new GetLatestStatusHandlesRequest().setSpaceId(spaces.selectedSpaceId), {})
      .then((response) => {
        const handles = response.getHandlesList();
        this.latestStatusHandles = success(handles);
        this.loadLatestStatuses(handles);
      })
      .catch((error) => (this.latestStatusHandles = failure(userErrorFrom(error))));
  }

  resizeEventHandler(): void {
    this.$nextTick(() => {
      this.width = this.$el.clientWidth;
    });
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  async loadLatestStatuses(handles: LatestStatusHandle[]) {
    this.latestStatuses = handles.map(() => pending);

    handles.forEach((handle, i) => {
      readingsServiceClient
        .getLatestStatus(new GetLatestStatusRequest().setHandle(handle), {})
        .then((response) => {
          this.latestStatuses = this.latestStatuses.map((s, j) =>
            i === j ? successOptional(response.getStatus()) : s
          );
        })
        .catch((error) => (this.latestStatuses[i] = failure(userErrorFrom(error))));
    });
  }
}
