
import { themeModule } from "@/store/modules/theme";
import { Component, Vue } from "vue-property-decorator";
import { PartnerTheme } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class Product extends Vue {
  @themeModule.State partnerTheme: PartnerTheme | null;
  primaryColor: string = "";

  get productId(): string {
    return this.$route.params.productId;
  }

  mounted(): void {
    this.primaryColor = this.partnerTheme?.getPrimaryColor() ?? "";
    let script = document.createElement("script");
    script.setAttribute("src", "/single-product.js");
    script.type = "text/javascript";
    document.head.appendChild(script);
  }
}
