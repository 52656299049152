import { SearchResult, Tariff, TariffSearchInfo, TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";
import {
  GetCurrentTariffRequest,
  GetEstimatedConsumptionRequest,
  GetPendingSwitchRequest,
  GetSearchInfoRequest,
  GetSearchResultsRequest,
} from "zaehlerfreunde-central/tariff_service_pb";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import spaces from "@/store/modules/spaces";
import store from "@/store";
import { failure, initialized, pending, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { SpaceData } from "../utils/space-data";
import { namespace } from "vuex-class";
import { tariffServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "tariff",
})
class TariffModule extends VuexModule {
  tariffSearchInfoBySpaceId: SpaceData<RemoteData<UserError, TariffSearchInfo | null>> = {};
  pendingTariffSwitchBySpaceId: SpaceData<RemoteData<UserError, TariffSwitch | null>> = {};
  tariffSearchResultsBySpaceId: SpaceData<RemoteData<UserError, SearchResult[]>> = {};
  estimatedConsumptionBySpaceId: SpaceData<RemoteData<UserError, number>> = {};

  currentElectricityTariff: RemoteData<UserError, Tariff | null> = initialized;

  get tariffSearchInfo(): RemoteData<UserError, TariffSearchInfo | null> {
    return this.tariffSearchInfoBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get pendingTariffSwitch(): RemoteData<UserError, TariffSwitch | null> {
    return this.pendingTariffSwitchBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get tariffSearchResults(): RemoteData<UserError, SearchResult[]> {
    return this.tariffSearchResultsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get estimatedConsumption(): RemoteData<UserError, number> {
    return this.estimatedConsumptionBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  @Action
  async getTariffSearchInfo() {
    try {
      this.setTariffSearchInfo(pending);
      const request = new GetSearchInfoRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      const response = await tariffServiceClient.getSearchInfo(request, {});
      this.setTariffSearchInfo(success(response.getSearchInfo() ?? null));
    } catch (error) {
      this.setTariffSearchInfo(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getTariffSearchResults() {
    try {
      this.setTariffSearchResults(pending);
      const request = new GetSearchResultsRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      const response = await tariffServiceClient.getSearchResults(request, {});
      this.setTariffSearchResults(success(response.getSearchResultsList()));
    } catch (error) {
      this.setTariffSearchResults(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getPendingTariffSwitch() {
    try {
      this.setPendingTariffSwitch(pending);
      const request = new GetPendingSwitchRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      const response = await tariffServiceClient.getPendingSwitch(request, {});
      this.setPendingTariffSwitch(success(response.getPendingSwitch() ?? null));
    } catch (error) {
      this.setPendingTariffSwitch(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getEstimatedConsumption() {
    try {
      this.setEstimatedConsumption(pending);

      const request = new GetEstimatedConsumptionRequest();
      request.setSpaceId(spaces.selectedSpaceId);

      const response = await tariffServiceClient.getEstimatedConsumption(request, {});
      this.setEstimatedConsumption(success(response.getEstimatedConsumption()));
    } catch (error) {
      this.setEstimatedConsumption(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getCurrentElectricityTariff() {
    try {
      this.setCurrentElectricityTariff(pending);

      const request = new GetCurrentTariffRequest();
      request.setMedium(Device.Medium.ELECTRICITY);
      request.setSpaceId(spaces.selectedSpaceId);

      const response = await tariffServiceClient.getCurrentTariff(request, {});

      this.setCurrentElectricityTariff(success(response.getTariff() ?? null));
    } catch (error) {
      this.setCurrentElectricityTariff(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setTariffSearchInfo(data: RemoteData<UserError, TariffSearchInfo | null>) {
    this.tariffSearchInfoBySpaceId = {
      ...this.tariffSearchInfoBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setPendingTariffSwitch(data: RemoteData<UserError, TariffSwitch | null>) {
    this.pendingTariffSwitchBySpaceId = {
      ...this.pendingTariffSwitchBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setTariffSearchResults(data: RemoteData<UserError, SearchResult[]>) {
    this.tariffSearchResultsBySpaceId = {
      ...this.tariffSearchResultsBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setEstimatedConsumption(data: RemoteData<UserError, number>) {
    this.estimatedConsumptionBySpaceId = {
      ...this.estimatedConsumptionBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setCurrentElectricityTariff(tariff: RemoteData<UserError, Tariff | null>) {
    this.currentElectricityTariff = tariff;
  }
}

export const tariffModule = namespace("tariff");
export default getModule(TariffModule);
