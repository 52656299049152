
import { Vue, Component } from "vue-property-decorator";
import { ErrorCode } from "./error-code";

export const errorMessages = {
  [ErrorCode.UnableToReachServer]:
    "Verbindung mit Server konnte nicht aufgebaut werden. Bitte versuche es später nochmal.",
  [ErrorCode.UnknownError]: "Ein Fehler ist aufgetreten. Bitte versuche es später nochmal.",
};

@Component
export default class ErrorPage extends Vue {
  errorCode: ErrorCode = ErrorCode.UnknownError;
  traceId: string | null = null;

  mounted(): void {
    const errorCodeString = this.$route.query["code"] as string;
    if (errorCodeString && errorCodeString in ErrorCode) {
      this.errorCode = ErrorCode[errorCodeString];
    }

    this.traceId = (this.$route.query["traceId"] as string) ?? null;
  }

  get errorMessage(): string {
    return `${errorMessages[this.errorCode]} ${this.traceId ? `(Trace Id: ${this.traceId})` : ""}`;
  }
}
