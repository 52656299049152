/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_ui_service_pb from '../../services/central/ui_service_pb';


export class UIServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetMinimumAppVersion = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetMinimumAppVersion',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetMinimumAppVersionRequest,
    services_central_ui_service_pb.GetMinimumAppVersionResponse,
    (request: services_central_ui_service_pb.GetMinimumAppVersionRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetMinimumAppVersionResponse.deserializeBinary
  );

  getMinimumAppVersion(
    request: services_central_ui_service_pb.GetMinimumAppVersionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetMinimumAppVersionResponse>;

  getMinimumAppVersion(
    request: services_central_ui_service_pb.GetMinimumAppVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetMinimumAppVersionResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetMinimumAppVersionResponse>;

  getMinimumAppVersion(
    request: services_central_ui_service_pb.GetMinimumAppVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetMinimumAppVersionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetMinimumAppVersion',
        request,
        metadata || {},
        this.methodInfoGetMinimumAppVersion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetMinimumAppVersion',
    request,
    metadata || {},
    this.methodInfoGetMinimumAppVersion);
  }

  methodInfoGetCurrentMonthWidgetData = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetCurrentMonthWidgetData',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetCurrentMonthWidgetDataRequest,
    services_central_ui_service_pb.GetCurrentMonthWidgetDataResponse,
    (request: services_central_ui_service_pb.GetCurrentMonthWidgetDataRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetCurrentMonthWidgetDataResponse.deserializeBinary
  );

  getCurrentMonthWidgetData(
    request: services_central_ui_service_pb.GetCurrentMonthWidgetDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetCurrentMonthWidgetDataResponse>;

  getCurrentMonthWidgetData(
    request: services_central_ui_service_pb.GetCurrentMonthWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetCurrentMonthWidgetDataResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetCurrentMonthWidgetDataResponse>;

  getCurrentMonthWidgetData(
    request: services_central_ui_service_pb.GetCurrentMonthWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetCurrentMonthWidgetDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetCurrentMonthWidgetData',
        request,
        metadata || {},
        this.methodInfoGetCurrentMonthWidgetData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetCurrentMonthWidgetData',
    request,
    metadata || {},
    this.methodInfoGetCurrentMonthWidgetData);
  }

  methodInfoGetDashboardChartData = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetDashboardChartData',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetDashboardChartDataRequest,
    services_central_ui_service_pb.GetDashboardChartDataResponse,
    (request: services_central_ui_service_pb.GetDashboardChartDataRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetDashboardChartDataResponse.deserializeBinary
  );

  getDashboardChartData(
    request: services_central_ui_service_pb.GetDashboardChartDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetDashboardChartDataResponse>;

  getDashboardChartData(
    request: services_central_ui_service_pb.GetDashboardChartDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetDashboardChartDataResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetDashboardChartDataResponse>;

  getDashboardChartData(
    request: services_central_ui_service_pb.GetDashboardChartDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetDashboardChartDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetDashboardChartData',
        request,
        metadata || {},
        this.methodInfoGetDashboardChartData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetDashboardChartData',
    request,
    metadata || {},
    this.methodInfoGetDashboardChartData);
  }

  methodInfoGetSelectedSpace = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetSelectedSpace',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetSelectedSpaceRequest,
    services_central_ui_service_pb.GetSelectedSpaceResponse,
    (request: services_central_ui_service_pb.GetSelectedSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetSelectedSpaceResponse.deserializeBinary
  );

  getSelectedSpace(
    request: services_central_ui_service_pb.GetSelectedSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetSelectedSpaceResponse>;

  getSelectedSpace(
    request: services_central_ui_service_pb.GetSelectedSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetSelectedSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetSelectedSpaceResponse>;

  getSelectedSpace(
    request: services_central_ui_service_pb.GetSelectedSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetSelectedSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetSelectedSpace',
        request,
        metadata || {},
        this.methodInfoGetSelectedSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetSelectedSpace',
    request,
    metadata || {},
    this.methodInfoGetSelectedSpace);
  }

  methodInfoSetSelectedSpace = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/SetSelectedSpace',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.SetSelectedSpaceRequest,
    services_central_ui_service_pb.SetSelectedSpaceResponse,
    (request: services_central_ui_service_pb.SetSelectedSpaceRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.SetSelectedSpaceResponse.deserializeBinary
  );

  setSelectedSpace(
    request: services_central_ui_service_pb.SetSelectedSpaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.SetSelectedSpaceResponse>;

  setSelectedSpace(
    request: services_central_ui_service_pb.SetSelectedSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.SetSelectedSpaceResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.SetSelectedSpaceResponse>;

  setSelectedSpace(
    request: services_central_ui_service_pb.SetSelectedSpaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.SetSelectedSpaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/SetSelectedSpace',
        request,
        metadata || {},
        this.methodInfoSetSelectedSpace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/SetSelectedSpace',
    request,
    metadata || {},
    this.methodInfoSetSelectedSpace);
  }

  methodInfoGetAdvancePaymentWidgetData = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetAdvancePaymentWidgetData',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetAdvancePaymentWidgetDataRequest,
    services_central_ui_service_pb.GetAdvancePaymentWidgetDataResponse,
    (request: services_central_ui_service_pb.GetAdvancePaymentWidgetDataRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetAdvancePaymentWidgetDataResponse.deserializeBinary
  );

  getAdvancePaymentWidgetData(
    request: services_central_ui_service_pb.GetAdvancePaymentWidgetDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetAdvancePaymentWidgetDataResponse>;

  getAdvancePaymentWidgetData(
    request: services_central_ui_service_pb.GetAdvancePaymentWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAdvancePaymentWidgetDataResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetAdvancePaymentWidgetDataResponse>;

  getAdvancePaymentWidgetData(
    request: services_central_ui_service_pb.GetAdvancePaymentWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAdvancePaymentWidgetDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetAdvancePaymentWidgetData',
        request,
        metadata || {},
        this.methodInfoGetAdvancePaymentWidgetData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetAdvancePaymentWidgetData',
    request,
    metadata || {},
    this.methodInfoGetAdvancePaymentWidgetData);
  }

  methodInfoGetSpaceComparisonWidgetData = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetSpaceComparisonWidgetData',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetSpaceComparisonWidgetDataRequest,
    services_central_ui_service_pb.GetSpaceComparisonWidgetDataResponse,
    (request: services_central_ui_service_pb.GetSpaceComparisonWidgetDataRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetSpaceComparisonWidgetDataResponse.deserializeBinary
  );

  getSpaceComparisonWidgetData(
    request: services_central_ui_service_pb.GetSpaceComparisonWidgetDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetSpaceComparisonWidgetDataResponse>;

  getSpaceComparisonWidgetData(
    request: services_central_ui_service_pb.GetSpaceComparisonWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetSpaceComparisonWidgetDataResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetSpaceComparisonWidgetDataResponse>;

  getSpaceComparisonWidgetData(
    request: services_central_ui_service_pb.GetSpaceComparisonWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetSpaceComparisonWidgetDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetSpaceComparisonWidgetData',
        request,
        metadata || {},
        this.methodInfoGetSpaceComparisonWidgetData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetSpaceComparisonWidgetData',
    request,
    metadata || {},
    this.methodInfoGetSpaceComparisonWidgetData);
  }

  methodInfoGetTabularData = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetTabularData',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetTabularDataRequest,
    services_central_ui_service_pb.GetTabularDataResponse,
    (request: services_central_ui_service_pb.GetTabularDataRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetTabularDataResponse.deserializeBinary
  );

  getTabularData(
    request: services_central_ui_service_pb.GetTabularDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetTabularDataResponse>;

  getTabularData(
    request: services_central_ui_service_pb.GetTabularDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetTabularDataResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetTabularDataResponse>;

  getTabularData(
    request: services_central_ui_service_pb.GetTabularDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetTabularDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetTabularData',
        request,
        metadata || {},
        this.methodInfoGetTabularData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetTabularData',
    request,
    metadata || {},
    this.methodInfoGetTabularData);
  }

  methodInfoGetAutomationDescriptions = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetAutomationDescriptions',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetAutomationDescriptionsRequest,
    services_central_ui_service_pb.GetAutomationDescriptionsResponse,
    (request: services_central_ui_service_pb.GetAutomationDescriptionsRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetAutomationDescriptionsResponse.deserializeBinary
  );

  getAutomationDescriptions(
    request: services_central_ui_service_pb.GetAutomationDescriptionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetAutomationDescriptionsResponse>;

  getAutomationDescriptions(
    request: services_central_ui_service_pb.GetAutomationDescriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAutomationDescriptionsResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetAutomationDescriptionsResponse>;

  getAutomationDescriptions(
    request: services_central_ui_service_pb.GetAutomationDescriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAutomationDescriptionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetAutomationDescriptions',
        request,
        metadata || {},
        this.methodInfoGetAutomationDescriptions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetAutomationDescriptions',
    request,
    metadata || {},
    this.methodInfoGetAutomationDescriptions);
  }

  methodInfoGetDashboardLayout = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetDashboardLayout',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetDashboardLayoutRequest,
    services_central_ui_service_pb.GetDashboardLayoutResponse,
    (request: services_central_ui_service_pb.GetDashboardLayoutRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetDashboardLayoutResponse.deserializeBinary
  );

  getDashboardLayout(
    request: services_central_ui_service_pb.GetDashboardLayoutRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetDashboardLayoutResponse>;

  getDashboardLayout(
    request: services_central_ui_service_pb.GetDashboardLayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetDashboardLayoutResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetDashboardLayoutResponse>;

  getDashboardLayout(
    request: services_central_ui_service_pb.GetDashboardLayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetDashboardLayoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetDashboardLayout',
        request,
        metadata || {},
        this.methodInfoGetDashboardLayout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetDashboardLayout',
    request,
    metadata || {},
    this.methodInfoGetDashboardLayout);
  }

  methodInfoResetDashboardLayout = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/ResetDashboardLayout',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.ResetDashboardLayoutRequest,
    services_central_ui_service_pb.ResetDashboardLayoutResponse,
    (request: services_central_ui_service_pb.ResetDashboardLayoutRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.ResetDashboardLayoutResponse.deserializeBinary
  );

  resetDashboardLayout(
    request: services_central_ui_service_pb.ResetDashboardLayoutRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.ResetDashboardLayoutResponse>;

  resetDashboardLayout(
    request: services_central_ui_service_pb.ResetDashboardLayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.ResetDashboardLayoutResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.ResetDashboardLayoutResponse>;

  resetDashboardLayout(
    request: services_central_ui_service_pb.ResetDashboardLayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.ResetDashboardLayoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/ResetDashboardLayout',
        request,
        metadata || {},
        this.methodInfoResetDashboardLayout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/ResetDashboardLayout',
    request,
    metadata || {},
    this.methodInfoResetDashboardLayout);
  }

  methodInfoMoveDashboardItem = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/MoveDashboardItem',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.MoveDashboardItemRequest,
    services_central_ui_service_pb.MoveDashboardItemResponse,
    (request: services_central_ui_service_pb.MoveDashboardItemRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.MoveDashboardItemResponse.deserializeBinary
  );

  moveDashboardItem(
    request: services_central_ui_service_pb.MoveDashboardItemRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.MoveDashboardItemResponse>;

  moveDashboardItem(
    request: services_central_ui_service_pb.MoveDashboardItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.MoveDashboardItemResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.MoveDashboardItemResponse>;

  moveDashboardItem(
    request: services_central_ui_service_pb.MoveDashboardItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.MoveDashboardItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/MoveDashboardItem',
        request,
        metadata || {},
        this.methodInfoMoveDashboardItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/MoveDashboardItem',
    request,
    metadata || {},
    this.methodInfoMoveDashboardItem);
  }

  methodInfoDeleteDashboardItem = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/DeleteDashboardItem',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.DeleteDashboardItemRequest,
    services_central_ui_service_pb.DeleteDashboardItemResponse,
    (request: services_central_ui_service_pb.DeleteDashboardItemRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.DeleteDashboardItemResponse.deserializeBinary
  );

  deleteDashboardItem(
    request: services_central_ui_service_pb.DeleteDashboardItemRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.DeleteDashboardItemResponse>;

  deleteDashboardItem(
    request: services_central_ui_service_pb.DeleteDashboardItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.DeleteDashboardItemResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.DeleteDashboardItemResponse>;

  deleteDashboardItem(
    request: services_central_ui_service_pb.DeleteDashboardItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.DeleteDashboardItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/DeleteDashboardItem',
        request,
        metadata || {},
        this.methodInfoDeleteDashboardItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/DeleteDashboardItem',
    request,
    metadata || {},
    this.methodInfoDeleteDashboardItem);
  }

  methodInfoGetAvailableFeatures = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetAvailableFeatures',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetAvailableFeaturesRequest,
    services_central_ui_service_pb.GetAvailableFeaturesResponse,
    (request: services_central_ui_service_pb.GetAvailableFeaturesRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetAvailableFeaturesResponse.deserializeBinary
  );

  getAvailableFeatures(
    request: services_central_ui_service_pb.GetAvailableFeaturesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetAvailableFeaturesResponse>;

  getAvailableFeatures(
    request: services_central_ui_service_pb.GetAvailableFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAvailableFeaturesResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetAvailableFeaturesResponse>;

  getAvailableFeatures(
    request: services_central_ui_service_pb.GetAvailableFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAvailableFeaturesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetAvailableFeatures',
        request,
        metadata || {},
        this.methodInfoGetAvailableFeatures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetAvailableFeatures',
    request,
    metadata || {},
    this.methodInfoGetAvailableFeatures);
  }

  methodInfoGetTreeSelect = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetTreeSelect',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetTreeSelectRequest,
    services_central_ui_service_pb.GetTreeSelectResponse,
    (request: services_central_ui_service_pb.GetTreeSelectRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetTreeSelectResponse.deserializeBinary
  );

  getTreeSelect(
    request: services_central_ui_service_pb.GetTreeSelectRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetTreeSelectResponse>;

  getTreeSelect(
    request: services_central_ui_service_pb.GetTreeSelectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetTreeSelectResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetTreeSelectResponse>;

  getTreeSelect(
    request: services_central_ui_service_pb.GetTreeSelectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetTreeSelectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetTreeSelect',
        request,
        metadata || {},
        this.methodInfoGetTreeSelect,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetTreeSelect',
    request,
    metadata || {},
    this.methodInfoGetTreeSelect);
  }

  methodInfoGetSelectedChildPartner = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetSelectedChildPartner',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetSelectedChildPartnerRequest,
    services_central_ui_service_pb.GetSelectedChildPartnerResponse,
    (request: services_central_ui_service_pb.GetSelectedChildPartnerRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetSelectedChildPartnerResponse.deserializeBinary
  );

  getSelectedChildPartner(
    request: services_central_ui_service_pb.GetSelectedChildPartnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetSelectedChildPartnerResponse>;

  getSelectedChildPartner(
    request: services_central_ui_service_pb.GetSelectedChildPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetSelectedChildPartnerResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetSelectedChildPartnerResponse>;

  getSelectedChildPartner(
    request: services_central_ui_service_pb.GetSelectedChildPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetSelectedChildPartnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetSelectedChildPartner',
        request,
        metadata || {},
        this.methodInfoGetSelectedChildPartner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetSelectedChildPartner',
    request,
    metadata || {},
    this.methodInfoGetSelectedChildPartner);
  }

  methodInfoSwitchChildPartner = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/SwitchChildPartner',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.SwitchChildPartnerRequest,
    services_central_ui_service_pb.SwitchChildPartnerResponse,
    (request: services_central_ui_service_pb.SwitchChildPartnerRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.SwitchChildPartnerResponse.deserializeBinary
  );

  switchChildPartner(
    request: services_central_ui_service_pb.SwitchChildPartnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.SwitchChildPartnerResponse>;

  switchChildPartner(
    request: services_central_ui_service_pb.SwitchChildPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.SwitchChildPartnerResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.SwitchChildPartnerResponse>;

  switchChildPartner(
    request: services_central_ui_service_pb.SwitchChildPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.SwitchChildPartnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/SwitchChildPartner',
        request,
        metadata || {},
        this.methodInfoSwitchChildPartner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/SwitchChildPartner',
    request,
    metadata || {},
    this.methodInfoSwitchChildPartner);
  }

  methodInfoGetAutomationWidgetData = new grpcWeb.MethodDescriptor(
    '/services.central.UIService/GetAutomationWidgetData',
    grpcWeb.MethodType.UNARY,
    services_central_ui_service_pb.GetAutomationWidgetDataRequest,
    services_central_ui_service_pb.GetAutomationWidgetDataResponse,
    (request: services_central_ui_service_pb.GetAutomationWidgetDataRequest) => {
      return request.serializeBinary();
    },
    services_central_ui_service_pb.GetAutomationWidgetDataResponse.deserializeBinary
  );

  getAutomationWidgetData(
    request: services_central_ui_service_pb.GetAutomationWidgetDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_ui_service_pb.GetAutomationWidgetDataResponse>;

  getAutomationWidgetData(
    request: services_central_ui_service_pb.GetAutomationWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAutomationWidgetDataResponse) => void): grpcWeb.ClientReadableStream<services_central_ui_service_pb.GetAutomationWidgetDataResponse>;

  getAutomationWidgetData(
    request: services_central_ui_service_pb.GetAutomationWidgetDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_ui_service_pb.GetAutomationWidgetDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.UIService/GetAutomationWidgetData',
        request,
        metadata || {},
        this.methodInfoGetAutomationWidgetData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.UIService/GetAutomationWidgetData',
    request,
    metadata || {},
    this.methodInfoGetAutomationWidgetData);
  }

}

