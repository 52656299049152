
import DevicesTable, { SearchParams } from "@/components/devices-table/DevicesTable.vue";
import spaces from "@/store/modules/spaces";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GetPartnerDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { paths } from "@/router/routes";
import DeleteDeviceDialog from "../../devices/DeleteDeviceDialog.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import LinkSpaceToDeviceDialog from "./Dialogs/LinkSpaceToDeviceDialog.vue";
import { deviceServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component({
  components: {
    DevicesTable,
    DeleteDeviceDialog,
    LinkSpaceToDeviceDialog,
  },
})
export default class SpaceDevices extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  @Prop() spaceId: string | null;
  @Prop({ default: initialized }) spaceInfo: RemoteData<UserError, Space>;

  partnerDevices: RemoteData<UserError, Device[]> = initialized;
  devicePages: RemoteData<UserError, number> = initialized;
  page = 1;

  showAddDeviceToSpaceDialog: boolean = false;
  showDeleteSpaceDialog: boolean = false;
  selectedSpace: Space | null = null;
  selectedDevice: Device | null = null;
  ownerDevices: RemoteData<UserError, Device[]> = initialized;

  itemControls = [
    {
      icon: "mdi-link-variant-off",
      event: "unlink-device",
    },
  ];
  headerControls = [
    {
      icon: "mdi-link-variant",
      event: "link-device-to-space",
    },
    {
      icon: "mdi-plus-circle",
      event: "connect-new-device",
    },
  ];

  mounted(): void {
    this.selectedSpace = this.spaceInfo.data;
    this.loadDevices(this.page);
  }

  async loadDevices(page: number): Promise<void> {
    this.page = page;
    const request = new GetPartnerDevicesRequest();
    request.setPage(page - 1);
    if (this.spaceId) {
      request.setSpaceId(this.spaceId);
    }
    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      this.partnerDevices = pending;
      const response = await deviceServiceClient.getPartnerDevices(request, {});
      this.partnerDevices = success(response.getDevicesList());
      this.devicePages = success(response.getTotalNumPages());
    } catch (error) {
      this.partnerDevices = failure(userErrorFrom(error));
      this.devicePages = failure(userErrorFrom(error));
    }
  }

  handleRowClick(device): void {
    this.$router.push({ path: `${paths.admin.devices}/${device.id}` });
  }

  unlinkDevice(device: Device): void {
    event?.stopPropagation();
    this.showDeleteSpaceDialog = true;
    this.selectedDevice = device;
  }
  connectNewDevice(): void {
    spaces.setSelectedSpaceByAdmin(this.selectedSpace);
    this.$router.push(paths.admin.connectDevice);
  }
}
