
import { failure, initialized, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  AddLandlordToTenantProjectRequest,
  EditLandlordToTenantProjectDetailsRequest,
} from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
@Component({
  components: {},
})
export default class EditLandlordToTenantProjectDialog extends Vue {
  editProjectDetailsRequest: RemoteCall<UserError> = initialized;
  @Prop({ default: null }) name: string | null;
  @Prop({ default: null }) location: string | null;
  @Prop({ default: null }) projectId: string | null;

  get infoComplete(): boolean {
    return this.name != null && this.location != null;
  }

  async editProjectDetails(): Promise<void> {
    const request = new EditLandlordToTenantProjectDetailsRequest();
    if (this.name && this.location && this.projectId) {
      request.setName(this.name);
      request.setLocation(this.location);
      request.setProjectId(this.projectId);

      try {
        await landlordToTenantServiceClient.editLandlordToTenantProjectDetails(request, {});
        this.editProjectDetailsRequest = success(void 0);
        this.$emit("project-edited");
      } catch (error) {
        this.editProjectDetailsRequest = failure(userErrorFrom(error));
      }
    }
  }
}
