
import { Vue, Component } from "vue-property-decorator";
import ProsAndConsList from "@/components/ProsAndConsList.vue";

@Component({
  components: {
    ProsAndConsList,
  },
})
export default class DynamicTariffChange extends Vue {
  pros: string[] = [
    "Strom dann verbrauchen, wenn er besonders günstig ist",
    "Smarte Steuereungen von Zählerfreunde verwenden",
    "Schutz von Preiserhöhungen und keine fixen Abschlagszahlungen mehr",
    "Hilft bei der Entlastung der Stromnetze",
  ];
  dialog: boolean = false;
  subtitle = `Was ist ein dynamischer Tarif? Im Gegensatz zu herkömmlichen Tarifen, bei denen die Presie im Voraus festgelegt sind, können sich die Kosten für Strom in einem dynamischen Tarif stündlich ändern. Dies ermöglicht es Verbrauchern, Strom zu besonders günstigen Zeiten zu beziehen und dadurch Kosten zu sparen. Voraussetzung dafür ist aber ein intelliges Messsystem(Smart-Meter).`;
}
