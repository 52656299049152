
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { DeviceRegistration, MeteocontrolRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  MeteocontrolSystem,
  MeteocontrolInverters,
  GetMeteocontrolSystemsRequest,
  GetMeteocontrolInvertersRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import spaces from "@/store/modules/spaces";

@Component
export default class MeteocontrolConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  systems: RemoteData<UserError, MeteocontrolSystem[]> = initialized;
  inverters: RemoteData<UserError, MeteocontrolInverters[]> = initialized;
  email: string = "";
  password: string = "";
  selectedSystem: string | null = null;
  acceptedTerms: boolean = false;
  address: string = "";
  selectedInverter: string | null = null;
  accessToken: string = "";

  systemName(device: MeteocontrolSystem): string {
    return device.getName();
  }

  systemKey(device: MeteocontrolSystem): string {
    return device.getSystemId();
  }

  inverterName(device: MeteocontrolInverters): string {
    return device.getName();
  }

  inverterKey(device: MeteocontrolInverters): string {
    return device.getId();
  }

  async onLoginClicked(): Promise<void> {
    const concatenated = `${this.email}:${this.password}`;
    const accessToken = btoa(concatenated);
    this.accessToken = `Basic ${accessToken}`;

    try {
      this.systems = pending;
      const request = new GetMeteocontrolSystemsRequest();
      request.setAccessToken(this.accessToken);

      const response = await deviceServiceClient.getMeteocontrolSystems(request, {});
      this.systems = success(response.getSystemsList());
    } catch (error) {
      this.systems = failure(userErrorFrom(error));
    }
  }

  back(): void {
    this.$router.go(-1);
  }

  async getInverters(): Promise<void> {
    try {
      this.inverters = pending;
      const request = new GetMeteocontrolInvertersRequest();
      if (this.selectedSystem) {
        request.setSystemId(this.selectedSystem);
        request.setAccessToken(this.accessToken);

        const response = await deviceServiceClient.getMeteocontrolInverters(request, {});
        this.address = response.getAddress();
        this.inverters = success(response.getInvertersList());
      }
    } catch (error) {
      this.inverters = failure(userErrorFrom(error));
    }
  }

  async onRegisterClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice ?? "false");
    registration.setSpaceMapping(spaceMapping);

    const details = new MeteocontrolRegistrationDetails();
    if (this.selectedSystem && this.selectedInverter) {
      details.setSystemId(this.selectedSystem);
      details.setInverterId(this.selectedInverter);
      details.setAccessToken(this.accessToken);

      registration.setMeteocontrol(details);

      const request = new RegisterDeviceRequest();
      request.setRegistration(registration);
      this.registerDeviceCall = pending;

      try {
        await deviceServiceClient.registerDevice(request, {});
        this.registerDeviceCall = success(void 0);
        this.$router.go(-1);
      } catch (error) {
        this.registerDeviceCall = failure(userErrorFrom(error));
      }
    }
  }
}
