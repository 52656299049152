
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { advisoryServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { TrackTipInteractionRequest } from "zaehlerfreunde-central/advisory_service_pb";
import TipExpanded from "./TipExpanded.vue";
import { Tip, TipInteraction } from "zaehlerfreunde-proto-types/tips_pb";

@Component({
  components: {
    TipExpanded,
  },
})
export default class TipWidget extends Vue {
  @Prop() tip: Tip;
  @Prop() tipsPerPage: number;
  @Prop({ default: true }) showDetails: boolean;

  updateTipCall: RemoteCall<UserError> = initialized;
  expandTip: boolean = false;

  async onCompleteTipClicked(event: CustomEvent): Promise<void> {
    event.stopPropagation();

    try {
      this.updateTipCall = pending;

      const interaction = new TipInteraction();
      interaction.setTipId(this.tip.getId());
      interaction.setKind(this.tip.getIsCompleted() ? TipInteraction.Kind.UNCOMPLETED : TipInteraction.Kind.COMPLETED);

      const request = new TrackTipInteractionRequest();
      request.setInteraction(interaction);

      await advisoryServiceClient.trackTipInteraction(request, {});

      this.tip.setIsCompleted(!this.tip.getIsCompleted());
      this.updateTipCall = success(void 0);
    } catch (error) {
      this.updateTipCall = failure(userErrorFrom(error));
    }
  }

  onAffiliateLinkClicked(): void {
    this.$gtag.event("affiliate-link-opened", {
      event_category: "user-action",
      event_label: `Affiliate link of ${this.tip.getTitle()} tip opened`,
    });
  }

  onTipClicked(): void {
    this.expandTip = true;

    this.$gtag.event("tip-opened", {
      event_category: "user-action",
      event_label: this.tip.getTitle(),
    });
  }
}
