
import spaces, { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GetPowerfoxAuthURLRequest } from "zaehlerfreunde-central/device_service_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";

export interface PowerfoxConnectionState {
  spaceId: string;
  deviceName: string;
  isMainDevice: boolean;
  isAdmin: boolean;
  deviceCount: number;
}

@Component
export default class PowerfoxConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  @spacesModule.Getter selectedSpaceId: string;

  redirectURI: RemoteData<UserError, string> = initialized;
  count: number = 1;

  updateCount(delta: number): void {
    this.count += delta;
  }

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  async openPowerfoxLogin(): Promise<void> {
    const state: PowerfoxConnectionState = {
      spaceId: this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId,
      deviceName: this.generalInfo.deviceName,
      isMainDevice: this.generalInfo.isMainDevice,
      isAdmin: this.adminAction,
      deviceCount: this.count,
    };

    this.redirectURI = pending;

    try {
      const request = new GetPowerfoxAuthURLRequest();
      request.setState(JSON.stringify(state));
      const response = await deviceServiceClient.getPowerfoxAuthURL(request, {});
      window.open(response.getAuthUrl(), "_self");
    } catch (error) {
      this.redirectURI = failure(userErrorFrom(error));
    }
  }
}
