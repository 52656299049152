
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { DeviceRegistration, TibberRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { GetTibberAuthTokenRequest, RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { paths } from "@/router/routes";
import { GetTibberHomesRequest, TibberHome } from "zaehlerfreunde-central/device_service_pb";

@Component
export default class TibberSelectHomesPage extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  homes: RemoteData<UserError, TibberHome[]> = initialized;
  authTokenCall: RemoteData<UserError, string> = initialized;
  email: string = "";
  selectedHome: string = "";
  paths = paths;

  homeAddress(home: TibberHome): string {
    return home.getAddress1();
  }

  homeId(home: TibberHome): string {
    return home.getId();
  }

  mounted(): void {
    const code = this.$route.query["code"] as string;
    if (code) {
      this.getTibberAuthToken(code);
    }
  }

  async getTibberAuthToken(code: string): Promise<void> {
    const request = new GetTibberAuthTokenRequest();
    request.setAuthorizationCode(code);
    try {
      const response = await deviceServiceClient.getTibberAuthToken(request, {});
      this.authTokenCall = success(response.getAccessToken());
      this.getTibberHomes(this.authTokenCall.data ?? "");
    } catch (error) {
      this.authTokenCall = failure(userErrorFrom(error));
    }
  }

  async getTibberHomes(authToken: string): Promise<void> {
    this.homes = pending;
    try {
      const request = new GetTibberHomesRequest();
      request.setAuthToken(authToken);
      const response = await deviceServiceClient.getTibberHomes(request, {});
      this.homes = success(response.getHomesList());
    } catch (error) {
      this.homes = failure(userErrorFrom(error));
    }
  }

  back(): void {
    this.$router.push(paths.platform.devices);
  }

  async onRegisterClicked(): Promise<void> {
    const stateJSON = this.$route.query["state"] as string;
    const state = JSON.parse(stateJSON);

    const registration = new DeviceRegistration();
    registration.setDeviceName(state.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(Device.Type.NON_SMART_METER);
    registration.setDeviceProvider(Device.Provider.TIBBER);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(state.spaceId);
    spaceMapping.setIsMainDevice(state.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new TibberRegistrationDetails();
    details.setHomeId(this.selectedHome);
    details.setAuthToken(this.authTokenCall.data ?? "");

    registration.setTibber(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      state.isAdminAction ? this.$router.push(paths.admin.devices) : this.$router.push(paths.platform.devices);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
