
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import TariffFound from "./TariffFound.vue";
import TariffSearchActive from "./TariffSearchActive.vue";
import TariffSwitchPending from "./TariffSwitchPending.vue";
import { SearchResult, Tariff, TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";
import AddTariffReminder from "../AddTariffReminder.vue";

@Component({
  components: {
    TariffFound,
    TariffSearchActive,
    TariffSwitchPending,
    AddTariffReminder,
  },
})
export default class TariffSwitchStatus extends Vue {
  @tariffModule.State currentElectricityTariff: RemoteData<UserError, Tariff | null>;
  @tariffModule.Getter pendingTariffSwitch: RemoteData<UserError, TariffSwitch | null>;
  @tariffModule.Getter tariffSearchResults: RemoteData<UserError, SearchResult[]>;
}
