
import { partnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import BitShakeCommonInstructions from "./BitShakeCommonInstructions.vue";

@Component({
  components: {
    BitShakeCommonInstructions,
  },
})
export default class BitShakeAppInstructions extends Vue {
  @partnerModule.Getter supportEmail: string;
  @partnerModule.Getter partnerName: string;
}
