
import {
  failure,
  initialized,
  initializedFactory,
  pending,
  RemoteCall,
  RemoteData,
  success,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { AccessRole } from "zaehlerfreunde-proto-types/permissions_pb";
import {
  AddUserToSpaceRequest,
  GetSpaceAccessRolesRequest,
  GetSpacesRequest,
  Pagination,
} from "zaehlerfreunde-central/space_service_pb";

import { getSpaceAddress } from "@/utils/space-utils";
import { spaceServiceClient } from "@/config/service-clients";

@Component
export default class AddSpaceToUserDialog extends Vue {
  userId: string;
  itemsPerPage: number = 10;
  page: number = 1;
  search: string = "";

  addToSpaceCall: RemoteCall<UserError> = initialized;
  selectedSpaces: Space[] | null = null;
  spaces: RemoteData<UserError, Space[]> = initialized;
  @Prop() spacesData: RemoteData<UserError, Space[]>;
  alreadyAddedSpaces: string[] = [];

  selectedAccessRole: string | null = null;

  spaceAccessRoles: RemoteData<UserError, AccessRole[]> = initialized;

  get spaceAccessRoleOptions(): { text: string; value: string }[] {
    return this.spaceAccessRoles.list.map((role) => ({
      text: role.getName(),
      value: role.getId(),
    }));
  }

  spaceName(space: Space): string {
    return space.getName() + ", " + getSpaceAddress(space);
  }

  spaceValue(space: Space): Space {
    return space;
  }

  spaceIcon(space: Space): string {
    return space.getCategoryIcon();
  }

  async loadSpaces(): Promise<void> {
    const request = new GetSpacesRequest();
    request.setPartnerSpaces(true);
    request.setPagination(new Pagination().setPageSize(this.itemsPerPage).setPage(this.page - 1));
    if (this.search != "") {
      request.setFilter(this.search);
    }

    request.setAvoidSpaceIdsList(this.alreadyAddedSpaces);

    try {
      const response = await spaceServiceClient.getSpaces(request, {});
      this.spaces = success(response.getSpacesList());
      if (this.selectedSpaces) {
        this.selectedSpaces.forEach((selectedSpace) => {
          let selectedSpacePresent = false;
          this.spaces.data?.forEach((space) => {
            if (space.getId() == selectedSpace.getId()) {
              selectedSpacePresent = true;
            }
          });
          if (!selectedSpacePresent) {
            this.spaces.data?.push(selectedSpace);
          }
        });
      }
    } catch (error) {
      this.spaces = failure(userErrorFrom(error));
    }
  }

  async mounted(): Promise<void> {
    this.userId = this.$route.params.userId;

    this.spacesData.data?.forEach((space) => {
      this.alreadyAddedSpaces.push(space.getId());
    });

    try {
      this.spaceAccessRoles = pending;
      const response = await spaceServiceClient.getSpaceAccessRoles(new GetSpaceAccessRolesRequest(), {});
      this.spaceAccessRoles = success(response.getAccessRolesList());
    } catch (error) {
      this.spaceAccessRoles = failure(userErrorFrom(error));
    }
    await this.loadSpaces();
  }

  async onSaveClicked(): Promise<void> {
    this.addToSpaceCall = pending;

    const request = new AddUserToSpaceRequest();

    request.setUserIdsList([this.userId]);

    if (this.selectedSpaces) {
      let spaces: string[] = [];
      this.selectedSpaces.forEach((space) => {
        spaces.push(space.getId());
      });

      request.setSpaceIdsList(spaces);

      if (this.selectedAccessRole) {
        request.setAccessRole(this.selectedAccessRole);
      }
    }

    await spaceServiceClient.addUserToSpace(request, {});

    this.addToSpaceCall = success(void 0);

    this.$emit("space-connected");
  }
  catch(error) {
    this.addToSpaceCall = failure(userErrorFrom(error));
  }
}
