export interface EnvironmentConfig {
  id: Environment;
  domain: string;
  audience: string;
  serviceUrl: string;
  datadogEnabled: boolean;
  datadogApplicationId?: string;
  datadogClientToken?: string;
  isProd: boolean;
}

export enum Environment {
  dev = "dev",
  stage = "stage",
  prod = "prod",
}

const environmentConfigs: { [id in Environment]: EnvironmentConfig } = {
  dev: {
    id: Environment.dev,
    // domain: "auth.dev.zaehlerfreunde.com",
    domain: "https://zaehlerfreunde-dev.eu.auth0.com",
    audience: "https://api.dev.zaehlerfreunde.com",
    serviceUrl: "http://localhost:10000",
    datadogEnabled: false,
    datadogApplicationId: "",
    datadogClientToken: "",
    isProd: false,
  },
  stage: {
    id: Environment.stage,
    // domain: "auth.stage.zaehlerfreunde.com",
    domain: "https://zaehlerfreunde-stage.eu.auth0.com",
    audience: "https://api.stage.zaehlerfreunde.com",
    serviceUrl: "https://api.stage.zaehlerfreunde.com:443",
    datadogEnabled: true,
    datadogApplicationId: "7eacc562-a41f-4a1f-9b89-717056a5c035",
    datadogClientToken: "pub49e4712096b09ad3e0f90555a1860919",
    isProd: false,
  },
  prod: {
    id: Environment.prod,
    domain: "https://auth.prod.zaehlerfreunde.com",
    audience: "https://api.prod.zaehlerfreunde.com",
    serviceUrl: "https://api.prod.zaehlerfreunde.com:443",
    datadogEnabled: true,
    datadogApplicationId: "2921a4e9-97ac-4073-97fa-50ac324db10f",
    datadogClientToken: "pub32afc280818d50d2d1e49b494f580776",
    isProd: true,
  },
};

export function getEnvironment(): EnvironmentConfig {
  const urlParams = new URLSearchParams(window.location.search);
  const environmentParam = urlParams.get("zf_env");
  let urlParamEnvironment: EnvironmentConfig | null = null;
  if (environmentParam) {
    urlParamEnvironment = environmentConfigs[environmentParam];
  }

  const storedEnvironment = localStorage.getItem("environment");
  let storedEnvironmentConfig: EnvironmentConfig | null = null;
  if (storedEnvironment) {
    storedEnvironmentConfig = environmentConfigs[storedEnvironment];
  }

  const isInternal = window.location.hostname.includes("internal");
  const isLocalhost = window.location.hostname.includes("localhost");

  if ((isInternal || isLocalhost) && urlParamEnvironment) {
    // We need to check if the overwritten env changed and reset it
    if (storedEnvironment && environmentParam != storedEnvironment) {
      localStorage.clear();
      deleteAllCookies();
    }

    console.log("Env: " + environmentParam);
    localStorage.setItem("environment", environmentParam ?? "");
    console.log("Storing env :" + localStorage.getItem("environment"));

    return urlParamEnvironment;
  } else if ((isInternal || isLocalhost) && storedEnvironmentConfig) {
    console.log("Loading env from local storage");
    console.log("Env: " + storedEnvironment);
    return storedEnvironmentConfig;
  } else if (isLocalhost) {
    console.log("Env: dev");
    return environmentConfigs[Environment.dev];
  } else if (isInternal) {
    console.log("Env: stage");
    return environmentConfigs[Environment.stage];
  } else {
    return environmentConfigs[Environment.prod];
  }
}

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
