
import { themeModule } from "@/store/modules/theme";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Tip } from "zaehlerfreunde-proto-types/tips_pb";
import TipsCarouselPage from "./TipsCarouselPage.vue";

export function getTipsPerPage(): number {
  if (window.innerWidth > 900) {
    return 3;
  } else if (window.innerWidth > 600) {
    return 2;
  } else {
    return 1;
  }
}

@Component({
  components: {
    TipsCarouselPage,
  },
})
export default class TipsSlides extends Vue {
  @Prop() title: string;
  @Prop() description: string;
  @Prop() tips: Tip[];

  @themeModule.Getter apperance: "light" | "dark";

  tipsPerPage: number = getTipsPerPage();

  resizeEventHandler(): void {
    this.tipsPerPage = getTipsPerPage();
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  get tipsPages(): Tip[][] {
    var pages: Tip[][] = [];

    for (var i = 0; i < this.tips.length; i += this.tipsPerPage) {
      pages.push(this.tips.slice(i, i + this.tipsPerPage));

      if (pages.length > 5) {
        break;
      }
    }

    return pages;
  }
}
