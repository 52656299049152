
import { tariffServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import {
  failure,
  initialized,
  pending,
  RemoteCall,
  RemoteData,
  success,
  successOptional,
} from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { getMediumName } from "@/utils/device-utils";
import { Vue, Component, Watch } from "vue-property-decorator";
import { DeleteTariffRequest, GetTariffRequest, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import PriceComponents from "./PriceComponents.vue";
import TariffContracts from "./TariffContracts.vue";

interface EditableDetails {
  name: string;
  supplier: string;
  isGreen: boolean;
}

@Component({
  components: {
    PriceComponents,
    TariffContracts,
  },
})
export default class TariffDetails extends Vue {
  tariff: RemoteData<UserError, Tariff> = initialized;
  deleteTariffCall: RemoteCall<UserError> = initialized;
  tab = 0;
  showEditDetailsDialog = false;
  showDeleteTariffDialog = false;
  editableDetails: EditableDetails = {
    name: "",
    supplier: "",
    isGreen: true,
  };

  success = success;

  @Watch("tab")
  onTabChanged() {
    this.$router.replace({
      path: this.$route.path,
      query: { tab: this.tab.toString() },
    });
  }

  mounted(): void {
    this.getTariff();
    const tab = this.$route.query["tab"] as string;

    if (tab) {
      this.tab = parseInt(tab);
    }
  }

  showEditDialog() {
    const tariff = this.tariff.data;

    if (tariff) {
      this.editableDetails = {
        name: tariff.getName(),
        supplier: tariff.getSupplier(),
        isGreen: tariff.getIsGreen(),
      };
      this.showEditDetailsDialog = true;
    }
  }

  get details(): { key: string; value: string }[] {
    const tariff = this.tariff.data;

    if (tariff) {
      return [
        {
          key: "Lieferant",
          value: tariff.getSupplier(),
        },
        {
          key: "Sparte",
          value: getMediumName(tariff.getMedium()),
        },
        {
          key: "Ökotarif",
          value: tariff.getIsGreen() ? "Ja" : "Nein",
        },
      ];
    } else {
      return [];
    }
  }

  async getTariff(): Promise<void> {
    const tariffId = this.$route.params["tariffId"] as string;
    const request = new GetTariffRequest();
    request.setTariffId(tariffId);

    try {
      this.tariff = pending;
      const response = await tariffServiceClient.getTariff(request, {});
      this.tariff = successOptional(response.getTariff());
    } catch (error) {
      this.tariff = failure(userErrorFrom(error));
    }
  }

  async updateTariffDetails(): Promise<void> {
    const tariff = this.tariff.data;

    if (tariff) {
      tariff.setName(this.editableDetails.name);
      tariff.setSupplier(this.editableDetails.supplier);
      tariff.setIsGreen(this.editableDetails.isGreen);

      const request = new UpdateTariffRequest();
      request.setTariff(tariff);

      try {
        this.tariff = pending;
        const response = await tariffServiceClient.updateTariff(request, {});
        this.tariff = successOptional(response.getTariff());
        this.showEditDetailsDialog = false;
      } catch (error) {
        this.tariff = failure(userErrorFrom(error));
      }
    }
  }

  async deleteTariff() {
    const tariffId = this.tariff.data?.getId();

    if (tariffId) {
      const request = new DeleteTariffRequest();
      request.setTariffId(tariffId);

      try {
        this.deleteTariffCall = pending;
        await tariffServiceClient.deleteTariff(request, {});
        this.deleteTariffCall = success(void 0);

        this.showDeleteTariffDialog = false;
        this.goBackToTariffs();
      } catch (error) {
        this.deleteTariffCall = failure(userErrorFrom(error));
      }
    }
  }

  goBackToTariffs(): void {
    this.$router.replace(paths.admin.tariffs);
  }
}
