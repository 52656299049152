
import { deviceServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { pending, success, failure, RemoteCall, initialized } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  AuxiliusRegistrationDetails,
  DeviceRegistration,
  EdaRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";

@Component
export default class AuxiliusConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  meteringCode: string = "";

  meteringCodeRules = [
    (mp: string) => (mp.startsWith("DE") ? true : "Zählcodenummer muss mit DE beginnen."),
    (mp: string) => (mp.length === 33 ? true : "Zählcodenummer muss 33 Zeichen lang sein."),
  ];

  get isMeteringCodeValid(): boolean {
    return this.meteringCodeRules.every((r) => r(this.meteringCode) === true);
  }

  async onNextClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new AuxiliusRegistrationDetails();
    details.setMeteringCode(this.meteringCode);
    registration.setAuxilius(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;
    try {
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
