import { UserError, userErrorFrom } from "@/types/user-error";
import { partnerServiceClient } from "@/config/service-clients";
import { namespace } from "vuex-class";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { GetPartnerThemeRequest } from "zaehlerfreunde-central/partner_service_pb";
import { PartnerLogo, PartnerTheme } from "zaehlerfreunde-proto-types/partners_pb";
import store from "..";
import { failure, pending, RemoteCall, success } from "../utils/remote-data";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "theme",
})
class ThemeModule extends VuexModule {
  partnerTheme: PartnerTheme | null = null;
  partnerThemeCall: RemoteCall<UserError>;

  preferredApperance: "light" | "dark" = "light";

  get apperance(): "light" | "dark" {
    if (this.partnerTheme?.getAllowDarkMode()) {
      return this.preferredApperance;
    } else {
      return "light";
    }
  }

  get partnerLogo(): PartnerLogo | null {
    if (this.apperance === "light" && this.partnerTheme?.getLogoLight()) {
      return this.partnerTheme.getLogoLight() ?? null;
    } else if (this.apperance === "dark" && this.partnerTheme?.getLogoDark()) {
      return this.partnerTheme.getLogoDark() ?? null;
    } else {
      return this.partnerTheme?.getLogoLight() ?? this.partnerTheme?.getLogoDark() ?? null;
    }
  }

  @Action
  async getPartnerTheme() {
    try {
      this.setPartnerThemeCall(pending);
      const request = new GetPartnerThemeRequest();
      const response = await partnerServiceClient.getPartnerTheme(request, {});
      const theme = response.getPartnerTheme();

      if (theme) {
        this.setPartnerThemeCall(success(void 0));
        this.setPartnerTheme(theme);
      } else {
        this.setPartnerThemeCall(failure(userErrorFrom("Partnerdesign konnte nicht geladen werden")));
      }
    } catch (error) {
      this.setPartnerThemeCall(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setPartnerTheme(partnerTheme: PartnerTheme) {
    this.partnerTheme = partnerTheme;
  }

  @Mutation
  setPartnerThemeCall(call: RemoteCall<UserError>) {
    this.partnerThemeCall = call;
  }

  @Mutation
  setPreferredApperance(apperance: "light" | "dark") {
    this.preferredApperance = apperance;
  }
}

export const themeModule = namespace("theme");
export default getModule(ThemeModule);
