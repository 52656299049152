
import { Component, Vue } from "vue-property-decorator";
import TariffSearchResultsTable from "./TariffSearchResultsTable.vue";
import NoTariffFound from "./NoTariffFound.vue";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { tariffModule } from "@/store/modules/tariff";
import { SearchResult, TariffSearchInfo, TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";

@Component({
  components: {
    TariffSearchResultsTable,
    NoTariffFound,
  },
})
export default class TariffSearchResults extends Vue {
  @tariffModule.Getter tariffSearchResults: RemoteData<UserError, SearchResult[]>;
  @tariffModule.Getter pendingTariffSwitch: RemoteData<UserError, TariffSwitch | null>;
  @tariffModule.Getter tariffSearchInfo: RemoteData<UserError, TariffSearchInfo>;

  get watchdogEnabled(): boolean {
    return !!this.tariffSearchInfo.data?.getIsWatchdogEnabled();
  }
}
