
import { Vue, Component, Prop } from "vue-property-decorator";
import { Tip } from "zaehlerfreunde-proto-types/tips_pb";
import TipCarouselItem from "./TipCarouselItem.vue";

@Component({
  components: {
    TipCarouselItem,
  },
})
export default class TipsCarouselPage extends Vue {
  @Prop() tips: Tip[];
  @Prop() tipsPerPage: number;
}
