
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ConsumptionTargetProgress extends Vue {
  @Prop({ default: 0 }) targetPercentage: number;
  @Prop({ default: 0 }) target: number;

  get progressColor(): string {
    if (this.onTrack) {
      return "primary";
    } else {
      return "error";
    }
  }

  get formattedPercentage(): string {
    return this.targetPercentage.toLocaleString(window.navigator.language, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  }

  get onTrack(): boolean {
    const currentDay = new Date(Date.now());
    const lastDayOfMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0);
    const percentageOfMonth = (currentDay.getDate() * 100) / lastDayOfMonth.getDate();
    return percentageOfMonth >= this.targetPercentage;
  }
}
