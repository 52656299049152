
import { Vue, Component, Watch } from "vue-property-decorator";
import MetergridImport from "./metergrid/MetergridImport.vue";
import dataImports, { dataImportsModule } from "@/store/modules/data-imports";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import CsvImport from "./csv/CsvImport.vue";
import GridDataImport from "./grid-data/GridDataImport.vue";
import ImportBluemeteringData from "../../platform/import-data/import-bluemetering-data/ImportBluemeteringData.vue";
import moment from "moment";
import { paths } from "@/router/routes";
import MetiundoImportDialog from "./metiundo/MetiundoImportDialog.vue";
import { DataImport } from "zaehlerfreunde-proto-types/data_import_pb";
import SolarizeImportDialog from "./solarize/SolarizeImportDialog.vue";

@Component({
  components: {
    MetergridImport,
    CsvImport,
    ImportBluemeteringData,
    MetiundoImportDialog,
    GridDataImport,
    SolarizeImportDialog,
  },
})
export default class ImportData extends Vue {
  @dataImportsModule.State dataImports: RemoteData<UserError, DataImport[]>;

  showCsvImportDialog: boolean = false;
  showImportBluemeteringDataDialog: boolean = false;
  showMetiundoImportDialog: boolean = false;
  showGridDataImportDialog: boolean = false;
  showSolarizeImportDialog: boolean = false;

  paths = paths;

  @Watch("showCsvImportDialog")
  @Watch("showImportBluemeteringDataDialog")
  @Watch("showMetiundoImportDialog")
  @Watch("showGridDataImportDialog")
  onShowCsvImportDialogChanged(): void {
    dataImports.getDataImports();
  }

  mounted() {
    dataImports.getDataImports();
  }

  onRefreshDataImportsClicked(): void {
    dataImports.getDataImports();
  }

  headers = [
    { text: "Zeit", value: "time" },
    { text: "Status", value: "status" },
    { text: "Art", value: "name" },
    { text: "Details", value: "details" },
  ];

  get items() {
    const imports = this.dataImports.data;
    if (imports !== null) {
      return imports.map((d) => ({
        time: moment.unix(d.getTime()).locale("de").fromNow(),
        status: this.getStatusName(d.getStatus()),
        name: d.getName(),
        details: d.getDetails(),
      }));
    } else {
      return [];
    }
  }

  getStatusName(status: DataImport.Status): string {
    switch (status) {
      case DataImport.Status.IMPORTING:
        return "Import aktiv";
      case DataImport.Status.FINISHED:
        return "Import durchgeführt";
      case DataImport.Status.FAILED:
        return "Import fehlgeschlagen";
      default:
        return "Unbekannter Status";
    }
  }
}
