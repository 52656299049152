
import { RemoteData, initializedFactory } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import moment from "moment";

export interface DeviceDetailsData {
  title: string;
  value: string;
}

@Component({
  components: {},
})
export default class DeviceDetails extends Vue {
  @Prop({ default: initializedFactory }) deviceInfo: RemoteData<UserError, Device>;

  get deviceDetails(): DeviceDetailsData[] {
    const list = [
      {
        title: "Status",
        value: this.getStatus(this.deviceInfo?.data?.getStatus()),
      },
      {
        title: "Hersteller",
        value: this.deviceInfo?.data?.getDeviceProviderInfo()?.getName() ?? "",
      },
      {
        title: "Typ",
        value: this.deviceInfo?.data?.getDeviceTypeInfo()?.getName() ?? "",
      },
      {
        title: "Medium",
        value: this.getMedium(this.deviceInfo?.data?.getMedium()) ?? "",
      },
      {
        title: "Letzter Messwert",
        value: this.getFormattedDate(this.deviceInfo?.data?.getLatestReadingTime()),
      },
      {
        title: "Erstellungszeit",
        value: this.getFormattedDate(this.deviceInfo?.data?.getCreatedAt()),
      },
    ];

    // Append metadata
    this.deviceInfo.data?.getMetaDataList().forEach((metaData) => {
      list.push({
        title: metaData.getLabel(),
        value: metaData.getValue(),
      });
    });
    return list;
  }

  getStatus(status: Device.Status | undefined): string {
    switch (status) {
      case Device.Status.CONNECTED:
        return "Connected";
      case Device.Status.CONNECTING:
        return "Connecting";
      case Device.Status.FAILED:
        return "Failed";
      default:
        return "Unknown Status";
    }
  }

  getMedium(medium: Device.Medium | undefined): string {
    switch (medium) {
      case Device.Medium.ELECTRICITY:
        return "Electricity";
      case Device.Medium.GAS:
        return "Gas";
      case Device.Medium.HEATING:
        return "Heating";
      case Device.Medium.WATER:
        return "Water";
      default:
        return "Unknown Medium";
    }
  }

  getFormattedDate(date: number | undefined): string {
    if (date == undefined || date == 0) {
      return "-";
    } else {
      return moment.unix(date).locale("de").format("DD.MM.YYYY HH:mm");
    }
  }
}
