
import { Component, Vue } from "vue-property-decorator";
import FeaturePage, { Feature } from "./FeaturePage.vue";

@Component({
  components: {
    FeaturePage,
  },
})
export default class MeterToTenantFeatures extends Vue {
  features: Feature[] = [
    {
      text: this.$t("whiteLabelFeaturesPage.landlordToTenant.functionalities.text"),
      image: "landlord-to-tenant.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.crossPlatform.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.crossPlatform.text"),
      image: "cross-platform.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.smartCharging.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.smartCharging.text"),
      image: "smart-charging.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.multipleDevices.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.multipleDevices.text"),
      image: "multiple-devices.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.adminPortal.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.adminPortal.text"),
      image: "admin-portal.png",
    },
    {
      title: this.$t("whiteLabelFeaturesPage.utilities.reporting.title"),
      text: this.$t("whiteLabelFeaturesPage.utilities.reporting.text"),
      image: "report.png",
    },
  ];
}
