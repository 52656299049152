
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { MetiundoMeteringPoint } from "zaehlerfreunde-proto-types/device_registration_pb";

interface TableItem {
  id: string;
  name: string;
  melo: string;
  address: string;
  selected: boolean;
  alreadyRegistered: boolean;
}

@Component
export default class MeteringPointsTable extends Vue {
  @Prop({ default: false }) multiSelect: boolean;
  @Prop() meteringPoints: MetiundoMeteringPoint[];

  headers = [
    { text: "", value: "selected" },
    { text: "Name", value: "name" },
    { text: "MeLo", value: "melo" },
    { text: "Addresse", value: "address" },
  ];

  items: TableItem[] = [];

  onMeteringPointSelected(item: TableItem, selected: boolean): void {
    if (this.multiSelect) {
      this.$emit(
        "selection-changed",
        this.items.filter((i) => i.selected).map((i) => i.id)
      );
    } else {
      if (selected) {
        this.items = this.items.map((i) => ({
          ...i,
          selected: i === item,
        }));
        this.$emit("selection-changed", item.id);
      } else {
        this.$emit("selection-changed", null);
      }
    }
  }

  @Watch("meteringPoints")
  onMeteringPointsChanged(): void {
    this.items = this.meteringPoints.map((m) => ({
      id: m.getId(),
      name: m.getName(),
      melo: m.getMelo(),
      address: m.getAddress(),
      selected: false,
      alreadyRegistered: m.getAlreadyRegistered(),
    }));
  }
}
