
import { partnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BitShakeCommonInstructions extends Vue {
  @partnerModule.Getter supportEmail: string;
  @partnerModule.Getter partnerName: string;
  @Prop() isAppTab: boolean;
}
