
import DevicesTable from "@/components/devices-table/DevicesTable.vue";
import { deviceServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { GetUserDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {
    DevicesTable,
  },
})
export default class UserDevices extends Vue {
  devicesList: RemoteData<UserError, Device[]> = initialized;

  async mounted(): Promise<void> {
    this.devicesList = pending;

    const getUserDevicesRequest = new GetUserDevicesRequest();
    getUserDevicesRequest.setUserId(this.$route.params.userId);

    try {
      const response = await deviceServiceClient.getUserDevices(getUserDevicesRequest, {});
      this.devicesList = success(response.getDevicesList());
    } catch (error) {
      this.devicesList = failure(userErrorFrom(error));
    }
  }
  handleRowClick(device): void {
    this.$router.push({ path: `${paths.admin.devices}/${device.id}` });
  }
}
