
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UserError, userErrorFrom } from "@/types/user-error";
import { GetLandlordToTenantProjectMetersAndSpacesRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import DeleteLandlordToTenantMeterDialog from "./Dialogs/DeleteLandlordToTenantMeterDialog.vue";
import ProjectMetersTable, {
  ProjectMeterTable,
} from "@/components/landlord-tenants-project-table/ProjectMetersTable.vue";
import ProjectSpacesTable, {
  ProjectSpaceTable,
} from "@/components/landlord-tenants-project-table/ProjectSpacesTable.vue";
import AddLandlordToTenantMeterDialog from "./Dialogs/AddLandlordToTenantMeterDialog.vue";
import AddLandlordToTenantSpaceDialog from "./Dialogs/AddLandlordToTenantSpaceDialog.vue";
import DeleteLandlordToTenantSpaceDialog from "./Dialogs/DeleteLandlordToTenantSpaceDialog.vue";
import {
  LandlordToTenantProjectMeter,
  LandlordToTenantProjectSpace,
} from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { paths } from "@/router/routes";
@Component({
  components: {
    ProjectMetersTable,
    DeleteLandlordToTenantMeterDialog,
    AddLandlordToTenantMeterDialog,
    ProjectSpacesTable,
    AddLandlordToTenantSpaceDialog,
    DeleteLandlordToTenantSpaceDialog,
  },
})
export default class SingleProjectDevices extends Vue {
  @Prop({ default: null }) projectId: string | null;
  projectMeters: RemoteData<UserError, LandlordToTenantProjectMeter[]> = initialized;
  projectSpaces: RemoteData<UserError, LandlordToTenantProjectSpace[]> = initialized;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  deleteMeterDialog: boolean = false;
  deleteSpaceDialog: boolean = false;
  addLandlordToTenantMeterDialog: boolean = false;
  addLandlordToTenantSpaceDialog: boolean = false;
  selectedMeterId: string | null = null;
  selectedSpaceId: string | null = null;
  selectedDeviceId: string | null = null;
  deviceName: string | null = null;
  spaceName: string | null = null;
  itemControls = [
    {
      icon: "mdi-trash-can-outline",
      event: "delete-meter",
    },
  ];
  headerControls = [
    {
      icon: "mdi-plus-circle-outline",
      event: "add-meter",
    },
  ];

  itemSpaceControls = [
    {
      icon: "mdi-trash-can-outline",
      event: "delete-space",
    },
  ];
  headerSpaceControls = [
    {
      icon: "mdi-plus-circle-outline",
      event: "add-space",
    },
  ];
  @Watch("projectId")
  onProjectIdUpdated(): void {
    this.getProjectMeters();
  }

  mounted(): void {
    this.getProjectMeters();
  }

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    this.$router.push(paths.admin.devices);
  }

  async getProjectMeters(): Promise<void> {
    this.deleteMeterDialog = false;
    this.deleteSpaceDialog = false;
    this.addLandlordToTenantMeterDialog = false;
    this.addLandlordToTenantSpaceDialog = false;
    this.projectMeters = pending;
    const request = new GetLandlordToTenantProjectMetersAndSpacesRequest();
    if (this.projectId) {
      request.setProjectId(this.projectId);

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      try {
        const response = await landlordToTenantServiceClient.getLandlordToTenantProjectMetersAndSpaces(request, {});
        this.projectMeters = success(response.getMetersList());
        this.projectSpaces = success(response.getSpacesList());
      } catch (error) {
        this.projectMeters = failure(userErrorFrom(error));
        this.projectSpaces = failure(userErrorFrom(error));
      }
    }
  }

  deleteSelectedMeter(meter: ProjectMeterTable): void {
    this.deleteMeterDialog = true;
    this.selectedMeterId = meter.id;
    if (meter.type == "VIRTUAL") {
      this.selectedDeviceId = meter.deviceId;
      this.deviceName = meter.name;
    } else {
      this.selectedDeviceId = null;
      this.deviceName = null;
    }
  }

  deleteSelectedSpace(space: ProjectSpaceTable): void {
    console.log("hello", space);

    this.selectedSpaceId = space.id;
    this.spaceName = space.name;
    this.deleteSpaceDialog = true;
  }
}
