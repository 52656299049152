
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UserError, userErrorFrom } from "@/types/user-error";
import devices, { devicesModule } from "@/store/modules/devices";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { DeviceRegistration, GoERegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { RemoteCall, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { deviceServiceClient } from "@/config/service-clients";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";

@Component
export default class GoEConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;

  serialNumber: string = "";
  apiToken: string = "";
  apiVersion = GoERegistrationDetails.APIVersion.V2;
  apiVersionV2 = GoERegistrationDetails.APIVersion.V2;

  versions: { text: string; value: GoERegistrationDetails.APIVersion }[] = [
    { text: "V2", value: GoERegistrationDetails.APIVersion.V1 },
    { text: "V3 oder neuer", value: GoERegistrationDetails.APIVersion.V2 },
  ];

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoCompleted(): boolean {
    return !!this.apiToken && (this.apiVersion != this.apiVersionV2 || !!this.serialNumber);
  }

  async onConnectMeterClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new GoERegistrationDetails();
    details.setApiToken(this.apiToken);
    details.setSerialNumber(this.serialNumber);
    details.setApiVersion(this.apiVersion);
    registration.setGoE(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;
    try {
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
