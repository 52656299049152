// source: services/central/advisory_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_datetime_pb = require('../../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
var types_tips_pb = require('../../types/tips_pb.js');
goog.object.extend(proto, types_tips_pb);
var types_simulations_pb = require('../../types/simulations_pb.js');
goog.object.extend(proto, types_simulations_pb);
var types_automations_pb = require('../../types/automations_pb.js');
goog.object.extend(proto, types_automations_pb);
var types_pagination_pb = require('../../types/pagination_pb.js');
goog.object.extend(proto, types_pagination_pb);
var types_device_pb = require('../../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
var types_reporting_pb = require('../../types/reporting_pb.js');
goog.object.extend(proto, types_reporting_pb);
goog.exportSymbol('proto.services.central.AddAutomationRequest', null, global);
goog.exportSymbol('proto.services.central.AddAutomationResponse', null, global);
goog.exportSymbol('proto.services.central.CreateReportScheduleRequest', null, global);
goog.exportSymbol('proto.services.central.CreateReportScheduleResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteAutomationRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteAutomationResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteReportScheduleRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteReportScheduleResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteSimulationRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteSimulationResponse', null, global);
goog.exportSymbol('proto.services.central.EditReportScheduleRequest', null, global);
goog.exportSymbol('proto.services.central.EditReportScheduleResponse', null, global);
goog.exportSymbol('proto.services.central.GetAutomationDetailRequest', null, global);
goog.exportSymbol('proto.services.central.GetAutomationDetailResponse', null, global);
goog.exportSymbol('proto.services.central.GetAutomationDispatchedActionsRequest', null, global);
goog.exportSymbol('proto.services.central.GetAutomationDispatchedActionsResponse', null, global);
goog.exportSymbol('proto.services.central.GetAutomationsRequest', null, global);
goog.exportSymbol('proto.services.central.GetAutomationsResponse', null, global);
goog.exportSymbol('proto.services.central.GetDispatchedActionsRequest', null, global);
goog.exportSymbol('proto.services.central.GetDispatchedActionsResponse', null, global);
goog.exportSymbol('proto.services.central.GetEnergyConsumersRequest', null, global);
goog.exportSymbol('proto.services.central.GetEnergyConsumersResponse', null, global);
goog.exportSymbol('proto.services.central.GetReportContentRequest', null, global);
goog.exportSymbol('proto.services.central.GetReportContentResponse', null, global);
goog.exportSymbol('proto.services.central.GetReportMetricsRequest', null, global);
goog.exportSymbol('proto.services.central.GetReportMetricsResponse', null, global);
goog.exportSymbol('proto.services.central.GetReportScheduleFrequenciesRequest', null, global);
goog.exportSymbol('proto.services.central.GetReportScheduleFrequenciesResponse', null, global);
goog.exportSymbol('proto.services.central.GetReportSchedulesRequest', null, global);
goog.exportSymbol('proto.services.central.GetReportSchedulesResponse', null, global);
goog.exportSymbol('proto.services.central.GetReportsRequest', null, global);
goog.exportSymbol('proto.services.central.GetReportsResponse', null, global);
goog.exportSymbol('proto.services.central.GetSimulationsRequest', null, global);
goog.exportSymbol('proto.services.central.GetSimulationsResponse', null, global);
goog.exportSymbol('proto.services.central.GetTipsRequest', null, global);
goog.exportSymbol('proto.services.central.GetTipsResponse', null, global);
goog.exportSymbol('proto.services.central.LoadMiniPVSimulationRequest', null, global);
goog.exportSymbol('proto.services.central.LoadMiniPVSimulationResponse', null, global);
goog.exportSymbol('proto.services.central.RetryDispatchedActionRequest', null, global);
goog.exportSymbol('proto.services.central.RetryDispatchedActionResponse', null, global);
goog.exportSymbol('proto.services.central.RunMiniPVSimulationRequest', null, global);
goog.exportSymbol('proto.services.central.RunMiniPVSimulationResponse', null, global);
goog.exportSymbol('proto.services.central.SetEnergyConsumersRequest', null, global);
goog.exportSymbol('proto.services.central.SetEnergyConsumersResponse', null, global);
goog.exportSymbol('proto.services.central.TrackTipInteractionRequest', null, global);
goog.exportSymbol('proto.services.central.TrackTipInteractionResponse', null, global);
goog.exportSymbol('proto.services.central.TriggerAutomationRequest', null, global);
goog.exportSymbol('proto.services.central.TriggerAutomationResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateAutomationRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateAutomationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.RunMiniPVSimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.RunMiniPVSimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.RunMiniPVSimulationRequest.displayName = 'proto.services.central.RunMiniPVSimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.RunMiniPVSimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.RunMiniPVSimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.RunMiniPVSimulationResponse.displayName = 'proto.services.central.RunMiniPVSimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSimulationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSimulationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSimulationsRequest.displayName = 'proto.services.central.GetSimulationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSimulationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetSimulationsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetSimulationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSimulationsResponse.displayName = 'proto.services.central.GetSimulationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LoadMiniPVSimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.LoadMiniPVSimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LoadMiniPVSimulationRequest.displayName = 'proto.services.central.LoadMiniPVSimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.LoadMiniPVSimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.LoadMiniPVSimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.LoadMiniPVSimulationResponse.displayName = 'proto.services.central.LoadMiniPVSimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTipsRequest.displayName = 'proto.services.central.GetTipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetTipsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetTipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTipsResponse.displayName = 'proto.services.central.GetTipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TrackTipInteractionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TrackTipInteractionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TrackTipInteractionRequest.displayName = 'proto.services.central.TrackTipInteractionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TrackTipInteractionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TrackTipInteractionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TrackTipInteractionResponse.displayName = 'proto.services.central.TrackTipInteractionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetEnergyConsumersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetEnergyConsumersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetEnergyConsumersRequest.displayName = 'proto.services.central.GetEnergyConsumersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetEnergyConsumersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetEnergyConsumersResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetEnergyConsumersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetEnergyConsumersResponse.displayName = 'proto.services.central.GetEnergyConsumersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetEnergyConsumersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.SetEnergyConsumersRequest.repeatedFields_, null);
};
goog.inherits(proto.services.central.SetEnergyConsumersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetEnergyConsumersRequest.displayName = 'proto.services.central.SetEnergyConsumersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetEnergyConsumersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetEnergyConsumersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetEnergyConsumersResponse.displayName = 'proto.services.central.SetEnergyConsumersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteSimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteSimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteSimulationRequest.displayName = 'proto.services.central.DeleteSimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteSimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteSimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteSimulationResponse.displayName = 'proto.services.central.DeleteSimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddAutomationRequest.displayName = 'proto.services.central.AddAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddAutomationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddAutomationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddAutomationResponse.displayName = 'proto.services.central.AddAutomationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteAutomationRequest.displayName = 'proto.services.central.DeleteAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteAutomationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteAutomationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteAutomationResponse.displayName = 'proto.services.central.DeleteAutomationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAutomationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationsRequest.displayName = 'proto.services.central.GetAutomationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAutomationsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAutomationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationsResponse.displayName = 'proto.services.central.GetAutomationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDispatchedActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetDispatchedActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDispatchedActionsRequest.displayName = 'proto.services.central.GetDispatchedActionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetDispatchedActionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetDispatchedActionsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetDispatchedActionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetDispatchedActionsResponse.displayName = 'proto.services.central.GetDispatchedActionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.RetryDispatchedActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.RetryDispatchedActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.RetryDispatchedActionRequest.displayName = 'proto.services.central.RetryDispatchedActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.RetryDispatchedActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.RetryDispatchedActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.RetryDispatchedActionResponse.displayName = 'proto.services.central.RetryDispatchedActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAutomationDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationDetailRequest.displayName = 'proto.services.central.GetAutomationDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAutomationDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAutomationDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationDetailResponse.displayName = 'proto.services.central.GetAutomationDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationDispatchedActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetAutomationDispatchedActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationDispatchedActionsRequest.displayName = 'proto.services.central.GetAutomationDispatchedActionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAutomationDispatchedActionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAutomationDispatchedActionsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAutomationDispatchedActionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAutomationDispatchedActionsResponse.displayName = 'proto.services.central.GetAutomationDispatchedActionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateAutomationRequest.displayName = 'proto.services.central.UpdateAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateAutomationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateAutomationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateAutomationResponse.displayName = 'proto.services.central.UpdateAutomationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TriggerAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TriggerAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TriggerAutomationRequest.displayName = 'proto.services.central.TriggerAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.TriggerAutomationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.TriggerAutomationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.TriggerAutomationResponse.displayName = 'proto.services.central.TriggerAutomationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportsRequest.displayName = 'proto.services.central.GetReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportsResponse.displayName = 'proto.services.central.GetReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportSchedulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReportSchedulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportSchedulesRequest.displayName = 'proto.services.central.GetReportSchedulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportSchedulesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetReportSchedulesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetReportSchedulesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportSchedulesResponse.displayName = 'proto.services.central.GetReportSchedulesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.CreateReportScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.CreateReportScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.CreateReportScheduleRequest.displayName = 'proto.services.central.CreateReportScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.CreateReportScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.CreateReportScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.CreateReportScheduleResponse.displayName = 'proto.services.central.CreateReportScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReportContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportContentRequest.displayName = 'proto.services.central.GetReportContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReportContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportContentResponse.displayName = 'proto.services.central.GetReportContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportScheduleFrequenciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReportScheduleFrequenciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportScheduleFrequenciesRequest.displayName = 'proto.services.central.GetReportScheduleFrequenciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportScheduleFrequenciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetReportScheduleFrequenciesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetReportScheduleFrequenciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportScheduleFrequenciesResponse.displayName = 'proto.services.central.GetReportScheduleFrequenciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetReportMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportMetricsRequest.displayName = 'proto.services.central.GetReportMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetReportMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetReportMetricsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetReportMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetReportMetricsResponse.displayName = 'proto.services.central.GetReportMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.EditReportScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.EditReportScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.EditReportScheduleRequest.displayName = 'proto.services.central.EditReportScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.EditReportScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.EditReportScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.EditReportScheduleResponse.displayName = 'proto.services.central.EditReportScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteReportScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteReportScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteReportScheduleRequest.displayName = 'proto.services.central.DeleteReportScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteReportScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteReportScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteReportScheduleResponse.displayName = 'proto.services.central.DeleteReportScheduleResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.RunMiniPVSimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.RunMiniPVSimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RunMiniPVSimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    installation: (f = msg.getInstallation()) && types_simulations_pb.MiniPVInstallation.toObject(includeInstance, f),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    useDefaultLoadProfile: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    yearlyConsumption: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.RunMiniPVSimulationRequest}
 */
proto.services.central.RunMiniPVSimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.RunMiniPVSimulationRequest;
  return proto.services.central.RunMiniPVSimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.RunMiniPVSimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.RunMiniPVSimulationRequest}
 */
proto.services.central.RunMiniPVSimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_simulations_pb.MiniPVInstallation;
      reader.readMessage(value,types_simulations_pb.MiniPVInstallation.deserializeBinaryFromReader);
      msg.setInstallation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseDefaultLoadProfile(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearlyConsumption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.RunMiniPVSimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.RunMiniPVSimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RunMiniPVSimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstallation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_simulations_pb.MiniPVInstallation.serializeBinaryToWriter
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUseDefaultLoadProfile();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional types.MiniPVInstallation installation = 1;
 * @return {?proto.types.MiniPVInstallation}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.getInstallation = function() {
  return /** @type{?proto.types.MiniPVInstallation} */ (
    jspb.Message.getWrapperField(this, types_simulations_pb.MiniPVInstallation, 1));
};


/**
 * @param {?proto.types.MiniPVInstallation|undefined} value
 * @return {!proto.services.central.RunMiniPVSimulationRequest} returns this
*/
proto.services.central.RunMiniPVSimulationRequest.prototype.setInstallation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.RunMiniPVSimulationRequest} returns this
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.clearInstallation = function() {
  return this.setInstallation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.hasInstallation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.RunMiniPVSimulationRequest} returns this
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool use_default_load_profile = 3;
 * @return {boolean}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.getUseDefaultLoadProfile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.RunMiniPVSimulationRequest} returns this
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.setUseDefaultLoadProfile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 yearly_consumption = 4;
 * @return {number}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.getYearlyConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.RunMiniPVSimulationRequest} returns this
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.setYearlyConsumption = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.RunMiniPVSimulationRequest} returns this
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.clearYearlyConsumption = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.RunMiniPVSimulationRequest.prototype.hasYearlyConsumption = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.RunMiniPVSimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.RunMiniPVSimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.RunMiniPVSimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RunMiniPVSimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    simulationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.RunMiniPVSimulationResponse}
 */
proto.services.central.RunMiniPVSimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.RunMiniPVSimulationResponse;
  return proto.services.central.RunMiniPVSimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.RunMiniPVSimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.RunMiniPVSimulationResponse}
 */
proto.services.central.RunMiniPVSimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSimulationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.RunMiniPVSimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.RunMiniPVSimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.RunMiniPVSimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RunMiniPVSimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimulationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string simulation_id = 1;
 * @return {string}
 */
proto.services.central.RunMiniPVSimulationResponse.prototype.getSimulationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.RunMiniPVSimulationResponse} returns this
 */
proto.services.central.RunMiniPVSimulationResponse.prototype.setSimulationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSimulationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSimulationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSimulationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSimulationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSimulationsRequest}
 */
proto.services.central.GetSimulationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSimulationsRequest;
  return proto.services.central.GetSimulationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSimulationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSimulationsRequest}
 */
proto.services.central.GetSimulationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSimulationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSimulationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSimulationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSimulationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetSimulationsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSimulationsRequest} returns this
 */
proto.services.central.GetSimulationsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetSimulationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSimulationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSimulationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSimulationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSimulationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    simulationsList: jspb.Message.toObjectList(msg.getSimulationsList(),
    types_simulations_pb.Simulation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSimulationsResponse}
 */
proto.services.central.GetSimulationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSimulationsResponse;
  return proto.services.central.GetSimulationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSimulationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSimulationsResponse}
 */
proto.services.central.GetSimulationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_simulations_pb.Simulation;
      reader.readMessage(value,types_simulations_pb.Simulation.deserializeBinaryFromReader);
      msg.addSimulations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSimulationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSimulationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSimulationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSimulationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimulationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_simulations_pb.Simulation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.Simulation simulations = 1;
 * @return {!Array<!proto.types.Simulation>}
 */
proto.services.central.GetSimulationsResponse.prototype.getSimulationsList = function() {
  return /** @type{!Array<!proto.types.Simulation>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_simulations_pb.Simulation, 1));
};


/**
 * @param {!Array<!proto.types.Simulation>} value
 * @return {!proto.services.central.GetSimulationsResponse} returns this
*/
proto.services.central.GetSimulationsResponse.prototype.setSimulationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Simulation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Simulation}
 */
proto.services.central.GetSimulationsResponse.prototype.addSimulations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Simulation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetSimulationsResponse} returns this
 */
proto.services.central.GetSimulationsResponse.prototype.clearSimulationsList = function() {
  return this.setSimulationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LoadMiniPVSimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LoadMiniPVSimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LoadMiniPVSimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LoadMiniPVSimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    simulationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LoadMiniPVSimulationRequest}
 */
proto.services.central.LoadMiniPVSimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LoadMiniPVSimulationRequest;
  return proto.services.central.LoadMiniPVSimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LoadMiniPVSimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LoadMiniPVSimulationRequest}
 */
proto.services.central.LoadMiniPVSimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSimulationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LoadMiniPVSimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LoadMiniPVSimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LoadMiniPVSimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LoadMiniPVSimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimulationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string simulation_id = 1;
 * @return {string}
 */
proto.services.central.LoadMiniPVSimulationRequest.prototype.getSimulationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.LoadMiniPVSimulationRequest} returns this
 */
proto.services.central.LoadMiniPVSimulationRequest.prototype.setSimulationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.LoadMiniPVSimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.LoadMiniPVSimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.LoadMiniPVSimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LoadMiniPVSimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && types_simulations_pb.MiniPVSimulationResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.LoadMiniPVSimulationResponse}
 */
proto.services.central.LoadMiniPVSimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.LoadMiniPVSimulationResponse;
  return proto.services.central.LoadMiniPVSimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.LoadMiniPVSimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.LoadMiniPVSimulationResponse}
 */
proto.services.central.LoadMiniPVSimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_simulations_pb.MiniPVSimulationResult;
      reader.readMessage(value,types_simulations_pb.MiniPVSimulationResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.LoadMiniPVSimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.LoadMiniPVSimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.LoadMiniPVSimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.LoadMiniPVSimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_simulations_pb.MiniPVSimulationResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.MiniPVSimulationResult result = 1;
 * @return {?proto.types.MiniPVSimulationResult}
 */
proto.services.central.LoadMiniPVSimulationResponse.prototype.getResult = function() {
  return /** @type{?proto.types.MiniPVSimulationResult} */ (
    jspb.Message.getWrapperField(this, types_simulations_pb.MiniPVSimulationResult, 1));
};


/**
 * @param {?proto.types.MiniPVSimulationResult|undefined} value
 * @return {!proto.services.central.LoadMiniPVSimulationResponse} returns this
*/
proto.services.central.LoadMiniPVSimulationResponse.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.LoadMiniPVSimulationResponse} returns this
 */
proto.services.central.LoadMiniPVSimulationResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.LoadMiniPVSimulationResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTipsRequest}
 */
proto.services.central.GetTipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTipsRequest;
  return proto.services.central.GetTipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTipsRequest}
 */
proto.services.central.GetTipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetTipsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTipsRequest} returns this
 */
proto.services.central.GetTipsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetTipsRequest} returns this
 */
proto.services.central.GetTipsRequest.prototype.clearSpaceId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTipsRequest.prototype.hasSpaceId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetTipsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tipsList: jspb.Message.toObjectList(msg.getTipsList(),
    types_tips_pb.Tip.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTipsResponse}
 */
proto.services.central.GetTipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTipsResponse;
  return proto.services.central.GetTipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTipsResponse}
 */
proto.services.central.GetTipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tips_pb.Tip;
      reader.readMessage(value,types_tips_pb.Tip.deserializeBinaryFromReader);
      msg.addTips(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tips_pb.Tip.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.Tip tips = 1;
 * @return {!Array<!proto.types.Tip>}
 */
proto.services.central.GetTipsResponse.prototype.getTipsList = function() {
  return /** @type{!Array<!proto.types.Tip>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tips_pb.Tip, 1));
};


/**
 * @param {!Array<!proto.types.Tip>} value
 * @return {!proto.services.central.GetTipsResponse} returns this
*/
proto.services.central.GetTipsResponse.prototype.setTipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Tip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Tip}
 */
proto.services.central.GetTipsResponse.prototype.addTips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Tip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetTipsResponse} returns this
 */
proto.services.central.GetTipsResponse.prototype.clearTipsList = function() {
  return this.setTipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TrackTipInteractionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TrackTipInteractionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TrackTipInteractionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TrackTipInteractionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    interaction: (f = msg.getInteraction()) && types_tips_pb.TipInteraction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TrackTipInteractionRequest}
 */
proto.services.central.TrackTipInteractionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TrackTipInteractionRequest;
  return proto.services.central.TrackTipInteractionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TrackTipInteractionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TrackTipInteractionRequest}
 */
proto.services.central.TrackTipInteractionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tips_pb.TipInteraction;
      reader.readMessage(value,types_tips_pb.TipInteraction.deserializeBinaryFromReader);
      msg.setInteraction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TrackTipInteractionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TrackTipInteractionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TrackTipInteractionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TrackTipInteractionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInteraction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_tips_pb.TipInteraction.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.TipInteraction interaction = 1;
 * @return {?proto.types.TipInteraction}
 */
proto.services.central.TrackTipInteractionRequest.prototype.getInteraction = function() {
  return /** @type{?proto.types.TipInteraction} */ (
    jspb.Message.getWrapperField(this, types_tips_pb.TipInteraction, 1));
};


/**
 * @param {?proto.types.TipInteraction|undefined} value
 * @return {!proto.services.central.TrackTipInteractionRequest} returns this
*/
proto.services.central.TrackTipInteractionRequest.prototype.setInteraction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.TrackTipInteractionRequest} returns this
 */
proto.services.central.TrackTipInteractionRequest.prototype.clearInteraction = function() {
  return this.setInteraction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.TrackTipInteractionRequest.prototype.hasInteraction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TrackTipInteractionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TrackTipInteractionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TrackTipInteractionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TrackTipInteractionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TrackTipInteractionResponse}
 */
proto.services.central.TrackTipInteractionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TrackTipInteractionResponse;
  return proto.services.central.TrackTipInteractionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TrackTipInteractionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TrackTipInteractionResponse}
 */
proto.services.central.TrackTipInteractionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TrackTipInteractionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TrackTipInteractionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TrackTipInteractionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TrackTipInteractionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetEnergyConsumersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetEnergyConsumersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetEnergyConsumersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyConsumersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetEnergyConsumersRequest}
 */
proto.services.central.GetEnergyConsumersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetEnergyConsumersRequest;
  return proto.services.central.GetEnergyConsumersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetEnergyConsumersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetEnergyConsumersRequest}
 */
proto.services.central.GetEnergyConsumersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetEnergyConsumersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetEnergyConsumersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetEnergyConsumersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyConsumersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetEnergyConsumersRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetEnergyConsumersRequest} returns this
 */
proto.services.central.GetEnergyConsumersRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetEnergyConsumersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetEnergyConsumersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetEnergyConsumersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetEnergyConsumersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyConsumersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyConsumersList: jspb.Message.toObjectList(msg.getEnergyConsumersList(),
    types_tips_pb.EnergyConsumer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetEnergyConsumersResponse}
 */
proto.services.central.GetEnergyConsumersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetEnergyConsumersResponse;
  return proto.services.central.GetEnergyConsumersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetEnergyConsumersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetEnergyConsumersResponse}
 */
proto.services.central.GetEnergyConsumersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tips_pb.EnergyConsumer;
      reader.readMessage(value,types_tips_pb.EnergyConsumer.deserializeBinaryFromReader);
      msg.addEnergyConsumers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetEnergyConsumersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetEnergyConsumersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetEnergyConsumersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyConsumersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyConsumersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tips_pb.EnergyConsumer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.EnergyConsumer energy_consumers = 1;
 * @return {!Array<!proto.types.EnergyConsumer>}
 */
proto.services.central.GetEnergyConsumersResponse.prototype.getEnergyConsumersList = function() {
  return /** @type{!Array<!proto.types.EnergyConsumer>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tips_pb.EnergyConsumer, 1));
};


/**
 * @param {!Array<!proto.types.EnergyConsumer>} value
 * @return {!proto.services.central.GetEnergyConsumersResponse} returns this
*/
proto.services.central.GetEnergyConsumersResponse.prototype.setEnergyConsumersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergyConsumer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyConsumer}
 */
proto.services.central.GetEnergyConsumersResponse.prototype.addEnergyConsumers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergyConsumer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetEnergyConsumersResponse} returns this
 */
proto.services.central.GetEnergyConsumersResponse.prototype.clearEnergyConsumersList = function() {
  return this.setEnergyConsumersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.SetEnergyConsumersRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetEnergyConsumersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetEnergyConsumersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetEnergyConsumersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEnergyConsumersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    energyConsumersList: jspb.Message.toObjectList(msg.getEnergyConsumersList(),
    types_tips_pb.EnergyConsumer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetEnergyConsumersRequest}
 */
proto.services.central.SetEnergyConsumersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetEnergyConsumersRequest;
  return proto.services.central.SetEnergyConsumersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetEnergyConsumersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetEnergyConsumersRequest}
 */
proto.services.central.SetEnergyConsumersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_tips_pb.EnergyConsumer;
      reader.readMessage(value,types_tips_pb.EnergyConsumer.deserializeBinaryFromReader);
      msg.addEnergyConsumers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetEnergyConsumersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetEnergyConsumersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetEnergyConsumersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEnergyConsumersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnergyConsumersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_tips_pb.EnergyConsumer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.SetEnergyConsumersRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SetEnergyConsumersRequest} returns this
 */
proto.services.central.SetEnergyConsumersRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated types.EnergyConsumer energy_consumers = 2;
 * @return {!Array<!proto.types.EnergyConsumer>}
 */
proto.services.central.SetEnergyConsumersRequest.prototype.getEnergyConsumersList = function() {
  return /** @type{!Array<!proto.types.EnergyConsumer>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tips_pb.EnergyConsumer, 2));
};


/**
 * @param {!Array<!proto.types.EnergyConsumer>} value
 * @return {!proto.services.central.SetEnergyConsumersRequest} returns this
*/
proto.services.central.SetEnergyConsumersRequest.prototype.setEnergyConsumersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.EnergyConsumer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyConsumer}
 */
proto.services.central.SetEnergyConsumersRequest.prototype.addEnergyConsumers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.EnergyConsumer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.SetEnergyConsumersRequest} returns this
 */
proto.services.central.SetEnergyConsumersRequest.prototype.clearEnergyConsumersList = function() {
  return this.setEnergyConsumersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetEnergyConsumersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetEnergyConsumersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetEnergyConsumersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEnergyConsumersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetEnergyConsumersResponse}
 */
proto.services.central.SetEnergyConsumersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetEnergyConsumersResponse;
  return proto.services.central.SetEnergyConsumersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetEnergyConsumersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetEnergyConsumersResponse}
 */
proto.services.central.SetEnergyConsumersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetEnergyConsumersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetEnergyConsumersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetEnergyConsumersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEnergyConsumersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteSimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteSimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteSimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteSimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    simulation: (f = msg.getSimulation()) && types_simulations_pb.Simulation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteSimulationRequest}
 */
proto.services.central.DeleteSimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteSimulationRequest;
  return proto.services.central.DeleteSimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteSimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteSimulationRequest}
 */
proto.services.central.DeleteSimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_simulations_pb.Simulation;
      reader.readMessage(value,types_simulations_pb.Simulation.deserializeBinaryFromReader);
      msg.setSimulation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteSimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteSimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteSimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteSimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSimulation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_simulations_pb.Simulation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.DeleteSimulationRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteSimulationRequest} returns this
 */
proto.services.central.DeleteSimulationRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Simulation simulation = 2;
 * @return {?proto.types.Simulation}
 */
proto.services.central.DeleteSimulationRequest.prototype.getSimulation = function() {
  return /** @type{?proto.types.Simulation} */ (
    jspb.Message.getWrapperField(this, types_simulations_pb.Simulation, 2));
};


/**
 * @param {?proto.types.Simulation|undefined} value
 * @return {!proto.services.central.DeleteSimulationRequest} returns this
*/
proto.services.central.DeleteSimulationRequest.prototype.setSimulation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.DeleteSimulationRequest} returns this
 */
proto.services.central.DeleteSimulationRequest.prototype.clearSimulation = function() {
  return this.setSimulation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteSimulationRequest.prototype.hasSimulation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteSimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteSimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteSimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteSimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteSimulationResponse}
 */
proto.services.central.DeleteSimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteSimulationResponse;
  return proto.services.central.DeleteSimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteSimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteSimulationResponse}
 */
proto.services.central.DeleteSimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteSimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteSimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteSimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteSimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automation: (f = msg.getAutomation()) && types_automations_pb.Automation.toObject(includeInstance, f),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddAutomationRequest}
 */
proto.services.central.AddAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddAutomationRequest;
  return proto.services.central.AddAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddAutomationRequest}
 */
proto.services.central.AddAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_automations_pb.Automation;
      reader.readMessage(value,types_automations_pb.Automation.deserializeBinaryFromReader);
      msg.setAutomation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_automations_pb.Automation.serializeBinaryToWriter
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional types.Automation automation = 1;
 * @return {?proto.types.Automation}
 */
proto.services.central.AddAutomationRequest.prototype.getAutomation = function() {
  return /** @type{?proto.types.Automation} */ (
    jspb.Message.getWrapperField(this, types_automations_pb.Automation, 1));
};


/**
 * @param {?proto.types.Automation|undefined} value
 * @return {!proto.services.central.AddAutomationRequest} returns this
*/
proto.services.central.AddAutomationRequest.prototype.setAutomation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddAutomationRequest} returns this
 */
proto.services.central.AddAutomationRequest.prototype.clearAutomation = function() {
  return this.setAutomation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddAutomationRequest.prototype.hasAutomation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.AddAutomationRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddAutomationRequest} returns this
 */
proto.services.central.AddAutomationRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddAutomationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddAutomationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddAutomationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAutomationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddAutomationResponse}
 */
proto.services.central.AddAutomationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddAutomationResponse;
  return proto.services.central.AddAutomationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddAutomationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddAutomationResponse}
 */
proto.services.central.AddAutomationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddAutomationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddAutomationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddAutomationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAutomationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteAutomationRequest}
 */
proto.services.central.DeleteAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteAutomationRequest;
  return proto.services.central.DeleteAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteAutomationRequest}
 */
proto.services.central.DeleteAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string automation_id = 1;
 * @return {string}
 */
proto.services.central.DeleteAutomationRequest.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteAutomationRequest} returns this
 */
proto.services.central.DeleteAutomationRequest.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string space_id = 2;
 * @return {string}
 */
proto.services.central.DeleteAutomationRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteAutomationRequest} returns this
 */
proto.services.central.DeleteAutomationRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteAutomationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteAutomationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteAutomationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAutomationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteAutomationResponse}
 */
proto.services.central.DeleteAutomationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteAutomationResponse;
  return proto.services.central.DeleteAutomationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteAutomationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteAutomationResponse}
 */
proto.services.central.DeleteAutomationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteAutomationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteAutomationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteAutomationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAutomationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationsRequest}
 */
proto.services.central.GetAutomationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationsRequest;
  return proto.services.central.GetAutomationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationsRequest}
 */
proto.services.central.GetAutomationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetAutomationsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAutomationsRequest} returns this
 */
proto.services.central.GetAutomationsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAutomationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationsList: jspb.Message.toObjectList(msg.getAutomationsList(),
    types_automations_pb.Automation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationsResponse}
 */
proto.services.central.GetAutomationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationsResponse;
  return proto.services.central.GetAutomationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationsResponse}
 */
proto.services.central.GetAutomationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_automations_pb.Automation;
      reader.readMessage(value,types_automations_pb.Automation.deserializeBinaryFromReader);
      msg.addAutomations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_automations_pb.Automation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.Automation automations = 1;
 * @return {!Array<!proto.types.Automation>}
 */
proto.services.central.GetAutomationsResponse.prototype.getAutomationsList = function() {
  return /** @type{!Array<!proto.types.Automation>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_automations_pb.Automation, 1));
};


/**
 * @param {!Array<!proto.types.Automation>} value
 * @return {!proto.services.central.GetAutomationsResponse} returns this
*/
proto.services.central.GetAutomationsResponse.prototype.setAutomationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Automation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Automation}
 */
proto.services.central.GetAutomationsResponse.prototype.addAutomations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Automation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAutomationsResponse} returns this
 */
proto.services.central.GetAutomationsResponse.prototype.clearAutomationsList = function() {
  return this.setAutomationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDispatchedActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDispatchedActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDispatchedActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDispatchedActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && types_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDispatchedActionsRequest}
 */
proto.services.central.GetDispatchedActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDispatchedActionsRequest;
  return proto.services.central.GetDispatchedActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDispatchedActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDispatchedActionsRequest}
 */
proto.services.central.GetDispatchedActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_pagination_pb.Pagination;
      reader.readMessage(value,types_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDispatchedActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDispatchedActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDispatchedActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDispatchedActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetDispatchedActionsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetDispatchedActionsRequest} returns this
 */
proto.services.central.GetDispatchedActionsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Pagination pagination = 2;
 * @return {?proto.types.Pagination}
 */
proto.services.central.GetDispatchedActionsRequest.prototype.getPagination = function() {
  return /** @type{?proto.types.Pagination} */ (
    jspb.Message.getWrapperField(this, types_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.types.Pagination|undefined} value
 * @return {!proto.services.central.GetDispatchedActionsRequest} returns this
*/
proto.services.central.GetDispatchedActionsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetDispatchedActionsRequest} returns this
 */
proto.services.central.GetDispatchedActionsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetDispatchedActionsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetDispatchedActionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetDispatchedActionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetDispatchedActionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetDispatchedActionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDispatchedActionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dispatchedActionsList: jspb.Message.toObjectList(msg.getDispatchedActionsList(),
    types_automations_pb.DispatchedAction.toObject, includeInstance),
    totalNumPages: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetDispatchedActionsResponse}
 */
proto.services.central.GetDispatchedActionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetDispatchedActionsResponse;
  return proto.services.central.GetDispatchedActionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetDispatchedActionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetDispatchedActionsResponse}
 */
proto.services.central.GetDispatchedActionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_automations_pb.DispatchedAction;
      reader.readMessage(value,types_automations_pb.DispatchedAction.deserializeBinaryFromReader);
      msg.addDispatchedActions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetDispatchedActionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetDispatchedActionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetDispatchedActionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetDispatchedActionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDispatchedActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_automations_pb.DispatchedAction.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumPages();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated types.DispatchedAction dispatched_actions = 1;
 * @return {!Array<!proto.types.DispatchedAction>}
 */
proto.services.central.GetDispatchedActionsResponse.prototype.getDispatchedActionsList = function() {
  return /** @type{!Array<!proto.types.DispatchedAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_automations_pb.DispatchedAction, 1));
};


/**
 * @param {!Array<!proto.types.DispatchedAction>} value
 * @return {!proto.services.central.GetDispatchedActionsResponse} returns this
*/
proto.services.central.GetDispatchedActionsResponse.prototype.setDispatchedActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DispatchedAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DispatchedAction}
 */
proto.services.central.GetDispatchedActionsResponse.prototype.addDispatchedActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DispatchedAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetDispatchedActionsResponse} returns this
 */
proto.services.central.GetDispatchedActionsResponse.prototype.clearDispatchedActionsList = function() {
  return this.setDispatchedActionsList([]);
};


/**
 * optional int32 total_num_pages = 2;
 * @return {number}
 */
proto.services.central.GetDispatchedActionsResponse.prototype.getTotalNumPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetDispatchedActionsResponse} returns this
 */
proto.services.central.GetDispatchedActionsResponse.prototype.setTotalNumPages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.RetryDispatchedActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.RetryDispatchedActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.RetryDispatchedActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RetryDispatchedActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dispatchedActionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.RetryDispatchedActionRequest}
 */
proto.services.central.RetryDispatchedActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.RetryDispatchedActionRequest;
  return proto.services.central.RetryDispatchedActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.RetryDispatchedActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.RetryDispatchedActionRequest}
 */
proto.services.central.RetryDispatchedActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDispatchedActionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.RetryDispatchedActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.RetryDispatchedActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.RetryDispatchedActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RetryDispatchedActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDispatchedActionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dispatched_action_id = 1;
 * @return {string}
 */
proto.services.central.RetryDispatchedActionRequest.prototype.getDispatchedActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.RetryDispatchedActionRequest} returns this
 */
proto.services.central.RetryDispatchedActionRequest.prototype.setDispatchedActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.RetryDispatchedActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.RetryDispatchedActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.RetryDispatchedActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RetryDispatchedActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.RetryDispatchedActionResponse}
 */
proto.services.central.RetryDispatchedActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.RetryDispatchedActionResponse;
  return proto.services.central.RetryDispatchedActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.RetryDispatchedActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.RetryDispatchedActionResponse}
 */
proto.services.central.RetryDispatchedActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.RetryDispatchedActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.RetryDispatchedActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.RetryDispatchedActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.RetryDispatchedActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationDetailRequest}
 */
proto.services.central.GetAutomationDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationDetailRequest;
  return proto.services.central.GetAutomationDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationDetailRequest}
 */
proto.services.central.GetAutomationDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string automation_id = 1;
 * @return {string}
 */
proto.services.central.GetAutomationDetailRequest.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAutomationDetailRequest} returns this
 */
proto.services.central.GetAutomationDetailRequest.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAutomationDetailResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    automation: (f = msg.getAutomation()) && types_automations_pb.Automation.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionIconsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    triggerIconsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationDetailResponse}
 */
proto.services.central.GetAutomationDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationDetailResponse;
  return proto.services.central.GetAutomationDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationDetailResponse}
 */
proto.services.central.GetAutomationDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_automations_pb.Automation;
      reader.readMessage(value,types_automations_pb.Automation.deserializeBinaryFromReader);
      msg.setAutomation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addActionIcons(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTriggerIcons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_automations_pb.Automation.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionIconsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getTriggerIconsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional types.Automation automation = 1;
 * @return {?proto.types.Automation}
 */
proto.services.central.GetAutomationDetailResponse.prototype.getAutomation = function() {
  return /** @type{?proto.types.Automation} */ (
    jspb.Message.getWrapperField(this, types_automations_pb.Automation, 1));
};


/**
 * @param {?proto.types.Automation|undefined} value
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
*/
proto.services.central.GetAutomationDetailResponse.prototype.setAutomation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.clearAutomation = function() {
  return this.setAutomation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAutomationDetailResponse.prototype.hasAutomation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.services.central.GetAutomationDetailResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string action_icons = 3;
 * @return {!Array<string>}
 */
proto.services.central.GetAutomationDetailResponse.prototype.getActionIconsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.setActionIconsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.addActionIcons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.clearActionIconsList = function() {
  return this.setActionIconsList([]);
};


/**
 * repeated string trigger_icons = 4;
 * @return {!Array<string>}
 */
proto.services.central.GetAutomationDetailResponse.prototype.getTriggerIconsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.setTriggerIconsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.addTriggerIcons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAutomationDetailResponse} returns this
 */
proto.services.central.GetAutomationDetailResponse.prototype.clearTriggerIconsList = function() {
  return this.setTriggerIconsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationDispatchedActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationDispatchedActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDispatchedActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && types_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationDispatchedActionsRequest}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationDispatchedActionsRequest;
  return proto.services.central.GetAutomationDispatchedActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationDispatchedActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationDispatchedActionsRequest}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    case 2:
      var value = new types_pagination_pb.Pagination;
      reader.readMessage(value,types_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationDispatchedActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationDispatchedActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDispatchedActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string automation_id = 1;
 * @return {string}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAutomationDispatchedActionsRequest} returns this
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Pagination pagination = 2;
 * @return {?proto.types.Pagination}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.getPagination = function() {
  return /** @type{?proto.types.Pagination} */ (
    jspb.Message.getWrapperField(this, types_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.types.Pagination|undefined} value
 * @return {!proto.services.central.GetAutomationDispatchedActionsRequest} returns this
*/
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetAutomationDispatchedActionsRequest} returns this
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAutomationDispatchedActionsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAutomationDispatchedActionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAutomationDispatchedActionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAutomationDispatchedActionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDispatchedActionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dispatchedActionsList: jspb.Message.toObjectList(msg.getDispatchedActionsList(),
    types_automations_pb.DispatchedAction.toObject, includeInstance),
    totalNumPages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAutomationDispatchedActionsResponse}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAutomationDispatchedActionsResponse;
  return proto.services.central.GetAutomationDispatchedActionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAutomationDispatchedActionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAutomationDispatchedActionsResponse}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new types_automations_pb.DispatchedAction;
      reader.readMessage(value,types_automations_pb.DispatchedAction.deserializeBinaryFromReader);
      msg.addDispatchedActions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAutomationDispatchedActionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAutomationDispatchedActionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAutomationDispatchedActionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDispatchedActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_automations_pb.DispatchedAction.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated types.DispatchedAction dispatched_actions = 2;
 * @return {!Array<!proto.types.DispatchedAction>}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.getDispatchedActionsList = function() {
  return /** @type{!Array<!proto.types.DispatchedAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_automations_pb.DispatchedAction, 2));
};


/**
 * @param {!Array<!proto.types.DispatchedAction>} value
 * @return {!proto.services.central.GetAutomationDispatchedActionsResponse} returns this
*/
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.setDispatchedActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.DispatchedAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DispatchedAction}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.addDispatchedActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.DispatchedAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAutomationDispatchedActionsResponse} returns this
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.clearDispatchedActionsList = function() {
  return this.setDispatchedActionsList([]);
};


/**
 * optional int32 total_num_pages = 3;
 * @return {number}
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.getTotalNumPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetAutomationDispatchedActionsResponse} returns this
 */
proto.services.central.GetAutomationDispatchedActionsResponse.prototype.setTotalNumPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automation: (f = msg.getAutomation()) && types_automations_pb.Automation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateAutomationRequest}
 */
proto.services.central.UpdateAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateAutomationRequest;
  return proto.services.central.UpdateAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateAutomationRequest}
 */
proto.services.central.UpdateAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_automations_pb.Automation;
      reader.readMessage(value,types_automations_pb.Automation.deserializeBinaryFromReader);
      msg.setAutomation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_automations_pb.Automation.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.Automation automation = 1;
 * @return {?proto.types.Automation}
 */
proto.services.central.UpdateAutomationRequest.prototype.getAutomation = function() {
  return /** @type{?proto.types.Automation} */ (
    jspb.Message.getWrapperField(this, types_automations_pb.Automation, 1));
};


/**
 * @param {?proto.types.Automation|undefined} value
 * @return {!proto.services.central.UpdateAutomationRequest} returns this
*/
proto.services.central.UpdateAutomationRequest.prototype.setAutomation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateAutomationRequest} returns this
 */
proto.services.central.UpdateAutomationRequest.prototype.clearAutomation = function() {
  return this.setAutomation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateAutomationRequest.prototype.hasAutomation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateAutomationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateAutomationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateAutomationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateAutomationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateAutomationResponse}
 */
proto.services.central.UpdateAutomationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateAutomationResponse;
  return proto.services.central.UpdateAutomationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateAutomationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateAutomationResponse}
 */
proto.services.central.UpdateAutomationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateAutomationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateAutomationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateAutomationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateAutomationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TriggerAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TriggerAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TriggerAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TriggerAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    automationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TriggerAutomationRequest}
 */
proto.services.central.TriggerAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TriggerAutomationRequest;
  return proto.services.central.TriggerAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TriggerAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TriggerAutomationRequest}
 */
proto.services.central.TriggerAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TriggerAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TriggerAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TriggerAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TriggerAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string automation_id = 1;
 * @return {string}
 */
proto.services.central.TriggerAutomationRequest.prototype.getAutomationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.TriggerAutomationRequest} returns this
 */
proto.services.central.TriggerAutomationRequest.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.TriggerAutomationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.TriggerAutomationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.TriggerAutomationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TriggerAutomationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.TriggerAutomationResponse}
 */
proto.services.central.TriggerAutomationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.TriggerAutomationResponse;
  return proto.services.central.TriggerAutomationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.TriggerAutomationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.TriggerAutomationResponse}
 */
proto.services.central.TriggerAutomationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.TriggerAutomationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.TriggerAutomationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.TriggerAutomationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.TriggerAutomationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && types_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportsRequest}
 */
proto.services.central.GetReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportsRequest;
  return proto.services.central.GetReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportsRequest}
 */
proto.services.central.GetReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_pagination_pb.Pagination;
      reader.readMessage(value,types_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetReportsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetReportsRequest} returns this
 */
proto.services.central.GetReportsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Pagination pagination = 2;
 * @return {?proto.types.Pagination}
 */
proto.services.central.GetReportsRequest.prototype.getPagination = function() {
  return /** @type{?proto.types.Pagination} */ (
    jspb.Message.getWrapperField(this, types_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.types.Pagination|undefined} value
 * @return {!proto.services.central.GetReportsRequest} returns this
*/
proto.services.central.GetReportsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReportsRequest} returns this
 */
proto.services.central.GetReportsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReportsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetReportsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalNumReports: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    types_reporting_pb.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportsResponse}
 */
proto.services.central.GetReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportsResponse;
  return proto.services.central.GetReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportsResponse}
 */
proto.services.central.GetReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumReports(value);
      break;
    case 2:
      var value = new types_reporting_pb.Report;
      reader.readMessage(value,types_reporting_pb.Report.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalNumReports();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_reporting_pb.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_num_reports = 1;
 * @return {number}
 */
proto.services.central.GetReportsResponse.prototype.getTotalNumReports = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetReportsResponse} returns this
 */
proto.services.central.GetReportsResponse.prototype.setTotalNumReports = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated types.Report reports = 2;
 * @return {!Array<!proto.types.Report>}
 */
proto.services.central.GetReportsResponse.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.types.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_reporting_pb.Report, 2));
};


/**
 * @param {!Array<!proto.types.Report>} value
 * @return {!proto.services.central.GetReportsResponse} returns this
*/
proto.services.central.GetReportsResponse.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Report}
 */
proto.services.central.GetReportsResponse.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReportsResponse} returns this
 */
proto.services.central.GetReportsResponse.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportSchedulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportSchedulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportSchedulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportSchedulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportSchedulesRequest}
 */
proto.services.central.GetReportSchedulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportSchedulesRequest;
  return proto.services.central.GetReportSchedulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportSchedulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportSchedulesRequest}
 */
proto.services.central.GetReportSchedulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportSchedulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportSchedulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportSchedulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportSchedulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetReportSchedulesRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetReportSchedulesRequest} returns this
 */
proto.services.central.GetReportSchedulesRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetReportSchedulesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportSchedulesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportSchedulesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportSchedulesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportSchedulesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    schedulesList: jspb.Message.toObjectList(msg.getSchedulesList(),
    types_reporting_pb.Report.Schedule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportSchedulesResponse}
 */
proto.services.central.GetReportSchedulesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportSchedulesResponse;
  return proto.services.central.GetReportSchedulesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportSchedulesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportSchedulesResponse}
 */
proto.services.central.GetReportSchedulesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_reporting_pb.Report.Schedule;
      reader.readMessage(value,types_reporting_pb.Report.Schedule.deserializeBinaryFromReader);
      msg.addSchedules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportSchedulesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportSchedulesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportSchedulesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportSchedulesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchedulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_reporting_pb.Report.Schedule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.Report.Schedule schedules = 1;
 * @return {!Array<!proto.types.Report.Schedule>}
 */
proto.services.central.GetReportSchedulesResponse.prototype.getSchedulesList = function() {
  return /** @type{!Array<!proto.types.Report.Schedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_reporting_pb.Report.Schedule, 1));
};


/**
 * @param {!Array<!proto.types.Report.Schedule>} value
 * @return {!proto.services.central.GetReportSchedulesResponse} returns this
*/
proto.services.central.GetReportSchedulesResponse.prototype.setSchedulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Report.Schedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Report.Schedule}
 */
proto.services.central.GetReportSchedulesResponse.prototype.addSchedules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Report.Schedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReportSchedulesResponse} returns this
 */
proto.services.central.GetReportSchedulesResponse.prototype.clearSchedulesList = function() {
  return this.setSchedulesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.CreateReportScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.CreateReportScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.CreateReportScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CreateReportScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newSchedule: (f = msg.getNewSchedule()) && types_reporting_pb.Report.Schedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.CreateReportScheduleRequest}
 */
proto.services.central.CreateReportScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.CreateReportScheduleRequest;
  return proto.services.central.CreateReportScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.CreateReportScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.CreateReportScheduleRequest}
 */
proto.services.central.CreateReportScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_reporting_pb.Report.Schedule;
      reader.readMessage(value,types_reporting_pb.Report.Schedule.deserializeBinaryFromReader);
      msg.setNewSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.CreateReportScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.CreateReportScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.CreateReportScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CreateReportScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewSchedule();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_reporting_pb.Report.Schedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.CreateReportScheduleRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.CreateReportScheduleRequest} returns this
 */
proto.services.central.CreateReportScheduleRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Report.Schedule new_schedule = 2;
 * @return {?proto.types.Report.Schedule}
 */
proto.services.central.CreateReportScheduleRequest.prototype.getNewSchedule = function() {
  return /** @type{?proto.types.Report.Schedule} */ (
    jspb.Message.getWrapperField(this, types_reporting_pb.Report.Schedule, 2));
};


/**
 * @param {?proto.types.Report.Schedule|undefined} value
 * @return {!proto.services.central.CreateReportScheduleRequest} returns this
*/
proto.services.central.CreateReportScheduleRequest.prototype.setNewSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.CreateReportScheduleRequest} returns this
 */
proto.services.central.CreateReportScheduleRequest.prototype.clearNewSchedule = function() {
  return this.setNewSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.CreateReportScheduleRequest.prototype.hasNewSchedule = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.CreateReportScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.CreateReportScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.CreateReportScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CreateReportScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.CreateReportScheduleResponse}
 */
proto.services.central.CreateReportScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.CreateReportScheduleResponse;
  return proto.services.central.CreateReportScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.CreateReportScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.CreateReportScheduleResponse}
 */
proto.services.central.CreateReportScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.CreateReportScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.CreateReportScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.CreateReportScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.CreateReportScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportContentRequest}
 */
proto.services.central.GetReportContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportContentRequest;
  return proto.services.central.GetReportContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportContentRequest}
 */
proto.services.central.GetReportContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string report_id = 1;
 * @return {string}
 */
proto.services.central.GetReportContentRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetReportContentRequest} returns this
 */
proto.services.central.GetReportContentRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: (f = msg.getReport()) && types_reporting_pb.Report.toObject(includeInstance, f),
    content: (f = msg.getContent()) && types_reporting_pb.Report.Content.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportContentResponse}
 */
proto.services.central.GetReportContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportContentResponse;
  return proto.services.central.GetReportContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportContentResponse}
 */
proto.services.central.GetReportContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_reporting_pb.Report;
      reader.readMessage(value,types_reporting_pb.Report.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    case 2:
      var value = new types_reporting_pb.Report.Content;
      reader.readMessage(value,types_reporting_pb.Report.Content.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_reporting_pb.Report.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_reporting_pb.Report.Content.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.Report report = 1;
 * @return {?proto.types.Report}
 */
proto.services.central.GetReportContentResponse.prototype.getReport = function() {
  return /** @type{?proto.types.Report} */ (
    jspb.Message.getWrapperField(this, types_reporting_pb.Report, 1));
};


/**
 * @param {?proto.types.Report|undefined} value
 * @return {!proto.services.central.GetReportContentResponse} returns this
*/
proto.services.central.GetReportContentResponse.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReportContentResponse} returns this
 */
proto.services.central.GetReportContentResponse.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReportContentResponse.prototype.hasReport = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional types.Report.Content content = 2;
 * @return {?proto.types.Report.Content}
 */
proto.services.central.GetReportContentResponse.prototype.getContent = function() {
  return /** @type{?proto.types.Report.Content} */ (
    jspb.Message.getWrapperField(this, types_reporting_pb.Report.Content, 2));
};


/**
 * @param {?proto.types.Report.Content|undefined} value
 * @return {!proto.services.central.GetReportContentResponse} returns this
*/
proto.services.central.GetReportContentResponse.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetReportContentResponse} returns this
 */
proto.services.central.GetReportContentResponse.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetReportContentResponse.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportScheduleFrequenciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportScheduleFrequenciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportScheduleFrequenciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportScheduleFrequenciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportScheduleFrequenciesRequest}
 */
proto.services.central.GetReportScheduleFrequenciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportScheduleFrequenciesRequest;
  return proto.services.central.GetReportScheduleFrequenciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportScheduleFrequenciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportScheduleFrequenciesRequest}
 */
proto.services.central.GetReportScheduleFrequenciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportScheduleFrequenciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportScheduleFrequenciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportScheduleFrequenciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportScheduleFrequenciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetReportScheduleFrequenciesResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportScheduleFrequenciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportScheduleFrequenciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportScheduleFrequenciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequenciesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse}
 */
proto.services.central.GetReportScheduleFrequenciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportScheduleFrequenciesResponse;
  return proto.services.central.GetReportScheduleFrequenciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportScheduleFrequenciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse}
 */
proto.services.central.GetReportScheduleFrequenciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.types.Report.ScheduleFrequency>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFrequencies(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportScheduleFrequenciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportScheduleFrequenciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportScheduleFrequenciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequenciesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated types.Report.ScheduleFrequency frequencies = 1;
 * @return {!Array<!proto.types.Report.ScheduleFrequency>}
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.getFrequenciesList = function() {
  return /** @type {!Array<!proto.types.Report.ScheduleFrequency>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.types.Report.ScheduleFrequency>} value
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse} returns this
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.setFrequenciesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.types.Report.ScheduleFrequency} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse} returns this
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.addFrequencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse} returns this
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.clearFrequenciesList = function() {
  return this.setFrequenciesList([]);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse} returns this
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse} returns this
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReportScheduleFrequenciesResponse} returns this
 */
proto.services.central.GetReportScheduleFrequenciesResponse.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportMetricsRequest}
 */
proto.services.central.GetReportMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportMetricsRequest;
  return proto.services.central.GetReportMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportMetricsRequest}
 */
proto.services.central.GetReportMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetReportMetricsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetReportMetricsRequest} returns this
 */
proto.services.central.GetReportMetricsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetReportMetricsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetReportMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetReportMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetReportMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metricsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetReportMetricsResponse}
 */
proto.services.central.GetReportMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetReportMetricsResponse;
  return proto.services.central.GetReportMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetReportMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetReportMetricsResponse}
 */
proto.services.central.GetReportMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.types.Report.Metric>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMetrics(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetReportMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetReportMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetReportMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetReportMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated types.Report.Metric metrics = 1;
 * @return {!Array<!proto.types.Report.Metric>}
 */
proto.services.central.GetReportMetricsResponse.prototype.getMetricsList = function() {
  return /** @type {!Array<!proto.types.Report.Metric>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.types.Report.Metric>} value
 * @return {!proto.services.central.GetReportMetricsResponse} returns this
 */
proto.services.central.GetReportMetricsResponse.prototype.setMetricsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.types.Report.Metric} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetReportMetricsResponse} returns this
 */
proto.services.central.GetReportMetricsResponse.prototype.addMetrics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReportMetricsResponse} returns this
 */
proto.services.central.GetReportMetricsResponse.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.services.central.GetReportMetricsResponse.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.central.GetReportMetricsResponse} returns this
 */
proto.services.central.GetReportMetricsResponse.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.central.GetReportMetricsResponse} returns this
 */
proto.services.central.GetReportMetricsResponse.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetReportMetricsResponse} returns this
 */
proto.services.central.GetReportMetricsResponse.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.EditReportScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.EditReportScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.EditReportScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.EditReportScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    schedule: (f = msg.getSchedule()) && types_reporting_pb.Report.Schedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.EditReportScheduleRequest}
 */
proto.services.central.EditReportScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.EditReportScheduleRequest;
  return proto.services.central.EditReportScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.EditReportScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.EditReportScheduleRequest}
 */
proto.services.central.EditReportScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_reporting_pb.Report.Schedule;
      reader.readMessage(value,types_reporting_pb.Report.Schedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.EditReportScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.EditReportScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.EditReportScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.EditReportScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_reporting_pb.Report.Schedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.Report.Schedule schedule = 1;
 * @return {?proto.types.Report.Schedule}
 */
proto.services.central.EditReportScheduleRequest.prototype.getSchedule = function() {
  return /** @type{?proto.types.Report.Schedule} */ (
    jspb.Message.getWrapperField(this, types_reporting_pb.Report.Schedule, 1));
};


/**
 * @param {?proto.types.Report.Schedule|undefined} value
 * @return {!proto.services.central.EditReportScheduleRequest} returns this
*/
proto.services.central.EditReportScheduleRequest.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.EditReportScheduleRequest} returns this
 */
proto.services.central.EditReportScheduleRequest.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.EditReportScheduleRequest.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.EditReportScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.EditReportScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.EditReportScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.EditReportScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.EditReportScheduleResponse}
 */
proto.services.central.EditReportScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.EditReportScheduleResponse;
  return proto.services.central.EditReportScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.EditReportScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.EditReportScheduleResponse}
 */
proto.services.central.EditReportScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.EditReportScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.EditReportScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.EditReportScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.EditReportScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteReportScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteReportScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteReportScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteReportScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteReportScheduleRequest}
 */
proto.services.central.DeleteReportScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteReportScheduleRequest;
  return proto.services.central.DeleteReportScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteReportScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteReportScheduleRequest}
 */
proto.services.central.DeleteReportScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteReportScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteReportScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteReportScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteReportScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string schedule_id = 1;
 * @return {string}
 */
proto.services.central.DeleteReportScheduleRequest.prototype.getScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteReportScheduleRequest} returns this
 */
proto.services.central.DeleteReportScheduleRequest.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteReportScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteReportScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteReportScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteReportScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteReportScheduleResponse}
 */
proto.services.central.DeleteReportScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteReportScheduleResponse;
  return proto.services.central.DeleteReportScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteReportScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteReportScheduleResponse}
 */
proto.services.central.DeleteReportScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteReportScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteReportScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteReportScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteReportScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.services.central);
