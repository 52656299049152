
import { projectsModule } from "@/store/modules/projects";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { CharityProject } from "zaehlerfreunde-proto-types/tariff_pb";
import ProjectDetails from "./ProjectDetails.vue";

@Component({
  components: {
    ProjectDetails,
  },
})
export default class ProjectSelection extends Vue {
  currentPage: number = 0;
  projectsPerPage: number = 3;

  @projectsModule.Getter projects: RemoteData<UserError, CharityProject[]>;

  get projectsStart(): number {
    return this.currentPage * this.projectsPerPage;
  }

  get projectsEnd(): number {
    return Math.min(this.projects.list.length, this.projectsStart + this.projectsPerPage);
  }

  get currentProjects(): CharityProject[] {
    return this.projects.list.slice(this.projectsStart, this.projectsEnd);
  }

  resizeEventHandler(): void {
    if (window.innerWidth < 700) {
      this.projectsPerPage = 1;
    } else if (window.innerWidth < 1000) {
      this.projectsPerPage = 2;
    } else {
      this.projectsPerPage = 3;
    }
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  mounted(): void {
    this.resizeEventHandler();
  }

  nextPage(): void {
    this.currentPage += 1;
  }

  previousPage(): void {
    this.currentPage -= 1;
  }
}
