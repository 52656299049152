
import { Vue, Component, Watch } from "vue-property-decorator";
import ZaehlerfreundeLogo from "@/components/layout/ZaehlerfreundeLogo.vue";
import Footer from "@/components/layout/Footer.vue";
import { paths } from "@/router/routes";

@Component({
  components: {
    ZaehlerfreundeLogo,
    Footer,
  },
})
export default class Legal extends Vue {
  title: string = "";

  mounted(): void {
    this.onRouteChanged();
  }

  @Watch("$route")
  onRouteChanged(): void {
    const meta = this.$route.meta ?? {};
    this.title = meta.title ?? "";
  }

  onLogoClicked(): void {
    this.$router.replace(paths.home);
  }
}
