
import { Vue, Component } from "vue-property-decorator";
import DeviceType from "./DeviceType.vue";
import DeviceProvider from "./DeviceProvider.vue";
import MainDevice from "./MainDevice.vue";
import DeviceName from "./DeviceName.vue";
import { BLUEMETERING_PARTNER_ID } from "@/store/modules/partner";
import { NON_SMART_METER_INITIALISATION } from "../non-smart-meter/UpdateManualReadingsPage.vue";
import { Device, DeviceTypeInfo } from "zaehlerfreunde-proto-types/device_pb";
import { Option } from "@/components/core/funnel/OptionSelection.vue";

export interface GeneralDeviceInfo {
  deviceType: Device.Type;
  deviceProvider: Device.Provider;
  isMainDevice: boolean;
  deviceName: string;
  instructionsPath: string | null;
  deviceProviderName: string | null;
}

@Component({
  components: {
    DeviceType,
    DeviceProvider,
    MainDevice,
    DeviceName,
  },
})
export default class GeneralSteps extends Vue {
  deviceType: Device.Type | null = null;
  deviceProvider: Device.Provider | null = null;
  isMainDevice: boolean | null = null;
  deviceName: string | null = null;
  instructionsUrl: string | null = null;
  deviceProviderName: string | null = null;
  canSkipProvidersSelection: boolean = false;

  mounted(): void {
    const routeParam = this.$route.params.provider;
    if (routeParam === BLUEMETERING_PARTNER_ID) {
      this.deviceType = Device.Type.SMART_METER;
      this.deviceProvider = Device.Provider.BLUEMETERING;
    }
    if (routeParam === NON_SMART_METER_INITIALISATION) {
      this.deviceType = Device.Type.NON_SMART_METER;
      this.deviceProvider = Device.Provider.NON_SMART_METER_PROVIDER;
      this.isMainDevice = true;
    }
  }

  onDeviceTypeSelected(deviceTypeInfo: DeviceTypeInfo): void {
    this.deviceType = deviceTypeInfo.getValue();
    this.canSkipProvidersSelection = deviceTypeInfo.getCanSkipProviderSelection();
    if (!deviceTypeInfo.getMainDeviceAllowed()) {
      this.isMainDevice = false;
    }
  }

  onDeviceProviderSelected(providerOption: Option): void {
    this.deviceProvider = providerOption.value as Device.Provider;
    if (providerOption.props) {
      this.instructionsUrl = (providerOption.props.instructionsPath as string) ?? "";
      this.deviceProviderName = providerOption.title;
    }
  }

  onIsMainDeviceSelected(isMainDevice: boolean): void {
    this.isMainDevice = isMainDevice;
  }

  onDeviceNameSelected(deviceName: string): void {
    this.deviceName = deviceName;
    this.onGeneralDeviceInfoCompleted();
  }

  onGeneralDeviceInfoCompleted(): void {
    if (
      this.deviceType !== null &&
      this.deviceProvider !== null &&
      this.deviceName !== null &&
      this.isMainDevice !== null
    ) {
      const info: GeneralDeviceInfo = {
        deviceType: this.deviceType,
        deviceProvider: this.deviceProvider,
        isMainDevice: this.isMainDevice,
        deviceName: this.deviceName,
        instructionsPath: this.instructionsUrl,
        deviceProviderName: this.deviceProviderName,
      };

      this.$emit("general-info-complete", info);
    }
  }
}
