
import { failure, initialized, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { AddLandlordToTenantProjectRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
@Component({
  components: {},
})
export default class CreateLandlordToTenantProjectDialog extends Vue {
  createProjectDialog: RemoteCall<UserError> = initialized;
  name: string | null = null;
  location: string | null = null;

  get infoComplete(): boolean {
    return this.name != null && this.location != null;
  }

  async addProject(): Promise<void> {
    const request = new AddLandlordToTenantProjectRequest();
    if (this.name && this.location) {
      request.setName(this.name);
      request.setLocation(this.location);

      try {
        await landlordToTenantServiceClient.addLandlordToTenantProject(request, {});
        this.createProjectDialog = success(void 0);
        this.$emit("project-added");
      } catch (error) {
        this.createProjectDialog = failure(userErrorFrom(error));
      }
    }
  }
}
