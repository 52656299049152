import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { namespace } from "vuex-class";
import { failure, initialized, pending, RemoteData, success, successOptional } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";

import { partnerServiceClient, uiServiceClient } from "@/config/service-clients";
import {
  GetPartnerRequest,
  GetSocialLinksRequest,
  GetCustomizationConfigRequest,
} from "zaehlerfreunde-central/partner_service_pb";
import { SwitchChildPartnerRequest, GetSelectedChildPartnerRequest } from "zaehlerfreunde-central/ui_service_pb";
import { Partner, PartnerSocialLinks, PartnerCustomizationConfig } from "zaehlerfreunde-proto-types/partners_pb";

export const BLUEMETERING_PARTNER_ID = "bluemetering";
export const ZAEHLERFREUNDE_PARTNER_ID = "zaehlerfreunde";
export const HERNE_PARTNER_ID = "stadtwerkeherne";
export const VOLTEGO_ID = "voltego";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "partner",
})
class PartnerModule extends VuexModule {
  partner: RemoteData<UserError, Partner> = initialized;
  partnerSocialLinks: RemoteData<UserError, PartnerSocialLinks> = initialized;
  customizationConfig: RemoteData<UserError, PartnerCustomizationConfig> = initialized;
  selectedChildPartner: RemoteData<UserError, Partner | undefined> = initialized;

  get isPartnerZaehlerfreunde(): boolean {
    return this.partner.data?.getId() === ZAEHLERFREUNDE_PARTNER_ID;
  }

  get isPartnerBlueMetering(): boolean {
    return this.partner.data?.getId() === BLUEMETERING_PARTNER_ID;
  }

  get isPartnerHerne(): boolean {
    return this.partner.data?.getId() === HERNE_PARTNER_ID;
  }

  get isPartnerVoltego(): boolean {
    return this.partner.data?.getId() === VOLTEGO_ID;
  }

  get partnerType(): Partner.Type | undefined {
    return this.partner.data?.getType();
  }

  get supportEmail(): string {
    return this.customizationConfig.data?.getSupportEmail() ?? "support@zaehlerfreunde.com";
  }

  get partnerName(): string {
    return this.partner.data?.getName() ?? "Zählerfreunde";
  }

  @Action
  async getPartner() {
    try {
      this.setPartner(pending);
      const request = new GetPartnerRequest();
      const response = await partnerServiceClient.getPartner(request, {});
      const partner = response.getPartner();

      if (partner) {
        this.setPartner(success(partner));
      } else {
        this.setPartner(failure(userErrorFrom("Beim Laden des Partners ist ein Fehler aufgetreten")));
      }
    } catch (error) {
      this.setPartner(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getSocialLinks() {
    try {
      this.setSocialLinks(pending);

      const request = new GetSocialLinksRequest();
      const response = await partnerServiceClient.getSocialLinks(request, {});

      this.setSocialLinks(successOptional(response.getSocialLinks()));
    } catch (error) {
      this.setSocialLinks(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getCustomizationConfig() {
    try {
      this.setCustomizationConfigs(pending);

      const request = new GetCustomizationConfigRequest();
      const response = await partnerServiceClient.getCustomizationConfig(request, {});

      this.setCustomizationConfigs(successOptional(response.getConfig()));
    } catch (error) {
      this.setCustomizationConfigs(failure(userErrorFrom(error)));
    }
  }

  @Action
  async switchChildPartner(childPartnerId: string) {
    try {
      this.setSelectedChildPartner(pending);

      const request = new SwitchChildPartnerRequest();
      request.setChildPartnerId(childPartnerId);
      const response = await uiServiceClient.switchChildPartner(request, {});
      const selectedChildPartner = response.getChildPartner();
      this.setSelectedChildPartner(success(selectedChildPartner));
    } catch (error) {
      this.setSelectedChildPartner(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getSelectedChildPartner() {
    try {
      this.setSelectedChildPartner(pending);

      const request = new GetSelectedChildPartnerRequest();
      const response = await uiServiceClient.getSelectedChildPartner(request, {});
      const selectedChildPartner = response.getChildPartner();
      this.setSelectedChildPartner(success(selectedChildPartner));
    } catch (error) {
      this.setSelectedChildPartner(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setPartner(partner: RemoteData<UserError, Partner>) {
    this.partner = partner;
  }

  @Mutation
  setSocialLinks(d: RemoteData<UserError, PartnerSocialLinks>) {
    this.partnerSocialLinks = d;
  }

  @Mutation
  setCustomizationConfigs(d: RemoteData<UserError, PartnerCustomizationConfig>) {
    this.customizationConfig = d;
  }

  @Mutation
  setSelectedChildPartner(selectedChildPartner: RemoteData<UserError, Partner | undefined>) {
    this.selectedChildPartner = selectedChildPartner;
  }
}

export const partnerModule = namespace("partner");
export default getModule(PartnerModule);
