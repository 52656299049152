
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import Chart from "chart.js";
import VueChart from "vue-chartjs";
import "chartjs-plugin-colorschemes";

@Component
export default class SpaceComparisonChart extends Mixins(VueChart.Bar, VueChart.mixins.reactiveProp) {
  @Prop() chartOptions!: Chart.ChartOptions;

  private options: Chart.ChartOptions = {};

  private chart!: Chart;

  mounted(): void {
    this.options = this.chartOptions;

    // Draw chart
    this.renderChart(this.chartData, this.options);
    this.chart = this.$data._chart;
  }

  @Watch("chartData")
  chartDataChanged(): void {
    this.rerenderChart();
  }

  private rerenderChart(): void {
    this.chart.destroy();
    this.renderChart(this.chartData, this.chartOptions);
    this.chart = this.$data._chart;
  }

  resizeEventHandler(): void {
    this.rerenderChart();
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }
  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }
}
