
import { deviceServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  DeviceRegistration,
  EnbwRegistrationDetails,
  MsconsRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";

@Component
export default class EnbwConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registrationCall: RemoteCall<UserError> = initialized;
  locationIdType: "market" | "metering" = "metering";
  locationId: string = "";
  medium: Device.Medium = Device.Medium.ELECTRICITY;
  startDate: Date | null = null;
  meterId: string = "";

  deviceMediums: { text: string; value: Device.Medium }[] = [
    { text: "Strom", value: Device.Medium.ELECTRICITY },
    { text: "Gas", value: Device.Medium.GAS },
    { text: "Wasser", value: Device.Medium.WATER },
    { text: "Heizung", value: Device.Medium.HEATING },
  ];

  async onContinueClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(this.medium);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new EnbwRegistrationDetails();
    details.setStartTime((this.startDate?.getTime() ?? 0) / 1000);
    details.setMeterId(this.meterId);
    if (this.locationIdType === "market") {
      details.setMarketLocationId(this.locationId);
    } else {
      details.setMeteringLocationId(this.locationId);
    }

    registration.setEnbw(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    this.registrationCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});

      this.registrationCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registrationCall = failure(userErrorFrom(error));
    }
  }

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }
}
