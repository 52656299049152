
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import ExistingCustomers from "../landing-page/ExistingCustomers.vue";

export interface Feature {
  title?: TranslateResult;
  text: TranslateResult;
  image?: string;
}

const CALENDLY_LINK = "https://meetings.hubspot.com/tobias-keussen";
const SEND_MAIL_LINK = "mailto:kontakt@zaehlerfreunde.com";

@Component({
  components: {
    ExistingCustomers,
  },
})
export default class MunicipalUtilitiesFeatures extends Vue {
  @Prop() text: string;
  @Prop() features: Feature[];

  onBookAppointmentClicked(): void {
    this.$gtag.event("book-white-label-appointment-clicked", {
      event_label: "Book white label appointment",
      event_category: "user-action",
    });
    window.open(CALENDLY_LINK, "_blank");
  }

  onSendMailClicked(): void {
    this.$gtag.event("send-white-label-email-clicked", {
      event_label: "Send white label email",
      event_category: "user-action",
    });
    window.open(SEND_MAIL_LINK, "_blank");
  }
}
