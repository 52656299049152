
import { formatNumber } from "@/utils/number-utils";
import MonthlyPaymentChart from "./MonthlyPaymentChart.vue";
import Chart, { ChartOptions } from "chart.js";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AdvancePaymentWidgetData } from "zaehlerfreunde-central/ui_service_pb";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";

@Component({
  components: {
    MonthlyPaymentChart,
  },
})
export default class MonthlyPayments extends Vue {
  @Prop() advancePaymentData: RemoteData<UserError, AdvancePaymentWidgetData>;

  get currentMonth(): string {
    return new Date().toLocaleString("de-DE", { month: "long" });
  }

  chartStyles = {
    height: "100%",
    width: "100%",
    margin: "auto",
  };

  get chartData(): Chart.ChartData | null {
    if (this.advancePaymentData.data) {
      return {
        labels: this.advancePaymentData.data?.getChartData()?.getLabelsList(),
        datasets: [
          {
            data: this.advancePaymentData.data.getChartData()?.getValuesList(),
            backgroundColor: this.advancePaymentData.data.getChartData()?.getBarColorsList(),
          },
        ],
      };
    } else return null;
  }

  chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      displayColors: false,
      callbacks: {
        label: this.tooltipLabelCallbackfunction(),
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          },
        },
      ],
    },
  };

  tooltipLabelCallbackfunction(): (arg0: Chart.ChartTooltipItem) => string {
    return function (tooltipItem) {
      let valueAsNumber = Number(tooltipItem.value);
      if (valueAsNumber < 0) {
        return "Nachzahlung: " + formatNumber(Math.abs(valueAsNumber)) + " €";
      } else {
        return "Guthaben: " + formatNumber(valueAsNumber) + " €";
      }
    };
  }
}
