
import { Vue, Component } from "vue-property-decorator";

interface Benefit {
  category: string;
  categoryIcon: string;
  title: string;
  image: string;
  description: string;
}

@Component
export default class B2BBenefits extends Vue {
  smallWindow: boolean = false;

  get sides(): ("left" | "right")[] {
    if (this.smallWindow) {
      return ["left"];
    } else {
      return ["left", "right"];
    }
  }

  benefits: Benefit[] = [
    {
      category: this.$t("landingPage.b2b.benefits.automation.category").toString(),
      categoryIcon: "🤖",
      title: this.$t("landingPage.b2b.benefits.automation.title").toString(),
      image: "automation.png",
      description: this.$t("landingPage.b2b.benefits.automation.description").toString(),
    },
    {
      category: this.$t("landingPage.b2b.benefits.transparency.category").toString(),
      categoryIcon: "🔎",
      title: this.$t("landingPage.b2b.benefits.transparency.title").toString(),
      image: "all-devices.png",
      description: this.$t("landingPage.b2b.benefits.transparency.description").toString(),
    },
    {
      category: this.$t("landingPage.b2b.benefits.tariff.category").toString(),
      categoryIcon: "📄",
      title: this.$t("landingPage.b2b.benefits.tariff.title").toString(),
      image: "tariff-found.png",
      description: this.$t("landingPage.b2b.benefits.tariff.description").toString(),
    },
    {
      category: this.$t("landingPage.b2b.benefits.optimization.category").toString(),
      categoryIcon: "📈",
      title: this.$t("landingPage.b2b.benefits.optimization.title").toString(),
      image: "tips.png",
      description: this.$t("landingPage.b2b.benefits.optimization.description").toString(),
    },
  ];

  getBenefitImage(src: string): string {
    var images = require.context("@/assets/b2b-benefits", false, /\.png$/);
    return images("./" + src);
  }

  resizeEventHandler(): void {
    this.smallWindow = window.innerWidth < 700;
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  beforeMount(): void {
    this.resizeEventHandler();
  }
}
