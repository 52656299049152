
import { Component, Vue } from "vue-property-decorator";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import { accountModule } from "@/store/modules/account";
import { partnerModule } from "@/store/modules/partner";

@Component({
  components: {},
})
export default class WelcomeScreen extends Vue {
  @accountModule.State userDetails: RemoteData<UserError, User>;
  @partnerModule.Getter supportEmail: string;

  get name(): string {
    return this.userDetails.data?.getName() ?? "";
  }
}
