
import devices, { devicesModule } from "@/store/modules/devices";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import spaces from "@/store/modules/spaces";
import {
  DeviceRegistration,
  NonSmartMeterRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { getMediumIcon, getMediumName } from "@/utils/device-utils";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { ManualReading, Unit } from "zaehlerfreunde-proto-types/device_reading_pb";
import { paths } from "@/router/routes";
import consumption, { consumptionModule } from "@/store/modules/consumption";

@Component({
  components: {},
})
export default class NonSmartMeterConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;

  electrictyMedium = Device.Medium.ELECTRICITY;
  heatingMedium = Device.Medium.HEATING;

  getMediumIcon = getMediumIcon;
  deviceMediums: { text: string; value: Device.Medium }[] = [
    {
      text: getMediumName(Device.Medium.ELECTRICITY),
      value: Device.Medium.ELECTRICITY,
    },
    {
      text: getMediumName(Device.Medium.WATER),
      value: Device.Medium.WATER,
    },
    {
      text: getMediumName(Device.Medium.GAS),
      value: Device.Medium.GAS,
    },
    {
      text: getMediumName(Device.Medium.HEATING),
      value: Device.Medium.HEATING,
    },
  ];
  heatingUnits: { text: string; value: Unit }[] = [
    {
      text: "kWH",
      value: Unit.KILO_WATT_HOUR,
    },
    {
      text: "m3",
      value: Unit.CUBIC_METRE,
    },
  ];
  deviceMedium: Device.Medium = Device.Medium.ELECTRICITY;
  totalConsumption: number | null = null;
  totalIngestion: number | null = null;

  measurementDate: Date = new Date(Date.now());
  isBidirectional: boolean = false;
  serialNumber: string = "";
  chosenUnit: Unit;

  getMediumUnit() {
    switch (this.deviceMedium) {
      case Device.Medium.ELECTRICITY:
        this.chosenUnit = Unit.KILO_WATT_HOUR;
        return "kWh";
      case Device.Medium.GAS:
      case Device.Medium.WATER:
        this.chosenUnit = Unit.CUBIC_METRE;
        return "m3";
      case Device.Medium.HEATING:
        return "";
    }
  }

  requiredRule(input: string): string | boolean {
    return input?.length > 0 ? true : "Dieses Feld ist erforderlich";
  }

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  onOptionSelected(medium: Device.Medium): void {
    this.deviceMedium = medium;
  }

  onSubmit(): void {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);
    registration.setDeviceMedium(this.deviceMedium);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new NonSmartMeterRegistrationDetails();
    if (this.serialNumber) {
      details.setSerialNumber(this.serialNumber);
    }
    details.setIsBidirectional(this.isBidirectional);
    const manualReading = new ManualReading();

    manualReading.setMeasurementTime(Date.parse(this.measurementDate?.toDateString() as string) / 1000);
    manualReading.setUnit(this.chosenUnit);

    manualReading.setConsumptionReadingValue(this.totalConsumption!);
    if (this.isBidirectional) {
      manualReading.setIngestionReadingValue(this.totalIngestion!);
    }
    details.setManualReading(manualReading);
    registration.setNonSmartMeter(details);
    devices.registerDevice(registration);
  }

  onCloseButtonClicked(): void {
    this.$router.go(-1);
  }

  isSubmitDisabled() {
    return (
      !this.totalConsumption ||
      !this.measurementDate ||
      !(this.totalConsumption >= 0) ||
      (this.isBidirectional ? !this.totalIngestion || !(this.totalIngestion >= 0) : false)
    );
  }
}
