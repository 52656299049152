
import { Vue, Component, Prop } from "vue-property-decorator";
import { TabularData } from "zaehlerfreunde-central/ui_service_pb";

@Component
export default class TabularChart extends Vue {
  @Prop({ default: null }) tabularData: TabularData | null;

  showEditDialog: boolean = false;

  get headers() {
    return this.tabularData?.getHeadersList().map((header, i) => ({
      text: header,
      value: i.toString(),
    }));
  }

  get items() {
    return this.tabularData?.getRowsList().map((row) =>
      row.getColumnsList().reduce(
        (curr, column, i) => ({
          ...curr,
          [i]: column,
        }),
        {}
      )
    );
  }
}
