
import { Vue, Component } from "vue-property-decorator";
import ChooseCustomerType from "./ChooseCustomerType.vue";
import CustomerInfo from "./residential/CustomerInfo.vue";
import PreviousContract, { PreviousContractDetails } from "./residential/PreviousContract.vue";
import PaymentDetails from "./residential/PaymentDetails.vue";
import PowerOfAttorney from "./residential/PowerOfAttorney.vue";
import SignContract from "./SignContract.vue";
import ConfirmTariffDetails from "./residential/ConfirmTariffDetails.vue";
import SignInStep from "./residential/SignInStep.vue";

type Step =
  | "customer-type"
  | "tariff-details"
  | "customer-info"
  | "sign-in"
  | "previous-contract"
  | "payment-details"
  | "power-of-attorney"
  | "sign-contract";

@Component({
  components: {
    ChooseCustomerType,
    CustomerInfo,
    PreviousContract,
    PaymentDetails,
    PowerOfAttorney,
    SignContract,
    ConfirmTariffDetails,
    SignInStep,
  },
})
export default class InnovativePowerQuestionnaire extends Vue {
  completedSteps: Step[] = [];
  step: Step = "customer-type";

  customerType: "commercial" | "residential" = "residential";

  previousContract: PreviousContractDetails | null = null;

  get nextBtnEnabled(): boolean {
    return this.step !== "sign-contract";
  }

  get continueBtnText(): string {
    return this.step === "tariff-details" ? "Angebot akzeptieren" : "Weiter";
  }

  get nextStep(): Step | null {
    switch (this.step) {
      case "customer-type":
        return "tariff-details";
      case "tariff-details":
        return "customer-info";
      case "customer-info":
        return "sign-in";
      case "sign-in":
        return "previous-contract";
      case "previous-contract":
        return "payment-details";
      case "payment-details":
        if (
          this.previousContract?.orderReason === "supplier-changed" &&
          this.previousContract.previousSupplierContract === "ie-cancels-contract"
        ) {
          return "power-of-attorney";
        } else {
          return "sign-contract";
        }
      case "power-of-attorney":
        return "sign-contract";
      default:
        return null;
    }
  }

  mounted(): void {
    // partnerAuthConfig.then((authConfig) => {
    //   if (authConfig.getPartnerId() !== "ip") {
    //     this.$router.replace(paths.home);
    //   }
    // });
  }

  back() {
    const prevStep = this.completedSteps[this.completedSteps.length - 1];
    this.completedSteps = this.completedSteps.slice(0, -1);
    this.step = prevStep;
  }

  next() {
    const nextStep = this.nextStep;

    if (nextStep) {
      this.completedSteps.push(this.step);
      this.step = nextStep;
    }
  }
}
