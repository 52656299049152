
import DevicesTable, { SearchParams } from "@/components/devices-table/DevicesTable.vue";
import spaces from "@/store/modules/spaces";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetPartnerDevicesRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { deviceServiceClient } from "@/config/service-clients";

@Component({
  components: {
    DevicesTable,
  },
})
export default class AdminDevicesOverview extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  partnerDevices: RemoteData<UserError, Device[]> = initialized;
  devicePages: RemoteData<UserError, number> = initialized;

  itemsPerPage = 10;
  filteredSpaceId: string | null = null;
  page = 1;
  search: string = "";

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    if (this.selectedChildPartner.data) {
      this.loadDevices();
    }
  }

  beforeMount(): void {
    this.filteredSpaceId = spaces.adminSelectedSpaceId;
    const search = (this.$route.query.search as string) ?? "";
    const page = typeof this.$route.query.page === "string" ? parseInt(this.$route.query.page, 10) : 1;
    if (search == "" && page == 1) {
      this.loadDevices();
    }
  }

  async searchParamsInput(params: SearchParams): Promise<void> {
    this.page = params.page;
    this.search = params.search;
    this.loadDevices();
  }

  async loadDevices(): Promise<void> {
    const request = new GetPartnerDevicesRequest();
    request.setPageSize(this.itemsPerPage);
    request.setPage(this.page - 1);
    if (this.filteredSpaceId) {
      request.setSpaceId(this.filteredSpaceId);
    }
    if (this.search != "") {
      request.setSearchFilter(this.search);
    }
    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      this.partnerDevices = pending;
      const response = await deviceServiceClient.getPartnerDevices(request, {});
      this.partnerDevices = success(response.getDevicesList());
      this.devicePages = success(response.getTotalNumPages());
    } catch (error) {
      this.partnerDevices = failure(userErrorFrom(error));
      this.devicePages = failure(userErrorFrom(error));
    }
  }
}
