
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Trigger } from "zaehlerfreunde-proto-types/automations_pb";
import DeviceReadingTriggerComponent from "./DeviceReadingTrigger.vue";
import PriceTriggerComponent from "./PriceTrigger.vue";
import StateOfChargeComponent from "./StateOfChargeTrigger.vue";

@Component({
  components: {
    DeviceReadingTriggerComponent,
    PriceTriggerComponent,
    StateOfChargeComponent,
  },
})
export default class TriggerComponent extends Vue {
  TriggerType = Trigger.TypeCase;

  @Prop()
  value: Trigger;

  @Prop()
  editable: boolean;

  editing: boolean = true;
  valid: boolean = false;

  mounted(): void {
    this.editing = this.editable;
  }

  onFinishedEditingClicked(): void {
    this.editing = false;
    this.$emit("input", this.value);
  }

  onDeleteClicked(): void {
    this.$emit("delete");
  }
}
