
import { Vue, Component } from "vue-property-decorator";
import CreateSpaceDialog from "@/components/create-space/CreateSpaceDialog.vue";

@Component({
  components: {
    CreateSpaceDialog,
  },
})
export default class CreateFirstSpaceReminder extends Vue {
  showCreateSpaceDialog = false;
}
