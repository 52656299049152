
import { pending, failure, success, initialized, RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { BitShakeRegistrationDetails, DeviceRegistration } from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  BitShakeMeter,
  GetBitShakeMeterScriptRequest,
  GetBitShakeSupportedMetersRequest,
  RegisterDeviceRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { paths } from "@/router/routes";
import { ipRules } from "@/utils/rules";
import { partnerModule } from "@/store/modules/partner";

@Component
export default class BitShakeConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;
  @partnerModule.Getter partnerName: string;

  ip: string | null = null;
  ipRules = ipRules;
  meter: string | null = null;
  copied: boolean = false;
  tokenCopied: boolean = false;
  bitShakeToken: string;
  infoRead: boolean = false;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  meterScript: RemoteData<UserError, string> = initialized;
  meters: RemoteData<UserError, BitShakeMeter[]> = initialized;
  paths = paths;

  get explanation(): string {
    return `BitShake-Geräte können nur über die mobile App automatisch verbunden werden. Im folgenden findest du die Links zu der App für den Apple und Play Store. Alternativ kannst du das Gerät auch manuell über die Web App verbinden.`;
  }
  get ipAddress(): string {
    return `http://${this.ip}`;
  }

  meterName(meter: BitShakeMeter): string {
    return meter.getName();
  }

  meterValue(meter: BitShakeMeter): string {
    return meter.getValue();
  }

  back(): void {
    this.$router.push(paths.platform.devices);
  }

  async mounted(): Promise<void> {
    const request = new GetBitShakeSupportedMetersRequest();

    try {
      const response = await deviceServiceClient.getBitShakeSupportedMeters(request, {});
      this.meters = success(response.getMetersList());
    } catch (error) {
      this.meters = failure(userErrorFrom(error));
    }
  }

  get infoCompleted(): boolean {
    return (
      (!this.ipRules.some((rule) => typeof rule(this.ip ?? "") === "string") ?? false) &&
      this.ip !== null &&
      this.meter !== null
    );
  }

  copyScript(): void {
    navigator.clipboard.writeText(this.meterScript.data ?? "");
    this.copied = true;
  }
  copyToken(): void {
    navigator.clipboard.writeText(this.bitShakeToken ?? "");
    this.tokenCopied = true;
  }

  async onRegisterClicked(): Promise<void> {
    await this.registerDevice();
  }

  async registerDevice(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice ?? "false");
    registration.setSpaceMapping(spaceMapping);

    const details = new BitShakeRegistrationDetails();
    if (this.ip) {
      details.setIp(this.ip);
    }

    registration.setBitshake(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;

    try {
      const response = await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      this.bitShakeToken = response.getBitShakeToken();
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }

  async getScript(): Promise<void> {
    const request = new GetBitShakeMeterScriptRequest();

    if (this.meter != null) {
      request.setMeterValue(this.meter);
      try {
        const response = await deviceServiceClient.getBitShakeMeterScript(request, {});

        const script = response.getScript();
        if (script) {
          this.meterScript = success(script);
          await this.registerDevice();
        }
      } catch (error) {
        this.meterScript = failure(userErrorFrom(error));
      }
    }
  }
}
