import { spaceServiceClient, uiServiceClient } from "@/config/service-clients";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { failure, initialized, pending, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { namespace } from "vuex-class";
import { GetSelectedSpaceRequest, SetSelectedSpaceRequest } from "zaehlerfreunde-central/ui_service_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetChildSpacesRequest, GetSpacePermissionsRequest, GetSpacesRequest } from "zaehlerfreunde-central/space_service_pb";
import features from "./features";
import { AccessPermission } from "zaehlerfreunde-proto-types/permissions_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "spaces",
})
class SpacesModule extends VuexModule {
  spaces: RemoteData<UserError, Space[]> = initialized;
  selectedSpace: Space | null = null;
  selectedSpaceByAdmin: Space | null = null;
  childSpaces: RemoteData<UserError, Space[]> = initialized;
  spaceAccessPermissions: RemoteData<UserError, AccessPermission[]> = initialized;

  get accessPermissions(): AccessPermission[] {
    return this.spaceAccessPermissions.data ?? [];
  }

  get selectedSpaceId(): string {
    return this.selectedSpace?.getId() ?? "";
  }

  get adminSelectedSpaceId(): string {
    return this.selectedSpaceByAdmin?.getId() ?? "";
  }

  get selectedSpaceName(): string {
    return this.selectedSpace?.getName() ?? "";
  }

  get spaceSelected(): boolean {
    return this.selectedSpace !== null;
  }

  get spacesWithoutParentSpace(): Space[] {
    return this.spaces.list.filter((space) => !space.getParentSpaceId());
  }

  @Action
  async getSpaces() {
    const getSpaceRequest = new GetSpacesRequest();
    const getSelectedSpaceRequest = new GetSelectedSpaceRequest();

    try {
      this.setSpaces(pending);
      const getSpaceResponse = await spaceServiceClient.getSpaces(getSpaceRequest, {});
      const spaces = getSpaceResponse.getSpacesList();

      if (spaces.length > 0) {
        try {
          const getSelectedStateResponse = await uiServiceClient.getSelectedSpace(getSelectedSpaceRequest, {});
          const savedSelectedSpaceId = getSelectedStateResponse.getSpaceId();
          const savedSelectedSpace = spaces.find((val) => val.getId() === savedSelectedSpaceId);
          if (savedSelectedSpace) {
            this._setSelectedSpace(savedSelectedSpace);
          } else {
            this._setSelectedSpace(spaces[0]);
          }
        } catch (error) {
          this._setSelectedSpace(spaces[0]);
        }
      } else {
        this._setSelectedSpace(null);
      }
      this.setSpaces(success(spaces));
    } catch (error) {
      this.setSpaces(failure(userErrorFrom(error)));
    }
  }

  @Action
  async setSelectedSpace(space: Space) {
    const spaceId = space.getId();
    const request = new SetSelectedSpaceRequest();
    request.setSpaceId(spaceId);
    this._setSelectedSpace(space);
    try {
      await uiServiceClient.setSelectedSpace(request, {});
    } catch (error) {
      console.log("Failed to set selected Space");
    }
  }

  @Action
  async getChildSpaces() {
    const getChildSpaceRequest = new GetChildSpacesRequest();
    getChildSpaceRequest.setSpaceId(this.selectedSpaceId);

    try {
      this.setChildSpaces(pending);
      const getChildSpacesResponse = await spaceServiceClient.getChildSpaces(getChildSpaceRequest, {});
      this.setChildSpaces(success(getChildSpacesResponse.getChildSpacesList()));
    } catch (error) {
      this.setChildSpaces(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getAccessPermissions() {
    try {
        this.setAccessPermissions(pending);
        const response = await spaceServiceClient.getSpacePermissions(
          new GetSpacePermissionsRequest().setSpaceId(this.selectedSpaceId),
          {}
        );
  
        this.setAccessPermissions(success(response.getPermissionsList()));
      } catch (error) {
        this.setAccessPermissions(failure(userErrorFrom(error)));
      }
  }

  @Mutation
  setSpaces(spaces: RemoteData<UserError, Space[]>) {
    this.spaces = spaces;
  }

  @Mutation
  _setSelectedSpace(space: Space | null) {
    this.selectedSpace = space;
    features.getFeatures();
  }

  @Mutation
  setSelectedSpaceByAdmin(space: Space | null) {
    this.selectedSpaceByAdmin = space;
  }

  @Mutation
  setChildSpaces(childSpaces: RemoteData<UserError, Space[]>) {
    this.childSpaces = childSpaces;
  }

  @Mutation
  setAccessPermissions(accessPermissions: RemoteData<UserError, AccessPermission[]>) {
    this.spaceAccessPermissions = accessPermissions;
  }
}

export const spacesModule = namespace("spaces");
export default getModule(SpacesModule);
