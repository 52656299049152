
import { devicesModule } from "@/store/modules/devices";
import { RemoteData, map } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { WallboxAction, WallboxActionType } from "zaehlerfreunde-proto-types/automations_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class WallboxActionComponent extends Vue {
  @Prop()
  wallboxAction: WallboxAction;

  @Prop()
  editing: boolean;

  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  selectedWallbox: Device | null = null;
  selectedWallboxAction: WallboxActionType = WallboxActionType.START_CHARGING;

  @Watch("selectedWallbox")
  onSelectedWallboxChanged(): void {
    this.wallboxAction?.setDeviceId(this.selectedWallbox?.getId() ?? "");
    this.wallboxAction.setActionType(this.selectedWallboxAction);
    this.$emit("valid", this.selectedWallbox !== null);
  }

  mounted() {
    this.selectedWallbox = this.devices.list.find((val) => val.getId() === this.wallboxAction.getDeviceId()) ?? null;
    this.selectedWallboxAction = this.wallboxAction.getActionType();
  }

  get ocppDevices(): RemoteData<UserError, Device[]> {
    return map(this.devices, (devices) => devices.filter((d) => d.getProvider() === Device.Provider.OCPP));
  }

  wallboxActionLabel(actionType: WallboxActionType): string {
    switch (actionType) {
      case WallboxActionType.START_CHARGING:
        return "Starten";
      case WallboxActionType.STOP_CHARGING:
        return "Beenden";
      default:
        return "";
    }
  }

  onWallboxActionType(wallboxActionType: WallboxActionType): void {
    this.wallboxAction.setActionType(wallboxActionType);
  }

  wallboxActions = [
    {
      label: this.wallboxActionLabel(WallboxActionType.START_CHARGING),
      value: WallboxActionType.START_CHARGING,
    },
    {
      label: this.wallboxActionLabel(WallboxActionType.STOP_CHARGING),
      value: WallboxActionType.STOP_CHARGING,
    },
  ];
}
