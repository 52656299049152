import { namespace } from "vuex-class";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "..";
import { RemoteData, failure, initialized, pending, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { GetDataImportsRequest } from "zaehlerfreunde-central/device_service_pb";
import { deviceServiceClient } from "@/config/service-clients";
import { DataImport } from "zaehlerfreunde-proto-types/data_import_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "dataimports",
})
class DataImportsModule extends VuexModule {
  dataImports: RemoteData<UserError, DataImport[]> = initialized;

  @Action
  async getDataImports() {
    try {
      this.setDataImports(pending);

      const request = new GetDataImportsRequest();
      const response = await deviceServiceClient.getDataImports(request, {});

      this.setDataImports(success(response.getDataImportsList()));
    } catch (error) {
      this.setDataImports(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setDataImports(v: RemoteData<UserError, DataImport[]>) {
    this.dataImports = v;
  }
}

export const dataImportsModule = namespace("dataimports");
export default getModule(DataImportsModule);
