import { interceptors } from "@/utils/request-interceptors";
import { getEnvironment } from "./env";
import { AdvisoryServiceClient } from "zaehlerfreunde-central/Advisory_serviceServiceClientPb";
import { MiscServiceClient } from "zaehlerfreunde-central/Misc_serviceServiceClientPb";
import { DeviceServiceClient } from "zaehlerfreunde-central/Device_serviceServiceClientPb";
import { PartnerServiceClient } from "zaehlerfreunde-central/Partner_serviceServiceClientPb";
import { ReadingServiceClient } from "zaehlerfreunde-central/Reading_serviceServiceClientPb";
import { TariffServiceClient } from "zaehlerfreunde-central/Tariff_serviceServiceClientPb";
import { UIServiceClient } from "zaehlerfreunde-central/Ui_serviceServiceClientPb";
import { UserServiceClient } from "zaehlerfreunde-central/User_serviceServiceClientPb";
import { SpaceServiceClient } from "zaehlerfreunde-central/Space_serviceServiceClientPb";
import { LandlordToTenantServiceClient } from "zaehlerfreunde-central/Landlord_to_tenant_serviceServiceClientPb";

const environment = getEnvironment();

export const advisoryServiceClient = new AdvisoryServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const apiClient = new MiscServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const deviceServiceClient = new DeviceServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const partnerServiceClient = new PartnerServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const readingsServiceClient = new ReadingServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const tariffServiceClient = new TariffServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const uiServiceClient = new UIServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const userServiceClient = new UserServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const spaceServiceClient = new SpaceServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});

export const landlordToTenantServiceClient = new LandlordToTenantServiceClient(environment.serviceUrl, null, {
  unaryInterceptors: interceptors,
});
