
import account from "@/store/modules/account";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ChangePassword extends Vue {
  onCancelClicked(): void {
    this.$emit("cancel");
  }

  onSaveClicked(): void {
    account.setNewPassword();
    this.$emit("save");
  }
}
