import { userErrorFrom } from "@/types/user-error";
import { GetAuthConfigRequest } from "zaehlerfreunde-central/partner_service_pb";
import { AuthConfig } from "zaehlerfreunde-proto-types/partners_pb";
import { getEnvironment } from "./env";
import { partnerServiceClient } from "./service-clients";

export const partnerAuthConfig = new Promise<AuthConfig>((resolve, reject) => {
  const environment = getEnvironment();
  const urlParams = new URLSearchParams(window.location.search);
  const partnerParam = urlParams.get("zf_partner");
  const storedPartner = localStorage.getItem("partner");
  const request = new GetAuthConfigRequest();

  if (!environment.isProd && partnerParam) {
    localStorage.setItem("partner", partnerParam ?? "");
    console.log("Storing partner :" + localStorage.getItem("partner"));
    request.setPartnerId(partnerParam);
  } else if (!environment.isProd && storedPartner) {
    console.log("Loading partner from local storage: " + storedPartner);
    request.setPartnerId(storedPartner);
  } else {
    request.setDomain(window.location.origin);
  }

  partnerServiceClient
    .getAuthConfig(request, {})
    .then((response) => {
      const authConfig = response.getAuthConfig();
      if (authConfig) {
        resolve(authConfig);
      } else {
        reject("failed to get partner auth config");
      }
    })
    .catch((error) => {
      console.error(userErrorFrom(error));
      reject(error);
    });
});
