
import { paths } from "@/router/routes";
import { formatNumber } from "@/utils/number-utils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";

@Component
export default class TariffSwitchPending extends Vue {
  @Prop({ default: null }) pendingTariffSwitch: TariffSwitch;

  get savings(): string {
    return formatNumber(this.pendingTariffSwitch.getSearchResult()?.getSavings() ?? 0);
  }

  get rows(): { name: string; value: string }[] {
    return [
      {
        name: "Anbieter",
        value: this.pendingTariffSwitch.getSearchResult()?.getSupplier()?.getName() ?? "",
      },
      {
        name: "Tarif",
        value: this.pendingTariffSwitch.getSearchResult()?.getTariffName() ?? "",
      },
      {
        name: "Arbeitspreis",
        value: `${formatNumber(this.pendingTariffSwitch.getSearchResult()?.getPricePerKwh() ?? 0)} Cent/kWh`,
      },
      {
        name: "Grundpreis",
        value: `${formatNumber((this.pendingTariffSwitch.getSearchResult()?.getBasePrice() ?? 0) / 12)} €/Monat`,
      },
      {
        name: "Kosten",
        value: `${formatNumber((this.pendingTariffSwitch.getSearchResult()?.getPriceFirstYear() ?? 0) / 12)} €/Monat`,
      },
    ];
  }

  onGoToTariffSwitchClicked(): void {
    this.$router.push(paths.platform.tariffSwitch);
  }
}
