
import InvitesTable from "@/components/invites-table/InvitesTable.vue";
import { RemoteData, initialized, pending, failure, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import SendInviteDialog from "@/components/send-invite/SendInviteDialog.vue";

import { userServiceClient } from "@/config/service-clients";
import { Invite } from "zaehlerfreunde-proto-types/invite_pb";
import { GetInvitesRequest } from "zaehlerfreunde-central/user_service_pb";

@Component({
  components: {
    InvitesTable,
    SendInviteDialog,
  },
})
export default class SpaceInvites extends Vue {
  invites: RemoteData<UserError, Invite[]> = initialized;
  numInvitePages: RemoteData<UserError, number> = initialized;

  @Prop({ default: initialized }) spaceInfo: RemoteData<UserError, Space>;

  showInviteDialog: boolean = false;

  itemsPerPage = 10;

  headerControls = [
    {
      icon: "mdi-account-plus",
      event: "send-invite",
    },
  ];

  beforeMount(): void {
    this.loadInvites(0);
  }

  async loadInvites(page: number): Promise<void> {
    this.invites = pending;

    const request = new GetInvitesRequest();
    request.setPage(page);
    request.setPageSize(this.itemsPerPage);
    request.setSpaceId(this.spaceInfo.data?.getId() ?? "");

    try {
      const response = await userServiceClient.getInvites(request, {});
      this.invites = success(response.getInvitesList());
      this.numInvitePages = success(response.getTotalNumPages());
    } catch (error) {
      this.invites = failure(userErrorFrom(error));
    }
  }
}
