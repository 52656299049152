
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SearchBar extends Vue {
  @Prop({ default: 500 }) delay: number;
  @Prop() value: string;

  search: string = "";
  timeoutId: number | null = null;

  @Watch("value")
  onValueChanged(): void {
    this.search = this.value;
  }

  @Watch("search")
  onSearchChanged(): void {
    if (this.search === this.value) {
      return;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    if (this.search === "") {
      this.$emit("input", this.search);
    } else {
      this.timeoutId = setTimeout(() => this.$emit("input", this.search), this.delay);
    }
  }
}
