
import { partnerServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { CreateClientSecretRequest } from "zaehlerfreunde-central/partner_service_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

interface ClientCredentials {
  clientId: string;
  clientSecret: string;
}

@Component
export default class CreateClientSecret extends Vue {
  name: string = "";
  clientCreds: RemoteData<UserError, ClientCredentials> = initialized;
  showSecretCopiedSnackbar = false;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  get infoComplete(): boolean {
    return this.name.length > 0;
  }

  async generateSecret(): Promise<void> {
    try {
      this.clientCreds = pending;

      const request = new CreateClientSecretRequest();
      request.setName(this.name);

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      const response = await partnerServiceClient.createClientSecret(request, {});

      this.clientCreds = success({
        clientId: response.getClientId(),
        clientSecret: response.getClientSecret(),
      });
      this.$emit("secret-generated");
    } catch (error) {
      this.clientCreds = failure(userErrorFrom(error));
    }
  }

  copySecretToClipboard(): void {
    navigator.clipboard.writeText(this.clientCreds.data?.clientSecret ?? "");
    this.showSecretCopiedSnackbar = true;
  }
  copyClienIdToClipboard(): void {
    navigator.clipboard.writeText(this.clientCreds.data?.clientId ?? "");
    this.showSecretCopiedSnackbar = true;
  }
}
