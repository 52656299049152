import "./class-component-hooks";
import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import VueMoment from "vue-moment";
import Moment from "moment";
import { Auth0Plugin } from "./auth";
import { isPending, RemoteData, toNullable, toNullableError } from "./store/utils/remote-data";
import AOS from "aos";
import "aos/dist/aos.css";
import { formatNumber } from "./utils/number-utils";
import { datadogRum } from "@datadog/browser-rum";
import { formatCurrency, formatMoney } from "./utils/proto-utils";
import Card from "@/components/core/Card.vue";
import Widget from "@/components/core/widget/Widget.vue";
import Progress from "@/components/core/Progress.vue";
import Alert from "@/components/core/Alert.vue";
import RequestStatus from "@/components/core/RequestStatus.vue";
import Group from "@/components/core/Group.vue";
import Funnel from "@/components/core/funnel/Funnel.vue";
import FunnelStep from "@/components/core/funnel/FunnelStep.vue";
import OptionSelection from "@/components/core/funnel/OptionSelection.vue";
import Button from "@/components/core/Button.vue";
import Dialog from "@/components/core/Dialog.vue";
import Table from "@/components/core/Table.vue";
import DatePicker from "@/components/core/DatePicker.vue";
import ValueWithUnit from "@/components/core/ValueWithUnit.vue";
import Value from "@/components/core/Value.vue";
import Unit from "@/components/core/Unit.vue";
import Icon from "@/components/core/Icon.vue";
import Detail from "@/components/core/Detail.vue";
import VueGtag from "vue-gtag";
import { getVisitorType, visitorType$ } from "./observables/visitor-type-observables";
import { getLocale, i18n } from "./locales/i18n";
import { partnerAuthConfig } from "./config/auth-config";
import { getEnvironment } from "./config/env";
import { ErrorCode } from "./pages/shared";
import { paths } from "./router/routes";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

partnerAuthConfig
  .then((authConfig) => {
    const environment = getEnvironment();
    const domain = environment.domain;
    const audience = environment.audience;
    const clientId = authConfig.getAuth0ClientId();

    if (environment.datadogEnabled && environment.datadogApplicationId && environment.datadogClientToken) {
      datadogRum.init({
        applicationId: environment.datadogApplicationId,
        clientToken: environment.datadogClientToken,
        site: "datadoghq.eu",
        service: location.hostname,
        env: environment.id.valueOf(),
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        premiumSampleRate: 0,
        trackInteractions: false,
        allowedTracingOrigins: ["https://api.stage.zaehlerfreunde.com", "https://api.prod.zaehlerfreunde.com"],
      });
    }

    Vue.config.productionTip = false;

    Vue.filter("dateOnly", function (value: number) {
      if (value) {
        return moment.unix(value).locale("de").format("DD.MM.YYYY");
      }
    });

    Vue.filter("timeOnly", function (value: number) {
      if (value) {
        return moment.unix(value).locale("de").format("HH:mm");
      }
    });

    Vue.filter("formatDate", function (value: number) {
      if (value) {
        return moment.unix(value).locale("de").format("DD.MM.YYYY HH:mm");
      }
    });

    Vue.filter("formatNumber", function (value: number) {
      return formatNumber(value);
    });

    Vue.filter("formatMoney", (value) => formatMoney(value));

    Vue.filter("formatCurrency", (value) => formatCurrency(value));

    Vue.filter("formatDuration", (value: number) => moment.duration(value).locale("de").humanize());

    Vue.filter("toList", function (value: RemoteData<unknown, unknown>) {
      return toNullable(value) ?? [];
    });

    Vue.filter("toNullable", function (value: RemoteData<unknown, unknown>) {
      return toNullable(value);
    });

    Vue.filter("isPending", function (value: RemoteData<unknown, unknown>) {
      return isPending(value);
    });

    Vue.filter("toNullableError", function (value: RemoteData<unknown, unknown>) {
      return toNullableError(value);
    });

    Vue.component("zf-card", Card);
    Vue.component("zf-widget", Widget);
    Vue.component("zf-progress", Progress);
    Vue.component("zf-alert", Alert);
    Vue.component("zf-request-status", RequestStatus);
    Vue.component("zf-group", Group);
    Vue.component("zf-funnel", Funnel);
    Vue.component("zf-funnel-step", FunnelStep);
    Vue.component("zf-option-selection", OptionSelection);
    Vue.component("zf-btn", Button);
    Vue.component("zf-dialog", Dialog);
    Vue.component("zf-table", Table);
    Vue.component("zf-date-picker", DatePicker);
    Vue.component("zf-value-with-unit", ValueWithUnit);
    Vue.component("zf-value", Value);
    Vue.component("zf-unit", Unit);
    Vue.component("zf-icon", Icon);
    Vue.component("zf-detail", Detail);

    Chart.pluginService.register(annotationPlugin);

    Moment.locale(window.navigator.language);
    Vue.use(VueMoment, { moment: Moment });

    Vue.use(Auth0Plugin, {
      domain,
      clientId,
      audience,
    });

    i18n.locale = getLocale();

    visitorType$.next(getVisitorType());

    Vue.use(VueMeta, {});

    Vue.use(
      VueGtag,
      {
        config: {
          id: "UA-223177521-1",
          params: {
            anonymize_ip: true,
          },
        },
        // Disable by default; only enable if user gives consent
        enabled: false,
        includes: [
          {
            id: "AW-10831568791",
            params: {
              anonymize_ip: true,
            },
          },
        ],
      },
      router
    );

    new Vue({
      router,
      store,
      vuetify,
      mounted() {
        AOS.init();
      },
      render: (h) => h(App),
      i18n,
    }).$mount("#app");
  })
  .catch((error) => {
    const errorQuery: { [key: string]: string } = {
      code: ErrorCode.UnableToReachServer,
    };

    Vue.component("zf-alert", Alert);
    Vue.component("zf-dialog", Dialog);

    new Vue({
      router,
      store,
      vuetify,
      mounted() {
        AOS.init();
      },
      render: (h) => h(App),
      i18n,
    }).$mount("#app");

    router.push({ path: paths.error, query: errorQuery });
  });
