
import { Component, Vue } from "vue-property-decorator";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { tipsModule } from "@/store/modules/tips";
import { spacesModule } from "@/store/modules/spaces";
import AddEnergyConsumersReminder from "./AddEnergyConsumersReminder.vue";
import TipCarouselItem from "../../tips/TipCarouselItem.vue";
import { getTipsPerPage } from "../../tips/TipsCarousel.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { Tip, EnergyConsumer } from "zaehlerfreunde-proto-types/tips_pb";

@Component({
  components: {
    AddEnergyConsumersReminder,
    TipCarouselItem,
  },
})
export default class Tips extends Vue {
  @spacesModule.State selectedSpace: Space | null;
  @tipsModule.State tips: RemoteData<UserError, Tip[]>;
  @tipsModule.State energyConsumers: RemoteData<UserError, EnergyConsumer[]>;

  numDisplayedTips = getTipsPerPage();

  get energyConsumersMissing(): boolean {
    return (
      !!this.selectedSpace && this.energyConsumers.succeeded && !this.energyConsumers.list.some((e) => e.getCount() > 0)
    );
  }

  resizeEventHandler(): void {
    this.numDisplayedTips = getTipsPerPage();
  }

  created(): void {
    window.addEventListener("resize", this.resizeEventHandler);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resizeEventHandler);
  }

  get tipsToDisplay(): Tip[] {
    const personalisedTips = this.tips.list.filter((t) => t.getCategory() === Tip.Category.PERSONALISED);

    if (personalisedTips.length < this.numDisplayedTips) {
      personalisedTips.push(
        ...this.tips.list.sort(() => 0.5 - Math.random()).slice(0, this.numDisplayedTips - personalisedTips.length)
      );
    }

    return personalisedTips
      .sort(() => 0.5 - Math.random())
      .slice(0, this.numDisplayedTips - (this.energyConsumersMissing ? 1 : 0));
  }
}
