// source: types/landlord_to_tenant.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_pb = require('../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
goog.exportSymbol('proto.types.LandlordToTenantEnergyTariff', null, global);
goog.exportSymbol('proto.types.LandlordToTenantMeterType', null, global);
goog.exportSymbol('proto.types.LandlordToTenantProject', null, global);
goog.exportSymbol('proto.types.LandlordToTenantProjectMeter', null, global);
goog.exportSymbol('proto.types.LandlordToTenantProjectSpace', null, global);
goog.exportSymbol('proto.types.LandlordToTenantSpaceType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LandlordToTenantProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LandlordToTenantProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LandlordToTenantProject.displayName = 'proto.types.LandlordToTenantProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LandlordToTenantProjectMeter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LandlordToTenantProjectMeter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LandlordToTenantProjectMeter.displayName = 'proto.types.LandlordToTenantProjectMeter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LandlordToTenantProjectSpace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LandlordToTenantProjectSpace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LandlordToTenantProjectSpace.displayName = 'proto.types.LandlordToTenantProjectSpace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.LandlordToTenantEnergyTariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.LandlordToTenantEnergyTariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.LandlordToTenantEnergyTariff.displayName = 'proto.types.LandlordToTenantEnergyTariff';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LandlordToTenantProject.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LandlordToTenantProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LandlordToTenantProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    partnerId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LandlordToTenantProject}
 */
proto.types.LandlordToTenantProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LandlordToTenantProject;
  return proto.types.LandlordToTenantProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LandlordToTenantProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LandlordToTenantProject}
 */
proto.types.LandlordToTenantProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LandlordToTenantProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LandlordToTenantProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LandlordToTenantProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPartnerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.LandlordToTenantProject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProject} returns this
 */
proto.types.LandlordToTenantProject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.types.LandlordToTenantProject.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProject} returns this
 */
proto.types.LandlordToTenantProject.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.types.LandlordToTenantProject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProject} returns this
 */
proto.types.LandlordToTenantProject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.types.LandlordToTenantProject.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProject} returns this
 */
proto.types.LandlordToTenantProject.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string partner_id = 5;
 * @return {string}
 */
proto.types.LandlordToTenantProject.prototype.getPartnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProject} returns this
 */
proto.types.LandlordToTenantProject.prototype.setPartnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LandlordToTenantProjectMeter.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LandlordToTenantProjectMeter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LandlordToTenantProjectMeter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantProjectMeter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    device: (f = msg.getDevice()) && types_device_pb.Device.toObject(includeInstance, f),
    meterType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LandlordToTenantProjectMeter}
 */
proto.types.LandlordToTenantProjectMeter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LandlordToTenantProjectMeter;
  return proto.types.LandlordToTenantProjectMeter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LandlordToTenantProjectMeter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LandlordToTenantProjectMeter}
 */
proto.types.LandlordToTenantProjectMeter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 4:
      var value = /** @type {!proto.types.LandlordToTenantMeterType} */ (reader.readEnum());
      msg.setMeterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LandlordToTenantProjectMeter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LandlordToTenantProjectMeter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LandlordToTenantProjectMeter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantProjectMeter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getMeterType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.LandlordToTenantProjectMeter.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProjectMeter} returns this
 */
proto.types.LandlordToTenantProjectMeter.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.types.LandlordToTenantProjectMeter.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProjectMeter} returns this
 */
proto.types.LandlordToTenantProjectMeter.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Device device = 3;
 * @return {?proto.types.Device}
 */
proto.types.LandlordToTenantProjectMeter.prototype.getDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 3));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.types.LandlordToTenantProjectMeter} returns this
*/
proto.types.LandlordToTenantProjectMeter.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.LandlordToTenantProjectMeter} returns this
 */
proto.types.LandlordToTenantProjectMeter.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LandlordToTenantProjectMeter.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LandlordToTenantMeterType meter_type = 4;
 * @return {!proto.types.LandlordToTenantMeterType}
 */
proto.types.LandlordToTenantProjectMeter.prototype.getMeterType = function() {
  return /** @type {!proto.types.LandlordToTenantMeterType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.LandlordToTenantMeterType} value
 * @return {!proto.types.LandlordToTenantProjectMeter} returns this
 */
proto.types.LandlordToTenantProjectMeter.prototype.setMeterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LandlordToTenantProjectSpace.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LandlordToTenantProjectSpace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LandlordToTenantProjectSpace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantProjectSpace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mainDevice: (f = msg.getMainDevice()) && types_device_pb.Device.toObject(includeInstance, f),
    spaceType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LandlordToTenantProjectSpace}
 */
proto.types.LandlordToTenantProjectSpace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LandlordToTenantProjectSpace;
  return proto.types.LandlordToTenantProjectSpace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LandlordToTenantProjectSpace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LandlordToTenantProjectSpace}
 */
proto.types.LandlordToTenantProjectSpace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new types_device_pb.Device;
      reader.readMessage(value,types_device_pb.Device.deserializeBinaryFromReader);
      msg.setMainDevice(value);
      break;
    case 4:
      var value = /** @type {!proto.types.LandlordToTenantSpaceType} */ (reader.readEnum());
      msg.setSpaceType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LandlordToTenantProjectSpace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LandlordToTenantProjectSpace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LandlordToTenantProjectSpace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantProjectSpace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMainDevice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_device_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getSpaceType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.LandlordToTenantProjectSpace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
 */
proto.types.LandlordToTenantProjectSpace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.types.LandlordToTenantProjectSpace.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
 */
proto.types.LandlordToTenantProjectSpace.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Device main_device = 3;
 * @return {?proto.types.Device}
 */
proto.types.LandlordToTenantProjectSpace.prototype.getMainDevice = function() {
  return /** @type{?proto.types.Device} */ (
    jspb.Message.getWrapperField(this, types_device_pb.Device, 3));
};


/**
 * @param {?proto.types.Device|undefined} value
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
*/
proto.types.LandlordToTenantProjectSpace.prototype.setMainDevice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
 */
proto.types.LandlordToTenantProjectSpace.prototype.clearMainDevice = function() {
  return this.setMainDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LandlordToTenantProjectSpace.prototype.hasMainDevice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LandlordToTenantSpaceType space_type = 4;
 * @return {!proto.types.LandlordToTenantSpaceType}
 */
proto.types.LandlordToTenantProjectSpace.prototype.getSpaceType = function() {
  return /** @type {!proto.types.LandlordToTenantSpaceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.LandlordToTenantSpaceType} value
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
 */
proto.types.LandlordToTenantProjectSpace.prototype.setSpaceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.types.LandlordToTenantProjectSpace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
 */
proto.types.LandlordToTenantProjectSpace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string space_id = 6;
 * @return {string}
 */
proto.types.LandlordToTenantProjectSpace.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantProjectSpace} returns this
 */
proto.types.LandlordToTenantProjectSpace.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.toObject = function(opt_includeInstance) {
  return proto.types.LandlordToTenantEnergyTariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.LandlordToTenantEnergyTariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantEnergyTariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    residualPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    solarPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    mixedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    basePricePerMonth: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    mixedPriceTariff: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.LandlordToTenantEnergyTariff}
 */
proto.types.LandlordToTenantEnergyTariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.LandlordToTenantEnergyTariff;
  return proto.types.LandlordToTenantEnergyTariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.LandlordToTenantEnergyTariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.LandlordToTenantEnergyTariff}
 */
proto.types.LandlordToTenantEnergyTariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setResidualPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSolarPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMixedPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasePricePerMonth(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMixedPriceTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.LandlordToTenantEnergyTariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.LandlordToTenantEnergyTariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.LandlordToTenantEnergyTariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResidualPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSolarPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMixedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBasePricePerMonth();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMixedPriceTariff();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double residual_price = 2;
 * @return {number}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.getResidualPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.setResidualPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double solar_price = 3;
 * @return {number}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.getSolarPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.setSolarPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double mixed_price = 5;
 * @return {number}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.getMixedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.setMixedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double base_price_per_month = 4;
 * @return {number}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.getBasePricePerMonth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.setBasePricePerMonth = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool mixed_price_tariff = 6;
 * @return {boolean}
 */
proto.types.LandlordToTenantEnergyTariff.prototype.getMixedPriceTariff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.LandlordToTenantEnergyTariff} returns this
 */
proto.types.LandlordToTenantEnergyTariff.prototype.setMixedPriceTariff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.types.LandlordToTenantMeterType = {
  SOLAR_PANEL: 0,
  VIRTUAL: 1
};

/**
 * @enum {number}
 */
proto.types.LandlordToTenantSpaceType = {
  BUILDING: 0,
  FLAT: 1
};

goog.object.extend(exports, proto.types);
