
import { Component, Vue, Watch } from "vue-property-decorator";
import CookieBanner from "@/components/cookie-banner/CookieBanner.vue";
import { gtagInterceptor } from "./utils/request-interceptors";
import { partnerModule } from "./store/modules/partner";
import { RemoteData } from "./store/utils/remote-data";
import { UserError } from "./types/user-error";
import { Partner, PartnerCustomizationConfig } from "zaehlerfreunde-proto-types/partners_pb";
import de from "vuetify/src/locale/de";
import { getAuthService } from "./auth";

@Component({
  components: {
    CookieBanner,
  },
})
export default class App extends Vue {
  gradientBackground: boolean = false;

  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @partnerModule.State customizationConfig: RemoteData<UserError, PartnerCustomizationConfig>;

  metaInfo(): { title: string; titleTemplate: string } {
    return {
      title: this.partner.data?.getName() ?? "Zählerfreunde",
      titleTemplate: "%s",
    };
  }

  @Watch("$route")
  onRouteChanged(): void {
    const meta = this.$route.meta ?? {};
    this.gradientBackground = meta.gradientBackground ?? false;
  }

  mounted(): void {
    var r = document.querySelector(":root") as HTMLElement;

    this.$vuetify.lang.locales = { de };
    this.$vuetify.lang.current = "de";
    this.$vuetify.theme.dark = false;
    r.style.setProperty("--zf-text-color", "var(--zf-text-color-light)");
    r.style.setProperty("--zf-background", "var(--zf-background-light)");
    r.style.setProperty("--zf-box-shadow", "var(--zf-box-shadow-light)");
    r.style.setProperty("--zf-box-shadow-highlight", "var(--zf-box-shadow-highlight-light)");
    r.style.setProperty("--zf-elevated-background", "var(--zf-elevated-background-light)");
    r.style.setProperty("--zf-gray", "var(--zf-gray-light)");
    r.style.setProperty("--zf-border-color", "var(--zf-border-color-light)");
    r.style.setProperty("--zf-option-selected", "var(--zf-option-selected-light)");
    r.style.setProperty("--zf-option-hover", "var(--zf-option-hover-light)");

    gtagInterceptor.gtag = this.$gtag;

    this.onRouteChanged();

    const authService = getAuthService();
    authService.isAuthenticated.then((isAuthenticated) => {
      if (!isAuthenticated) {
        this.showChatbox();
      }
    });
  }

  @Watch("customizationConfig")
  onCustomizationConfigChanged() {
    if (this.customizationConfig.succeeded && !this.customizationConfig.data?.getHideChatbox()) {
      this.showChatbox();
    }
  }

  showChatbox() {
    let divScripts = document.getElementById("main");
    let newScript = document.createElement("script");
    newScript.src = "//code.tidio.co/tyiayrik5qyoeohqbfvj7tfkecnl75in.js";
    if (divScripts) {
      divScripts.appendChild(newScript);
    }
  }
}
