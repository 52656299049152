
import { devicesModule } from "@/store/modules/devices";
import { RemoteData, map } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DeviceAction, DeviceActionType } from "zaehlerfreunde-proto-types/automations_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component
export default class DeviceActionComponent extends Vue {
  @Prop()
  deviceAction: DeviceAction;

  @Prop()
  editing: boolean;

  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  selectedDevice: Device | null = null;
  selectedAction: DeviceActionType = DeviceActionType.TURN_ON;
  providersWithActionSupport: Device.Provider[] = [Device.Provider.MYSTROM];

  @Watch("selectedDevice")
  onSelectedDeviceChanged(): void {
    this.deviceAction.setDeviceId(this.selectedDevice?.getId() ?? "");
    this.deviceAction.setActionType(this.selectedAction);
    this.$emit("valid", this.selectedDevice !== null);
  }

  mounted() {
    this.selectedDevice = this.devices.list.find((val) => val.getId() === this.deviceAction.getDeviceId()) ?? null;
    this.selectedAction  = this.deviceAction.getActionType();
  }

  get devicesWithActionSupport(): RemoteData<UserError, Device[]> {
    return map(this.devices, (devices) =>
      devices.filter((d) => this.providersWithActionSupport.includes(d.getProvider()))
    );
  }

  actionLabel(actionType: DeviceActionType): string {
    switch (actionType) {
      case DeviceActionType.TURN_ON:
        return "Einschalten";
      case DeviceActionType.TURN_OFF:
        return "Ausschalten";
      default:
        return "";
    }
  }

  onDeviceActionType(deviceActionType: DeviceActionType): void {
    this.deviceAction.setActionType(deviceActionType);
  }

  actions = [
    {
      label: this.actionLabel(DeviceActionType.TURN_ON),
      value: DeviceActionType.TURN_ON,
    },
    {
      label: this.actionLabel(DeviceActionType.TURN_OFF),
      value: DeviceActionType.TURN_OFF,
    },
  ];
}
