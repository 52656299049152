
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import Chart, { ChartOptions } from "chart.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import spaces from "@/store/modules/spaces";
import { uiServiceClient } from "@/config/service-clients";
import { TimeInterval, TimePeriod } from "zaehlerfreunde-proto-types/time_data_pb";
import { formatNumber } from "@/utils/number-utils";
import { SpaceComparisonWidgetData, GetSpaceComparisonWidgetDataRequest } from "zaehlerfreunde-central/ui_service_pb";
import SpaceComparisonChart from "./SpaceComparisonChart.vue";

@Component({
  components: {
    SpaceComparisonChart,
  },
})
export default class SpaceComparison extends Vue {
  spaceComparisonWidgetData: RemoteData<UserError, SpaceComparisonWidgetData | null> = initialized;

  selectedTimePeriod: TimePeriod = TimePeriod.LAST_MONTH;
  cachedTimePeriodTabs: SpaceComparisonWidgetData.TimePeriodTab[] = [];

  onTimePeriodSelected(option: SpaceComparisonWidgetData.TimePeriodTab) {
    this.selectedTimePeriod = option.getValue();
    this.loadSpaceComparaisonData();
  }

  mounted(): void {
    this.loadSpaceComparaisonData();
  }

  async loadSpaceComparaisonData(): Promise<void> {
    if (spaces.selectedSpaceId) {
      this.spaceComparisonWidgetData = pending;
      const request = new GetSpaceComparisonWidgetDataRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setTimePeriod(this.selectedTimePeriod);
      request.setSourceComparand(GetSpaceComparisonWidgetDataRequest.Comparand.SPACE_CONSUMPTION);
      request.setTargetComparand(GetSpaceComparisonWidgetDataRequest.Comparand.AVERAGE_CONSUMPTION_OF_PEER_SPACES);

      await uiServiceClient
        .getSpaceComparisonWidgetData(request, {})
        .then((response) => {
          this.spaceComparisonWidgetData = success(response.getData() ?? null);
          this.cachedTimePeriodTabs = this.spaceComparisonWidgetData.data?.getTimePeriodTabsList() ?? [];
        })
        .catch((error) => (this.spaceComparisonWidgetData = failure(userErrorFrom(error))));
    }
  }

  chartStyles = {
    height: "100%",
    width: "100%",
    margin: "auto",
  };

  get chartData(): Chart.ChartData | null {
    if (this.spaceComparisonWidgetData.data) {
      return {
        labels: this.spaceComparisonWidgetData.data.getComparisonValuesList().map((s) => s.getLabel()),
        datasets: [
          {
            data: this.spaceComparisonWidgetData.data.getComparisonValuesList().map((s) => s.getValue()),
            backgroundColor: this.spaceComparisonWidgetData.data.getComparisonValuesList().map((s) => s.getColor()),
            label: this.spaceComparisonWidgetData.data
              .getComparisonValuesList()
              .map((x) => x.getUnit())
              .join(","),
          },
        ],
      };
    } else return null;
  }

  chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: "index",
      displayColors: false,
      callbacks: {
        label: (item, data) => {
          if (data.datasets) {
            return `${formatNumber(Number(item.value))} ${data.datasets[0].label?.split(",")[item.index ?? 0]}`;
          }
          return "";
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          },
        },
      ],
    },
  };
}
