
import { deviceServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceRegistration, MsconsRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";

@Component
export default class MsconsConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  LocationIdType = MsconsRegistrationDetails.LocationIdType;

  registrationCall: RemoteCall<UserError> = initialized;
  locationIdType: MsconsRegistrationDetails.LocationIdType = MsconsRegistrationDetails.LocationIdType.MEASUREMENT;
  locationId: string = "";
  medium: Device.Medium = Device.Medium.ELECTRICITY;

  get getMedium(): string {
    switch (this.generalInfo.deviceType) {
      case Device.Type.SMART_METER:
        this.medium = Device.Medium.ELECTRICITY;
        return "Strom";
      case Device.Type.GAS_METER:
        this.medium = Device.Medium.GAS;
        return "Gas";

      case Device.Type.WATER_METER:
        this.medium = Device.Medium.WATER;
        return "Wasser";

      case Device.Type.HEATING_METER:
        this.medium = Device.Medium.HEATING;
        return "Heizung";
    }
    return "Strom";
  }

  async onContinueClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(this.medium);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);
    registration.setSpaceMapping(spaceMapping);

    const details = new MsconsRegistrationDetails();
    details.setLocationIdType(this.locationIdType);
    details.setLocationId(this.locationId);

    registration.setMscons(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);

    this.registrationCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});

      this.registrationCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registrationCall = failure(userErrorFrom(error));
    }
  }
}
