import { RpcError, StatusCode } from "grpc-web";
import { ServiceError } from "zaehlerfreunde-proto-types/service_error_pb";
import { Status } from "google-rpc-protos/status_pb";
import { toByteArray } from "base64-js";

export class UserError extends Error {
  // The unique error code  (e.g. 'USER_NOT_FOUND')
  code: StatusCode;
  // Human readable error message (e.g. 'The requested user could not be found')
  message: string;
  // Trace id
  traceId: string | null;

  constructor(code: StatusCode, message: string, traceId: string | null) {
    super();
    this.code = code;
    this.message = message;
    this.traceId = traceId;
  }
}

const unknownErrorMessage = "Etwas ist schief gelaufen";

export const unknownError: UserError = new UserError(StatusCode.UNKNOWN, unknownErrorMessage, null);

export const userErrorFrom = (a: unknown): UserError => {
  let code = StatusCode.UNKNOWN;
  let message = unknownErrorMessage;
  let traceId: string | null = null;

  if (a instanceof RpcError) {
    code = a.code;
    message = a.message;

    // Add the trace id to the error message if present
    if (a.metadata["x-datadog-trace-id"]) {
      traceId = a.metadata["x-datadog-trace-id"];
      message = message + ` (ID: ${traceId})`;
    }
  } else if (a instanceof Error) {
    message = a.message;
  } else if (typeof a === "string") {
    message = a;
  } else {
    message = "Etwas ist schief gelaufen";
  }

  return new UserError(code, message, traceId);
};

export function serviceErrorFrom(err: RpcError): ServiceError | null {
  // to get status, we requires err['metadata']['grpc-status-details-bin']
  if (
    !(
      "metadata" in err &&
      "grpc-status-details-bin" in err["metadata"] &&
      typeof err.metadata["grpc-status-details-bin"] === "string"
    )
  ) {
    // if the error does not contain status, return null
    return null;
  }
  const statusDetailsBinStr = err.metadata["grpc-status-details-bin"];

  let bytes: Uint8Array;
  try {
    bytes = toByteArray(pad(statusDetailsBinStr));
  } catch {
    // `grpc-status-details-bin` has an invalid base64 string
    return null;
  }

  const st = Status.deserializeBinary(bytes);
  const details = st.getDetailsList();

  for (const detail of details) {
    const serviceError = ServiceError.deserializeBinary(detail.getValue_asU8());

    if (serviceError) {
      return serviceError;
    }
  }

  return null;
}

function pad(unpadded: string): string {
  while (unpadded.length % 4 !== 0) {
    unpadded += "=";
  }
  return unpadded;
}
